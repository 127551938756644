export class creativeAssetPreviewEditModalController {
  constructor($scope, $uibModalInstance, data, creativeRepositoryService, creativeRepositoryState) {
    this.scope = $scope;
    this.uibModalInstance = $uibModalInstance;
    this.creativeAsset = data.creative;
    this.creativeRepositoryService = creativeRepositoryService;
    this.creativeRepositoryState = creativeRepositoryState;
    this.loading = true;
    this.creativeAssetChanged = false;

    this.showError = false;
    this.errorMessage = 'There was an error while editing the creative asset.';

    this.editMode = data.editMode || false;
    this.setData();
  }

  async setData() {
    try {
      this.isImage = this.creativeAsset.type === 'IMAGE';
      this.previewURL = this.creativeAsset.preview_url;
      this.creativeAssetId = this.creativeAsset.asset_id;
      this.status = this.creativeAsset.status;
      this.creativeAssetName = this.creativeAsset.asset_name;
      this.creativeAssetType = this.creativeAsset.type;
      this.editedCreativeAssetName = this.creativeAsset.asset_name;

      this.adGroups = await this.creativeRepositoryService.getAssociatedAdgroups(this.creativeAsset.asset_id);
      this.makeAdGroupUrl();
    } catch (e) {
      this.raiseError(e);
    } finally {
      this.loading = false;
      this.scope.$apply();
    }
  }

  makeAdGroupUrl() {
    for (let adGroup of this.adGroups) {
      adGroup.url = `/campaign/${adGroup.campaign_id}/adgroup/${adGroup.adgroup_id}#creatives`;
    }
  }

  onEditUneditToggle() {
    this.showError = false;
    this.editMode = !this.editMode;
    this.editedCreativeAssetName = this.creativeAssetName;
  }

  /**
   * After every action that would change the creative asset we have to change the variable creativeAssetChanged to true
   * This will allow us to keep track of when to reload the creative list
   */
  async saveName() {
    try {
      this.loading = true;
      const response = await this.creativeRepositoryService.changeCreativeAssetName(
        this.creativeAssetId,
        this.editedCreativeAssetName
      );
      if (response.success) {
        this.creativeAssetChanged = true;
        this.creativeAssetName = this.editedCreativeAssetName;
      } else {
        throw 'There was error trying to change the name.';
      }
    } catch (e) {
      this.raiseError(e);
    } finally {
      this.loading = false;
      this.editMode = false;
    }
    this.scope.$apply();
  }

  async pauseCreativeAsset() {
    this.loading = true;
    try {
      const response = await this.creativeRepositoryService.pauseCreativeAsset(this.creativeAssetId);
      if (!response.success) {
        throw 'There was an error pausing the creative asset.';
      } else {
        this.status = 'Paused';
        this.creativeAssetChanged = true;

        this.updateCurrentCreativeAssetField('status', 'Paused');
      }
    } catch (e) {
      this.raiseError(e);
    } finally {
      this.loading = false;
    }
    this.scope.$apply();
  }

  updateCurrentCreativeAssetField(field, newFieldValue) {
    let newCreativeAsset = this.creativeRepositoryState.listOfCreativeAssets.find(creativeAsset => {
      return creativeAsset.asset_id === this.creativeAssetId;
    });
    newCreativeAsset[field] = newFieldValue;

    this.creativeAssetChanged = true;
  }

  raiseError(errorMessage) {
    if (typeof errorMessage === 'string') {
      this.errorMessage = errorMessage;
    } else {
      this.errorMessage = 'There was an error. Please contact support.';
    }
    this.showError = true;
  }

  closeError() {
    this.showError = false;
  }

  async close() {
    if (this.creativeAssetChanged) {
      this.creativeRepositoryState.refreshCreativeAssetsList();
    }
    this.uibModalInstance.dismiss('cancel');
  }
}

creativeAssetPreviewEditModalController.$inject = [
  '$scope',
  '$uibModalInstance',
  'data',
  'creativeRepositoryService',
  'creativeRepositoryState'
];
