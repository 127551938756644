import React from 'react';
import PropTypes from 'prop-types';
import { CircularIndicator } from '../../../components/reactComponents/LoadingIndicator/CircularIndicator';
import { CreditCard } from './BillingPageView';
import { Customer } from '../../../api/types/customer';
import { cardStatus } from './CardRow';

interface PrimaryCellProps {
  card: CreditCard;
  customer: Customer;
  setDefaultCard: (card: CreditCard) => void;
  isLoading: boolean;
}

export function PrimaryCell(props: PrimaryCellProps) {
  const { card, customer, setDefaultCard, isLoading } = props;

  const isRadioButtonDisabled = [cardStatus.FAILED, cardStatus.PENDING, cardStatus.DISPUTE].includes(card.status);

  return (
    <td className="payments-table__primary-cell">
      {card.primaryProgress ? (
        <CircularIndicator size={24} className="primary-cell--loading" />
      ) : (
        <div className="radio-buttons-wrapper">
          <input
            type="radio"
            checked={card.card_id == customer?.default_card_id && !card.primaryProgress}
            disabled={isLoading || isRadioButtonDisabled}
            onChange={() => setDefaultCard(card)}
          />
        </div>
      )}
    </td>
  );
}

PrimaryCell.propTypes = {
  card: PropTypes.object,
  customer: PropTypes.object,
  setDefaultCard: PropTypes.func,
  isLoading: PropTypes.bool
};
