import { NotificationTypes } from '../../api/types/common';

UserManagerCtrl.$inject = ['$scope', 'userFactory'];

/**
 * @param {import('../../factories/types').UserFactoryService} userFactory
 */
export function UserManagerCtrl($scope, userFactory) {
  $scope.booleans = {
    loading: false
  };

  $scope.excludeNotificationTypes = [NotificationTypes.NO_VALID_ACCOUNT_LEVEL_CREDIT_CARD];

  $scope.selectedUser = angular.copy(userFactory.nullUser);

  $scope.$on('in progress', function() {
    $scope.booleans.loading = true;
  });

  $scope.$on('end progress', function() {
    $scope.booleans.loading = false;
  });
}
