import React from 'react';
import { Promotion } from '../../../api/types/Promotion';

interface PromotionSectionProps {
  promotions?: Promotion[];
}

export function PromotionSection({ promotions = [] }: PromotionSectionProps) {
  const formatTimestamp = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <>
      <div className="payment-page__section">
        <div className="payment-page__section-title">Promotions &amp; Credits</div>
        <p>
          Any valid promotional code or advertising credits, if applicable, are automatically applied to your account
          and will be deducted from your next billing event. Note: Your credit amount is displayed in the currency the
          credit was issued in and cannot be transferred to another currency, another GroundTruth Ads Manager account,
          or used outside GroundTruth Ads Manager.
        </p>
        <table className="xad-table table-striped">
          <thead>
            <tr className="payments-table-header">
              <th>Code</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {promotions.map((promotion, index) => (
              <tr className="promotion-table-data" key={index}>
                <td>{promotion.code?.toUpperCase()}</td>
                <td>{formatTimestamp(promotion.credit_date)}</td>
                <td>${promotion.amount.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
