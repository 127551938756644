import {
  FeatureFlagService,
  SearchService,
  AdgroupTargetingFactory,
  CompanyAndAccountFactoryService
} from '../../factories/types';
import { AdGroupTargeting } from '../../api/types/adgroup';

export enum LocationFilterSearchPlaceholderType {
  Mobile = 'Search and select city, state, DMA, zipcode, coordinates, or address',
  Default = 'Search and select city, state, DMA, or zipcode',
  countyEnabledMobile = 'Search and select city, state, DMA, county, zipcode, coordinates, or address',
  countyEnabledDefault = 'Search and select city, state, DMA, county, or zipcode',
  canadaMobile = 'Search and select city, province, postal code, coordinates, or address',
  canadaDefault = 'Search and select city, province or postal code'
}

export class AdgroupTargetingService {
  // Factories
  readonly featureFlagFactory: FeatureFlagService;
  readonly searchFactory: SearchService;
  readonly adgroupTargetingFactory: AdgroupTargetingFactory;
  readonly companyAndAccountFactoryService: CompanyAndAccountFactoryService;

  /** @ngInject */
  constructor(
    featureFlagFactory: FeatureFlagService,
    searchFactory: SearchService,
    adgroupTargetingFactory: AdgroupTargetingFactory,
    companyAndAccountFactory: CompanyAndAccountFactoryService
  ) {
    this.featureFlagFactory = featureFlagFactory;
    this.searchFactory = searchFactory;
    this.adgroupTargetingFactory = adgroupTargetingFactory;
    this.companyAndAccountFactoryService = companyAndAccountFactory;
  }

  getLocationFilterSearchPlaceholder(adGroupTargeting: AdGroupTargeting): LocationFilterSearchPlaceholderType {
    if (
      this.isDesktopTargetingSelected(adGroupTargeting) ||
      (this.areStreamingServicesSelected(adGroupTargeting) &&
        !this.isTargetTypeGeoTargetingWithStreamingRadialFeatureFlag(adGroupTargeting))
    ) {
      if (this.companyAndAccountFactoryService.isAccountInCountry('CA')) {
        return LocationFilterSearchPlaceholderType.canadaDefault;
      } else if (this.isFeatureEnabled('COUNTY_TARGETING')) {
        return LocationFilterSearchPlaceholderType.countyEnabledDefault;
      }
      return LocationFilterSearchPlaceholderType.Default;
    } else if (this.companyAndAccountFactoryService.isAccountInCountry('CA')) {
      return LocationFilterSearchPlaceholderType.canadaMobile;
    } else if (this.isFeatureEnabled('COUNTY_TARGETING')) {
      return LocationFilterSearchPlaceholderType.countyEnabledMobile;
    }
    return LocationFilterSearchPlaceholderType.Mobile;
  }

  searchAudiences(query: string, exclude = false, includeAllTab = false) {
    const filter = {
      targeting_search: 1,
      exclude: exclude ? 1 : 0,
      entity_types: 'behavior,custom_audience,segment,brand,category,location_group',
      search_category: 'audience'
    };
    return this.searchFactory.getSearchResults(query, filter, includeAllTab);
  }

  searchTargetingLocations(adGroupTargeting: AdGroupTargeting, query: string) {
    let entity_types: string = '';
    if (
      this.isDesktopTargetingSelected(adGroupTargeting) ||
      (this.areStreamingServicesSelected(adGroupTargeting) &&
        !this.isTargetTypeGeoTargetingWithStreamingRadialFeatureFlag(adGroupTargeting))
    ) {
      if (this.companyAndAccountFactoryService.isAccountInCountry('CA')) {
        entity_types = 'city,state,zip_code';
      } else if (this.isFeatureEnabled('COUNTY_TARGETING')) {
        entity_types = 'city,state,dma,county,zip_code';
      } else {
        entity_types = 'city,state,dma,zip_code';
      }
    } else {
      if (this.companyAndAccountFactoryService.isAccountInCountry('CA')) {
        entity_types = 'city,state,zip_code,latlng,address';
      } else if (this.isFeatureEnabled('COUNTY_TARGETING')) {
        entity_types = 'city,state,dma,county,zip_code,latlng,address';
      } else {
        entity_types = 'city,state,dma,zip_code,latlng,address';
      }
    }

    interface Filter {
      targeting_search: number;
      entity_types: string;
      device_type: Array<string> | null | undefined;
    }
    const filter: Filter = {
      targeting_search: 1,
      entity_types,
      device_type: adGroupTargeting.deviceTypes
    };
    return this.searchFactory.getSearchResults(query, filter);
  }

  searchNeighborhoods(query) {
    const filter = {
      targeting_search: 1,
      entity_types: 'behavior,brand,category,location_group',
      search_category: 'neighborhood'
    };
    return this.searchFactory.getSearchResults(query, filter);
  }

  getOrderedLookalikeAudienceOptions() {
    return this.adgroupTargetingFactory.orderedLookalikeAudienceOptions;
  }

  getLookalikeAudienceOption(optionId: string) {
    return this.adgroupTargetingFactory.getLookalikeAudienceOptions[optionId];
  }

  isFeatureEnabled(feature: string) {
    return this.featureFlagFactory.isFeatureEnabled(feature);
  }

  private areStreamingServicesSelected(adGroupTargeting: AdGroupTargeting): boolean {
    const technographicsOptions = this.adgroupTargetingFactory.getTechnographicsOptions;
    return !!(
      adGroupTargeting.deviceTypes?.map(t => t.toString()).includes(technographicsOptions.device.CTV.value) ||
      adGroupTargeting.deviceTypes?.map(t => t.toString()).includes(technographicsOptions.device.OTT.value)
    );
  }

  private isDesktopTargetingSelected(adGroupTargeting: AdGroupTargeting): boolean {
    const technographicsOptions = this.adgroupTargetingFactory.getTechnographicsOptions;
    return !!(
      adGroupTargeting.deviceTypes?.map(t => t.toString()).includes(technographicsOptions.device.Desktop.value) &&
      this.featureFlagFactory.isFeatureEnabled('DESKTOP_TARGETING')
    );
  }

  private isTargetTypeGeoTargetingWithStreamingRadialFeatureFlag(adGroupTargeting: AdGroupTargeting): boolean {
    return (
      adGroupTargeting.product === 'geotargeting' &&
      this.featureFlagFactory.isFeatureEnabled('CTV_OTT_RADIAL_TARGETING')
    );
  }
}
