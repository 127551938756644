import angular from 'angular';
import ngRoute from 'angular-route';
import ngSanitize from 'angular-sanitize';
import ngMessages from 'angular-messages';
import ngFileSaver from 'angular-file-saver';
import ngStorage from 'ngstorage';
import uirouter from '@uirouter/angularjs';
import ngUiBootstrap from 'angular-ui-bootstrap';
import ngFileUpload from 'ng-file-upload';

import ngMd5 from 'angular-md5';
import '@flowjs/ng-flow';
import 'ng-infinite-scroll';

import '../styles/css/colorpicker.css';
import '../styles/css/xad.min.css';
import '../styles/css/daterangepicker.css';
import '../styles/main.scss';

import { SentryAngular } from './sentry';

SentryAngular.setup(angular);

import { controllers as baseControllers, directives as baseDirectives } from './base';
import { controllers as accountControllers } from './account';
import {
  controllers as adgroupControllers,
  factories as adgroupFactories,
  directives as adgroupDirectives,
  services as adgroupServices,
  adgroupReactComponents
} from './adgroup';
import {
  controllers as campaignDashboardControllers,
  directives as campaignDashboardDirectives
} from './campaign/campaign_dashboard';
import { controllers as campaignReportControllers, factories as campaignReportFactories } from './campaign';
import * as creativeRepository from './creative_repo';
import {
  controllers as gtComponentsControllers,
  directives as gtComponentsDirectives,
  factories as gtComponentsFactories,
  components as gtComponents,
  reactAngularComponents
} from '../components';
import { controllers as organizationControllers } from './organization';
import {
  controllers as paymentsControllers,
  directives as paymentsDirectives,
  factories as paymentsFactories,
  billingPageViewComponents
} from './payments';
import * as root from './root';
import { controllers as searchControllers } from './search';
import {
  services as sharedServices,
  factories as sharedFactories,
  controllers as sharedControllers,
  filters as sharedFilters,
  directives as sharedDirectives
} from '../utils';
import { controllers as tenantControllers } from './tenant';
import * as tenantDashboard from './tenant/tenant_dashboard';
import * as directMailOrdersDashboard from './direct_mail_orders';
import { controllers as userControllers, filters as userFilters } from './user';

import {
  config as myfp_uirouterConfig,
  runBlock as myfp_uirouterRunBlock,
  redirectInterceptor,
  subsessionInterceptor
} from './ui_router';

import servicesModule from '../utils/services';
import reportSchedulerModule from './report_scheduler/ng';
import adgroupModule from './adgroup/ng';
import xad from '../utils/modules/xad';
import isocurrency from '../utils/modules/isocurrency';
import shared from '../utils/modules/shared';
import colorpicker from '../components/bootstrap-colorpicker-module';
import creativeRepositoryV2Module from './creative_repo_v2/ng';

// construct application
const app = angular
  .module('app', [
    uirouter,
    ngRoute,
    ngSanitize,
    ngUiBootstrap,
    'flow',
    ngMessages,
    ngMd5,
    'infinite-scroll',
    xad.name,
    isocurrency.name,
    colorpicker.name,
    ngFileUpload,
    ngStorage.name,
    ngFileSaver,
    shared.name,
    reportSchedulerModule.name,
    adgroupModule.name,
    servicesModule.name,
    creativeRepositoryV2Module.name,
    'ngSentry'
  ])
  .config(myfp_uirouterConfig)
  .run(myfp_uirouterRunBlock)
  .controller({
    ...baseControllers,
    ...accountControllers,
    ...adgroupControllers,
    ...campaignDashboardControllers,
    ...campaignReportControllers,
    ...creativeRepository.controllers,
    ...gtComponentsControllers,
    ...organizationControllers,
    ...paymentsControllers,
    ...root.controllers,
    ...searchControllers,
    ...sharedControllers,
    ...tenantControllers,
    ...tenantDashboard.controllers,
    ...userControllers,
    ...directMailOrdersDashboard.controllers
  })
  .directive({
    ...baseDirectives,
    ...adgroupDirectives,
    ...campaignDashboardDirectives,
    ...creativeRepository.directives,
    ...gtComponentsDirectives,
    ...paymentsDirectives,
    ...sharedDirectives
  })
  .factory({
    ...adgroupFactories,
    ...campaignReportFactories,
    ...gtComponentsFactories,
    ...paymentsFactories,
    ...sharedFactories,
    redirectInterceptor,
    subsessionInterceptor
  })
  .component({
    ...gtComponents,
    ...creativeRepository.components,
    ...reactAngularComponents,
    ...billingPageViewComponents,
    ...adgroupReactComponents
  })
  .service({
    ...creativeRepository.services,
    ...tenantDashboard.services,
    ...sharedServices,
    ...adgroupServices,
    ...directMailOrdersDashboard.services
  })
  .filter({
    ...sharedFilters,
    ...userFilters
  });

export default app;
