import GAEventList from '../../../components/googleAnalytics/eventList';

export function yesNo() {
  return function(input) {
    return input ? 'Yes' : 'No';
  };
}

export function newForTrue() {
  return function(input) {
    return input ? 'New' : '';
  };
}

UserListViewController.$inject = [
  '$scope',
  '$state',
  '$stateParams',
  'userFactory',
  '$timeout',
  '$q',
  '$filter',
  'modalFactory',
  'featureFlagFactory'
];

/**
 * @param {import('../../../factories/types').UserFactoryService} userFactory
 */
export function UserListViewController(
  $scope,
  $state,
  $stateParams,
  userFactory,
  $timeout,
  $q,
  $filter,
  modalFactory,
  featureFlagFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;
  $scope.userFactory = userFactory;
  $scope.filteredUsers = [];
  $scope.groups = [];

  $scope.booleans = {
    tableDataChanged: {
      changed: false
    },
    noUsers: false
  };

  $scope.search = {
    user: ''
  };

  $scope.loadingData = true;

  $scope.init = function() {
    $scope.$emit('in progress');
    var getUserDfd = userFactory.getUsers();
    var getGroupDfd = userFactory.getGroups();
    var getCurrentLoggedInUserDfd = userFactory.getCurrentLoggedInUser();
    $q.all([getGroupDfd, getUserDfd, getCurrentLoggedInUserDfd])
      .then(function(responses) {
        //loading all the user groups once
        angular.copy($scope.userFactory.groups, $scope.groups);

        //group id to group map
        var groupMap = {};
        for (var i = 0; i < $scope.groups.length; i++) {
          var groupObj = $scope.groups[i];
          groupMap[groupObj.group_id] = groupObj;
        }

        if (userFactory.currentLoggedInUser.tenant_type != 1) {
          $scope.usersTableConfig.header.push({
            name: 'Ads Manager Access',
            field: 'authorized_for_mpcb',
            components: [
              {
                field: 'authorized_for_mpcb',
                filter: 'yesNo'
              }
            ]
          });
        }

        if (!$scope.userFactory.users.length) {
          $scope.booleans.noUsers = true;
        } else {
          $scope.booleans.noUsers = false;
        }
        angular.copy($scope.userFactory.users, $scope.filteredUsers);
        $scope.booleans.tableDataChanged.changed = true;

        //setting a boolean so that we know
        //not needed right now
        if ($stateParams.userId === '') {
          angular.copy(userFactory.nullUser, $scope.selectedUser);
        } else {
          angular.copy(userFactory.getSelectedUser($stateParams.userId), $scope.selectedUser);
        }
      })
      .finally(function() {
        $scope.loadingData = false;
        $scope.$emit('end progress');
      });
  };

  $scope.editUser = function(user) {
    $state.go('users.edit-user', { userId: user.id });
  };

  $scope.usersTableConfig = {
    tableName: 'user',
    header: [
      { name: 'First Name', field: 'firstName', components: [{ field: 'firstName', filter: 'capitalize' }] },
      { name: 'Last Name', field: 'lastName', components: [{ field: 'lastName', filter: 'capitalize' }] },
      { name: 'Email', field: 'username', components: [{ field: 'username' }] },
      { name: 'Department', field: 'departments', components: [{ field: 'departments', filter: 'join' }] }
    ],
    defaultOrderField: '-username',
    filter: '',
    rowTrigger: $scope.editUser
  };

  $scope.userFilterChange = function() {
    $scope.filteredUsers = $filter('commonFilter')(
      $scope.userFactory.users,
      $scope.search.user,
      $scope.usersTableConfig.header
    );
    $scope.booleans.tableDataChanged.changed = true;
  };

  $scope.clearSearchBar = function() {
    this.search.user = '';
    $scope.userFilterChange();
  };

  $scope.inviteUserModal = function() {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-invite_user.html',
      controller: 'InviteUserModalInstanceCtrl'
    };

    modalFactory.createModal(modalOptions);
  };

  $timeout($scope.init);
}

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 * @param {import('../../../factories/types').UserFactoryService} userFactory
 * @param {ng.IWindowService} $window
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @ngInject
 */
export function InviteUserModalInstanceCtrl($scope, $uibModalInstance, userFactory, $window, companyAndAccountFactory) {
  $scope.progress = false;
  $scope.user_email = '';

  $scope.inviteUser = function() {
    $scope.inviteUserModalErrorMsg = '';
    $scope.progress = true;
    userFactory
      .inviteUserSendEmail($scope.user_email)
      .then(
        function() {
          $scope.$emit('success-toast', { message: 'Invite sent successfully.' });
          typeof $window.gtag === 'function' &&
            $window.gtag('event', GAEventList.TENANT_USER_INVITE, {
              invite_email: $scope.user_email,
              tenant_id: companyAndAccountFactory.selectedTenant?.tenant_id
            });
          $uibModalInstance.dismiss('success');
        },
        function(error) {
          $scope.inviteUserModalErrorMsg = error;
        }
      )
      .finally(function() {
        $scope.progress = false;
      });
  };

  $scope.close = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
