outsideClick.$inject = ['$timeout', '$document'];

export function outsideClick($timeout, $document) {
  var directive = {
    link: link,
    restrict: 'A',
    scope: {
      outsideClick: '&',
      watching: '='
    }
  };
  return directive;

  function link(scope, element, attrs) {
    // postpone to allow for unique id generation
    $timeout(function() {
      var ifNotClassList = attrs.ifNot !== undefined ? attrs.ifNot.split(/[ ,]+/) : [];
      var isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints;

      function eventHandler(e) {
        var target;
        var targetId;
        var targetClassNames;
        var testRegex;
        var i;

        if (!e || !e.target) {
          return;
        }

        // loop through all levels of clicked element to see if ifNotClassList is part of the target
        for (target = e.target; target; target = target.parentNode) {
          targetId = target.id;
          targetClassNames = target.className;

          if (target === element[0]) {
            return;
          }

          // Unwrap SVGAnimatedString classes
          if (targetClassNames && targetClassNames.baseVal !== undefined) {
            targetClassNames = targetClassNames.baseVal;
          }

          // if there are no class names on the element clicked, skip the check
          if (targetClassNames || targetId) {
            // loop through the elements id's and classnames looking for exceptions
            for (i = 0; i < ifNotClassList.length; i++) {
              testRegex = new RegExp('\\b' + ifNotClassList[i] + '\\b');

              if (
                (targetId !== undefined && targetId === ifNotClassList[i]) ||
                (targetClassNames && testRegex.test(targetClassNames))
              ) {
                return;
              }
            }
          }
        }

        scope.$apply(scope.outsideClick);
      }

      function turnOnClickHandler() {
        $document.on('click', eventHandler);

        if (isTouchScreen) {
          $document.on('touchstart', eventHandler);
        }
      }

      function turnOffClickHandler() {
        $document.off('click', eventHandler);

        if (isTouchScreen) {
          $document.off('touchstart', eventHandler);
        }
      }

      if (scope.watching !== undefined) {
        scope.$watch('watching', function(newVal) {
          if (newVal) {
            turnOnClickHandler();
          } else {
            turnOffClickHandler();
          }
        });
      } else {
        turnOnClickHandler();
      }

      scope.$on('destroy', function() {
        turnOffClickHandler();
      });
    });
  }
}
