import { replaceAll } from '../../../utils/string';
import { isEmpty } from 'lodash';
import {
  CreativeVideoTranscoderStatusResponse_status,
  CreativeHTML5StatusResponse_status,
  CreativeResponse_status
} from '../../../api/http/creative';

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 * @param {ng.ITimeoutService} $timeout
 * @param {ng.ILocationService} $location
 * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @param {import('../../../factories/static_data.fac').IStaticDataFactory} staticDataFactory
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param flowFactory
 * @ngInject
 */
export function CreativeModalInstanceCtrl(
  $scope,
  $uibModalInstance,
  $timeout,
  $filter,
  $location,
  data,
  modalFactory,
  selfServeDataFactory,
  generalUtilsFactory,
  creativeTemplatesFactory,
  campaignManagerFactory,
  staticDataFactory,
  companyAndAccountFactory,
  featureFlagFactory,
  universalAlert,
  flowFactory
) {
  const html5DefaultBackgroundColor = '#1890FF';
  const html5DefaultDistanceColor = '#FFFF00';
  const html5RevisionId = 195;
  const html5ThemeId = 130;
  $scope.html5BackgroundOptions = {
    SOLID: 'solid',
    IMAGE: 'image'
  };

  $scope.vars = {
    textBannerBackgroundType: $scope.html5BackgroundOptions.SOLID
  };

  if (data?.creative?.cmsConfig?.backgroundImage) {
    $scope.vars.textBannerBackgroundType = $scope.html5BackgroundOptions.IMAGE;
  }

  $scope.resetTextBannerBackground = creative => {
    creative.cmsConfig.backgroundColor = html5DefaultBackgroundColor;
    if ($scope.vars.textBannerBackgroundType === $scope.html5BackgroundOptions.SOLID) {
      creative.cmsConfig.backgroundImage = null;
      $scope.uploader.backgroundImage.files = [];
    }
  };

  $scope.showUploadBgImage = creative =>
    creative.cmsConfig.backgroundImage && $scope.vars.textBannerBackgroundType === $scope.html5BackgroundOptions.IMAGE;
  $scope.showDefBgImage = creative =>
    !creative.cmsConfig.backgroundImage && $scope.vars.textBannerBackgroundType === $scope.html5BackgroundOptions.IMAGE;

  $scope.featureFlagFactory = featureFlagFactory;
  $scope.$filter = $filter;

  $scope.uploader = {};
  $scope.iurlUploader = {};
  $scope.macros = {};
  $scope.html5CreativeHeight = 0;
  $scope.html5CreativeWidth = 0;
  $scope.booleans = {
    showIUrlDragDrop: false
  };

  $scope.adGroup = angular.copy(data.adGroup);
  $scope.isCTVAdGroup = $scope.adGroup.targeting.deviceTypes.includes('CTV');
  $scope.isOTTAdGroup = $scope.adGroup.targeting.deviceTypes.includes('OTT');
  $scope.isNewDesktopAdgroup =
    $scope.adGroup.targeting.deviceTypes.includes('Desktop') &&
    $scope.featureFlagFactory.isFeatureEnabled('DESKTOP_TARGETING');
  $scope.supportedDesktopSizes = ['728x90', '300x250', '160x600', '300x600'];
  $scope.showCreativeRepoTab =
    $scope.featureFlagFactory.isFeatureEnabled('CREATIVE_REPO') &&
    companyAndAccountFactory.selectedAccount?.accountType != 7 &&
    !data.creative;
  $scope.isCreativeRepoTab = false;

  $scope.errorMessages = {
    videoVastMessage: ''
  };

  $scope.showTextEditor = false;

  $scope.currentTab = null;

  $scope.deliveryChannelCreatives = {};

  $scope.progress = false;
  $scope.adGroupBackUp = angular.copy($scope.adGroup);
  // creativeInterstitial flag is used at the end to assign to creative.creativeInterstitial which is what backend looks at
  $scope.creativeInterstitial = false;
  $scope.creativeSize = '320x50';
  $scope.clickedCreativeType = null;

  // flag to set if we want the UI to show the switch
  $scope.isSizeInterstitial = false;

  $scope.companyAndAccountFactory = companyAndAccountFactory;

  $scope.notASizeMsg = '';
  $scope.videoIsPreviewing = false;
  $scope.$parent.creativeModalErrorMsg = '';
  $scope.$parent.videoCreativeModalErrorMsg = '';

  $scope.landingPageMethod = {
    validate: null,
    beforeSave: null
  };

  $scope.landingPages = ['Standard Landing Page', 'Super Landing Page', 'Image Landing Page'];

  $scope.currentIframe = null;

  $scope.status = {
    isopen: false
  };
  $scope.availableSizes = [];

  $scope.creativeTypeCMSConfigs = angular.copy(creativeTemplatesFactory.creativeTypeCMSConfigs);

  $scope.iframeArr = [];

  $scope.html5CaptionMaxLength = 160;

  $scope.html5Preview = null;

  staticDataFactory.getMacros(companyAndAccountFactory.selectedAccount).then(function(data) {
    $scope.macros = data;
  });

  $scope.hasNativeAdsSupport =
    $scope.featureFlagFactory.isFeatureEnabled('NATIVE_ADS') &&
    companyAndAccountFactory.selectedAccount?.accountType != 7 &&
    !$scope.isCTVAdGroup &&
    !$scope.isOTTAdGroup &&
    !$scope.isNewDesktopAdgroup;

  $scope.hasAudioAdsSupport =
    $scope.featureFlagFactory.isFeatureEnabled('AUDIO_ADS') &&
    ($scope.adGroup.targeting.deviceTypes.includes('Audio-Podcast') ||
      $scope.adGroup.targeting.deviceTypes.includes('Audio-Streaming'));

  $scope.showPriceSection = $scope.companyAndAccountFactory?.selectedAccount?.countryCode === 'JP';
  $scope.accountCurrency = $scope.companyAndAccountFactory?.selectedAccount?.currency;

  $scope.nativeIconImagePixels = _getIconImageSize($scope.companyAndAccountFactory?.selectedAccount?.countryCode);

  $scope.fileSizeAllowance = $scope.featureFlagFactory.isFeatureEnabled('VIDEO_CREATIVE_INCREASED_SIZE') ? 200 : 50;
  $scope.videoDurationAllowance = $scope.featureFlagFactory.isFeatureEnabled('VIDEO_CREATIVE_INCREASED_SIZE')
    ? '2 mins'
    : '30 seconds';

  $scope.videoUploadTargetUrl = `/data/upload?subsession=${window.subsession}&type=video`;

  $scope.getFlowObject = options => {
    return new flowFactory.create(options);
  };

  $scope.videoFlow = $scope.getFlowObject({
    target: `/data/upload?subsession=${window.subsession}&type=video`,
    singleFile: true,
    chunkSize: 1024 * 1024 * 1024,
    permanentErrors: [404, 500, 501, 502],
    minFileSize: 0,
    simultaneousUploads: 1,
    allowDuplicateUploads: true,
    testChunks: false
  });

  $scope.nativeIconImageSizes = [
    {
      width: $scope.nativeIconImagePixels,
      height: $scope.nativeIconImagePixels
    }
  ];
  $scope.textBannerLogoImageSizes = [
    {
      width: 40,
      height: 40
    }
  ];
  $scope.textBannerBgImageSizes = [
    {
      width: 320,
      height: 50
    }
  ];

  function _getIconImageSize(accountCountry) {
    if (accountCountry === 'JP') {
      return 100;
    }
    return 80;
  }

  $scope.isNumber = value => typeof value === typeof 0;

  $scope.getCurrencyWithSymbol = (amount, currencyCode) => $filter('isoCurrency')(amount, currencyCode, 2, true);

  /**
   * Used when switching between `Upload image` and `creative-repo` tab
   * inside of `Creative Image` tab
   */
  $scope.onImageTabChange = tabName => {
    if (tabName === 'creative-repo') {
      $scope.isCreativeRepoTab = true;
    } else {
      $scope.isCreativeRepoTab = false;
    }
  };

  $scope.refreshAvailableSizes = function(type) {
    $scope.availableSizes.length = 0;
    staticDataFactory.getCreativeSizes().then(function(data) {
      var creativeSizes = data[type];
      if (creativeSizes && !$scope.availableSizes.length) {
        if ($scope.isNewDesktopAdgroup) {
          $scope.availableSizes = creativeSizes.filter(
            size => $scope.supportedDesktopSizes.includes(size.size) && !size.interstitial
          );
        } else {
          for (var i = 0; i < creativeSizes.length; i++) {
            if (creativeSizes[i].interstitial) {
              $scope.availableSizes.push(creativeSizes[i]);
            } else {
              $scope.availableSizes.unshift(creativeSizes[i]);
            }
          }
        }
      }

      if (!$scope.creative.id) {
        $scope.size_obj = $scope.availableSizes[0];
        $scope.size_obj.size_model = $scope.size_obj?.size_key;
        $scope.sizeSelect();
      }
    });
  };

  $scope.distanceBox = {
    containerId: 'banner-container',
    id: 'creativeEditorDistanceBox',
    distanceText: '',
    showTextEditorTool: true,
    css: {
      top: '0px',
      left: '0px',
      bottom: '0px',
      right: '0px',
      height: '50px',
      width: '50px',
      color: '#000000',
      'font-family': 'Helvetica',
      'font-size': '12px',
      'text-align': 'center',
      'text-shadow': 'none',
      'font-style': 'normal',
      'font-weight': 'normal'
    }
  };

  $scope.guid = function() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  };

  var reduceTheDimensionAccordingToScale = function(ratio, css) {
    if (css.hasOwnProperty('font-size') && css['font-size'].indexOf('px') > 0) {
      css['font-size'] = parseInt(css['font-size'].split('px')[0]) * ratio;
      css['font-size'] = css['font-size'].toString() + 'px';
    }

    if (css.hasOwnProperty('left') && css['left'].indexOf('px') > 0) {
      css['left'] = parseFloat(css['left'].split('px')[0]) * ratio;
      css['left'] = css['left'].toString() + 'px';
    }

    if (css.hasOwnProperty('top') && css['top'].indexOf('px') > 0) {
      css['top'] = parseFloat(css['top'].split('px')[0]) * ratio;
      css['top'] = css['top'].toString() + 'px';
    }

    if (css.hasOwnProperty('height') && css['height'].indexOf('px') > 0) {
      css['height'] = parseFloat(css['height'].split('px')[0]) * ratio;
      css['height'] = css['height'].toString() + 'px';
    }

    if (css.hasOwnProperty('width') && css['width'].indexOf('px') > 0) {
      css['width'] = parseFloat(css['width'].split('px')[0]) * ratio;
      css['width'] = css['width'].toString() + 'px';
    }

    if (css.hasOwnProperty('bottom') && css['bottom'].indexOf('px') > 0) {
      css['bottom'] = parseFloat(css['bottom'].split('px')[0]) * ratio;
      css['bottom'] = css['bottom'].toString() + 'px';
    }

    if (css.hasOwnProperty('right') && css['right'].indexOf('px') > 0) {
      css['right'] = parseFloat(css['right'].split('px')[0]) * ratio;
      css['right'] = css['right'].toString() + 'px';
    }
  };

  if (data.creative) {
    for (var i = 0; i < $scope.adGroup.creatives.length; i++) {
      if ($scope.adGroup.creatives[i].id == data.creative.id) {
        $scope.creative = angular.copy(selfServeDataFactory.nullAdGroup.creative);
        generalUtilsFactory.nestedUpdateInPlace($scope.adGroup.creatives[i], $scope.creative);

        if (!$scope.creative.landingPageUrl && $scope.creative.status != 'Incomplete') {
          $scope.creative.useLandingPage = true;
        }

        $scope.adGroup.creatives[i] = $scope.creative;
        if ($scope.creative.creativeIUrl) {
          $scope.booleans.showIUrlDragDrop = true;
        }

        break;
      }
    }
  } else {
    $scope.adGroup.creatives.push(angular.copy(selfServeDataFactory.nullAdGroup.creative));
    $scope.creative = $scope.adGroup.creatives[$scope.adGroup.creatives.length - 1];
  }

  if (!$scope.creative.useLandingPage || $scope.creative.landingPageUrl) {
    $scope.creative.useLandingPageSwitchSet = false;
  } else {
    $scope.creative.useLandingPageSwitchSet = true;
  }

  $scope.landingPageSwitchChange = function() {
    autoScroll('landing-page');
  };

  $scope.landingPageChange = function() {
    // When user switches between landing pages, we need to clear the landing page methods.
    $scope.landingPageMethod.validate = null;
    $scope.landingPageMethod.beforeSave = null;

    autoScroll('landing-page');
  };

  if ($scope.creative.creativeType === 'HTML5_NEW') {
    if ($scope.creative.cmsConfig.html_tag) {
      const html5Preview = creativeTemplatesFactory.getHTMLTagForHTML5CreativePreview($scope.creative.cmsConfig);
      [$scope.html5CreativeWidth, $scope.html5CreativeHeight] = $scope.creative.creativeSize.split('x');
      $scope.html5Preview = html5Preview;
    }
  }

  var autoScroll = function(scrollToClass) {
    // adding timeout because angular changes the ng-model value of useLandingPageSwitchSet BUT does not make the dom element visible.
    // so keeping the auto scroll code in timeout will allow the dom element to be visible and then auto scroll will work.
    $timeout(function() {
      // auto scroll to LP section only when creative does not already have landing page
      if (!$scope.creative.useLandingPage && $scope.creative.useLandingPageSwitchSet) {
        var container = $('#modalId').closest('.modal');

        // if scrollToClass is provided then scroll to that class else scroll to top of the modal
        if (scrollToClass) {
          var scrollTo = $('.' + scrollToClass);
          container.animate(
            {
              scrollTop: (scrollTo.offset()?.top ?? 0) - (container.offset()?.top ?? 0) + container.scrollTop()
            },
            1300
          );
        } else {
          container.animate(
            {
              scrollTop: 0
            },
            1300
          );
        }
      }
    });
  };

  $scope.loadCreativeFromScript = function() {
    $scope.scriptTagErrorMsg = '';
    var validation = verifyScriptTag();
    if (!validation.valid) {
      $scope.scriptTagErrorMsg = validation.reason.join('\n');
      return;
    }

    var scriptPrefix = '<script src="//cf.groundtruth.com/content/myfootprints/js/mraid.js"></script>';
    var scriptTag = scriptPrefix + $scope.creative.cmsConfig.script_tag;
    var doc = /** @type {HTMLIFrameElement}*/ ($('iframe#scriptTagIframe')[0]).contentWindow?.document;
    if (doc) {
      doc.open();
      doc.write(scriptTag);
      doc.close();
    }
  };

  var replaceMacrosOnVastURL = function(url) {
    for (var key in $scope.macros) {
      if ($scope.macros.hasOwnProperty(key)) {
        url = replaceAll(url, key, '');
      }
    }
    return url;
  };

  $scope.vastTagUrlChanged = function(form, org_url) {
    var url = replaceMacrosOnVastURL(org_url);
    $scope.errorMessages.videoVastMessage = '';

    if (org_url === '') return;

    creativeTemplatesFactory
      .validateVast(org_url)
      .then(() => $scope.loadVideoFromTag())
      .catch(err => {
        $scope.removeVideo(false);
        $scope.errorMessages.videoVastMessage = err;
      });
  };

  $scope.loadVideoFromTag = function() {
    $scope.videoIsPreviewing = true;
    $scope.playVideo(replaceMacrosOnVastURL($scope.creative.cmsConfig.vastTagUrl));
  };

  $scope.removeVideo = (clearVastTagUrl = true) => {
    if (clearVastTagUrl) {
      $scope.creative.cmsConfig.vastTagUrl = '';
      $scope.creative.video_transcoder = {
        s3_file_name: ''
      };
    }
    // fix to remove video element completely: https://stackoverflow.com/a/28060352
    $scope.videoElement.pause();
    $scope.videoElement.removeAttribute('src');
    $scope.videoElement.load();
    $scope.fluidPlayer?.destroy();
    $scope.videoIsPreviewing = false;
  };

  $scope.playVideo = function(url) {
    if (!url) return;

    try {
      $scope.fluidPlayer?.destroy();
    } catch (e) {
    } finally {
      let videoSource = '';
      const videoContainer = document.querySelector('#vast-video-container');
      $scope.videoElement = document.createElement('video');
      $scope.videoElement.setAttribute('id', 'vast-video');
      videoContainer?.appendChild($scope.videoElement);

      // @ts-ignore
      $scope.fluidPlayer = fluidPlayer('vast-video', {
        onBeforeXMLHttpRequest: request => {
          request.withCredentials = false;
        },
        layoutControls: {
          fillToContainer: true,
          keyboardControl: false
        },
        vastOptions: {
          allowVPAID: true,
          adList: [
            {
              roll: 'preRoll',
              vastTag: url,
              adClickable: false
            }
          ],
          vastAdvanced: {
            vastLoadedCallback: () => {
              // buffer video source
              videoSource = $scope.videoElement.src;
            },
            noVastVideoCallback: () => {},
            vastVideoSkippedCallback: () => {},
            vastVideoEndedCallback: () => {
              /*
                set video source to player, as fluid player removes
                vast video src when finished
              */
              $scope.videoElement.src = videoSource;
              // avoid autoplay when DOM ready
              $timeout(() => $scope.fluidPlayer.pause(), 10);
            }
          }
        }
      });
    }
  };

  $scope.tabs = [];

  // Setting up all Creative type tab names to template url mapping

  $scope.creativeTypeTabs = {
    IMAGE: {
      type: 'IMAGE',
      tabTitle: 'Image',
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-creative-image-tab.html'
    },
    SCRIPT: {
      type: 'SCRIPT',
      tabTitle: 'Script',
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-creative_script.html'
    },
    VIDEO: {
      type: 'VIDEO',
      tabTitle: 'Video',
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-creative_video.html'
    },
    HTML5: {
      type: 'HTML5',
      tabTitle: 'Text Banner',
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-creative_html5.html'
    },
    HTML5_NEW: {
      type: 'HTML5_NEW',
      tabTitle: 'Image',
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-creative-image-tab.html'
    }
  };

  if ($scope.hasNativeAdsSupport) {
    $scope.creativeTypeTabs.NATIVE = {
      type: 'NATIVE',
      tabTitle: 'Native',
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-creative_native.html'
    };
  }

  if ($scope.hasAudioAdsSupport) {
    $scope.creativeTypeTabs.AUDIO = {
      type: 'AUDIO',
      tabTitle: 'Audio',
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-creative_audio.html'
    };
  }

  if (
    $scope.companyAndAccountFactory.requireSelectedAccount().accountType == 7 &&
    $scope.adGroup.deliveryChannel_id != 0
  ) {
    $scope.creativeTypeTabs = {
      IMAGE: {
        type: 'IMAGE',
        tabTitle: 'Image',
        url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-creative_programmatic.html'
      },
      SCRIPT: {
        type: 'SCRIPT',
        tabTitle: 'Script',
        url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-creative_programmatic.html'
      },
      VIDEO: {
        type: 'VIDEO',
        tabTitle: 'Video',
        url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-creative_programmatic.html'
      },
      HTML5: {
        type: 'HTML5',
        tabTitle: 'Text Banner',
        url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-creative_programmatic.html'
      }
    };
  }

  $scope.setCMSConfig = function() {
    $scope.currentTab = $scope.tabs[0];
    $scope.changeTab($scope.currentTab);
    if (!isEmpty($scope.creativeTypeCMSConfigs)) {
      if ($scope.creative.creativeType === 'HTML5_NEW') {
        $scope.creative.cmsConfig = $scope.creativeTypeCMSConfigs['HTML5_NEW'];
      } else {
        $scope.creative.cmsConfig = $scope.creativeTypeCMSConfigs[$scope.currentTab['type']];
      }
    }
  };

  staticDataFactory
    .getCreativesMap(
      $scope.companyAndAccountFactory.requireSelectedAccount().accountType,
      $scope.adGroup.deliveryChannel_id
    )
    .then(function(result) {
      angular.copy(result, $scope.creativeTypeCMSConfigs);
      if (data.creative) {
        $scope.tabs.push($scope.creativeTypeTabs[data.creative.creativeType.toUpperCase()]);
      } else {
        for (var k in $scope.creativeTypeCMSConfigs) {
          if (['IMAGE', 'SCRIPT', 'VIDEO', 'HTML'].indexOf(k) > -1) {
            if ($scope.creativeTypeCMSConfigs[k].hasOwnProperty('prog_outbound_deal_id')) {
              $scope.creativeTypeCMSConfigs[k]['prog_outbound_deal_id'] = $scope.guid();
            }
          }
        }
        if ('AUDIO' in $scope.creativeTypeCMSConfigs && $scope.hasAudioAdsSupport) {
          $scope.tabs.push($scope.creativeTypeTabs['AUDIO']);
          return;
        }
        if ('IMAGE' in $scope.creativeTypeCMSConfigs && !$scope.isCTVAdGroup && !$scope.isOTTAdGroup) {
          $scope.tabs.push($scope.creativeTypeTabs['IMAGE']);
        }
        if ('SCRIPT' in $scope.creativeTypeCMSConfigs && !$scope.isCTVAdGroup && !$scope.isOTTAdGroup) {
          $scope.tabs.push($scope.creativeTypeTabs['SCRIPT']);
        }
        if ('VIDEO' in $scope.creativeTypeCMSConfigs) {
          $scope.tabs.push($scope.creativeTypeTabs['VIDEO']);
        }
        if (
          'HTML5' in $scope.creativeTypeCMSConfigs &&
          !$scope.isCTVAdGroup &&
          !$scope.isNewDesktopAdgroup &&
          !$scope.isOTTAdGroup
        ) {
          $scope.tabs.push($scope.creativeTypeTabs['HTML5']);
        }
        if ('NATIVE' in $scope.creativeTypeCMSConfigs && $scope.hasNativeAdsSupport) {
          $scope.tabs.push($scope.creativeTypeTabs['NATIVE']);
        }
        $scope.setCMSConfig();
      }
    });

  // set the tab and size if we have clicked on existing creative
  if (data.creative) {
    $scope.clickedCreativeType = data.creative.creativeType;
    $scope.creativeInterstitial = !!data.creative.creativeInterstitial;
    $scope.currentTab = $scope.creativeTypeTabs[data.creative.creativeType.toUpperCase()];

    var i = data.creative.creativeInterstitial ? 1 : 0;

    $scope.size_obj = {
      size_model: data.creative.creativeSize + '_' + i,
      size_key: data.creative.creativeSize + '_' + i,
      size: data.creative.creativeSize
    };

    $scope.refreshAvailableSizes($scope.clickedCreativeType);
  }

  $scope.updateIframeArr = function() {
    if ($scope.creative.creativeType !== 'HTML5') {
      return;
    }

    if ($scope.creative.cmsConfig.distanceColor != 'none') {
      if ($scope.creative.cmsConfig.name) {
        $scope.creative.cmsConfig.distanceColor = $scope.creative.cmsConfig.businessNameColor;
      } else if ($scope.creative.cmsConfig.caption) {
        $scope.creative.cmsConfig.distanceColor = $scope.creative.cmsConfig.captionColor;
      } else {
        $scope.creative.cmsConfig.distanceColor = html5DefaultDistanceColor;
      }
    }

    $scope.countCharactersLeft();

    creativeTemplatesFactory
      .getHtml5BannerPreview(
        $scope.creative.cmsConfig.name,
        $scope.creative.cmsConfig.caption,
        $scope.creative.cmsConfig.backgroundColor,
        $scope.creative.cmsConfig.backgroundImage,
        $scope.creative.cmsConfig.distanceToggle,
        $scope.creative.cmsConfig.logoImage,
        $scope.creative.cmsConfig.scrollingTextToggle,
        $scope.creative.cmsConfig.businessNameColor,
        $scope.creative.cmsConfig.captionColor,
        $scope.creative.cmsConfig.distanceColor
      )
      .then(function(renderedHtml5Preview) {
        $scope.html5BannerPreviewHtml = renderedHtml5Preview;
        $scope.creative.creativeType = 'HTML5';
        $scope.creative.creativeSize = '320x50';
        $scope.creative.cmsRevisionId = html5RevisionId;
        $scope.creative.cmsConfig.themeId = html5ThemeId;
      });
  };

  function validateVideo(file) {
    const allowedFileTypes = ['mp4', 'mov'];
    if (file) {
      if (file.size / 1024 ** 2 > $scope.fileSizeAllowance) {
        return false;
      }
      if (!allowedFileTypes.includes(file.getExtension())) {
        return false;
      }
    }
    return true;
  }

  $scope.onVideoAdded = function(file, _event, flow) {
    if (!validateVideo(file)) {
      $scope.uploadingVideo = false;
      $scope.$parent.videoCreativeModalErrorMsg = 'Oops! Something went wrong. Check the Video Spec.';
      return false;
    }
    $scope.uploadingVideo = true;
    return true;
  };

  $scope.videoUploadSuccess = function(message) {
    $scope.creative.video_transcoder = {
      s3_file_name: JSON.parse(message || {}).s3_path
    };
    $scope.uploadingVideo = false;
    $scope.$parent.videoCreativeModalErrorMsg = '';
  };

  $scope.onVideoUploadError = function() {
    $scope.uploadingVideo = false;
    $scope.$parent.videoCreativeModalErrorMsg = 'Oops! Something went wrong. Check the Video Spec.';
  };
  $scope.uploadingZipFile = false;
  $scope.isHTML5File = false;
  $scope.$on('flowUploadStarted', function(event, flow = 'flow', flowFile) {
    event.preventDefault(); //prevent file from uploading
    if ($scope.uploader[flow].files.length) {
      const filetype = $scope.uploader[flow].files[0].file.type;
      if (filetype === 'application/zip' || filetype === 'application/x-zip-compressed') {
        $scope.isHTML5File = true;
        $scope.uploader[flow].opts.target = `/data/upload?subsession=${window.subsession}&type=html5`;
      }
    }
  });

  $scope.onFileAdded = function(file, $event, flow = 'flow', availableSizes = $scope.availableSizes) {
    $scope.uploader[flow].files.length = 0;
    const logoImage = 'logoImage';
    const backgroundImage = 'backgroundImage';
    const nativeIconImage = 'iconImage';
    const nativeMainImage = 'mainImage';
    if (file.getExtension() == 'zip' && $scope.featureFlagFactory.isFeatureEnabled('HTML5_CREATIVE')) {
      $scope.uploadingZipFile = true;
      $scope.creative.creativeType = 'HTML5_NEW';
      $scope.creative.creativeSize = '320x50';
      $scope.notASize = false;
      $scope.isHTML5File = true;
      return true;
    } else if (file.getExtension() == 'zip' && !$scope.featureFlagFactory.isFeatureEnabled('HTML5_CREATIVE')) {
      return false;
    }
    if (file.file.dimensions) {
      return true;
    }
    var fileReader = new FileReader();
    fileReader.readAsDataURL(file.file);
    fileReader.onload = function(event) {
      var img = new Image();
      img.onload = function() {
        $timeout(function() {
          file.file.dimensions = {
            width: img.width,
            height: img.height
          };

          /*
                        TODO: need to rethink this part of finding a different size
                         */
          var foundCloseSize = false;
          var sizeCount = 0;
          var foundSizes = [];
          // iterate through all sizes to found interstitial one
          availableSizes.forEach(function(size) {
            if (
              flow === logoImage &&
              (file.file.dimensions.width < $scope.textBannerLogoImageSizes[0].width ||
                file.file.dimensions.height < $scope.textBannerLogoImageSizes[0].height)
            ) {
              return;
            }
            const widthRatio = size.width / file.file.dimensions.width;
            const heightRatio = size.height / file.file.dimensions.height;
            if (widthRatio == heightRatio) {
              foundCloseSize = true;
              sizeCount += 1;
              foundSizes.push(size);
            }
          });

          if (foundCloseSize) {
            $scope.uploader[flow].addFile(file.file);

            var foundSizeSingleItem = foundSizes[0];
            $scope.creative.creativeSize = foundSizeSingleItem.size;

            if (file.file.dimensions.width !== foundSizeSingleItem.width) {
              $scope.creative.creativeSizeRatio = foundSizeSingleItem.width / file.file.dimensions.width;
            } else {
              $scope.creative.creativeSizeRatio = 1;
            }

            var interstitialSum = 0;
            foundSizes.forEach(function(sizeItem) {
              interstitialSum += sizeItem.interstitial;
            });

            /*
                            We add up all the interstitials. If the sum equals all possible sizes we found.
                            This means the creative can only be interstitial.

                            If the sum is not equal, then the creative can be optionally interstitial
                             */
            $scope.creative.creativeInterstitial = false;
            $scope.creativeInterstitial = false;
            $scope.isSizeInterstitial = false;

            if (interstitialSum) {
              if (interstitialSum != foundSizes.length) {
                $scope.isSizeInterstitial = true;
                $scope.creative.creativeInterstitial = false;
                $scope.creativeInterstitial = false;
              } else {
                $scope.isSizeInterstitial = false;
                $scope.creative.creativeInterstitial = true;
                $scope.creativeInterstitial = true;
              }
            }

            $('#creative-container-banner-container').css({
              width: file.file.dimensions.width,
              height: file.file.dimensions.height
            });
            if (foundSizeSingleItem.width > 728) {
              $('#creative-container-banner-container').css({
                overflow: 'auto'
              });
            }
          }

          if (!foundCloseSize) {
            if (flow == backgroundImage) {
              $('.background-image-error-msg').text('Invalid image dimension. It should be 320x50 or proportional.');
            }
            if (flow == logoImage) {
              $('.logo-error-msg').text('Invalid image dimension. It should be 40x40 or proportional.');
            }
            if (flow == nativeIconImage) {
              $scope.nativeIconImageNotASize = true;
            }
            if (flow == nativeMainImage) {
              $scope.nativeMainImageNotASize = true;
            }
            $scope.notASize = true;
            $scope.hasNoImage = false;
            return;
          }
        });
      };
      $scope.notASize = false;
      if (flow == nativeIconImage) {
        $scope.nativeIconImageNotASize = false;
      }
      if (flow == nativeMainImage) {
        $scope.nativeMainImageNotASize = false;
      }
      if (typeof fileReader.result === 'string') img.src = fileReader.result;
    };
    return false;
  };

  $scope.fileAdded = function($flow, flowFile, message, imageToUpdate = 'bannerUrl') {
    const s3_path = JSON.parse(message).s3_path;
    $scope.hasNoImage = false;
    $scope.uploadingZipFile = false;
    const backgroundImage = 'backgroundImage';
    if (imageToUpdate == backgroundImage) {
      $('.background-image-error-msg').text('');
    }
    if (imageToUpdate === 'logoImage') {
      $('.logo-error-msg').text('');
    }
    if ($scope.creative.creativeType === 'HTML5_NEW') {
      $scope.creativeInterstitial = false;
      $scope.creative.html5_creative = {
        s3_file_name: s3_path
      };
      $scope.creative.cmsConfig['s3_file_name'] = s3_path;
    } else {
      $scope.creative.cmsConfig[imageToUpdate] = window.mediaCdnUrl + s3_path;
      $scope.creative.s3_file_name = s3_path;
    }
    if (
      $scope.creative.creativeType === 'HTML5' &&
      imageToUpdate == backgroundImage &&
      $scope.creative.cmsConfig.backgroundColor
    ) {
      $scope.creative.cmsConfig.backgroundColor = '';
    }
    if ($scope.creative.creativeType !== 'NATIVE' && $scope.creative.creativeType !== 'HTML5') {
      clearImage();
    }
  };

  if ($scope.creative.cmsConfig.customText) {
    $scope.distanceBox.distanceText = $scope.creative.cmsConfig.customText;
  }
  if ($scope.creative.cmsConfig.cssCustomText) {
    var splitBySemiColons = $scope.creative.cmsConfig.cssCustomText.split(';');
    for (var key in $scope.distanceBox.css) {
      var isKeyPresent = false;
      for (var i = 0; i < splitBySemiColons.length; i++) {
        var keyvalue = splitBySemiColons[i].split(':');

        if (key === keyvalue[0]) {
          $scope.distanceBox.css[key] = keyvalue[1];
          isKeyPresent = true;
        }
      }

      if (!isKeyPresent) {
        delete $scope.distanceBox.css[key];
      }
    }
  }

  $scope.saveCreative = function() {
    let creativeCopy = angular.copy($scope.creative);

    if (['IMAGE', 'HTML5_NEW'].includes(creativeCopy.creativeType)) {
      if (creativeCopy.creativeType === 'IMAGE') {
        creativeCopy.cmsConfig.customText = $scope.distanceBox.distanceText;

        // if the size of the image banner is of double density, we need to scale down the css properties
        let cssCopy = angular.copy($scope.distanceBox.css);
        reduceTheDimensionAccordingToScale(creativeCopy.creativeSizeRatio, cssCopy);

        creativeCopy.cmsConfig.cssCustomText = generalUtilsFactory.dictCssToStringCss(cssCopy);
      }

      if (creativeCopy.useLandingPageSwitchSet) {
        if (
          $scope.landingPageMethod &&
          $scope.landingPageMethod.validate !== null &&
          $scope.landingPageMethod.validate() === false
        ) {
          return;
        } else if (creativeCopy.lpTypeName === 'Image Landing Page') {
          $scope.landingPageMethod.beforeSave();
          creativeCopy.lpConfig = angular.copy($scope.creative.lpConfig);
        }
      }

      creativeCopy.useLandingPage = creativeCopy.useLandingPageSwitchSet;
      if (creativeCopy.useLandingPage) {
        creativeCopy.landingPageUrl = null;
      } else {
        creativeCopy.lpConfig = null;
      }
    } else {
      creativeCopy.useLandingPage = false;
      creativeCopy.lpConfig = null;
    }

    if (creativeCopy.creativeType === 'HTML5_NEW' && !creativeCopy.cmsConfig.s3_file_name) {
      creativeCopy.cmsConfig.s3_file_name = creativeCopy?.html5_creative?.s3_file_name;
    }

    if (creativeCopy.usePlacementDates) {
      creativeCopy.startDate = null;
      creativeCopy.endDate = null;
    }

    // Give all new creatives an initial rotation weight of 1
    if (!creativeCopy.weight) {
      creativeCopy.weight = 1;
    }

    $scope.progress = true;
    $scope.adGroup.tab = 'creatives';
    $scope.adGroupBackUp.creatives = [creativeCopy];
    campaignManagerFactory
      .saveAdGroupCreatives(campaignManagerFactory.selectedCampaign.id, $scope.adGroupBackUp)
      .then(
        function() {
          $uibModalInstance.close();
          if (creativeCopy.creativeType === 'VIDEO' && $scope.creative.video_transcoder.s3_file_name) {
            const modalSetting = {
              hideFooter: true,
              title: 'Video Processing',
              message:
                'Your video is being processed. Edits to the creative cannot be made until the job is complete. You can safely close this dialogue box and navigate between other pages in Ads Manager while the job progresses. In the event of a failed upload, you will receive an email notification.'
            };
            modalFactory.simpleAlert(modalSetting, {});
          } else if (
            creativeCopy.creativeType === 'HTML5_NEW' &&
            $scope.creative.html5_creative.s3_file_name &&
            !($scope.isHTML5CreativeJobDone($scope.creative) || $scope.isHTML5JobInProgress($scope.creative))
          ) {
            const modalSetting = {
              hideFooter: true,
              title: 'HTML5 Creative Processing',
              message:
                'Your HTML5 creative is being processed. Edits to the creative cannot be made until the job is complete. You can safely close this dialogue box and navigate between other pages in Ads Manager while the job progresses.'
            };
            modalFactory.simpleAlert(modalSetting, {});
          }
        },
        function(msg) {
          $scope.$parent.creativeModalErrorMsg = msg.join('\n');
        }
      )
      .finally(function() {
        $scope.progress = false;
        clearCreativeSearchParam();
      });
  };

  $scope.cancel = function() {
    clearCreativeSearchParam();
    $uibModalInstance.dismiss('cancel');
  };

  $scope.isActiveTab = function(tab) {
    return tab == $scope.currentTab;
  };

  $scope.$watchGroup(
    [
      'creative.cmsConfig.name',
      'creative.cmsConfig.caption',
      'creative.cmsConfig.backgroundColor',
      'creative.cmsConfig.backgroundImage',
      'creative.cmsConfig.distanceToggle',
      'creative.cmsConfig.logoImage',
      'creative.cmsConfig.scrollingTextToggle',
      'creative.cmsConfig.businessNameColor',
      'creative.cmsConfig.captionColor'
    ],
    function() {
      $scope.updateIframeArr();
    }
  );

  $scope.changeSwitch = function() {
    $scope.creative.creativeInterstitial = !$scope.creativeInterstitial;
  };

  $scope.sizeSelect = function() {
    var size_and_interstitial = $scope.size_obj.size_model.split('_');
    var interstitial = !!parseInt(size_and_interstitial[1]);
    $scope.creative.creativeSize = size_and_interstitial[0];
    $scope.creative.creativeInterstitial = interstitial;
    $scope.removeIUrlImage();
  };

  function getInterstitial(creativeSize) {
    var count = 0;
    for (var i = 0; i < $scope.availableSizes.length; i++) {
      if ($scope.availableSizes[i].size == creativeSize) {
        count += 1;
      }
    }

    if (count == 2) {
      return true;
    }
    return false;
  }

  $scope.cleanupSizeErrors = function() {
    $scope.notASize = false;
    $scope.nativeIconImageNotASize = false;
    $scope.nativeMainImageNotASize = false;
  };

  $scope.changeTab = function(tab) {
    $scope.onImageTabChange();
    var oldTab = $scope.currentTab;
    $scope.$parent.creativeModalErrorMsg = '';
    $scope.$parent.videoCreativeModalErrorMsg = '';
    $scope.scriptTagErrorMsg = '';
    $scope.currentTab = tab;
    if ($scope.currentTab.type !== oldTab.type) {
      $scope.cleanupSizeErrors();
    }
    if (tab.type === 'IMAGE' && $scope.isHTML5File) {
      $scope.creative.creativeType = 'HTML5_NEW';
    } else if (tab.type === 'IMAGE' && !$scope.isHTML5File) {
      $scope.creative.creativeType = 'IMAGE';
    } else {
      $scope.creative.creativeType = $scope.currentTab['type'];
    }
    if ($scope.creative.creativeSize) {
      $scope.isSizeInterstitial = getInterstitial($scope.creative.creativeSize);
    }
    $scope.refreshAvailableSizes($scope.creative.creativeType);
    if (!$scope.creative.id) {
      if ($scope.creative.creativeType === 'HTML5_NEW') {
        $scope.creative.cmsConfig = $scope.creativeTypeCMSConfigs['HTML5_NEW'];
      } else {
        $scope.creative.cmsConfig = $scope.creativeTypeCMSConfigs[$scope.currentTab['type']];
      }

      if ($scope.creative.creativeType === 'VIDEO') {
        $scope.creative.creative_api = 'NONE';
      } else if ($scope.creative.creativeType === 'SCRIPT') {
        $scope.creative.creative_api = $scope.isNewDesktopAdgroup ? 'NONE' : 'MRAID2';
      } else if ($scope.creative.creativeType === 'IMAGE') {
        $scope.creative.creative_api = $scope.isNewDesktopAdgroup ? 'NONE' : 'MRAID2';
      }
    }
    $scope.hasNoImage = false;
    if ($scope.creative.creativeType == 'VIDEO' || $scope.isSizeInterstitial) {
      $scope.isSizeInterstitial = true;
    } else {
      $scope.isSizeInterstitial = false;
      $scope.creativeInterstitial = false;
    }

    if (!$scope.creative.name || $scope.creative.name === 'Ads Manager - ' + oldTab.tabTitle.toUpperCase()) {
      $scope.creative.name = 'Ads Manager - ' + $scope.currentTab['tabTitle'].toUpperCase();
    }
  };

  function clearCreativeSearchParam() {
    $location.search('creativeId', null);
  }

  $scope.$on('$destroy', function() {
    if ($uibModalInstance != null) {
      $uibModalInstance.opened.then(function() {
        clearCreativeSearchParam();
        $uibModalInstance.dismiss('cancel');
      });
    }
  });

  /**
   * @returns { { valid: true, reason?: null } | { valid: false, reason: string[] } }
   */
  function verifyScriptTag() {
    var atLeastOneMacros = { '%%CLICKURL%%': false, '%%ENCODED_CLICKURL%%': false };
    var optionalMacros = ['%%IMPRESSIONURL%%', '%%TIMESTAMP%%'].concat(Object.keys($scope.macros));
    var script = $scope.creative.cmsConfig.script_tag;
    var i;
    var key;
    var item;
    var unquotedItem = '';
    var re = /"?%%[A-Z_0-9]*%%"?/g;
    var match;
    var userMacros = [];
    let valid = true;
    var errors = {
      invalid: {
        flag: false,
        message: 'Found invalid macro tags: '
      },
      missing: {
        flag: false,
        message: 'Missing macro tags: '
      }
    };
    var messages = [];

    if (!script) {
      return { valid: false, reason: ['Script tag is empty.'] };
    }

    while ((match = re.exec(script)) !== null) {
      userMacros.push(match[0]);
    }

    for (i = 0; i < userMacros.length; i += 1) {
      item = userMacros[i];

      if (!item) {
        continue;
      }

      if (item[0] === '"' && item[item.length - 1] === '"') {
        unquotedItem = item.substring(1, item.length - 1);
      } else if (item[0] === '"' && item[item.length - 1] !== '"') {
        unquotedItem = item.substring(1);
      } else if (item[0] !== '"' && item[item.length - 1] === '"') {
        unquotedItem = item.substring(0, item.length - 1);
      }

      if (atLeastOneMacros.hasOwnProperty(item)) {
        atLeastOneMacros[item] = true;
      } else if (atLeastOneMacros.hasOwnProperty(unquotedItem)) {
        atLeastOneMacros[unquotedItem] = true;
      } else if (!optionalMacros.includes(item)) {
        valid = false;
        errors.invalid.flag = true;
        errors.invalid.message += item + ' ';
      }
    }

    var isRequiredMacroPresent = false;
    var missingKeyList = [];
    for (key in atLeastOneMacros) {
      if (atLeastOneMacros.hasOwnProperty(key)) {
        missingKeyList.push(key);
        if (atLeastOneMacros[key]) {
          isRequiredMacroPresent = true;
          break;
        }
      }
    }

    if (!isRequiredMacroPresent) {
      valid = false;
      errors.missing.flag = true;
      errors.missing.message += missingKeyList.join(', ') + ' ';
    }

    if (!valid) {
      for (key in errors) {
        if (errors.hasOwnProperty(key) && errors[key].flag) {
          messages.push(errors[key].message);
        }
      }

      return { valid, reason: messages };
    }

    return { valid, reason: null };
  }

  $scope.onIurlImageFileAdded = function(file, $event) {
    $scope.iurlUploader.flow.files.length = 0;
    if (file.file.dimensions) {
      file.dimensions = file.file.dimensions;
      if (file.dimensions.width < 100) {
        alert('invalid dimensions');
        return false;
      }
      return true;
    }
    var fileReader = new FileReader();
    fileReader.readAsDataURL(file.file);
    fileReader.onload = function(event) {
      var img = new Image();
      img.onload = function() {
        $timeout(function() {
          file.file.dimensions = {
            width: img.width,
            height: img.height
          };

          var size = $scope.creative.creativeSize.split('x');
          const widthRatio = file.file.dimensions.width / parseInt(size[0]);
          const heightRatio = file.file.dimensions.height / parseInt(size[1]);
          if (widthRatio != heightRatio) {
            $scope.notASize = true;
            return;
          }

          $scope.iurlUploader.flow.addFile(file.file);
          $('#creative-container-iurl-container').css({
            width: file.file.dimensions.width,
            height: file.file.dimensions.height
          });
          if (size[0] > 728) {
            $('#creative-container-iurl-container').css({
              overflow: 'auto'
            });
          }
        });
      };
      $scope.notASize = false;
      if (typeof fileReader.result === 'string') img.src = fileReader.result;
    };
    return false;
  };

  $scope.iurlFileAdded = function($flow, flowFile, message) {
    $scope.creative.creativeIUrl = window.mediaCdnUrl + JSON.parse(message).s3_path;
    $scope.hasNoImage = false;
  };

  $scope.removeIUrlImage = function() {
    $scope.creative.creativeIUrl = undefined;
    if ($scope.iurlUploader.flow && $scope.iurlUploader.flow.files) {
      $scope.iurlUploader.flow.files.length = 0;
    }
  };

  $scope.removeBannerImage = function() {
    if ($scope.uploader.flow && $scope.uploader.flow.files) {
      $scope.uploader.flow.files.length = 0;
    }
    if ($scope.creative.creativeType === 'HTML5_NEW') {
      $scope.creative.html5_creative = {
        s3_file_name: ''
      };
      $scope.creative.cmsConfig.html_tag = '';
      $scope.creative.creativeType = 'IMAGE';
      $scope.isHTML5File = false;
      $scope.refreshAvailableSizes($scope.creative.creativeType);
    } else {
      $scope.creative.cmsConfig.bannerUrl = '';
      clearImage();
    }
  };

  $scope.removeImage = function(imageType, flowName, event) {
    event.stopPropagation();
    const backgroundImage = 'backgroundImage';
    const logoImage = 'logoImage';
    $scope.creative.cmsConfig[imageType] = '';

    if ($scope.uploader[flowName] && $scope.uploader[flowName].files) {
      $scope.uploader[flowName].files.length = 0;
    }
    if (imageType === logoImage) {
      $('#uploadLogoImage-text').text('Upload Logo (40x40)');
    }
    if (imageType === backgroundImage) {
      $scope.creative.cmsConfig.backgroundColor = html5DefaultBackgroundColor;
    }
  };

  $scope.countCharactersLeft = function() {
    $scope.charactersRemaining = $scope.html5CaptionMaxLength - $scope.creative.cmsConfig.caption.length;
  };

  var clearImage = function() {
    $scope.creative.cmsConfig.cssCustomText = '';
    $scope.creative.cmsConfig.customText = '';

    // Reset overlay
    $scope.distanceBox.distanceText = '';
    $scope.distanceBox.css = {
      top: '0px',
      left: '0px',
      bottom: '0px',
      right: '0px',
      height: '50px',
      width: '50px',
      color: '#000000',
      'font-family': 'Helvetica',
      'font-size': '12px',
      'text-align': 'center',
      'text-shadow': 'none',
      'font-style': 'normal',
      'font-weight': 'normal'
    };

    // close text editor
    $scope.showTextEditor = false;
    $('.' + $scope.distanceBox.id).remove();
  };

  $scope.showTextEditorFunction = function() {
    $scope.showTextEditor = !$scope.showTextEditor;

    $scope.creative.cmsConfig.cssCustomText = generalUtilsFactory.dictCssToStringCss($scope.distanceBox.css);
    $scope.creative.cmsConfig.customText = $scope.distanceBox.distanceText;

    if (!$scope.showTextEditor) {
      $('.' + $scope.distanceBox.id).remove();
    }
  };

  // callback method to fetch updated adgroup after successfully adding creative assets
  $scope.fetchUpdatedAdgroup = async () => {
    const campaignId = campaignManagerFactory.selectedCampaign ? campaignManagerFactory.selectedCampaign.id : null;

    $scope.progress = true;
    try {
      await campaignManagerFactory.loadCampaignWithAdGroups(campaignId, $scope.adGroup.id);
    } catch (e) {
      universalAlert.showAlert('Something went wrong when loading the updated list of creative(s), please try again');
    } finally {
      $scope.progress = false;
      $uibModalInstance.close();
    }
  };

  $scope.isVideoJobInProgress = creative =>
    [
      CreativeVideoTranscoderStatusResponse_status.NEW,
      CreativeVideoTranscoderStatusResponse_status.IN_PROGRESS
    ].includes(creative?.video_transcoder?.status);

  $scope.isString = value => typeof value === 'string';

  $scope.isHTML5JobInProgress = creative =>
    [CreativeHTML5StatusResponse_status.NEW, CreativeHTML5StatusResponse_status.IN_PROGRESS].includes(
      creative?.html5_creative?.status
    );

  $scope.isHTML5CreativeJobFailed = creative => {
    return (
      creative?.creativeType === 'HTML5_NEW' &&
      creative?.html5_creative?.status === CreativeHTML5StatusResponse_status.FAILED
    );
  };

  $scope.isHTML5CreativeJobDone = creative => {
    return (
      creative?.creativeType === 'HTML5_NEW' &&
      creative?.html5_creative?.status === CreativeHTML5StatusResponse_status.DONE
    );
  };

  $scope.showUploadMenu = creative => {
    if (creative.creativeType === 'HTML5_NEW') {
      if (typeof $scope.creative.html5_creative === 'undefined') {
        return false;
      }
      return (
        $scope.creative.html5_creative.s3_file_name === '' &&
        !$scope.isHTML5JobInProgress(creative) &&
        !$scope.isHTML5CreativeJobDone(creative)
      );
    }
    return (!creative.cmsConfig.bannerUrl && !$scope.uploader.flow.files.length) || $scope.notASize;
  };

  $scope.hideCloseButton = creative => {
    if (creative.creativeType === 'IMAGE') {
      return !creative.cmsConfig.bannerUrl;
    } else {
      return $scope.creative.html5_creative?.status === CreativeHTML5StatusResponse_status.IN_PROGRESS;
    }
  };

  $scope.isSaveDisabled = creative =>
    $scope.progress ||
    $scope.isVideoJobInProgress(creative) ||
    $scope.isHTML5JobInProgress(creative) ||
    $scope.uploadingZipFile;

  $scope.progressBar = creative =>
    !$scope.isHTML5JobInProgress(creative) &&
    creative.creativeType == 'HTML5_NEW' &&
    !$scope.uploadingZipFile &&
    $scope.creative.html5_creative.s3_file_name &&
    !$scope.isHTML5CreativeJobDone(creative) &&
    !$scope.isHTML5CreativeJobFailed(creative);

  $scope.animatedProgressBar = creative =>
    $scope.isHTML5JobInProgress(creative) && creative.creativeType == 'HTML5_NEW' && !$scope.uploadingZipFile;
}
