modalWelcomeController.$inject = ['$uibModalInstance'];

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 */
export function modalWelcomeController($uibModalInstance) {
  var vm = this;

  vm.close = close;

  function close() {
    $uibModalInstance.dismiss();
  }
}
