import * as actionSheets from './actionSheets';
import * as autocompleteDropDown from './autocompleteDropDown';
import * as charts from './charts';
import { contextualHeader } from './contextualHeader/contextual-header';
import { xadDaterangeSelector } from './dateRangeSelector/xad-daterange-selector';
import { gtAutocomplete } from './gtAutoComplete/gt-autocomplete.dir';
import { gtLoadingBar } from './gtLoadingBar/gt-loading-bar.dir';
import { gtSpinner } from './gtSpinner/gt-spinner.dir';
import { xadListInput } from './listInput/list_input';
import * as modal from './modal';
import { multiSelectDropdown } from './multiSelectDropDown/multi-select-dropdown.dir';
import { xadSelectionList } from './xadSelectionList/xad-selection-list';
import { components as buttonComponents } from './buttons';
import { includeReplace } from './includeReplace';
import { components as alertComponents } from './alerts';
import { gridList } from './gridList/gridList';
import { components as creativeRepoTab } from '../pages/creative_repo/creative_repo_tab';
import { navElement } from '../pages/adgroup/nav_element';
import { reactAngularComponents } from './reactComponents';
export const controllers = {
  ...modal.controllers
};

export const factories = {
  ...modal.factories
};

export const directives = {
  ...actionSheets.directives,
  ...autocompleteDropDown.directives,
  ...charts.directives,
  contextualHeader,
  xadDaterangeSelector,
  gtAutocomplete,
  gtLoadingBar,
  gtSpinner,
  xadListInput,
  multiSelectDropdown,
  xadSelectionList,
  includeReplace,
  navElement
};

export const components = {
  ...buttonComponents,
  ...alertComponents,
  ...creativeRepoTab,
  gridList
};

export { reactAngularComponents };
