AccountSelectedTabsCtrl.$inject = ['$scope', 'modalFactory', 'featureFlagFactory'];

export function AccountSelectedTabsCtrl($scope, modalFactory, featureFlagFactory) {
  $scope.activeTab = 0;

  $scope.tabs = {
    Settings: {
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.selected_account_view.html',
      msg: 'account.selected.settings.loaded'
    },
    'Tracking Pixels': {
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.tracking-pixels.html',
      msg: 'account.selected.trackingPixel.loaded'
    }
  };

  if (featureFlagFactory.isFeatureEnabled('CHECK_GROWTH')) {
    $scope.tabs['Points of Sale'] = {
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.points-of-sale.html'
    };

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('authSuccess') && queryParams.get('pos')) {
      $scope.activeTab = Object.keys($scope.tabs).length - 1;
    }
  }

  $scope.currentTab = $scope.tabs['Settings'];

  $scope.changeTab = function(tabName) {
    if ($scope.currentTab == $scope.tabs[tabName]) {
      return;
    }
    $scope.currentTab = $scope.tabs[tabName];
    $scope.$broadcast($scope.currentTab['msg']);
  };

  $scope.newPixelModal = function() {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.trackingPixelAccountModal.html',
      controller: 'trackingPixelAccountModalController',
      keyboard: false,
      backdrop: 'static',
      size: 'xl'
    };

    modalFactory.createModal(modalOptions).then(function(newPixel) {
      if (newPixel) {
        $scope.$broadcast('newPixelCreated', newPixel);
      }
    });
  };
}
