import * as React from 'react';
import MaterialTooltip from '@material-ui/core/Tooltip';

interface TooltipInterface {
  title: string;
  children: React.ReactElement;
}

export const Tooltip = React.forwardRef(function({ title, children }: TooltipInterface, ref) {
  return (
    <MaterialTooltip title={title} ref={ref}>
      {children}
    </MaterialTooltip>
  );
});

Tooltip.displayName = 'Tooltip';
