xadLineChart.$inject = ['$timeout', '$filter', 'generalUtilsFactory', 'chartUtilsFactory'];

/**
 * @param {ng.ITimeoutService} $timeout
 * @param {ng.IFilterService} $filter
 * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @param {import('../../../factories/types').ChartUtilsService} chartUtilsFactory
 */
export function xadLineChart($timeout, $filter, generalUtilsFactory, chartUtilsFactory) {
  var DEFAULT_METRIC = 'impression';
  return {
    restrict: 'E',
    replace: true,
    scope: {
      active: '=',
      changed: '=',
      chartProgress: '=',
      chartClass: '=',
      chartData: '=',
      chartMessage: '=',
      accountCurrency: '=',
      forecastedChartData: '=',
      seriesOptions: '=',
      metrics: '=',
      doubleYAxis: '=',
      metricsChanged: '=',
      lineChartType: '@' // will default to "daily", can also be "dow" or "tod"
    },
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.xad-line-chart-multi.html',
    controller: /** @ngInject */ function($scope) {
      if (angular.isUndefined($scope.type)) {
        $scope.type = 'daily';
      }
      $scope.yAxisMetrics = [{ value: 'impression' }, { value: 'visits' }];
      $scope.yAxisTitles = ['Impressions', 'Visits'];
      Highcharts.setOptions({
        lang: {
          thousandsSep: ','
        }
      });
    },
    link: function(scope, element, attrs) {
      /** @type {(Highcharts.SeriesLineOptions & { data: [number, number][] })[]} */ var seriesOptions = [];
      var tooltipFormat = function() {
        var color = this.series.color;
        var name = this.series.name;
        var dataLabel = 'number';
        var value = this.y;

        scope.yAxisMetrics.forEach(function(item) {
          if (scope.seriesOptions.hasOwnProperty(item.value) && scope.seriesOptions[item.value].name === name) {
            dataLabel = scope.seriesOptions[item.value].dataLabel;
          }
        });

        if (dataLabel === 'percent') {
          value = $filter('percentage')(value, 2);
        } else {
          if (value < 1) {
            value = $filter('number')(value, 1);
          } else {
            value = $filter('number')(value, 0);
          }
        }

        value = ['Total Spend', 'Sales'].includes(name) ? `${value} ${scope.accountCurrency}` : value;

        return (
          '<span style="color:' +
          color +
          '">' +
          name +
          '<span style="font-weight: 700">: ' +
          value +
          '</span></span></br>'
        );
      };

      var colors = ['#65c5a5', '#3c89bb'];

      var yAxisLabelFn = function() {
        return generalUtilsFactory.shorthandNumber(this.value, 0, 1);
      };

      var yAxisFloatNumberLabelFn = function() {
        return generalUtilsFactory.shorthandNumber(this.value, 1, 1);
      };

      var yAxisLabelPercentFn = function() {
        return $filter('percentage')(this.value, 2);
      };

      function createChart() {
        var config = {
          xAxis: {
            /** @type {string | null} */
            type: null,
            labels: {}
          }
        };
        if (scope.lineChartType === 'daily') {
          config.xAxis.type = 'datetime';
          config.xAxis.labels = {
            formatter: function() {
              return Highcharts.dateFormat('%b %e', this.value);
            }
          };
          config.xAxis.dateTimeLabelFormats = {
            day: '%e %b'
          };
          config.xAxis.tickPositioner = function() {
            var dataLength = seriesOptions[0].data.length;

            if (dataLength === 0) {
              return [];
            }

            if (dataLength === 1) {
              return [Math.floor(this.dataMin)];
            }

            dataLength--;

            var positions = [],
              tick = Math.floor(this.dataMin),
              increment = Math.ceil((this.dataMax - this.dataMin) / dataLength);

            var MAX_LABELS = 14;
            var k = Math.ceil(dataLength / MAX_LABELS);

            if (this.dataMax !== null && this.dataMin !== null) {
              for (tick; tick - k * increment <= this.dataMax; tick += k * increment) {
                positions.push(tick);
              }
            }

            return positions;
          };
        } else if (scope.lineChartType === 'dow') {
          config.xAxis.type = 'category';
          config.xAxis.categories = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
          config.xAxis.tickmarkPlacement = 'on';
        } else if (scope.lineChartType === 'tod') {
          config.xAxis.type = 'category';
          config.xAxis.categories = [
            '12 AM',
            '1 AM',
            '2 AM',
            '3 AM',
            '4 AM',
            '5 AM',
            '6 AM',
            '7 AM',
            '8 AM',
            '9 AM',
            '10 AM',
            '11 AM',
            '12 PM',
            '1 PM',
            '2 PM',
            '3 PM',
            '4 PM',
            '5 PM',
            '6 PM',
            '7 PM',
            '8 PM',
            '9 PM',
            '10 PM',
            '11 PM'
          ];
          config.xAxis.tickmarkPlacement = 'on';
        }

        var formatLabelFn = [];
        scope.yAxisMetrics.forEach(function(metrics) {
          var fn = yAxisLabelFn;

          if (metrics && metrics.value === 'spends_spent') {
            fn = yAxisFloatNumberLabelFn;
          }
          var dataLabel = scope.seriesOptions[metrics ? metrics.value : 'impression'].dataLabel;
          formatLabelFn.push(dataLabel && dataLabel === 'percent' ? yAxisLabelPercentFn : fn);
        });

        const yAxisMaxValues = chartUtilsFactory.getYAxisMaxValues(
          scope.lineChartType,
          seriesOptions,
          scope.seriesOptions,
          scope.yAxisMetrics
        );

        // Configure chart
        /** @type {Highcharts.Options} */
        var chartConfig = {
          title: {
            text: ''
          },
          chart: {
            marginBottom: 50,
            height: 370,
            style: {
              fontFamily: 'Poppins'
            },
            plotBorderWidth: 0
          },
          xAxis: {
            type: undefined,
            dateTimeLabelFormats: {
              day: undefined
            },
            categories: undefined,
            tickmarkPlacement: undefined,
            lineColor: '#0A3F36',
            lineWidth: 1,
            tickColor: '#FFFFFF',
            gridLineWidth: 1,
            gridLineColor: '#BCF1E9',
            gridLineDashStyle: 'Solid',
            startOnTick: false,
            endOnTick: false,
            gridZIndex: -1
          },
          yAxis: [
            {
              labels: {
                formatter: formatLabelFn[0],
                style: {
                  color: '#65c5a5',
                  fontSize: '14px'
                }
              },
              title: {
                align: 'high',
                text: scope.yAxisTitles[0],
                rotation: 0,
                offset: 10,
                y: -15,
                style: {
                  color: '#65c5a5'
                }
              },
              lineColor: '#bcf1e9',
              lineWidth: 1,
              gridLineDashStyle: 'ShortDash',
              gridZIndex: -1,
              plotLines: [
                {
                  value: 0,
                  width: 2,
                  color: 'silver',
                  zIndex: -1
                }
              ],
              tickAmount: yAxisMaxValues[0]?.amount,
              min: yAxisMaxValues[0]?.min,
              max: yAxisMaxValues[0]?.max
            },
            {
              labels: {
                formatter: formatLabelFn[1],
                style: {
                  color: '#3c89bb',
                  fontSize: '14px'
                }
              },
              title: {
                align: 'high',
                text: scope.yAxisTitles[1],
                rotation: 0,
                offset: 10,
                y: -15,
                style: {
                  color: '#3c89bb'
                }
              },
              lineColor: '#bcf1e9',
              lineWidth: 1,
              gridLineDashStyle: 'ShortDash',
              gridZIndex: -1,
              plotLines: [
                {
                  value: 0,
                  width: 2,
                  color: 'silver',
                  zIndex: -1
                }
              ],
              tickAmount: yAxisMaxValues[1]?.amount,
              min: yAxisMaxValues[1]?.min,
              max: yAxisMaxValues[1]?.max,
              opposite: true
            }
          ],
          legend: {
            enabled: false
          },
          plotOptions: {
            line: {
              marker: {
                symbol: 'circle'
              }
            }
          },
          tooltip: {
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderColor: '#BCF1E9',
            borderRadius: 10,
            padding: 24,
            pointFormatter: tooltipFormat,
            xDateFormat: '%A, %b %d, %Y',
            valueDecimals: 2,
            split: false,
            shared: true,
            style: {
              color: '#0A3F36',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '41.6px',
              fontFamily: 'Roboto',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.05)'
            }
          },
          series: scope.lineChartType === 'daily' ? fillZero(seriesOptions) : seriesOptions,
          lang: {
            noData:
              `<div style="width: 700px; height: 250px; background-color: #E9FAF8; color: #0A3F36; font-family: Roboto; font-size: 16px; font-weight: 400; line-height: 160%; display: flex;
            justify-content: center; border-radius: 8px; align-items: center; text-align: center">` +
              scope.chartMessage +
              `</div>`
          },
          noData: {
            useHTML: true
          },
          exporting: {
            enabled: true,
            scale: 1,
            buttons: {
              contextButton: {
                symbol: 'url(/ui/images/export-icon-download.svg)',
                menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF'],
                text: 'Download',
                verticalAlign: 'top',
                height: 40,
                symbolY: 20,
                symbolX: 20,
                y: -38,
                x: -145
              }
            },
            chartOptions: {
              chart: {
                marginTop: 50,
                marginRight: 130,
                marginBottom: 50,
                marginLeft: 130,
                width: 1000
              }
            }
          },
          credits: {
            enabled: false
          }
        };

        if (scope.lineChartType === 'daily') {
          const extractAverageSalesValue = chartData => chartData['avg_sales']?.[0]?.[1] ?? null;
          const averageSalesValue = extractAverageSalesValue(scope.chartData);

          // Add the plot line for pre-campaign average sales conditionally
          if (averageSalesValue || scope.chartData['total_sales']) {
            const plotLine = {
              value: averageSalesValue,
              width: averageSalesValue === 0 ? 3 : 2,
              color: '#3C89BB',
              dashStyle: 'shortdash',
              label: {
                text: `Pre-campaign average sales: <b>${generalUtilsFactory.shorthandNumber(averageSalesValue, 1, 1)} ${
                  scope.accountCurrency
                }</b>`,
                align: 'right',
                x: -2,
                y: -5,
                zIndex: 2,
                style: {
                  fontSize: '14px',
                  fontFamily: 'Poppins',
                  fontWeight: '400',
                  color: '#0A3F36',
                  lineHeight: '160%'
                }
              }
            };

            const totalSalesIndex = scope.yAxisMetrics.findIndex(metric => metric.value === 'total_sales');
            if (totalSalesIndex !== -1) {
              // Add the plot line to the corresponding yAxis
              chartConfig.yAxis[totalSalesIndex].plotLines.push(plotLine);
              chartConfig.yAxis[totalSalesIndex].min = Math.min(
                averageSalesValue,
                yAxisMaxValues[totalSalesIndex]?.min
              );
              chartConfig.yAxis[totalSalesIndex].max = Math.max(
                averageSalesValue,
                yAxisMaxValues[totalSalesIndex]?.max
              );
            }
          }
        }

        function getIndex(data, target) {
          for (var i = 0; i < data.length; i++) {
            if (isSameDay(new Date(data[i][0]), new Date(target))) {
              return i;
            }
          }
          return -1;
        }

        function isSameDay(day1, day2) {
          return (
            day1.getFullYear() === day2.getFullYear() &&
            day1.getMonth() === day2.getMonth() &&
            day1.getDate() === day2.getDate()
          );
        }

        /**
         * @param seriesOptions {(Highcharts.SeriesLineOptions & { data: [number, number][] })[]}
         *
         * For the first series in seriesOptions, we have such example data:
         * series(7): [[1517472000000, 5602], [1517558400000, 6364], [1517817600000, 5727],
         * [1517904000000, 6295],[1517990400000, 6227], [1518076800000, 6184], [1518163200000, 6146]]
         *
         * Between these two dates (1517558400000, 02/02/2018) and (1517817600000, 02/05/2018], there
         * are 3 days gap. So we want to use the fillZero function to fill in this gap. After we run that
         * function, that series should be changed as:
         * [[1517472000000, 5602], [1517558400000, 6364], [1517644800000, 0], [1517731200000, 0],
         * [1517817600000, 5727], [1517904000000, 6295],[1517990400000, 6227], [1518076800000, 6184],
         * [1518163200000, 6146]]
         *
         * Then we get the complete data without any gaps.
         * @returns {*}
         */
        function fillZero(seriesOptions) {
          for (var series of seriesOptions) {
            var data = series.data;
            /** @type {[number, number][]} */ var res = [];
            var pointInterval = 24 * 3600 * 1000;
            var start = data.length ? data[0][0] : 1;
            var end = data.length ? data[data.length - 1][0] : 0;

            for (start; start <= end; start += pointInterval) {
              res.push([start, 0]);
            }

            for (var i = 0; i < res.length; i++) {
              var indexRes = getIndex(data, res[i][0]);
              if (indexRes >= 0) {
                res[i][1] = data[indexRes][1];
              }
            }
            delete series.data;
            series.data = res;
          }
          return seriesOptions;
        }

        angular.merge(chartConfig, config);

        var chartContainer = element[0].querySelector('.chart');

        // render chart
        scope.chart = Highcharts.chart(chartContainer, chartConfig);

        $timeout(function() {
          scope.chart.reflow();
        });
      }

      function fillArrayWithLastItems(array, start) {
        /*
         * This function fills the empty space in the given `array` with the last item
         *
         * @param (array): an array object that stores objects
         * @param (start): where to start filling
         * */
        if (start < 1 || start >= array.length) {
          return;
        }

        var i;
        var last = array[start - 1];

        if (!last) {
          return;
        }

        for (i = start; i < array.length; i++) {
          if (array[i]) {
            continue;
          }
          if (angular.isObject(last)) {
            array[i] = angular.copy(last);
          } else {
            array[i] = last;
          }
        }
      }

      scope.changeMetrics = function() {
        var prop;

        for (prop in scope.seriesOptions) {
          if (scope.seriesOptions.hasOwnProperty(prop)) {
            scope.seriesOptions[prop].visible = false;
          }
        }

        // TODO: Need to see if we can remove this with some other changes.
        // Here we are handling an empty metric field. If a null value occurs in either of the two yAxisMetrics,
        // we set it to its default value, i.e., impressions and visits.
        for (let metricIndex in scope.yAxisMetrics) {
          if (scope.yAxisMetrics[metricIndex].value === null) {
            scope.yAxisMetrics = [{ value: 'impression' }, { value: 'visits' }];
            break;
          }
        }

        for (let metricIndex in scope.yAxisMetrics) {
          prop = scope.yAxisMetrics[metricIndex].value;
          scope.seriesOptions[prop].visible = true;
        }

        // need to get the new data first
        scope.metricsChanged();
      };

      scope.$watch('changed', function(newValue, oldValue) {
        if (newValue === true) {
          if (scope.chart) {
            scope.chart.destroy();
          }

          seriesOptions = [/** @type {any} */ (null), /** @type {any} */ (null)];

          /** @type {[{ value: string }, { value: string }]} */
          var yAxisMetrics = [/** @type {any} */ (null), /** @type {any} */ (null)];
          var yAxisTitles = ['', ''];
          var yAxisIndex = 0;
          var prop;
          var index;
          var i;

          // Set up series options
          for (prop in scope.chartData) {
            if (scope.chartData.hasOwnProperty(prop)) {
              index = yAxisIndex;
              for (i = 0; i < scope.yAxisMetrics.length; i++) {
                if (scope.yAxisMetrics[i].value === prop) {
                  index = i;
                  break;
                }
              }

              if (scope.seriesOptions.hasOwnProperty(prop)) {
                seriesOptions[index] = {
                  type: 'spline',
                  data: scope.chartData[prop],
                  name: scope.seriesOptions[prop].name,
                  visible: scope.seriesOptions[prop].visible,
                  yAxis: index,
                  color: colors[index]
                };

                if (
                  prop === 'visits' &&
                  scope.lineChartType === 'daily' &&
                  scope.forecastedChartData?.[prop]?.length > 0
                ) {
                  const forecastedVisitData = scope.forecastedChartData[prop];
                  seriesOptions[index].zoneAxis = 'x';
                  const chartData = scope.chartData[prop];

                  seriesOptions[index].zones = [
                    {
                      value: chartData.length ? chartData[chartData.length - 1][0] : forecastedVisitData[0][0]
                    },
                    {
                      dashStyle: 'dot'
                    }
                  ];
                  const combinedVisitData = [...chartData, ...forecastedVisitData];
                  seriesOptions[index].data = combinedVisitData;
                }
                yAxisTitles[index] = scope.seriesOptions[prop].name;
              } else if (prop === 'avg_sales') {
                continue;
              } else {
                seriesOptions[index] = {
                  type: 'spline',
                  data: scope.chartData[prop],
                  name: prop,
                  color: colors[index]
                };
                yAxisTitles[index] = prop;
              }
              yAxisMetrics[index] = { value: prop };
              yAxisIndex++;
            }
          }
          // @z.huang: why we need this:
          // the above code that fills the yAxisMetrics and yAxisTitles
          // only cares about the visible series, which means when the second metric
          // is the same as the first pick, we only get one item for both of arrays
          // Thus, we have to duplicate the items already in the arrays so that the chart
          // can display properly.
          // Why I removed $scope.updateMetrics:
          // this function only works when the directive gets created, due to the way we set up
          // all the charts with `ng-if`, it magically `works`, but in a wrong way (we have to fix this
          // later); another issue is that we shouldn't loop through an unordered object like
          // `scope.seriesOptions when updating an ordered item (i.e.: $scope.yAxisMetrics).
          if (yAxisIndex < 2) {
            fillArrayWithLastItems(yAxisMetrics, yAxisIndex);
            fillArrayWithLastItems(yAxisTitles, yAxisIndex);
            seriesOptions.pop();
          }

          scope.yAxisMetrics = yAxisMetrics;
          scope.yAxisTitles = yAxisTitles;

          createChart();
          scope.changed = false;
        }
      });

      // When chart becomes active, fit the chart inside its container
      scope.$watch('active', function(newValue, oldValue) {
        if (newValue === true) {
          if (scope.chart) {
            $timeout(function() {
              scope.chart.reflow();
            });
          }
        }
      });

      // Set the datepicker's date format
      $.datepicker.setDefaults({
        dateFormat: 'yy-mm-dd',
        onSelect: function() {
          scope.chart.xAxis[0].setExtremes(
            $('input.highcharts-range-selector:eq(0)')
              .datepicker('getDate')
              .getTime(),
            $('input.highcharts-range-selector:eq(1)')
              .datepicker('getDate')
              .getTime()
          );
          /** @type {HTMLInputElement} */ (this).blur();
        }
      });
    }
  };
}
