import { safeApply } from '../../factories/general_utils';

export class directMailOrdersDashboardController {
  constructor($scope, $http, abdmService, companyAndAccountFactory, modalFactory, $filter) {
    this.$scope = $scope;
    this.$http = $http;
    this.abdmService = abdmService;
    this.tableClasses = ['scrollable-table dashboard-table'];
    this.tableName = 'directMailOrdersDashboard';
    this.modalFactory = modalFactory;
    this.directMailURL = `${window.directMailURL}`;
    this.$filter = $filter;
    this.allHeaders = [
      {
        name: 'ID',
        sortable: true,
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'id',
        components: [{ field: 'id' }]
      },
      {
        name: 'Account Name',
        sortable: true,
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'account_name',
        components: [{ field: 'account_name' }]
      },
      {
        name: 'Budget',
        sortable: true,
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'budget',
        components: [{ field: 'totalBudgetWithCurrencyCode', exportField: 'budget', exportFilter: 'number' }]
      },
      {
        name: 'Start Date',
        sortable: true,
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'start_date',
        components: [{ field: 'start_date' }]
      },
      {
        name: 'End Date',
        sortable: true,
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'end_date',
        components: [{ field: 'end_date' }]
      },
      {
        name: '# Addresses',
        sortable: true,
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'addresses',
        components: [{ field: 'addresses' }]
      },
      {
        name: '# Zipcodes',
        sortable: true,
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'zipcodes',
        components: [{ field: 'zipcodes' }]
      },
      {
        name: 'Status',
        sortable: true,
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'status',
        components: [{ field: 'status', getCSSClass: this.getStatusCSSClass }]
      },
      {
        name: 'Creative',
        type: 'button',
        field: 'creative_url',
        components: [
          {
            field: 'creative_url',
            form: {
              type: 'button',
              class: 'creative-action-button table-button'
            },
            trigger: ($event, row, itemThis) => {
              const url = itemThis.creative_url;
              window.open(url, '_blank');
            }
          }
        ],
        sortable: false
      }
    ];
    this.tableConfig = {
      tableData: [],
      header: [...this.allHeaders],
      tableName: this.tableName,
      paginationTrigger: angular.noop,
      emptyTableMessage: 'No data available.'
    };
    this.booleans = {
      selectionProgress: true,
      tableChanged: false
    };
    this.orders = [];
    this.accountNames = {};
    this.allCompaniesForTenant = companyAndAccountFactory.companiesAccounts;
    this.setAccountIdToNameMapping();
    this.initDashboardData();
  }

  showError(errorMessage) {
    const modalSetting = {
      titleClass: 'alert',
      title: 'Error',
      message: errorMessage
    };

    this.modalFactory.simpleAlert(modalSetting);
  }

  openAbdm() {
    window.open(this.directMailURL, '_blank');
  }

  setAccountIdToNameMapping() {
    this.allCompaniesForTenant.forEach(company => {
      for (const account of company.accounts) {
        this.accountNames[account.id] = account.name;
      }
    });
  }

  getStatusCSSClass(status) {
    let rowStatus = status.toLowerCase().replace(/ /g, '-');

    if (['delivered', 'pending-delivery', 'in-transit'].includes(rowStatus)) {
      return `status ${rowStatus}-status`;
    }
    return '';
  }

  async initDashboardData() {
    try {
      const response = await this.abdmService.getCampaigns();
      response.data.data?.forEach(item => {
        const addressStr = item.ad_qty
          ?.split(' ')[0]
          ?.split(',')
          ?.join('');
        const addressNum = Number(addressStr);
        const zipStr = item.ad_description
          ?.split(' ')[4]
          ?.split(',')
          ?.join('');
        const zipNum = Number(zipStr);
        const orderData = {
          id: Number(item.id),
          budget: Number(item.price),
          totalBudgetWithCurrencyCode: this.$filter('isoCurrency')(Number(item.price), 'USD'),
          start_date: item.date_start?.split(' ')[0],
          end_date: item.date_end?.split(' ')[0],
          addresses: addressNum,
          zipcodes: zipNum,
          status: item.flight_status,
          creative_url: item.file_url,
          account_name: this.accountNames[item.account_id]
        };
        this.orders.push(orderData);
      });
      this.booleans.tableChanged = true;
    } catch (e) {
      console.error(e);
      this.showError('Sorry, an unexpected error occurred');
    } finally {
      this.booleans.selectionProgress = false;
      safeApply(this.$scope);
    }
  }
}
directMailOrdersDashboardController.$inject = [
  '$scope',
  '$http',
  'abdmService',
  'companyAndAccountFactory',
  'modalFactory',
  '$filter'
];
