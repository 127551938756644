import creativeGridTemplate from './creative-asset-grid-template.html';
import { CreativeAsset } from '../../../factories/types';

export class CreativeGridController {
  private creativeAssetList!: CreativeAsset[];
  private selectedCreativeAssetIds!: number[];
  constructor() {
    this.onSelect = this.onSelect.bind(this);
  }

  $onInit() {
    if (!this.selectedCreativeAssetIds) {
      this.selectedCreativeAssetIds = [];
    }
  }

  $onChanges(changesObj) {
    if (changesObj.creativeAssetList) {
      this.selectedCreativeAssetIds = [];
    }
  }

  isSelected(creativeAsset) {
    return this.selectedCreativeAssetIds.includes(creativeAsset.id);
  }

  selectAll() {
    let allCreativeAssetIdsOnPage = this.creativeAssetList.map(creativeAsset => creativeAsset.asset_id);
    let listOfSelectedIds = new Set([...this.selectedCreativeAssetIds, ...allCreativeAssetIdsOnPage]);
    this.selectedCreativeAssetIds = Array.from(listOfSelectedIds);
  }

  clearAllSelections() {
    this.selectedCreativeAssetIds = [];
  }

  onSelect(id, checked) {
    if (checked) {
      this.selectedCreativeAssetIds.push(id);
    } else {
      this.selectedCreativeAssetIds = this.selectedCreativeAssetIds.filter(creativeAssetId => creativeAssetId != id);
    }
  }
}

export const creativeAssetGrid = {
  bindings: {
    creativeAssetList: '<',
    selectedCreativeAssetIds: '=',
    availableLimits: '=',
    itemsPerPage: '=',
    updateItemsPerPage: '&',
    totalItems: '=',
    currentPage: '=',
    updateCurrentPage: '&'
  },
  template: creativeGridTemplate,
  controller: CreativeGridController,

  controllerAs: 'vm',
  bindToController: true
};
