import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '../../../utils/react/mui_styled';
import { Box, ClickAwayListener } from '@material-ui/core';

import { AutoCompleteInputField } from './AutoCompleteInputField';
import { AutoCompleteTabHeader } from './AutoCompleteTabHeader';
import { useDebounce } from 'use-debounce';
import './AutoCompleteV2.scss';

export interface ListType {
  id: number;
  name: string;
  type: string;
}

export interface QueryResult {
  [key: string]: ListType[];
}

export interface AutoCompleteV2Props {
  placeholder: string;
  selectedItem: ListType[];
  callback: (selected: ListType[], callbackParam: string | undefined, newSelected: ListType[]) => void;
  callbackParam?: string;
  queryFn: (query: string) => Promise<QueryResult>;
  buttonLabel: string;
}

export const InputContainer = styled('input')({
  margin: '0',
  border: '0',
  padding: '0px 12px',
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontFamily: 'var(--font-family-regular)',
  fontSize: 'var(--font-size-regular)',
  color: 'var(--color-on-primary)',
  lineHeight: 'var(--line-height)',
  '&::placeholder': {
    opacity: 0.4,
    color: 'var(--color-on-primary)'
  }
});

export function AutoCompleteV2(props: AutoCompleteV2Props): JSX.Element {
  const { placeholder, selectedItem, callback, callbackParam, buttonLabel, queryFn } = props;

  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [showIndicator, setIndicator] = React.useState(false);
  const [tabData, setTabData]: [QueryResult, React.Dispatch<React.SetStateAction<QueryResult>>] = React.useState({});
  const [newSelection, setNewSelection] = React.useState<ListType[]>([]);

  const toggleDropdown = async (value: boolean) => {
    if (value && value !== open) {
      await setQueryResults();
    }
    setOpen(value);
  };

  async function setQueryResults() {
    setIndicator(true);
    const result = await getQueryResults(searchText);
    const uniqueResults = getUniqueResults(selectedItem, result);
    setTabData(uniqueResults);
    setIndicator(false);
  }

  const [debouncedSearchTerm] = useDebounce(searchText, 500);

  React.useEffect(() => {
    if (open) {
      setQueryResults();
    }
  }, [debouncedSearchTerm]);

  const getQueryResults = async (query: string) => {
    try {
      const res = await queryFn(query);
      return res;
    } catch (e) {
      console.error(e);
    } finally {
    }
    return {};
  };

  const selectItems = (selected: ListType[]) => {
    if (selected.length) {
      const prevSelectedItemSet = new Set(newSelection.map(item => `${item.type}-${item.name}`));
      const tempSelections = selected.filter(item => {
        const currItem = `${item.type}-${item.name}`;
        return !prevSelectedItemSet.has(currItem);
      });
      setNewSelection([...newSelection, ...tempSelections]);
    }
  };

  const deselectItems = (deselected: ListType[]) => {
    if (deselected.length) {
      const deselectedSet = new Set(deselected.map(item => `${item.type}-${item.name}`));
      const tempSelections = newSelection.filter(item => {
        const currItem = `${item.type}-${item.name}`;
        return !deselectedSet.has(currItem);
      });
      setNewSelection([...tempSelections]);
    }
  };

  const saveItems = async () => {
    const prevSelectedItemSet = new Set(selectedItem.map(item => `${item.type}-${item.name}`));
    newSelection.forEach(item => {
      const currItem = `${item.type}-${item.name}`;
      if (!prevSelectedItemSet.has(currItem)) {
        selectedItem.push(item);
      }
    });
    callback(selectedItem, callbackParam, newSelection);
    setNewSelection([]);
    setSearchText('');
    toggleDropdown(false);
  };

  function sortTabs(a, b) {
    if (a === 'All' && b !== 'All') {
      return -1; // a comes before b, ensuring All comes first
    }
    if (a !== 'All' && b === 'All') {
      return 1; // b comes before a, ensuring All comes first
    }
    // If neither of the tabs is All, use localeCompare
    return a.localeCompare(b);
  }

  /**
   * Removes selected items from search results
   */
  function getUniqueResults(selectedItems: ListType[], result: QueryResult): QueryResult {
    const selectedItemSet = new Set(selectedItems.map(item => `${item.name}-${item.type}`));
    const tabs = Object.entries(result);
    tabs.sort((a, b) => sortTabs(a[0], b[0])); // sort tabs to maintain order
    const uniqueTabData = {} as QueryResult;

    tabs.forEach(([tabName, tabData]) => {
      uniqueTabData[tabName] = tabData.filter(item => {
        const currItem = `${item.name}-${item.type}`;
        return !selectedItemSet.has(currItem);
      });
    });

    return uniqueTabData;
  }

  return (
    <ClickAwayListener onClickAway={() => toggleDropdown(false)}>
      <div className={`autocomplete-v2 ${open && 'open'}`}>
        <Box borderRadius="var(--border-radius)">
          <AutoCompleteInputField
            searchText={searchText}
            setSearchText={setSearchText}
            placeholder={placeholder}
            toggleDropdown={toggleDropdown}
            showIndicator={showIndicator}
          />

          {open && (
            <AutoCompleteTabHeader
              tabData={tabData}
              selectItems={selectItems}
              deselectItems={deselectItems}
              selectedTabItems={newSelection}
              saveItems={saveItems}
              buttonLabel={buttonLabel}
            />
          )}
        </Box>
      </div>
    </ClickAwayListener>
  );
}

AutoCompleteV2.propTypes = {
  placeholder: PropTypes.string,
  selectedItem: PropTypes.array,
  callbackParam: PropTypes.string,
  callback: PropTypes.func,
  queryFn: PropTypes.func,
  buttonLabel: PropTypes.string
};
