import { AdgroupTargetingService, LocationFilterSearchPlaceholderType } from '../../adgroup_targeting.serv';
import { AdGroupTargeting } from '../../../../api/http/adgroup';
import { safeApply, nestedUpdateInPlace } from '../../../../factories/general_utils';
import { DistanceUnits } from '../../../../api/types/common';
import { CampaignManagerService, CompanyAndAccountFactoryService, SearchService } from '../../../../factories/types';
import { AdsManagerEvent } from '../../../../events/events';
import GAEventList from '../../../../components/googleAnalytics/eventList';
export interface LocationFilterType {
  name: string;
  type: string;
  place_id?: number;
}

/**
 * Abstract class used for adgroup targeting. It expose commons functions used in targeting pages.
 */
export abstract class AbstractAdgroupTargetingController {
  scope: ng.IScope;
  // Factories
  companyAndAccountFactory: CompanyAndAccountFactoryService;
  adgroupTargetingService: AdgroupTargetingService;
  searchFactory: SearchService;
  campaignManagerFactory: CampaignManagerService;

  showAudienceExclude = false;
  distanceUnit = DistanceUnits.MILES;
  locationFilterSearchPlaceholder = LocationFilterSearchPlaceholderType.Default;

  protected constructor(
    scope: ng.IScope,
    companyAndAccountFactory: CompanyAndAccountFactoryService,
    adgroupTargetingService: AdgroupTargetingService,
    searchFactory: SearchService,
    campaignManagerFactory: CampaignManagerService
  ) {
    this.scope = scope;
    this.companyAndAccountFactory = companyAndAccountFactory;
    this.adgroupTargetingService = adgroupTargetingService;
    this.distanceUnit = this.companyAndAccountFactory?.selectedAccount?.distanceUnit ?? DistanceUnits.MILES;
    this.searchFactory = searchFactory;
    this.campaignManagerFactory = campaignManagerFactory;
  }

  /**
   * All subclasses must implement this method and return an adgroup targeting
   */
  abstract getAdgroupTargeting(): AdGroupTargeting;

  searchAudiences = (query: string, exclude = false, parseResult = false) => {
    return this.adgroupTargetingService.searchAudiences(query, exclude, parseResult);
  };

  searchAudiencesExclude = (query: string) => {
    return this.searchAudiences(query, true);
  };

  handleSearchAudiencesExclude = () => {
    // Note: safeApply here is needed as gt-autocompletev2 a react
    // component does changes outside of angular's context.
    this.scope.$broadcast(AdsManagerEvent.AdGroupTargetingAudienceExcludeTableChanged);
    safeApply(this.scope);
    // We have updated the campaign's agroup exclude audience, logging this event into GA
    typeof window.gtag === 'function' &&
      window.gtag('event', GAEventList.CAMPAIGN_TARGET_AUDIENCE, {
        adgroup_id: this.campaignManagerFactory.selectedAdGroup?.id,
        campaign_id: this.campaignManagerFactory.selectedCampaign?.id,
        type: 'exclude',
        gt_user_id: window?.user?.id,
        tenant_id: this.companyAndAccountFactory.selectedTenant?.tenant_id
      });
  };

  getAddressFromGooglePlaceId = async (newLocationFilters: LocationFilterType[]) => {
    for (let location of newLocationFilters) {
      if (location.type == 'address' && location.hasOwnProperty('place_id')) {
        const data = await this.searchFactory.getGeoCodedAddressObjFromPlaceId(location);
        nestedUpdateInPlace(data, location);
        delete location.place_id;
      }
    }
  };

  handleLocationFilterChange = (
    selected: LocationFilterType[],
    callbackParam: string | undefined,
    newSelected: LocationFilterType[]
  ) => {
    // Note: safeApply here is needed as gt-autocompletev2 a react
    // component does changes outside of angular's context.
    safeApply(this.scope);
    this.scope.$broadcast(AdsManagerEvent.AdGroupTargetingLocationFilterTableChanged);
    this.getAddressFromGooglePlaceId(newSelected);
    // We have updated the campaign's agroup location filters, logging this event into GA
    typeof window.gtag === 'function' &&
      window.gtag('event', GAEventList.CAMPAIGN_TARGET_LOCATION, {
        adgroup_id: this.campaignManagerFactory.selectedAdGroup.id,
        campaign_id: this.campaignManagerFactory.selectedCampaign.id,
        gt_user_id: window?.user?.id,
        tenant_id: this.companyAndAccountFactory.selectedTenant?.tenant_id
      });
  };

  searchLocations = (query: string) => {
    return this.adgroupTargetingService.searchTargetingLocations(this.getAdgroupTargeting(), query);
  };

  toggleAudienceExclude = () => {
    this.showAudienceExclude = !this.showAudienceExclude;
  };
}
