import { HttpApiExecutor, ParamsOptions, ResponseOf, AnyHttpApiDef, HttpApiExecutorParams } from './executor';

export async function send$http<TDef extends AnyHttpApiDef>(
  $http: ng.IHttpService,
  api: TDef,
  ...request: HttpApiExecutorParams<TDef>
): Promise<ResponseOf<TDef>> {
  const [{ params = {}, data = undefined } = {}] = request as [ParamsOptions<typeof api>];
  const transformedData = api.requestContent?.transformInput(data);
  const urlSpec = typeof api.url === 'function' ? api.url(params) : api.url;
  const [url, urlParams] = Array.isArray(urlSpec) ? urlSpec : [urlSpec];
  const urlParamsPart = urlParams === undefined ? {} : { params: urlParams };
  const dataPart = transformedData === undefined ? {} : { data: transformedData };
  const response = await $http<ResponseOf<typeof api>>({
    method: api.method,
    url,
    ...urlParamsPart,
    ...dataPart
  });
  const result = api.responseContent?.transformOutput(response) ?? response.data;

  return result;
}

export function create$httpExecutor($http: ng.IHttpService): HttpApiExecutor {
  return (api, ...request) => send$http($http, api, ...request);
}
