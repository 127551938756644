import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from './TextField';
import { Chip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

interface TagsTextFieldProps {
  value: string[];
  onChange(value: string[]): void;
  label?: string;
  errorMessage?: string | null;
  disabled?: boolean;
  separatorKeys?: string[];
  className?: string;
  fullWidth?: boolean;
}

const StyledChip = withStyles({
  deleteIcon: {
    marginRight: '12px',
    color: 'var(--color-on-primary)',
    '&:hover': {
      color: 'var(--color-primitives-evergreen-evergreen30)'
    }
  },
  colorPrimary: {
    borderRadius: 'var(--border-radius)',
    border: '1px solid var(--color-primary)',
    background: 'var(--color-background)',
    padding: '10px 16px',
    height: 'var(--action-item-height)',
    color: 'var(--color-secondary)',
    fontFamily: 'var(--secondary-font-family)',
    fontSize: 'var(--font-size-regular)',
    fontWeight: 'var(--font-weight-light-bold)',
    lineHeight: '150%',
    columnGap: '12px',
    '&:hover': {
      color: 'var(--color-primitives-evergreen-evergreen30)',
      border: '1px solid var(--color-primitives-evergreen-evergreen50)'
    }
  }
})(Chip);

export function TagsTextField({
  value,
  onChange,
  label,
  errorMessage,
  disabled,
  separatorKeys,
  className,
  fullWidth
}: TagsTextFieldProps) {
  const ChipInput = withStyles({
    root: {
      '& .MuiInputBase-root.Mui-error': {
        border: '1px solid var(--color-error)',
        background: 'var(--color-error-container)'
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: 'var(--color-error)',
        fontFamily: 'var(--font-family-regular)',
        fontSize: 'var(--font-size-small)',
        fontWeight: 'var(--font-weight-regular)',
        lineHeight: 'var(--line-height)',
        marginTop: '4px'
      },
      '& .MuiChip-label': {
        paddingLeft: '0px'
      }
    },
    input: {
      height: '18px',
      padding: '4px 0 0 4px !important'
    },
    inputRoot: {
      padding: '8px 12px 12px 8px !important',
      background: value.length ? 'var(--color-primary-container)' : 'var(--color-background)',
      borderRadius: 'var(--border-radius)',
      border: value.length ? '1px solid var(--color-primary)' : '1px solid var(--color-outline)',
      minHeight: 'var(--action-item-height)',
      maxHeight: '220px',
      overflowY: 'auto',
      alignContent: 'baseline',
      paddingRight: '32px !important',

      '&:hover': {
        border: '1px solid var(--color-link)'
      },
      '&:focus-within': {
        border: '1px solid var(--color-primary)',
        backgroundColor: 'var(--color-primary-container)'
      }
    },
    clearIndicator: {
      color: 'var(--color-on-primary)',
      paddingRight: '14px'
    },
    popupIndicator: {
      marginRight: '10px'
    }
  })(Autocomplete);
  return (
    <ChipInput
      multiple
      id="tags-outlined"
      options={[]} // You should define your options based on what your application needs
      value={value}
      freeSolo
      getOptionLabel={option => option.name}
      filterOptions={(options, { inputValue }) =>
        options.filter(option => {
          // Convert both sides to strings to ensure proper comparison
          const label = typeof option === 'string' ? option : option[0].name;
          return label.toString();
        })
      }
      onChange={(event, newValue, reason) => onChange(newValue)}
      autoSelect
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <StyledChip
            variant="outlined"
            color="primary"
            deleteIcon={<CloseIcon />}
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          fullWidth={fullWidth}
          errorMessage={errorMessage}
          disabled={disabled}
          className={className}
          variant="standard"
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
    />
  );
}

TagsTextField.classes = {
  inputRoot: 'GT-TagsTextField-inputRoot' // todo: autogenerate
};

TagsTextField.selectors = {
  inputRoot: `.${TagsTextField.classes.inputRoot}`
};
