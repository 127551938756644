import * as React from 'react';
import { useObserver } from 'mobx-react-lite';
import { SelectEditableFormField } from '../index';
import { SimpleSearchDropdown } from '../../reactComponents/Dropdowns/SimpleSearchDropdown';

export interface SelectFieldProps<O> {
  field: SelectEditableFormField<O>;
  label: string;
  getItemTitle: (option: O) => string;
  className?: string;
  fullWidth?: boolean;
}

export function SelectField<O>(props: SelectFieldProps<O>) {
  return useObserver(() => (
    <SimpleSearchDropdown
      className={props.className}
      title={props.label}
      value={props.field.value}
      onSelect={props.field.update}
      getItemTitle={props.getItemTitle}
      items={props.field.options}
      error={!props.field.isValid}
      errorMessage={props.field.validationMessage ?? undefined}
      fullWidth={props.fullWidth}
    />
  ));
}
