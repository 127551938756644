import { keepDecimalNoRounding } from '../../../factories/general_utils';

LocationReportController.$inject = [
  '$scope',
  'campaignManagerFactory',
  'reportFactory',
  'companyAndAccountFactory',
  'featureFlagFactory'
];

/**
 * Data that used for markers on the map.
 * @typedef {google.maps.MarkerOptions & {
 *  impressions: number;
 *  visits: number;
 *  secondaryActions: number;
 *  clicks: number;
 * }} MarkerData
 */

/**
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 */
export function LocationReportController(
  $scope,
  campaignManagerFactory,
  reportFactory,
  companyAndAccountFactory,
  featureFlagFactory
) {
  var campaign = campaignManagerFactory.selectedCampaign;
  var gmap = null;
  var markerClusterer = null;
  var markers = [];

  $scope.featureFlagFactory = featureFlagFactory;

  $scope.mapSummaryLoading = false;
  $scope.showMapSummaryMessage = false;
  $scope.mapSummaryMessage = '';
  $scope.sortOptionsEnabled = true;
  $scope.showMapMessage = false;
  $scope.showMapError = false;

  var MAP_INITIAL_ZOOM = 4;
  var MAP_INITIAL_CENTER = new google.maps.LatLng(37.3282, -80.5795); // Default to US (will auto-fit upon getting data)
  /** @type {google.maps.MapTypeStyle[]} */
  const MAP_THEME_LIGHT = [
    {
      stylers: [{ lightness: 25 }, { saturation: -25 }]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    }
  ];

  const mapConfig = {
    options: {
      scrollwheel: false,
      streetViewControl: false,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.SMALL,
        position: google.maps.ControlPosition.LEFT_TOP
      },
      panControl: false,
      mapTypeControl: true,
      minZoom: 3,
      maxZoom: 15,
      zoom: MAP_INITIAL_ZOOM,
      center: MAP_INITIAL_CENTER,
      styles: MAP_THEME_LIGHT,
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.ROADMAP]
      },
      tilt: 0
    }
  };

  $scope.booleans = {
    showSummaryWindow: true
  };

  $scope.mapTableData = [];

  $scope.selectedTopOption = {
    value: null
  };
  $scope.selectedSortOption = {
    value: null
  };

  var topOptions = {
    states: {
      text: 'States',
      value: 'states',
      hide: false
    },
    dmas: {
      text: "DMA's",
      value: 'dmas',
      hide: false
    },
    counties: {
      text: 'Counties',
      value: 'counties',
      hide: false
    },
    zipCodes: {
      text: 'ZIP Codes',
      value: 'zipCodes',
      hide: false
    },
    // NOTE: the root options such as the one below should be kept regardless of feature.
    // This is because our logic uses it for some checks.
    // Leaving this here will not expose any of our visits data, even if the user directly calls some route.
    stores: {
      text: 'Drive To Stores',
      value: 'stores',
      hide: !featureFlagFactory.isFeatureEnabled('VISITS_READ')
    }
  };

  // Populates md-select in this order (will only populate counties if COUNTY_TARGETING is enabled)
  $scope.topOptionsOrder = featureFlagFactory.isFeatureEnabled('COUNTY_TARGETING')
    ? [topOptions.states, topOptions.dmas, topOptions.counties, topOptions.zipCodes, topOptions.stores]
    : [topOptions.states, topOptions.dmas, topOptions.zipCodes, topOptions.stores];

  // Filtering by hide flag happens below
  var account = companyAndAccountFactory.getSelectedAccount();
  account.then(function(selectedAccount) {
    if (selectedAccount.countryCode != 'US') {
      topOptions.dmas.hide = true;
      $scope.topOptionsOrder = $scope.topOptionsOrder.filter(function(option) {
        return !option.hide;
      });
    }
  });

  var sortOptions = {
    impressions: {
      text: 'Impressions',
      value: 'impressions'
    },
    ctr: {
      text: 'Clicks',
      value: 'clicks'
    },
    secondaryActions: {
      text: 'Sec. Actions',
      value: 'secondaryActions'
    },
    visits: {
      text: 'Store Visits',
      value: 'visits'
    }
  };

  // Populates md-select in this order
  $scope.sortOptionsOrder = [sortOptions.impressions, sortOptions.ctr, sortOptions.secondaryActions];

  // Add sort by visits option if VISITS_READ is enabled
  if (featureFlagFactory.isFeatureEnabled('VISITS_READ')) {
    $scope.sortOptionsOrder.push(sortOptions.visits);
  }

  $scope.genericTableConfig = {
    mainColumn: {
      title: '',
      key: ''
    },
    columns: [
      {
        title: 'Impressions',
        key: 'impressions'
      },
      {
        title: 'Clicks',
        key: 'clicks'
      },
      {
        title: 'Sec. Actions',
        key: 'location_secondary_actions'
      },
      {
        title: 'VCR',
        key: 'vcr',
        filter: 'percentage'
      }
    ]
  };

  $scope.getValue = (value, filter) => {
    if (filter === 'percentage') {
      if (typeof value === 'number') {
        const percentValue = keepDecimalNoRounding(value * 100);
        return `${percentValue}%`;
      }
      if (value === null) {
        return '-';
      }
    }
    return value;
  };

  // Add column for showing visits data
  if (featureFlagFactory.isFeatureEnabled('VISITS_READ')) {
    $scope.genericTableConfig.columns.push({
      title: 'Store Visits',
      key: 'visits'
    });
  }

  $scope.storesTableConfig = {
    mainColumn: {
      title: 'Stores',
      key: 'address'
    },
    columns: [
      {
        title: 'Visits',
        key: 'address_visits'
      }
    ]
  };

  $scope.toggleSummaryWindow = function() {
    $scope.booleans.showSummaryWindow = !$scope.booleans.showSummaryWindow;
  };

  $scope.$on('$destroy', function() {
    $scope.map = null;
  });

  var loadZipcodeClusterData = function() {
    var queryObj = angular.copy($scope.$parent.highlightedAdGroup);
    var selectedTableLevel = null;
    var specifiedId = null;

    if (queryObj == null || queryObj.hasOwnProperty('adGroups')) {
      selectedTableLevel = 'campaign';
      specifiedId = campaign.id;
    } else {
      if (queryObj.hasOwnProperty('adgroup_id')) {
        selectedTableLevel = 'placements';
        specifiedId = queryObj.adgroup_id;
      } else {
        // we don't have creative level location reporting data
        $scope.showMapMessage = true;
        $scope.mapMessage = 'Cluster information only supports campaign and adgroup levels.';
        markerClusterer.clearMarkers();
        markerClusterer.clearInfoWindows();
        return;
      }
    }

    $scope.mapClustersLoading = true;
    reportFactory
      .getAllZipcodeMetrics(selectedTableLevel, specifiedId, $scope.$parent.selectedDurationRange)
      .then(
        function(data) {
          if (!clusterCallIsExpired(selectedTableLevel, specifiedId)) {
            markers = [];
            for (var point of data) {
              if (point.lat == null && point.lng == null) {
                continue;
              }

              markers.push(
                new google.maps.Marker(
                  /** @type {MarkerData} */ ({
                    position: new google.maps.LatLng(point.lat, point.lng),
                    visits: point.visits,
                    impressions: point.impressions,
                    secondaryActions: point.location_secondary_actions,
                    clicks: point.clicks,
                    vcr: point?.vcr ?? 0
                  })
                )
              );
            }

            if (markers && markers.length) {
              markerClusterer.clearMarkers();
              markerClusterer.clearInfoWindows();
              markerClusterer.addMarkers(markers);
              markerClusterer.fitMapToMarkers(); // fit map to where the clusters are
            }
          }
        },
        function(error) {
          if (!clusterCallIsExpired(selectedTableLevel, specifiedId)) {
            markers = [];
          }
        }
      )
      .finally(function() {
        if (!clusterCallIsExpired(selectedTableLevel, specifiedId)) {
          $scope.mapClustersLoading = false;
          if (!markers || !markers.length) {
            $scope.mapMessage = 'Cluster information is not available at the moment. Please try again later.';
            $scope.showMapMessage = true;
          } else {
            $scope.showMapMessage = false;
          }
        }
      });
  };

  var getLocationTableData = function() {
    if (!gmap || !gmap.getBounds()) {
      return; // map not ready yet
    }

    var bounds = gmap.getBounds();
    var northEast = bounds.getNorthEast();
    var southWest = bounds.getSouthWest();

    // update 'active' bounds so that earlier calls are now expired
    $scope.currentBoundsForSummaryTable = bounds;

    var queryObj = $scope.$parent.highlightedAdGroup;
    var selectedTableLevel = null;
    var specifiedId = null;

    if (queryObj == null || queryObj.hasOwnProperty('adGroups')) {
      selectedTableLevel = 'campaign';
      specifiedId = campaign.id;
    } else {
      if (queryObj.hasOwnProperty('adgroup_id')) {
        selectedTableLevel = 'placements';
        specifiedId = queryObj.adgroup_id;
      } else {
        // we don't have creative level location reporting data
        $scope.showMapSummaryMessage = true;
        $scope.mapSummaryMessage = 'Location reporting is only supported on campaign and adgroup levels.';
        $scope.mapTableData = [];
        return;
      }
    }

    $scope.mapSummaryLoading = true;
    $scope.showMapSummaryMessage = false;

    var topOption = $scope.selectedTopOption.value;
    var sortOption = $scope.selectedSortOption.value;

    reportFactory
      .getTopSummaryData(
        selectedTableLevel,
        specifiedId,
        $scope.$parent.selectedDurationRange,
        topOption,
        sortOption,
        northEast,
        southWest
      )
      .then(
        function(data) {
          if (!summaryCallIsExpired(bounds)) {
            var mapTableData = [];
            for (var i = 0; i < data.length; i++) {
              mapTableData.push(data[i]);
            }
            $scope.mapTableData = mapTableData;
          }
        },
        function() {
          if (!summaryCallIsExpired(bounds)) {
            $scope.mapTableData = [];
          }
        }
      )
      .finally(function() {
        if (!summaryCallIsExpired(bounds)) {
          if ($scope.mapTableData.length === 0) {
            $scope.mapSummaryMessage = 'No results.';
            $scope.showMapSummaryMessage = true;
          } else {
            $scope.showMapSummaryMessage = false;
          }

          $scope.mapSummaryLoading = false;
        }
      });
  };

  function clusterCallIsExpired(selectedTableLevel, specifiedId) {
    var queryObj = $scope.$parent.highlightedAdGroup;
    var currentSelectedTableLevel;
    var currentSpecifiedId;

    if (queryObj == null || queryObj.hasOwnProperty('adGroups')) {
      currentSelectedTableLevel = 'campaign';
      currentSpecifiedId = campaign.id;
    } else {
      if (queryObj.hasOwnProperty('adgroup_id')) {
        currentSelectedTableLevel = 'placements';
        currentSpecifiedId = queryObj.adgroup_id;
      } else {
        // creatives selected => no clusters
        return true;
      }
    }

    return currentSelectedTableLevel !== selectedTableLevel || currentSpecifiedId !== specifiedId;
  }

  function summaryCallIsExpired(bounds) {
    if (!bounds || !$scope.currentBoundsForSummaryTable) {
      return false;
    }

    return !bounds.equals($scope.currentBoundsForSummaryTable);
  }

  function init() {
    if (gmap) {
      return;
    }

    // init map
    gmap = new google.maps.Map(document.getElementById('google-map-area'), mapConfig.options);

    // init marker clusterer
    markerClusterer = new MarkerClusterer(gmap);
    markerClusterer.setEnabled(true);
    markerClusterer.setClickEnabled(true);

    // when cluster is clicked then set bounds and center
    google.maps.event.addListener(markerClusterer, 'clusterclick', function(clusterIcon, event) {
      var bounds;

      // TODO: if cluster size is small then show pois, if not show sub clusters
      if (clusterIcon.size > 1) {
        // zoom into the cluster
        bounds = clusterIcon.cluster_.getBounds();
        gmap.fitBounds(bounds);
        gmap.panTo(clusterIcon.cluster_.getCenter());
      }
    });

    // disable cluster click when dragging so we don't zoom into a cluster while panning map
    google.maps.event.addListener(gmap, 'dragstart', function() {
      if (markerClusterer) {
        markerClusterer.setClickEnabled(false);
      }
    });

    google.maps.event.addListener(gmap, 'idle', function() {
      if (markerClusterer) {
        markerClusterer.setClickEnabled(true);
      }

      // refresh summary table on map idle
      getLocationTableData();
    });

    // default values for input fields
    $scope.selectedSortOption = {
      value: sortOptions.impressions.value
    };
    $scope.selectedTopOption = {
      value: topOptions.states.value
    };
    useGenericTableConfig();
    loadZipcodeClusterData();
  }

  function useGenericTableConfig() {
    $scope.genericTableConfig.mainColumn = {
      title: topOptions[$scope.selectedTopOption.value].text,
      key: topOptions[$scope.selectedTopOption.value].value
    };
    $scope.summaryTableConfig = $scope.genericTableConfig;
  }

  function useStoresTableConfig() {
    $scope.summaryTableConfig = $scope.storesTableConfig;
  }

  $scope.changeTopOption = function() {
    if ($scope.selectedTopOption.value == topOptions.stores.value) {
      $scope.sortOptionsEnabled = false;
      $scope.selectedSortOption = {
        value: sortOptions.visits.value
      };
      markerClusterer.changeMetric($scope.selectedSortOption.value);
      useStoresTableConfig();
    } else {
      $scope.sortOptionsEnabled = true;
      useGenericTableConfig();
    }

    getLocationTableData();
  };

  $scope.changeSortOption = function() {
    getLocationTableData();

    if (markerClusterer) {
      markerClusterer.changeMetric($scope.selectedSortOption.value);
    }
  };

  init();

  $scope.$watch('$parent.highlightedAdGroup', function() {
    getLocationTableData();
    loadZipcodeClusterData();
  });

  $scope.$on('daterange.changed', function() {
    getLocationTableData();
    loadZipcodeClusterData();
  });
}
