import { NotificationTypes } from '../../../api/types/common';

OrganizationManagerCtrl.$inject = [
  '$scope',
  '$stateParams',
  '$q',
  'companyAndAccountFactory',
  '$state',
  'staticDataFactory',
  '$filter',
  'modalFactory',
  'featureFlagFactory'
];

/**
 * @param {import('@uirouter/angularjs').StateParams} $stateParams
 * @param {ng.IQService} $q
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {import('../../../factories/static_data.fac').IStaticDataFactory} staticDataFactory
 * @param {ng.IFilterService} $filter
 */
export function OrganizationManagerCtrl(
  $scope,
  $stateParams,
  $q,
  companyAndAccountFactory,
  $state,
  staticDataFactory,
  $filter,
  modalFactory,
  featureFlagFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;
  $scope.companyAndAccountFactory = companyAndAccountFactory;
  $scope.filterAccountsSearch = '';
  $scope.saveProgress = false;
  $scope.invoiceProgress = false;
  $scope.openCampaignDashboardProgress = false;
  $scope.deleteProgress = false;
  $scope.errorMsg = '';

  $scope.booleans = {
    loading: false
  };

  $scope.$on('in progress', function() {
    $scope.booleans.loading = true;
  });

  $scope.$on('end progress', function() {
    $scope.booleans.loading = false;
  });

  $scope.is_holding_company = companyAndAccountFactory.is_holding_company;

  $scope.selectedCompany = {};
  $scope.companiesAccounts = [];

  $scope.countries = {};

  $scope.search = {
    account: '',
    company: ''
  };

  $scope.dartBillingAccounts = {
    values: []
  };

  $scope.accountVerticals = [];

  $scope.companyAndAccountsLoading = true;
  $scope.company_not_found = false;
  $scope.account_not_found = false;
  $scope.excludeNotificationTypes = [NotificationTypes.NO_VALID_ACCOUNT_LEVEL_CREDIT_CARD];

  /** @type {[
   *  ReturnType<typeof staticDataFactory['getCountries']>,
   *  ReturnType<typeof staticDataFactory['getAccountVerticals']>,
   *  ReturnType<typeof staticDataFactory['getDartBillingAccounts']> | Promise<undefined>
   * ]} */
  const promises = [
    staticDataFactory.getCountries(),
    staticDataFactory.getAccountVerticals(),
    // No need to get available DART billing accounts if the user doesn't even
    // have the feature to use account billing sources in the first place
    featureFlagFactory.isFeatureEnabled('ACCOUNT_BILLING_SOURCE_READ')
      ? staticDataFactory.getDartBillingAccounts()
      : Promise.resolve(undefined)
  ];

  $scope.$emit('in progress');

  $q.all(promises)
    .then(function([countries, accountVerticals, dartBillingAccounts]) {
      $scope.countries = countries;
      $scope.accountVerticals = accountVerticals;

      angular.copy(companyAndAccountFactory.companiesAccounts, $scope.companiesAccounts);
      companyAndAccountFactory.selectFromCompaniesAndAccountsForOrg(
        $stateParams.selectedCompanyId,
        $stateParams.selectedAccountId
      );
      if ($stateParams.selectedAccountId != null && companyAndAccountFactory.selectedAccountForOrg == null) {
        $scope.account_not_found = true;
        return;
      }
      if ($stateParams.selectedCompanyId != null && companyAndAccountFactory.selectedCompanyForOrg == null) {
        $scope.company_not_found = true;
        return;
      }

      if (companyAndAccountFactory.selectedCompanyForOrg) {
        $scope.selectedCompany = angular.copy(companyAndAccountFactory.selectedCompanyForOrg);
        $scope.is_holding_company = companyAndAccountFactory.is_holding_company;
      }

      if ($state.current.name == 'organization') {
        $scope.paginateCompanies();
        if (!$scope.is_holding_company) {
          $scope.openCompany($scope.selectedCompany.id);
        }
        companyAndAccountFactory.selection = 'CompanyList';
      } else if ($state.current.name == 'company') {
        $scope.paginateAccounts();
        companyAndAccountFactory.selection = 'Company';
      } else if ($state.current.name == 'account') {
        companyAndAccountFactory.selection = 'Account';
      }

      if ($stateParams.action && $stateParams.action.toLowerCase() === 'create') {
        $scope.newCompanyModal();
      }

      if (dartBillingAccounts) {
        $scope.dartBillingAccounts.values = angular.copy(dartBillingAccounts);
      }
    })
    .finally(function() {
      $scope.$emit('end progress');
    });

  $scope.openCompany = function(companyId) {
    $state.go('company', { selectedCompanyId: companyId });
  };

  $scope.openCompanyFromTable = function(company) {
    $state.go('company', { selectedCompanyId: company.id });
  };

  $scope.openAccount = function(account) {
    $state.go('account', { selectedCompanyId: $scope.selectedCompany.id, selectedAccountId: account.id });
  };

  $scope.newAccount = function() {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.new-account.html',
      controller: 'NewAccountController',
      size: 'xl'
    };

    var data = {
      countries: $scope.countries,
      dartBillingAccounts: $scope.dartBillingAccounts,
      accountVerticals: $scope.accountVerticals
    };

    modalFactory.createModal(modalOptions, data).then(function(account) {
      if (account) {
        $state.go('account', {
          selectedCompanyId: $scope.selectedCompany.id,
          selectedAccountId: account.id,
          tab: 'Tracking Pixels'
        });
      }
    });
  };

  $scope.openCampaigns = function() {
    $state.go('campaigns.dashboard');
  };

  $scope.newCompanyModal = function() {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.new-organization.html',
      controller: 'newCompanyController'
    };

    modalFactory.createModal(modalOptions).then(function(company) {
      $scope.selectedCompany = company;
      if (company) {
        $scope.openCompany(company.id);
      }
    });
  };

  $scope.editOrganizationName = function() {
    $('.edit-company-name').hide();
    $('.save-company-name').show();
    $('.organization-name input').prop('disabled', false);
    $('.organization-name md-input-container').addClass('md-input-focused');
  };

  $scope.paginatedCompanies = [];
  $scope.paginateCompanies = function() {
    $scope.paginatedCompanies.length = 0;
    $scope.paginatedCompanies.push.apply($scope.paginatedCompanies, $scope.companiesAccounts);
    $scope.companiesTableChanged.changed = true;
  };

  $scope.companiesTableChanged = {
    changed: false
  };
  $scope.companiesTableConfig = {
    tableName: 'companies',
    header: [
      { name: 'ID', field: 'id', components: [{ field: 'id' }] },
      { name: 'Name', field: 'name', components: [{ field: 'name' }] },
      { name: 'Accounts', field: '&accounts.length', components: [{ field: '&accounts.length' }] }
    ],
    defaultOrderField: '&-accounts.length',
    filter: '',
    customFilter: function(scope) {
      return function(value) {
        var query = scope.config.filter.toLowerCase();
        var i;

        for (i = 0; i < value.accounts.length; i += 1) {
          if (value.accounts[i].name.toLowerCase().indexOf(query) >= 0) {
            return true;
          }
        }
        return value.name.toLowerCase().indexOf(query) >= 0;
      };
    },
    rowTrigger: $scope.openCompanyFromTable
  };

  $scope.accountFilterChange = function() {
    $scope.paginatedAccounts.length = 0;
    $scope.paginatedAccounts = $filter('commonFilter')(
      $scope.selectedCompany.accounts,
      $scope.search.account,
      $scope.accountTableConfig.header
    );
    $scope.accountsTableChanged.changed = true;
  };

  $scope.clearSearch = function() {
    $scope.search.account = '';
    $scope.paginateAccounts();
  };

  $scope.companyFilterChange = function() {
    $scope.paginatedCompanies.length = 0;
    $scope.paginatedCompanies = $filter('commonFilter')(
      $scope.companiesAccounts,
      $scope.search.company,
      $scope.companiesTableConfig.header
    );
    $scope.companiesTableChanged.changed = true;
  };

  $scope.paginatedAccounts = [];
  $scope.paginateAccounts = function() {
    $scope.paginatedAccounts.length = 0;
    $scope.paginatedAccounts.push.apply($scope.paginatedAccounts, $scope.selectedCompany.accounts);
    $scope.accountsTableChanged.changed = true;
  };

  $scope.clearSearchBar = function() {
    this.search.company = '';
    $scope.companyFilterChange();
  };

  $scope.accountsTableChanged = {
    changed: false
  };
  $scope.accountTableConfig = {
    tableName: 'account',
    header: [
      { name: 'ID', field: 'id', components: [{ field: 'id' }] },
      { name: 'Name', field: 'name', components: [{ field: 'name' }] },
      {
        name: 'Status',
        field: 'status',
        components: [{ field: 'status', filter: 'capitalize', getCSSClass: getStatusCSSClass }]
      },
      { name: 'Country', field: 'countryCode', components: [{ field: 'countryCode', filter: 'uppercase' }] },
      { name: 'Currency', field: 'currency', components: [{ field: 'currency', filter: 'uppercase' }] },
      { name: 'Timezone', field: 'timezone', components: [{ field: 'timezone' }] },
      { name: 'Type', field: 'accountTypeName', components: [{ field: 'accountTypeName', filter: 'capitalize' }] }
    ],
    defaultOrderField: 'id',
    filter: '',
    rowTrigger: $scope.openAccount
  };

  function getStatusCSSClass(status) {
    const rowStatus = status.toLowerCase();
    if (['active', 'pending', 'paused', 'expired'].includes(rowStatus)) {
      return `status ${rowStatus}-status`;
    }
    return '';
  }
}
