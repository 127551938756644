import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const RedirectIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <g clip-path="url(#clip0_12684_28624)">
      <path
        d="M22.1667 3.5H5.83333C4.53833 3.5 3.5 4.55 3.5 5.83333V22.1667C3.5 23.45 4.53833 24.5 5.83333 24.5H22.1667C23.45 24.5 24.5 23.45 24.5 22.1667V5.83333C24.5 4.55 23.45 3.5 22.1667 3.5ZM22.1667 22.1667H5.83333V5.83333H22.1667V22.1667ZM12.8333 19.8333H15.1667V15.1667H19.8333V12.8333H15.1667V8.16667H12.8333V12.8333H8.16667V15.1667H12.8333V19.8333Z"
        fill="#193238"
      />
    </g>
    <defs>
      <clipPath id="clip0_12684_28624">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'GtRedirect'
);

export default RedirectIcon;
