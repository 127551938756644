import { isEmpty } from 'lodash';

import { BudgetType, BudgetLevel } from '../../../api/types/common';

campaignSettingsController.$inject = [
  '$state',
  '$scope',
  '$q',
  'campaignManagerFactory',
  'searchFactory',
  'xadcmsFactory',
  'generalUtilsFactory',
  'staticDataFactory',
  'companyAndAccountFactory',
  'modalFactory',
  'featureFlagFactory'
];

/**
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {ng.IQService} $q
 * @param {import('../../../factories/types').CampaignManagerService} campaignManagerFactory
 * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @param {import('../../../factories/static_data.fac').IStaticDataFactory} staticDataFactory
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 */
export function campaignSettingsController(
  $state,
  $scope,
  $q,
  campaignManagerFactory,
  searchFactory,
  xadcmsFactory,
  generalUtilsFactory,
  staticDataFactory,
  companyAndAccountFactory,
  modalFactory,
  featureFlagFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;

  $scope.progress = false;
  $scope.error = '';

  $scope.vars = {
    selectedCategory: null,
    billingSourceSelected: null
  };
  $scope.isChannelTenant = companyAndAccountFactory.selectedTenant?.type === 2;
  $scope.selectedAccount = companyAndAccountFactory.selectedAccount;
  $scope.budgetLevel = {
    value: BudgetLevel.ADGROUP
  };
  $scope.budgetSettingsTooltip = staticDataFactory.budgetSettingsTooltip;

  $scope.crossDeviceEnabledCountries = {
    US: 'United States'
  };
  $scope.accountCountryCode = companyAndAccountFactory.selectedAccount?.countryCode;

  function fetchCampaignAfterSave() {
    $state.reload();
  }

  $scope.init = function() {
    $scope.addPixelProgress = false;
    $scope.saveSettingsProgress = false;
    $scope.campaign = {};

    $scope.dartBillingAccounts = {
      values: null
    };

    $scope.$emit('in progress');

    $scope.progress = true;

    var selectedCamapaignSettingsDfd = campaignManagerFactory.getSelectedCampaignWithSettings();
    var dartAccountDfd = staticDataFactory.getDartBillingAccounts();

    $q.all([selectedCamapaignSettingsDfd, dartAccountDfd])
      .then(function(data) {
        $scope.campaign = angular.copy(data[0]);
        $scope.populateInputFields($scope.campaign);
        $scope.billingSourceActive(data[0].settings.category.billing_source_id);

        $scope.dartBillingAccounts.values = angular.copy(data[1]);
        if (companyAndAccountFactory.selectedAccount)
          $scope.dartAccount = staticDataFactory.setDartBillingAccount(
            $scope.dartBillingAccounts.values,
            companyAndAccountFactory.selectedAccount
          );
      })
      .finally(function() {
        $scope.$emit('end progress');
      });

    $scope.$watch('vars.selectedCategory', function() {
      if (
        $scope.vars.selectedCategory &&
        $scope.vars.selectedCategory.hasOwnProperty('parentsParentCategoryName') &&
        $scope.vars.selectedCategory.hasOwnProperty('parentCategoryName')
      ) {
        $scope.categoryPath =
          $scope.vars.selectedCategory.parentsParentCategoryName +
          ' / ' +
          $scope.vars.selectedCategory.parentCategoryName +
          ' / ' +
          $scope.vars.selectedCategory.name;
      }
    });
  };

  $scope.showChangeBudgetLevelToCampaignConfirm = function(campaign) {
    var modalSetting = {
      title: 'Move Ad Group Budgets to Campaign Level',
      message:
        "You're about to change your budget type. Your Ad Group Budgets will be consolidated into a single Campaign Budget that will be shared across all ad groups. Campaign Budgets can accelerate ad spend by allowing ad groups with more available inventory to spend a larger portion of the total budget. \n\n Once you save this change you will not be able to change back to Ad Group Budgets for this campaign. \n\n Click SAVE to move Ad Group Budgets to a single Campaign Budget.",
      noText: 'Cancel',
      yesText: 'Save',
      yesClass: 'button--green',
      size: 'lg'
    };

    var handlers = {
      confirm: function() {
        var campaignCopy = angular.copy(campaignManagerFactory.selectedCampaign);
        return campaignManagerFactory
          .saveCampaign(campaignCopy, true)
          .then(function(campaign) {
            $scope.$emit('success-toast', { message: 'Successfully updated campaign.' });
            fetchCampaignAfterSave();
          })
          .catch(function(data) {
            $scope.error = data.message;
            $scope.$emit('failed-toast', { message: data.message });
            $scope.budgetLevel.value = BudgetLevel.ADGROUP;
          });
      },
      cancel: function() {
        $scope.budgetLevel.value = BudgetLevel.ADGROUP;
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.changeCampaignBudgetLevel = function() {
    /*
        If not campaign.budgetAtCampaignLevel and budgetLevel.value === BudgetLevel.CAMPAIGN, then show a confirm screen to the
        user. Upon confirming that the user will not be able to change this back to adgroup. Send a request to the backend
        to save the campaign with campaign budget that is the sum of all of its adgroups.
         */
    if (!$scope.campaign.budgetAtCampaignLevel && $scope.budgetLevel.value === BudgetLevel.CAMPAIGN) {
      $scope.showChangeBudgetLevelToCampaignConfirm($scope.campaign);
    }
  };

  $scope.populateInputFields = function(campaign) {
    if (campaign.budgetAtCampaignLevel) {
      $scope.budgetLevel.value = BudgetLevel.CAMPAIGN;
    }
    if (!isEmpty(campaign.settings)) {
      var category = {
        name: campaign.settings.category.categoryName,
        sicCode: campaign.settings.category.sicCode
      };
      $scope.vars.selectedCategory = category;

      $scope.isDailyBudget = campaign.budgetType == BudgetType.DAILY;

      if (
        campaign.settings.category.hasOwnProperty('parentsParentCategoryName') &&
        campaign.settings.category.hasOwnProperty('parentCategoryName')
      ) {
        $scope.categoryPath =
          campaign.settings.category.parentsParentCategoryName +
          ' / ' +
          campaign.settings.category.parentCategoryName +
          ' / ' +
          campaign.settings.category.categoryName;
      }
    }

    $scope.startDate = generalUtilsFactory.getCorrectedJSdateFromString(campaign.timeframe.start);
    $scope.endDate = generalUtilsFactory.getCorrectedJSdateFromString(campaign.timeframe.end);

    $scope.maxCampaignDate = null;
    $scope.minCampaignDate = null;
    for (var i = 0; i < campaign.adGroups.length; i++) {
      if (campaign.adGroups[i].status == 'Active') {
        var d1 = generalUtilsFactory.getCorrectedJSdateFromString(campaign.adGroups[i].timeframe.start);
        var d2 = generalUtilsFactory.getCorrectedJSdateFromString(campaign.adGroups[i].timeframe.end);
        if (!$scope.maxCampaignDate || (d2 && d2 > $scope.maxCampaignDate)) {
          $scope.maxCampaignDate = d2;
        }

        if (!$scope.minCampaignDate || (d1 && d1 < $scope.minCampaignDate)) {
          $scope.minCampaignDate = d1;
        }
      }
    }
    if (!$scope.maxCampaignDate) {
      $scope.maxCampaignDate = $scope.startDate;
    }

    // campaign manager/ops/sales fields
    if (!isEmpty(campaign.settings.users)) {
      angular.copy(campaign.settings.users, $scope.campaignUsers);
    }

    $scope.campaign.frequencyUnlimited = true;
    if ($scope.campaign.freqCapValue || $scope.campaign.freqCapDuration) {
      $scope.campaign.frequencyUnlimited = false;
    }
  };

  $scope.frequencyCappingChange = function() {
    if ($scope.campaign.frequencyUnlimited) {
      $scope.campaign.freqCapValue = null;
      $scope.campaign.freqCapDuration = null;
    } else {
      $scope.campaign.freqCapDuration = 1;
    }
  };

  $scope.saveCampaignSettings = function() {
    $scope.error = '';

    $scope.campaign.settings.category = $scope.vars.selectedCategory;
    $scope.campaign.dartBillingAccount = $scope.dartAccount;
    $scope.campaign.billing_source = $scope.vars.billingSourceSelected.id;
    $scope.saveSettingsProgress = true;
    campaignManagerFactory
      .saveCampaign($scope.campaign)
      .then(
        function(campaign) {
          $scope.$emit('success-toast', { message: 'Updated campaign successfully' });
        },
        function(data) {
          $scope.error = data.message;
        }
      )
      .finally(function() {
        $scope.saveSettingsProgress = false;
        fetchCampaignAfterSave();
      });
  };

  $scope.searchCategories = function(query) {
    var filter = {
      targeting_search: 1,
      entity_types: 'campaign_category'
    };
    return searchFactory.getSearchResults(query, filter);
  };

  $scope.searchSalesUsers = function(query) {
    return xadcmsFactory.getUsersAutoComplete(query, 'Direct Sales');
  };

  $scope.searchManagerUsers = function(query) {
    return xadcmsFactory.getUsersAutoComplete(query, 'Account Manager');
  };

  $scope.searchAdOpsUsers = function(query) {
    return xadcmsFactory.getUsersAutoComplete(query, 'Direct Ops');
  };

  $scope.init();

  $scope.stringComparator = function(v1, v2) {
    // Compare strings alphabetically
    return (v1.value || '').localeCompare(v2.value);
  };

  $scope.billingSourceActive = function(bill_id) {
    staticDataFactory.getBillingSources().then(function() {
      $scope.billingSourcesData = staticDataFactory.billingSources.billing_sources;
      $scope.vars.billingSourceSelected = $scope.billingSourcesData.filter(function(item) {
        return item.id === bill_id;
      })[0];
    });
  };

  $scope.billingSourceUpdate = function() {
    $scope.campaign.enableAutoreconcile = 0;
  };

  $scope.setCategoryAutoCompleteFieldValidity = function() {
    var form = $scope.campaignSettingsForm;
    form.categoryAutoComplete.$setValidity('categoryValueNotFromSelection', true);
  };
}
