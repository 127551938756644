import * as React from 'react';
import Typography from '@material-ui/core/Typography';

export interface HeadingProps {
  /**
   * The size of the heading you want
   */
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: React.ReactNode;
  /**
   * styles you want to apply to the
   */
  className?: string;
}

export function Heading(props: HeadingProps) {
  const { className, ...others } = props;
  // Typography variant={variant}
  return (
    <Typography className={className} {...others}>
      {props.children}
    </Typography>
  );
}
