import GAEventList from '../../../components/googleAnalytics/eventList';

newCompanyController.$inject = [
  '$scope',
  '$uibModalInstance',
  'companyAndAccountFactory',
  'featureFlagFactory',
  '$window'
];

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 */
export function newCompanyController($scope, $uibModalInstance, companyAndAccountFactory, featureFlagFactory, $window) {
  $scope.featureFlagFactory = featureFlagFactory;

  $scope.progress = false;
  $scope.companyName = null;
  $scope.companyInvoiceId = null;

  $scope.close = function(result) {
    $uibModalInstance.close(result);
  };

  $scope.createNewCompany = function() {
    if ($scope.newCompanyForm.$invalid) {
      for (var errorField in $scope.newCompanyForm.$error) {
        if (
          $scope.newCompanyForm.$error.hasOwnProperty(errorField) &&
          $scope.newCompanyForm.$error[errorField].length > 0
        ) {
          $scope.newCompanyForm.$error[errorField].forEach(function(value) {
            value.$setTouched();
          });
        }
      }

      return;
    }
    $scope.progress = true;
    companyAndAccountFactory
      .createNewCompany($scope.companyName, $scope.companyInvoiceId)
      .then(
        function(response) {
          $scope.close(response.data[0]);
          // We have successfully created an organization, logging this event into GA
          typeof $window.gtag === 'function' &&
            $window.gtag('event', GAEventList.ORGANIZATION_CREATE, {
              organization_id: response.data[0].id,
              gt_user_id: $window?.user?.id,
              tenant_id: companyAndAccountFactory.selectedTenant?.tenant_id
            });
        },
        function(msg) {
          var errorElement = $('.modal-body .error-message').addClass('active');
          errorElement.text(msg);
        }
      )
      .finally(function() {
        $scope.progress = false;
      });
  };
}
