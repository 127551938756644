import * as React from 'react';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ModalBase } from './ModalBase';
import { PrimaryButton } from '../Button';
import { styled } from '../../../utils/react/mui_styled';

interface RedirectionModalProps {
  url: string;
  open: boolean;
  redirectionText: string;
  continueButtonLabel?: string | null;
  cancelButtonLabel?: string | null;
  onClose: () => void;
}

const ModalText = styled('span')(({ theme }) => ({
  color: 'var(--color-on-primary)',
  fontFamily: 'var(--font-family-regular)',
  fontSize: 'var(--font-size-regular)',
  fontWeight: 'var(--font-weight-bold)',
  lineHeight: 'var(--line-height)'
}));

const ActionSection = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'base-line',
  justifyContent: 'flex-start',
  width: '100%',
  paddingTop: '16px',
  marginBottom: '24px',

  '& .button--tertiary-green': {
    marginLeft: '16px !important'
  }
}));

const StyledButton = styled(Button)({
  backgroundColor: 'var(--color-primary)',
  borderRadius: 'var(--border-radius)',
  border: '1px solid var(--color-primary)',
  '&:hover': {
    backgroundColor: '#83bc20'
  }
});

const StyledModal = styled(ModalBase)({
  '& .MuiDialog-paperWidthSm': {
    width: '578px',
    height: '190px',
    borderRadius: 'var(--border-radius)',
    border: '1px solid var(--color-outline)',
    background: 'var(--color-background)',
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.3)'
  },
  '& .MuiDialogContent-root': {
    minHeight: '0px'
  },
  '& .MuiDialogActions-root': {
    marginLeft: '32px',
    marginRight: '32px',
    paddingLeft: '0px'
  }
});

export function RedirectionModal({
  url,
  open,
  onClose,
  redirectionText,
  cancelButtonLabel = null,
  continueButtonLabel = null,
  ...others
}: RedirectionModalProps) {
  const handleRedirect = () => {
    window.open(url, '_blank');
    onClose();
  };

  const actionSection = (
    <ActionSection>
      <StyledButton
        className="button-v2 button--green"
        disableElevation
        onClick={handleRedirect}
        variant="contained"
        endIcon={<OpenInNewIcon />}
      >
        {continueButtonLabel ? continueButtonLabel : 'Continue'}
      </StyledButton>
      <PrimaryButton
        className="button-v2 button--tertiary-green"
        title={cancelButtonLabel ? cancelButtonLabel : 'Cancel'}
        onClick={onClose}
      />
    </ActionSection>
  );

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      actionSection={actionSection}
      showCloseButton={true}
      {...others}
      className="redirection-modal"
    >
      <ModalText>{redirectionText}</ModalText>
    </StyledModal>
  );
}
