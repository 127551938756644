import { safeApply } from '../../factories/general_utils';

export function autocompleteDropdown() {
  var directive = {
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.autocomplete-dropdown.html',
    bindToController: true,
    controller: AutocompleteDropdownController,
    controllerAs: 'vm',
    link: link,
    restrict: 'E',
    scope: {
      resetInput: '=?',
      searchFn: '&',
      config: '=?',
      callback: '&?'
    }
  };
  return directive;

  function link(scope, element, attrs) {}
}

AutocompleteDropdownController.$inject = ['$scope'];

function AutocompleteDropdownController($scope) {
  var vm = this;
  var defaultConfig = {
    key: null,
    minlength: 3,
    selectPlaceholder: 'All options',
    inputPlaceholder: 'Enter search text'
  };

  this.$onInit = () => {
    vm.config = vm.config || angular.copy(defaultConfig);
  };

  vm.options = [];
  vm.searchText = null;
  vm.selectedOption = null;
  vm.showDropdown = false;
  vm.progress = false;

  vm.reset = reset;
  vm.clearInput = clearInput;
  vm.toggleDropdown = toggleDropdown;
  vm.searchOptions = searchOptions;
  vm.selectOption = selectOption;

  $scope.$watch('vm.resetInput', function(newVal) {
    if (newVal) {
      vm.clearInput();
      vm.resetInput = false;
    }
  });

  function reset() {
    vm.options = [];
    vm.searchText = null;
  }

  function clearInput() {
    vm.reset();
    vm.selectedOption = null;

    if (angular.isFunction(vm.callback)) {
      vm.callback({ selected: vm.selectedOption });
    }
  }

  function toggleDropdown(boolean) {
    vm.showDropdown = boolean || !vm.showDropdown;

    if (!vm.showDropdown) {
      vm.reset();
    }
  }

  function searchOptions() {
    if (!vm.searchText || vm.searchText.length < vm.config.minlength) {
      return;
    }
    vm.progress = true;
    vm.searchFn({ query: vm.searchText }).then(function(data) {
      vm.options = data;
      vm.progress = false;
      safeApply($scope);
    });
  }

  function selectOption(option) {
    vm.selectedOption = option;
    vm.toggleDropdown(false);

    if (angular.isFunction(vm.callback)) {
      vm.callback({ selected: vm.selectedOption });
    }
  }
}
