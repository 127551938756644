shortnumber.$inject = ['$filter'];

export function shortnumber($filter) {
  var DEFAULT_FRACTION_SIZE = 2;

  return function(input, fractionSize, integer) {
    // if null is specified, show '--', as in 'no data'
    if (input == null) {
      return '--';
    }

    if (!angular.isNumber(input)) {
      input = parseFloat(input);
    }

    if (isNaN(input)) {
      return '';
    }

    if (input === Number.POSITIVE_INFINITY) {
      return '∞';
    }

    if (input === Number.NEGATIVE_INFINITY) {
      return '-∞';
    }

    if (angular.isUndefined(fractionSize) || fractionSize === null || !angular.isNumber(fractionSize)) {
      fractionSize = DEFAULT_FRACTION_SIZE;
    }

    if (isNaN(fractionSize) || fractionSize === Number.POSITIVE_INFINITY || fractionSize === Number.NEGATIVE_INFINITY) {
      fractionSize = DEFAULT_FRACTION_SIZE;
    }

    if (fractionSize < 0) {
      fractionSize = 0;
    }

    var neg = input < 0,
      result,
      postFixIndex = 0,
      TRILLION = Math.pow(10, 12),
      BILLION = Math.pow(10, 9),
      MILLION = Math.pow(10, 6),
      THOUSAND = Math.pow(10, 3),
      postFixes = ['', 'K', 'M', 'B', 'T'],
      i;
    input = Math.abs(input);

    if (input >= TRILLION) {
      input = input / TRILLION;
      postFixIndex = 4;
    } else if (input >= BILLION && input < TRILLION) {
      input = input / BILLION;
      postFixIndex = 3;
    } else if (input >= MILLION && input < BILLION) {
      input = input / MILLION;
      postFixIndex = 2;
    } else if (input >= THOUSAND && input < MILLION) {
      input = input / THOUSAND;
      postFixIndex = 1;
    }
    if (postFixIndex === 0 && integer) {
      fractionSize = 0;
    }
    result = $filter('number')(input, fractionSize);

    if (result.search(/NaN/) === 0) {
      return '';
    }

    if (result.search(/1,000/) === 0 && postFixIndex < postFixes.length - 1) {
      result = '1';
      postFixIndex += 1;

      if (fractionSize > 0) {
        result += '.';
      }

      for (i = 0; i < fractionSize; i += 1) {
        result += '0';
      }
    }

    result += postFixes[postFixIndex];

    if (neg) {
      result = '-' + result;
    }

    return result;
  };
}
