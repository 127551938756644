import * as t from 'io-ts';
import * as tx from '../def/io-ts';

export enum CreativeAssetStatus {
  LINKED = 'Linked',
  UNLINKED = 'Unlinked',
  PENDING = 'Pending',
  PAUSED = 'Paused',
  ACTIVE = 'Active',
  EXPIRED = 'Expired'
}

const CreativeAssetStatusEnum = tx.enumeration(CreativeAssetStatus, 'CreativeAssetStatus');

const CreativeAssetResponse = t.type(
  {
    asset_id: t.number,
    asset_name: t.string,
    file_extension: t.string,
    created_by: t.string,
    status: CreativeAssetStatusEnum,
    created_date: t.string,
    asset_source: t.string,
    asset_type: t.string,
    size: t.string
  },
  'CreativeAssetResponse'
);

export const PostFetchAssetsRequest = t.type(
  {
    status: t.array(CreativeAssetStatusEnum),
    account_ids: t.array(tx.Id),
    asset_types: t.array(t.string),
    organization_ids: t.array(tx.Id),
    size: t.array(t.string),
    sort_by: t.string,
    sort_desc: t.boolean,
    search: t.string,
    page: t.number,
    limit: t.number,
    full_load: t.boolean
  },
  'CreativeAssetsRequest'
);

export type CreativeAssetResponse = t.TypeOf<typeof CreativeAssetResponse>;

/**
 * Describes API endpoint call for: `/data/creative_assets/v2`
 * */

export type PostFetchAssetsRequest = t.TypeOf<typeof PostFetchAssetsRequest>;

export const PostFetchAssetsResponse = t.type(
  {
    creative_assets: t.array(CreativeAssetResponse)
  },
  'PostFetchAssetsResponse'
);
export type PostFetchAssetsResponse = t.TypeOf<typeof PostFetchAssetsResponse>;

/**
 * Describes API endpoing call for: `/data/creative_assets/deletions`
 */
export const DeleteResponse = t.type(
  {
    sucess: t.array(tx.Id),
    failure: t.array(tx.Id)
  },
  'DeleteResponse'
);
export type DeleteResponse = t.TypeOf<typeof DeleteResponse>;

export const DeleteRequest = t.type(
  {
    asset_ids: t.array(tx.Id)
  },
  'DeleteRequest'
);
export type DeleteRequest = t.TypeOf<typeof DeleteRequest>;

export const PutRequest = t.type(
  {
    asset_name: t.string
  },
  'PutRequest'
);
export type PutRequest = t.TypeOf<typeof PutRequest>;

export const PutResponse = t.type(
  {
    success: t.boolean
  },
  'PutResponse'
);
export type PutResponse = t.TypeOf<typeof PutResponse>;

export const CreativeAssetInsertInAdgroupResponse = t.type(
  {
    error_message: t.union([t.string, t.undefined, t.null]),
    name: t.string,
    success: t.boolean
  },
  'CreativeAssetInsertInAdgroupResponse'
);
export type CreativeAssetInsertInAdgroupResponse = t.TypeOf<typeof CreativeAssetInsertInAdgroupResponse>;

/**
 * Describes API endpoint call for: `/data/creative_assets` METHOD: post
 */
export const PostResponse = t.type(
  {
    result: t.array(CreativeAssetInsertInAdgroupResponse)
  },
  'PostResponse'
);
export type PostResponse = t.TypeOf<typeof PostResponse>;

const UploadedFile = t.type(
  {
    file_type: tx.nullable(t.string),
    name: t.string,
    url: t.string
  },
  'UploadedFile'
);

export type UploadedFile = t.TypeOf<typeof UploadedFile>;

export const PostRequest = t.type(
  {
    creative_assets: t.array(UploadedFile)
  },
  'PostRequest'
);
export type PostRequest = t.TypeOf<typeof PostRequest>;

export const PutStatusRequest = t.type(
  {
    status: t.literal('paused')
  },
  'PutStatusRequest'
);
export type PutStatusRequest = t.TypeOf<typeof PutStatusRequest>;
