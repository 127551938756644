import * as React from 'react';
import { format, parse } from 'date-fns';
import { MuiPickersUtilsProvider, DatePicker as MaterialDatePicker } from '@material-ui/pickers';
import { TextField as MaterialTextField, Box, InputLabel, Popover } from '@material-ui/core';
import { PrimaryButton } from '../Button';
import DateFnsUtils from '@date-io/date-fns';
import { styled } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import './Datepicker.scss';

const StyledPrimaryButton = styled(PrimaryButton)({
  display: 'flex',
  justifyContent: 'flexstart',
  marginLeft: '20',
  alignItems: 'center',
  '& .MuiButton-label': { fontWeight: 'var(--font-weight-light-bold)' }
});

export interface DatePickerProps {
  /** Callback function that would be called when the date changes */
  onChange: (date: Date | null) => void;
  /** When there is an error string the text box would highlighted */
  error?: string;
  /** The value we want on the picker. This is a controlled component so the user has to change the date*/
  value?: Date | null;
  /** The lable we want on top of the date picker's text box*/
  label: string;
  /** The element id assigned to the popup component */
  popupId?: string;
  /** Styles that are to be applied on the container. You can also style the inner components by nesting styles */
  className?: string;
  /** The placeholder you want in the text box */
  placeholder?: string;
  /**
   * Date format to use for formatting and parsing.
   * @default 'yyyy-MM-dd'
   */
  dateFormat?: string;
  /**
   * Set to true if you want the field to take the fullWidth of the container
   */
  fullWidth?: boolean;
}

interface DatePickerComponentProps {
  onChange: (date: Date | null) => void;
  closeMenu: () => void;
  value: Date | null;
}

interface StyleMaterialTextFieldProps {
  theme?: Theme;
  hasvalue?: boolean;
  error?: boolean;
}

const ActionContainer = styled(Box)({
  marginLeft: '46px',
  marginTop: '8px',
  paddingBottom: '32px'
});

const StyleMaterialTextField = styled(MaterialTextField)<StyleMaterialTextFieldProps>(({ theme, hasvalue, error }) => ({
  minWidth: 100,
  height: 'var(--action-item-height)',
  borderRadius: 'var(--border-radius)',
  fontSize: 'var(--font-size-regular)',
  color: 'var(--color-on-primary)',
  background: error
    ? 'var(--color-error-container)'
    : hasvalue
    ? 'var(--color-primary-container)'
    : 'var(--color-background)',
  border: error
    ? '1px solid var(--color-error)'
    : hasvalue
    ? '1px solid var(--color-primary)'
    : '1px solid var(--color-outline)',

  '&:hover': {
    border: '1px solid var(--color-link)'
  },
  '& .MuiInputBase-root': {
    width: '388px'
  },

  '& .MuiFormHelperText-root': {
    color: 'var(--color-error)',
    fontFamily: 'var(--font-family-regular)',
    fontSize: 'var( --font-size-small)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--line-height)',
    marginBottom: '15px',
    marginTop: '0px'
  },

  '& input': {
    height: '20px',
    padding: '12px'
  }
}));

const DatePickerContainer = styled('div')({
  marginTop: 16,
  marginBottom: 8,
  width: '100%'
});

StyleMaterialTextField.displayName = 'StyleMaterialTextField';

function DatePickerComponent(props: DatePickerComponentProps) {
  const [dateValue, changeDate] = React.useState<Date | null>(props.value || new Date(Date.now()));

  const saveDate = () => {
    props.onChange(dateValue);
    props.closeMenu();
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MaterialDatePicker
        disableToolbar
        variant="static"
        emptyLabel="Select date"
        inputVariant="outlined"
        value={dateValue}
        onChange={changeDate}
      />
      <ActionContainer display="flex">
        <StyledPrimaryButton title="Ok" className="button-v2 button--green" onClick={saveDate} />
        <StyledPrimaryButton
          title="Cancel"
          className="button-v2 button--tertiary-green"
          outlined
          onClick={props.closeMenu}
        />
      </ActionContainer>
    </MuiPickersUtilsProvider>
  );
}

const DatePickerPopover = styled(Popover)(({ theme }) => ({
  '&>.MuiPopover-paper': {
    borderRadius: 'var(--border-radius)',
    border: '1px solid var(--color-outline)',
    background: 'var( --color-background)',
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.3)'
  }
}));

const defaultDateFormat = 'yyyy-MM-dd';

function formatDate(value: Date | null, dateFormat: string): string {
  return value ? format(value, dateFormat) : '';
}

export function DatePicker(props: DatePickerProps) {
  const {
    value = null,
    error = null,
    label,
    onChange,
    popupId,
    className,
    placeholder = '',
    dateFormat = defaultDateFormat,
    fullWidth
  } = props;
  const anchor = React.useRef<HTMLElement>();
  const [open, setOpen] = React.useState(false);
  const [formattedDate, setFormattedDate] = React.useState(formatDate(value, dateFormat));

  React.useEffect(() => {
    setFormattedDate(formatDate(value, dateFormat));
  }, [value]);

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const handleInputChange: React.ComponentProps<typeof MaterialTextField>['onChange'] = event => {
    setFormattedDate(event.target.value);
    const parsedDate = parse(event.target.value, dateFormat, 0);
    if (parsedDate.valueOf()) {
      onChange(parsedDate);
    }
  };

  return (
    <DatePickerContainer className={className}>
      <InputLabel>{label}</InputLabel>
      <StyleMaterialTextField
        value={formattedDate}
        onChange={handleInputChange}
        placeholder={placeholder}
        variant="standard"
        onClick={openMenu}
        error={Boolean(error)}
        helperText={error}
        inputRef={anchor}
        fullWidth={fullWidth}
        margin={'dense'}
        InputProps={{
          disableUnderline: true
        }}
        hasvalue={Boolean(formattedDate)}
      />
      <DatePickerPopover
        id={popupId}
        keepMounted
        open={open}
        onClose={closeMenu}
        anchorEl={anchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <DatePickerComponent value={value} onChange={onChange} closeMenu={closeMenu} />
      </DatePickerPopover>
    </DatePickerContainer>
  );
}
