export function highChartsFactory() {
  var highChartsFactory = {
    updateMetrics: updateMetrics,
    changeMetrics: changeMetrics
  };

  function updateMetrics(options) {
    if (!options || !angular.isObject(options)) {
      return;
    }

    var yAxisMetrics = [];
    var prop;

    for (prop in options) {
      if (options.hasOwnProperty(prop)) {
        if (options[prop].visible) {
          yAxisMetrics.push({ value: prop });
        }
      }
    }

    return yAxisMetrics;
  }

  function changeMetrics(options, yMetrics, metrics, title, index) {
    var prop;
    var i;

    for (prop in options) {
      if (options.hasOwnProperty(prop)) {
        options[prop].visible = false;
      }
    }

    for (i = 0; i < yMetrics.length; i++) {
      prop = yMetrics[i].value;
      options[prop].visible = true;
    }

    try {
      var filtered = metrics.filter(function(item) {
        return item.value === yMetrics[index].value;
      });

      if (filtered.length) {
        title = filtered[0].displayName;
      }
    } catch (e) {
      console.error(e);
    }

    return {
      options: options,
      yMetrics: yMetrics,
      metrics: metrics,
      title: title
    };
  }

  return highChartsFactory;
}
