import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const AudioIcon = createSvgIcon(
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_176_3418)">
      <path
        d="M6 18.5V30.5H14L24 40.5V8.49996L14 18.5H6ZM20 18.16V30.84L15.66 26.5H10V22.5H15.66L20 18.16ZM33 24.5C33 20.96 30.96 17.92 28 16.44V32.54C30.96 31.08 33 28.04 33 24.5ZM28 6.95996V11.08C33.78 12.8 38 18.16 38 24.5C38 30.84 33.78 36.2 28 37.92V42.04C36.02 40.22 42 33.06 42 24.5C42 15.94 36.02 8.77996 28 6.95996Z"
        fill="#0A3F36"
      />
    </g>
    <defs>
      <clipPath id="clip0_176_3418">
        <rect width="48" height="48" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default AudioIcon;
