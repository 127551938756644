import { TenantPaymentType } from '../../../api/types/common';
import GAEventList from '../../../components/googleAnalytics/eventList';

const FULLY_MANAGED_TENANT_ID = 1;

NewAccountController.$inject = [
  '$scope',
  '$state',
  '$rootScope',
  '$uibModalInstance',
  'staticDataFactory',
  'searchFactory',
  'campaignManagerFactory',
  'companyAndAccountFactory',
  'tenantFactory',
  'data',
  'featureFlagFactory',
  'paymentsFactory',
  '$window'
];

/**
 * @param {*} $scope
 * @param {*} $state
 * @param {*} $rootScope
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 * @param {import('../../../factories/static_data.fac').IStaticDataFactory} staticDataFactory
 * @param {*} searchFactory
 * @param {*} campaignManagerFactory
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {*} tenantFactory
 * @param {*} data
 * @param {*} featureFlagFactory
 */
export function NewAccountController(
  $scope,
  $state,
  $rootScope,
  $uibModalInstance,
  staticDataFactory,
  searchFactory,
  campaignManagerFactory,
  companyAndAccountFactory,
  tenantFactory,
  data,
  featureFlagFactory,
  paymentsFactory,
  $window
) {
  $scope.featureFlagFactory = featureFlagFactory;
  $scope.companyAndAccountFactory = companyAndAccountFactory;
  $scope.paymentsFactory = paymentsFactory;

  // We are no longer allowing US/CA for new accounts
  // TODO: Make this change on the backend instead
  $scope.countries = {
    US: data.countries.US,
    CA: data.countries.CA
  };
  $scope.accountVerticals = data.accountVerticals;
  $scope.saveProgress = false;
  $scope.selectedAccount = angular.copy(companyAndAccountFactory.dummyAccount);

  $scope.booleans = {
    hideBrandSelector: true
  };

  $scope.dartBillingAccounts = {
    values: data.dartBillingAccounts.values
  };

  $scope.autocompleteVars = {
    searchBrandText: ''
  };

  $scope.selectedDartBillingAccount = {
    value: null
  };

  $scope.isAccountLevelCreditCardsEnabled =
    companyAndAccountFactory.selectedTenant?.payment_type === TenantPaymentType.ACCOUNT_LEVEL_CREDIT_CARD;

  $scope.init = function() {
    createCountryList();
    createAccountTypeList();

    if (tenantFactory.tenant.serving_country && tenantFactory.tenant.serving_country.code) {
      $scope.selectedAccount.countryCode = tenantFactory.tenant.serving_country.code;
    }
    $scope.selectedCountryChange();
    $scope.selectedAccountTypeChange();
  };

  function createAccountTypeList() {
    $scope.accountTypes = Object.values(companyAndAccountFactory.accountTypes);
    if (tenantFactory.tenant.tenant_id === FULLY_MANAGED_TENANT_ID) {
      $scope.accountTypes = Object.values(companyAndAccountFactory.fullyManagedAccountTypes);
    }
  }

  function createCountryList() {
    $scope.countryList = Object.values($scope.countries);

    // Remove countries from countryList that the tenant isn't allowed to target
    $scope.countryList = $scope.countryList.filter(country =>
      tenantFactory.tenant.serving_eligible_countries.includes(country.code)
    );

    // Credit card tenants (type = 1) are restricted to account currencies in USD only
    if (['CREDIT_CARD', 'CREDIT_CARD_V2'].includes(tenantFactory.tenant.payment_type)) {
      $scope.countryList.forEach(country => (country.currency = [tenantFactory.tenant.currency]));
    }

    // Japanese tenants (Dentsu) are restricted to JPY currency only
    $scope.countryList.forEach(country => {
      if (country.code === 'JP') {
        country.currency = ['JPY'];
      }
    });

    $scope.countryList.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
  }

  $scope.createAccount = function() {
    $scope.saveProgress = true;
    const account = { ...$scope.selectedAccount, card_id: $scope.selectedCard?.card_id };
    companyAndAccountFactory
      .createNewAccount(account)
      .then(
        function(response) {
          // When an account gets created, go to dashboard after loading that account
          var tenantId = companyAndAccountFactory.selectedTenant?.tenant_id;
          var companyId = companyAndAccountFactory.selectedCompanyForOrg?.id;
          var accountId = companyAndAccountFactory.selectedAccountForOrg?.id;

          // Need to make call to backend to set last used account.
          companyAndAccountFactory.clean();

          // We have successfully created an account, logging this event into GA
          typeof $window.gtag === 'function' &&
            $window.gtag('event', GAEventList.ACCOUNT_CREATE, {
              organization_id: companyAndAccountFactory.selectedCompanyForOrg?.id,
              gt_user_id: window?.user?.id
            });
          $scope.close();
          $state.go('campaigns.dashboard', { tenantId, companyId, accountId });
        },
        function(msg) {
          var errorElement = $('.modal-body .error-message').addClass('active');
          errorElement.text(msg);
        }
      )
      .finally(function() {
        $scope.saveProgress = false;
      });
  };

  $scope.selectedAccountTypeChange = function() {
    if ($scope.selectedAccount.accountType == 8) {
      $scope.booleans.hideBrandSelector = true;
    } else {
      $scope.booleans.hideBrandSelector = false;
    }
  };

  $scope.searchBrands = function(query) {
    var filter = {
      targeting_search: 1,
      exclude: 0,
      entity_types: 'brand'
    };
    return searchFactory.getSearchResults(query, filter);
  };

  $scope.selectedCountryChange = function() {
    $scope.selectedCountry = $scope.countryList.filter(
      country => country.code === $scope.selectedAccount.countryCode
    )[0];
    if ($scope.selectedCountry) {
      $scope.selectedAccount.currency = $scope.selectedCountry.currency[0];
      $scope.selectedAccount.timezone = $scope.selectedCountry.timezoneNameList[0];
      $scope.selectedAccount.distanceUnit = $scope.selectedCountry.distanceUnits[0];
      $scope.selectedAccount.language = staticDataFactory.defaultLanguageCode;
      $scope.selectedAccount.brand = null;
      $scope.autocompleteVars.searchBrandText = '';
    }
  };

  $scope.extractDomain = function() {
    /** @type {string | null} */
    var url = $scope.selectedAccount.adomain;
    if (url == null) {
      return;
    }

    var hostName = getHostName(url);
    if (hostName == null) {
      hostName = url;
    }

    var domain = hostName.split('/')[0];
    var parts = hostName.split('.').reverse();

    if (parts != null && parts.length > 1) {
      domain = parts[1] + '.' + parts[0];

      if (parts.length > 2 && parts[1].match(/^(com|edu|gov|net|mil|org|nom|co|name|info|biz)$/i)) {
        domain = parts[2] + '.' + domain;
      }
    }

    $scope.selectedAccount.adomain = domain;
  };

  function getHostName(url) {
    var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2];
    } else {
      return null;
    }
  }

  $scope.close = function(account) {
    $uibModalInstance.close(account);
  };

  $scope.updateDartBillingSource = function() {
    $scope.selectedAccount.dartBillingAccount = $scope.selectedDartBillingAccount.value;
  };

  $scope.selectCard = function(card, cardsLength) {
    if (
      cardsLength === 1 ||
      (cardsLength > 1 &&
        ![
          $scope.paymentsFactory.cardStatus.FAILED,
          $scope.paymentsFactory.cardStatus.PENDING,
          $scope.paymentsFactory.cardStatus.DISPUTE
        ].includes(card.status))
    ) {
      $scope.selectedCard = card;
    }
  };

  $scope.isCardStatusValid = function(card) {
    const invalidStatuses = [
      $scope.paymentsFactory.cardStatus.FAILED,
      $scope.paymentsFactory.cardStatus.PENDING,
      $scope.paymentsFactory.cardStatus.DISPUTE
    ];

    return !invalidStatuses.includes(card.status);
  };

  $scope.init();
}
