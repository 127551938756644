import * as React from 'react';
import { ReportFormHeader } from './report_form_header';
import { ReportForm } from './report_form';
import { ReportSchedulerEditFormModel } from '../model/report_scheduler_edit_form_model';

export interface ReportFormPageProps {
  model: ReportSchedulerEditFormModel;
}

export function ReportFormPage({ model }: ReportFormPageProps) {
  return (
    <div>
      <ReportFormHeader reportName={model.report.name} reportIsNew={!model.report.id} onCancel={model.cancelReport} />
      <ReportForm model={model} />
    </div>
  );
}
