import { adGroupService } from './adgroup.serv';
import { adgroupTargetingFactory } from './adgroup_targeting.fac';
import { AdgroupTargetingService as adgroupTargetingService } from './adgroup_targeting.serv';
import { adGroupTargetingController } from './adgroup_targeting_superform.ctrl';
import { budgetDateController } from './adgroup_budget/budget_date.ctrl';
import { daypartingSelector } from './adgroup_budget/dayparting_selector';
import { trackingPixelAdGroupModalController } from './adgroup_budget/tracking_pixel_adgroup_modal.ctrl';
import {
  CreativeEditorCtrl,
  ceDrag,
  ceResize,
  creativeTextEditor,
  textBoxOptions,
  textOptions
} from './adgroup_creative/creative_text_editor';
import { creativeFullSize } from './adgroup_creative/creative_full_size';
import { CreativeModalInstanceCtrl } from './adgroup_creative/creative_modal_instance.ctrl';
import { imageLandingPageController } from './adgroup_creative/image_landing_page.ctrl';
import { creativeTemplatesFactory } from './adgroup_creative/creative_templates.fac';
import { standardSuperLandingPageController } from './adgroup_creative/standard_and_super_landing_page.ctrl';
import { AdgroupCreativeService } from './adgroup_creative/adgroup_creative.serv';
import { creativeList } from './adgroup_creative/creative_list/creative_list.dir';
import { adgroupGoals } from './adgroup_goals/adgroup_goals.dir';
import { SuperformUploadController } from './adgroup_targeting/superform_upload.ctrl';
import { adgroupDeliveryChannels } from './adgroup_targeting/adgroup_delivery_channels/adgroup_delivery_channels.dir';
import { adgroupTargetingOnpremise } from './adgroup_targeting/adgroup_targeting_onpremise/adgroup_targeting_onpremise.dir';
import { adgroupTargetingAudience } from './adgroup_targeting/adgroup_targeting_audience/adgroup_targeting_audience.dir';
import { adgroupTargetingNeighborhood } from './adgroup_targeting/adgroup_targeting_neighborhood/adgroup_targeting_neighborhood.dir';
import { adgroupTargetingGeotargeting } from './adgroup_targeting/adgroup_targeting_geotargeting/adgroup_targeting_geotargeting.dir';
import { adgroupTargetingWeather } from './adgroup_targeting/adgroup_targeting_weather/adgroup_targeting_weather.dir';
import { weatherOperatorValueInput } from './adgroup_targeting/adgroup_targeting_weather/weather_operator_value_input.dir';
import { adgroupTargetingMeasurement } from './adgroup_targeting/adgroup_targeting_measurement/adgroup_targeting_measurement.dir';
import { AudienceSearchSection } from './adgroup_targeting/audience_search/AudienceSearchSection';
import { newFeature } from './avails_sidebar/new_feature/new_feature.dir';
import { availsSidebar } from './avails_sidebar/avails_sidebar';
import { mapProximityController } from './avails_sidebar/map_proximity.ctrl';
import { ctaButtons } from './cta_buttons/cta_buttons.dir';
import { locationsUpload } from './location_upload/location_upload';
import { selectionListOnpremise } from './selection_list_onpremise/selection_list_onpremise';

import { reactToAngularComponents } from '../../components/reactComponents/reactToAngular';

export const controllers = {
  adGroupTargetingController,
  mapProximityController,
  budgetDateController,
  CreativeModalInstanceCtrl,
  CreativeEditorCtrl,
  imageLandingPageController,
  standardSuperLandingPageController,
  SuperformUploadController,
  trackingPixelAdGroupModalController
};

export const factories = {
  adgroupTargetingFactory,
  creativeTemplatesFactory
};

export const directives = {
  availsSidebar,
  newFeature,
  creativeFullSize,
  ceDrag,
  ceResize,
  creativeTextEditor,
  textBoxOptions,
  textOptions,
  daypartingSelector,
  locationsUpload,
  selectionListOnpremise,
  weatherOperatorValueInput,
  adgroupGoals,
  adgroupTargetingOnpremise,
  adgroupTargetingAudience,
  creativeList,
  adgroupTargetingGeotargeting,
  adgroupTargetingNeighborhood,
  adgroupTargetingWeather,
  ctaButtons,
  adgroupDeliveryChannels,
  adgroupTargetingMeasurement
};

export const services = {
  adGroupService,
  adgroupTargetingService,
  AdgroupCreativeService
};

const reactComponents = {
  AudienceSearchSection
};

export const adgroupReactComponents = reactToAngularComponents(reactComponents);
