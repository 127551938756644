import servicesModule from '../../utils/services';
import { reactToAngularComponents } from '../../components/reactComponents/reactToAngular';
import { CreativeRepositoryV2 } from './creative_repository_v2';

const reactComponents = {
  CreativeRepositoryV2
};

export default angular
  .module('app.creativeRepositoryV2', [servicesModule.name])
  .component(reactToAngularComponents(reactComponents));
