import * as React from 'react';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { QueryResult, ListType } from './index';
import { TabPanel } from './TabPanelProps';
import { styled } from '../../../utils/react/mui_styled';
import { isEmpty } from 'lodash';

export interface AutoCompleteTabHeaderProps {
  tabData: QueryResult;
  selectItems: (selected: ListType[]) => void;
  deselectItems: (selected: ListType[]) => void;
  selectedTabItems: ListType[];
  saveItems: () => void;
  buttonLabel: string;
}

const StyledTab = styled(Tab)({
  textTransform: 'capitalize',
  fontFamily: 'var(--font-family-regular)',
  fontSize: 'var(--font-size-regular)',
  color: 'var(--color-on-primary)',
  lineHeight: 'var(--line-height)',
  '&.Mui-selected': {
    color: 'var(--color-primary)'
  }
});

export function AutoCompleteTabHeader(props: AutoCompleteTabHeaderProps) {
  const { tabData, selectItems, deselectItems, selectedTabItems, saveItems, buttonLabel } = props;
  const [currTabValue, setCurrentTabValue] = React.useState('');

  const setTab = (_e?: React.ChangeEvent<{}>, value?: string) => {
    if (value) {
      setCurrentTabValue(value);
    } else {
      const keys = Object.keys(tabData);
      if (!keys.includes(currTabValue)) {
        // change tabName when it's not in current data's keys
        // and set it to first found key, if any
        // or if the keys list is empty we set it to empty string
        // which means no result found.
        const tabName = keys.find(i => i) ?? '';
        setCurrentTabValue(tabName);
      }
    }
  };
  const getId = tabName => tabName.split(' ').join('_');

  React.useEffect(
    // Whenever new data is received, need to maintain/update tab position
    () => setTab(),
    [tabData]
  );

  return (
    <Box>
      <Box
        borderTop="0"
        sx={{
          backgroundColor: !isEmpty(tabData) ? 'var(--color-primary-container)' : 'var(--color-background)',
          borderRadius: '0px 0px 8px 8px',
          border: !isEmpty(tabData) ? 'none' : '1px solid var(--color-outline) !important'
        }}
      >
        <Tabs
          data-testid="tabs-control"
          value={currTabValue}
          onChange={setTab}
          indicatorColor={isEmpty(tabData) ? 'primary' : 'secondary'}
          variant="scrollable"
          scrollButtons="auto"
          textColor={isEmpty(tabData) ? 'primary' : 'secondary'}
          TabIndicatorProps={{
            style: {
              backgroundColor: 'var(--color-primary)'
            }
          }}
          style={{ height: !isEmpty(tabData) ? '50px' : '166px' }}
        >
          {Object.entries(tabData).map(([tabName, data]) => (
            <StyledTab
              data-testid={getId(tabName)}
              key={tabName}
              value={tabName}
              label={`${tabName} (${data.length})`}
            />
          ))}
          <Box
            sx={{
              width: '100%',
              height: '166px',
              display: !isEmpty(tabData) ? 'none' : 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '4px',
              fontFamily: 'var(--font-family-regular)',
              fontSize: 'var(--font-size-regular)',
              fontWeight: 'var(--font-weight-regular)'
            }}
          >
            <img src="/ui/images/no-search-results-icon.svg" />
            <Box>Sorry, we couldn't find any results that match your text</Box>
          </Box>
        </Tabs>
      </Box>
      {Object.entries(tabData).map(([tabName, data]) => (
        <TabPanel
          selectItems={selectItems}
          deselectItems={deselectItems}
          title={tabName}
          currTabValue={currTabValue}
          key={tabName}
          data={data}
          selectedTabItems={selectedTabItems}
          saveItems={saveItems}
          buttonLabel={buttonLabel}
        />
      ))}
    </Box>
  );
}
