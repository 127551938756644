import creativeAssetTileComponentTemplate from './creative-repo-tab.html';
import { safeApply, searchInOrder } from '../../../factories/general_utils';

class CreativeRepoTabController {
  /**
   * @ngInject
   * @param {ng.IScope} $scope
   * @param {import('../creative_repository.serv').creativeRepositoryService} creativeRepositoryService
   * @param {import('../../adgroup/adgroup.serv').adGroupService} adGroupService
   * @param {import('../../../factories/types').CampaignManagerService} campaignManagerFactory
   */

  constructor($scope, creativeRepositoryService, adGroupService, campaignManagerFactory) {
    this.$scope = $scope;

    // default values for bindings
    this.adGroupId = 0;
    this.callback = () => {};

    this.searchTerm = '';
    this.adGroupService = adGroupService;
    this.creativeRepositoryService = creativeRepositoryService;
    this.campaignManagerFactory = campaignManagerFactory;
    this.limits = {
      itemsToShow: 6,
      scrollIncrement: 6
    };
    this.maxSelectionLimit = 10;
    this.checkedIds = {};
    this.checkedAssetsLength = 0;
    this.addToAdgroupProgress = false;
    this.showAlert = false;
    this.onSelect = this.onSelect.bind(this);
  }

  $onInit() {
    /**
     * Initiate api Calls here
     */
    this.disableAddToAdgroup = true;
    this.progress = true;
    this.getCreativeAssetsForTenant();
  }

  triggerAlert(alertType, message) {
    this.showAlert = true;
    this.alertType = alertType;
    this.message = message;
  }

  async getCreativeAssetsForTenant() {
    try {
      const data = await this.creativeRepositoryService.getCreativeAssetList({
        full_load: false,
        // @ts-ignore
        asset_types: ['IMAGE']
      });
      this.creativeAssets = data.creative_assets.map(asset => ({
        ...asset,
        preview_url: asset.asset_source,
        type: asset.asset_type,
        name: asset.asset_name,
        id: asset.asset_id
      }));
      this.creativeAssetsToShow = this.creativeAssets;
    } catch (e) {
      const message =
        'There was an error fetching the list of creative assets. Please try again by refreshing the page.';
      this.triggerAlert('error', message);
    } finally {
      this.progress = false;
      safeApply(this.$scope);
    }
  }

  onSelect(id, checked) {
    if (checked) {
      this.checkedIds[id] = true;
    } else if (this.checkedIds[id]) {
      delete this.checkedIds[id];
    }
    this.checkedAssetsLength = Object.keys(this.checkedIds).length;
  }

  search() {
    if (!this.creativeAssets) return;
    this.creativeAssetsToShow = searchInOrder(this.searchTerm, this.creativeAssets, ['name', 'id']);
  }

  clearSearch() {
    this.searchTerm = '';
    this.search();
  }

  loadMore() {
    this.limits.itemsToShow = this.limits.itemsToShow + this.limits.scrollIncrement;
  }

  async addToAdgroup() {
    if (this.checkedAssetsLength > this.maxSelectionLimit) {
      const message = `Only ${this.maxSelectionLimit} assets can be linked at once.`;
      this.triggerAlert('warning', message);
      return;
    }
    try {
      const adGroupsToAdd = [this.adGroupId];
      this.addToAdgroupProgress = true;
      const creativeAssetsToAdd = Object.keys(this.checkedIds).map(item => parseInt(item));
      const res = await this.adGroupService.addCreativesAssetsToAdGroups(adGroupsToAdd, creativeAssetsToAdd);

      const grammar = this.checkedAssetsLength > 1 ? 'creatives' : 'creative';
      const message = `${this.checkedAssetsLength} ${grammar} successfully linked to this adgroup.`;
      this.triggerAlert('success', message);
      // fetch the updated Adgroup so that UI can load the newly added creative
      await this.callback();
    } catch (e) {
      const message = 'Something went wrong, please try again.';
      this.triggerAlert('error', message);
    } finally {
      // reload the state?
      this.addToAdgroupProgress = false;
      safeApply(this.$scope);
    }
  }

  closeError() {
    this.showAlert = false;
  }
}

export const creativeRepoTab = {
  template: creativeAssetTileComponentTemplate,
  controller: CreativeRepoTabController,
  bindToController: true,
  controllerAs: 'vm',
  bindings: {
    adGroupId: '<',
    callback: '='
  }
};
