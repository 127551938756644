xadFastChart.$inject = ['$log'];

export function xadFastChart($log) {
  var DEFAULT_COLOR = '#333',
    DEFAULT_RENDERER = 'spline',
    DEFAULT_INTERPOLATION = 'linear',
    CLICKS_CHART_BUFFER = 0.3, // buffer between clicks chart and impressions chart -- this ensures that their graphs never touch
    HOVER_DETAIL_Y_OFFSET = -10,
    HOVER_DETAIL_X_OFFSET = 40;

  return {
    restrict: 'E',
    scope: {
      config: '=xadConfig',
      content: '=xadContent',
      changed: '=changed',
      dataField: '@xadDataField'
    },
    controller: /** @ngInject */ function($scope, $element) {
      var defaultConfig = {
        id: 'xad-chart',
        columns: [],
        colors: [DEFAULT_COLOR],
        renderer: DEFAULT_RENDERER,
        interpolation: DEFAULT_INTERPOLATION
      };

      if (!$scope.config) {
        $scope.config = {};
      }
      $scope.config = angular.merge(defaultConfig, $scope.config);
      $scope.data = null;
      $scope.chartId = $scope.config.id;
      $scope.showLoading = true;

      /**
       * Configures a new graph, and renders it if data is present.
       * To add data points, use updateGraph()
       */
      function initGraph() {
        $scope.config.element = document.getElementById($scope.chartId);
        $scope.hoverDetail = $('#' + $scope.chartId).find('.hover-detail');

        var graph = new Rickshaw.Graph($scope.config);

        // subclassing Rickshaw.Graph.HoverDetail in order to have more control over how hover detail is rendered
        var Hover = Rickshaw.Class.create(Rickshaw.Graph.HoverDetail, {
          render: function(args) {
            var points = args.points;
            $scope.hoverDetail.empty();
            for (var i = points.length - 1; i >= 0; i--) {
              var d = args.detail[i];
              var p = points[i];

              // show values
              $scope.hoverDetail.append($('<span>' + p.name + ': ' + parseInt(p.formattedYValue) + '</span><br>'));
              $scope.hoverDetail.css('top', args.mouseY + HOVER_DETAIL_Y_OFFSET);
              $scope.hoverDetail.css('left', args.mouseX + HOVER_DETAIL_X_OFFSET);

              // show impressions vertex
              if (p.name === 'impressions') {
                var dot = document.createElement('div');
                dot.className = 'dot';
                dot.style.top = graph.y(d.value.y0 + d.value.y) + 'px';
                dot.style.borderColor = d.series.color;
                this.element.appendChild(dot);
                dot.className = 'dot active';
                this.show();
              }
            }
          }
        });
        var h = new Hover({
          graph: graph
        });

        $scope.graph = graph;
        $scope.showLoading = false;
        graph.render();
      }

      /**
       * Populates graph with fresh data, and re-renders it (if it's already been rendered).
       * If the graph has not been configured, it also makes a call to initGraph().
       */
      function updateGraph() {
        var data = formatDataForRickshaw($scope.data);
        var maxValues = getMaxValues(data);
        var series = [];
        var color, type, column, scale;

        for (var i = 0; i < $scope.config.columns.length; i++) {
          column = $scope.config.columns[i];
          if (data.hasOwnProperty(column)) {
            // colors

            var colors = $scope.config.colors;
            if (colors && angular.isArray(colors)) {
              if (i < colors.length) {
                color = colors[i];
              } else {
                // if not enough colors, keep picking the last one specified
                color = colors[colors.length - 1];
              }
            } else {
              color = DEFAULT_COLOR;
            }

            // chart type

            var types = $scope.config.types;
            if (types && angular.isArray(types)) {
              if (i < types.length) {
                type = types[i];
              } else {
                type = types[types.length - 1];
              }
            } else {
              type = DEFAULT_RENDERER;
            }

            // y-axis scaling

            if (column === 'clicks') {
              // clicks graph needs to scale down so that it never exceeds impressions graph
              var clicksScalar = getMaxDifferential(data, maxValues) + CLICKS_CHART_BUFFER;
              scale = d3.scale.linear().domain([0, Math.ceil(clicksScalar * maxValues[column])]);
            } else {
              scale = d3.scale.linear().domain([0, maxValues[column]]);
            }

            series.push({
              color: color,
              data: data[column],
              name: column,
              scale: scale,
              renderer: type
            });
          }
        }

        $scope.config.series = series;

        if (angular.isUndefined($scope.graph)) {
          initGraph();
        } else {
          $scope.graph.update();
        }
      }

      function updateData(value) {
        if (!value) {
          return;
        }

        if (value.then) {
          value
            .then(function(data) {
              $scope.data = getData(data, $scope.dataField);
              updateGraph();
            })
            .catch(function(reason) {
              $log.debug(reason);
              $scope.data = [];
              updateGraph();
            });
        } else {
          $scope.data = getData(value, $scope.dataField);
          updateGraph();
        }
      }

      function getData(content, field) {
        if (angular.isArray(content)) {
          return content;
        }

        if (field && content.hasOwnProperty(field)) {
          return content[field];
        }

        return [];
      }

      /**
       * Formats each series of data to be in this form: [{ x: x0, y: y0}, ..., { x: xn, y: yn }]
       * so Rickshaw knows how to read it.
       */
      function formatDataForRickshaw(data) {
        var columns = $scope.config.columns,
          formattedData = {},
          i,
          key,
          item;

        for (i = 0; i < columns.length; i++) {
          formattedData[columns[i]] = [];
        }

        for (i = 0; i < data.length; i++) {
          item = data[i];
          for (key in formattedData) {
            if (formattedData.hasOwnProperty(key) && item.hasOwnProperty(key)) {
              formattedData[key].push({
                x: i,
                y: item[key]
              });
            }
          }
        }

        return formattedData;
      }

      function getMaxKeyValueForArrayOfObjects(arr, key) {
        // sry this function name is long...
        var max, val;

        for (var i = 0; i < arr.length; i++) {
          if (arr[i].hasOwnProperty(key)) {
            val = arr[i][key];
            if (angular.isUndefined(max) || max < val) {
              max = val;
            }
          }
        }

        return max;
      }

      function getMaxValues(data) {
        var maxValues = {};
        for (var i = 0; i < $scope.config.columns.length; i++) {
          var column = $scope.config.columns[i];
          maxValues[column] = getMaxKeyValueForArrayOfObjects(data[column], 'y');
        }
        return maxValues;
      }

      /**
       * Normalizes data values to lie between 0 and 1, by dividing each value by the max value in the series.
       */
      function normalizeData(data, maxValues) {
        var normalizedData = {};
        var columns = $scope.config.columns;

        if (angular.isUndefined(maxValues)) {
          maxValues = getMaxValues(data);
        }

        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            if (data[key][0] && data[key][0].hasOwnProperty('y')) {
              normalizedData[key] = [];
              for (var i = 0; i < data[key].length; i++) {
                normalizedData[key].push(data[key][i].y / maxValues[key]);
              }
            } else {
              // exit if not formatted properly
              return {};
            }
          }
        }

        return normalizedData;
      }

      /**
       * @returns the largest percentage (as a decimal between 0 and 1) by which the clicks chart exceeds the impressions chart.
       */
      function getMaxDifferential(data, maxValues) {
        // TODO: find a better name for this? I don't think "differential" is right
        var maxDiff = 0;
        var normalizedData;

        if (maxValues.clicks == 0) {
          return 0;
        }

        normalizedData = normalizeData(data, maxValues);

        for (var i = 0; i < data.clicks.length; i++) {
          var diff = normalizedData.clicks[i] / normalizedData.impressions[i];
          if (diff > maxDiff) {
            maxDiff = diff;
          }
        }
        return maxDiff;
      }

      // listen for changes...
      $scope.$watch('changed', function() {
        if ($scope.changed && !angular.isUndefined($scope.content)) {
          $scope.chartId = $scope.config.id;
          updateData($scope.content);
        }
      });
    },
    template:
      '<div class="chart-preview" id="{{ chartId }}"><div class="hover-detail"></div></div>' +
      '<gt-spinner class="chart-loading-spinner" size="small" color="black" ng-show="showLoading"></gt-spinner>'
  };
}
