import { campaignBlacklistController } from './campaign_blacklist/campaign_blacklist.ctrl';
import { campaignReportController } from './campaign_dashboard/campaign_dashboard.ctrl';
import { campaignSettingsController } from './campaign_edit/campaign_settings.ctrl';
import { campaignWhitelistController } from './campaign_whitelist/campaign_whitelist.ctrl';
import { changeLogFactory } from './change_log.fac';
import { exportDataController } from './export_data.ctrl';
import { CampaignBulkEditModalInstance } from './campaign_bulk_upload/campaign_bulk_edit_modal_instance.ctrl';

export const controllers = {
  campaignBlacklistController,
  campaignReportController,
  campaignSettingsController,
  campaignWhitelistController,
  exportDataController,
  CampaignBulkEditModalInstance
};

export const factories = {
  changeLogFactory
};
