import React, { useState } from 'react';

import { ArrowIcon } from '../../../../components/reactComponents/Icons';
import { AudienceType } from './enum';
import { AnyTaxonomyItem, SelectableTaxonomyItem, isAudience, isAudienceCategory, isAudienceType } from './interfaces';
import { Audience } from '../../../../api/http/data.generated';
import { styled } from '../../../../utils/react/styled';
import { formatNumber } from './utils';

const audienceTypeMap = {
  [AudienceType.LOCATION_GROUP]: 'Location Group',
  [AudienceType.CUSTOM_AUDIENCE]: 'Custom Audience',
  [AudienceType.SEGMENT]: 'Segment'
};

const privateAudienceCategories = ['Segments', 'Custom Audiences', 'Location Groups'];

const shouldDisplayType = (item: AnyTaxonomyItem) => {
  if (isAudience(item) && item.type) {
    return [AudienceType.LOCATION_GROUP, AudienceType.SEGMENT, AudienceType.CUSTOM_AUDIENCE].includes(
      item.type as AudienceType
    );
  }
};

export const getType = (item: AnyTaxonomyItem): React.ReactNode => {
  return isAudience(item) && shouldDisplayType(item) ? `(${audienceTypeMap[item.type as AudienceType]})` : null;
};

interface AccordionItemProps {
  categoryExpanded?: boolean;
  includeItemDescription: boolean;
  item: AnyTaxonomyItem;
  selectedAudiences: Audience[];
  selectedAudienceIds: Set<string>;
  showLibrary: boolean;
  onCheckboxChange: (item: SelectableTaxonomyItem) => void;
}

export function AccordionItem({
  categoryExpanded,
  includeItemDescription,
  item,
  selectedAudiences,
  selectedAudienceIds,
  showLibrary,
  onCheckboxChange
}: AccordionItemProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  if (!item) {
    return null;
  }

  const validateCategoryIsChecked = (item, selectedAudienceIds) => {
    return item.audiences.every(audience => selectedAudienceIds.has(audience.id));
  };

  const validateAudienceIsChecked = (item, selectedAudienceIds) => {
    return selectedAudienceIds.has(item.id);
  };

  const validateItemIsChecked = item => {
    if (isAudienceCategory(item)) {
      return validateCategoryIsChecked(item, selectedAudienceIds);
    }
    return validateAudienceIsChecked(item, selectedAudienceIds);
  };

  const isPrivateAudience = item => {
    return item.name && privateAudienceCategories.includes(item.name);
  };

  const showDescription = React.useCallback(() => {
    return item?.description && includeItemDescription;
  }, [item, includeItemDescription]);

  const showSize = item => {
    return !isAudienceType(item) && !isPrivateAudience(item);
  };

  // if the item is an audience category or under audience category, push the description to the right
  const AccordionItemDescription = React.memo(styled.div`
    padding: 0 12px 0 ${isAudienceCategory(item) || categoryExpanded ? '58px' : '36px'};
  `);

  return (
    <div className="accordion-item">
      <div className="accordion-item__container">
        <div className="accordion-item__content">
          {isAudienceCategory(item) || isAudienceType(item) ? (
            <div
              data-testid="accordion-item__arrow"
              className={`accordion-item__arrow ${isExpanded ? 'expanded' : ''}`}
              onClick={handleToggle}
            >
              <ArrowIcon />
            </div>
          ) : (
            <>
              {categoryExpanded ? (
                <div className="accordion-item__arrow"></div>
              ) : (
                <div className="accordion-item__no-arrow"></div>
              )}
            </>
          )}
          <div className="multiselect-checkbox">
            {(isAudienceCategory(item) || !isAudienceType(item)) && !isPrivateAudience(item) ? (
              <input
                type="checkbox"
                className="accordion-item__checkbox-icon"
                checked={validateItemIsChecked(item)}
                onChange={() => onCheckboxChange(item)}
              />
            ) : null}
          </div>
          <div
            className="accordion-item__title"
            onClick={!isPrivateAudience(item) ? () => onCheckboxChange(item) : undefined}
          >
            <p className="accordion-item__title-name">
              {item.name} <span className="accordion-item__title-type">{getType(item)}</span>
            </p>
            {showSize(item) ? <p className="accordion-item__title-size">{formatNumber(item.size)}</p> : null}
          </div>
        </div>
        {showDescription() ? (
          <AccordionItemDescription
            className={
              showLibrary
                ? 'accordion-item__description-with-checkbox-library'
                : 'accordion-item__description-with-checkbox'
            }
          >
            {item.description}
          </AccordionItemDescription>
        ) : null}
      </div>
      {isExpanded && (isAudienceCategory(item) ? item.audiences : isAudienceType(item) ? item.categories : []) && (
        <div className="accordion-item__audiences">
          {(isAudienceCategory(item) ? item.audiences : isAudienceType(item) ? item.categories : [])?.map(
            (sub, index) => (
              <AccordionItem
                key={index}
                categoryExpanded={isAudienceCategory(item)}
                includeItemDescription={includeItemDescription}
                item={sub}
                selectedAudienceIds={selectedAudienceIds}
                selectedAudiences={selectedAudiences}
                showLibrary={showLibrary}
                onCheckboxChange={onCheckboxChange}
              />
            )
          )}
        </div>
      )}
    </div>
  );
}
