import React from 'react';
import PropTypes from 'prop-types';
import { CardRow } from './CardRow';
import { PaymentsTableProps } from './PaymentsTable';
import { Column } from './PaymentsTable';
import { CircularIndicator } from '../../../components/reactComponents/LoadingIndicator/CircularIndicator';

export interface TableBodyProps extends PaymentsTableProps {
  columns: Column[];
  isAccountLevelCreditCardsEnabled: boolean;
}

export function TableBody(props: TableBodyProps) {
  const { cards, columns, paymentsInProgress } = props;

  return (
    <>
      {paymentsInProgress || !cards.length ? (
        <td className="nocard" colSpan={6}>
          {paymentsInProgress ? (
            <CircularIndicator color="inherit" size={24} className="primary-cell--loading" />
          ) : (
            <div className="no-search-results">* No cards have been added. Please add your first payment method.</div>
          )}
        </td>
      ) : (
        cards.map(card => <CardRow {...props} card={card} columns={columns} key={card.card_id} />)
      )}
    </>
  );
}

TableBody.propTypes = {
  cards: PropTypes.array,
  columns: PropTypes.array,
  paymentsInProgress: PropTypes.bool,
  isAccountLevelCreditCardsEnabled: PropTypes.bool
};
