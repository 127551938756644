SearchCtrl.$inject = [
  '$scope',
  '$stateParams',
  '$location',
  'searchFactory',
  'generalUtilsFactory',
  'userFactory',
  'companyAndAccountFactory',
  'featureFlagFactory'
];

/**
 * @param {ng.ILocationService} $location
 * @param {import('../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @param {import('../../factories/types').UserFactoryService} userFactory
 *  * @param {import('../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 */
export function SearchCtrl(
  $scope,
  $stateParams,
  $location,
  searchFactory,
  generalUtilsFactory,
  userFactory,
  companyAndAccountFactory,
  featureFlagFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;
  searchFactory.query.text = $stateParams.q;
  $scope.searchQuery = searchFactory.query;
  $scope.hideAdgroups = featureFlagFactory.isFeatureEnabled('REPORTING_ONLY');
  $scope.hideCreatives = featureFlagFactory.isFeatureEnabled('REPORTING_ONLY');

  $scope.filtersCollapsedOnMobile = false; // Mobile only... can't collapse filters on large screens.

  var resultTypeMap = {};
  $scope.resultTypes = [];

  $scope.numResultsShown = 10;

  /* Breadcrumbs are shown at the top of each result. The value just means show everything less than it.
   * e.g. If results are accounts, we want to show Tenant -> Org
   */
  $scope.breadcrumbs = {
    tenant: 1,
    company: 2,
    account: 3,
    campaign: 4,
    adgroup: 5,
    creative: 6
  };

  /* Initially show numResultsShown results, and increase by resultsPerPage as user scrolls down */
  $scope.limits = {
    numResultsShown: 10,
    resultsPerPage: 10
  };

  $scope.booleans = {
    searchProgress: false,
    selectionProgress: false,
    isInit: false,
    showTenants: userFactory.currentLoggedInUser.is_sudoer,
    showOrganizations: companyAndAccountFactory.selectedTenant?.is_holding_company
  };

  $scope.searchFiltersUi = {
    dateCreated: {
      value: 'all',
      show: false
    },
    dateUpdated: {
      value: 'all',
      show: false
    },
    startDate: {
      value: 'all',
      show: false
    }
  };

  /* Object that stores filter values to pass to the back end */
  /** @type {Record<string, { value: string | null, equality: string | null } | null>} */
  const searchFilters = {
    campaign_start_date: {
      value: null,
      equality: null
    },
    campaign_created_date: {
      value: null,
      equality: null
    },
    campaign_updated_date: {
      value: null,
      equality: null
    },
    account_created_date: {
      value: null,
      equality: null
    },
    adgroup_start_date: {
      value: null,
      equality: null
    },
    adgroup_created_date: {
      value: null,
      equality: null
    },
    adgroup_updated_date: {
      value: null,
      equality: null
    },
    creative_start_date: {
      value: null,
      equality: null
    },
    creative_updated_date: {
      value: null,
      equality: null
    }
  };

  $scope.results = {
    adgroup: null,
    campaign: null,
    account: null,
    company: null,
    tenant: null
  };

  $scope.errorMsg = null;

  function init() {
    $scope.resultTypes = [
      resultTypeMap.tenant,
      resultTypeMap.company,
      resultTypeMap.account,
      resultTypeMap.campaign,
      resultTypeMap.adgroup,
      resultTypeMap.creative
    ];
    if ($stateParams.campaignCreated) {
      $scope.searchFiltersUi.campaignCreated.value = $stateParams.campaignCreated;
    }

    // Check query params
    if ($stateParams.filter_by) {
      if ($stateParams.filter_by === 'tenant') {
        $scope.filterByTenants();
      } else if ($stateParams.filter_by === 'account') {
        $scope.filterByAccounts();
        if ($stateParams.account_created_date) {
          $scope.searchFiltersUi.dateCreated.value = $stateParams.account_created_date;
          $scope.filterByDateCreated();
        }
      } else if ($stateParams.filter_by === 'company') {
        $scope.filterByCompanies();
      } else if ($stateParams.filter_by === 'campaign') {
        $scope.filterByCampaigns();
        if ($stateParams.campaign_created_date) {
          $scope.searchFiltersUi.dateCreated.value = $stateParams.campaign_created_date;
          $scope.filterByDateCreated();
        }
        if ($stateParams.campaign_updated_date) {
          $scope.searchFiltersUi.dateUpdated.value = $stateParams.campaign_updated_date;
          $scope.filterByDateUpdated();
        }
        if ($stateParams.campaign_start_date) {
          $scope.searchFiltersUi.startDate.value = $stateParams.campaign_start_date;
          $scope.filterByStartDate();
        }
      } else if ($stateParams.filter_by === 'adgroup') {
        $scope.filterByAdgroups();
        if ($stateParams.adgroup_created_date) {
          $scope.searchFiltersUi.dateCreated.value = $stateParams.adgroup_created_date;
          $scope.filterByDateCreated();
        }
        if ($stateParams.adgroup_updated_date) {
          $scope.searchFiltersUi.dateUpdated.value = $stateParams.adgroup_updated_date;
          $scope.filterByDateUpdated();
        }
        if ($stateParams.adgroup_start_date) {
          $scope.searchFiltersUi.startDate.value = $stateParams.adgroup_start_date;
          $scope.filterByStartDate();
        }
      } else {
        $scope.filterByCreatives();
        if ($stateParams.creative_updated_date) {
          $scope.searchFiltersUi.dateUpdated.value = $stateParams.creative_updated_date;
          $scope.filterByDateUpdated();
        }
        if ($stateParams.creative_start_date) {
          $scope.searchFiltersUi.startDate.value = $stateParams.creative_start_date;
          $scope.filterByStartDate();
        }
      }
    } else {
      $scope.filterByCampaigns(); // default
    }

    $scope.booleans.isInit = true;
    getResults();
  }

  function formatDate(date) {
    return generalUtilsFactory.DateToString(convertFilterValueToDate(date));
  }

  $scope.selectResult = function(result) {
    $scope.booleans.selectionProgress = true;
    searchFactory.selectEntityBySearch($scope.selectedResultType, result).then(function() {
      searchFactory.query.text = '';
      $scope.booleans.selectionProgress = false;
    });
  };

  $scope.filterByStartDate = function() {
    if ($scope.searchFiltersUi.startDate.value === 'all') {
      searchFilters[$scope.selectedResultType + '_start_date'] = null;
      // update query param
      $location.search($scope.selectedResultType + '_start_date', 'all');
    } else {
      var formattedDate = generalUtilsFactory.DateToString(
        convertFilterValueToDate($scope.searchFiltersUi.startDate.value)
      );
      searchFilters.campaign_start_date = {
        value: formattedDate,
        equality: 'gte'
      };
      // update query param
      $location.search($scope.selectedResultType + '_start_date', $scope.searchFiltersUi.startDate.value);
    }
    if ($scope.booleans.isInit) {
      getResults();
    }
  };

  $scope.filterByDateCreated = function() {
    if ($scope.searchFiltersUi.dateCreated.value === 'all') {
      searchFilters[$scope.selectedResultType + '_created_date'] = null;
      $location.search($scope.selectedResultType + '_created_date', 'all');
    } else {
      var formattedDate = generalUtilsFactory.DateToString(
        convertFilterValueToDate($scope.searchFiltersUi.dateCreated.value)
      );
      searchFilters[$scope.selectedResultType + '_created_date'] = {
        value: formattedDate,
        equality: 'gte'
      };
      $location.search($scope.selectedResultType + '_created_date', $scope.searchFiltersUi.dateCreated.value);
    }
    if ($scope.booleans.isInit) {
      getResults();
    }
  };

  $scope.filterByDateUpdated = function() {
    if ($scope.searchFiltersUi.dateUpdated.value === 'all') {
      searchFilters[$scope.selectedResultType + '_updated_date'] = null;
      $location.search($scope.selectedResultType + '_updated_date', 'all');
    } else {
      var formattedDate = generalUtilsFactory.DateToString(
        convertFilterValueToDate($scope.searchFiltersUi.dateUpdated.value)
      );
      searchFilters[$scope.selectedResultType + '_updated_date'] = {
        value: formattedDate,
        equality: 'gte'
      };
      $location.search($scope.selectedResultType + '_updated_date', $scope.searchFiltersUi.dateUpdated.value);
    }
    if ($scope.booleans.isInit) {
      getResults();
    }
  };

  function getResults() {
    $scope.booleans.searchProgress = true;
    searchFactory
      .getSearchResults($scope.searchQuery.text, searchFilters)
      .then(
        function(data) {
          $scope.errorMsg = null;
          $scope.results = data;
        },
        function(data) {
          $scope.errorMsg = 'An error occurred while performing the search. Please try again later.';
        }
      )
      .finally(function() {
        $scope.lastQuery = $scope.searchQuery.text;
        $scope.booleans.searchProgress = false;
      });
  }

  $scope.filterByCreatives = function() {
    $scope.selectedResultType = 'creative';

    $scope.searchFiltersUi.dateCreated.show = false;
    $scope.searchFiltersUi.dateUpdated.show = true;
    $scope.searchFiltersUi.startDate.show = true;

    $scope.showFilters = true;
    $location.search('filter_by', 'creative');
  };

  $scope.filterByCampaigns = function() {
    $scope.selectedResultType = 'campaign';

    $scope.searchFiltersUi.dateCreated.show = true;
    $scope.searchFiltersUi.dateUpdated.show = true;
    $scope.searchFiltersUi.startDate.show = true;

    $scope.showFilters = true;
    $location.search('filter_by', 'campaign');
  };

  $scope.filterByAdgroups = function() {
    $scope.selectedResultType = 'adgroup';

    $scope.searchFiltersUi.dateCreated.show = true;
    $scope.searchFiltersUi.dateUpdated.show = true;
    $scope.searchFiltersUi.startDate.show = true;

    $scope.showFilters = true;
    $location.search('filter_by', 'adgroup');
  };

  $scope.filterByAccounts = function() {
    $scope.selectedResultType = 'account';

    $scope.searchFiltersUi.dateCreated.show = true;
    $scope.searchFiltersUi.dateUpdated.show = false;
    $scope.searchFiltersUi.startDate.show = false;

    $scope.showFilters = true;
    $location.search('filter_by', 'account');
  };

  $scope.filterByCompanies = function() {
    $scope.selectedResultType = 'company';
    $scope.showFilters = false;
    $location.search('filter_by', 'company');
  };

  $scope.filterByTenants = function() {
    $scope.selectedResultType = 'tenant';
    $scope.showFilters = false;
    $location.search('filter_by', 'tenant');
  };

  function convertFilterValueToDate(value) {
    var newDate = new Date();
    switch (value) {
      case 'week':
        newDate.setDate(newDate.getDate() - 7);
        break;
      case 'two-weeks':
        newDate.setDate(newDate.getDate() - 14);
        break;
      case 'month':
        newDate.setMonth(newDate.getMonth() - 1);
        break;
      case 'three-months':
        newDate.setMonth(newDate.getMonth() - 3);
        break;
    }
    return newDate;
  }

  $scope.dateToString = function(date) {
    return generalUtilsFactory.DateToString(new Date(date));
  };

  /* Displays more results. Used by infinite scroll. */
  $scope.loadMoreResults = function() {
    $scope.limits.numResultsShown += $scope.limits.resultsPerPage;
  };

  init();
}
