import * as t from 'io-ts';
import { validate } from './validator';

// base types
export type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'PUT';
export type HttpContentType = 'application/json';

interface HttpInputContentDef<T> {
  contentType?: HttpContentType;
  transformInput: (data: T) => string | object | undefined;
}

interface HttpOutputContentDef<T> {
  contentType?: HttpContentType;
  transformOutput: (data: unknown) => T;
}

interface HttpApiMethodDef {
  method: HttpMethod;
}

type UrlSpec = string | [string, object];
interface HttpApiUrlDef<TUrl> {
  url: UrlSpec | ((urlData: TUrl) => UrlSpec);
}

interface HttpApiRequestDef<TRequest> {
  requestContent: HttpInputContentDef<TRequest>;
}

interface HttpApiResponseDef<TResponse> {
  responseContent: HttpOutputContentDef<TResponse>;
}

export interface HttpApiDef<TUrl, TRequest, TResponse> {
  method: HttpMethod;
  url: UrlSpec | ((urlData: TUrl) => UrlSpec);
  requestContent: HttpInputContentDef<TRequest>;
  responseContent: HttpOutputContentDef<TResponse>;
}

type Join<T1, T2> = T1 extends void ? T2 : T2 extends void ? T1 : T1 & T2;
export interface HttpApiDefBuilder<TUrl> extends HttpApiDefResponseBuilder<TUrl, void> {
  withParams<T extends object>(paramsBuilder?: (urlData: Join<TUrl, T>) => object): HttpApiDefBuilder<Join<TUrl, T>>;
  withParams<TType extends t.HasProps>(type: TType): HttpApiDefBuilder<Join<TUrl, t.TypeOf<TType>>>;
  acceptsJsonOf<T>(): HttpApiDefResponseBuilder<TUrl, T>;
  acceptsJsonOf<TType extends t.Type<any>>(type: TType): HttpApiDefResponseBuilder<TUrl, t.TypeOf<TType>>;
}

export interface HttpApiDefResponseBuilder<TUrl, TRequest> {
  returnsNone(): HttpApiDef<TUrl, TRequest, void>;
  returnsJsonOf<T>(): HttpApiDef<TUrl, TRequest, T>;
  returnsJsonOf<TType extends t.Type<any>>(type: TType): HttpApiDef<TUrl, TRequest, t.TypeOf<TType>>;
}

type Scalar = string | number | boolean;
export interface HttpApiDefUrlBuilder {
  (url: TemplateStringsArray, ...interpolations: Scalar[]): HttpApiDefBuilder<void>;
  <TUrl>(
    urlTemplate: TemplateStringsArray,
    ...interpolations: (Scalar | ((params: TUrl) => Scalar))[]
  ): HttpApiDefBuilder<TUrl>;
  (url: string): HttpApiDefBuilder<void>;
  <TUrl>(url: (params: TUrl) => UrlSpec): HttpApiDefBuilder<TUrl>;
}

export interface HttpApiDefMethodBuilder {
  get: HttpApiDefUrlBuilder;
  post: HttpApiDefUrlBuilder;
  put: HttpApiDefUrlBuilder;
  delete: HttpApiDefUrlBuilder;
}

// Builders

// Content types

const json: HttpInputContentDef<any> & HttpOutputContentDef<any> = {
  contentType: 'application/json',
  transformInput: data => data, // just pass through
  transformOutput: data => (data as ng.IHttpResponse<any>).data
};

function jsonOf<T>(): HttpInputContentDef<T> & HttpOutputContentDef<T>;
function jsonOf<TType extends t.Type<any>>(
  type: TType
): HttpInputContentDef<t.TypeOf<TType>> & HttpOutputContentDef<t.TypeOf<TType>>;
function jsonOf<T, TType extends t.Type<any>>(type?: TType) {
  if (type) {
    return {
      contentType: 'application/json',
      transformInput: data => transformJson(type, data),
      transformOutput: data => transformJson(type, (data as ng.IHttpResponse<any>).data)
    } as HttpInputContentDef<t.TypeOf<TType>> & HttpOutputContentDef<t.TypeOf<TType>>;
  }
  return json as HttpInputContentDef<T> & HttpOutputContentDef<T>;
}

function transformJson<TType extends t.Type<any>>(type: TType, data: t.TypeOf<TType>): t.TypeOf<TType> {
  return validate(type, data);
}

const none: HttpInputContentDef<void> & HttpOutputContentDef<void> = {
  transformInput: data => undefined,
  transformOutput: () => undefined
};

interface ResponseBuilderDraft<TUrl, TRequest>
  extends HttpApiMethodDef,
    HttpApiUrlDef<TUrl>,
    HttpApiRequestDef<TRequest> {}
function createResponseBuilder<TUrl, TRequest>(
  draft: () => ResponseBuilderDraft<TUrl, TRequest>
): HttpApiDefResponseBuilder<TUrl, TRequest> {
  return {
    returnsNone() {
      return { ...draft(), responseContent: none };
    },
    returnsJsonOf<T, TType extends t.Type<any>>(type?: TType) {
      return { ...draft(), responseContent: type ? jsonOf(type) : jsonOf<T>() };
    }
  };
}

interface RequestBuilderDraft<TUrl> extends HttpApiMethodDef, HttpApiUrlDef<TUrl> {}
function createRequestBuilder<TUrl>(draft: () => RequestBuilderDraft<TUrl>): HttpApiDefBuilder<TUrl> {
  return {
    ...createResponseBuilder<TUrl, void>(() => ({ ...draft(), requestContent: none })),
    // withParams<T extends object>(paramsBuilder?: (urlData: Join<TUrl, T>) => object): HttpApiDefBuilder<Join<TUrl, T>>;
    // withParams<TType extends t.HasProps>(type: TType): HttpApiDefBuilder<Join<TUrl, t.TypeOf<TType>>>;
    withParams<T extends object, TType extends t.HasProps>(
      paramsBuilderOrType?: ((urlData: Join<TUrl, T>) => object) | TType
    ) {
      return createRequestBuilder<Join<TUrl, T>>(() => {
        const oldDraft = draft();
        const url = (urlData: Join<TUrl, T>) => {
          const urlSpec = typeof oldDraft.url === 'function' ? oldDraft.url(urlData as TUrl) : oldDraft.url;
          const newParams: object = paramsBuilderOrType
            ? typeof paramsBuilderOrType === 'function'
              ? paramsBuilderOrType(urlData)
              : transformJson(paramsBuilderOrType, filterProps(paramsBuilderOrType, urlData))
            : urlData;
          const newUrlSpec: [string, object] =
            typeof urlSpec === 'string' ? [urlSpec, newParams] : [urlSpec[0], { ...urlSpec[1], ...newParams }];
          return newUrlSpec;
        };
        const newDraft = { ...oldDraft, url };
        return newDraft;
      });
    },
    acceptsJsonOf<T, TType extends t.Type<any>>(type?: TType) {
      return createResponseBuilder(() => ({ ...draft(), requestContent: type ? jsonOf(type) : jsonOf<T>() }));
    }
  };
}

function interpolate(template: TemplateStringsArray, ...interpolations: any[]) {
  return String.raw({ ...template, raw: template }, ...interpolations);
}

function filterProps(type: t.HasProps, data: object): object {
  if (type._tag === 'InterfaceType' || type._tag === 'PartialType') {
    const result = {};
    for (const name of Object.keys(type.props)) {
      if (data[name] !== undefined) {
        result[name] = data[name];
      }
    }
    return result;
  }

  if (type._tag === 'IntersectionType') {
    return type.types.map(t => filterProps(t, data)).reduce((prev, next) => ({ ...prev, ...next }), {});
  }

  console.log('Unknown type', type._tag);

  return {};
}

function createUrlBuilder(method: HttpMethod): HttpApiDefUrlBuilder {
  return <TUrl>(
    urlOrTemplate: TemplateStringsArray | string | ((params: TUrl) => UrlSpec),
    ...interpolations: (string | number | boolean | ((params: TUrl) => string | number | boolean))[]
  ): HttpApiDefBuilder<TUrl> => {
    let url: string | ((params: TUrl) => UrlSpec);
    if (typeof urlOrTemplate === 'string') {
      url = urlOrTemplate;
    } else if (typeof urlOrTemplate === 'function') {
      url = urlOrTemplate;
    } else if (Array.isArray(urlOrTemplate)) {
      if (interpolations.some(interpolation => typeof interpolation === 'function')) {
        url = (params: TUrl) =>
          interpolate(
            urlOrTemplate,
            ...interpolations.map(interpolation =>
              typeof interpolation === 'function' ? interpolation(params) : interpolation
            )
          );
      } else {
        url = interpolate(urlOrTemplate, ...interpolations);
      }
    }
    return createRequestBuilder<TUrl>(() => ({
      method,
      url
    }));
  };
}

export const http: HttpApiDefMethodBuilder = {
  get: createUrlBuilder('GET'),
  post: createUrlBuilder('POST'),
  put: createUrlBuilder('PUT'),
  delete: createUrlBuilder('DELETE')
};
