/**
 * This module is a bridge from angular DI system to ES6 module imports.
 * It allows to import services/values/controllers/whatever registered in DI.
 * It also re-exports some core angular services imported from 'ngimport' module.
 *
 * Procedure of adding importable services
 *
 * 1. Declare export in 'Declaration of injected services':
 *
 * export let someService: Type;
 *
 * Make sure you declare the type of the service.
 * Sometimes it is convinient to use the following construct to get the type.
 * This is when the service is registered with a factory that returns it:
 *
 * export let featureFlagFactory: ReturnType<
 *   typeof import('./factories/feature_flag_factory').featureFlagFactory
 * >;
 *
 * Make sure the name is exact the same name as it is registered in DI.
 * Make sure the type is the type of value that you get from DI.
 *
 * 2. Add service to request in 'Request injectable services'
 *
 * It is a call to a function `getServices` that lists the services to request.
 * Add the service to the object.
 *
 * const injected = getServices($injector, {
 *   ...,
 *   someService
 * });
 *
 * 3. Assign the returned service to the exported variable in 'Assigning returned services to exports'
 *
 * someService = injected.someService;
 */

export { $rootScope, $injector, $http } from 'ngimport';
import { StateService } from '@uirouter/angularjs';
import { getServices } from './services_helper';

import {
  CompanyAndAccountFactoryService,
  Debounce2Service,
  UserFactoryService,
  GeneralUtilsService,
  FeatureFlagService,
  JobService,
  MapUtilsService,
  NotificationService,
  CampaignManagerService,
  CsvXlsxUtilsService,
  ReportService,
  SearchService,
  SelfServeDataService,
  StaticDataService,
  TenantService,
  XadcmsService,
  UniversalAlertService,
  ChartUtilsService
} from '../factories/types';

// Declaration of injected services

export let campaignManagerFactory: CampaignManagerService;
export let chartUtilsFactory: ChartUtilsService;
export let companyAndAccountFactory: CompanyAndAccountFactoryService;
export let csvXlsxUtilsFactory: CsvXlsxUtilsService;
export let debounce2: Debounce2Service;
export let featureFlagFactory: FeatureFlagService;
export let generalUtilsFactory: GeneralUtilsService;
export let jobFactory: JobService;
export let mapsUtilsFactory: MapUtilsService;
export let notificationFactory: NotificationService;
export let reportFactory: ReportService;
export let searchFactory: SearchService;
export let selfServeDataFactory: SelfServeDataService;
export let staticDataFactory: StaticDataService;
export let tenantFactory: TenantService;
export let userFactory: UserFactoryService;
export let xadcmsFactory: XadcmsService;
export let universalAlert: UniversalAlertService;
export let $state: StateService;
// 1. add a new export declaration here
// export let serviceName: ServiceType;

export let initialized = false;
let onInitListeners: (() => void)[] = [];

/** @ngInject */
function init($injector: ng.auto.IInjectorService) {
  // Request injectable services
  const injected = getServices($injector, {
    campaignManagerFactory,
    chartUtilsFactory,
    companyAndAccountFactory,
    csvXlsxUtilsFactory,
    debounce2,
    featureFlagFactory,
    generalUtilsFactory,
    jobFactory,
    mapsUtilsFactory,
    notificationFactory,
    reportFactory,
    searchFactory,
    selfServeDataFactory,
    staticDataFactory,
    tenantFactory,
    userFactory,
    xadcmsFactory,
    universalAlert,
    $state
    // 2. add a declared service to this object
  });
  // Assigning returned services to exports
  campaignManagerFactory = injected.campaignManagerFactory;
  companyAndAccountFactory = injected.companyAndAccountFactory;
  csvXlsxUtilsFactory = injected.csvXlsxUtilsFactory;
  debounce2 = injected.debounce2;
  featureFlagFactory = injected.featureFlagFactory;
  generalUtilsFactory = injected.generalUtilsFactory;
  jobFactory = injected.jobFactory;
  mapsUtilsFactory = injected.mapsUtilsFactory;
  notificationFactory = injected.notificationFactory;
  reportFactory = injected.reportFactory;
  searchFactory = injected.searchFactory;
  selfServeDataFactory = injected.selfServeDataFactory;
  staticDataFactory = injected.staticDataFactory;
  tenantFactory = injected.tenantFactory;
  userFactory = injected.userFactory;
  xadcmsFactory = injected.xadcmsFactory;
  universalAlert = injected.universalAlert;
  $state = injected.$state;
  // 3. Assign the returned service to the variable
  // someService = injected.someService

  initialized = true;

  for (let listener of onInitListeners) {
    listener();
  }

  onInitListeners = [];
}

export function onInit(listener: () => void) {
  if (initialized) {
    listener();
  } else {
    onInitListeners.push(listener);
  }
}

export default angular.module('app.services', ['bcherny/ngimport']).run(init);
