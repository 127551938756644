import * as React from 'react';
import { useObserver } from 'mobx-react-lite';
import { EditableFormField } from '../index';
import { DatePicker } from '../../reactComponents/DatePicker/DatePicker';

interface DateFieldProps {
  field: EditableFormField<Date | null>;
  label: string;
  popupId?: string;
  className?: string;
  fullWidth?: boolean;
}

export const DateField = (props: DateFieldProps) =>
  useObserver(() => (
    <DatePicker
      popupId={props.popupId}
      className={props.className}
      label={props.label}
      value={props.field.value}
      onChange={props.field.update}
      error={props.field.validationMessage ?? undefined}
      fullWidth={props.fullWidth}
    />
  ));
