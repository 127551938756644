export function selfServeDataFactory() {
  var selfServeDataFactory = {};

  selfServeDataFactory.nullAdGroup = {
    adPackage: [],
    bidRate: null,
    bidType: null,
    budgetPadding: null,
    is_CPV: false,
    enableStoreVisitation: false,
    enableStoreVisitationLift: false,
    creative: {
      landingPageUrl: null,
      lpTypeName: 'Standard Landing Page',
      weight: null,
      freqCapValue: null,
      freqCapDuration: null,
      cmsRevisionId: null,
      creative_api: 'NONE',
      cmsConfig: {},
      useLandingPage: false,
      creativeType: null,
      creativeSize: null,
      creativeInterstitial: false,
      vendorIdentifier: null,
      creativeIUrl: '',
      lpConfig: {
        imageUrl_640x400: '',
        vanityPhone: '',
        button1Css: '',
        street: '',
        cssYouTube: '',
        button2Signal: '',
        button3Signal: '',
        button1Signal: '',
        city: '',
        enableAdditionalCTA: '',
        externalClickUrl: '',
        state: '',
        button2Link: '',
        additionalCTA: '',
        attribution_overide: '',
        customYouTubeId: '',
        button1Link: '',
        enableDirectionsButton: '',
        buttonColor: '',
        business_latitude: '',
        couponUrl: '',
        business_longitude: '',
        notificationUrl: '',
        phone: '',
        disclaimerTitle: '',
        callUrl: '',
        enablePhoneButton: '',
        name: '',
        button3Link: '',
        flipCouponVideo: '',
        button3Css: '',
        disclaimer: '',
        couponWebUrl: '',
        flipAddressFields: '',
        google_analytics_key: '',
        localized_text_call: 'Call',
        localized_text_directions: 'Direction'
      }
    },
    product: '',
    creatives: [],
    id: null,
    deliveryChannel_id: 0,
    ctr_threshold: null,
    conversion_threshold: null,
    basic_delivery: false,
    sar_threshold: null,
    psvr_threshold: null,
    optimization_strategy: null,
    mode: null,
    name: null,
    time_period_budgets: [],
    budget_carryover: false,
    status: null,
    timezone: null,
    timeframe: {
      end: null,
      remain: null,
      start: null
    },
    buildSegment: false,
    targeting: {
      audience: [],
      lookalikeAudienceScale: null,
      checksum: null,
      publisherSettings: [],
      technographics: [],
      carriers: [],
      deviceTypes: [],
      demographicsAge: [],
      demographicsIncome: [],
      demographicsEthnicity: [],
      name: null,
      on_premise: [],
      neighborhood: [],
      weather: {},
      location_filters: [],
      country: {
        id: null,
        name: null,
        code: null
      },
      from: {
        geotarget_job_id: null
      },
      measurements: [],
      cpgProducts: [],
      business_profile: {
        isNew: false,
        id: null,
        name: null,
        addresses_count: 0
      },
      dayParting: [],
      demographicsGender: null,
      twentyOneAndOver: false,
      privacySafeIdfas: false,
      tierPublisherSettings: {
        runOfNetwork: false,
        tiers: []
      }
    },
    pacingMode: 'DEFAULT',
    showAudienceExclude: false,
    appData: {
      selectedDaypartOption: {
        option: null
      }
    }
  };

  selfServeDataFactory.nullWeatherObject = {
    weather_alert: {
      phenomena: null
    },
    weather_pollen: {
      operator: 'gte',
      value: null
    },
    weather_temperature: {
      operator: 'gte',
      value: [],
      feels_like: false,
      time_constraint: 'ob'
    },
    weather_condition: {
      condition: null,
      time_constraint: 'ob'
    },
    generic: {
      operator: 'gte',
      value: null,
      time_constraint: 'ob'
    }
  };

  selfServeDataFactory.testDayPartOption = function(data) {
    if (!data) return null;
    var flag = true;
    var allFalseFlag = false;

    for (var i = 0; i < 7; i++) {
      for (var j = 0; j < 24; j++) {
        flag = flag && data[i].hours[j].status;
        allFalseFlag = data[i].hours[j].status || allFalseFlag;
      }
    }

    if (flag || !allFalseFlag) {
      for (var i = 0; i < 7; i++) {
        for (var j = 0; j < 24; j++) {
          data[i].hours[j].status = true;
        }
      }
      return 'All Times';
    }

    flag = true;

    for (var i = 0; i < 7; i++) {
      for (var j = 0; j < 24; j++) {
        if (i == 0 || i == 6) {
          flag = flag && data[i].hours[j].status;
        } else {
          if (data[i].hours[j].status) {
            flag = false;
          }
        }
      }
    }

    if (flag) {
      return 'Weekend';
    }

    flag = true;

    for (var i = 0; i < 7; i++) {
      for (var j = 0; j < 24; j++) {
        if (i != 0 && i != 6) {
          flag = flag && data[i].hours[j].status;
        } else {
          if (data[i].hours[j].status) {
            flag = false;
          }
        }
      }
    }

    if (flag) {
      return 'Weekdays';
    }

    flag = true;

    for (var i = 0; i < 7; i++) {
      for (var j = 0; j < 24; j++) {
        if (j >= 5 && j < 10) {
          flag = flag && data[i].hours[j].status;
        } else {
          if (data[i].hours[j].status) {
            flag = false;
          }
        }
      }
    }

    if (flag) {
      return 'Early Morning';
    }

    flag = true;

    for (var i = 0; i < 7; i++) {
      for (var j = 0; j < 24; j++) {
        if ((j >= 17 && j < 24) || j == 0) {
          flag = flag && data[i].hours[j].status;
        } else {
          if (data[i].hours[j].status) {
            flag = false;
          }
        }
      }
    }

    if (flag) {
      return 'Evenings';
    }

    flag = true;

    for (var i = 0; i < 7; i++) {
      for (var j = 0; j < 24; j++) {
        if ((j >= 0 && j < 4) || (j >= 22 && j < 24)) {
          flag = flag && data[i].hours[j].status;
        } else {
          if (data[i].hours[j].status) {
            flag = false;
          }
        }
      }
    }

    if (flag) {
      return 'Late Night';
    }

    flag = true;

    for (var i = 0; i < 7; i++) {
      for (var j = 0; j < 24; j++) {
        if (i != 0 && i != 6 && j >= 9 && j < 18) {
          flag = flag && data[i].hours[j].status;
        } else {
          if (data[i].hours[j].status) {
            flag = false;
          }
        }
      }
    }

    if (flag) {
      return 'Working Hours';
    }

    flag = true;

    for (var i = 0; i < 7; i++) {
      for (var j = 0; j < 24; j++) {
        if (i != 0 && i != 6 && j >= 8 && j < 16) {
          flag = flag && data[i].hours[j].status;
        } else {
          if (data[i].hours[j].status) {
            flag = false;
          }
        }
      }
    }

    if (flag) {
      return 'School Hours';
    }

    flag = true;

    for (var i = 0; i < 7; i++) {
      for (var j = 0; j < 24; j++) {
        if (j >= 18 && j < 21) {
          flag = flag && data[i].hours[j].status;
        } else {
          if (data[i].hours[j].status) {
            flag = false;
          }
        }
      }
    }

    if (flag) {
      return 'Happy Hour';
    }

    flag = false;

    for (var i = 0; i < 7; i++) {
      for (var j = 0; j < 24; j++) {
        flag = flag || data[i].hours[j].status;
      }
    }

    if (!flag) {
      return null;
    }

    return 'Custom';
  };

  return selfServeDataFactory;
}
