import { uniq } from 'lodash';

export function csvXlsxUtilsFactory() {
  var readCsvXlsxFile = function(data, type) {
    var entries = [];
    var workbook = XLS.read(data, { type: 'binary' });
    workbook.SheetNames.forEach(function(sheetName) {
      var data = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
      var quoteRegex = new RegExp('[\'"]+', 'g');
      angular.forEach(
        data,
        function(value, key) {
          // Some CSVs double-quote each value so need to check for this
          if (type == 'zip') {
            this.push(value.Zipcode || (value['"ZipCode"'] && value['"ZipCode"'].replace(quoteRegex, '')));
          } else if (type == 'excludedapps') {
            this.push(
              value.ExcludedApps || (value['"ExcludedApps"'] && value['"ExcludedApps"'].replace(quoteRegex, ''))
            );
          } else if (type == 'excludeddomains') {
            this.push(
              value.ExcludedDomains ||
                (value['"ExcludedDomains"'] && value['"ExcludedDomains"'].replace(quoteRegex, ''))
            );
          } else if (type == 'includedapps') {
            this.push(
              value.IncludedApps || (value['"IncludedApps"'] && value['"IncludedApps"'].replace(quoteRegex, ''))
            );
          } else if (type === 'includeddomains') {
            this.push(
              value.IncludedDomains ||
                (value['"IncludedDomains"'] && value['"IncludedDomains"'].replace(quoteRegex, ''))
            );
          }
        },
        entries
      );
    });

    return uniq(entries);
  };

  return {
    readCsvXlsxFile: readCsvXlsxFile
  };
}
