import * as React from 'react';
import PropTypes from 'prop-types';
import { DeviceTypesSelector, DeviceTypeProp } from '../../../components/reactComponents/DeviceType';
import {
  AudioIcon,
  MobileIcon,
  DesktopIcon,
  TVIcon,
  OTTIcon,
  DirectMailIcon,
  SplashIcon
} from '../../../components/reactComponents/Icons';

export const AudioDeviceIcon = <AudioIcon viewBox="0 0 48 48" style={{ width: '48px', height: '48px' }} />;
export const MobileDeviceIcon = <MobileIcon viewBox="0 0 48 48" style={{ width: '48px', height: '48px' }} />;
export const DesktopDeviceIcon = <DesktopIcon viewBox="0 0 48 48" style={{ width: '48px', height: '48px' }} />;
export const CTVDeviceIcon = <TVIcon viewBox="0 0 48 48" style={{ width: '48px', height: '48px' }} />;
export const OTTDeviceIcon = <OTTIcon viewBox="0 0 48 48" style={{ width: '48px', height: '48px' }} />;
export const DirectMailDeviceIcon = <DirectMailIcon viewBox="0 0 48 48" style={{ width: '48px', height: '48px' }} />;
export const SplashImage = (
  <SplashIcon
    viewBox="0 0 100 100"
    style={{ width: '4.5em', height: '4.5em', marginLeft: '-1vw', marginTop: '-4vh' }}
  />
);

enum deviceTypeOptions {
  mobile = 'mobile',
  app = 'app',
  web = 'web',
  desktop = 'desktop',
  ctv = 'ctv',
  ott = 'ott',
  directMail = 'directMail',
  audio = 'audio',
  podcast = 'audio-podcast',
  streaming = 'audio-streaming'
}

enum deviceTypeLabel {
  mobile = 'Mobile',
  app = 'App',
  web = 'Web',
  desktop = 'Desktop',
  ctv = 'CTV',
  ott = 'OTT',
  directMail = 'Direct Mail',
  audio = 'Audio',
  podcast = 'Podcast',
  streaming = 'Streaming'
}

interface deviceTypeComponentProp {
  tooltips: object;
  subtext: string;
  selectedDeviceTypes: deviceTypeOptions[];
  allowedDeviceTypes: deviceTypeOptions[];
  handleChange: (selected: DeviceTypeProp[], disabled: DeviceTypeProp[]) => void;
}

export function DeviceType(props: deviceTypeComponentProp) {
  const { selectedDeviceTypes, allowedDeviceTypes, handleChange, subtext, tooltips } = props;

  const { mobile, desktop, ctv, ott, directMail, audio } = React.useMemo(
    () => ({
      mobile: {
        icon: MobileDeviceIcon,
        label: deviceTypeLabel.mobile,
        name: deviceTypeOptions.mobile,
        selectedDisabled: true,
        tooltip: tooltips[deviceTypeOptions.mobile],
        parent: null,
        children: [
          {
            label: deviceTypeLabel.app,
            name: deviceTypeOptions.app,
            disabled: true, //default selected
            parent: deviceTypeLabel.mobile
          },
          {
            label: deviceTypeLabel.web,
            name: deviceTypeOptions.web,
            parent: deviceTypeLabel.mobile,
            selectSibling: true
          }
        ]
      },
      desktop: {
        icon: DesktopDeviceIcon,
        label: deviceTypeLabel.desktop,
        name: deviceTypeOptions.desktop,
        parent: null
      },
      ctv: {
        icon: CTVDeviceIcon,
        label: deviceTypeLabel.ctv,
        tooltip: tooltips[deviceTypeOptions.ctv],
        name: deviceTypeOptions.ctv,
        parent: null
      },
      ott: {
        icon: OTTDeviceIcon,
        label: deviceTypeLabel.ott,
        tooltip: tooltips[deviceTypeOptions.ott],
        name: deviceTypeOptions.ott,
        parent: null
      },
      directMail: {
        icon: DirectMailDeviceIcon,
        label: deviceTypeLabel.directMail,
        tooltip: tooltips[deviceTypeOptions.directMail],
        name: deviceTypeOptions.directMail,
        parent: null
      },
      audio: {
        icon: AudioDeviceIcon,
        label: deviceTypeLabel.audio,
        tooltip: tooltips[deviceTypeOptions.audio],
        name: deviceTypeOptions.audio,
        parent: null,
        children: [
          {
            label: deviceTypeLabel.podcast,
            name: deviceTypeOptions.podcast,
            parent: deviceTypeLabel.audio
          },
          {
            label: deviceTypeLabel.streaming,
            name: deviceTypeOptions.streaming,
            parent: deviceTypeLabel.audio
          }
        ]
      }
    }),
    []
  );

  const allDeviceTypes = [mobile, desktop, ctv, ott, audio, directMail];
  const deviceTypes = React.useMemo(
    () => allDeviceTypes.filter(deviceType => allowedDeviceTypes.includes(deviceType.name)),
    []
  );

  const getSelectedDevices = (deviceTypes: DeviceTypeProp[], selectedDeviceTypes) => {
    let selectedDevices: DeviceTypeProp[] = [];
    for (let deviceType of deviceTypes) {
      const res = deviceType.children ?? [deviceType];
      selectedDevices = [...selectedDevices, ...res.filter(type => selectedDeviceTypes.includes(type.name))];
    }
    return selectedDevices;
  };

  const selectedDevices = getSelectedDevices(deviceTypes, selectedDeviceTypes);
  const [selected, setSelected] = React.useState(selectedDevices);

  const disabled = [];

  const onClick = function(deviceType, needSelected) {
    /**
     * Multiple selections are not allowed unless the option itself has
     * multiple children nodes.
     */
    const res: DeviceTypeProp[] = deviceType.children ?? [deviceType];
    const parentsInSelected = selected.filter(type => type?.parent).map(c => c?.parent);
    const parentsInSelection = res.filter(type => type?.parent).map(c => c?.parent);
    const numOfParents = new Set([...parentsInSelected, ...parentsInSelection]).size;
    const sameParents = numOfParents === 1 && parentsInSelected.length && parentsInSelection.length;

    if (needSelected) {
      if (sameParents) {
        setSelected([...new Set([...selected, ...res])]);
      } else {
        setSelected([...res]);
      }
    } else {
      setSelected(selected.filter(s => !res.includes(s)));
    }
    if (res[0].name === 'directMail') {
      setSelected([]);
    }
  };

  React.useEffect(() => {
    //pass back the selected, disabled values to angular
    handleChange(selected, disabled);
  });

  return (
    <DeviceTypesSelector
      deviceTypes={deviceTypes}
      onClick={onClick}
      selected={selected}
      disabled={disabled}
      subtext={subtext}
      directMailUrl={window.directMailURL}
    />
  );
}

DeviceType.propTypes = {
  allowedDeviceTypes: PropTypes.array,
  selectedDeviceTypes: PropTypes.array,
  tooltips: PropTypes.object,
  subtext: PropTypes.string,
  handleChange: PropTypes.func
};
