CreativeEditorCtrl.$inject = ['$scope', '$filter', 'staticDataFactory', 'companyAndAccountFactory'];

/**
 * @param {ng.IFilterService} $filter
 * @param {import('../../../factories/static_data.fac').IStaticDataFactory} staticDataFactory
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 */
export function CreativeEditorCtrl($scope, $filter, staticDataFactory, companyAndAccountFactory) {
  $scope.isShowingMacros = false;
  $scope.isShowingAdvanced = false;
  $scope.boundingBox = $('#creative-container-' + $scope.distanceBox.containerId);

  staticDataFactory.getMacros(companyAndAccountFactory.selectedAccount).then(function() {
    $scope.macros = staticDataFactory.macroMapTable;
  });

  function init() {
    setEditingText();
    $scope.editingTextCSS = getEditingTextCSS();
    $('.' + $scope.distanceBox.id).css($scope.editingTextCSS);
  }

  if ($scope.distanceBoxChanged) {
    $scope.$watch(
      function() {
        return $scope.distanceBoxChanged.changed;
      },
      function() {
        if ($scope.distanceBoxChanged.changed) {
          init();
        }
      }
    );
  }

  init();

  function getEditingTextCSS() {
    if (!$scope.distanceBox.css) {
      return;
    }

    var i;
    var key;
    var styles = $scope.distanceBox.css;
    var filters = ['width', 'height', 'left', 'top', 'right', 'bottom', 'position', 'margin'];
    var result = {};
    var found = false;

    for (key in styles) {
      if (key && styles.hasOwnProperty(key) && styles[key]) {
        found = false;
        for (i = 0; i < filters.length; i += 1) {
          if (filters[i] === key) {
            found = true;
            break;
          }
        }

        if (!found) {
          result[key] = styles[key];
        }
      }
    }

    return result;
  }

  function setEditingText() {
    $scope.editingText = $filter('macroToString')($scope.distanceBox.distanceText, $scope.macros);
  }

  $scope.addMacroToText = function(e, macroName, macro) {
    const distanceTextArea = /** @type {HTMLTextAreaElement} */ ($('#distance-text')[0]);
    var cursorPos = distanceTextArea.selectionStart;
    var currentText = $scope.distanceBox.distanceText;
    $scope.distanceBox.distanceText = [currentText.slice(0, cursorPos), macro.macro, currentText.slice(cursorPos)].join(
      ' '
    );
    distanceTextArea.selectionEnd = cursorPos + macro.macro.length;
    $scope.textUpdated();
  };

  $scope.textUpdated = function() {
    setEditingText();
  };

  $scope.$watch('imageUrlForOverlay', function(newVal) {
    if ($scope.imageUrlForOverlay && $scope.imageUrlForOverlay.length) {
      var boundingBoxDim = {};
      boundingBoxDim.height = $('#creative-container-' + $scope.distanceBox.containerId + ' img').height();
      boundingBoxDim.width = $('#creative-container-' + $scope.distanceBox.containerId + ' img').width();
      if ($scope.imageUrlForOverlay && boundingBoxDim.height > 0 && boundingBoxDim.width > 0) {
        $scope.boundingBox.css({
          width: boundingBoxDim.width + 'px',
          height: boundingBoxDim.height + 'px'
        });
        if (boundingBoxDim.width > 768) {
          $scope.boundingBox.css({
            overflow: 'auto'
          });
        }
      }
      setEditingText();
    }
  });

  $scope.$watchCollection(
    function() {
      if ($scope.imageUrlForOverlay) {
        var distanceBox = $('.' + $scope.distanceBox.id);
        if (distanceBox && distanceBox.position() !== undefined) {
          return {
            top: distanceBox.position().top,
            left: distanceBox.position().left,
            width: distanceBox.width(),
            height: distanceBox.height()
          };
        }
      }
    },
    function(newVal) {
      if ($scope.imageUrlForOverlay && newVal) {
        $scope.distanceBox.css.left = newVal.left + 'px';
        $scope.distanceBox.css.top = newVal.top + 'px';
        $scope.distanceBox.css.width = newVal.width + 'px';
        $scope.distanceBox.css.height = newVal.height + 'px';
        $scope.$broadcast('distance_box_pos_dim_change', $scope.distanceBox);
      }
    }
  );
}

/** @ngInject */
export function creativeTextEditor($compile) {
  return {
    restrict: 'E',
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.creative_text_editor.html',
    controller: 'CreativeEditorCtrl',
    scope: {
      distanceBoxChanged: '=?',
      distanceBox: '=',
      imageUrlForOverlay: '@',
      showAdvancedOptions: '=',
      showOverlayEditorBox: '=',
      isVideo: '=',
      disableText: '=',
      disableDrag: '=',
      horizontalLayout: '='
    },
    link: function(scope, elem, atts) {
      scope.placeNode = function(node, top, left) {
        node.css({
          position: 'absolute',
          top: top + 'px',
          left: left + 'px'
        });

        /*
         * On placing the node to new location, we're also updating the same location
         * to the top level scope.
         */
        scope.distanceBox.css.top = top + 'px';
        scope.distanceBox.css.left = left + 'px';
      };

      scope.$watch('imageUrlForOverlay', function(newVal) {
        if (newVal) {
          compileAndPlaceBox();
        }
      });

      function setTopFromBottom(css, imageHeight) {
        if (css.hasOwnProperty('bottom')) {
          if (!css.hasOwnProperty('font-size')) {
            css['font-size'] = 14; // MPCB default font size
          }
          var lineHeigth = Math.floor(parseInt(css['font-size'].replace('px', '')) * 1.5);

          css['top'] = (imageHeight - lineHeigth - parseInt(css['bottom'].replace('px', ''))).toString() + 'px';
          delete css.bottom;
        }
      }

      function setWidth(css, imageWidth) {
        if (!css.hasOwnProperty('width') && css.hasOwnProperty('left')) {
          css['width'] = (imageWidth - parseInt(css['left'].replace('px', ''))).toString() + 'px';
        }
      }

      function setHeight(css, imageHeight) {
        var top = parseInt(css['top'].replace('px', ''));

        if (css.hasOwnProperty('height')) {
          var height = parseInt(css['height'].replace('px', ''));
          if (top + height > imageHeight) {
            css['height'] = (imageHeight - parseInt(css['top'].replace('px', ''))).toString() + 'px';
          }
        } else {
          css['height'] = (imageHeight - parseInt(css['top'].replace('px', ''))).toString() + 'px';
        }
      }

      function compileAndPlaceBox() {
        if (scope.imageUrlForOverlay) {
          if ($('.' + scope.distanceBox.id)) {
            $('.' + scope.distanceBox.id).remove();
          }

          var element = angular.element(
            '' +
              '<div class="{{distanceBox.id}}" ce-drag ce-resize>' +
              '<textarea readonly ng-if="!isVideo" id="{{distanceBox.id}}-textarea" class="inner-text" ng-style="editingTextCSS">{{editingText | macroToString:macros}}</textarea>' +
              '<iframe ng-if="isVideo && distanceBox.videoUrl !== \'\'" ng-src={{distanceBox.videoUrl}} id="{{distanceBox.id}}-video" frameborder="0" class="inner-text" ng-style="editingTextCSS"></iframe>' +
              '</div>'
          );

          element.attr('ng-class', '{"overlay-editor-shown": showOverlayEditorBox}');
          var newNode = $compile(element)(scope);

          setTopFromBottom(scope.distanceBox.css, scope.boundingBox.height());

          setWidth(scope.distanceBox.css, scope.boundingBox.width());

          setHeight(scope.distanceBox.css, scope.boundingBox.height());

          newNode.css(scope.distanceBox.css);
          scope.boundingBox.append(newNode);
          scope.placeNode(newNode, scope.distanceBox.css.top, scope.distanceBox.css.left);
        }
      }
    }
  };
}

export function textBoxOptions() {
  return {
    restrict: 'E',
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.text_box_options.html',
    scope: true,
    link: function(scope, element, attrs) {
      var targetTextBox = attrs.targetTextBox;

      scope.targetTextBoxDimPos = scope.distanceBox.css;

      scope.$on('distance_box_pos_dim_change', function(e, d) {
        if (d.id == targetTextBox) {
          scope.targetTextBoxDimPos = d.css;
        }
      });

      scope.changeBoxDimPos = function(e) {
        if (e.which == 13 || e.keyCode == 13 || e.type == 'blur') {
          e.preventDefault();
          var boxDimPosChange = $(e.target).val();
          var boxAttr = $(e.target).attr('name');

          if (targetTextBox == 'creativeEditorCustomBox') {
            $('.creativeEditorCustomBox').css(boxAttr, boxDimPosChange);
          } else {
            $('.' + targetTextBox).css(boxAttr, boxDimPosChange);
          }
        }
      };
    }
  };
}

export function textOptions() {
  return {
    restrict: 'E',
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.text_options.html',
    scope: {
      targetTextBox: '@',
      distanceBox: '='
    },
    controller: /** @ngInject */ function($scope) {
      $scope.fontOptions = {
        type: $scope.distanceBox.css['font-family'],
        size: $scope.distanceBox.css['font-size'],
        color: $scope.distanceBox.css.color,
        alignment: $scope.distanceBox.css['text-align']
      };

      $scope.hasTextShadow = $scope.distanceBox.css['text-shadow'];
      if ($scope.hasTextShadow !== undefined && $scope.hasTextShadow !== 'none') {
        $scope.isTextShadow = true;
      }

      $scope.isBold = false;
      $scope.isItalic = false;

      $scope.editText = function(textProperty, value) {
        var targetTextBox;

        if ($scope.targetTextBox == 'custom-text-box') {
          targetTextBox = '.creativeEditorCustomBox';
        } else {
          targetTextBox = '.' + $scope.targetTextBox + ' textarea';
        }

        targetTextBox = $(targetTextBox);

        if (textProperty == 'align') {
          targetTextBox.css('text-align', value);
          $scope.fontOptions.alignment = value;
          $scope.distanceBox.css['text-align'] = value;
        } else if (textProperty == 'font') {
          targetTextBox.css('font-family', value);
          $scope.distanceBox.css['font-family'] = value;
        } else if (textProperty == 'font-size') {
          targetTextBox.css('font-size', value);
          $scope.distanceBox.css['font-size'] = value;
        } else if (textProperty == 'color') {
          $scope.distanceBox.css.color = value;
          targetTextBox.css('color', value);
        } else if (textProperty == 'text-shadow') {
          targetTextBox.css('text-shadow', value);
          $scope.distanceBox.css['text-shadow'] = value;
        } else if (textProperty == 'font-weight') {
          $scope.isBold = !$scope.isBold;
          if (!$scope.isBold) {
            targetTextBox.css('font-weight', 'normal');
            $scope.distanceBox.css['font-weight'] = 'normal';
          } else {
            targetTextBox.css('font-weight', 'bold');
            $scope.distanceBox.css['font-weight'] = 'bold';
          }
        } else if (textProperty == 'font-style') {
          $scope.isItalic = !$scope.isItalic;
          if (!$scope.isItalic) {
            targetTextBox.css('font-style', 'normal');
            $scope.distanceBox.css['font-style'] = 'normal';
          } else {
            targetTextBox.css('font-style', 'italic');
            $scope.distanceBox.css['font-style'] = 'italic';
          }
        }
      };

      //Font-family change
      $scope.fontNames = [
        'Helvetica',
        'Arial',
        'Georgia',
        'Baskerville',
        'Copperplate',
        'Courier',
        'Futura',
        'Helvetica Neue',
        'Trebuchet',
        'Verdana'
      ].map(function(fontsize) {
        return { name: fontsize };
      });
      $scope.fontNames.sort();
      $scope.fontNameChange = function() {
        $scope.editText('font', $scope.fontOptions.type);
      };

      //Font size change
      $scope.fontSizes = ['6px', '7px', '8px', '9px', '10px', '11px', '12px', '14px', '18px', '24px', '30px'].map(
        function(fontsize) {
          return { size: fontsize };
        }
      );
      $scope.changeFontSize = function() {
        $scope.editText('font-size', $scope.fontOptions.size);
      };

      //Font color change
      $scope.colorPickerChange = function() {
        $scope.editText('color', $scope.fontOptions.color);
      };

      //Shadow
      if ($scope.distanceBox.css['font-weight'] == 'bold') {
        $scope.editText('font-weight', 'bold');
      }
      $scope.changeTextShadow = function() {
        if ($scope.isTextShadow) {
          $scope.editText('text-shadow', '0px 0px 6px rgba(0, 0, 0, .85)');
        } else {
          $scope.editText('text-shadow', 'none');
        }
      };
    }
  };
}

/** @ngInject */
export function ceDrag($document, $compile) {
  return {
    link: function(scope, $element, $attr) {
      var startX = 0,
        startY = 0;

      var element = angular.element('<div class="draggable-' + $attr.class + '"></div>');

      // for video click to play and pause we need to disable to click event for drag
      element.attr('ng-class', '{"propagate-click": disableDrag}');

      var newElement = $compile(element)(scope);

      var nodePos = {};
      var boundingBox = {};
      var diffX;
      var diffY;

      $element.append(newElement);
      newElement.on('mousedown', function($event) {
        $event.preventDefault();

        // To keep the last selected box in front
        //$(".creativeEditorDistanceBox, .creativeEditorCustomBox").css("z-index", "0");
        $element.css('z-index', '1');

        startX = $event.pageX - $element[0].offsetLeft;
        startY = $event.pageY - $element[0].offsetTop;
        $document.on('mousemove', mousemove);
        $document.on('mouseup', mouseup);
        //startX = $event.pageX - $($element[0]).position().left;
        //startY = $event.pageY - $($element[0]).position().top;
        //$document.on("mousemove", mousemove);
        //$element.on("mouseup", mouseup);
      });

      function mousemove($event) {
        $event.preventDefault();
        boundingBox.right = scope.boundingBox[0].offsetWidth;
        boundingBox.bottom = scope.boundingBox[0].offsetHeight;

        nodePos.top = $element[0].offsetTop;
        nodePos.left = $element[0].offsetLeft;
        nodePos.right = nodePos.left + $element[0].offsetWidth;
        nodePos.bottom = nodePos.top + $element[0].offsetHeight;

        //if the mouse leaves the bounding box
        //the box should not come outside of the bounding box
        var top;
        var left;
        if (
          (nodePos.top < 0 || $event.pageY < scope.boundingBox.offset().top) &&
          (nodePos.left < 0 || $event.pageX < scope.boundingBox.offset().left)
        ) {
          // top left
          top = 0;
          left = 0;
        } else if (
          (nodePos.right > boundingBox.right || $event.pageX > boundingBox.right) &&
          (nodePos.bottom > boundingBox.bottom || $event.pageY > boundingBox.bottom) &&
          ($element[0].offsetTop + $element[0].offsetHeight > boundingBox.bottom ||
            $element[0].offsetLeft + $element[0].offsetWidth > boundingBox.right)
        ) {
          // bottom right

          top = boundingBox.bottom - $element[0].offsetHeight;
          left = boundingBox.right - $element[0].offsetWidth;
        } else if (
          (nodePos.top < 0 || $event.pageY < scope.boundingBox.offset().top) &&
          (nodePos.right > boundingBox.right || $event.pageX > boundingBox.right)
        ) {
          //top right

          top = 0;
          left = boundingBox.right - $element[0].offsetWidth;
        } else if (
          (nodePos.left < 0 || $event.pageX < scope.boundingBox.offset().left) &&
          (nodePos.bottom > boundingBox.bottom || $event.pageY > boundingBox.bottom)
        ) {
          // bottom left

          top = boundingBox.bottom - $element[0].offsetHeight;
          left = 0;
        } else if (nodePos.top < 0) {
          // top

          top = 0;
          left = $event.pageX - startX;
        } else if (nodePos.left < 0) {
          // left

          top = $event.pageY - startY;
          left = 0;
        } else if (nodePos.right > boundingBox.right) {
          // right

          top = $event.pageY - startY;
          left = boundingBox.right - $element[0].offsetWidth;
        } else if (nodePos.bottom > boundingBox.bottom) {
          // bottom

          top = boundingBox.bottom - $element[0].offsetHeight;
          left = $event.pageX - startX;
        } else {
          top = $event.pageY - startY;
          left = $event.pageX - startX;
        }

        top = top < 0 ? 0 : top;
        left = left < 0 ? 0 : left;

        top = top + $element[0].offsetHeight > boundingBox.bottom ? boundingBox.bottom - $element[0].offsetHeight : top;
        left = left + $element[0].offsetWidth > boundingBox.right ? boundingBox.right - $element[0].offsetWidth : left;

        scope.placeNode($element, top, left);
      }

      function mouseup($event) {
        $event.preventDefault();
        mousemove($event);
        $document.off('mousemove', mousemove);
        $document.off('mouseup', mouseup);
      }
    }
  };
}

/** @ngInject */
export function ceResize($document, $compile) {
  return {
    link: function(scope, $element, $attr) {
      var $mouseDown;
      var relPageX;
      var relPageY;

      var resizeUp = function($event, boundingBoxHeight, boundingBoxWidth) {
        var margin = 0,
          lowest = $mouseDown.top + $mouseDown.height - margin,
          top = relPageY > lowest ? lowest : relPageY,
          height = $mouseDown.top - top + $mouseDown.height;

        // consider top margin and height only when top is greater than 0
        if (top >= 0) {
          $element.css({
            top: top + 'px',
            height: height + 'px'
          });
          /*
           * Upon successful resize action, updating the co-ordinates to top level scope
           */
          scope.distanceBox.css.top = top + 'px';
          scope.distanceBox.css.height = height + 'px';
        }
      };

      var resizeRight = function($event, boundingBoxHeight, boundingBoxWidth) {
        var margin = 0,
          leftest = $element[0].offsetLeft + margin,
          width = relPageX > leftest ? relPageX - $element[0].offsetLeft : margin;

        // width needs to be less than container
        width = $element[0].offsetLeft + $element[0].offsetWidth > boundingBoxWidth ? $element[0].offsetWidth : width;

        $element.css({
          width: width + 'px'
        });
        scope.distanceBox.css.width = width + 'px';
      };

      var resizeDown = function($event, boundingBoxHeight, boundingBoxWidth) {
        var margin = 0,
          uppest = $element[0].offsetTop + margin,
          height = relPageY > uppest ? relPageY - $element[0].offsetTop : margin;

        // height needs to be less than container
        height =
          $element[0].offsetTop + $element[0].offsetHeight > boundingBoxHeight ? $element[0].offsetHeight : height;

        $element.css({
          height: height + 'px'
        });
        scope.distanceBox.css.height = height + 'px';
      };

      function resizeLeft($event) {
        var margin = 0,
          rightest = $mouseDown.left + $mouseDown.width - margin,
          left = relPageX > rightest ? rightest : relPageX,
          width = $mouseDown.left - left + $mouseDown.width;

        // consider left margin and width only when left is greater than 0
        if (left >= 0) {
          $element.css({
            left: left + 'px',
            width: width + 'px'
          });
          scope.distanceBox.css.left = left + 'px';
          scope.distanceBox.css.width = width + 'px';
        }
      }

      var createResizer = function createResizer(className, handlers) {
        var element = angular.element('<div class="overlay-resizer-' + $attr.class + ' ' + className + '"></div>');
        element.attr('ng-class', '{"overlay-editor-shown": showOverlayEditorBox}');

        var newElement = $compile(element)(scope);
        $element.append(newElement);

        newElement.on('mousedown', function($event) {
          $document.on('mousemove', mousemove);
          $document.on('mouseup', mouseup);
          scope.boundingBox.mouseleave(mouseup);

          //Keep the original event around for up / left resizing
          $mouseDown = $event;
          $mouseDown.top = $element[0].offsetTop;
          $mouseDown.left = $element[0].offsetLeft;
          $mouseDown.width = $element[0].offsetWidth;
          $mouseDown.height = $element[0].offsetHeight;

          function mousemove($event) {
            $event.preventDefault();
            var parentContainerOffset = scope.boundingBox.offset();

            var boundingBoxHeight = scope.boundingBox[0].offsetHeight;
            var boundingBoxWidth = scope.boundingBox[0].offsetWidth;

            for (var i = 0; i < handlers.length; i++) {
              relPageX = $event.pageX - parentContainerOffset.left;
              relPageY = $event.pageY - parentContainerOffset.top;
              handlers[i]($event, boundingBoxHeight, boundingBoxWidth);
            }
          }

          function mouseup($event) {
            $event.preventDefault();
            $document.off('mousemove', mousemove);
            $document.off('mouseup', mouseup);
          }
        });
      };

      createResizer('sw-resize', [resizeDown, resizeLeft]);
      createResizer('ne-resize', [resizeUp, resizeRight]);
      createResizer('nw-resize', [resizeUp, resizeLeft]);
      createResizer('se-resize', [resizeDown, resizeRight]);
    }
  };
}
