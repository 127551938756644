'use strict';

newFeature.$inject = [];

export function newFeature() {
  var directive = {
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.new-feature.html',
    bindToController: true,
    controller: NewFeatureController,
    controllerAs: 'vm',
    link: link,
    restrict: 'E',
    scope: {
      description: '@',
      expireDate: '@',
      learnMoreLink: '@?'
    }
  };
  return directive;

  function link(scope, element, attrs) {}
}

NewFeatureController.$inject = [];
function NewFeatureController() {
  var vm = this;

  this.$onInit = () => {
    var expireDate = Date.parse(vm.expireDate);
    vm.isNewFeature = isNaN(expireDate) ? true : Date.now() <= new Date(expireDate).valueOf();
    vm.learnMoreLink = vm.learnMoreLink || '';
  };
}
