export class tenantDashboardService {
  constructor($http) {
    this.$http = $http;
  }

  getExportData(body) {
    return this.request('POST', '/data/campaigns/tenant_dashboard/export', body, 'arraybuffer');
  }

  getCampaigns(body) {
    return this.request('POST', '/data/campaigns/tenant_dashboard', body);
  }

  async request(method, endpoint, body, responseType = 'application/json') {
    let res = null;
    try {
      res = await this.$http({
        method: method,
        url: endpoint,
        responseType: responseType,
        data: body
      });
    } catch (e) {
      console.error(e);
      res = e;
    } finally {
      return res;
    }
  }
}

tenantDashboardService.$inject = ['$http'];
