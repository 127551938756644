export function macroToString() {
  return macroToStringFilter;
}

/**
 * @param {string} input
 * @param {Record<string, import('../../api/types/macros').Macro>} macros
 */
function macroToStringFilter(input, macros) {
  if (!input) {
    return '';
  }

  var re = /<br\s*\/?\s*>/gi;
  input = input.replace(/\n+/g, '').replace(re, '\n');

  angular.forEach(macros, function(macroObj, macro) {
    var index = input.indexOf(macro);
    while (index > -1) {
      input = input.replace(input.slice(index, index + macro.length), macroObj.sample);
      index = input.indexOf(macro);
    }
  });
  return input;
}
