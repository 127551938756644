import { isEmpty } from 'lodash';

tenantFactory.$inject = ['$q', '$http'];

/**
 * @param {ng.IQService} $q
 * @param {ng.IHttpService} $http
 */
export function tenantFactory($q, $http) {
  var tenantFactory = {};
  tenantFactory.tenant = {};
  tenantFactory.tenantCountries = {};
  tenantFactory.countryStates = {};
  tenantFactory.tenantVertical = [];

  tenantFactory.getTenantDetail = function(tenantId) {
    const d = $q.defer();

    const currTenant = tenantFactory.tenant;
    if (!isEmpty(currTenant) && tenantId === currTenant.tenant_id) {
      d.resolve(tenantFactory.tenant);
    } else {
      $http.get('/data/tenant_details').then(
        function(response) {
          tenantFactory.tenant = response.data;
          d.resolve(response.data);
        },
        function(response) {
          return d.reject(response.data.message);
        }
      );
    }
    return d.promise;
  };

  tenantFactory.saveTenantDetails = function(tenantDetails) {
    var d = $q.defer();

    $http({ method: 'POST', url: '/data/saveTenantDetail', data: tenantDetails })
      .then(function({ data }) {
        tenantFactory.tenant = angular.copy(data);
        d.resolve(data);
      })
      .catch(function({ data }) {
        //TODO: handle error when saving
        d.reject(data.message);
      });
    return d.promise;
  };

  tenantFactory.getCountriesForTenants = function() {
    var d = $q.defer();

    $http({
      method: 'GET',
      url: '/data/countriesForTenants'
    }).then(
      function(response) {
        tenantFactory.tenantCountries = response.data;
        d.resolve();
      },
      function(data) {
        if (typeof data === 'string') {
          d.reject(data);
        } else {
          d.reject(data?.message ?? 'Unknown error ' + String(data));
        }
      }
    );
    return d.promise;
  };

  tenantFactory.getCountryStates = function() {
    var d = $q.defer();
    if (Object.keys(tenantFactory.countryStates).length > 0) {
      // if cached
      d.resolve(tenantFactory.countryStates);
      return d.promise;
    } else {
      $http({ method: 'GET', url: '/data/countryStatesForTenants' }).then(
        function(response) {
          angular.copy(response.data, tenantFactory.countryStates);
          d.resolve(response.data);
        },
        function(response) {
          console.log(response.data);
          d.reject('Something went wrong');
        }
      );
      return d.promise;
    }
  };

  tenantFactory.getTenantVertical = () => {
    const d = $q.defer();
    if (tenantFactory.tenantVertical.length > 0) {
      // if cached
      d.resolve(tenantFactory.tenantVertical);
      return d.promise;
    } else {
      $http({ method: 'GET', url: '/data/tenant_vertical' })
        .then(response => {
          angular.copy(response.data, tenantFactory.tenantVertical);
          d.resolve(response.data);
        })
        .catch(error => d.reject(error));
      return d.promise;
    }
  };

  tenantFactory.tenantHasPastBillingStatements = async () => {
    try {
      const response = await $http.get('/data/payments/statements/exist');
      return response.data.tenant_has_statements;
    } catch (err) {
      console.error('Failed to check for past billing statements');
      return false;
    }
  };

  return tenantFactory;
}
