export class abdmService {
  constructor($http) {
    this.$http = $http;
  }
  async getCampaigns() {
    let res = null;
    try {
      res = await this.$http({
        url: '/data/abdm/orders',
        method: 'GET'
      });
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      return res;
    }
  }
}

abdmService.$inject = ['$http'];
