import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const BackIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_4928_155176)">
      <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" fill="#0A3F36" />
    </g>
    <defs>
      <clipPath id="clip0_4928_155176">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'GtClose'
);

export default BackIcon;
