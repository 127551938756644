import * as React from 'react';
import { withStyles, styled } from '@material-ui/core/styles';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { primary3Palette } from '../styles/colorConstants';

export enum SortingDirection {
  ASCENDING = 1,
  DESCENDING = -1
}

interface Column<T> {
  /**
   * The header of the column
   */
  title: React.ReactNode;
  /**
   * The element type to be rendered inside a column cell
   */
  selector: (item: T) => React.ReactNode;
  /**
   * Class name for root element to allow styling.
   */
  className?: string;
  /** Will update the items props */
  onSort?: () => void;
  /**
   * When sortedDirection is null the column is not sorted
   * @default: null
   */
  sortedDirection?: SortingDirection | null;
}

interface BaseTableProps<T> {
  /**
   * List columns to be rendered. Please refer to the source code for object shape
   */
  columns: Column<T>[];
  /**
   * List of items to render
   */
  items: T[];
  /**
   * @returns key to idendify each row
   */
  getKey?: (item: T, index: number) => React.Key;
}

const TableHeaderCell = withStyles({
  root: {
    paddingBottom: 0,
    paddingTop: 0,
    lineHeight: '38px',
    color: 'var(--color-on-primary)',
    fontFamily: 'var(--font-family-regular)',
    fontSize: 'var(--font-size-regular)',
    fontWeight: 'var(--font-weight-bold)',
    height: '61px'
  },
  head: {
    borderBottom: '1px solid var(--color-outline)',
    borderTop: '1px solid var(--color-outline)'
  }
})(TableCell);
TableHeaderCell.displayName = 'TableHeaderCell';

const StyledTableCell = withStyles({
  body: {
    borderBottom: '1px solid var(--color-outline)',
    color: 'var(--color-on-primary)',
    fontFamily: 'var( --font-family-regular)',
    fontSize: 'var(--font-size-regular)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--line-height)'
  }
})(TableCell);
StyledTableCell.displayName = 'TableCell';

const StyledSortableTableCell = withStyles({
  icon: {
    opacity: 1
  }
})(TableSortLabel);
StyledSortableTableCell.displayName = 'SortableCellHeader';

const TableHeadRow = withStyles({
  root: {}
})(TableRow);
TableHeadRow.displayName = 'TableHeadRow';

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: 'white'
  }
}))(TableRow);
StyledTableRow.displayName = 'TableRow';

const ArrowElemment = styled('div')({
  width: 0,
  height: 0,
  marginLeft: '5px',
  borderLeft: '5px solid transparent',
  borderRight: '5px solid transparent',
  borderTop: '5px solid var(--color-outline)'
});
const Arrow = ({ className }: { className?: string }) => <ArrowElemment className={className} />;

const Container = withStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    boxShadow: 'none'
  }
}))(Paper);
Container.displayName = 'Container';

const StyledTable = withStyles({
  root: {
    minWidth: 700,
    '& .MuiTableCell-root:first-child': {
      paddingLeft: 60
    }
  }
})(MaterialTable);
StyledTable.displayName = 'StyledTable';

const defaultGetKey = (item: unknown, index: number) => index;

export function BaseTable<T>(props: BaseTableProps<T>) {
  const { items, columns, getKey = defaultGetKey } = props;
  const getDirection = (column: Column<T>) => {
    if (!column.sortedDirection) {
      return undefined;
    } else if (column.sortedDirection === SortingDirection.ASCENDING) {
      return 'asc';
    } else {
      return 'desc';
    }
  };
  return (
    <Container className="report-table">
      <StyledTable>
        <TableHead>
          <TableHeadRow>
            {columns.map((column, index) => {
              if (column.onSort) {
                return (
                  <TableHeaderCell key={index}>
                    <StyledSortableTableCell
                      active={column.sortedDirection ? true : false}
                      IconComponent={Arrow}
                      direction={getDirection(column)}
                      onClick={column.onSort}
                    >
                      {column.title}
                    </StyledSortableTableCell>
                  </TableHeaderCell>
                );
              } else {
                return <TableHeaderCell key={index}>{column.title}</TableHeaderCell>;
              }
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {items.map((item, itemIndex) => {
            return (
              <StyledTableRow key={getKey(item, itemIndex)}>
                {columns.map((column, columnIndex) => (
                  <StyledTableCell key={columnIndex} className={column.className}>
                    {column.selector(item)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </Container>
  );
}
