import React from 'react';
import PropTypes from 'prop-types';
import { CardCell } from './CardCell';
import { CreditCard } from '../../../api/types/creditCard';
import { PaymentsTableProps } from './PaymentsTable';
import { Column } from './PaymentsTable';

export const cardStatus = {
  FAILED: 'failed',
  PENDING: 'pending',
  CHARGEABLE: 'chargeable',
  DISPUTE: 'dispute'
};

export interface CardRowProps extends PaymentsTableProps {
  card: CreditCard;
  columns: Column[];
  isAccountLevelCreditCardsEnabled: boolean;
}

export function CardRow(props: CardRowProps) {
  const { card, columns, customer, isFeatureEnabled, isSingleCard, isAccountLevelCreditCardsEnabled } = props;
  const showCardFailureContent = (card: CreditCard) => {
    return (
      isFeatureEnabled('CREDIT_CARD_RETRY') &&
      ((!isSingleCard && card.card_id == customer.default_card_id) ||
        isSingleCard ||
        (isAccountLevelCreditCardsEnabled && !!card.account_ids?.length)) &&
      card.status === cardStatus.FAILED
    );
  };

  const showCardFailureMessage = () => {
    return showCardFailureContent(card) && card.latest_decline_reason;
  };

  return (
    <>
      <tr>
        {columns.map((column, index) => {
          return !column.checkFeature || !!column.checkFeature() ? (
            <CardCell
              {...props}
              column={column}
              card={card}
              key={index}
              showCardFailureContent={showCardFailureContent}
              isFeatureEnabled={isFeatureEnabled}
            />
          ) : null;
        })}
      </tr>
      {showCardFailureMessage() && (
        <tr className="decline-reason">
          <td colSpan={columns.length}>
            <span className="reverse-return">&#x23CE;</span>
            {card.latest_decline_reason}
          </td>
        </tr>
      )}
    </>
  );
}

CardRow.propTypes = {
  card: PropTypes.object,
  columns: PropTypes.array,
  costumer: PropTypes.object,
  isFeatureEnabled: PropTypes.func,
  isSingleCard: PropTypes.bool,
  tenantPaymentType: PropTypes.number,
  isAccountLevelCreditCardsEnabled: PropTypes.bool
};
