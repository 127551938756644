import * as React from 'react';
import { CircularIndicator } from '../LoadingIndicator';
import { InputContainer } from './index';

export interface AutoCompleteInputFieldProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  toggleDropdown: (value: boolean) => Promise<void>;
  showIndicator: boolean;
}

export function AutoCompleteInputField(props: AutoCompleteInputFieldProps) {
  const { searchText, setSearchText, placeholder, toggleDropdown, showIndicator } = props;
  return (
    <div className="autocomplete-input-field">
      <InputContainer
        aria-label="auto-complete-input"
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        placeholder={placeholder}
        onClick={() => toggleDropdown(true)}
      />
      {showIndicator && <CircularIndicator variant="static" size={30} color="secondary" />}
    </div>
  );
}
