import * as React from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { gtBlue, primary2Palette } from '../styles/colorConstants';

interface LinkProps {
  href: string;
  children: React.ReactNode;
}

const StyledMuiLink = styled(MuiLink)({
  color: 'var(--color-link)',
  fontFamily: 'var(--font-family-regular)',
  fontSize: 'var(--font-size-regular)',
  fontWeight: 'var(--font-weight-regular)',
  lineHeight: 'var(--line-height)',
  '&:hover': {
    color: 'var(--links-hover)'
  },
  '&:focus': {
    color: 'var(--links-hover)'
  }
});

export function Link(props: LinkProps) {
  return <StyledMuiLink href={props.href}>{props.children}</StyledMuiLink>;
}
