import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const RemoveIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
      fill="#10695B"
    />
    ,
  </svg>,
  'GtRemove'
);

export default RemoveIcon;
