export function identifyAppcues() {
  const appCueObj = {
    first_name: window.user.firstName,
    email: window.user.username,
    created_date: window.user.created_date,
    tenant_id: window.user.tenant_id
  };

  if (document.readyState !== 'loading' && window.Appcues) {
    // executing identify as document is already ready
    // @ts-ignore
    window.Appcues.identify(window.user.id, appCueObj);
  } else {
    document.addEventListener('DOMContentLoaded', function(event) {
      if (window.Appcues) {
        // @ts-ignore
        window.Appcues.identify(window.user.id, appCueObj);
      }
    });
  }
}
