import template from './cta_buttons_template.html';

export function ctaButtons(): ng.IDirective {
  return {
    restrict: 'E',
    template: template,
    transclude: true,
    controller: CtaButtonsController,
    controllerAs: 'vm',
    bindToController: true,
    link: () => {},
    scope: {
      id: '@',
      backBtnId: '@',
      nextBtnId: '@',
      backBtnLabel: '@',
      nextBtnLabel: '@',
      backFn: '&',
      nextFn: '&',
      disableBackBtn: '=',
      disableNextBtn: '=',
      showLoader: '=',
      showError: '=',
      errors: '=',
      showNextButton: '='
    }
  };
}

export class CtaButtonsController implements ng.IOnInit {
  /**@ngInject */
  constructor() {}

  $onInit() {}
}
