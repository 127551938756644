import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const AlertIcon = createSvgIcon(
  <path
    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
    fill="#323232"
  />,
  'GtAlertIcon'
);

export default AlertIcon;
