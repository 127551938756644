import * as React from 'react';
import { ConfirmationModal } from './ConfirmationModal';
import { isPromise } from '../../../factories/general_utils';

export interface UseConfirmationModalOptions {
  confirmationText?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  title?: string;
}

interface ConfirmOptions {
  confirmationText?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  title?: string;
  onConfirm?: () => Promise<unknown> | void;
  onClose?: () => Promise<unknown> | void;
}

type ConfirmDistpath = (options?: ConfirmOptions) => Promise<boolean>;

function defaultConfirmOrCancel(): Promise<void> | void {}

let lastConfirmationModalKey = 0;
export function useConfirmationModal(options: UseConfirmationModalOptions = {}): [React.ReactNode, ConfirmDistpath] {
  const defaultProps = {
    confirmationText: options.confirmationText ?? 'Are you sure?',
    confirmButtonLabel: options.cancelButtonLabel,
    cancelButtonLabel: options.cancelButtonLabel,
    title: options.title ?? 'Confirm',
    onConfirm: defaultConfirmOrCancel,
    onClose: defaultConfirmOrCancel
  };
  const [modals, setModals] = React.useState<React.ReactNode[]>([]);

  const confirmDispatch: ConfirmDistpath = (confirmOptions = {}) =>
    new Promise(resolve => {
      const modal = (
        <InnerConfirmationModal
          key={++lastConfirmationModalKey}
          {...defaultProps}
          {...confirmOptions}
          resolve={result => {
            setModals(modals => modals.filter(m => m !== modal));
            resolve(result);
          }}
        />
      );
      setModals(modals => [...modals, modal]);
    });

  const element = modals;

  return [element, confirmDispatch];
}

function InnerConfirmationModal(props: {
  confirmationText: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  title: string;
  onConfirm?: () => Promise<unknown> | void;
  onClose?: () => Promise<unknown> | void;
  resolve(result: boolean): void;
}) {
  const [loading, setLoading] = React.useState(false);
  const { resolve, onConfirm, onClose, ...modalProps } = props;

  async function onResolve(result: boolean, callback?: () => Promise<unknown> | void) {
    if (callback) {
      const callbackResult = callback();
      if (isPromise(callbackResult)) {
        setLoading(true);
        try {
          await callbackResult;
        } finally {
          setLoading(false);
        }
      }
    }
    resolve(result);
  }

  return (
    <ConfirmationModal
      {...modalProps}
      open
      loading={loading}
      onConfirm={() => onResolve(true, onConfirm)}
      onClose={() => onResolve(false, onClose)}
    />
  );
}
