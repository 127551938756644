import * as React from 'react';
import { useObserver, Observer } from 'mobx-react-lite';
import { PrimaryButton } from '../../../components/reactComponents';
import { LoadingModal } from '../../../components/reactComponents/Modal';
import { ReportSchedulerEditFormModel } from '../model/report_scheduler_edit_form_model';
import { useSafeAsyncAction } from '../../../utils/react/use_async_action';
import { styled } from '../../../utils/react/styled';
import { useLocalModel } from '../../../utils/react/use_local_model';
import { ReportFormModel } from '../model/report_form_model';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AlertIcon, NotificationIcon } from '../../../components/reactComponents/Icons';
import Alert from '@material-ui/lab/Alert';

import {
  TextField,
  DateField,
  MultiselectField,
  SelectField,
  EmailListTextField
} from '../../../components/forms/components';
import { ReportDeliveryScheduleFrequency } from '../../../api/http/reports';
import { TagsTextField as InnerTagsTextField } from '../../../components/reactComponents/Text/TagsTextField';
import { useDebouncedCallback } from 'use-debounce';

const ReportEditContentContainer = styled('div')({
  padding: '40px 0px',
  width: 800
});

const ActionButtonContainer = styled('div')({
  display: 'flex',
  paddingTop: 30,
  paddingBottom: 50,
  width: '100%',
  borderTop: '1px solid var(--color-outline)'
});

const DatesContainer = styled('div')({
  display: 'flex',
  '&>:first-child': {
    marginRight: 24
  }
});

const StyledEmailListTextField = styled(EmailListTextField)({
  [`& ${InnerTagsTextField.selectors.inputRoot}`]: {
    minHeight: 120
  }
});
const StyledSelectField = withStyles({
  root: {
    paddingTop: '16px'
  }
})(SelectField);

const StyledScheduleNote = styled('div')({
  paddingBottom: 5,
  color: 'var(--color-on-primary)',
  fontFamily: 'var(--font-family-regular)',
  fontSize: 'var(--font-size-regular)',
  fontWeight: 'var(--font-weight-regular)',
  lineHeight: 'var(--line-height)',
  display: 'flex',
  gap: '12px',
  marginTop: '40px',
  ['&>svg']: {
    marginBottom: -11,
    marginLeft: 4
  }
});
const useStyles = makeStyles({
  input: {
    '& .Mui-error': {
      '& input': {
        border: '1px solid var(--color-error)',
        background: 'var(--color-error-container)'
      }
    },

    '& .MuiFormHelperText-root.Mui-error': {
      color: 'var(--color-error)',
      fontFamily: 'var(--font-family-regular)',
      fontSize: 'var(--font-size-small)',
      fontWeight: 'var(--font-weight-regular)',
      lineHeight: 'var(--line-height)',
      marginTop: '4px'
    }
  }
});
const StyledMaterialTextField = withStyles({
  root: {}
})(TextField);

const deliveryFrequencyNames = {
  [ReportDeliveryScheduleFrequency.DAILY]: 'Daily',
  [ReportDeliveryScheduleFrequency.WEEKLY]: 'Weekly'
};

function getDeliveryFrequencyName(option: ReportDeliveryScheduleFrequency) {
  return deliveryFrequencyNames[option];
}

const DEBOUNCE_TIMEOUT = 1000;

export function ReportForm(props: { model: ReportSchedulerEditFormModel }) {
  const { model } = props;
  const form = useLocalModel(() => new ReportFormModel(model.report));
  const [saving, waitAsync, hasError, error] = useSafeAsyncAction();

  const onSave = async () => {
    if (!form.validate()) return;
    form.saveTo(model.report);
    setIsAlertVisible(true);
    const result = await waitAsync(model.saveReport());
    if (!result.success) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const debouncedSearch = useDebouncedCallback((query: string) => form.setCampaignSearchQuery(query), DEBOUNCE_TIMEOUT);
  const searchCampaignOnInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const query: string = e.target.value?.trim();
    debouncedSearch(query);
  };
  const resetCampaignSearchQuery = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    form.setCampaignSearchQuery('');
  const classes = useStyles();
  const [isAlertVisible, setIsAlertVisible] = React.useState(true);
  return (
    <div className="report-form-container">
      {hasError && isAlertVisible && (
        <Alert
          severity="warning"
          icon={<NotificationIcon className="warning-icon" />}
          onClose={() => {
            setIsAlertVisible(false);
          }}
        >
          <ul>
            {error?.details.map(item => {
              return <li>{item}</li>;
            })}
          </ul>
        </Alert>
      )}

      <div className="report-form">
        <ReportEditContentContainer>
          <StyledMaterialTextField
            label="Report Name"
            field={form.name}
            fullWidth
            isOutlined={false}
            className={`${classes.input} form-input-field-v2`}
          />

          <Observer>
            {() => (
              <>
                <SelectField
                  key={form.getReportTypeNameVersion} // this is a hack to make React recreate component instance each time
                  label="Report Type"
                  field={form.type}
                  fullWidth
                  getItemTitle={form.getReportTypeName}
                />

                <DatesContainer style={{ marginBottom: form.startDate.validationMessage ? '32px' : undefined }}>
                  <DateField popupId="report-form-start-date" label="Start Date" field={form.startDate} fullWidth />
                  <DateField popupId="report-form-end-date" label="End Date" field={form.endDate} fullWidth />
                </DatesContainer>
              </>
            )}
          </Observer>
          <MultiselectField field={form.organizations} fullWidth label="Organization(s)" getItemTitle={o => o.name} />
          <Observer>
            {() => (
              <>
                <MultiselectField
                  field={form.accounts}
                  disabled={form.organizations.value.length <= 0}
                  fullWidth
                  label="Account(s)"
                  getItemTitle={o => o.name}
                />
                <MultiselectField
                  field={form.campaigns}
                  disabled={form.accounts.value.length <= 0 || form.organizations.value.length <= 0}
                  fullWidth
                  label="Campaign(s)"
                  getItemTitle={o => o.name}
                  onInputChange={searchCampaignOnInputChange}
                  onInputBlur={resetCampaignSearchQuery}
                />
              </>
            )}
          </Observer>

          <StyledEmailListTextField field={form.recipients} fullWidth label="Email Recipient(s)" />
          <div className="field multiselect-checkbox schedule-delivery">
            <input
              type="checkbox"
              checked={form.enableDelivery.value}
              onChange={e => form.enableDelivery.update(e.target.checked)}
              name="schedule-delivery"
              id="schedule-delivery"
            />
            <label htmlFor="schedule-delivery">Schedule delivery</label>
          </div>

          {useObserver(() =>
            form.enableDelivery.value ? (
              <StyledSelectField
                field={form.frequency}
                label="Delivery Frequency"
                fullWidth
                getItemTitle={getDeliveryFrequencyName}
                className={form.frequency.value ? 'delivery-frequency' : ''}
              />
            ) : null
          )}
          <Observer>
            {() => (
              <div className="field multiselect-checkbox generate-report">
                <input
                  type="checkbox"
                  checked={form.sendNow.value}
                  onChange={e => form.sendNow.update(e.target.checked)}
                  name="send-now"
                  id="send-now"
                />
                <label htmlFor="send-now">Generate report now</label>
              </div>
            )}
          </Observer>
          <StyledScheduleNote>
            <AlertIcon />
            All weekly reports are sent on the first Monday after the report was created.
          </StyledScheduleNote>

          <LoadingModal loading={saving} loadingMessage="Please wait" />
        </ReportEditContentContainer>
        <ActionButtonContainer className="report-form-buttons">
          <PrimaryButton title="Save" onClick={onSave} className="button-v2 button--green " />
          <PrimaryButton
            outlined
            title="Cancel"
            onClick={model.cancelReport}
            className="button-v2 button--tertiary-green"
          />
        </ActionButtonContainer>
      </div>
    </div>
  );
}
