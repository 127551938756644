import * as t from 'io-ts';
import { enumeration, Id } from '../def/io-ts';
import { AccountType, DistanceUnits } from './common';

// API: /data/companiesAndAccounts

const Brand = t.type(
  {
    id: t.number,
    name: t.union([t.string, t.null])
  },
  'Brand'
);
export type Brand = t.TypeOf<typeof Brand>;

export enum AccountStatusName {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  PENDING = 'PENDING'
}

const AccountTypeEnum = enumeration(AccountType, 'AccountType');
const ZeroOrOne = t.union([t.literal(0), t.literal(1)]);
const DistanceUnitsEnum = enumeration(DistanceUnits, 'DistanceUnits');
const AccountStatusNameEnum = enumeration(AccountStatusName, 'AccountStatusName');

const Account = t.type(
  {
    id: t.number,
    accountType: AccountTypeEnum,
    accountTypeName: t.string,
    adomain: t.union([t.string, t.null]),
    app_used: t.string,
    brand: t.union([Brand, t.null]),
    countryCode: t.string,
    currency: t.string,
    dart_account_id: t.union([t.unknown, t.null]), // todo: determine type
    distanceUnit: DistanceUnitsEnum,
    language: t.string, // todo: enum
    name: t.string,
    status: AccountStatusNameEnum, // todo: enum
    timezone: t.string,
    vertical_id: t.union([t.number, t.null]) // todo: determine type
  },
  'Account'
);
export type Account = t.TypeOf<typeof Account>;

const Company = t.type(
  {
    id: t.number,
    tenant_id: t.number,
    name: t.string,
    accounts: t.array(Account)
  },
  'Company'
);
export type Company = t.TypeOf<typeof Company>;

/**
 * Describes response of API `GET /data/companiesAndAccounts`.
 */
export const Response = t.type(
  {
    checksum: t.string,
    companies: t.array(Company),
    is_holding_company: ZeroOrOne,
    selected_account_id: t.number,
    selected_company_id: t.number,
    tenant_id: t.number
  },
  'Response'
);
export type Response = t.TypeOf<typeof Response>;
