import { campaignManagerFactory } from './campaign_manager.fac';
import { chartUtilsFactory } from './chart_utils.fac';
import { companyAndAccountFactory } from './company_and_account.fac';
import { csvXlsxUtilsFactory } from './csv_xlsx_utils.fac';
import { dataExportFactory } from './data_export.fac';
import { debounce2 } from './debounce2';
import { featureFlagFactory } from './feature_flag.fac';
import { generalUtilsFactory } from './general_utils.fac';
import { highChartsFactory } from './highcharts.fac';
import { jobFactory } from './job.fac';
import { LocationReportController } from '../pages/campaign/campaign_dashboard/location_report.ctrl';
import { mapsUtilsFactory } from './maps_utils.fac';
import { notificationFactory } from './notification.fac';
import { reportFactory } from './report.fac';
import { searchFactory } from './search.fac';
import { selfServeDataFactory } from './selfserve_data.fac';
import { staticDataFactory } from './static_data.fac';
import { tenantFactory } from './tenant.fac';
import { userFactory } from './user.fac';
import { xadcmsFactory } from './xadcms.fac';
import { universalAlert } from './universal_alert';

export const services = {
  universalAlert
};

export const factories = {
  campaignManagerFactory,
  chartUtilsFactory,
  companyAndAccountFactory,
  csvXlsxUtilsFactory,
  dataExportFactory,
  debounce2,
  featureFlagFactory,
  generalUtilsFactory,
  highChartsFactory,
  jobFactory,
  mapsUtilsFactory,
  notificationFactory,
  reportFactory,
  searchFactory,
  selfServeDataFactory,
  staticDataFactory,
  tenantFactory,
  userFactory,
  xadcmsFactory
};

export const controllers = {
  LocationReportController
};
