export const CONDITION_OPTIONS = {
  temperature: {
    text: 'Temperature',
    value: 'temperature'
  },
  wind: {
    text: 'Wind',
    value: 'wind'
  },
  rain: {
    text: 'Rain',
    value: 'rain'
  },
  humidity: {
    text: 'Humidity',
    value: 'humidity'
  }
};

export const TIMEFRAME_OPTIONS = {
  current: {
    text: 'Current Condition',
    value: 'ob'
  },
  today: {
    text: "Today's Forecast",
    value: 'd0'
  },
  tomorrow: {
    text: "Tomorrow's Forecast",
    value: 'd1'
  },
  threeDays: {
    text: '3 Day Forecast',
    value: 'd2'
  }
};

export const WEATHER_ALERT_OPTIONS = [
  {
    text: 'Air Stagnation',
    value: 'AS',
    selected: false
  },
  {
    text: 'Areal Flood',
    value: 'FA',
    selected: false
  },
  {
    text: 'Ashfall',
    value: 'AF',
    selected: false
  },
  {
    text: 'Beach Hazard',
    value: 'BH',
    selected: false
  },
  {
    text: 'Blizzard',
    value: 'BZ',
    selected: false
  },
  {
    text: 'Blowing Dust',
    value: 'DU',
    selected: false
  },
  {
    text: 'Blowing Snow',
    value: 'BS',
    selected: false
  },
  {
    text: 'Brisk Wind',
    value: 'BW',
    selected: false
  },
  {
    text: 'Coastal Flood',
    value: 'CF',
    selected: false
  },
  {
    text: 'Dense Fog',
    value: 'FG',
    selected: false
  },
  {
    text: 'Dense Smoke',
    value: 'SM',
    selected: false
  },
  {
    text: 'Dust Storm',
    value: 'DS',
    selected: false
  },
  {
    text: 'Excessive Heat',
    value: 'EH',
    selected: false
  },
  {
    text: 'Extreme Cold',
    value: 'EC',
    selected: false
  },
  {
    text: 'Extreme Wind',
    value: 'EW',
    selected: false
  },
  {
    text: 'Fire Weather',
    value: 'FW',
    selected: false
  },
  {
    text: 'Flash Flood ',
    value: 'FF',
    selected: false
  },
  {
    text: 'Flood',
    value: 'FL',
    selected: false
  },
  {
    text: 'Freeze',
    value: 'FZ',
    selected: false
  },
  {
    text: 'Freezing Fog',
    value: 'ZF',
    selected: false
  },
  {
    text: 'Freezing Rain',
    value: 'ZR',
    selected: false
  },
  {
    text: 'Frost',
    value: 'FR',
    selected: false
  },
  {
    text: 'Hard Freeze',
    value: 'HZ',
    selected: false
  },
  {
    text: 'Heat',
    value: 'HT',
    selected: false
  },
  {
    text: 'Heavy Snow',
    value: 'HS',
    selected: false
  },
  {
    text: 'High Surf ',
    value: 'SU',
    selected: false
  },
  {
    text: 'High Wind',
    value: 'HW',
    selected: false
  },
  {
    text: 'Hurricane',
    value: 'HU',
    selected: false
  },
  {
    text: 'Hurricane Force Wind',
    value: 'HF',
    selected: false
  },
  {
    text: 'Hydrologic',
    value: 'HY',
    selected: false
  },
  {
    text: 'Ice Accretion',
    value: 'UP',
    selected: false
  },
  {
    text: 'Ice Storm',
    value: 'IS',
    selected: false
  },
  {
    text: 'Inland Hurricane',
    value: 'HI',
    selected: false
  },
  {
    text: 'Inland Tropical Storm',
    value: 'TI',
    selected: false
  },
  {
    text: 'Lake Effect Snow',
    value: 'LE',
    selected: false
  },
  {
    text: 'Lake Effect Snow and Blowing Snow',
    value: 'LB',
    selected: false
  },
  {
    text: 'Lake Wind',
    value: 'LW',
    selected: false
  },
  {
    text: 'Lakeshore Flood',
    value: 'LS',
    selected: false
  },
  {
    text: 'Low Water',
    value: 'LO',
    selected: false
  },
  {
    text: 'Rip Current',
    value: 'RP',
    selected: false
  },
  {
    text: 'Severe Thunderstorm',
    value: 'SV',
    selected: false
  },
  {
    text: 'Sleet',
    value: 'IP',
    selected: false
  },
  {
    text: 'Snow',
    value: 'SN',
    selected: false
  },
  {
    text: 'Snow and Blowing Snow',
    value: 'SB',
    selected: false
  },
  {
    text: 'Tornado',
    value: 'TO',
    selected: false
  },
  {
    text: 'Tropical Storm',
    value: 'TR',
    selected: false
  },
  {
    text: 'Tsunami',
    value: 'TS',
    selected: false
  },
  {
    text: 'Typhoon',
    value: 'TY',
    selected: false
  },
  {
    text: 'Wind',
    value: 'WI',
    selected: false
  },
  {
    text: 'Wind Chill',
    value: 'WC',
    selected: false
  },
  {
    text: 'Winter Storm',
    value: 'WS',
    selected: false
  },
  {
    text: 'Winter Weather',
    value: 'WW',
    selected: false
  }
];

export const WEATHER_CONDITION_OPTIONS = [
  {
    text: 'Clear',
    value: 0,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Cloudy',
    value: 1,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Sunny',
    value: 2,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Hazy, Hot, Humid',
    value: 3,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Cold',
    value: 4,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Windy',
    value: 5,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Fog',
    value: 6,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Rain/Thunderstorms/Drizzle',
    value: 7,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Rain',
    value: 8,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Thunderstorms/Storms',
    value: 9,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Drizzle',
    value: 10,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Snowy and Winter weather',
    value: 11,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Flurries',
    value: 12,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Snow',
    value: 13,
    selected: false,
    forecast: 'ob'
  },
  {
    text: 'Frozen Mix/Sleet',
    value: 14,
    selected: false,
    forecast: 'ob'
  }
];
