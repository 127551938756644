import * as React from 'react';
import { useObserver } from 'mobx-react-lite';
import { MultiselectEditableFormField } from '../index';
import { MultiselectDropdown } from '../../reactComponents/Dropdowns/MultiselectDropdown';

interface MultiselectFieldProps<O> {
  field: MultiselectEditableFormField<O>;
  label: string;
  getItemTitle: (option: O) => string;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onInputBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export function MultiselectField<O>(props: MultiselectFieldProps<O>) {
  return useObserver(() => (
    <MultiselectDropdown
      className={props.className}
      label={props.label}
      value={props.field.value}
      onChange={props.field.update}
      getOptionText={props.getItemTitle}
      options={props.field.options}
      errorMessage={(!props.field.isValid && props.field.validationMessage) || undefined}
      compareOption={props.field.compare}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      onInputChange={props.onInputChange}
      onInputBlur={props.onInputBlur}
    />
  ));
}
