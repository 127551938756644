/**
 * @param {ng.IFilterService} $filter
 * @ngInject
 */
export function creativeFullSize($filter, creativeTemplatesFactory) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.creative_full_size.html',
    scope: {
      creativeObject: '=',
      macros: '=',
      imageProp: '@',
      creativeType: '@',
      creativeSize: '='
    },
    link: function(scope, el, attr) {
      scope.isShowingFullSize = false;

      if (scope.creativeType !== 'HTML5_NEW') {
        const overlayText = $filter('macroToString')(scope.creativeObject.customText, scope.macros);
        const overlayCss = scope.creativeObject.cssCustomText;
        const imageUrl = scope.creativeObject[scope.imageProp];
        var previewTemplate = `
                      <div class="full-size-preview">
                        <span class="glyphicon glyphicon-remove-circle"></span>
                        <div class="full-size-centerer">
                            <div class="full-size-container">
                                <div class="image-container">
                                  <span readonly class="custom-overlay" style="${overlayCss}">${overlayText}</span>
                                  <img src="${imageUrl}"/>
                                </div>
                            </div>
                        </div>
                      </div>`;
      } else {
        const cmsConfig = scope.creativeObject;
        let html5Preview = creativeTemplatesFactory.getHTMLTagForHTML5CreativePreview(cmsConfig);
        html5Preview = 'data:text/html,' + encodeURIComponent(html5Preview);
        const creativeWidth = scope.creativeSize.split('x')[0];
        const creativeHeight = scope.creativeSize.split('x')[1];

        var previewTemplate = `
                      <div class="full-size-preview">
                        <span class="glyphicon glyphicon-remove-circle"></span>
                        <div class="full-size-centerer">
                          <div class="full-size-container">
                            <iframe class="html5-iframe" src="${html5Preview}" width="${creativeWidth}" height="${creativeHeight}" href="#" frameborder="0"></iframe>
                            <div class="iframe-overlay" style="top:0;left:O;width:100%;height:100%;position:absolute"></div>
                          </div>
                        </div>
                      </div>`;
      }

      var fullSizeClick = function() {
        $('.full-size-preview, .top-bar').unbind('click', fullSizeClick);
        $('.full-size-preview').fadeOut(100, function() {
          $(this).remove();
        });
        scope.isShowingFullSize = false;
      };

      scope.$watch('isShowingFullSize', function(newVal) {
        if (scope.isShowingFullSize) {
          $('body').append(previewTemplate);
          $('.full-size-preview')
            .hide()
            .fadeIn(100);
          $('.full-size-preview, .top-bar').bind('click', fullSizeClick);
          scope.isShowingFullSize = false;
        }
      });
    }
  };
}
