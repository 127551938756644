import React, { ChangeEvent } from 'react';

interface AudienceSearchBarProps {
  searchTerm: string;
  onSearch: (e: ChangeEvent) => void;
  onAudienceLibrarySwitch: () => void;
}

export function AudienceSearchBar({ searchTerm, onSearch, onAudienceLibrarySwitch }: AudienceSearchBarProps) {
  return (
    <div className="audience-search__container">
      <input
        className="audience-search__input"
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={onSearch}
      />
      <button className="audience-search__browse" onClick={onAudienceLibrarySwitch}>
        Browse Audience Library
      </button>
    </div>
  );
}
