import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

interface SearchBarV2Props {
  searchOnEnter?: boolean;
  classname?: string;
  onSearch: (value: string) => void;
  searchValue: string;
  onClear?: () => void;
  placeholder?: string;
  inputId?: string;
}

export function SearchBarV2(props: SearchBarV2Props) {
  const {
    classname = '',
    searchOnEnter,
    onSearch,
    searchValue = '',
    onClear,
    placeholder = 'Search',
    inputId = ''
  } = props;
  const [value, setValue] = useState(searchValue);
  const [debouncedSearchTerm] = useDebounce(value, 500);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (!searchOnEnter) {
      onSearch(value);
    }
  }, [debouncedSearchTerm]);

  const handleKeyDown = event => {
    if (event.key === 'Enter' && searchOnEnter) {
      onSearch(value);
    }
  };

  const handleOnClear = () => {
    if (onClear) {
      onClear();
    }
    setValue('');
  };

  return (
    <div className={`search-bar-v2 ${value ? 'has-content' : ''} ${classname}`}>
      <input
        autoComplete="off"
        id={inputId}
        type="text"
        className="search-input"
        placeholder={placeholder}
        onChange={e => setValue(e.target.value)}
        spellCheck={true}
        onKeyDown={handleKeyDown}
        value={value}
      />
      {value && <div onClick={handleOnClear} className="icon clear-icon"></div>}
      <div className="icon search-icon" onClick={() => onSearch(value)}></div>
    </div>
  );
}

SearchBarV2.propTypes = {
  classname: PropTypes.string,
  searchOnEnter: PropTypes.bool,
  onSearch: PropTypes.func,
  searchValue: PropTypes.string,
  onClear: PropTypes.func,
  inputId: PropTypes.string
};
