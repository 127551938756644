import { createAtom, IAtom } from 'mobx';
import { $rootScope } from '../../../../utils/services';

export class NgWatch<T> {
  private atom: IAtom;
  private dispose: (() => void) | undefined;
  private lastValue!: T;

  constructor(private watchExpression: () => T, private $scope: ng.IScope | undefined) {
    this.atom = createAtom(
      watchExpression.name || 'NgWatch',
      () => this.startWatching(),
      () => this.stopWatching()
    );
  }

  get value() {
    if (!this.atom.reportObserved()) {
      this.lastValue = this.watchExpression();
    }
    return this.lastValue;
  }

  private startWatching() {
    this.dispose = (this.$scope ?? $rootScope).$watch(this.watchExpression, newValue => this.observe(newValue));
  }

  private stopWatching() {
    if (this.dispose) {
      this.dispose();
      this.dispose = undefined;
    }
  }

  private observe(value: T) {
    this.lastValue = value;
    this.atom.reportChanged();
  }
}

export function ngWatch<T>(watchExpression: () => T, $scope?: ng.IScope): { readonly value: T } {
  return new NgWatch<T>(watchExpression, $scope);
}
