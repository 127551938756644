import * as React from 'react';
import { ListType } from './index';

export interface TabPanelProps {
  title: string;
  selectItems: (selected: ListType[]) => void;
  deselectItems: (selected: ListType[]) => void;
  currTabValue: string;
  data: ListType[];
  selectedTabItems: ListType[];
  saveItems: () => void;
  buttonLabel: string;
}

export interface CheckBoxItemListProps {
  data: ListType[];
  selected: ListType[];
  handleChange: (item: any) => void;
}

export function CheckBoxItemList(props: CheckBoxItemListProps) {
  const { data, selected, handleChange } = props;
  return (
    <ul className="checkbox-item-list multiselect-checkbox">
      {data.map((item, index) => (
        <li key={index}>
          <label htmlFor={`${item.name}`}>
            <input
              type="checkbox"
              checked={selected.map(item => `${item.type}-${item.name}`).includes(`${item.type}-${item.name}`)}
              onChange={() => handleChange(item)}
              id={`${item.name}`}
            />
            <div>{item.name}</div>
          </label>
        </li>
      ))}
    </ul>
  );
}

export function TabPanel(props: TabPanelProps) {
  const { title, selectItems, deselectItems, currTabValue, data, selectedTabItems, saveItems, buttonLabel } = props;
  const [tabTitle, setTabTitle] = React.useState('');
  const [selectAll, setSelectAll] = React.useState(false);

  const handleChange = (item: any) => {
    if (selectedTabItems.map(item => `${item.type}-${item.name}`).includes(`${item.type}-${item.name}`)) {
      deselectItems([item]);
    } else {
      selectItems([item]);
    }
  };

  React.useEffect(() => {
    let length = selectedTabItems.length;
    let titleText = `Add ${length ? ` (${length}) ` : ''}${buttonLabel}${length > 1 ? 's' : ''}`;
    setTabTitle(titleText);
  }, [selectedTabItems]);

  const handleSelectAll = (value: boolean) => {
    setSelectAll(value);
    if (value) {
      selectItems(data);
    } else {
      deselectItems(data);
    }
  };

  return (
    <div role="tabpanel" hidden={currTabValue !== title} id={`scrollable-auto-tabpanel-${title}`}>
      {currTabValue === title && (
        <div className="tab-panel">
          <CheckBoxItemList data={data} selected={selectedTabItems} handleChange={handleChange} />
          <div className="tab-panel__footer">
            <label className="tab-panel__select-all multiselect-checkbox" htmlFor="select-all-autocomplete">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={() => handleSelectAll(!selectAll)}
                id="select-all-autocomplete"
              />
              Select All ({data.length})
            </label>
            <button className="button-v2 button--green" disabled={!selectedTabItems.length} onClick={saveItems}>
              {tabTitle}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
