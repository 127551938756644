modalSimpleConfirmController.$inject = ['$scope', '$uibModalInstance', 'data', 'handlers'];

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 */
export function modalSimpleConfirmController($scope, $uibModalInstance, data, handlers) {
  var vm = this;

  vm.data = data;
  vm.scope = $scope;
  vm.handlers = handlers;

  vm.inProgress = false;
  vm.error = null;
  vm.yesClass = vm.data.yesClass || 'button--green';
  vm.noClass = vm.data.noClass || 'button-v2 button--tertiary-green';

  vm.confirm = confirm;
  vm.cancel = cancel;

  function confirm() {
    if (vm.handlers && angular.isFunction(vm.handlers.confirm)) {
      vm.inProgress = true;
      vm.error = null;
      vm.handlers.confirm().then(function(error) {
        if (error) {
          vm.inProgress = false;
          vm.error = error;
          vm.scope.$apply();
        } else {
          $uibModalInstance.close(true);
        }
      });
    } else {
      $uibModalInstance.close(true);
    }
  }

  function cancel() {
    if (vm.handlers && angular.isFunction(vm.handlers.cancel)) {
      vm.handlers.cancel();
    }
    $uibModalInstance.dismiss('cancel');
  }
}
