import React, { useState, useEffect } from 'react';
import './AvailableApps.scss';

interface Icon {
  key: string;
  iconUrl: string;
  url: string;
  text: string;
}

export const AvailableApps: React.FC = () => {
  const appIcons = {
    LIP: {
      image: '/ui/images/discovery.png',
      url: window.discoveryURL,
      text: 'Discovery'
    },
    RA: {
      image: '/ui/images/console.png',
      url: window.consoleURL,
      text: 'Console'
    },
    LM: {
      image: '/ui/images/location-manager.png',
      url: '//' + window.locationManagerURL,
      text: 'Location Manager'
    },
    CAB: {
      image: '/ui/images/audience-manager.png',
      url: '//' + window.audienceManagerURL,
      text: 'Audience Manager'
    }
  };

  const authApps = window.user.authorized_apps;
  const [iconsToRender, setIconsToRender] = useState<Icon[]>([]);

  const getApplicationURL = (icon: Icon) => {
    const currentPath = window.location.pathname;
    // Key === 'RA' is for console, changing path based on the current page
    if (icon.key === 'RA' && currentPath.startsWith('/campaign/')) {
      const campaignId = currentPath.replace('/campaign/', '').split('/')[0];
      return icon.url + `/campaign/${campaignId}?reroute=true`;
    }
    return icon.url;
  };

  useEffect(() => {
    const renderIcons = () => {
      const icons: Icon[] = [];
      authApps?.split(',').forEach(function(appKey) {
        if (appIcons[appKey]) {
          icons.push({
            key: appKey,
            iconUrl: appIcons[appKey].image,
            url: appIcons[appKey].url,
            text: appIcons[appKey].text
          });
        }
      });
      setIconsToRender(icons);
    };

    renderIcons();
  }, []);

  return (
    <div className="available-apps">
      {iconsToRender.map(icon => (
        <div key={icon.key} className="available-apps-container">
          <button onClick={() => window.open(getApplicationURL(icon), '_blank')}>
            <div className="app-logo-container">
              <div className="available-apps__img-container">
                <img src="/ui/images/groundtruth-logo-re-skin.svg" className="groundtruth-logo" />
              </div>
            </div>
            <div className="gt-img-container">
              <img src="/ui/images/groundtruth-text-re-skin.svg" />
            </div>
            <p className="app-name">{icon.text}</p>
          </button>
        </div>
      ))}
    </div>
  );
};
