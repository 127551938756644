export const getTimeXAxisConfigurationFn = function() {
  return function() {
    return {
      type: 'category',
      dateTimeLabelFormats: {
        day: {
          main: '%e %b'
        }
      },
      labels: {
        step: 2
      },
      lineColor: '#0A3F36',
      lineWidth: 1,
      tickColor: '#FFFFFF',
      gridLineWidth: 1,
      gridLineColor: '#BCF1E9',
      gridLineDashStyle: 'Solid',
      tickmarkPlacement: 'on',
      categories: [
        '12 AM',
        '1 AM',
        '2 AM',
        '3 AM',
        '4 AM',
        '5 AM',
        '6 AM',
        '7 AM',
        '8 AM',
        '9 AM',
        '10 AM',
        '11 AM',
        '12 PM',
        '1 PM',
        '2 PM',
        '3 PM',
        '4 PM',
        '5 PM',
        '6 PM',
        '7 PM',
        '8 PM',
        '9 PM',
        '10 PM',
        '11 PM'
      ]
    };
  };
};

export const getDateXAxisConfigurationFn = function() {
  return function(seriesOptions) {
    return {
      type: 'datetime',
      labels: {
        formatter: function() {
          return Highcharts.dateFormat('%b %e', this.value);
        }
      },
      dateTimeLabelFormats: {
        day: '%e %b'
      },
      tickPositioner: function() {
        var dataLength = seriesOptions[0].data.length;

        if (dataLength === 0) {
          return [];
        }

        if (dataLength === 1) {
          return [Math.floor(this.dataMin)];
        }

        dataLength--;

        var positions = [],
          tick = Math.floor(this.dataMin),
          increment = Math.ceil((this.dataMax - this.dataMin) / dataLength);

        var MAX_LABELS = 14;
        var k = Math.ceil(dataLength / MAX_LABELS);

        if (this.dataMax !== null && this.dataMin !== null) {
          for (tick; tick - k * increment <= this.dataMax; tick += k * increment) {
            positions.push(tick);
          }
        }

        return positions;
      }
    };
  };
};
