import styledComponents_styled, {
  createGlobalStyle as styledComponents_createGlobalStyle,
  keyframes as styledComponents_keyframes
} from 'styled-components';

import { ThemedStyledInterface, ThemedStyledComponentsModule } from './styled_components_simplified';

export interface Theme {
  color: string;
}

type StyledComponentsModule = ThemedStyledComponentsModule<Theme>;

export type StyledInterface = ThemedStyledInterface<Theme>;

export const styled: StyledInterface = styledComponents_styled;
export const createGlobalStyle: StyledComponentsModule['createGlobalStyle'] = styledComponents_createGlobalStyle;
export const keyframes: StyledComponentsModule['keyframes'] = styledComponents_keyframes;
