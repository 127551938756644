import React, { useState, useEffect, useRef } from 'react';
import { useDebounce } from 'use-debounce';
import PropTypes from 'prop-types';

import { AnyTaxonomyItem, isAudience, isAudienceCategory, isAudienceType } from './interfaces';
import { AudienceList } from './AudienceList';
import { AudienceRecommendationPopup } from './AudienceRecommendationPopup';
import { AudienceSearchBar } from './AudienceSearchBar';
import { AudienceSearchPanel } from './AudienceSearchPanel';
import { ClearSelectionConfirmationModal } from './ClearSelectionConfirmationModal';
import { send$http } from '../../../../api/def/ngExecutor';
import { Audience, AudienceCategory } from '../../../../api/http/data.generated';
import {
  AudienceTaxonomyResponseType,
  getTaxonomy,
  searchAudience,
  fetchAudienceRecommendations
} from '../../../../api/http/audience';
import { $http } from '../../../../utils/services';
import { AudienceType } from './enum';
import GAEventList from '../../../../components/googleAnalytics/eventList';

interface AudienceSearchSectionProps {
  includeItemDescription: boolean;
  isExcluded: boolean;
  selectedAudiences: Audience[];
  selectedAdgroupId: number;
  selectedCampaignId: number;
  updateSelections: (a: Audience[], cb: Function, IDSet: Set<string>) => void;
}

export function AudienceSearchSection({
  includeItemDescription = false,
  isExcluded = false,
  selectedAdgroupId,
  selectedAudiences: tempSelected = [],
  selectedCampaignId,
  updateSelections = () => {}
}: AudienceSearchSectionProps) {
  const searchPanelRef = useRef(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedAudiences, setSelectedAudiences] = useState<Audience[]>(tempSelected);
  const [selectedAudienceIds, setSelectedAudienceIds] = useState<Set<string>>(
    new Set(tempSelected.map(item => item.id) as string[])
  );
  const [showError, setShowError] = useState(false);
  const [shouldShowPanel, setShouldShowPanel] = useState(false);
  const [searchResults, setSearchResults] = useState<{ audience: Audience; category: AudienceCategory }[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLibrary, setShowLibrary] = useState(false);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [audienceTaxonomyTreeData, setAudienceTaxonomyTreeData] = useState<AudienceTaxonomyResponseType['taxonomy']>(
    []
  );
  const [showRecommendationPopup, setShowRecommendationPopup] = useState(false);
  const [recommendations, setRecommendations] = useState<Audience[]>([]);
  const [debouncedSelectedAudienceIds] = useDebounce(selectedAudienceIds, 1000);
  const [isLoadingRecommendations, setIsLoadingRecommendations] = useState(false);
  const [popUpClosedWithRecommendations, setPopUpClosedWithRecommendations] = useState(false);

  const getTaxonomyAudience = async () => {
    try {
      const result = await send$http($http, getTaxonomy);
      setAudienceTaxonomyTreeData(result?.taxonomy);
    } catch (e) {
      // handle error
      console.error(e);
      setAudienceTaxonomyTreeData([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm.length > 0 || showLibrary) {
      setShouldShowPanel(true);
      handleSearch(searchTerm);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!isLoadingData && searchTerm.length > 0) {
      setShouldShowPanel(false);
      setSearchResults([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    updateSelections(selectedAudiences, updateCallback, selectedAudienceIds);
    if (selectedAudiences.length && !showRecommendationPopup && !popUpClosedWithRecommendations && !isExcluded) {
      setShowRecommendationPopup(true);
    }
  }, [selectedAudiences]);

  useEffect(() => {
    setAudienceRecommendations();
  }, [debouncedSelectedAudienceIds]);

  useEffect(() => {
    if (audienceTaxonomyTreeData!.length > 0) {
      setShouldShowPanel(true);
      setIsLoadingData(false);
      setShowLibrary(true);
    }
  }, [audienceTaxonomyTreeData]);

  useEffect(() => {
    if (!showRecommendationPopup && recommendations.length) {
      setPopUpClosedWithRecommendations(true);
    }
  }, [showRecommendationPopup]);

  const getAudience = async searchTerm => {
    try {
      const res = await send$http($http, searchAudience, {
        data: {
          search_text: searchTerm
        }
      });
      return res.results as { audience: Audience; category: AudienceCategory }[];
    } catch (e) {
      setShowError(true);
      // handle error boundary
      console.error(e);
      return [];
    }
  };

  const fetchRecommendations = async () => {
    const publicAudienceTypes: string[] = [AudienceType.BRAND, AudienceType.BEHAVIOR, AudienceType.CATEGORY];
    const publicAudiences = selectedAudiences.filter(a => publicAudienceTypes.includes(a.type as string));

    if (publicAudiences.length === 0 || isExcluded) {
      // Skip API call if there are no public audiences or if exclude section
      return [];
    }

    setIsLoadingRecommendations(true);

    try {
      const res = await send$http($http, fetchAudienceRecommendations, {
        data: {
          audience_ids: publicAudiences.map(a => (a?.id ?? '').toString())
        }
      });
      // Now that we have fetched new audience recommendations, we want to log this event into GA
      window.gtag('event', GAEventList.AUDIENCE_RECOMMENDATIONS_LOAD, {
        adgroup_id: selectedAdgroupId,
        campaign_id: selectedCampaignId,
        audience_ids: res.recommendations?.map(el => el.id).join(','),
        selected_audience_ids: publicAudiences.map(a => (a?.id ?? '').toString()),
        gt_user_id: window?.user?.id
      });
      return res.recommendations as Audience[];
    } catch (e) {
      setShowError(true);
      // handle error boundary
      console.error(e);
      return [];
    } finally {
      setIsLoadingRecommendations(false);
    }
  };

  const setAudienceRecommendations = async () => {
    const res = await fetchRecommendations();
    if (selectedAudienceIds.size > 0) {
      setRecommendations(res);
    } else {
      setRecommendations([]);
    }
  };

  const handleAddAllRecommendations = () => {
    const newRecommendedAudiences = recommendations.filter(r => !selectedAudienceIds.has(r?.id as string));
    const newSelections = [...selectedAudiences, ...newRecommendedAudiences];
    setIsLoadingRecommendations(true);
    setRecommendations([]);
    setSelectedAudiences(newSelections);
    setSelectedAudienceIds(new Set(newSelections.map(a => a?.id as string)));
    // Now that we have added all audiences from recommendations, we want to log this event into GA
    window.gtag('event', GAEventList.RECOMMENDED_AUDIENCE_ALL_SELECTED, {
      adgroup_id: selectedAdgroupId,
      campaign_id: selectedCampaignId,
      audience_ids: newRecommendedAudiences.map(el => el.id).join(','),
      gt_user_id: window?.user?.id
    });
  };

  const addRecommendation = (item: Audience) => {
    setRecommendations(recommendations.filter(r => r !== item));
    if (!selectedAudienceIds.has(item?.id as string)) {
      setSelectedAudienceIds(new Set([item, ...selectedAudiences].map(a => a?.id as string)));
      setSelectedAudiences([item, ...selectedAudiences]);
    }
    // Now that we have added one audience from recommendations, we want to log this event into GA
    window.gtag('event', GAEventList.RECOMMENDED_AUDIENCE_SINGLE_SELECTED, {
      adgroup_id: selectedAdgroupId,
      campaign_id: selectedCampaignId,
      audience_ids: `${item.id}`, // We're getting audience_id as either string or int. This line ensures uniformity in the GA event parameter
      gt_user_id: window?.user?.id
    });
  };

  const handleSearch = async (searchTerm: string) => {
    if (!searchTerm || searchTerm.length < 2) {
      return;
    }

    setShowError(false);
    setIsLoadingData(true);
    const results = await getAudience(searchTerm);
    setSearchResults(results);
    setIsLoadingData(false);
  };

  const handleChange = e => {
    setSearchTerm(e.target.value);
    setShowLibrary(false); // hide library on search
  };

  const removeSelectedItem = (itemToRemove: AnyTaxonomyItem) => {
    const filteredAudiences = selectedAudiences.filter(audience => audience.name !== itemToRemove.name);
    setSelectedAudiences(filteredAudiences);
    setSelectedAudienceIds(new Set(filteredAudiences.map(audience => audience.id as string)));
  };

  const handleItemSelected = (item: AnyTaxonomyItem) => {
    // If the item is an 'AudienceType', we don't want to do anything so just return
    if (isAudienceType(item)) {
      return;
    }
    let newSelectedAudiences = [...selectedAudiences];
    if (isAudienceCategory(item)) {
      const categoryChildAudiences = item.audiences || [];
      // If the item is a category, select all the audience ids inside it
      // If all child audiences are already selected, deselect them
      const allChildAudiencesSelected = categoryChildAudiences.every(audience =>
        selectedAudienceIds.has(audience.id as string)
      );
      if (allChildAudiencesSelected) {
        categoryChildAudiences.forEach(audience => {
          newSelectedAudiences = newSelectedAudiences.filter(a => a.id !== audience.id);
        });
      } else {
        newSelectedAudiences = [
          // When the user selects a category audience with unselected child audiences inside,
          // we want to reselect the category child audiences which are not already selected
          ...categoryChildAudiences.filter(audience => !selectedAudienceIds.has(audience.id as string)),
          ...selectedAudiences
        ];
      }
    } else if (isAudience(item)) {
      // If the audience is already selected
      if (selectedAudienceIds.has(item.id as string)) {
        // Deselect the audience
        newSelectedAudiences = newSelectedAudiences.filter(a => a.id !== item.id);
      } else {
        // Select the audience
        newSelectedAudiences = [item, ...selectedAudiences];
      }
    }

    setSelectedAudiences(newSelectedAudiences);
    setSelectedAudienceIds(new Set(newSelectedAudiences.map(audience => audience.id as string)));
  };

  const openClearSelectionsModal = () => {
    setShowConfirmationModal(true);
  };

  const closeClearSelectionModal = () => {
    setShowConfirmationModal(false);
  };

  const clearSelection = () => {
    setSelectedAudiences([]);
    setSelectedAudienceIds(new Set());
    setShowConfirmationModal(false);
  };

  const toggleAudienceLibrary = async () => {
    setShowError(false);
    setIsLoadingData(true);
    setShouldShowPanel(true);
    setSearchTerm('');
    if (audienceTaxonomyTreeData?.length === 0) {
      await getTaxonomyAudience();
    } else {
      setShowLibrary(true);
      setIsLoadingData(false);
    }
  };

  const updateCallback = (audiences: Audience[]) => {
    setSelectedAudiences(audiences);
    setSelectedAudienceIds(new Set(audiences.map(audience => audience.id as string)));
  };

  const handleClickOutside = event => {
    if (
      searchPanelRef.current &&
      !(searchPanelRef.current as HTMLDivElement).contains(event.target) &&
      !(document.querySelector('.modal-dialog') as HTMLDivElement).contains(event.target)
    ) {
      setShouldShowPanel(false);
    }
  };

  const handleRetrySearch = () => {
    handleSearch(searchTerm);
  };

  const handleRecommendationPopupTrigger = () => {
    setShowRecommendationPopup(!showRecommendationPopup);
  };

  const handlePopupClose = () => {
    setShowRecommendationPopup(false);
  };

  return (
    <div className="audience-search-section">
      <div className="audience-search-section__container">
        {showConfirmationModal ? (
          <ClearSelectionConfirmationModal
            shouldDisplay={showConfirmationModal}
            onClose={closeClearSelectionModal}
            onConfirm={clearSelection}
          />
        ) : null}
        <div className="audience-search-section__list">
          <AudienceList
            includeItemDescription={includeItemDescription}
            isExcluded={isExcluded}
            onClearSelections={openClearSelectionsModal}
            onRemoveItem={removeSelectedItem}
            selectedAudiences={selectedAudiences}
          />
        </div>
        <div ref={searchPanelRef}>
          <div className="audience-search-section__searchbar">
            <AudienceSearchBar
              onAudienceLibrarySwitch={toggleAudienceLibrary}
              searchTerm={searchTerm}
              onSearch={handleChange}
            />
            {!isExcluded && (
              <div className="audience-search-section__recommendation" onClick={handleRecommendationPopupTrigger}>
                <img
                  className="audience-search-section__recommendation-icon"
                  src="/ui/images/AudienceRecommendationTrigger.gif"
                />
              </div>
            )}
          </div>
          {shouldShowPanel ? (
            <div className={`audience-search-section__result${!isExcluded ? ' with-recommendation' : ''}`}>
              <AudienceSearchPanel
                audienceTaxonomyTreeData={audienceTaxonomyTreeData}
                includeItemDescription={includeItemDescription}
                isLoading={isLoadingData}
                searchResults={searchResults}
                searchTerm={searchTerm}
                selectedAudienceIds={selectedAudienceIds}
                selectedAudiences={selectedAudiences}
                showError={showError}
                showLibrary={showLibrary}
                onCheckboxChange={handleItemSelected}
                onRetrySearch={handleRetrySearch}
              />
            </div>
          ) : null}
        </div>
      </div>
      {showRecommendationPopup ? (
        <div className="audience-search-section__popup">
          <AudienceRecommendationPopup
            isLoading={isLoadingRecommendations}
            recommendations={recommendations}
            onPopupClose={handlePopupClose}
            onAddAllRecommendations={handleAddAllRecommendations}
            onAddAudience={addRecommendation}
          />
        </div>
      ) : null}
    </div>
  );
}

AudienceSearchSection.propTypes = {
  includeItemDescription: PropTypes.bool,
  isExcluded: PropTypes.bool,
  selectedAudiences: PropTypes.array,
  selectedAdgroupId: PropTypes.number,
  selectedCampaignId: PropTypes.number,
  updateSelections: PropTypes.func
};
