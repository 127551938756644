ProductPickerModalController.$inject = ['$uibModalInstance', 'data', 'handlers'];

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 */
export function ProductPickerModalController($uibModalInstance, data, handlers) {
  var vm = this;

  vm.dismiss = dismiss;
  vm.productOptions = data.productOptions;
  vm.selectedOptions = data.selectedOptions;
  vm.save = save;
  vm.changeCheckbox = changeCheckbox;
  vm.validateCheckboxes = validateCheckboxes;
  vm.checkedMap = {};
  vm.disableApply;

  for (var optionTitle in vm.productOptions) {
    for (var item in vm.productOptions[optionTitle]) {
      vm.checkedMap[vm.productOptions[optionTitle][item].key] = vm.productOptions[optionTitle][item].checked;
    }
  }
  validateCheckboxes();

  function changeCheckbox(optionTitle, checked, index) {
    vm.productOptions[optionTitle][index].checked = checked;
    vm.checkedMap[vm.productOptions[optionTitle][index].key] = vm.productOptions[optionTitle][index].checked;
    validateCheckboxes();
  }

  function validateCheckboxes() {
    vm.disableApply = Object.values(vm.checkedMap).includes(true) ? false : true;
  }

  function dismiss() {
    $uibModalInstance.dismiss();
  }

  // use this to save/update selected filters
  // changes the checked property in productOptions
  function save() {
    vm.selectedOptions = [];

    for (var optionTitle in vm.productOptions) {
      for (var item in vm.productOptions[optionTitle]) {
        if (vm.productOptions[optionTitle][item].checked) {
          vm.productOptions[optionTitle][item].checked = true;
          vm.selectedOptions.push(vm.productOptions[optionTitle][item].name);
        } else {
          vm.productOptions[optionTitle][item].checked = false;
        }
      }
    }

    handlers.getSelectedOptions(vm.selectedOptions);
    $uibModalInstance.close();
  }
}
