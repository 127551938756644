import * as React from 'react';
import { IconButton } from '../../../components/reactComponents/Button/IconButton';
import { Heading } from '../../../components/reactComponents/Typography';
import { BackIcon } from '../../../components/reactComponents/Icons';
import { styled } from '@material-ui/core/styles';

const HeadingContainer = styled('div')({
  width: '100%',
  height: '72px',
  display: 'flex',
  backgroundColor: 'var(--color-primary-container)',
  justifyContent: 'quick-start',
  borderBottom: '1px solid var(--color-outline)'
});

const StyledHeader = styled('h5')({
  paddingBottom: 2,
  paddingTop: 2,
  color: 'var(--color-caption)',
  margin: '0px'
});
const NewReportHeader = styled('h5')({
  color: 'var(--color-on-primary)',
  margin: '0px',
  fontFamily: 'var(--secondary-font-family)',
  fontSize: '20px',
  fontWeight: 'var(--font-weight-bold)',
  lineHeight: '140%',
  textTransform: 'capitalize'
});

const ReportName = styled(Heading)({
  color: 'var(--color-on-primary)',
  fontFamily: 'var(--secondary-font-family)',
  fontSize: '20px',
  fontWeight: 'var(--font-weight-bold)',
  lineHeight: '140%'
});

const BackButtonContainer = styled('div')({
  width: 75,
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: '20px'
});

const TextInfoContainer = styled('div')({
  padding: 16,
  paddingLeft: 20,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
});

export function ReportFormHeader(props) {
  const { reportName, reportIsNew, onCancel } = props;

  return (
    <HeadingContainer className="report-form-header">
      <BackButtonContainer>
        <IconButton onClick={onCancel}>
          <BackIcon />
        </IconButton>
      </BackButtonContainer>
      <TextInfoContainer>
        {reportIsNew ? <NewReportHeader>New Report</NewReportHeader> : <StyledHeader>Edit Report</StyledHeader>}
        <ReportName variant="h4">{reportName}</ReportName>
      </TextInfoContainer>
    </HeadingContainer>
  );
}
