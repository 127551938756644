import { computed, action } from 'mobx';
import { FormField } from './FormField';

export abstract class FormModelBase {
  protected abstract getFields(): FormField<any>[];

  @computed get fields() {
    return this.getFields();
  }

  @computed get isValid() {
    return this.fields.every(field => field.isValid);
  }

  /**
   * This action must be called before the main submit of the form.
   * It triggers validation (which may be async in the future).
   * From this point on a live validation of the form fields is triggered.
   */
  @action.bound validate() {
    return this.fields.map(field => field.validate()).every(isValid => isValid);
  }
}
