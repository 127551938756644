import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MaterialMenuItem from '@material-ui/core/MenuItem';
import { styled } from '../../../utils/react/mui_styled';

export interface PopupMenuOption {
  title: string;
  danger?: boolean;
}

const StyledMenuItem = styled(MaterialMenuItem).extraProps<{ danger?: boolean }>(({ danger, ...other }) => other)(
  ({ danger }) => ({
    color: danger ? 'var(--color-error)' : 'var(--color-on-primary)',
    minWidth: '180px',
    height: 'var(--action-item-height)',
    fontFamily: 'var(--font-family-regular)',
    fontSize: 'var(--font-size-regular)',
    fontWeight: 'var(--font-weight-regular)',
    borderRadius: '2px',
    paddingLeft: '8px',
    paddingRight: '8px',
    lineHeight: 'var(--line-height)',
    '&:hover': {
      backgroundColor: 'var(--color-primary-container)'
    }
  })
);

const paperPropsStyle = {
  borderRadius: 'var(--border-radius)',
  paddingLeft: 8,
  paddingRight: 8,
  border: '1px solid var(--color-outline)'
};

StyledMenuItem.displayName = 'StyledMenuItem';

export interface PopupMenuProps {
  /** The html element you want to the menu item to be on */
  anchor: null | HTMLElement;
  /**
   * Items you want to display. The **OptionItems** type looks like this:
   * @arg {string} title - Title to be displayed.
   * @arg {boolean} [isDeleted] - If this option is set to true then the item rendered would be red
   */
  options: PopupMenuOption[];
  /**
   * Function that is called with the **OptionItem** component
   */
  onSelect(selectedItem: PopupMenuOption): void;
  /**
   * This will be called when the user clicks out side of the menu or when the user has selected an option. It is upto to the user of the component to set the anchor to null to shut the element
   */
  onClose(): void;
}

export function PopupMenu(props: PopupMenuProps) {
  const { onSelect, options, onClose } = props;

  const handleSelect = React.useCallback(
    (option: PopupMenuOption): void => {
      onClose();
      onSelect(option);
    },
    [onClose, onSelect]
  );

  return (
    <Menu
      anchorEl={props.anchor}
      keepMounted
      open={Boolean(props.anchor)}
      onClose={onClose}
      PaperProps={{
        style: paperPropsStyle
      }}
    >
      {options.map((option, index) => (
        <StyledMenuItem key={index} danger={option.danger} onClick={() => handleSelect(option)} disableRipple>
          {option.title}
        </StyledMenuItem>
      ))}
    </Menu>
  );
}
