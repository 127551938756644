import { BudgetType } from '../../../api/types/common';

availsSidebar.$inject = [
  'campaignManagerFactory',
  'adgroupTargetingFactory',
  'generalUtilsFactory',
  'md5',
  'featureFlagFactory',
  'companyAndAccountFactory',
  '$filter'
];

/**
 * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {ng.IFilterService} $filter
 *
 */
export function availsSidebar(
  campaignManagerFactory,
  adgroupTargetingFactory,
  generalUtilsFactory,
  md5,
  featureFlagFactory,
  companyAndAccountFactory,
  $filter
) {
  return {
    restrict: 'E',
    replace: false,
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.avails_sidebar.html',
    link: function(scope, element, attrs) {
      scope.entityDisplayTypes = adgroupTargetingFactory.entityDisplayTypes;
      scope.includedItems = [];
      scope.excludedItems = [];
      scope.excludedAudiences = [];
      scope.includedLocations = [];
      scope.excludedLocations = [];
      scope.productType = '';
      scope.errorMsg = '';
      scope.totalBudgetedVisits = -1;
      scope.loading = false;
      scope.checksum = null;
      scope.accountCountryCode = companyAndAccountFactory.selectedAccount?.countryCode;
      scope.updateAvails = async function(adGroup) {
        var adGroup = adGroup || scope.adGroup;

        scope.includedItems = [];
        scope.excludedItems = [];
        scope.excludedAudiences = [];
        scope.includedLocations = [];
        scope.excludedLocations = [];
        if (adGroup.time_period_budgets && adGroup.time_period_budgets?.length) {
          adGroup.time_period_budgets.forEach(function(item, index) {
            let { startDate, endDate, budget } = item;
            if (
              budget >= 0 &&
              startDate &&
              (endDate || campaignManagerFactory.selectedCampaign.budgetType === BudgetType.DAILY)
            ) {
              let startDateOnly = generalUtilsFactory.getDateWithoutTime(startDate);
              let endDateOnly = generalUtilsFactory.getDateWithoutTime(endDate);
              let currentDate = generalUtilsFactory.getDateWithoutTime();

              let foundCurrTimePeriod =
                (currentDate < startDateOnly && index === 0) ||
                (currentDate > endDateOnly && index === adGroup.time_period_budgets.length - 1) ||
                (currentDate >= startDateOnly && currentDate <= endDateOnly);
              if (foundCurrTimePeriod) {
                setBudgetedImpressions(budget, startDate, endDate, adGroup.bidRate);
              }

              if (campaignManagerFactory.selectedCampaign.is_cpv) {
                let projection = Math.round(budget / adGroup.bidRate);
                scope.totalBudgetedVisitsPerDay =
                  projection / (generalUtilsFactory.daysBetween(startDate, endDate) + 1);
              }
            }
          });
        }
        var productItems;
        if (scope.adGroup.product.indexOf('onpremise') > -1) {
          scope.productType = 'On Premise';
          productItems = adGroup.targeting.on_premise || [];
        } else if (adGroup.product.indexOf('audience') > -1) {
          scope.productType = 'Audience';
          productItems = adGroup.targeting.audience || [];
        } else if (adGroup.product.indexOf('geotargeting') > -1) {
          scope.productType = 'Geotarget';
          productItems = adGroup.targeting.geotargeting || [];
        } else if (adGroup.product.indexOf('neighborhood') > -1) {
          scope.productType = 'Neighborhood';
          productItems = adGroup.targeting.neighborhood || [];
        } else if (adGroup.product.indexOf('weather') > -1) {
          scope.productType = 'Weather';
          productItems = [];
        }

        // And just in case...
        if (!(productItems instanceof Array)) {
          productItems = [];
        }

        var includedItemsByType = {};
        var excludedItemsByType = {};
        productItems.forEach(function(item) {
          if (item.exclude) {
            if (item.type in excludedItemsByType) {
              excludedItemsByType[item.type].push(item);
            } else {
              excludedItemsByType[item.type] = [item];
            }
          } else {
            if (item.type in includedItemsByType) {
              includedItemsByType[item.type].push(item);
            } else {
              includedItemsByType[item.type] = [item];
            }
          }
        });
        for (const type in includedItemsByType) {
          scope.includedItems.push.apply(scope.includedItems, includedItemsByType[type]);
        }
        for (const type in excludedItemsByType) {
          scope.excludedItems.push.apply(scope.excludedItems, excludedItemsByType[type]);
        }

        if (adGroup.product.indexOf('weather') > -1) {
          for (const weatherType in adGroup.targeting.weather) {
            var weatherTypeObjects = adGroup.targeting.weather[weatherType];
            if (weatherTypeObjects.length) {
              if (weatherType === 'weather_condition') {
                scope.includedItems.push({ text: weatherTypeObjects.length + ' Conditions Selected' });
              } else if (weatherType === 'weather_alert') {
                scope.includedItems.push({ text: weatherTypeObjects.length + ' Alerts Selected' });
              } else {
                scope.includedItems.push({ text: adgroupTargetingFactory.entityDisplayTypes[weatherType] });
              }
            }
          }
        }

        var excludedAudiencesByType = {};
        if (adGroup.targeting.audience_exclude) {
          adGroup.targeting.audience_exclude.forEach(function(item) {
            if (item.type in excludedAudiencesByType) {
              excludedAudiencesByType[item.type].push(item);
            } else {
              excludedAudiencesByType[item.type] = [item];
            }
          });
        }

        var includedLocationsByType = {};
        var excludedLocationsByType = {};
        var numIncludedAddresses = 0;
        var numIncludedLatLngs = 0;
        var numIncludedZipCodes = 0;
        var numExcludedZipCodes = 0;
        var locationFilters = adGroup.targeting.location_filters || [];
        locationFilters.forEach(function(item) {
          if (item.type !== 'address' && item.type !== 'latlng' && item.type !== 'zip_code') {
            if (item.exclude) {
              if (item.type in excludedLocationsByType) {
                excludedLocationsByType[item.type].push(item);
              } else {
                excludedLocationsByType[item.type] = [item];
              }
            } else {
              if (item.type in includedLocationsByType) {
                includedLocationsByType[item.type].push(item);
              } else {
                includedLocationsByType[item.type] = [item];
              }
            }
          } else {
            if (item.exclude) {
              if (item.type === 'zip_code') {
                numExcludedZipCodes++;
              }
            } else {
              if (item.type === 'address') {
                numIncludedAddresses++;
              } else if (item.type === 'latlng') {
                numIncludedLatLngs++;
              } else if (item.type === 'zip_code') {
                numIncludedZipCodes++;
              }
            }
          }
        });
        if (numIncludedAddresses > 0) {
          scope.includedLocations.push({ name: numIncludedAddresses + ' selected', type: 'address' });
        }
        if (numIncludedLatLngs > 0) {
          scope.includedLocations.push({ name: numIncludedLatLngs + ' selected', type: 'latlng' });
        }
        if (numIncludedZipCodes > 0) {
          scope.includedLocations.push({ name: numIncludedZipCodes + ' selected', type: 'zip_code' });
        }
        if (numExcludedZipCodes > 0) {
          scope.excludedLocations.push({ name: numExcludedZipCodes + ' selected', type: 'zip_code' });
        }
        for (const type in excludedAudiencesByType) {
          scope.excludedAudiences.push.apply(scope.excludedAudiences, excludedAudiencesByType[type]);
        }
        for (const type in includedLocationsByType) {
          scope.includedLocations.push.apply(scope.includedLocations, includedLocationsByType[type]);
        }
        for (const type in excludedLocationsByType) {
          scope.excludedLocations.push.apply(scope.excludedLocations, excludedLocationsByType[type]);
        }
        if (
          !['weather', 'default'].includes(adGroup.product) &&
          scope.accountCountryCode !== 'CA' &&
          !campaignManagerFactory.selectedCampaign.is_cpv
        ) {
          await updateAvailsData(adGroup);
        }
      };
      scope.showAvailsItemType = function(audienceType) {
        const hiddenAudienceTypes = ['Brand', 'Category', 'Behavior'];
        return (
          featureFlagFactory.isFeatureEnabled('AUDIENCE_TAXONOMY') &&
          hiddenAudienceTypes.includes(scope.entityDisplayTypes[audienceType])
        );
      };

      function setBudgetedImpressions(budget, startDate, endDate, bidRate) {
        scope.isBudgetSet = !!budget;
        if (scope.adGroup.product === 'weather' || scope.accountCountryCode === 'CA' || !budget) {
          scope.totalBudgetedImpressionsPerDay = 0;
        } else if (bidRate > 0) {
          if (campaignManagerFactory.selectedCampaign.budgetType === BudgetType.DAILY) {
            scope.totalBudgetedImpressionsPerDay = Math.round((1000 * budget) / bidRate);
          } else {
            const dailyBudget = budget / (generalUtilsFactory.daysBetween(startDate, endDate) + 1);
            scope.totalBudgetedImpressionsPerDay = Math.round((dailyBudget / bidRate) * 1000);
          }
        }
      }

      scope.updateFns.availsUpdateFn = scope.updateAvails;

      async function updateAvailsData(adGroup) {
        var checksum = md5.createHash(JSON.stringify(adGroup));
        if (checksum !== scope.checksum && adGroup !== null && adGroup.id !== null) {
          companyAndAccountFactory
            .getSelectedAccount()
            .then(function(account) {
              if (campaignManagerFactory.selectedCampaign.is_cpv) {
                if (!adGroup.bidRate) {
                  adGroup.cpv = account.suggestedBidPrice ? account.suggestedBidPrice : 0;
                } else {
                  adGroup.cpv = adGroup.bidRate ? adGroup.bidRate : 0;
                }
                if (account.suggestedBidPrice && adGroup.cpv < account.suggestedBidPrice) {
                  scope.loading = false;
                  throw new Error();
                }
              }
              return adGroup;
            })
            .then(function(adGroup) {
              campaignManagerFactory.getAdGroupAvailsData(
                campaignManagerFactory.selectedCampaign.id,
                adGroup,
                checksum,
                function beginCallback() {
                  scope.checksum = checksum;
                  scope.errorMsg = '';
                  scope.loading = true;
                },
                function successCallback(availsData) {
                  if (availsData['checksum'] !== scope.checksum) {
                    return;
                  }

                  scope.loading = false;
                  if (availsData) {
                    scope.projectedImpressions = availsData['projected_impressions'];
                    scope.projectedImpressionsLow = Math.round(availsData['projected_impressions_low'] / 100) * 100;
                    scope.projectedImpressionsHigh = Math.round(availsData['projected_impressions_high'] / 100) * 100;
                    scope.audienceReach = availsData['audience_reach'];
                    scope.audienceReachLow = Math.round(availsData['audience_reach_low'] / 100) * 100;
                    scope.audienceReachHigh = Math.round(availsData['audience_reach_high'] / 100) * 100;
                    scope.errorMsg = '';
                    scope.audienceReachRange = getAudienceReachRange(scope.audienceReachLow, scope.audienceReachHigh);
                  }
                },
                function errorCallback(response) {
                  if (response && response['checksum'] !== scope.checksum) {
                    return;
                  }
                  scope.loading = false;
                  scope.errorMsg = 'Error getting latest projections';
                }
              );
            })
            .catch(function(error) {});
        }
      }

      function getAudienceReachRange(audienceReachLow, audienceReachHigh) {
        let audienceReachRange;
        if (audienceReachHigh < 1000) {
          audienceReachRange = '<1000';
        } else if (audienceReachLow === audienceReachHigh) {
          audienceReachRange = $filter('number')(audienceReachHigh);
        } else if (audienceReachLow < 1000 && audienceReachHigh >= 1000) {
          audienceReachRange = '<1000 - ' + $filter('number')(audienceReachHigh);
        } else {
          audienceReachRange = $filter('number')(audienceReachLow) + ' - ' + $filter('number')(audienceReachHigh);
        }
        return audienceReachRange;
      }

      function getAlertMsg() {
        if (scope.accountCountryCode === 'CA') {
          return 'Avails not supported for Canada';
        } else if (campaignManagerFactory.selectedCampaign.is_cpv) {
          return 'Avails not supported for CPV ad groups';
        } else if (scope.adGroup.product === 'weather') {
          return 'Avails not supported due to the volatility of weather triggers';
        }
        return null; // no alert
      }

      scope.alertMsg = getAlertMsg();

      // make element sticky
      var offset = angular.element('.context-header').height();
      var el = angular.element(element[0]).find('.avails-sidebar');
      generalUtilsFactory.makeElementSticky(el, undefined, offset + 40);
      scope.updateAvails();
    }
  };
}
