const CHART_GREEN = '#81d135';
const CHART_BLUE = '#4e92df';
const CHART_ORANGE = '#FAAD14';
const IMPRESSION_INDICATOR_LEN = 100;
const MINIMUM_RIGHT_BOUNDARY = 1000;
const SIDE_BAR_TOTAL_LEN = 2000;

/**
 * This directive takes in three mandatory paramters and one optional paramter.
 * dailyAvailableImpressionsLow , dailyAvailableImpressionsHigh and dailyBudgetedImpressions are required - they are of type numbers
 * disableBudgetedImpressions - of type boolean (optional). setting it to true will grey out the Available Budgeted Impression to grey
 */
class XadImpressionChart {
  constructor() {
    this.restrict = 'E';
    (this.scope = {
      dailyAvailableImpressionsLow: '=availableImpressionsLow', // Minimum daily Avaialable Impression
      dailyAvailableImpressionsHigh: '=availableImpressionsHigh', //Maximum daily Avaialable Impression
      dailyBudgetedImpressions: '=budgetedImpressions', //green
      disableBudgetedImpressions: '=disableBudgetedImpressions',
      isBudgetSet: '=isBudgetSet'
    }),
      (this.templateUrl = '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.xad-impression-chart.html');
    this.controller = ImpressionChartController;
  }

  link(scope, element, attrs, controller) {
    scope.$watchGroup(
      [
        'dailyAvailableImpressionsLow',
        'dailyAvailableImpressionsHigh',
        'dailyBudgetedImpressions',
        'isBudgetSet',
        'disableBudgetedImpressions'
      ],
      function(newValue, oldValue) {
        let newSeries = controller.makeSeriesData(newValue[0], newValue[1], newValue[2], newValue[3], newValue[4]);
        let index = 0;
        for (let i in newSeries) {
          scope.chart.series[index].update(newSeries[i], false);
          index++;
        }
        scope.chart.redraw();
        scope.availableImpressionRange = controller.getAvailableImpressionRange(newValue[0], newValue[1]);
      }
    );
    scope.chart = controller.makeChart(
      scope.dailyAvailableImpressionsLow,
      scope.dailyAvailableImpressionsHigh,
      scope.dailyBudgetedImpressions,
      scope.isBudgetSet,
      element[0].querySelector('#impressionChart')
    );
  }
}
class ImpressionChartController {
  /**
   * @param {ng.IFilterService} $filter
   * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
   * @ngInject
   */
  constructor($filter, generalUtilsFactory) {
    this.generalUtilsFactory = generalUtilsFactory;
    this.filter = $filter;
  }

  /**
   *
   * @param {*} dailyAvailableImpressionsLow
   * @param {*} dailyAvailableImpressionsHigh
   * @returns dailyAvailableImpressionsRange
   */
  getAvailableImpressionRange(dailyAvailableImpressionsLow, dailyAvailableImpressionsHigh) {
    if (dailyAvailableImpressionsLow >= 0 && dailyAvailableImpressionsHigh >= 0) {
      let availableImpressionRange;
      if (dailyAvailableImpressionsHigh < MINIMUM_RIGHT_BOUNDARY) {
        availableImpressionRange = '<1000';
      } else if (
        dailyAvailableImpressionsLow < MINIMUM_RIGHT_BOUNDARY &&
        dailyAvailableImpressionsHigh >= MINIMUM_RIGHT_BOUNDARY
      ) {
        availableImpressionRange = '<1000 - ' + this.filter('number')(dailyAvailableImpressionsHigh);
      } else if (dailyAvailableImpressionsLow === dailyAvailableImpressionsHigh) {
        availableImpressionRange = this.filter('number')(dailyAvailableImpressionsHigh);
      } else {
        availableImpressionRange =
          this.filter('number')(dailyAvailableImpressionsLow) +
          ' - ' +
          this.filter('number')(dailyAvailableImpressionsHigh);
      }
      return availableImpressionRange;
    }
  }
  /**
   *
   * @param dailyImpressionsLow dailyImpressionsLow number of minimum impression. Blue
   * @param dailyImpressionsHigh dailyImpressionsHigh number of maximum impression.
   * @param budgetedImpressions budgetedImpressions number of budgeted impression. Green
   * @param disableBudgetedImpressions
   * @returns {*[]} an array of objects that give us the overlapping bars that make the impression chart
   * (green, grey and blue). Doesn't handle negative values
   */

  makeSeriesData(
    dailyImpressionsLow = 0,
    dailyImpressionsHigh = 0,
    budgetedImpressions = 0,
    isBudgetSet,
    disableBudgetedImpressions = false
  ) {
    if (Number.isNaN(dailyImpressionsLow) || !isFinite(dailyImpressionsLow) || dailyImpressionsLow < 0) {
      dailyImpressionsLow = 0;
    }
    if (Number.isNaN(dailyImpressionsHigh) || !isFinite(dailyImpressionsHigh) || dailyImpressionsHigh < 0) {
      dailyImpressionsHigh = 0;
    }
    if (Number.isNaN(budgetedImpressions) || !isFinite(budgetedImpressions)) {
      budgetedImpressions = 0;
    }

    dailyImpressionsLow =
      dailyImpressionsLow < 1000 ? dailyImpressionsLow : Math.round(dailyImpressionsLow / 1000) * 1000;
    dailyImpressionsHigh =
      dailyImpressionsHigh < 1000 ? dailyImpressionsHigh : Math.round(dailyImpressionsHigh / 1000) * 1000;
    budgetedImpressions = budgetedImpressions < 0 ? 0 : Math.round(budgetedImpressions);

    /**
     * The method will return scaled value of dailyMaxImpressions, dailyImpressionssRange and budgetedImpressions. They are scaled out of 2000
     * @param {number} dailyMaxImpressions: number of max daily impressions
     * @param {number} budgetedImpressions
     * @param {number} dailyRange: number of daily impression range
     * @returns {{dailyImpressionsMaxProgress: number, budgetedImpressionsProgress: number}}
     */
    function getScaledValues(dailyMaxImpressions, budgetedImpressions, dailyRange) {
      let dailyImpressionsMaxProgress = IMPRESSION_INDICATOR_LEN;
      let budgetedImpressionsProgress = 0;
      if (dailyMaxImpressions === 0 && budgetedImpressions === 0) {
        return { dailyImpressionsMaxProgress, budgetedImpressionsProgress };
      } else {
        if (dailyMaxImpressions > budgetedImpressions) {
          dailyImpressionsMaxProgress = SIDE_BAR_TOTAL_LEN;
          budgetedImpressionsProgress = SIDE_BAR_TOTAL_LEN * (budgetedImpressions / dailyMaxImpressions);
        } else {
          budgetedImpressionsProgress = SIDE_BAR_TOTAL_LEN;
          dailyImpressionsMaxProgress = SIDE_BAR_TOTAL_LEN * (dailyMaxImpressions / budgetedImpressions);
        }

        if (dailyImpressionsMaxProgress <= IMPRESSION_INDICATOR_LEN) {
          dailyImpressionsMaxProgress = IMPRESSION_INDICATOR_LEN;
        }
        if (budgetedImpressionsProgress <= IMPRESSION_INDICATOR_LEN) {
          budgetedImpressionsProgress = IMPRESSION_INDICATOR_LEN;
        }

        dailyImpressionsMaxProgress = Math.round(dailyImpressionsMaxProgress);
        budgetedImpressionsProgress = Math.round(budgetedImpressionsProgress);

        return { dailyImpressionsMaxProgress, budgetedImpressionsProgress };
      }
    }

    const BORDER_RADIUS = 10;
    const { dailyImpressionsMaxProgress, budgetedImpressionsProgress } = getScaledValues(
      dailyImpressionsHigh,
      budgetedImpressions,
      dailyImpressionsHigh - dailyImpressionsLow
    );
    const budgetedImpressionLabel = isBudgetSet
      ? this.generalUtilsFactory.shorthandNumber(budgetedImpressions, 0, 2)
      : 'N/A';
    const dailyImpressionsMin = this.generalUtilsFactory.shorthandNumber(dailyImpressionsLow, 0, 2);
    const dailyImpressionsMax = this.generalUtilsFactory.shorthandNumber(dailyImpressionsHigh, 0, 2);

    let impressionRangeLabel;
    if (dailyImpressionsHigh < MINIMUM_RIGHT_BOUNDARY) {
      impressionRangeLabel = '<1000';
    } else if (dailyImpressionsLow < MINIMUM_RIGHT_BOUNDARY && dailyImpressionsHigh >= MINIMUM_RIGHT_BOUNDARY) {
      impressionRangeLabel = '<1000 - ' + dailyImpressionsMax;
    } else if (dailyImpressionsLow === dailyImpressionsHigh) {
      impressionRangeLabel = dailyImpressionsMax;
    } else {
      impressionRangeLabel = dailyImpressionsMin + ' - ' + dailyImpressionsMax;
    }

    return [
      {
        data: [SIDE_BAR_TOTAL_LEN],
        borederRadius: BORDER_RADIUS,
        borderColor: 'transparent',
        color: dailyImpressionsMaxProgress > budgetedImpressionsProgress ? CHART_BLUE : CHART_ORANGE,
        stack: 0,
        dataLabels: {
          enabled: false
        },
        enableMouseTracking: false
      },
      {
        //blue part
        data: [dailyImpressionsMaxProgress],
        stack: 1,
        borderRadius: BORDER_RADIUS,
        zIndex: dailyImpressionsMaxProgress > budgetedImpressionsProgress ? 5 : 7,
        borderColor: 'transparent',
        color: CHART_BLUE,
        dataLabels: {
          style: {
            color: CHART_BLUE,
            fontSize: '16px',
            fontWeight: '600'
          },
          enabled: impressionRangeLabel != '0',
          format: impressionRangeLabel,
          x: 14,
          y: 70,
          verticalAlign: 'bottom'
        },
        tooltip: {
          pointFormat: `<span style="color:${CHART_BLUE}; font-size:30px; vertical-align:middle;">●</span>
                <span style="vertical-align:text-top;font-size:11px;">Daily Available Impressions: ${impressionRangeLabel} </span>`
        }
      },
      {
        //green part
        data: [budgetedImpressionsProgress],
        stack: 2,
        borderRadius: BORDER_RADIUS,
        borderColor: 'transparent',
        color:
          disableBudgetedImpressions || dailyImpressionsMaxProgress <= budgetedImpressionsProgress
            ? 'transparent'
            : CHART_GREEN,
        zIndex: dailyImpressionsMaxProgress > budgetedImpressionsProgress ? 7 : 5,
        dataLabels: {
          style: {
            color: CHART_GREEN,
            fontSize: '16px',
            fontWeight: '600'
          },
          enabled: !disableBudgetedImpressions,
          y: -22,
          x: -5,
          format: budgetedImpressionLabel
        },
        tooltip: {
          pointFormat: `<span style="color:${CHART_GREEN}; font-size:30px; vertical-align:middle;">●</span>
                <span style="vertical-align:text-top;font-size:11px;">Daily Budgeted Impressions: ${budgetedImpressionLabel}</span>`
        }
      }
    ];
  }

  /**
   *
   * @param {*} dailyImpressionsLow
   * @param {*} dailyImpressionsHigh
   * @param {*} budgetedImpressions
   * @param {*} isBudgetSet
   * @param {*} chartArea
   * @returns Highchart instance
   */
  makeChart(dailyImpressionsLow, dailyImpressionsHigh, budgetedImpressions, isBudgetSet, chartArea) {
    const seriesData = this.makeSeriesData(dailyImpressionsLow, dailyImpressionsHigh, budgetedImpressions, isBudgetSet);

    return Highcharts.chart(chartArea, {
      chart: {
        type: 'bar',
        height: 88,
        style: {
          fontFamily: 'Poppins',
          fontSize: '16px'
        }
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      xAxis: {
        visible: false
      },
      yAxis: {
        visible: false,
        max: SIDE_BAR_TOTAL_LEN
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        borderColor: 'black',
        shape: 'square',
        backgroundColor: 'black',
        useHTML: true,
        headerFormat: '<div style="line-height:23px;">',
        footerFormat: '</div>',
        style: {
          color: 'white',
          opacity: 0.8,
          margin: 30
        }
      },
      plotOptions: {
        bar: {
          stacking: 'normal',
          grouping: false,
          borderRadius: 10,
          borderColor: 'transparent',
          pointPadding: 0.2,
          pointWidth: 7
        },
        series: {
          dataLabels: {
            align: 'right',
            y: 75,
            x: 0,
            style: {
              textOutline: 'none'
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      series: seriesData
    });
  }
}

export function xadImpressionChart() {
  return new XadImpressionChart();
}
