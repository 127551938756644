// DEEP SEARCH V2: Filter on each level
//{name: null, adGroups: {name: null, mode: null}}

// DEEP SEARCH V3: Support logical conjunction
// still has bugs, use with caution

function deepFilter(data, exprs, fields) {
  var result = false,
    conjunctionFields = [],
    disjunctionFields = [],
    childFields = [];

  if (!data || !angular.isArray(data)) {
    return result;
  }

  for (var key in fields) {
    if (fields[key] && !angular.isArray(fields[key])) {
      childFields.push(key);
    } else {
      if (fields[key][1]) {
        conjunctionFields.push(key);
      } else {
        disjunctionFields.push(key);
      }
    }
  }

  for (var i = 0; i < data.length; i++) {
    var item = data[i],
      field,
      included = false,
      disjunctionFlag = undefined,
      conjunctionFlag = undefined,
      value,
      model,
      regex,
      isMatch,
      isParent = false,
      childIncluded = false;

    if (!item || angular.isArray(item) || !angular.isObject(item)) {
      continue;
    }

    // search box
    for (var j = 0; j < disjunctionFields.length; j++) {
      field = disjunctionFields[j];
      if (item.hasOwnProperty(field)) {
        value = item[field];
        model = exprs[fields[field][0]];
        if (!model || !value) {
          continue;
        }
        regex = new RegExp(model, 'gi');
        isMatch = !!value.toString().match(regex);
        included = included || isMatch;
        disjunctionFlag = included;
      }
    }

    // filter dropdown
    for (var j = 0; j < conjunctionFields.length; j++) {
      field = conjunctionFields[j];
      if (item.hasOwnProperty(field)) {
        value = item[field];
        model = exprs[fields[field][0]];
        if (!model || !value) {
          conjunctionFlag = true;
          continue;
        }
        regex = new RegExp(model, 'gi');
        isMatch = !!value.toString().match(regex);
        if (angular.isUndefined(conjunctionFlag)) {
          conjunctionFlag = isMatch;
        } else {
          conjunctionFlag = conjunctionFlag && isMatch;
        }
      }
    }

    if (angular.isDefined(conjunctionFlag)) {
      if (angular.isUndefined(disjunctionFlag)) {
        included = conjunctionFlag;
      } else {
        included = included && conjunctionFlag;
      }
    } else {
      if (angular.isUndefined(disjunctionFlag)) {
        included = true;
      }
    }

    // if campaign is paused or expired, do not show it or its ad groups under the "active" filter
    if (isParent && (item.status == 'Paused' || item.status == 'Expired') && exprs[1] == 'Active') {
      included = false;
      childIncluded = false;
      for (j = 0; j < childFields.length; j++) {
        hideAllChildren(item, childFields[j]);
      }
    }

    item['adGroupsShown'] = childIncluded;

    item['_DS_EXIST'] = included;
    result = result || included;
  }

  return result;
}

function hideAllChildren(parent, childField) {
  var children = parent[childField];
  for (var i = 0; i < children.length; i++) {
    children[i]['_DS_EXIST'] = false;
  }
}

// search for nested objects
export function deepsearch() {
  return function(array) {
    var exprs = [],
      fields;
    for (var i = 1; i < arguments.length - 1; i++) {
      exprs.push(arguments[i]);
    }

    var newArray = [];

    fields = arguments[arguments.length - 1];
    deepFilter(array, exprs, fields);

    // TODO: OPTIMIZE
    for (var i = 0; i < array.length; i++) {
      if (array[i]['_DS_EXIST']) {
        newArray.push(array[i]);
        if (array[i].hasOwnProperty('adGroups')) {
          for (var j = 0; j < array[i].adGroups.length; j++) {
            if (array[i].adGroups[j]['_DS_EXIST']) {
              newArray[newArray.length - 1].adGroups.push(array[i].adGroups[j]);
            }
          }
        }
      }
    }

    return newArray;
  };
}
