modalRejectCampaignController.$inject = ['$uibModalInstance', 'campaignManagerFactory'];

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 */
export function modalRejectCampaignController($uibModalInstance, campaignManagerFactory) {
  var vm = this;

  vm.campaign = campaignManagerFactory.selectedCampaign;
  vm.saveProgress = false;
  vm.rejectComment = '';

  vm.close = function() {
    $uibModalInstance.close();
  };

  vm.rejectCampaign = function() {
    vm.saveProgress = true;
    campaignManagerFactory
      .changeCampaignStatus(vm.campaign, campaignManagerFactory.statusNames.REJECTED, vm.rejectComment)
      .then(
        function() {
          vm.errorMsg = '';
        },
        function(msg) {
          vm.errorMsg = msg;
        }
      )
      .finally(function() {
        vm.saveProgress = false;
        $uibModalInstance.close();
      });
  };
}
