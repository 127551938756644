import { directMailOrdersDashboardController } from './direct_mail_orders.ctrl';
import { abdmService } from './direct_mail_orders.serv';

export const controllers = {
  directMailOrdersDashboardController
};

export const services = {
  abdmService
};
