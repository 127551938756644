import creativeAssetTileTemplate from './creative-asset-tile-template.html';
export const creativeAssetTile = () => {
  const link = (scope, element, attrs, controller) => {
    scope.isImage = scope.creativeAsset.type === 'IMAGE';
    if (scope.isChecked === undefined) {
      scope.isChecked = false;
    }

    scope.onClick = function() {
      const { id } = scope.creativeAsset;
      scope.onSelect(id, scope.isChecked);
    };
  };

  return {
    restrict: 'E',
    scope: {
      creativeAsset: '=',
      onSelect: '=',
      isChecked: '<'
    },
    template: creativeAssetTileTemplate,
    link
  };
};
