xadNegativeStackBarChart.$inject = ['$timeout', '$filter', 'highChartsFactory'];

export function xadNegativeStackBarChart($timeout, $filter, highChartsFactory) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      active: '=',
      changed: '=',
      chartProgress: '=',
      chartClass: '=',
      chartData: '=',
      categories: '=',
      seriesNames: '=', // Name of each half of the chart. Array with two strings.
      heading: '@', // title displayed to the user
      seriesOptions: '=',
      metrics: '=',
      metricsChanged: '=',
      ldaSelected: '=ldaselected'
    },
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.xad-negative-stack-bar-chart.html',
    controller: /** @ngInject */ function($scope) {
      $scope.yAxisMetrics = [];
      $scope.yAxisTitle = 'Reach';

      Highcharts.setOptions({
        lang: {
          thousandsSep: ','
        }
      });

      $scope.yAxisMetrics = highChartsFactory.updateMetrics($scope.seriesOptions);
    },
    link: function(scope, element, attrs) {
      var seriesOptions = [];
      var seriesCounter = 0;
      var seriesOrder = [];
      var tooltipFormat = function() {
        var color = this.series.color;
        var name = this.series.name;
        name = name.charAt(0).toUpperCase() + name.slice(1);
        var category = this.category;
        var dataLabel = scope.seriesOptions[scope.yAxisMetrics[0].value].dataLabel;
        /** @type {number | string} */ var value = Math.abs(this.y);

        if (dataLabel === 'percent') {
          value = $filter('percentage')(value, 2);
        } else {
          value = Highcharts.numberFormat(value, 0);
        }
        return '<b>' + name + ', Age ' + category + '</b><br/>' + scope.yAxisTitle + ': ' + value;
      };

      var yAxisLabelFn = function() {
        return $filter('number')(Math.abs(this.value), 0);
      };

      var yAxisLabelPercentFn = function() {
        return $filter('percentage')(Math.abs(this.value), 2);
      };

      function createChart() {
        // Configure and render chart
        var chartContainer = element[0].querySelector('.chart');
        var formatLabelFn = [];
        scope.yAxisMetrics.forEach(function(metrics) {
          var dataLabel = scope.seriesOptions[metrics ? metrics.value : 'impression'].dataLabel;
          formatLabelFn.push(dataLabel && dataLabel === 'percent' ? yAxisLabelPercentFn : yAxisLabelFn);
        });

        scope.chart = Highcharts.chart(chartContainer, {
          chart: {
            height: 370,
            marginBottom: 50,
            marginTop: 100,
            style: {
              fontFamily: 'Poppins'
            },
            type: 'bar'
          },
          colors: ['#3689bb', '#f36d4a'],
          title: {
            text: ''
          },
          xAxis: [
            {
              title: {
                text: null
              },
              categories: scope.categories,
              reversed: false,
              labels: {
                step: 1,
                style: {
                  color: '#0A3F36'
                }
              }
            },
            {
              title: {
                text: null
              },
              opposite: true,
              reversed: false,
              categories: scope.categories,
              linkedTo: 0,
              labels: {
                step: 1,
                style: {
                  color: '#0A3F36'
                }
              }
            }
          ],
          yAxis: {
            title: {
              text: scope.yAxisTitle,
              x: 0,
              y: -5,
              align: 'middle',
              offset: 40,
              style: {
                color: '#cccccc'
              }
            },
            lineColor: '#cccccc',
            labels: {
              step: 2,
              formatter: formatLabelFn[0],
              style: {
                color: '#0A3F36'
              }
            }
          },
          caption: {
            text: scope.ldaSelected,
            y: 55,
            x: -12,
            style: {
              color: '#0A3F36',
              fontSize: '13px',
              fontWeight: '400',
              lineHeight: '19.2px',
              fontFamily: 'Roboto'
            }
          },
          legend: {
            enabled: true,
            align: 'left',
            x: 460,
            y: 0,
            verticalAlign: 'top',
            itemStyle: {
              fontSize: '14px'
            },
            labelFormatter: function() {
              var name = this.name;
              name = name.charAt(0).toUpperCase() + name.slice(1);
              return name;
            }
          },
          plotOptions: {
            line: {
              marker: {
                symbol: 'square'
              }
            },
            series: {
              stacking: 'normal'
            }
          },
          tooltip: {
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderColor: '#BCF1E9',
            borderRadius: 10,
            padding: 24,
            pointFormatter: tooltipFormat,
            headerFormat: '',
            style: {
              color: '#0A3F36',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '41.6px',
              fontFamily: 'Roboto',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.05)'
            }
          },
          series: [
            {
              type: 'bar',
              name: scope.seriesNames[0],
              data: undefined,
              borderRadiusBottomLeft: 50,
              borderRadiusBottomRight: 50
            },
            {
              type: 'bar',
              name: scope.seriesNames[1],
              data: undefined,
              borderRadiusTopLeft: 50,
              borderRadiusTopRight: 50
            }
          ],
          exporting: {
            enabled: true,
            scale: 1,
            sourceWidth: 1000,
            sourceHeight: 400,
            buttons: {
              contextButton: {
                symbol: 'url(/ui/images/export-icon-download.svg)',
                menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF'],
                text: 'Download',
                verticalAlign: 'top',
                height: 40,
                symbolY: 20,
                symbolX: 20,
                y: 35,
                x: -35
              }
            },
            chartOptions: {
              chart: {
                marginTop: 50,
                marginRight: 100,
                marginBottom: 80,
                marginLeft: 100
              }
            }
          },
          credits: {
            enabled: false
          }
        });
        $timeout(function() {
          scope.chart.reflow();
        });
      }

      scope.changeMetrics = function(index) {
        var data = highChartsFactory.changeMetrics(
          scope.seriesOptions,
          scope.yAxisMetrics,
          scope.metrics,
          scope.yAxisTitle,
          index
        );
        scope.seriesOptions = data.options;
        scope.yAxisMetrics = data.yMetrics;
        scope.metrics = data.metrics;
        scope.yAxisTitle = data.title;

        scope.metricsChanged();
      };

      // Listen for changes
      scope.$watch('changed', function(newValue, oldValue) {
        if (newValue === true) {
          if (scope.chartData && Object.keys(scope.chartData).length) {
            // Negate one side of the chart
            scope.chartData[scope.seriesNames[0]] = scope.chartData[scope.seriesNames[0]].map(function(x) {
              return x > 0 ? -x : x;
            });
          } else {
            // We got not data back -- show empty chart
            scope.chartData = {};
            scope.chartData[scope.seriesNames[0]] = [];
            scope.chartData[scope.seriesNames[1]] = [];

            for (i = 0; i < scope.categories.length; i++) {
              scope.chartData[scope.seriesNames[0]].push(0);
              scope.chartData[scope.seriesNames[1]].push(0);
            }
          }

          createChart();

          // Update chart data and re-render
          var i;
          for (i = 0; i < scope.seriesNames.length; i++) {
            scope.chart.series[i].setData(scope.chartData[scope.seriesNames[i]]);
          }

          scope.changed = false;
        }
      });

      // When chart becomes active, fit the chart inside its container
      scope.$watch('active', function(newValue, oldValue) {
        if (newValue === true) {
          if (scope.chart) {
            $timeout(function() {
              scope.chart.reflow();
            });
          }
        }
      });
    }
  };
}
