import { TenantPaymentType } from '../../../api/types/common';

SelectedAccountViewCtrl.$inject = [
  '$scope',
  '$state',
  'companyAndAccountFactory',
  'staticDataFactory',
  '$q',
  'tenantFactory',
  'searchFactory',
  'modalFactory',
  'featureFlagFactory',
  'paymentsFactory',
  'notificationFactory'
];

/**
 *
 * @param {*} $scope
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {import("../../../factories/static_data.fac").IStaticDataFactory} staticDataFactory
 * @param {import("angular").IQService} $q
 * @param {*} tenantFactory
 * @param {*} searchFactory
 * @param {*} modalFactory
 * @param {*} featureFlagFactory
 * @param {*} paymentsFactory
 */
export function SelectedAccountViewCtrl(
  $scope,
  $state,
  companyAndAccountFactory,
  staticDataFactory,
  $q,
  tenantFactory,
  searchFactory,
  modalFactory,
  featureFlagFactory,
  paymentsFactory,
  notificationFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;
  $scope.companyAndAccountFactory = companyAndAccountFactory;
  $scope.paymentsFactory = paymentsFactory;

  $scope.selectedAccount = angular.copy(companyAndAccountFactory.dummyAccount);

  $scope.countries = {};
  $scope.currencies = [];
  $scope.distanceUnits = [];
  $scope.languages = {};
  $scope.timezones = [];

  $scope.selectedMarket = {};

  $scope.accountVerticals = [];

  $scope.dartBillingAccounts = {
    values: []
  };

  $scope.selectedDartBillingAccount = {
    value: null
  };

  $scope.autocompleteVars = {
    searchBrandText: ''
  };

  $scope.booleans = {
    hideBrandSelector: true
  };

  $scope.isAccountLevelCreditCardsEnabled =
    companyAndAccountFactory.selectedTenant?.payment_type === TenantPaymentType.ACCOUNT_LEVEL_CREDIT_CARD;

  $scope.selectedAccountTypeChange = function() {
    if ($scope.selectedAccount.accountType == 8) {
      $scope.booleans.hideBrandSelector = true;
    } else {
      $scope.booleans.hideBrandSelector = false;
    }
  };

  $scope.init = function() {
    $scope.$emit('accountLoadingProgress');
    notificationFactory.getNotifications(companyAndAccountFactory?.selectedAccountForOrg?.id);

    $q.all([
      staticDataFactory.getCountries(),
      staticDataFactory.getAccountVerticals(),
      // No need to get available DART billing accounts if the user doesn't even
      // have the feature to use account billing sources in the first place
      featureFlagFactory.isFeatureEnabled('ACCOUNT_BILLING_SOURCE_READ')
        ? staticDataFactory.getDartBillingAccounts()
        : Promise.resolve(null),
      $scope.isAccountLevelCreditCardsEnabled
        ? paymentsFactory.getAcountCards(companyAndAccountFactory.selectedAccountForOrg?.id)
        : Promise.resolve(null)
    ])
      .then(([countries, accountVerticals, dartBillingAccounts, attachedCard]) => {
        $scope.accountVerticals = accountVerticals;

        angular.copy(companyAndAccountFactory.selectedAccountForOrg, $scope.selectedAccount);

        $scope.countries = Object.values(countries);
        $scope.selectedCountry = $scope.countries.filter(
          country => country.code === $scope.selectedAccount.countryCode
        )[0];

        $scope.selectedAccountTypeChange();

        if (dartBillingAccounts) {
          $scope.dartBillingAccounts.values = angular.copy(dartBillingAccounts);
          $scope.selectedDartBillingAccount.value = staticDataFactory.setDartBillingAccount(
            $scope.dartBillingAccounts.values,
            $scope.selectedAccount
          );
          $scope.updateDartBillingSource();
        }

        if (attachedCard) {
          $scope.selectedCard = attachedCard;
        }
      })
      .finally(function() {
        $scope.$emit('accountLoadingEndProgress');
      });
  };

  $scope.searchBrands = function(query) {
    var filter = {
      targeting_search: 1,
      exclude: 0,
      entity_types: 'brand'
    };
    return searchFactory.getSearchResults(query, filter);
  };

  $scope.deleteAccount = function() {
    var modalSetting = {
      title: 'Delete Account?',
      message: 'Are you sure you want to delete this account?',
      noText: 'Cancel',
      yesText: 'Delete',
      yesClass: 'skin-red'
    };

    var handlers = {
      confirm: function() {
        return companyAndAccountFactory
          .deleteAccount($scope.selectedAccount)
          .then(
            function() {
              $scope.openCompany($scope.selectedCompany.id);
            },
            function(msg) {
              return msg;
            }
          )
          .finally(function() {
            $scope.paginateAccounts();
          });
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.selectCard = function(card, cardsLength) {
    if (
      cardsLength === 1 ||
      (cardsLength > 1 &&
        ![
          $scope.paymentsFactory.cardStatus.FAILED,
          $scope.paymentsFactory.cardStatus.PENDING,
          $scope.paymentsFactory.cardStatus.DISPUTE
        ].includes(card.status))
    ) {
      $scope.selectedCard = card;
    }
  };

  $scope.isCardStatusValid = function(card) {
    const invalidStatuses = [
      $scope.paymentsFactory.cardStatus.FAILED,
      $scope.paymentsFactory.cardStatus.PENDING,
      $scope.paymentsFactory.cardStatus.DISPUTE
    ];

    return !invalidStatuses.includes(card.status);
  };

  $scope.saveAccount = function() {
    $scope.errorMsg = '';
    if ($scope.selectedAccount.id) {
      $scope.saveProgress = true;
      const account = { ...$scope.selectedAccount, card_id: $scope.selectedCard?.card_id };
      companyAndAccountFactory
        .saveAccount(account)
        .then(
          function() {
            $scope.$emit('success-toast', { message: 'Account settings updated successfully.' });
          },
          function(response) {
            $scope.errorMsg = response;
          }
        )
        .finally(function() {
          $scope.paginateAccounts();
          $scope.saveProgress = false;
          notificationFactory.getNotifications($scope?.selectedAccount?.id);
        });
    }
  };

  $scope.$on('account.selected.settings.loaded', function() {
    $scope.init();
  });

  if ($scope.currentTab && $scope.currentTab.msg == 'account.selected.settings.loaded') {
    $scope.init();
  }

  $scope.updateDartBillingSource = function() {
    $scope.selectedAccount.dartBillingAccount = $scope.selectedDartBillingAccount.value;
  };
}
