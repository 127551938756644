import { NotificationTypes } from '../api/types/common';

/**
 * @typedef {import('../api/http/data.generated').GetNotificationsResponse} Notification
 * @param {ng.IQService} $q
 * @param {ng.IHttpService} $http
 * @param {import('ngstorage').ngStorage.StorageService} $sessionStorage
 * @param {import('ngstorage').ngStorage.StorageService} $localStorage
 * @ngInject
 */
export function notificationFactory($q, $http, $sessionStorage, $localStorage) {
  const notificationFactory = {};
  notificationFactory.notificationState = $sessionStorage.notificationState || {};
  Object.keys(NotificationTypes)
    .filter(t => isNaN(Number(t)))
    .forEach(notificationType => {
      if (!notificationFactory.notificationState[notificationType]) {
        notificationFactory.notificationState[notificationType] = { shouldShow: true };
      }
    });

  notificationFactory.notificationMap = {
    [NotificationTypes.NO_VALID_CREDIT_CARD]: 'NO_VALID_CREDIT_CARD',
    [NotificationTypes.PAUSED_ACCOUNT_FOUND]: 'PAUSED_ACCOUNT_FOUND',
    [NotificationTypes.VIEW_ONLY]: 'VIEW_ONLY',
    [NotificationTypes.SPEND_LIMIT_TENANT]: 'SPEND_LIMIT_TENANT',
    [NotificationTypes.REPORTING_ONLY]: 'REPORTING_ONLY',
    [NotificationTypes.NO_VALID_ACCOUNT_LEVEL_CREDIT_CARD]: 'NO_VALID_ACCOUNT_LEVEL_CREDIT_CARD',
    [NotificationTypes.EMAIL_NOT_ACTIVATED]: 'EMAIL_NOT_ACTIVATED'
  };

  notificationFactory.updateNotificationState = function(notificationType, condition) {
    if (notificationFactory.notificationState.hasOwnProperty(notificationType)) {
      notificationFactory.notificationState[notificationType].shouldShow = condition;
      $sessionStorage.notificationState = notificationFactory.notificationState;
    }
  };

  /**
   * @param {number} index
   */
  notificationFactory.closeNotification = function(index) {
    if (!notificationFactory.notifications) {
      return;
    }
    const notification = notificationFactory.notifications[index];
    if (
      notification?.type &&
      notificationFactory.notificationState[notificationFactory.notificationMap[notification.type]]
    ) {
      notificationFactory.notificationState[notificationFactory.notificationMap[notification.type]].shouldShow = false;
      $sessionStorage.notificationState = notificationFactory.notificationState;
    }
    notificationFactory.notifications.splice(index, 1);
  };

  notificationFactory.setNotifications = function(data) {
    if (!data) {
      return;
    }

    if (!$localStorage.hasOwnProperty('notification')) {
      $localStorage.notification = {};
    }

    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i].type === 0) {
        const newExpiredDate = new Date(data[i].expiry_date);

        if (!$localStorage.notification.hasOwnProperty(data[i].id)) {
          $localStorage.notification[data[i].id] = {
            show: true,
            expiry_date: newExpiredDate
          };
        }

        if (!$localStorage.notification[data[i].id].show && new Date() < newExpiredDate) {
          data.splice(i, 1);
        }
      }
    }
    notificationFactory.notifications = data;
    $sessionStorage.notificationState = notificationFactory.notificationState;
  };

  notificationFactory.getNotifications = function(accountId = null) {
    const d = $q.defer();

    $http
      .get('/data/notifications', {
        params: { account_id: accountId }
      })
      .then(function(response) {
        notificationFactory.setNotifications(response.data);
        d.resolve(notificationFactory.notifications);
      })
      .catch(function(response) {
        console.error(response.message);
        d.resolve();
      });

    return d.promise;
  };

  notificationFactory.isNotificationTypePresent = function(notificationType) {
    return notificationFactory.notifications?.some(notif => notif.type === notificationType);
  };

  return notificationFactory;
}
