import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from '../../../components/reactComponents/Button';
import { ModalBase } from '../../../components/reactComponents/Modal/ModalBase';
import { Account } from '../../../api/types/companiesAndAccounts';
import { Popover, makeStyles } from '@material-ui/core';
import { CreditCard } from './BillingPageView';
import { cardStatus } from './CardRow';
import { SearchBarV2 } from '../../../components/reactComponents';
import { valuesIn } from 'lodash';

interface AccountCreditCardModalProps {
  open: boolean;
  onConfirm: (accounts: number[], cardId: number) => void;
  onClose: (isOpen: boolean) => void;
  accounts: Account[];
  disableConfirm: boolean;
  card: CreditCard;
}

export function AccountCreditCardModal(props: AccountCreditCardModalProps) {
  const { accounts = [], onConfirm, open, onClose, disableConfirm, card } = props;
  const [connectedAccounts, setConnectedAccounts] = useState<Account[]>([]);
  const [notConnectedAccounts, setNotConnectedAccounts] = useState<Account[]>([]);
  const [accountsToConnect, setAccountsToConnect] = useState(new Set<number>());
  const [selectAll, setSelectAll] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [filteredConnectedAccounts, setFilteredConnectedAccounts] = useState<Account[]>([]);
  const [filteredNotConnectedAccounts, setFilteredNotConnectedAccounts] = useState<Account[]>([]);
  const [openedPopover, setOpenedPopover] = useState(false);

  const popoverAnchor = useRef(null);
  const popoverContainer = useRef(null);

  const classes = makeStyles(styles => ({
    popover: {
      pointerEvents: 'none'
    }
  }))();

  const handlePopoverOpen = () => {
    setOpenedPopover(true);
  };

  const handlePopoverClose = () => {
    setOpenedPopover(false);
  };

  useEffect(() => {
    if (open) {
      sortAccountsIntoConnectedLists();
    }
  }, [open]);

  useEffect(() => {
    setFilteredConnectedAccounts(connectedAccounts);
  }, [connectedAccounts]);

  useEffect(() => {
    setFilteredNotConnectedAccounts(notConnectedAccounts);
  }, [notConnectedAccounts]);

  const filterAccounts = (accounts, searchText) => {
    return accounts.filter(
      item =>
        item.id.toString().includes(searchText.trim()) ||
        item.name.toLowerCase().includes(searchText.trim().toLowerCase())
    );
  };

  const handleSearchChange = value => {
    setSearchVal(value);

    if (value === '') {
      setFilteredConnectedAccounts(connectedAccounts);
      setFilteredNotConnectedAccounts(notConnectedAccounts);
      return;
    }
    setFilteredConnectedAccounts(filterAccounts(connectedAccounts, value));
    setFilteredNotConnectedAccounts(filterAccounts(notConnectedAccounts, value));
  };

  const sortAccountsIntoConnectedLists = () => {
    const connectedAccounts: Account[] = [];
    const notConnectedAccounts: Account[] = [];
    accounts.forEach(account => {
      card.account_ids.includes(account.id) ? connectedAccounts.push(account) : notConnectedAccounts.push(account);
    });
    setConnectedAccounts(connectedAccounts);
    setNotConnectedAccounts(notConnectedAccounts);
  };

  const handleAccountChange = (checked: boolean, account) => {
    const accounts = new Set(accountsToConnect);
    checked ? accounts.add(account.id) : accounts.delete(account.id);
    setAccountsToConnect(accounts);
  };

  const handleConfirm = () => {
    onConfirm(card.account_ids.concat(Array.from(accountsToConnect)), card.card_id);
    onClose(false);
  };

  const getTotalSelected = () => {
    const selected = card.account_ids.length + accountsToConnect.size;
    return `${selected} Account${selected != 1 ? 's' : ''} Selected`;
  };

  const handleSelectAllChange = checked => {
    setSelectAll(checked);

    const accounts = new Set(accountsToConnect);
    notConnectedAccounts.forEach(account => {
      checked ? accounts.add(account.id) : accounts.delete(account.id);
    });
    setAccountsToConnect(accounts);
  };

  const getCheckboxLabel = account => {
    return (
      <div className="account__info">
        <div className="account__name">{account.name}</div>
        <div className="account__id">ID: {account.id}</div>
      </div>
    );
  };

  const getConfirmBtnPopoverContent = () => {
    const status = card.status === cardStatus.DISPUTE ? 'disputed' : card.status;
    return `Accounts cannot be added to a ${status.charAt(0).toUpperCase() + status.slice(1)} card.`;
  };

  const actionSection = (
    <>
      {disableConfirm ? (
        <>
          <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
            <PrimaryButton
              ref={popoverAnchor}
              title="Confirm"
              disabled={disableConfirm}
              className="button-v2 button--green"
            />
          </div>
          <Popover
            className={classes.popover}
            open={openedPopover}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            anchorEl={popoverAnchor.current}
            container={popoverContainer.current}
          >
            <div className="confirm-btn-popover-content">{getConfirmBtnPopoverContent()}</div>
          </Popover>
        </>
      ) : (
        <PrimaryButton title="Confirm" onClick={handleConfirm} className="button-v2 button--green" />
      )}
      <PrimaryButton title="Cancel" onClick={() => onClose(false)} className="button-v2 button--tertiary-green" />
    </>
  );
  return (
    <ModalBase
      open={open}
      title="Connect Accounts to the Payment Method"
      actionSection={actionSection}
      className="accounts-cc-modal"
    >
      <button type="button" className="close-modal-button" onClick={() => onClose(false)}>
        <span className="cancel-icon"></span>
      </button>
      <div className="accounts-cc-dialog">
        <div className="accounts-cc-dialog__selected">{getTotalSelected()}</div>
        <SearchBarV2 searchValue={searchVal} onSearch={handleSearchChange} classname="search_account_field" />
        {filteredNotConnectedAccounts.length > 0 && (
          <div className="accounts__select_all multiselect-checkbox">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={e => handleSelectAllChange(e.target.checked)}
              name="select-all"
            />
            <label htmlFor="select-all">Select All</label>
          </div>
        )}
        <div className="accounts-cc-dialog__account_lists">
          {filteredConnectedAccounts.length > 0 && (
            <ul className="connected-accounts">
              {filteredConnectedAccounts.map(account => {
                return (
                  <li key={account.id}>
                    <div className="multiselect-checkbox">
                      <input type="checkbox" checked={true} disabled name={`account-${account.id}`} />
                      <label htmlFor={`account-${account.id}`}>{getCheckboxLabel(account)}</label>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {filteredConnectedAccounts.length > 0 && filteredNotConnectedAccounts.length > 0 && (
            <hr className="divider" />
          )}
          {filteredNotConnectedAccounts.length > 0 && (
            <ul className="not-connected-accounts">
              {filteredNotConnectedAccounts.map(account => {
                return (
                  <li key={account.id}>
                    <div className="multiselect-checkbox">
                      <input
                        type="checkbox"
                        checked={accountsToConnect.has(account.id)}
                        name={`account-${account.id}`}
                        onChange={e => handleAccountChange(e.target.checked, account)}
                      />
                      <label htmlFor={`account-${account.id}`}>{getCheckboxLabel(account)}</label>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {!filteredNotConnectedAccounts.length && !filteredConnectedAccounts.length && (
            <div className="no-accounts-found">No Result(s) Found</div>
          )}
        </div>
      </div>
    </ModalBase>
  );
}

AccountCreditCardModal.propTypes = {
  accounts: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  disableConfirm: PropTypes.bool,
  card: PropTypes.object
};
