import { $http } from 'ngimport';
import { send$http } from '../../../api/def/ngExecutor';
import { TenantHasCampaigns } from '../../../api/http/campaigns';
import GAEventList from '../../../components/googleAnalytics/eventList';

/**
 * @param {ng.IQService} $q
 * @param {import('@uirouter/angularjs').TransitionService} $transitions
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {ng.ILocationService} $location
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {ng.IFilterService} $filter
 * @param {ng.ITimeoutService} $timeout
 * @param {import('../../../factories/types').UserFactoryService} userFactory
 * @param {ng.IWindowService} $window
 * @ngInject
 */
export function CampaignDashboardController(
  $rootScope,
  $scope,
  $q,
  $transitions,
  campaignManagerFactory,
  companyAndAccountFactory,
  $location,
  $state,
  $filter,
  $timeout,
  modalFactory,
  userFactory,
  $window,
  featureFlagFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;

  $scope.campaign_not_found = false;
  $scope.dashboard_ready = false;
  $scope.booleans = {
    tableChanged: false,
    newCampaignProgress: false,
    actionInProgress: false,
    showPlayButton: false,
    showPauseButton: false,
    showCloneButton: true,
    showEditButton: true,
    showExportButton: false,
    showRetractButton: false,
    selectionProgress: false
  };

  $scope.errorMsg = '';
  $scope.campaignManagerFactory = campaignManagerFactory;

  $scope.campaignManagerFactory.campaignsLoading = false;

  const transitionStartHandler = $transitions.onStart({}, t => {
    const toState = t.to(),
      fromState = t.from();
    if (
      toState.name === fromState.name ||
      (toState.name === 'campaigns.opsDashboard' && !(toState.data && toState.data.isAllowed))
    ) {
      $scope.campaignManagerFactory.campaignsLoading = false;
    } else {
      $scope.campaignManagerFactory.campaignsLoading = true;
    }
  });

  const transitionSuccessHandler = $transitions.onSuccess({}, () => {
    $scope.campaignManagerFactory.campaignsLoading = false;
  });

  $scope.tableClasses = ['scrollable-table dashboard-table'];

  $scope.tableConfig = {
    tableData: [],
    header: [
      { name: 'Campaign ID', hide: false, field: 'id', components: [{ field: 'id' }] },
      { name: 'Campaign Name', hide: false, field: 'name', components: [{ field: 'name' }] },
      {
        name: 'Status',
        hide: false,
        field: 'status',
        components: [{ field: 'status', getCSSClass: getStatusCSSClass }]
      },
      { name: 'Pacing', hide: false, field: 'pacing', components: [{ field: 'pacing', filter: 'percentage' }] },
      {
        name: 'Total Spent',
        hide: false,
        field: 'spends_spent',
        components: [{ field: 'spentWithCurrencyCode', exportField: 'spends_spent', exportFilter: 'number' }]
      },
      {
        name: 'Spent Today',
        hide: false,
        field: 'spends_daily_spent',
        components: [{ field: 'dailySpentWithCurrencyCode', exportField: 'spends_daily_spent', exportFilter: 'number' }]
      },
      {
        name: 'Budget',
        hide: false,
        field: 'totalBudget',
        components: [{ field: 'totalBudgetWithCurrencyCode', exportField: 'totalBudget', exportFilter: 'number' }]
      },
      // {name: 'Impressions', hide: false, field: 'impression', components: [{field: 'impression', filter: 'number'}]},
      { name: 'Days Remaining', hide: false, field: 'timeframe_remain', components: [{ field: 'timeframe_remain' }] },
      {
        name: 'Salesforce Number',
        hide: false,
        field: 'salesforceNumber',
        components: [{ field: 'salesforceNumber' }]
      },
      {
        name: 'Start',
        hide: false,
        field: 'timeframe_start_string',
        components: [{ field: 'timeframe_start_string' }]
      },
      { name: 'End', hide: false, field: 'timeframe_end_string', components: [{ field: 'timeframe_end_string' }] }
    ],
    tableName: 'campaignDashboard',
    paginationTrigger: angular.noop,
    rowTrigger: function(item) {
      $scope.errorMsg = null;
      $scope.openCampaignActionSheet(item);
      $scope.highlightedRow = item;
    },
    extraRowTrigger: function(item) {
      $scope.selectCampaign(item);
    },
    emptyTableMessage: 'There are no campaigns that match the selected filters.'
  };

  function getStatusCSSClass(status) {
    const rowStatus = status.toLowerCase();
    if (['active', 'pending', 'paused', 'expired', 'draft', 'submitted', 'rejected'].includes(rowStatus)) {
      return `status ${rowStatus}-status`;
    }
    return '';
  }

  $scope.tableOpsConfig = {
    tableData: [],
    header: [
      { name: '', hide: false, field: 'checkbox', components: [{ field: 'checkbox' }] },
      { name: 'ID', hide: false, field: 'id', components: [{ field: 'id' }] },
      { name: 'Order Name', hide: false, field: 'name', components: [{ field: 'name' }] },
      {
        name: 'Status',
        hide: false,
        field: 'status',
        components: [{ field: 'status', getCSSClass: getStatusCSSClass }]
      },
      { name: 'Tenant Name', hide: false, field: 'tenant_name', components: [{ field: 'tenant_name' }] },
      { name: 'Submission Date', hide: false, field: 'submissionDate', components: [{ field: 'submissionDate' }] }
    ],
    tableName: 'campaignOpsDashboard',
    paginationTrigger: angular.noop,
    rowTrigger: function(item) {
      $scope.errorMsg = null;
      $scope.openCampaignActionSheet(item);
      $scope.highlightedRow = item;
    },
    extraRowTrigger: function(item) {
      $scope.selectOpsCampaign(item);
    },
    emptyTableMessage: 'There are no campaigns that match the selected filters.'
  };

  $scope.tableClientConfig = {
    tableData: [],
    header: [
      { name: '', hide: false, field: 'checkbox', components: [{ field: 'checkbox' }] },
      { name: 'ID', hide: false, field: 'id', components: [{ field: 'id' }] },
      { name: 'Order Name', hide: false, field: 'name', components: [{ field: 'name' }] },
      {
        name: 'Status',
        hide: false,
        field: 'status',
        components: [{ field: 'status', getCSSClass: getStatusCSSClass }]
      }
    ],
    tableName: 'campaignClientDashboard',
    paginationTrigger: angular.noop,
    rowTrigger: function(item) {
      $scope.errorMsg = null;
      $scope.openCampaignActionSheet(item);
      $scope.highlightedRow = item;
    },
    extraRowTrigger: function(item) {
      $scope.selectCampaign(item);
    },
    emptyTableMessage: 'There are no campaigns that match the selected filters.'
  };

  $scope.checkedIds = [];

  var filters = {
    showAll: {
      value: '',
      name: 'All'
    },
    pending: {
      value: 'Pending',
      name: 'Pending'
    },
    active: {
      value: 'Active',
      name: 'Active'
    },
    paused: {
      value: 'Paused',
      name: 'Paused'
    },
    expired: {
      value: 'Expired',
      name: 'Expired'
    },
    submitted: {
      value: 'Submitted',
      name: 'Submitted'
    },
    rejected: {
      value: 'Rejected',
      name: 'Rejected'
    },
    draft: {
      value: 'Draft',
      name: 'Draft'
    }
  };

  $scope.dashboardFilters = [filters.showAll, filters.pending, filters.active, filters.paused, filters.expired];
  $scope.channelFilters = [
    filters.showAll,
    filters.draft,
    filters.submitted,
    filters.pending,
    filters.active,
    filters.paused,
    filters.expired,
    filters.rejected
  ];

  $scope.$on('dashboard-table-update', function() {
    $timeout(function() {
      formatDates(campaignManagerFactory.livecampaigns);
      formatCurrencyData(campaignManagerFactory.livecampaigns);
      $scope.booleans.tableChanged = true;
    });
  });

  $scope.init = function() {
    if ($state.params.campaignId) {
      $scope.campaign_not_found = true;
      return;
    } else {
      $scope.campaignManagerFactory = campaignManagerFactory;
      $scope.companyAndAccountFactory = companyAndAccountFactory;

      var dashboardTile = $('.campaignItem').first();
      var tileWidth, tileHeight;

      if (dashboardTile.length) {
        tileWidth = dashboardTile.outerWidth();
        tileHeight = dashboardTile.outerHeight();
      } else {
        // shouldn't ever get here
        tileWidth = 270;
        tileHeight = 231;
      }

      formatDates(campaignManagerFactory.livecampaigns);

      // initially load enough campaign tiles to fill 2 viewports
      $scope.numCampaignsToShow =
        2 *
        Math.floor($('#main-content-frame').width() / tileWidth) *
        Math.floor($('#main-content-frame').height() / tileHeight);
      $scope.dashboard_ready = true;

      // Get selected account so we know currency code
      companyAndAccountFactory.getSelectedAccount().then(
        function(data) {
          $scope.currencyCode = data && data.currency ? data.currency : null;
          formatCurrencyData(campaignManagerFactory.livecampaigns);
          $scope.booleans.tableChanged = true;
        },
        function() {
          console.warn('Failed to load selected account.');
        }
      );

      const filterStatus = $scope.currentState === 'campaigns.opsDashboard' ? 'Submitted' : 'Active';
      $scope.filterCampaignsByStatus(filterStatus);

      if ($state?.params?.createCampaign) {
        if (campaignManagerFactory.livecampaigns?.length === 0) {
          send$http($http, TenantHasCampaigns)
            .then(res => handleNewSignUp(res.tenant_has_campaigns))
            .catch(e => console.error(e));
        }
      }
    }
  };

  function handleNewSignUp(tenantHasCampaigns) {
    if (!tenantHasCampaigns) {
      $scope.openNewCampaign();
    }
  }

  $scope.clearSearch = () => {
    campaignManagerFactory.searchCampaign = '';
    $scope.currentState === 'campaigns.opsDashboard'
      ? campaignManagerFactory.updateFilteredChannelCampaigns()
      : campaignManagerFactory.updateFilteredCampaigns();
    $scope.booleans.tableChanged = true;
  };

  $scope.selectCampaign = function(campaign) {
    $state.go('campaigns.campaign', { campaignId: campaign.id });
  };

  $scope.selectOpsCampaign = function(item) {
    $scope.booleans.selectionProgress = true;
    $scope.booleans.actionInProgress = true;
    var d = $q.defer();
    if (item.tenant_id === companyAndAccountFactory.selectedTenant?.tenant_id) {
      if (
        item.company_id === companyAndAccountFactory.selectedCompany?.id &&
        item.account_id === companyAndAccountFactory.selectedAccount?.id
      ) {
        d.resolve();
        $state.go('campaigns.campaign', { campaignId: item.id });
        $scope.booleans.selectionProgress = false;
        $scope.booleans.actionInProgress = false;
      } else {
        companyAndAccountFactory
          .getCompaniesAndAccounts(item.tenant_id, item.company_id, item.account_id)
          .then(function() {
            $rootScope.$broadcast('updateTenantCompanyAccount', item.tenant_id);
            campaignManagerFactory.clean();
            campaignManagerFactory.loadCampaignsWithBudgetsForAccount().then(function() {
              d.resolve();
              campaignManagerFactory.selectedCampaign = campaignManagerFactory.selectCampaignWithId(item.id);
              $state.go('campaigns.campaign', { campaignId: item.id });
              $scope.$broadcast('campaign-report-update');
              $scope.booleans.selectionProgress = false;
              $scope.booleans.actionInProgress = false;
            });
          });
      }
    } else {
      companyAndAccountFactory.clean();
      userFactory.clean();
      companyAndAccountFactory
        .getCompaniesAndAccounts(item.tenant_id, item.company_id, item.account_id)
        .then(function() {
          $rootScope.$broadcast('updateTenantCompanyAccount', item.tenant_id);
          campaignManagerFactory.clean();
          campaignManagerFactory.loadCampaignsWithBudgetsForAccount().then(function() {
            d.resolve();
            campaignManagerFactory.selectedCampaign = campaignManagerFactory.selectCampaignWithId(item.id);
            $state.go('campaigns.campaign', { campaignId: item.id });
            $scope.$broadcast('campaign-report-update');
            $scope.booleans.selectionProgress = false;
            $scope.booleans.actionInProgress = false;
          });
        });
    }
  };

  $scope.filterCampaignsByStatus = function(status) {
    $scope.checkedIds.length = 0;
    if ($scope.tableOpsConfig.header[0].hasOwnProperty('checked')) {
      $scope.tableOpsConfig.header[0].checked = false;
    }
    if ($scope.tableClientConfig.header[0].hasOwnProperty('checked')) {
      $scope.tableClientConfig.header[0].checked = false;
    }
    if ($scope.currentState === 'campaigns.opsDashboard') {
      campaignManagerFactory.updateFilteredChannelCampaigns(status);
    } else {
      campaignManagerFactory.updateFilteredCampaigns(status);
    }
    $scope.booleans.tableChanged = true;
  };

  $scope.openNewCampaign = function() {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaign_create_modal.html',
      controller: 'NewCampaignInstanceController',
      size: 'xl'
    };

    var handlers = {
      submitFn: $scope.createNewCampaign
    };

    modalFactory.createModal(modalOptions, null, handlers);
  };

  $scope.createNewCampaign = function(obj) {
    return campaignManagerFactory.createCampaign(obj).then(function(campaign) {
      if (obj.isBulkUpload) {
        $state.go('campaigns.campaign', { campaignId: campaign.id, isBulkUpload: obj.isBulkUpload });
      } else {
        // We have created a campaign, logging this event into GA
        typeof $window.gtag === 'function' &&
          $window.gtag('event', GAEventList.CAMPAIGN_START, {
            adgroup_id: campaign.adGroups[0].id,
            campaign_id: campaign.id,
            gt_user_id: window?.user?.id,
            tenant_id: companyAndAccountFactory.selectedTenant?.tenant_id
          });
        $state.go('campaigns.adgroup', { campaignId: campaign.id, adGroupId: campaign.adGroups[0].id });
      }
    });
  };

  $scope.editCampaign = function() {
    if ($scope.currentState === 'campaigns.opsDashboard') {
      $scope.selectOpsCampaign($scope.highlightedRow);
    } else {
      $state.go('campaigns.campaign', { campaignId: $scope.selectedCampaign.id });
      campaignManagerFactory.selectCampaign($scope.selectedCampaign);
    }
  };

  $scope.deleteCampaign = function() {
    var modalSetting = {
      title: 'Would you like to delete campaign ' + $scope.selectedCampaign.name + '?',
      message: 'All of the ad groups will be removed. Are you sure?',
      noText: 'Cancel',
      yesText: 'Delete',
      yesClass: 'skin-red'
    };

    var handlers = {
      confirm: function() {
        $scope.booleans.actionInProgress = true;
        return campaignManagerFactory
          .deleteCampaign($scope.selectedCampaign)
          .then(
            function() {
              $scope.$broadcast('dashboard-table-update');
              $scope.booleans.tableChanged = true;
              $scope.errorMsg = '';
            },
            function(msg) {
              $scope.errorMsg = msg;
            }
          )
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.deleteCampaigns = function() {
    var checkedNames = function(checkedIds) {
      var campaignNames = [];
      for (var id of checkedIds) {
        campaignNames.push(campaignManagerFactory.selectFromCampaignsWithId(id).name);
      }
      return campaignNames;
    };

    var modalSetting = {
      title: 'Would you like to delete these campaigns?',
      list: checkedNames($scope.checkedIds),
      message: 'All of the ad groups will be removed. Are you sure?',
      noText: 'Cancel',
      yesText: 'Delete',
      yesClass: 'skin-red'
    };

    var handlers = {
      confirm: function() {
        $scope.booleans.actionInProgress = true;
        return campaignManagerFactory
          .deleteCampaigns($scope.checkedIds)
          .then(
            function() {
              $scope.$broadcast('dashboard-table-update');
              $scope.booleans.tableChanged = true;
              $scope.errorMsg = '';
              $scope.checkedIds = [];
            },
            function(msg) {
              $scope.errorMsg = msg;
            }
          )
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.pauseCampaign = function() {
    $scope.booleans.actionInProgress = true;
    campaignManagerFactory
      .changeCampaignStatus($scope.selectedCampaign, 'Paused')
      .then(
        function() {
          $scope.$broadcast('dashboard-table-update');
          $scope.openCampaignActionSheet(campaignManagerFactory.selectFromCampaignsWithId($scope.selectedCampaign.id));
          $scope.errorMsg = '';
        },
        function(msg) {
          $scope.errorMsg = msg;
        }
      )
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  };

  $scope.downloadSpreadsheet = function() {
    $scope.booleans.actionInProgress = true;
    campaignManagerFactory
      .downloadCampaignSpreadsheet($scope.checkedIds)
      .then(
        function() {
          $scope.booleans.tableChanged = true;
          $scope.errorMsg = '';
        },
        function(msg) {
          $scope.errorMsg = msg;
        }
      )
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  };

  $scope.downloadCampaignPDF = function() {
    $scope.booleans.actionInProgress = true;
    campaignManagerFactory
      .downloadCampaignPDF($scope.selectedCampaign.id)
      .then(
        function() {
          $scope.booleans.tableChanged = true;
          $scope.errorMsg = '';
        },
        function(msg) {
          $scope.errorMsg = msg;
        }
      )
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  };

  $scope.activateCampaign = function() {
    $scope.booleans.actionInProgress = true;
    campaignManagerFactory
      .changeCampaignStatus($scope.selectedCampaign, campaignManagerFactory.statusNames.ACTIVE)
      .then(
        function() {
          $scope.$broadcast('dashboard-table-update');
          $scope.openCampaignActionSheet(campaignManagerFactory.selectFromCampaignsWithId($scope.selectedCampaign.id));
          $scope.errorMsg = '';
        },
        function(msg) {
          $scope.errorMsg = msg;
        }
      )
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  };

  $scope.retractCampaign = function() {
    $scope.booleans.actionInProgress = true;
    campaignManagerFactory
      .changeCampaignStatus($scope.selectedCampaign, campaignManagerFactory.statusNames.DRAFT)
      .then(
        function() {
          $scope.$broadcast('dashboard-table-update');
          $scope.openCampaignActionSheet(campaignManagerFactory.selectFromCampaignsWithId($scope.selectedCampaign.id));
          $scope.errorMsg = '';
        },
        function(msg) {
          $scope.errorMsg = msg;
        }
      )
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  };

  $scope.cloneCampaign = function() {
    var modalSetting = {
      title: 'Duplicate campaign?',
      message: 'Cloning a campaign will create a new campaign with all of the attributes of the original.',
      noText: 'Cancel',
      yesText: 'Duplicate'
    };

    var handlers = {
      confirm: function() {
        $scope.booleans.actionInProgress = true;
        return campaignManagerFactory
          .cloneCampaign($scope.selectedCampaign)
          .then(
            function() {
              $scope.$broadcast('dashboard-table-update');
              $scope.booleans.tableChanged = true;
              $scope.errorMsg = '';
            },
            function(msg) {
              $scope.errorMsg = msg;
            }
          )
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.actionSheetCallback = function(type) {
    if (type === 'activate') {
      $scope.activateCampaign();
    } else if (type === 'pause') {
      $scope.pauseCampaign();
    } else if (type === 'edit') {
      $scope.editCampaign();
    } else if (type === 'clone') {
      $scope.cloneCampaign();
    } else if (type === 'delete') {
      $scope.deleteCampaign();
    } else if (type === 'retract') {
      $scope.retractCampaign();
    } else if (type === 'download') {
      $scope.downloadCampaignPDF();
    }
  };

  $scope.openCampaignActionSheet = function(campaign) {
    if (
      !campaign ||
      (companyAndAccountFactory.selectedAccount?.app_used !== 'MPCB' &&
        !featureFlagFactory.isFeatureEnabled('CAMPAIGN_OPS_READ'))
    ) {
      $scope.selectedCampaign = null;
      return;
    }
    $scope.selectedCampaign = campaign;

    $scope.booleans.showEditButton = true;
    $scope.booleans.showPauseButton = true;
    $scope.booleans.showCloneButton = true;
    $scope.booleans.showPlayButton = true;
    $scope.booleans.showExportButton = true;
    $scope.booleans.showRetractButton = false;

    validateCampaignStatus();
  };

  function validateCampaignStatus() {
    if ($scope.selectedCampaign.status !== 'Paused') {
      $scope.booleans.showPlayButton = false;
    }

    if ($scope.selectedCampaign.status !== 'Active' && $scope.selectedCampaign.status !== 'Pending') {
      $scope.booleans.showPauseButton = false;
    }

    if (featureFlagFactory.isFeatureEnabled('CAMPAIGN_EXPORT_CHANNEL')) {
      $scope.booleans.showExportButton = true;
    }

    // only availible if client and status is Submitted
    if (
      featureFlagFactory.isFeatureEnabled('SHOW_SUBMIT_OR_RETRACT_BUTTON') &&
      $scope.selectedCampaign.status === 'Submitted'
    ) {
      $scope.booleans.showRetractButton = true;
    }
  }

  $scope.loadMoreCampaigns = function(num) {
    num = num || 10;
    $scope.numCampaignsToShow += num;
  };

  function formatDates(arr) {
    var start;
    for (var i = 0; i < arr.length; i++) {
      start = new Date(arr[i].timeframe.start);
      arr[i].timeframe_start_string = start.toISOString().substring(0, 10);

      if (arr[i].timeframe.end) {
        arr[i].timeframe_end_string = new Date(arr[i].timeframe.end).toISOString().substring(0, 10);
        arr[i].timeframe_remain =
          arr[i].timeframe.remain <= 0 ? 0 : (arr[i].timeframe.remain / 60 / 60 / 24).toFixed(0);
      } else {
        arr[i].timeframe_end_string = null;
        arr[i].timeframe_remain = null;
      }
    }
  }

  function formatCurrencyData(data) {
    for (var i = 0; i < data.length; i++) {
      var campaign = data[i];

      campaign.totalBudgetWithCurrencyCode =
        campaign.totalBudget != null
          ? $filter('isoCurrency')(campaign.totalBudget, $scope.currencyCode)
          : campaign.totalBudget;

      campaign.spentWithCurrencyCode = '--';
      campaign.dailySpentWithCurrencyCode = '--';
      if (campaign.hasOwnProperty('spends_spent')) {
        campaign.spentWithCurrencyCode = $filter('isoCurrency')(campaign.spends_spent, $scope.currencyCode);
        campaign.dailySpentWithCurrencyCode = $filter('isoCurrency')(campaign.spends_daily_spent, $scope.currencyCode);
      }
    }
  }

  $scope.init();

  this.$onDestroy = () => {
    transitionStartHandler();
    transitionSuccessHandler();
  };
}
