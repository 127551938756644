export function parseHour() {
  return parseHourFilter;

  function parseHourFilter(hour) {
    if (hour === 0) {
      return '12am';
    } else if (hour === 12) {
      return '12pm';
    } else if (hour > 12) {
      return hour - 12 + 'pm';
    } else {
      return hour + 'am';
    }
  }
}
