import { send$http } from '../api/def/ngExecutor';
import { getCompaniesAndAccounts } from '../api/http';

companyAndAccountFactory.$inject = ['$http', '$q', '$rootScope', 'featureFlagFactory'];

/**
 *
 * @param {ng.IHttpService} $http
 * @param {ng.IQService} $q
 * @param {ng.IRootScopeService} $rootScope
 * @param {*} featureFlagFactory
 * @return {import('./types').CompanyAndAccountFactoryService}
 */
export function companyAndAccountFactory($http, $q, $rootScope, featureFlagFactory) {
  /** @type {import('./types').CompanyAndAccountFactoryService} */
  var companyAndAccountFactory = {};
  companyAndAccountFactory.companiesAccounts = [];
  companyAndAccountFactory.tenants = [];
  // @ts-ignore
  companyAndAccountFactory.selectedTenant = {};
  companyAndAccountFactory.is_holding_company = false;
  companyAndAccountFactory.selection = 'CompanyView';
  companyAndAccountFactory.allAccounts = [];
  // currently selected company and account only for Organization section
  companyAndAccountFactory.selectedCompanyForOrg = null;
  companyAndAccountFactory.selectedAccountForOrg = null;

  // CURRENTLY SELECTED COMPANY AND ACCOUNT FOR ENTIRE APPLICATION
  companyAndAccountFactory.selectedCompany = null;
  companyAndAccountFactory.selectedAccount = null;

  companyAndAccountFactory.fullyManagedAccountTypes = [
    { label: 'Direct Client', value: 0 },
    { label: 'Direct Agency', value: 1 },
    { label: 'Direct Programmatic', value: 5 },
    { label: 'Programmatic', value: 7 }
  ];

  companyAndAccountFactory.accountTypes = [
    ...companyAndAccountFactory.fullyManagedAccountTypes,
    { label: 'Platform CMR', value: 2 },
    { label: 'Platform Reseller', value: 3 },
    { label: 'Platform Channel', value: 4 },
    { label: 'Platform Programmatic', value: 6 },
    { label: 'Self-Serve', value: 8 },
    { label: 'Charity', value: 9 }
  ];

  companyAndAccountFactory.dummyAccount = {
    id: null,
    name: null,
    adomain: null,
    accountType: null,
    countryCode: 'US',
    timezone: '',
    distanceUnit: '',
    currency: '',
    language: '',
    brand: null,
    dartBillingAccount: null,
    maxTierBid: 30,
    isCpvEligible: null,
    suggestedBidPrice: null
  };

  // cache result of latest call to
  /** @type {undefined | import('../api/types/companiesAndAccounts').Response} */
  companyAndAccountFactory.companiesAndAccountsResponse = undefined;
  companyAndAccountFactory.getCompaniesAndAccounts = /**
   * @param {number} [tenantId]
   * @param {number} [companyId]
   * @param {number} [accountId]
   * @param {number} [campaignId]
   */ async function(tenantId, companyId, accountId, campaignId) {
    tenantId = !tenantId ? companyAndAccountFactory.selectedTenant?.tenant_id : Number(tenantId);
    if (companyId) companyId = Number(companyId);
    if (accountId) accountId = Number(accountId);
    if (campaignId) campaignId = Number(campaignId);
    if (
      companyAndAccountFactory.companiesAndAccountsResponse &&
      companyAndAccountFactory.selectedTenant &&
      companyAndAccountFactory.selectedTenant.tenant_id === tenantId &&
      companyAndAccountFactory.companiesAndAccountsResponse.tenant_id === tenantId &&
      companyAndAccountFactory.selectedAccount &&
      companyAndAccountFactory.selectedAccount.id === accountId &&
      companyAndAccountFactory.companiesAndAccountsResponse.selected_account_id === accountId
    ) {
      return companyAndAccountFactory.companiesAndAccountsResponse;
    }

    await companyAndAccountFactory.getTenants();

    const data = await send$http($http, getCompaniesAndAccounts, {
      params: { tenant_id: tenantId, company_id: companyId, account_id: accountId, campaign_id: campaignId }
    });

    if (companyAndAccountFactory.selectedAccount?.id) {
      this.updateLastUsedAccount(companyAndAccountFactory.selectedAccount.id);
    }

    let i, j;
    angular.copy(data.companies, companyAndAccountFactory.companiesAccounts);
    companyAndAccountFactory.allAccounts = getAllAccounts(data.companies);
    // @ts-ignore
    companyAndAccountFactory.selectedTenant = companyAndAccountFactory.selectTenant(data.tenant_id);
    if (companyAndAccountFactory.selectedTenant === undefined) {
      console.log("You are going to see error 'tenant_id of undefined'");
    }
    if (data.selected_company_id) {
      for (i = 0; i < companyAndAccountFactory.companiesAccounts.length; i++) {
        if (companyAndAccountFactory.companiesAccounts[i].id == data.selected_company_id) {
          companyAndAccountFactory.selectedCompany = companyAndAccountFactory.companiesAccounts[i];
          if (data.selected_account_id) {
            for (j in companyAndAccountFactory.companiesAccounts[i].accounts) {
              if (companyAndAccountFactory.companiesAccounts[i].accounts[j].id == data.selected_account_id) {
                companyAndAccountFactory.selectedAccount = companyAndAccountFactory.companiesAccounts[i].accounts[j];
                if (companyAndAccountFactory.selectedAccount.countryCode === 'US') {
                  companyAndAccountFactory.setSuggestedBidPrice().catch(err => console.log(err));
                }
                break;
              }
            }
          } else {
            companyAndAccountFactory.selectedAccount = null;
          }
        }
      }
    } else {
      companyAndAccountFactory.clean();
    }

    companyAndAccountFactory.is_holding_company = !!data.is_holding_company;
    if (data.checksum !== null) {
      window.checksum = data.checksum;
    }

    companyAndAccountFactory.companiesAndAccountsResponse = data;

    return data;
  };

  companyAndAccountFactory.requireSelectedTenant = () => {
    if (!companyAndAccountFactory.selectedTenant) {
      throw new Error('No selected tenant');
    }
    return companyAndAccountFactory.selectedTenant;
  };

  function getAllAccounts(companies) {
    return companies.flatMap(company => company.accounts);
  }
  companyAndAccountFactory.selectFromCompaniesAndAccounts = function(companyId, accountId) {
    if (companyAndAccountFactory.companiesAccounts.length) {
      var company = companyAndAccountFactory.getCompany(companyAndAccountFactory.companiesAccounts, companyId);
      var account;
      if (company) {
        companyAndAccountFactory.selectedCompany = company;
        account = companyAndAccountFactory.getAccount(company, accountId);
      }
      if (account) {
        companyAndAccountFactory.selectedAccount = account;
      }
    }
  };

  companyAndAccountFactory.clean = function() {
    companyAndAccountFactory.selectedCompany = null;
    companyAndAccountFactory.selectedAccount = null;
    companyAndAccountFactory.companiesAccounts.length = 0;
  };

  companyAndAccountFactory.selectFromCompaniesAndAccountsForOrg = function(companyId, accountId) {
    if (companyAndAccountFactory.companiesAccounts.length) {
      var company = companyAndAccountFactory.getCompany(companyAndAccountFactory.companiesAccounts, companyId);
      var account;
      if (company) {
        companyAndAccountFactory.selectedCompanyForOrg = company;
        account = companyAndAccountFactory.getAccount(company, accountId);
      }

      if (account) {
        companyAndAccountFactory.selectedAccountForOrg = account;
      }
    }
  };

  companyAndAccountFactory.requireSelectedCompany = function() {
    if (!companyAndAccountFactory.selectedCompany) {
      throw new Error('No selected company');
    }
    return companyAndAccountFactory.selectedCompany;
  };

  companyAndAccountFactory.getCompany = function(companies, id) {
    for (var i = 0; i < companies.length; i++) {
      //we support id == undefined here so that, /account route will work even with param CompanyId is passed.
      if (id == undefined || companies[i].id == id) {
        return companies[i];
      }
    }
  };

  companyAndAccountFactory.getAccount = function(company, id) {
    for (var i = 0; i < company.accounts.length; i++) {
      if (company.accounts[i].id == id) {
        return company.accounts[i];
      }
    }
  };

  companyAndAccountFactory.getSelectedAccount = function() {
    var d = $q.defer();
    if (companyAndAccountFactory.selectedAccount) {
      if (companyAndAccountFactory.selectedAccount.countryCode === 'US') {
        companyAndAccountFactory.setSuggestedBidPrice().catch(err => console.log(err));
      }
      d.resolve(companyAndAccountFactory.selectedAccount);
    } else {
      companyAndAccountFactory.getCompaniesAndAccounts().then(function() {
        d.resolve(companyAndAccountFactory.selectedAccount);
      });
    }
    return d.promise;
  };

  companyAndAccountFactory.requireSelectedAccount = () => {
    if (!companyAndAccountFactory.selectedAccount) {
      throw new Error('No selected account');
    }
    return companyAndAccountFactory.selectedAccount;
  };

  companyAndAccountFactory.getAccountsForCompany = function(companyId) {
    var companies = companyAndAccountFactory.companiesAccounts;
    for (var i = 0; i < companies.length; i++) {
      if (companies[i].id == companyId) {
        return companies[i].accounts;
      }
    }
  };

  companyAndAccountFactory.saveAccount = function(account) {
    var d = $q.defer();
    $http({
      method: 'PUT',
      url: '/data/company/' + companyAndAccountFactory.selectedCompanyForOrg?.id + '/account/' + account.id,
      data: account
    }).then(
      function successCallback(response) {
        angular.copy(response.data[0].accounts[0], companyAndAccountFactory.selectedAccountForOrg);
        if (
          companyAndAccountFactory.selectedAccount &&
          companyAndAccountFactory.selectedAccountForOrg?.id === companyAndAccountFactory.selectedAccount.id
        ) {
          companyAndAccountFactory.selectedAccount = companyAndAccountFactory.selectedAccountForOrg;
          $rootScope.$broadcast('updateTenantCompanyAccount', companyAndAccountFactory.selectedTenant.tenant_id);
        }
        d.resolve(response);
      },
      function errorCallback(response) {
        d.reject(response.data.message);
      }
    );
    return d.promise;
  };

  companyAndAccountFactory.deleteAccount = function(account) {
    var d = $q.defer();
    $http({
      method: 'DELETE',
      url: '/data/company/' + companyAndAccountFactory.selectedCompanyForOrg?.id + '/account/' + account.id
    }).then(
      function successCallback(response) {
        var index = 0;
        while (companyAndAccountFactory.selectedCompanyForOrg?.accounts[index].id != account.id) {
          index++;
        }
        companyAndAccountFactory.selectedCompanyForOrg.accounts.splice(index, 1);
        companyAndAccountFactory.allAccounts = companyAndAccountFactory.allAccounts.filter(function(accountItem) {
          return accountItem.id !== account.id;
        });
        d.resolve(response);
      },
      function errorCallback(response) {
        d.reject(response.data.message);
      }
    );
    return d.promise;
  };

  companyAndAccountFactory.createNewAccount = function(account) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: '/data/company/' + companyAndAccountFactory.selectedCompanyForOrg?.id + '/account',
      data: account
    }).then(
      function successCallback(response) {
        companyAndAccountFactory.selectedCompanyForOrg?.accounts.push(response.data[0].accounts[0]);
        companyAndAccountFactory.selectedAccountForOrg = angular.copy(response.data[0].accounts[0]);
        d.resolve(response);
      },
      function errorCallback(response) {
        d.reject(response.data.message);
      }
    );
    return d.promise;
  };

  companyAndAccountFactory.createNewCompany = function(name, invoiceId) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: '/data/company',
      data: {
        name: name,
        invoiceId: invoiceId
      }
    }).then(
      function successCallback(response) {
        companyAndAccountFactory.companiesAccounts.push(response.data[0]);
        d.resolve(response);
      },
      function errorCallback(response) {
        d.reject(response.data.message);
      }
    );
    return d.promise;
  };

  companyAndAccountFactory.updateLastUsedAccount = function(account_id) {
    $http({
      method: 'POST',
      url: '/data/last_used_account',
      data: { account_id }
    });
  };

  companyAndAccountFactory.getTenants = function() {
    var d = $q.defer();
    if (companyAndAccountFactory.tenants.length > 0) {
      d.resolve(companyAndAccountFactory.tenants);
      return d.promise;
    } else {
      $http({
        method: 'GET',
        url: '/data/getTenants'
      }).then(
        function successCallback(response) {
          angular.copy(response.data, companyAndAccountFactory.tenants);
          d.resolve(response.data);
        },
        function errorCallback(response) {
          d.reject(response);
        }
      );
      return d.promise;
    }
  };

  companyAndAccountFactory.newConversionTrackingPixel = function(name, website_url, enable_site_retargeting) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: `/data/account/${companyAndAccountFactory.selectedAccountForOrg?.id}/pixel`,
      data: { alias: name, website_url: website_url, enable_site_retargeting: enable_site_retargeting }
    })
      .then(function({ data: newPixel, status, headers, config }) {
        //companyAndAccountFactory.selectedAccountForOrg.settings.pixels.push(newPixel);
        d.resolve(newPixel);
      })
      .catch(function({ data }) {
        // this is the error message displayed to the user
        d.reject(data.message);
      });

    return d.promise;
  };

  companyAndAccountFactory.deleteConversionTrackingPixel = function(pixelId) {
    var d = $q.defer();
    $http({
      method: 'DELETE',
      url: `/data/account/${companyAndAccountFactory.selectedAccountForOrg?.id}/pixel/${pixelId}`
    })
      .then(function() {
        /*for(var i = 0; i<campaignManagerFactory.selectedCampaign.settings.pixels; i++){
                    if (campaignManagerFactory.selectedCampaign.settings.pixels[i].id == pixelId){
                        campaignManagerFactory.selectedCampaign.settings.pixels.splice(i, 1);
                        break;
                    }
                }*/
        d.resolve();
      })
      .catch(function({ data }) {
        // this is the error message displayed to the user
        d.reject(data.message);
      });

    return d.promise;
  };

  companyAndAccountFactory.getTrackingPixels = function(accountId) {
    var d = $q.defer();
    $http({
      method: 'GET',
      url: '/data/pixels',
      params: { account_id: accountId }
    }).then(
      function successCallback(response) {
        d.resolve(response.data);
      },
      function errorCallback(response) {
        d.reject(response);
      }
    );
    return d.promise;
  };

  companyAndAccountFactory.getTrackingPixelStatuses = function(accountId, pixelIds) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: '/data/account/' + accountId + '/pixelStatuses',
      data: { pixel_ids: pixelIds }
    })
      .then(function({ data: updatedTrackingPixels, status, headers, config }) {
        d.resolve(updatedTrackingPixels);
      })
      .catch(function({ data }) {
        d.reject(data.message);
      });
    return d.promise;
  };

  companyAndAccountFactory.selectTenant = function(tenant_id) {
    for (var i = 0; i < companyAndAccountFactory.tenants.length; i++) {
      if (tenant_id == companyAndAccountFactory.tenants[i].tenant_id) {
        return companyAndAccountFactory.tenants[i];
      }
    }
  };

  companyAndAccountFactory.canShowDashBoard = function() {
    for (var i = 0; i < companyAndAccountFactory.companiesAccounts.length; i++) {
      if (companyAndAccountFactory.companiesAccounts[i].accounts.length > 0) {
        return true;
      }
    }
    return false;
  };

  companyAndAccountFactory.setSuggestedBidPrice = function() {
    var d = $q.defer();
    const accountId = companyAndAccountFactory.selectedAccount?.id;
    if (typeof companyAndAccountFactory.selectedAccount?.isCpvEligible === 'undefined') {
      $http({
        method: 'GET',
        url: `/data/account/${accountId}/minimum_cpv_price`
      })
        .then(function(response) {
          if (response.data.min_cpv_pricing !== -1.0) {
            if (companyAndAccountFactory.selectedAccount) {
              companyAndAccountFactory.selectedAccount.isCpvEligible = true;
              companyAndAccountFactory.selectedAccount.suggestedBidPrice = response.data.min_cpv_pricing;
              d.resolve(response.data.min_cpv_pricing);
            }
          } else {
            if (companyAndAccountFactory.selectedAccount) {
              companyAndAccountFactory.selectedAccount.isCpvEligible = false;
            }
            d.reject('No suggested price');
          }
        })
        .catch(function(error) {
          d.reject('Something went wrong');
        });
    }
    return d.promise;
  };

  companyAndAccountFactory.isAccountInCountry = function(countryCode) {
    return companyAndAccountFactory?.selectedAccount?.countryCode === countryCode;
  };

  return companyAndAccountFactory;
}
