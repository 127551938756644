import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const CustomizationToggleIcon = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', height: '100%' }} viewBox="0 0 52 68" fill="none">
    <g clipPath="url(#clip0_7342_1445)">
      <path
        d="M39.1851 41.6224V36.3968C39.1851 35.3985 38.3763 34.5918 37.378 34.5918H35.813C34.6988 34.5918 33.7974 35.4933 33.7974 36.6075V41.6224"
        stroke="#83BC20"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.5732 47.5203V39.2196C44.5732 38.2212 43.7643 37.4146 42.7681 37.4146H40.9926C39.9942 37.4146 39.1875 38.2233 39.1875 39.2196V43.9417"
        stroke="#83BC20"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.7978 37.6373V25.2821C33.7978 23.7951 32.5931 22.5903 31.1061 22.5903C29.617 22.5903 28.4122 23.7993 28.4143 25.2863L28.4522 43.204C28.4522 44.2593 27.6034 45.1186 26.5482 45.1249C25.9248 45.1291 25.3624 44.75 25.1349 44.1708C24.819 43.3662 24.3556 42.172 23.9365 41.0515C23.3151 39.3918 21.4975 38.5156 19.804 39.0632L19.7451 39.0821C18.97 39.3328 18.5213 40.1395 18.7172 40.9293C19.8693 43.7538 20.7245 46.6877 21.2721 49.687L22.3505 55.5887C22.7612 57.2484 23.6374 58.7586 24.8716 59.9402L26.5735 61.5683V65.9998"
        stroke="#83BC20"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.5732 46.5111V42.4502C44.5732 41.336 45.4747 40.4346 46.5889 40.4346H48.1538C49.1522 40.4346 49.9589 41.2434 49.9589 42.2396V53.4953C49.9589 56.1955 48.99 58.8072 47.2292 60.8545L46.7132 61.4548C46.0097 62.2741 45.6221 63.3188 45.6221 64.3993V66"
        stroke="#83BC20"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 16.6025H9.0685" stroke="#83BC20" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M22.5483 16.6025H47.9432"
        stroke="#83BC20"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 26.7314H24.4588" stroke="#83BC20" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M37.5366 26.7314H47.9435"
        stroke="#83BC20"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 6.47559H21.2489" stroke="#83BC20" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M34.7266 6.47559H47.9432"
        stroke="#83BC20"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8771 21.0784C11.4044 21.0784 9.40137 19.0754 9.40137 16.6027C9.40137 14.13 11.4044 12.127 13.8771 12.127C16.3498 12.127 18.3529 14.13 18.3529 16.6027C18.3529 19.0754 16.3498 21.0784 13.8771 21.0784Z"
        fill="#E0E0E0"
        fillOpacity="0.13"
        stroke="#E0E0E0"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.9176 10.9515C27.4449 10.9515 25.4419 8.94846 25.4419 6.47574C25.4419 4.00303 27.4449 2 29.9176 2C32.3904 2 34.3934 4.00303 34.3934 6.47574C34.3934 8.94846 32.3904 10.9515 29.9176 10.9515Z"
        fill="#E0E0E0"
        fillOpacity="0.13"
        stroke="#E0E0E0"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7342_1445">
        <rect width="52" height="68" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default CustomizationToggleIcon;
