export function campaignActionSheet() {
  var directive = {
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaign-action-sheet.html',
    bindToController: true,
    controller: CampaignActionSheetController,
    controllerAs: 'vm',
    link: link,
    restrict: 'E',
    scope: {
      disableWriteIcons: '=',
      booleans: '=',
      errorMsg: '=error',
      callback: '&'
    }
  };
  return directive;

  function link(scope, element, attrs) {}
}

/** @ngInject */
function CampaignActionSheetController(featureFlagFactory) {
  var vm = this;
  vm.featureFlagFactory = featureFlagFactory;
}
