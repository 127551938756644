changeLogFactory.$inject = ['$q', '$http'];

/**
 * @param {ng.IQService} $q
 * @param {ng.IHttpService} $http
 */
export function changeLogFactory($q, $http) {
  var changeLogFactory = {
    getUserChangeLog
  };

  return changeLogFactory;

  /**
   * @param {number} campaignId
   * @returns {Promise<ArrayBuffer>}
   */
  async function getUserChangeLog(campaignId) {
    var url = '/data/campaigns/' + campaignId + '/changelog';
    const response = await $http({ url: url, method: 'GET', responseType: 'arraybuffer' });
    return response.data;
  }
}
