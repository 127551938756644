export function commonFilter() {
  return function(inputArray, filter, tableHeader) {
    const input = angular.copy(inputArray);
    if (filter == undefined || filter === '') {
      return input;
    }
    var returnList = [];
    var tableKeyList = Array.from(tableHeader, x => x.field);

    input.forEach(function(row) {
      for (var key in row) {
        if (
          row.hasOwnProperty(key) &&
          typeof row[key] === 'string' &&
          tableKeyList.indexOf(key) > -1 &&
          row[key].toLowerCase().indexOf(filter.toLowerCase()) > -1
        ) {
          returnList.push(row);
          break;
        }
      }
    });

    return returnList;
  };
}
