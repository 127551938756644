import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const WarningIcon = createSvgIcon(
  <path
    d="M28.0002 13.977L45.5702 44.3337H10.4302L28.0002 13.977ZM28.0002 4.66699L2.3335 49.0003H53.6668L28.0002 4.66699ZM30.3335 37.3337H25.6668V42.0003H30.3335V37.3337ZM30.3335 23.3337H25.6668V32.667H30.3335V23.3337Z"
    fill="#323232"
  />,
  'GTWarning'
);
