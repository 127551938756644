import { updatePixelStatuses } from './utils';

TrackingPixelController.$inject = ['$scope', 'modalFactory', 'companyAndAccountFactory'];

/**
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 */
export function TrackingPixelController($scope, modalFactory, companyAndAccountFactory) {
  function selectedAccountForOrg() {
    if (!companyAndAccountFactory.selectedAccountForOrg) {
      throw new Error('companyAndAccountFactory.selectedAccountForOrg must be set.');
    }
    return companyAndAccountFactory.selectedAccountForOrg;
  }

  $scope.pixelArr = [];
  $scope.pixelToAdd = '';
  $scope.booleans.validPixelsProgress = false;

  $scope.init = function() {
    $scope.$emit('accountLoadingProgress');
    $scope.pixelToAdd = '';
    $scope.addPixelProgress = false;
    $scope.pixelArr.length = 0;

    companyAndAccountFactory.getTrackingPixels(selectedAccountForOrg().id).then(function(data) {
      $scope.pixelArr = data;
      $scope.$emit('accountLoadingEndProgress');
    });
  };

  $scope.$on('account.selected.trackingPixel.loaded', function() {
    $scope.init();
  });

  $scope.$on('newPixelCreated', function(event, newPixel) {
    $scope.pixelArr.push(newPixel);
  });

  if ($scope.currentTab && $scope.currentTab.msg == 'account.selected.trackingPixel.loaded') {
    $scope.init();
  }

  $scope.removePixel = function removePixel(pixel, index) {
    const modalSetting = {
      title: 'Confirm Deletion',
      message: 'Are you sure you want to remove this pixel?',
      noText: 'Cancel',
      noClass: 'button-v2 button--tertiary-green',
      yesText: 'Delete',
      yesClass: 'skin-red'
    };

    var handlers = {
      confirm: function() {
        $scope.errorMsg = '';
        if (!$scope.removePixelProgress) {
          $scope.removePixelProgress = true;
          $scope.$emit('accountLoadingProgress');
          $scope.progress = true;
          return companyAndAccountFactory
            .deleteConversionTrackingPixel(pixel.id)
            .then(
              function() {
                $scope.pixelArr.splice(index, 1);
              },
              function(msg) {
                return msg;
              }
            )
            .finally(function() {
              $scope.removePixelProgress = false;
              $scope.progress = false;
              $scope.$emit('accountLoadingEndProgress');
            });
        }
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.validatePixels = function() {
    $scope.booleans.validPixelsProgress = true;
    companyAndAccountFactory
      .getTrackingPixelStatuses(selectedAccountForOrg().id, [])
      .then(pixels => {
        $scope.pixelArr = updatePixelStatuses($scope.pixelArr, pixels);
      })
      .finally(function() {
        $scope.booleans.validPixelsProgress = false;
      });
  };

  $scope.downloadPixels = `/download/account/${selectedAccountForOrg().id}/pixels?subsession=${window.subsession}`;

  $scope.pixelDetailsModal = function(pixel) {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.trackingPixelDetailsModal.html',
      controller: 'trackingPixelAccountModalController',
      keyboard: false,
      backdrop: 'static',
      size: 'medium'
    };

    modalFactory.createModal(modalOptions, pixel);
  };
}
