import { action, observable } from 'mobx';
import { store } from './store';
import { ReportModel } from './report_model';

export class ReportSchedulerEditFormModel {
  @observable private _mobx = true; // make this class an observable

  report: ReportModel;

  constructor(report: ReportModel) {
    this.report = report;
  }

  @action.bound cancelReport() {
    store.reportScheduler.navigateToReportListPage();
  }

  @action async saveReport() {
    const data = this.report.getReportInput();
    if (!data) {
      throw new Error('Some required fields are missing values');
    } else if (this.report.id) {
      await store.reportScheduler.updateReportDetails(this.report.id, data);
    } else {
      await store.reportScheduler.createReport(data);
    }
    store.reportScheduler.navigateToReportListPage();
  }
}
