export enum TenantType {
  MANAGED = 0,
  SELFSERVE = 1,
  CHANNEL = 2
}

export enum TenantPaymentType {
  INVOICE = 0,
  CREDIT_CARD = 1,
  SPEND_LIMIT = 2,
  CREDIT_CARD_V2 = 3,
  ACCOUNT_LEVEL_CREDIT_CARD = 4
}

export const CREDIT_CARD_TENANT_TYPES = [
  TenantPaymentType.CREDIT_CARD,
  TenantPaymentType.CREDIT_CARD_V2,
  TenantPaymentType.ACCOUNT_LEVEL_CREDIT_CARD
];

export enum UserStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  NEEDS_ACTIVATION = 2
}

export enum AccountType {
  DIRECT_CLIENT = 0,
  DIRECT_AGENCY = 1,
  PLATFORM_CMR = 2,
  PLATFORM_RESELLER = 3,
  PLATFORM_CHANNEL = 4,
  DIRECT_PROGRAMMATIC = 5,
  PLATFORM_PROGRAMMATIC = 6,
  PROGRAMMATIC = 7,
  SELFSERVE = 8,
  CHARITY = 9
}

export enum DistanceUnits {
  MILES = 'miles',
  KM = 'km',
  KILOMETERS = 'km'
}

export enum BudgetType {
  LIFETIME = 0,
  PERIODIC = 1,
  TIME_PERIOD = 2,
  DAILY = 3
}

export enum BudgetLevel {
  ADGROUP = 'adgroup-budget',
  CAMPAIGN = 'campaign-budget'
}

export enum BidType {
  CPC = 'CPC',
  CPM = 'CPM',
  CPV = 'CPV'
}

export enum NotificationTypes {
  NO_VALID_CREDIT_CARD = 1,
  PAUSED_ACCOUNT_FOUND = 2,
  VIEW_ONLY = 3,
  SPEND_LIMIT_TENANT = 4,
  REPORTING_ONLY = 5,
  NO_VALID_ACCOUNT_LEVEL_CREDIT_CARD = 6,
  EMAIL_NOT_ACTIVATED = 7
}
