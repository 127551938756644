xadSingleLineChart.$inject = ['$timeout', '$filter', 'generalUtilsFactory', 'highChartsFactory', 'chartUtilsFactory'];

/**
 * @param {ng.ITimeoutService} $timeout
 * @param {ng.IFilterService} $filter
 * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @param {import('../../../factories/types').HighchartsService} highChartsFactory
 * @param {import('../../../factories/types').ChartUtilsService} chartUtilsFactory
 */
export function xadSingleLineChart($timeout, $filter, generalUtilsFactory, highChartsFactory, chartUtilsFactory) {
  const DEFAULT_METRIC = 'Impressions';
  return {
    restrict: 'E',
    replace: true,
    scope: {
      active: '=',
      changed: '=',
      chartProgress: '=',
      chartClass: '=',
      chartData: '=',
      seriesOptions: '=',
      metrics: '=',
      metricsChanged: '=',
      days: '=',
      lineChartType: '@',
      xaxisChartConfigFn: '=',
      yaxisChartTitle: '='
    },
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.xad-line-chart-single.html',
    controller: /** @ngInject */ function($scope) {
      if (angular.isUndefined($scope.type)) {
        $scope.type = $scope.lineChartType;
      }
      $scope.yAxisMetrics = [];
      Highcharts.setOptions({
        lang: {
          thousandsSep: ','
        }
      });

      $scope.yAxisMetrics = highChartsFactory.updateMetrics($scope.seriesOptions);
    },
    link: function(scope, element, attrs) {
      var seriesOptions = [];
      var seriesCounter = 0;
      var seriesOrder = [];
      var days = scope.days;

      var tooltipFormat = function() {
        var color = this.series.color;
        var value = this.y;
        var name = scope.seriesOptions[scope.yAxisMetrics[0].value].tooltipName || this.series.name;
        name = name.charAt(0).toUpperCase() + name.slice(1);
        var dataLabel = scope.seriesOptions[scope.yAxisMetrics[0].value].dataLabel;

        if (dataLabel === 'percent') {
          value = $filter('percentage')(value, 2);
        } else {
          if (value < 1) {
            // For 'conversions' lineChartType only, it will display 0 value as '0' instead of '0.0'
            switch (scope.lineChartType) {
              case 'conversions':
                break;
              default:
                value = $filter('number')(value, 1);
            }
          } else {
            value = $filter('number')(value, 0);
          }
        }

        return (
          '<span style="color:' +
          color +
          '">' +
          name +
          '<span style="font-weight: 700">: ' +
          value +
          '</span></span><br/>'
        );
      };

      var yAxisLabelFn = function() {
        return generalUtilsFactory.shorthandNumber(this.value, 0, 2);
      };

      var yAxisLabelPercentFn = function() {
        return $filter('percentage')(this.value, 2);
      };

      var colors = [
        '#655cb4',
        '#866248',
        '#f46e4d',
        '#3487bb',
        '#424555',
        '#be9266',
        '#4fd540',
        '#b5d9b1',
        '#c45e6d',
        '#ad5bb7'
      ];

      function createChart() {
        // Configure and render chart
        /** @type {any} */ (Highcharts).seriesTypes.line.prototype.drawLegendSymbol =
          /** @type {any} */ (Highcharts).seriesTypes.column.prototype.drawLegendSymbol;
        var chartContainer = element[0].querySelector('.chart');
        if (['conversions'].includes(scope.lineChartType)) {
          scope.yaxisChartTitle = 'Conversions';
        } else {
          scope.yaxisChartTitle = scope.yAxisTitle || DEFAULT_METRIC;
        }
        var formatLabelFn = [];
        scope.yAxisMetrics.forEach(function(metrics) {
          var dataLabel = scope.seriesOptions[metrics ? metrics.value : 'impression'].dataLabel;
          formatLabelFn.push(dataLabel && dataLabel === 'percent' ? yAxisLabelPercentFn : yAxisLabelFn);
        });

        // to be added here
        let yAxisMaxValues = [];

        // 'dowtod' charts should be treated differently because we compute the max and min of multiple charts.
        // (ex. 'Weekday' + 'Tuesday').
        if (['dowtod'].includes(scope.lineChartType)) {
          yAxisMaxValues = chartUtilsFactory.getYAxisMaxValuesTodDow(
            seriesOptions,
            scope.seriesOptions,
            scope.yAxisMetrics
          );
        } else {
          yAxisMaxValues = chartUtilsFactory.getYAxisMaxValues(
            scope.lineChartType,
            seriesOptions,
            scope.seriesOptions,
            scope.yAxisMetrics
          );
        }

        scope.chart = Highcharts.chart(chartContainer, {
          title: {
            text: ''
          },
          chart: {
            height: 370,
            marginBottom: 50,
            marginTop: 60,
            style: {
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontWeight: '400'
            },
            plotBorderWidth: 0
          },
          xAxis: scope.xaxisChartConfigFn(seriesOptions),
          yAxis: {
            labels: {
              formatter: formatLabelFn[0],
              style: {
                color: '#65c5a5',
                fontSize: '14px'
              }
            },
            title: {
              align: 'high',
              text: scope.yaxisChartTitle,
              rotation: 0,
              offset: 10,
              y: -25,
              style: {
                color: '#65c5a5'
              }
            },
            lineColor: '#bcf1e9',
            lineWidth: 1,
            gridLineDashStyle: 'ShortDash',
            plotLines: [
              {
                value: 0,
                width: 2,
                color: 'silver'
              }
            ],
            ...(yAxisMaxValues.length > 0 && {
              tickAmount: yAxisMaxValues[0].amount,
              min: yAxisMaxValues[0].min,
              max: yAxisMaxValues[0].max
            })
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            line: {
              marker: {
                symbol: 'circle'
              }
            }
          },
          tooltip: {
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderColor: '#BCF1E9',
            borderRadius: 10,
            padding: 24,
            pointFormatter: tooltipFormat,
            xDateFormat: '%A, %b %d, %Y',
            valueDecimals: 2,
            split: false,
            shared: true,
            style: {
              color: '#0A3F36',
              fontSize: '16px',
              fontWeight: '400',
              fontFamily: 'Roboto',
              lineHeight: '29.6px',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.05)'
            }
          },
          series: seriesOptions,
          exporting: {
            enabled: true,
            scale: 1,
            buttons: {
              contextButton: {
                symbol: 'url(/ui/images/export-icon-download.svg)',
                menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF'],
                text: 'Download',
                verticalAlign: 'top',
                height: 40,
                symbolY: 20,
                symbolX: 20,
                y: 5,
                x: 5
              }
            },
            chartOptions: {
              chart: {
                marginTop: 50,
                marginRight: 30,
                marginBottom: 50,
                marginLeft: 150
              }
            }
          },
          credits: {
            enabled: false
          }
        });
        $timeout(function() {
          scope.chart.reflow();
        });
      }

      scope.changeMetrics = function(index) {
        var data = highChartsFactory.changeMetrics(
          scope.seriesOptions,
          scope.yAxisMetrics,
          scope.metrics,
          scope.yAxisTitle,
          index
        );
        scope.seriesOptions = data.options;
        scope.yAxisMetrics = data.yMetrics;
        scope.metrics = data.metrics;
        scope.yAxisTitle = data.title;

        scope.metricsChanged();
      };

      scope.checkboxChanged = function(item) {
        var checkedCount = scope.days.filter(day => {
          return day.checked;
        }).length;

        if (!checkedCount) {
          item.checked = true;
        } else {
          scope.metricsChanged();
        }
      };

      scope.$watch('changed', function(newValue, oldValue) {
        if (newValue === true) {
          seriesOrder.length = 0;
          seriesOptions.length = 0;
          seriesCounter = 0;

          if (scope.chart) {
            scope.chart.destroy();
          }

          // Set up series options
          var prop;
          let color;
          for (prop in scope.chartData) {
            if (scope.chartData.hasOwnProperty(prop)) {
              var index;

              switch (scope.lineChartType) {
                case 'conversions':
                  color = scope.seriesOptions[scope.yAxisMetrics[0].value].color;
                  break;
                default:
                  for (index = 0; index < days.length; index++) {
                    if (prop === days[index].value) {
                      color = days[index].color;
                      break;
                    }
                  }
              }

              seriesOptions.push({
                data: scope.chartData[prop],
                name: prop,
                color,
                type: 'spline'
              });

              seriesOrder.push(prop);
              seriesCounter++;
            }
          }

          scope.yAxisMetrics = highChartsFactory.updateMetrics(scope.seriesOptions);
          createChart();
          scope.changed = false;
        }
      });

      // When chart becomes active, fit the chart inside its container
      scope.$watch('active', function(newValue, oldValue) {
        if (newValue === true) {
          if (scope.chart) {
            $timeout(function() {
              scope.chart.reflow();
            });
          }
        }
      });

      // Set the datepicker's date format
      $.datepicker.setDefaults({
        dateFormat: 'yy-mm-dd',
        onSelect: function() {
          scope.chart.xAxis[0].setExtremes(
            $('input.highcharts-range-selector:eq(0)')
              .datepicker('getDate')
              .getTime(),
            $('input.highcharts-range-selector:eq(1)')
              .datepicker('getDate')
              .getTime()
          );
          /** @type {HTMLInputElement} */ (this).blur();
        }
      });
    }
  };
}
