import * as React from 'react';
import { styled as muiStyled, Theme } from '@material-ui/core/styles';
import { CreateCSSProperties, WithStylesOptions } from '@material-ui/styles/withStyles';
import { getDisplayName } from '@material-ui/utils';

interface ComponentCreatorEx<Props extends object> {
  (
    styles: CreateCSSProperties<Props> | ((props: { theme: Theme } & Props) => CreateCSSProperties<Props>),
    options?: WithStylesOptions<Theme>
  ): React.ComponentType<Props>;
}

interface ComponentCreatorBase<Component extends React.ElementType>
  extends ComponentCreatorEx<React.ComponentProps<Component>> {
  extraProps<ExtraProps extends object>(
    propsSelector: (props: ExtraProps & React.ComponentProps<Component>) => React.ComponentProps<Component>
  ): ComponentCreatorEx<ExtraProps & React.ComponentProps<Component>>;
}

export function styled<Component extends React.ElementType>(component: Component): ComponentCreatorBase<Component> {
  const creator: ComponentCreatorBase<Component> = ((styles, options) => {
    return (muiStyled as any)(component)(styles, options);
  }) as ComponentCreatorBase<Component>;
  creator.extraProps = <ExtraProps extends object>(
    propsSelector: (props: ExtraProps & React.ComponentProps<Component>) => React.ComponentProps<Component>
  ) => (styles, options) => {
    const Component: any = component;
    const innerComponent: React.RefForwardingComponent<any, ExtraProps & React.ComponentProps<Component>> = (
      props,
      ref
    ) => <Component ref={ref} {...propsSelector(props)} />;
    innerComponent.displayName = getDisplayName(component);
    const outerComponent = React.forwardRef(innerComponent);
    outerComponent.displayName = `ExtraProps(${innerComponent.displayName})`;
    return (muiStyled as any)(outerComponent)(styles, options);
  };
  return creator;
}
