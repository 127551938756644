import angular from 'angular';

export function getServices<T extends { [name: string]: object }>($injector: ng.auto.IInjectorService, services: T): T {
  const result: T = {} as any;
  for (const name of Object.keys(services)) {
    let injected: any = undefined;
    try {
      injected = $injector.get(name);
    } catch (error) {
      // if Proxy is supported, we create a proxy to then throw when it is being accessed
      if (typeof Proxy === 'function') {
        function onAccess(): never {
          const forwardError = new Error(String(error));
          if (error.stack) {
            forwardError.stack = forwardError.stack + '\n\n' + error.stack;
          }
          throw forwardError;
        }
        injected = new Proxy(
          {},
          {
            apply: onAccess,
            construct: onAccess,
            defineProperty: onAccess,
            deleteProperty: onAccess,
            enumerate: onAccess,
            get: onAccess,
            getOwnPropertyDescriptor: onAccess,
            getPrototypeOf: onAccess,
            has: onAccess,
            isExtensible: onAccess,
            ownKeys: onAccess,
            preventExtensions: onAccess,
            set: onAccess,
            setPrototypeOf: onAccess
          }
        );
      } else if (!angular || !angular.mock) {
        // not in testing
        throw error;
      }
    }
    result[name as keyof T] = injected;
  }
  return result;
}
