import React from 'react';
import PropTypes from 'prop-types';

import { ModalBase } from '../../../../components/reactComponents/Modal/ModalBase';
import { PrimaryButton } from '../../../../components/reactComponents/Button';
import Divider from '@material-ui/core/Divider';
import { ClearIcon } from '../../../../components/reactComponents/Icons';

interface ClearSelectionConfirmationModalProps {
  shouldDisplay: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function ClearSelectionConfirmationModal({
  shouldDisplay,
  onClose,
  onConfirm
}: ClearSelectionConfirmationModalProps) {
  return (
    <ModalBase open={shouldDisplay} className="clear-selection-confirmation-modal">
      <div className="clear-selection-confirmation-modal__container">
        <div className="clear-selection-confirmation-modal__content">
          <div className="clear-selection-confirmation-modal__text">
            <div className="clear-selection-confirmation-modal__text-container">
              <h3 className="clear-selection-confirmation-modal__title">
                Are you sure you want to delete all selections?
              </h3>
              <div onClick={onClose} className="clear-selection-confirmation-modal__clear-icon">
                {' '}
                <ClearIcon />
              </div>
            </div>
            <Divider />
            <p className="clear-selection-confirmation-modal__description">
              By confirming this action, the table will be cleared.
            </p>
          </div>
        </div>
        <Divider />
        <div className="clear-selection-confirmation-modal__actions">
          <PrimaryButton
            title="Yes, Delete"
            onClick={onConfirm}
            className="clear-selection-confirmation-modal__actions-yes button-v2 button--green"
          />
          <PrimaryButton
            title="Cancel"
            onClick={onClose}
            outlined
            className="clear-selection-confirmation-modal__actions-no button-v2 button--tertiary-green"
          />
        </div>
      </div>
    </ModalBase>
  );
}

ClearSelectionConfirmationModal.propTypes = {
  shouldDisplay: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};
