import { uniq } from 'lodash';

campaignWhitelistController.$inject = [
  '$scope',
  'campaignManagerFactory',
  '$q',
  'csvXlsxUtilsFactory',
  'dataExportFactory',
  '$timeout',
  'featureFlagFactory'
];

export function campaignWhitelistController(
  $scope,
  campaignManagerFactory,
  $q,
  csvXlsxUtilsFactory,
  dataExportFactory,
  $timeout,
  featureFlagFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;

  $scope.booleans = {
    progress: false,
    globalWhitelist: true
  };

  $scope.errorMsg = '';
  $scope.whitelistAppName = {
    value: ''
  };
  $scope.whitelistDomain = {
    value: ''
  };
  $scope.publisherWhitelistAppNames = [];
  $scope.publisherWhitelistDomains = [];
  $scope.campaign = null;
  $scope.uploadedWhitelistName = {
    chipsNumLimit: 25,
    numMoreChips: 0,
    chipsToShow: []
  };
  $scope.uploadedWhitelistDomain = {
    chipsNumLimit: 25,
    numMoreChips: 0,
    chipsToShow: []
  };
  $scope.nameUploadPanelVars = {
    showPanel: false,
    file: null,
    error: false,
    errorMsg: '',
    failed: false,
    submitted: false
  };
  $scope.domainUploadPanelVars = {
    showPanel: false,
    file: null,
    error: false,
    errorMsg: '',
    failed: false,
    submitted: false
  };
  $scope.failedDomains = [];

  var validationBeforeSubmit = function() {
    var errorMap = {
      InvalidWhitelistItems:
        'Whitelisted apps and domains cannot contain comma in it. Please remove it to continue with your setup.'
    };
    var errorCharsRegex = '[,]';
    var error = undefined;
    var whitelistNames = $scope.publisherWhitelistAppNames;
    const whitelistDomains = $scope.publisherWhitelistDomains;

    for (var i = 0; i < whitelistNames.length; i++) {
      if (whitelistNames[i].search(errorCharsRegex) >= 0) {
        error = errorMap['InvalidWhitelistItems'];
        break;
      }
    }

    for (const whitelistDomain of whitelistDomains) {
      if (whitelistDomain.search(errorCharsRegex) >= 0) {
        error = errorMap['InvalidWhitelistItems'];
        break;
      }
    }

    return error;
  };

  var refreshChipsToShow = function(type) {
    if (type === 'name') {
      $scope.uploadedWhitelistName.numMoreChips = 0;
      $scope.uploadedWhitelistName.chipsToShow = $scope.publisherWhitelistAppNames;
      var numberOfChipsForName = $scope.publisherWhitelistAppNames.length;
      if (numberOfChipsForName > $scope.uploadedWhitelistName.chipsNumLimit) {
        $scope.uploadedWhitelistName.numMoreChips = numberOfChipsForName - $scope.uploadedWhitelistName.chipsNumLimit;
      }
    } else if (type === 'domain') {
      $scope.uploadedWhitelistDomain.numMoreChips = 0;
      $scope.uploadedWhitelistDomain.chipsToShow = $scope.publisherWhitelistDomains;
      const numberOfChipsForDomain = $scope.publisherWhitelistDomains.length;
      if (numberOfChipsForDomain > $scope.uploadedWhitelistDomain.chipsNumLimit) {
        $scope.uploadedWhitelistDomain.numMoreChips =
          numberOfChipsForDomain - $scope.uploadedWhitelistDomain.chipsNumLimit;
      }
    }
  };

  $scope.init = function() {
    var selectedCamapaignSettingsDfd = campaignManagerFactory.getSelectedCampaignWithSettings();
    $q.all([selectedCamapaignSettingsDfd]).then(function(data) {
      $scope.campaign = angular.copy(data[0]);
      $scope.booleans.globalWhitelist = $scope.campaign.globalWhitelist;
      $scope.publisherWhitelistAppNames = $scope.campaign.publisherWhitelistAppNames || [];
      $scope.publisherWhitelistDomains = $scope.campaign.publisherWhitelistDomains || [];

      if ($scope.publisherWhitelistDomains.length) {
        refreshChipsToShow('domain');
      }
      if ($scope.publisherWhitelistAppNames.length) {
        refreshChipsToShow('name');
      }
    });
  };

  $scope.saveCampaignWhitelist = function() {
    $scope.booleans.progress = true;

    var validationResult = validationBeforeSubmit();
    if (validationResult !== undefined) {
      $scope.errorMsg = validationResult;
      $scope.booleans.progress = false;
      return;
    }
    $scope.campaign.globalWhitelist = $scope.booleans.globalWhitelist;
    if (!$scope.booleans.globalWhitelist) {
      $scope.campaign.publisherWhitelistAppNames = $scope.publisherWhitelistAppNames;
      $scope.campaign.publisherWhitelistDomains = $scope.publisherWhitelistDomains;
    } else {
      $scope.campaign.publisherWhitelistAppNames = [];
      $scope.campaign.publisherWhitelistDomains = [];
    }

    $scope.errorMsg = '';
    campaignManagerFactory
      .saveCampaign($scope.campaign)
      .then(
        function() {
          $scope.$emit('success-toast', { message: 'Successfully updated include settings.' });
          $scope.failedDomains = [];
          $scope.init();
        },
        function(data) {
          let errorMsg = data.message;
          for (const error of data.errors || []) {
            if (error.message.indexOf('Invalid domains: ') === 0) {
              errorMsg = 'Failed to update include settings.';
              $scope.failedDomains = error.message
                .substring(0, error.message.length - 1)
                .replace('Invalid domains: ', '')
                .split(',');
              break;
            }
          }
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.progress = false;
      });
  };

  $scope.deleteSelectedWhitelistItem = function(index, dataSet) {
    if (!Array.isArray(dataSet) || index < 0) {
      return;
    }
    dataSet.splice(index, 1);
  };

  $scope.addComponentIntoWhitelist = function(type, $event) {
    if ($event.which === 13) {
      if (type === 'name') {
        if (!Array.isArray($scope.publisherWhitelistAppNames)) {
          $scope.publisherWhitelistAppNames = [];
        }
        if (
          $scope.publisherWhitelistAppNames.indexOf($scope.whitelistAppName.value) >= 0 ||
          $scope.whitelistAppName.value == ''
        ) {
          // duplicates are not allowed
          return;
        }
        $scope.publisherWhitelistAppNames.push(angular.copy($scope.whitelistAppName.value));
        $scope.whitelistAppName.value = '';
      } else if (type === 'domain') {
        if (!Array.isArray($scope.publisherWhitelistDomains)) {
          $scope.publisherWhitelistDomains = [];
        }
        if (
          $scope.publisherWhitelistDomains.indexOf($scope.whitelistDomain.value) >= 0 ||
          $scope.whitelistDomain.value == ''
        ) {
          // duplicates are not allowed
          return;
        }
        $scope.publisherWhitelistDomains.push(angular.copy($scope.whitelistDomain.value));
        $scope.whitelistDomain.value = '';
      }
    }
    refreshChipsToShow(type);
  };

  $scope.onChipRemove = function(item, type) {
    if (type === 'name') {
      const index = $scope.publisherWhitelistAppNames.indexOf(item);
      if (index > -1) {
        $scope.publisherWhitelistAppNames.splice(index, 1);
      }
    } else if (type === 'domain') {
      const index = $scope.publisherWhitelistDomains.indexOf(item);
      if (index > -1) {
        $scope.publisherWhitelistDomains.splice(index, 1);
      }
    }
    refreshChipsToShow(type);
  };

  $scope.toggleUploadOptions = function(type) {
    if (type === 'name') {
      $scope.nameUploadPanelVars.showPanel = !$scope.nameUploadPanelVars.showPanel;
      $scope.nameUploadPanelVars.submitted = false;
      $scope.nameUploadPanelVars.file = null;
    } else if (type === 'domain') {
      $scope.domainUploadPanelVars.showPanel = !$scope.domainUploadPanelVars.showPanel;
      $scope.domainUploadPanelVars.submitted = false;
      $scope.domainUploadPanelVars.file = null;
    }
  };

  $scope.selectFile = function(file, type) {
    if (type === 'name') {
      $scope.nameUploadPanelVars.failed = false;
      if (!file) {
        $scope.nameUploadPanelVars.submitted = false;
        $scope.nameUploadPanelVars.error = true;
      } else {
        $scope.nameUploadPanelVars.error = false;
        $scope.nameUploadPanelVars.submitted = true;
      }

      $scope.nameUploadPanelVars.file = file;
    } else if (type === 'domain') {
      $scope.domainUploadPanelVars.failed = false;
      if (!file) {
        $scope.domainUploadPanelVars.submitted = false;
        $scope.domainUploadPanelVars.error = true;
      } else {
        $scope.domainUploadPanelVars.error = false;
        $scope.domainUploadPanelVars.submitted = true;
      }

      $scope.domainUploadPanelVars.file = file;
    }
  };

  $scope.closePanel = function(type) {
    if (type === 'name') {
      $scope.nameUploadPanelVars.showPanel = false;
      $scope.nameUploadPanelVars.submitted = false;
      $scope.nameUploadPanelVars.file = null;
    } else if (type === 'domain') {
      $scope.domainUploadPanelVars.showPanel = false;
      $scope.domainUploadPanelVars.submitted = false;
      $scope.domainUploadPanelVars.file = null;
    }
  };

  $scope.clearWhitelistItem = function(type) {
    if (type === 'name') {
      $scope.publisherWhitelistAppNames.length = 0;
    } else {
      $scope.publisherWhitelistDomains.length = 0;
      $scope.failedDomains.length = 0;
    }
    refreshChipsToShow(type);
  };

  let validateFile = function(file) {
    const supportedExtensions = ['.csv', '.xls', '.xlsx'];
    const extension = file.slice(file.lastIndexOf('.'));

    return !supportedExtensions.includes(extension);
  };

  $scope.importFile = function(type) {
    if (!$scope.nameUploadPanelVars.file && !$scope.domainUploadPanelVars.file) {
      return;
    }

    if (type === 'name') {
      if (validateFile($scope.nameUploadPanelVars.file.name)) {
        $scope.nameUploadPanelVars.error = true;
        $scope.nameUploadPanelVars.failed = true;
        $scope.nameUploadPanelVars.submitted = false;
        $scope.nameUploadPanelVars.errorMsg = 'This file format is not supported.';
        return;
      }
      $scope.nameUploadPanelVars.error = false;
      $scope.nameUploadPanelVars.errorMsg = '';
      if (!$scope.nameUploadPanelVars.submitted) {
        return;
      }

      $scope.nameUploadPanelVars.failed = false;
    } else if (type === 'domain') {
      if (validateFile($scope.domainUploadPanelVars.file.name)) {
        $scope.domainUploadPanelVars.error = true;
        $scope.domainUploadPanelVars.failed = true;
        $scope.domainUploadPanelVars.submitted = false;
        $scope.domainUploadPanelVars.errorMsg = 'This file format is not supported.';
        return;
      }
      $scope.domainUploadPanelVars.error = false;
      $scope.domainUploadPanelVars.errorMsg = '';
      if (!$scope.domainUploadPanelVars.submitted) {
        return;
      }

      $scope.domainUploadPanelVars.failed = false;
    }

    var reader = new FileReader();
    reader.onload = function(e) {
      $timeout(function() {
        var data = reader.result;

        var whitelistItems = undefined;
        if (type === 'name') {
          whitelistItems = csvXlsxUtilsFactory.readCsvXlsxFile(data, 'includedapps');
        } else if (type === 'domain') {
          whitelistItems = csvXlsxUtilsFactory.readCsvXlsxFile(data, 'includeddomains');
        }

        // get uniq if user import the same sheet again
        if (whitelistItems === undefined || (Array.isArray(whitelistItems) && whitelistItems[0] === undefined)) {
          errorHandler(type);
          return;
        }
        if (type === 'name') {
          Array.prototype.push.apply($scope.publisherWhitelistAppNames, whitelistItems);
          $scope.publisherWhitelistAppNames = uniq($scope.publisherWhitelistAppNames);
        } else if (type === 'domain') {
          Array.prototype.push.apply($scope.publisherWhitelistDomains, whitelistItems);
          $scope.publisherWhitelistDomains = uniq($scope.publisherWhitelistDomains);
        }

        refreshChipsToShow(type);

        if (type === 'name') {
          $scope.nameUploadPanelVars.file = null;
          $scope.nameUploadPanelVars.showPanel = false;
        } else {
          $scope.domainUploadPanelVars.file = null;
          $scope.domainUploadPanelVars.showPanel = false;
        }
      });
    };

    var errorHandler = function(type) {
      if (type === 'name') {
        $scope.nameUploadPanelVars.failed = true;
        $scope.nameUploadPanelVars.submitted = false;
        $scope.nameUploadPanelVars.error = true;
        $scope.nameUploadPanelVars.errorMsg = 'Error in uploading whitelisted names.';
      } else if (type === 'domain') {
        $scope.domainUploadPanelVars.failed = true;
        $scope.domainUploadPanelVars.submitted = false;
        $scope.domainUploadPanelVars.error = true;
        $scope.domainUploadPanelVars.errorMsg = 'Error in uploading whitelisted domains.';
      }
    };

    reader.onerror = errorHandler;

    if (type === 'name') {
      reader.readAsBinaryString($scope.nameUploadPanelVars.file);
    } else {
      reader.readAsBinaryString($scope.domainUploadPanelVars.file);
    }
  };

  $scope.removeAppsFile = function() {
    $scope.nameUploadPanelVars.file = null;
    $scope.nameUploadPanelVars.submitted = false;
    $scope.nameUploadPanelVars.error = false;
    $scope.nameUploadPanelVars.failed = false;
  };

  $scope.removeDomainsFile = function() {
    $scope.domainUploadPanelVars.file = null;
    $scope.domainUploadPanelVars.submitted = false;
    $scope.domainUploadPanelVars.error = false;
    $scope.domainUploadPanelVars.failed = false;
  };

  $scope.downloadWhitelistAppNames = function() {
    campaignManagerFactory
      .getCampaignWhitelistAppNames($scope.campaign.id)
      .then(
        function(data) {
          var filename = 'campaign-' + $scope.campaign.id + '-included-app-names.csv';
          $scope.downloadList(data, filename, 'IncludedApps');
        },
        function(msg) {
          $scope.errorMsg = 'Failed to download included app names.';
        }
      )
      .finally(function() {});
  };

  $scope.downloadFailedDomains = function() {
    const filename = 'campaign-' + $scope.campaign.id + '-failed-domains.csv';
    $scope.downloadList($scope.failedDomains, filename, 'IncludeDomains');
  };

  $scope.downloadWhitelistDomains = function() {
    campaignManagerFactory
      .getCampaignDomains($scope.campaign.id, 0)
      .then(
        function(data) {
          const filename = 'campaign-' + $scope.campaign.id + '-included-domains.csv';
          $scope.downloadList(data, filename, 'IncludedDomains');
        },
        function(msg) {
          $scope.errorMsg = 'Failed to download included domains.';
        }
      )
      .finally(function() {});
  };

  $scope.downloadList = function(list, fileName, columnName) {
    if (list === null || !list.length) {
      // Hack to make the CSV builder happy
      list = [''];
    }

    var args = {
      data: list.map(function(item) {
        return { item: item };
      }),
      keys: ['item'],
      header: [columnName]
    };
    var csv = dataExportFactory.convertArrayOfObjectsToCSV(args);
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    var link = document.createElement('a');
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', fileName);
    link.click();
  };

  $scope.init();
}
