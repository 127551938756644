mapsUtilsFactory.$inject = ['$q', '$http', 'debounce2'];

/**
 * @param {ng.IQService} $q
 * @param {ng.IHttpService} $http
 * @param {import('./debounce2').Debounce2Service} debounce2
 */
export function mapsUtilsFactory($q, $http, debounce2) {
  var safeMarkerDisposal = function(marker) {
    if (!marker) {
      return;
    }

    marker.setMap(null);
    google.maps.event.clearInstanceListeners(marker);
  };

  var MAP_THEME_LIGHT = [
    {
      stylers: [{ lightness: 25 }, { saturation: -25 }]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    }
  ];

  var getGeocodeForCountry = function(countryName) {
    var d = $q.defer();
    var geoCoder = new google.maps.Geocoder();
    if (countryName == 'GB') {
      countryName = 'UK';
    }
    geoCoder.geocode(
      {
        componentRestrictions: {
          country: countryName
        }
      },
      function(results, status) {
        //$scope.map.setCenter(new google.maps.LatLng(latitude, longitude));

        if (status === google.maps.GeocoderStatus.OK) {
          if (!results.length) {
            return;
          }

          var item = results[0];
          var re;
          var i;

          if (results.length > 1) {
            re = new RegExp(countryName, 'i');
            for (i = 0; i < results.length; i += 1) {
              if (re.exec(results[i].formatted_address) !== null) {
                item = results[i];
                break;
              }
            }
          }

          d.resolve(item);
        } else {
          //$scope.map.setZoom(DEFAULT_ZOOM_LEVEL);
          d.reject('Could not geocode');
        }
      }
    );
    return d.promise;
  };

  var getBoundingBoxMarkers = function(adGroup, map) {
    var d = $q.defer();
    var httpRequest = $http({
      method: 'POST',
      url: '/data/pois/search',
      data: {
        targeting: adGroup.targeting,
        ne_lat: map
          .getBounds()
          .getNorthEast()
          .lat(),
        ne_lng: map
          .getBounds()
          .getNorthEast()
          .lng(),
        sw_lat: map
          .getBounds()
          .getSouthWest()
          .lat(),
        sw_lng: map
          .getBounds()
          .getSouthWest()
          .lng()
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data: msg }) {
        d.reject(msg);
      });
    return d.promise;
  };

  const getHeatMapDataForAdGroup = debounce2(1000, function(requestData, successCallback) {
    const d = $q.defer();
    $http({
      method: 'POST',
      url: '/data/' + requestData.product + '/heatmapdata',
      data: {
        targeting: requestData.targeting,
        zoom_level: requestData.zoomLevel
      }
    })
      .then(function({ data }) {
        successCallback(data);
        d.resolve(data);
      })
      .catch(function({ data: msg }) {
        d.reject(msg);
      });
    return d.promise;
  });

  return {
    safeMarkerDisposal: safeMarkerDisposal,
    getGeocodeForCountry: getGeocodeForCountry,
    getHeatMapDataForAdGroup: getHeatMapDataForAdGroup,
    getBoundingBoxMarkers: getBoundingBoxMarkers,
    MAP_THEME_LIGHT: MAP_THEME_LIGHT
  };
}
