customNumber.$inject = ['$filter'];

export function customNumber($filter) {
  return function(value) {
    // if null is specified, show '--', as in 'no data'
    if (value == null) {
      return '--';
    }

    if (value == '-') {
      return value;
    }

    if (!angular.isNumber(value)) {
      value = parseFloat(value);
    }

    if (isNaN(value)) {
      value = 0;
    }

    if (value === Number.POSITIVE_INFINITY) {
      return '∞';
    }

    if (value === Number.NEGATIVE_INFINITY) {
      return '-∞';
    }

    // Handle N/A if value is -1
    if (value === -1) {
      return '--';
    }

    var result;
    result = $filter('number')(value, 0);
    return result;
  };
}
