import servicesModule from '../../utils/services';
import { reactToAngularComponents } from '../../components/reactComponents/reactToAngular';

import { ReportScheduler } from './report_scheduler';
import { ReportSchedulerEdit } from './report_scheduler_edit';
import { ReportSchedulerCreate } from './report_scheduler_create';

const reactComponents = {
  ReportScheduler,
  ReportSchedulerEdit,
  ReportSchedulerCreate
};

export default angular
  .module('app.reportScheduler', [servicesModule.name])
  .component(reactToAngularComponents(reactComponents));
