import { CreativeCmsConfig, NativeCreativeCmsConfig } from '../api/types/static_deliveryChannelsCreativesMap';

import {
  MacrosResponse,
  CreativeSizesResponse,
  StaticDeliveryChannelsCreativesMapResponse,
  LandingPageResponse,
  CreativeFieldsResponse,
  BillingSourcesResponse
} from '../api/types';
import { Account } from '../api/types/companiesAndAccounts';
import { AccountType } from '../api/types/common';
import { DeliveryChannel } from '../api/http/data.generated';

type CountriesStaticData = Record<string, CountryStaticDataEntry>;
interface CountryStaticDataEntry {
  distanceUnit: string;
  distanceUnits: string[];
}

type AccountVerticalsStaticData = AccountVerticalsStaticDataEntry[];
interface AccountVerticalsStaticDataEntry {
  id: number;
  name: string;
}

type DartAccountStaticData = DartAccountStaticDataEntry[];
interface DartAccountStaticDataEntry {
  userName: string;
  kind: string;
  accountName: string;
  etag: string;
  profileId: string;
  accountId: string;
}

interface SvlVendor {
  key: string;
  displayText: string;
  selected: boolean;
}

interface CreativeMapData {
  IMAGE?: CreativeCmsConfig;
  VIDEO?: CreativeCmsConfig;
  SCRIPT?: CreativeCmsConfig;
  HTML?: CreativeCmsConfig;
  HTML5?: CreativeCmsConfig;
  HTML5_NEW?: CreativeCmsConfig;
  NATIVE?: NativeCreativeCmsConfig;
}

interface NonProgrammaticCreativeMapData {
  IMAGE: {
    bannerUrl: '';
    cssDistance: '';
    cssCustomText: '';
    customText: '';
    distance_text: '';
    externalImpressionUrls: [];
    externalScriptUrls: [];
    externalClickUrl: '';
  };
  SCRIPT: {
    script_tag: '';
    externalImpressionUrls: [];
    externalScriptUrls: [];
  };
  VIDEO: {
    vastTagUrl: '';
    externalImpressionUrls: [];
    externalScriptUrls: [];
  };
  HTML5: {
    themeId: null;
    caption: '';
    name: '';
    backgroundColor: '';
    scrollingTextToggle: '';
    distanceToggle: '';
    backgroundImage: '';
    logoImage: '';
    businessNameColor: '';
    captionColor: '';
    distanceColor: '';
  };
  HTML5_NEW: {
    html_tag: '';
    externalImpressionUrls: [];
    externalScriptUrls: [];
    s3_file_name: '';
  };
}

export interface IStaticDataFactory {
  defaultLanguageCode: string;

  countries: CountriesStaticData;
  accountVerticals: AccountVerticalsStaticData;
  svlVendors: SvlVendor[];

  getCountries(): Promise<CountriesStaticData>;
  getAccountVerticals(): Promise<AccountVerticalsStaticData>;
  getDartBillingAccounts(): Promise<DartAccountStaticData>;
  dartBillingAccounts: DartAccountStaticData;
  setDartBillingAccount(
    dartBillingAccounts: DartAccountStaticData,
    account: Account
  ): DartAccountStaticDataEntry | undefined;

  getMacros(selectedAccount?: Account | null): Promise<MacrosResponse>;
  macroMapTable: MacrosResponse;

  getCreativeSizes(): Promise<CreativeSizesResponse>;

  getCreativesMap(account_type: AccountType.PROGRAMMATIC, delivery_channel_id: number): Promise<CreativeMapData>;
  getCreativesMap(
    account_type: AccountType,
    delivery_channel_id: number
  ): Promise<CreativeMapData | NonProgrammaticCreativeMapData>;
  getCreativeFields(): Promise<CreativeFieldsResponse>;
  creativeFields: CreativeFieldsResponse;

  getLandingPage(): Promise<LandingPageResponse>;
  landingPage: LandingPageResponse;

  budgetSettingsTooltip: string;
  isCbdTooltip: string;

  getBillingSources(): Promise<BillingSourcesResponse>;
  billingSources: BillingSourcesResponse;
  getDeliveryChannels(deviceType: String): Promise<DeliveryChannel[]>;
}

staticDataFactory.$inject = ['$q', '$http'];

export function staticDataFactory($q: angular.IQService, $http: angular.IHttpService): IStaticDataFactory {
  var staticDataFactory: any = {};
  staticDataFactory.countries = {};
  staticDataFactory.creativeSizes = {};
  staticDataFactory.defaultCurrency = 'USD';
  staticDataFactory.defaultLanguageCode = 'en';
  staticDataFactory.defaultLanguage = 'English';
  staticDataFactory.defaultDistanceUnit = 'miles';

  staticDataFactory.countryStates = {};

  staticDataFactory.publisherChannels = [];

  staticDataFactory.blacklistCategories = [];
  staticDataFactory.macroMapTable = {};

  staticDataFactory.landingPage = {};
  staticDataFactory.creativeFields = {};

  staticDataFactory.billingSources = [];

  staticDataFactory.dartBillingAccounts = [];
  staticDataFactory.deliveryChannels = [];
  staticDataFactory.accountVerticals = [];

  staticDataFactory.svlVendors = [
    {
      key: 'USAMP',
      displayText: 'uSamp',
      selected: false
    },
    {
      key: 'PLACED',
      displayText: 'Placed/Foursquare',
      selected: false
    },
    {
      key: 'LIVERAMP',
      displayText: 'LiveRamp Exposure Files',
      selected: false
    },
    {
      key: 'XAD',
      displayText: 'XAD Exposure File',
      selected: false
    },
    {
      key: 'NEUSTAR',
      displayText: 'Neustar',
      selected: false
    },
    {
      key: 'CARDLYTICS',
      displayText: 'Cardlytics',
      selected: false
    },
    {
      key: 'AFFINITY',
      displayText: 'Affinity',
      selected: false
    }
  ];

  staticDataFactory.biddingPacingModes = [
    {
      featureFlagName: 'ADGROUP_BIDDING_ACCELERATED_PACE',
      value: 'ACCELERATED',
      name: 'Accelerated Pacing Mode'
    },
    {
      featureFlagName: 'ADGROUP_BIDDING_ASAP_PACE',
      value: 'ASAP',
      name: 'ASAP Mode'
    }
  ];

  staticDataFactory.budgetSettingsTooltip =
    'A budget is the amount of money you want to spend on showing people your ads. \n\n Campaign budget is a budget you set once at the campaign level. The campaign budget will be shared across ad groups based on available inventory that each ad group is competing for. Spending will not be evenly divided across all ad groups. \n\n Ad group budgets are set for each ad group, when you setup the targeting tactics. This provides more granular budget control but has the risk of under delivery. You also have the option to convert ad group budgets to a single campaign budget after launching the campaign.';
  staticDataFactory.bidTypeToolTip = 'Please select your bidding strategy';
  staticDataFactory.isCbdTooltip = 'A campaign to advertise CBD-containing products must be explicitly noted';
  staticDataFactory.dailyBudgetTooltip = 'Once saved, this setting cannot be changed';

  staticDataFactory.tenantAssignment = {
    createOrganizationObjectives: [
      {
        key: 'audienceTargeting',
        value: 'Audience Targeting'
      },
      {
        key: 'onlineConsumer',
        value: 'Drive consumers online'
      },
      {
        key: 'storeConsumer',
        value: 'Drive consumers to store (s)'
      },
      {
        key: 'locationTargeting',
        value: 'Location Targeting'
      },
      {
        key: 'salesMeasurement',
        value: 'Sales Measurement'
      },
      {
        key: 'weatherTargeting',
        value: 'Weather Targeting'
      },
      {
        key: 'whiteLabeled',
        value: 'White-Labeled Solution'
      }
    ],
    organizationTypes: {
      Agency: {
        value: 'Advertising / Media Agency',
        disableClientCountSelection: false
      },
      AdTechOrServiceProviders: {
        value: 'Ad Tech / Service Provider',
        disableClientCountSelection: false
      },
      Brand: {
        value: 'Brand',
        disableClientCountSelection: true
      },
      Consultant: {
        value: 'Consultant',
        disableClientCountSelection: false
      },
      Reseller: {
        value: 'Channel / Reseller Partnerships',
        disableClientCountSelection: false
      },
      MediaCompanies: {
        value: 'Media Companies (Radio/TV/Broadcast/Out of Home/Print/Direct Mail)',
        disableClientCountSelection: false
      },
      Publisher: {
        value: 'Publisher',
        disableClientCountSelection: false
      },
      Business: {
        value: 'Small and Mid-Size Business',
        disableClientCountSelection: false
      },
      Other: {
        value: 'Other',
        disableClientCountSelection: false
      }
    },
    numberOfEmployees: [
      '1 - 10',
      '11 - 50',
      '51 - 250',
      '251 - 1k',
      '1k - 5k',
      '5k - 10k',
      '10k - 50k',
      '50k - 100k',
      '100k+'
    ],
    experienceLevel: ['Yes, I have much experience', 'I have some experience', 'Never'],
    deviceType: [
      {
        value: 'Mobile',
        selected: false
      },
      {
        value: 'Desktop',
        selected: false
      },
      {
        value: 'CTV/OTT(Video)',
        selected: false
      },
      {
        value: 'Direct Mail',
        selected: false
      },
      {
        value: 'Out of Home (OOH)',
        selected: false
      }
    ]
  };

  staticDataFactory.getCountries = function() {
    var d = $q.defer<CountriesStaticData>();
    if (Object.keys(staticDataFactory.countries).length > 0) {
      // if cached
      d.resolve(staticDataFactory.countries);
      return d.promise;
    } else {
      $http<CountriesStaticData>({ method: 'GET', url: '/data/static/countries' }).then(
        function(response) {
          for (const country in response.data) {
            var distantUnits: string[] = [];

            if (response.data.hasOwnProperty(country)) {
              distantUnits.push(response.data[country].distanceUnit);
              response.data[country].distanceUnits = distantUnits;
            }
          }

          angular.copy(response.data, staticDataFactory.countries);
          d.resolve(response.data);
        },
        function(response) {
          console.log(response.data);
          d.reject('Something went wrong');
        }
      );
      return d.promise;
    }
  };

  staticDataFactory.getAccountVerticals = function() {
    var d = $q.defer<AccountVerticalsStaticData>();
    if (Object.keys(staticDataFactory.accountVerticals).length > 0) {
      d.resolve(staticDataFactory.accountVerticals);
      return d.promise;
    } else {
      $http<AccountVerticalsStaticData>({ method: 'GET', url: '/data/static/account_verticals' }).then(
        function(response) {
          angular.copy(response.data, staticDataFactory.accountVerticals);
          d.resolve(response.data);
        },
        function(response) {
          console.log(response.data);
          d.reject('Something went wrong');
        }
      );
      return d.promise;
    }
  };

  staticDataFactory.getCreativeSizes = function() {
    var d = $q.defer();
    if (!$.isEmptyObject(staticDataFactory.creativeSizes)) {
      d.resolve(staticDataFactory.creativeSizes);
    } else {
      $http({ method: 'GET', url: '/data/campaigns/creatives/creative_sizes' }).then(
        function(response) {
          angular.copy(response.data, staticDataFactory.creativeSizes);
          d.resolve(staticDataFactory.creativeSizes);
        },
        function(response) {
          console.log(response.data);
          d.reject('Could not fetch creative sizes!');
        }
      );
    }
    return d.promise;
  };

  staticDataFactory.getCountryStates = function() {
    var d = $q.defer();
    if (Object.keys(staticDataFactory.countryStates).length > 0) {
      // if cached
      d.resolve(staticDataFactory.countryStates);
      return d.promise;
    } else {
      $http({ method: 'GET', url: '/data/static/country_states' }).then(
        function(response) {
          angular.copy(response.data, staticDataFactory.countryStates);
          d.resolve(response.data);
        },
        function(response) {
          console.log(response.data);
          d.reject('Something went wrong');
        }
      );
      return d.promise;
    }
  };

  staticDataFactory.getMacros = function(selectedAccount) {
    var d = $q.defer();

    if (Object.keys(staticDataFactory.macroMapTable).length > 0) {
      if (selectedAccount) {
        staticDataFactory.macroMapTable['%%DISTANCEUNIT%%'].sample = selectedAccount.distanceUnit;
      }
      d.resolve(staticDataFactory.macroMapTable);
      return d.promise;
    }

    $http({
      method: 'GET',
      url: '/data/static/creative_macros'
    })
      .then(function(response) {
        staticDataFactory.macroMapTable = response.data;
        if (selectedAccount) {
          const distanceUnit = staticDataFactory.macroMapTable['%%DISTANCEUNIT%%'];
          if (distanceUnit) distanceUnit.sample = selectedAccount.distanceUnit;
        }
        d.resolve(response.data);
      })
      .catch(function() {
        d.resolve({});
      });

    return d.promise;
  };

  staticDataFactory.getLandingPage = function() {
    var d = $q.defer();

    if (Object.keys(staticDataFactory.landingPage).length > 0) {
      d.resolve(staticDataFactory.landingPage);
      return d.promise;
    }

    $http<LandingPageResponse>({
      method: 'GET',
      url: '/data/campaigns/creatives/landing_page_configs'
    }).then(
      function(response) {
        staticDataFactory.landingPage = response.data;
        d.resolve(staticDataFactory.landingPage);
      },
      function(response) {
        console.log(response.data);
      }
    );

    return d.promise;
  };

  staticDataFactory.getCreativeFields = function() {
    var d = $q.defer();

    if (Object.keys(staticDataFactory.creativeFields).length > 0) {
      d.resolve(staticDataFactory.creativeFields);
      return d.promise;
    }

    $http({
      method: 'GET',
      url: '/data/campaigns/creatives/creative_fields'
    }).then(
      function(response) {
        staticDataFactory.creativeFields = response.data;
        d.resolve(staticDataFactory.creativeFields);
      },
      function(response) {
        console.log(response.data);
      }
    );

    return d.promise;
  };

  staticDataFactory.getBillingSources = function() {
    var d = $q.defer();

    if (Object.keys(staticDataFactory.billingSources).length > 0) {
      d.resolve(staticDataFactory.billingSources);
      return d.promise;
    }

    $http({
      method: 'GET',
      url: '/data/static/billing_sources'
    }).then(
      function(response) {
        staticDataFactory.billingSources = response.data;
        d.resolve(staticDataFactory.billingSources);
      },
      function(response) {
        console.log(response.data);
      }
    );

    return d.promise;
  };

  staticDataFactory.getDeliveryChannels = function(deviceType) {
    const d = $q.defer();
    const params = { device_type: deviceType };

    $http({
      method: 'GET',
      url: '/data/static/delivery_channels',
      params: params
    }).then(function(response) {
      staticDataFactory.deliveryChannels = response.data;
      d.resolve(staticDataFactory.deliveryChannels);
    });

    return d.promise;
  };

  staticDataFactory.getCreativesMap = function(account_type: AccountType, delivery_channel_id: number) {
    var d = $q.defer<CreativeMapData | NonProgrammaticCreativeMapData>();

    if (account_type == 7 && delivery_channel_id != 0) {
      $http<StaticDeliveryChannelsCreativesMapResponse>({
        method: 'GET',
        url: '/data/static/delivery_channel_creative_map'
      }).then(function(response) {
        var return_dict: CreativeMapData = {};
        var deliveryChannelsCreativesMap = response.data[delivery_channel_id];
        for (const k in deliveryChannelsCreativesMap) {
          if (['IMAGE', 'SCRIPT', 'VIDEO', 'HTML'].indexOf(k) > -1) {
            return_dict[k] = deliveryChannelsCreativesMap[k]['cmsConfig'];
          }
        }
        d.resolve(return_dict);
      });
    } else {
      d.resolve({
        IMAGE: {
          bannerUrl: '',
          cssDistance: '',
          cssCustomText: '',
          customText: '',
          distance_text: '',
          externalImpressionUrls: [],
          externalScriptUrls: [],
          externalClickUrl: ''
        },
        SCRIPT: {
          script_tag: '',
          externalImpressionUrls: [],
          externalScriptUrls: []
        },
        VIDEO: {
          vastTagUrl: '',
          externalImpressionUrls: [],
          externalScriptUrls: []
        },
        AUDIO: {
          vastTagUrl: '',
          externalImpressionUrls: [],
          externalScriptUrls: []
        },
        HTML5: {
          themeId: null,
          caption: '',
          name: '',
          backgroundColor: '#1890FF',
          scrollingTextToggle: '',
          distanceToggle: '',
          backgroundImage: '',
          logoImage: '',
          businessNameColor: '#FFFFFF',
          captionColor: '#FFFFFF',
          distanceColor: '#FFFF00'
        },
        NATIVE: {
          title: '',
          description: '',
          mainImage: '',
          iconImage: '',
          advertiserName: '',
          cta: '',
          price: '',
          clickUrl: '',
          externalImpressionUrls: [],
          externalScriptUrls: []
        },
        HTML5_NEW: {
          html_tag: '',
          externalImpressionUrls: [],
          externalScriptUrls: []
        }
      });
    }

    return d.promise;
  };

  staticDataFactory.getDartBillingAccounts = async () => {
    if (staticDataFactory.dartBillingAccounts && staticDataFactory.dartBillingAccounts.length) {
      // We've already loaded DART billing accounts so just return them
      return staticDataFactory.dartBillingAccounts;
    }

    try {
      const response = await $http<DartAccountStaticData>({
        method: 'GET',
        url: '/data/dartAccounts'
      });
      staticDataFactory.dartBillingAccounts = response.data;
    } catch (e) {
      console.error('Failed to get available DART billing accounts');
    }

    return staticDataFactory.dartBillingAccounts;
  };

  staticDataFactory.setDartBillingAccount = function(
    dartBillingAccounts: DartAccountStaticData,
    account: Account
  ): DartAccountStaticDataEntry | undefined {
    for (var index = 0; index < dartBillingAccounts.length; ++index) {
      var item = dartBillingAccounts[index];
      if (item.profileId == account.dart_account_id) {
        return item;
      }
    }
  };

  return staticDataFactory;
}
