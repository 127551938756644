import { isEqual } from 'lodash';
import { AdsManagerEvent } from '../../events/events';
import { BudgetType } from '../../../ui/api/types/common';
import GAEventList from '../../components/googleAnalytics/eventList';
import { locationGroupWarningTooltip } from './common_messages';

adGroupTargetingController.$inject = [
  '$scope',
  '$state',
  'generalUtilsFactory',
  'searchFactory',
  'paymentsFactory',
  'staticDataFactory',
  'selfServeDataFactory',
  'campaignManagerFactory',
  'companyAndAccountFactory',
  'adgroupTargetingFactory',
  'jobFactory',
  '$timeout',
  '$q',
  '$log',
  '$interval',
  'modalFactory',
  'tenantFactory',
  'featureFlagFactory',
  '$location',
  '$window'
];

/**
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {import('../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @param {import('../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {ng.ITimeoutService} $timeout
 * @param {ng.IQService} $q
 * @param {ng.ILogService} $log
 * @param {ng.IIntervalService} $interval
 * @param {ng.ILocationService} $location
 * @param {ng.IWindowService} $window
 */
export function adGroupTargetingController(
  $scope,
  $state,
  generalUtilsFactory,
  searchFactory,
  paymentsFactory,
  staticDataFactory,
  selfServeDataFactory,
  campaignManagerFactory,
  companyAndAccountFactory,
  adgroupTargetingFactory,
  jobFactory,
  $timeout,
  $q,
  $log,
  $interval,
  modalFactory,
  tenantFactory,
  featureFlagFactory,
  $location,
  $window
) {
  $scope.featureFlagFactory = featureFlagFactory;

  $scope.adGroup = angular.copy(selfServeDataFactory.nullAdGroup);
  generalUtilsFactory.nestedUpdateInPlace(campaignManagerFactory.selectedAdGroup, $scope.adGroup);
  $scope.errors = [];
  $scope.showErrorTt = false;
  $scope.currentTenantTier = tenantFactory.tenant.tenant_tier;
  $scope.isSemiSensitiveEligibleTenant = tenantFactory.tenant.semi_sensitive_eligible;
  $scope.isChannelTenant = companyAndAccountFactory.selectedTenant?.type === 2;
  $scope.tenantType = companyAndAccountFactory.selectedTenant?.type;
  $scope.crossDeviceEnabledCountries = {
    US: 'United States'
  };
  $scope.accountCountryCode = companyAndAccountFactory.selectedAccount?.countryCode;
  $scope.budgetAtCampaignLevel =
    campaignManagerFactory.selectedCampaign && campaignManagerFactory.selectedCampaign.budgetAtCampaignLevel;
  $scope.getTimezoneOffset = new Date().getTimezoneOffset() / 60; // Offset is in minutes

  var eligibleHTML =
    'Several settings might not apply for desktop impressions and for a majority of mobile impressions. Desktop and a majority of mobile impressions might not be enabled under certain circumstances.' +
    '<a target="_blank" href="https://help.groundtruth.com/hc/en-us/articles/360022857393"> Learn More.</a>';

  var deviceTargetingWarningTooltip = {
    notEligible:
      'Targeting for desktop (and in some cases targeting for mobile web) will not be activated since you have activated a campaign level budget',
    eligible: eligibleHTML
  };

  const PROGRESS_INTERVAL_DELAY = 3000;

  $scope.updateFns = {
    mapUpdateFn: null,
    availsUpdateFn: null
  };

  $scope.consts = {
    distanceUnit: 'miles'
  };

  $scope.locationManagerURL = '//' + window.locationManagerURL;
  $scope.audienceManagerURL = '//' + window.audienceManagerURL;

  $scope.demographicsOptions = angular.copy(adgroupTargetingFactory.getDemographicsOptions);
  $scope.technographicsOptions = angular.copy(adgroupTargetingFactory.getTechnographicsOptions);
  $scope.lookalikeAudienceOptions = angular.copy(adgroupTargetingFactory.getLookalikeAudienceOptions);

  $scope.selectedGoalCard = {
    value: null
  };

  $scope.navHeader = '';

  $scope.setNavHeader = selectedGoal => {
    if (selectedGoal === 'audience') {
      $scope.navHeader = 'Audience';
    } else if (selectedGoal === 'weather') {
      $scope.navHeader = 'Weather';
    } else {
      $scope.navHeader = 'Location';
    }
  };

  $scope.checkAllMajorSections = {
    demographics: true,
    technographics: true
  };

  $scope.checkAllMinorSections = {
    carriers: false,
    channels: false
  };

  $scope.showAll = {
    channels: false,
    carriers: false
  };

  $scope.selectAll = {
    carriers: false,
    channels: false
  };

  $scope.disable = {
    locationGroupAutoComplete: false,
    ageGroups: false
  };

  $scope.saveAdgroup = {
    budgetAndSchedules: null
  };

  // Order is important, HTML is hardcoded with specific indices.
  $scope.optimizationGoals = [
    { label: 'Click', value: 'click' },
    { label: 'Delivery', value: 'delivery' },
    { label: 'SAR', value: 'sar' },
    { label: 'Conversion', value: 'conversion' },
    { label: 'Visit', value: 'visit' }
  ];

  $scope.optimizationInfoMessage = [
    'We’ll deliver your ads to people that are expected to provide more click through to your landing page.',
    'Maximize delivery to get the most impressions.',
    'We’ll deliver your ads to people that are expected to provide more secondary action clicks on your landing page. This optimization requires you to use GroundTruth landing pages. The threshold is the number of secondary actions expected per 10,000 impressions.',
    'We’ll deliver your ads to people that are expected to provide better conversions on your site. GroundTruth Tracking Pixels must be generated and used for this optimization to work (Tracking Pixels can be added on the Budget & Schedule section).',
    'We’ll deliver maximized visits for your ad group. We optimize based on the likeliness that a person will visit your store in the future.'
  ];

  $scope.channelOptimizationInfoMessage = [
    'CTR (Radius > 5 Miles & Flight > 2 Weeks)',
    'Delivery (Radius < 5 Miles & Flight < 2 Weeks)',
    'SAR (Available When Using An GroundTruth LP & Radius > 5 Miles & Flight > 2 Weeks)',
    'We’ll deliver your ads to people that are expected to provide better conversions on your site. GroundTruth Tracking Pixels must be generated and used for this optimization to work (Tracking Pixels can be added on the Budget & Schedule section).',
    'We’ll deliver maximized visits for your ad group. We optimize based on the likeliness that a person will visit your store in the future. '
  ];

  $scope.secondaryOptimizationInfoMessage = [
    '',
    '',
    '',
    '',
    'Select top X% users with the highest predicted visit rate.'
  ];

  $scope.vars = {
    ctr: '',
    sar: '',
    con: '',
    psvr: ''
  };

  $scope.viewability = {
    dataProvider: '',
    viewabilityScore: null,
    measurabilityScore: null
  };

  $scope.tableChanged = true;

  // order to display demographic options in UI
  $scope.demographicsOptionsOrder = {
    gender: [
      $scope.demographicsOptions.gender.All,
      $scope.demographicsOptions.gender.Male,
      $scope.demographicsOptions.gender.Female
    ],
    ethnicity: [
      $scope.demographicsOptions.ethnicity['All'],
      $scope.demographicsOptions.ethnicity['Asian'],
      $scope.demographicsOptions.ethnicity['African American'],
      $scope.demographicsOptions.ethnicity['Hispanic or Latino']
    ],
    income: [
      $scope.demographicsOptions.income['All'],
      $scope.demographicsOptions.income['0-50K'],
      $scope.demographicsOptions.income['50-100K'],
      $scope.demographicsOptions.income['100-150K'],
      $scope.demographicsOptions.income['150-200K'],
      $scope.demographicsOptions.income['200K+']
    ],
    age: [
      $scope.demographicsOptions.age['All'],
      $scope.demographicsOptions.age['18-24'],
      $scope.demographicsOptions.age['25-34'],
      $scope.demographicsOptions.age['35-44'],
      $scope.demographicsOptions.age['45-54'],
      $scope.demographicsOptions.age['55-64'],
      $scope.demographicsOptions.age['65+']
    ]
  };

  $scope.lookalikeAudienceOptionsOrder = [
    $scope.lookalikeAudienceOptions['1'],
    $scope.lookalikeAudienceOptions['3'],
    $scope.lookalikeAudienceOptions['5'],
    $scope.lookalikeAudienceOptions['9']
  ];

  if (featureFlagFactory.isFeatureEnabled('UNKNOWN_GENDER_AGE_READ')) {
    $scope.demographicsOptionsOrder.age.push($scope.demographicsOptions.age['Unknown']);
    $scope.demographicsOptionsOrder.gender.push($scope.demographicsOptions.gender['Unknown']);
  }

  $scope.technographicsOptionsOrder = {
    os: [
      $scope.technographicsOptions.os.All,
      $scope.technographicsOptions.os.iOS,
      $scope.technographicsOptions.os.Android,
      $scope.technographicsOptions.os.Other
    ]
  };

  $scope.selectedDeliveryChannel = {
    value: null
  };

  $scope.companyAndAccountFactory = companyAndAccountFactory;

  // Progress in left sidebar
  $scope.completedSteps = {
    targetingGoal: null,
    deviceType: null,
    targeting: null,
    adCreatives: null,
    budgetAndSchedule: null
  };

  $scope.currentStepIndex = null;

  $scope.stepToPage = {
    0: 'goals',
    1: 'deviceType',
    2: 'targeting',
    3: 'creatives',
    4: 'budget'
  };

  $scope.pageToStep = {
    goals: 0,
    deviceType: 1,
    '': 2,
    targeting: 2,
    creatives: 3,
    budget: 4
  };

  $scope.numOfSteps = Object.keys($scope.stepToPage).length;

  $scope.booleans = {
    showAudienceExclude: false,
    progressSidebarInitialized: false,
    availsSidebarInitialized: false,
    enableCreativeOverlays: false,
    selectionTargetingTableChanged: false,
    selectionTargetingExcludeTableChanged: false,
    selectionLocationFilterTableChanged: false,
    selectionMeasurementTableChanged: false,
    neighborhoodTableChanged: false,
    uploadModalOpen: false,
    saveProgress: false,
    saveAndLaunchProgress: false,
    saveAndCloseProgress: false,
    isMeasurementNational: false,
    showLaunchButton: false,
    programmaticAccount: false,
    leadToPayments: false,
    locationFiltersDownloadInProgress: false,
    cpgProductsTableChanged: false,
    targetingDataLoaded: false,
    enableCpgProducts: false
  };

  // Upload vars that are used by both the modal and the minimized modal
  $scope.uploadVars = {
    progress: null,
    status: null,
    fileName: null,
    intervalPromise: null,
    appendData: false,
    errorMsg: ''
  };

  $scope.selectedDemographics = {
    gender: {},
    income: {},
    ethnicity: {},
    age: {}
  };

  $scope.selectedTechnographics = {
    os: {}
  };

  $scope.selectedBlueprintProximity = null;

  $scope.deviceTypeSubtext = 'Select the device type you would like to advertise on to reach your desired audience';
  $scope.deviceTypeTooltips = {
    ctv: 'CTV refers to Connected TV devices, specifically Smart TVs and streaming devices that are attached to TVs.',
    ott: 'OTT refers to over-the-top video and can include small screen devices, such as mobile and desktop devices.',
    mobile: 'Mobile App is required to target mobile devices.',
    directMail: 'Identify and engage with customers seen at locations of interest.',
    audio: 'Ads delivered via podcasts and streaming services.'
  };
  // saveCreative will be populated by creative_list.dir.ts
  $scope.creativeListObject = {
    saveCreative: null
  };

  $scope.hasTargetingChanged = false;

  $scope.callDriveToGaEvent = function(currentState) {
    // We have updated the campaign's drive-to-location, logging this event into GA
    typeof $window.gtag === 'function' &&
      $window.gtag('event', GAEventList.CAMPAIGN_TARGET_DRIVE_TO, {
        state: !currentState,
        adgroup_id: $scope.adGroup?.id,
        campaign_id: campaignManagerFactory.selectedCampaign.id,
        gt_user_id: window?.user?.id,
        tenant_id: companyAndAccountFactory.selectedTenant?.tenant_id
      });
  };

  $scope.getLocationFilterSearchPlaceholder = function() {
    let searchPlaceholder = '';
    if (
      isDesktopTargetingSelected() ||
      (areStreamingServicesSelected() && !isTargetTypeGeoTargetingWithStreamingRadialFeatureFlag())
    ) {
      searchPlaceholder = 'Search and select city, state, DMA, or zipcode';
    } else {
      searchPlaceholder = 'Search and select city, state, DMA, zipcode, coordinates, or address';
    }
    return searchPlaceholder;
  };

  $scope.searchAudiences = function(query, exclude, includeAllTab = false) {
    var filter = {
      targeting_search: 1,
      exclude: exclude ? 1 : 0,
      entity_types: 'behavior,custom_audience,segment,brand,category,location_group',
      search_category: 'audience'
    };
    return searchFactory.getSearchResults(query, filter, includeAllTab);
  };

  function needConsent(selectedItem, booleanName) {
    if ($scope.isSemiSensitiveEligibleTenant) {
      if (booleanName === 'selectionMeasurementTableChanged') {
        return selectedItem.type === 'brand' && selectedItem.sensitivity === 2;
      } else {
        return ['brand', 'category'].includes(selectedItem.type) && selectedItem.sensitivity > 0;
      }
    } else {
      if (booleanName === 'selectionMeasurementTableChanged') {
        return false;
      } else {
        return ['brand', 'category'].includes(selectedItem.type) && selectedItem.sensitivity === 1;
      }
    }
  }

  $scope.checkSensitivity = function(items, booleanName) {
    var selectedItem = items[items.length - 1];
    if (needConsent(selectedItem, booleanName)) {
      // if sensitive
      var handlers = {
        confirm: function() {
          var data = {
            brand_id: selectedItem.type === 'brand' ? selectedItem.id : null,
            category_sic_code: selectedItem.type === 'category' ? selectedItem.sicCode : null
          };
          return adgroupTargetingFactory
            .giveSensitivityConsent(
              campaignManagerFactory.selectedCampaign.id,
              campaignManagerFactory.selectedAdGroup.id,
              data
            )
            .then(
              function() {},
              function(error) {
                return error;
              }
            );
        }
      };

      var params = {
        brand_id: selectedItem.type === 'brand' ? selectedItem.id : null,
        category_sic_code: selectedItem.type === 'category' ? selectedItem.sicCode : null
      };
      return adgroupTargetingFactory
        .checkSensitivityConsent(campaignManagerFactory.selectedCampaign.id, params)
        .then(function(response) {
          if (response.consent_given) {
            if (booleanName) {
              $scope.booleans[booleanName] = true;
            }
            return true;
          } else {
            return modalFactory
              .privacySensitivityConsent(selectedItem, $scope.tenantType, handlers)
              .then(function(data) {
                if (!data) {
                  items.splice(-1, 1);
                }
                if (booleanName) {
                  $scope.booleans[booleanName] = true;
                }
                return true;
              });
          }
        });
    } else if (booleanName) {
      // if not graylist but need to update table
      $scope.booleans[booleanName] = true;
    } else {
      // mainly for selection list onpremise directive
      return $q.when(true);
    }
    generalUtilsFactory.safeApply($scope);
  };

  $scope.saveSelectedAudiences = function(items, booleanName, newSelected, removeItemsOnCancel = true) {
    // We have updated the campaign's agroup include audience, logging this event into GA
    typeof window.gtag === 'function' &&
      window.gtag('event', GAEventList.CAMPAIGN_TARGET_AUDIENCE, {
        adgroup_id: campaignManagerFactory.selectedAdGroup.id,
        campaign_id: campaignManagerFactory.selectedCampaign.id,
        type: 'include',
        gt_user_id: window?.user?.id,
        tenant_id: companyAndAccountFactory.selectedTenant?.tenant_id
      });
    $scope.checkSensitivityForMultiple(items, booleanName, newSelected, removeItemsOnCancel);
  };

  $scope.checkSensitivityForMultiple = function(items, booleanName, newSelected, removeItemsOnCancel = true) {
    const brands = newSelected.filter(item => item.type === 'brand');
    const categories = newSelected.filter(item => item.type === 'category');

    const brandsNeedingConsent = brands.filter(brand => needConsent(brand, booleanName));
    const categoriesNeedingConsent = categories.filter(category => needConsent(category, booleanName));

    const brandIdsNeedingConsent = brandsNeedingConsent.map(item => item.id);
    const categorySicCodesNeedingConsent = categoriesNeedingConsent.map(item => item.sicCode);
    if (!brandIdsNeedingConsent.length && !categorySicCodesNeedingConsent.length) {
      $scope.booleans[booleanName] = true;
      generalUtilsFactory.safeApply($scope);
      return true;
    }

    const data = {
      brand_ids: brandIdsNeedingConsent,
      category_sic_codes: categorySicCodesNeedingConsent
    };

    const handlers = {
      confirm: function() {
        return adgroupTargetingFactory
          .giveSensitivityConsentMulti(
            campaignManagerFactory.selectedCampaign.id,
            campaignManagerFactory.selectedAdGroup.id,
            data
          )
          .then(
            function() {},
            function(error) {
              return error;
            }
          );
      }
    };

    const itemsNeedingConsent = [...brandsNeedingConsent, ...categoriesNeedingConsent];

    return adgroupTargetingFactory
      .checkSensitivityConsentMulti(campaignManagerFactory.selectedCampaign.id, data)
      .then(function(response) {
        if (response.consent_given) {
          if (booleanName) {
            $scope.booleans[booleanName] = true;
          }
          return true;
        } else {
          return modalFactory
            .privacySensitivityConsentMulti(itemsNeedingConsent, $scope.tenantType, handlers)
            .then(function(data) {
              if (booleanName) {
                $scope.booleans[booleanName] = true;
              }
              if (!data) {
                if (removeItemsOnCancel) {
                  const start = items.length - newSelected.length;
                  items.splice(start);
                  generalUtilsFactory.safeApply($scope);
                }
                return false;
              }
              return true;
            });
        }
      });
  };

  function getStatusCall(item) {
    let productFlag = $scope.selectedGoalCard.value;
    adgroupTargetingFactory.getLocationGroupStatus(item.id, productFlag).then(function(status) {
      item.status = locationGroupWarningTooltip[$scope.selectedGoalCard.value].hasOwnProperty(status)
        ? locationGroupWarningTooltip[$scope.selectedGoalCard.value][status]
        : null;
    });
  }

  $scope.getLocationGroupsStatus = function(items) {
    if (!items) {
      return;
    }
    var i;
    for (i = 0; i < items.length; i++) {
      if (items[i].type === 'location_group' && !items[i].hasOwnProperty('status')) {
        getStatusCall(items[i]);
      }
    }

    return items;
  };

  // TODO: remove this, once service usage is successful
  $scope.searchAudiencesExclude = function(query) {
    return $scope.searchAudiences(query, true);
  };

  // TODO: remove this, once service usage is successful
  $scope.searchAudiencesExcludeCallback = function() {
    $scope.booleans.selectionTargetingExcludeTableChanged = true;
    generalUtilsFactory.safeApply($scope);
  };

  $scope.searchLocations = function(query) {
    let entity_types = '';
    if (
      isDesktopTargetingSelected() ||
      (areStreamingServicesSelected() && !isTargetTypeGeoTargetingWithStreamingRadialFeatureFlag()) ||
      $scope.isAudioAdGroup()
    ) {
      entity_types = 'city,state,dma,zip_code';
    } else {
      entity_types = 'city,state,dma,zip_code,latlng,address';
    }

    let filter = {
      targeting_search: 1,
      entity_types: entity_types
    };
    return searchFactory.getSearchResults(query, filter);
  };

  function addWatchers() {
    $scope.$watch(
      function() {
        return (
          angular.toJson($scope.adGroup?.targeting?.location_filters) +
          angular.toJson($scope.adGroup?.targeting?.on_premise)
        );
      },
      function(newVal, oldVal) {
        if (newVal === oldVal) return;

        if ($scope.updateFns.mapUpdateFn) {
          $scope.updateFns.mapUpdateFn();
        }
      }
    );

    $scope.$watch(
      function() {
        return (
          angular.toJson($scope.adGroup) +
          angular.toJson($scope.selectedDemographics) +
          angular.toJson($scope.selectedTechnographics) +
          angular.toJson($scope.viewability) +
          angular.toJson($scope.selectedDeliveryChannel)
        );
      },
      function(newVal, oldVal) {
        if (newVal !== oldVal) {
          if ($scope.getCurrentPage() === 'targeting') {
            $scope.hasTargetingChanged = true;
          }
          if ($scope.updateFns.availsUpdateFn) {
            saveCommonTargetingData();
            $scope.updateFns.availsUpdateFn($scope.adGroup);
          }
        }
      }
    );
  }

  const init = async function(skipGoTo) {
    try {
      var accountDfd = companyAndAccountFactory.getSelectedAccount();
      var validPaymentsDfd = paymentsFactory.getValidPaymentWays();
      var selectedAdGroupDfd = campaignManagerFactory.getSelectedAdGroup();
      var countriesDfd = staticDataFactory.getCountries();
      var sensitivityDfd = campaignManagerFactory.getSelectedAdGroupLocationGroupSensitivityData();

      await $q
        .all([accountDfd, validPaymentsDfd, countriesDfd, selectedAdGroupDfd])
        .then(async function(data) {
          if (featureFlagFactory.isFeatureEnabled('CAMPAIGN_OPS_DASHBOARD') && $scope.isChannelTenant) {
            $scope.budgetAndScheduleCta = 'Close';
          } else {
            $scope.budgetAndScheduleCta = 'Save and Close';
          }
          if (!data[1]) {
            $scope.booleans.leadToPayments = true;
          }
          setLocationGroupSensitivityData(sensitivityDfd);
          if (companyAndAccountFactory.selectedAccount?.accountType == 7) {
            $scope.booleans.programmaticAccount = true;
          } else {
            $scope.booleans.programmaticAccount = false;
          }

          angular.copy([], $scope.errors);
          $scope.showErrorTt = false;

          //get adgroup
          generalUtilsFactory.nestedUpdateInPlace(data[3], $scope.adGroup);

          $scope.booleans.showLaunchButton =
            $scope.adGroup.status !== 'Active' &&
            $scope.adGroup.status !== 'Pending' &&
            (companyAndAccountFactory.selectedTenant?.type !== 2 ||
              campaignManagerFactory.selectedCampaign.status === 'Active');

          $scope.consts.distanceUnit = companyAndAccountFactory.selectedAccount?.distanceUnit;

          if ($scope.adGroup.product && $scope.adGroup.product != 'default') {
            if ($scope.adGroup.product.indexOf('onpremise') > -1) {
              $scope.selectedGoalCard.value = 'onpremise';
            } else if ($scope.adGroup.product.indexOf('audience') > -1) {
              $scope.selectedGoalCard.value = 'audience';
            } else if ($scope.adGroup.product.indexOf('geotargeting') > -1) {
              $scope.selectedGoalCard.value = 'geotargeting';
            } else if ($scope.adGroup.product.indexOf('neighborhood') > -1) {
              $scope.selectedGoalCard.value = 'neighborhood';
            } else if ($scope.adGroup.product.indexOf('weather') > -1) {
              $scope.selectedGoalCard.value = 'weather';
            }

            setupCommonTargetingData();
            $scope.booleans.selectionTargetingTableChanged = true;
            $scope.booleans.selectionTargetingExcludeTableChanged = true;
            $scope.booleans.selectionLocationFilterTableChanged = true;
            $scope.booleans.selectionMeasurementTableChanged = true;
            $scope.booleans.cpgProductsTableChanged = true;

            $scope.booleans.neighborhoodTableChanged = true;

            $scope.adGroup.targeting.showAudienceExclude =
              $scope.adGroup.targeting.audience_exclude && $scope.adGroup.targeting.audience_exclude.length > 0;

            $scope.booleans.showDemographicsSection =
              (!isDesktopTargetingSelected() && !areStreamingServicesSelected()) ||
              featureFlagFactory.isFeatureEnabled('GENDER_AND_AGE_TARGETING_FOR_STREAMING_AND_DESKTOP');
            $scope.booleans.showGenderSection = $scope.booleans.showDemographicsSection;
            $scope.booleans.showAgeSection = $scope.booleans.showDemographicsSection;
            $scope.booleans.showAlcoholAgeSection = $scope.booleans.showDemographicsSection;
            $scope.booleans.showIncomeSection =
              featureFlagFactory.isFeatureEnabled('INCOME_READ') && !$scope.isAudioAdGroup();

            $scope.booleans.showEthnicitySection =
              featureFlagFactory.isFeatureEnabled('ETHNICITY_READ') && !$scope.isAudioAdGroup();

            initPublisherSelection();
            if (!$scope.adGroup.targeting.tierPublisherSettings.tiers[0].bidtype) {
              $scope.adGroup.targeting.tierPublisherSettings.tiers[0].bidtype = 'CPM';
            }
            showOrHideChannelsOnLoad();
            showOrHideCarriersOnLoad();

            if (
              campaignManagerFactory.selectedCampaign.is_cpv &&
              featureFlagFactory.isFeatureEnabled('VISIT_OPTIMIZATION')
            ) {
              $scope.adGroup.optimization_strategy = $scope.optimizationGoals[4].value;
              $scope.adGroup.psvr_threshold = null;
            } else if (
              campaignManagerFactory.selectedCampaign.is_cpv &&
              !featureFlagFactory.isFeatureEnabled('VISIT_OPTIMIZATION')
            ) {
              $scope.adGroup.optimization_strategy = $scope.optimizationGoals[1].value;
            }
            resetThresholdToDefault();

            if ($scope.adGroup.targeting.from.geotarget_job_id != null) {
              $scope.openUploadModal(true);
            }

            if (!skipGoTo) {
              // Get step based on URL fragment (if any)
              // Default is targeting section
              let step = $scope.pageToStep.targeting;
              const newStep = $scope.pageToStep[$location.hash()];
              if (typeof newStep !== 'undefined') {
                step = newStep;
              }
              $scope.goToStep(step, false);
            }

            if ($scope.selectedGoalCard.value == 'audience') {
              $scope.goToSection('audience-fields');
            } else if ($scope.selectedGoalCard.value == 'onpremise') {
              $scope.goToSection('onpremise-target-fields');
            } else if ($scope.selectedGoalCard.value == 'geotargeting') {
              $scope.goToSection('location-filter');
            } else if ($scope.selectedGoalCard.value == 'neighborhood') {
              $scope.goToSection('geoblock-brands-and-categories-fields');
            } else if ($scope.selectedGoalCard.value == 'weather') {
              $scope.goToSection('weather-target-fields');
            }
            $scope.setNavHeader($scope.selectedGoalCard.value);
          } else {
            $scope.goToStep(0);
          }

          $scope.deviceTypes = getDeviceTypes();
          $scope.tempSelectedDeviceTypes = getSelectedDevices($scope?.adGroup?.targeting?.deviceTypes);

          $scope.showPublisherCategories =
            (featureFlagFactory.isFeatureEnabled('PUBLISHER_CATEGORIES_READ') &&
              !['ctv', 'ott'].includes($scope.tempSelectedDeviceTypes[0])) ||
            (['ctv', 'ott'].includes($scope.tempSelectedDeviceTypes[0]) &&
              featureFlagFactory.isFeatureEnabled('TARGET_CTV_CONTENT_CATEGORIES'));

          $scope.publisherCategoriesLabel = ['ctv', 'ott'].includes($scope.tempSelectedDeviceTypes[0])
            ? 'Content Categories'
            : 'Publisher Categories';
        })
        .finally(function() {
          addWatchers();
        });
      $scope.updateCompletedSteps();

      $timeout(function() {
        angular.element('.date-field input').datepicker();
      });
    } catch (error) {
      $scope.errors.msg =
        'Sorry, we could not load the AdGroup you are looking for at this time. Please refresh your browser in a few minutes.';
    } finally {
      $timeout(function() {
        $scope.booleans.targetingDataLoaded = true;
        generalUtilsFactory.safeApply($scope);
      });
    }
  };

  $scope.handleDeviceTypeChange = function(selectedDeviceTypes, disabledDeviceTypes) {
    /**
     * Callback function on device type page, updates $scope.tempSelectedDeviceTypes when a selection is made.
     */
    $scope.tempSelectedDeviceTypes = selectedDeviceTypes
      .filter(deviceType => !disabledDeviceTypes.includes(deviceType))
      .map(item => item.name);
  };

  function setLocationGroupSensitivityData(sensitivityDfd) {
    sensitivityDfd
      .then(function(data) {
        const audienceList = $scope.adGroup.targeting.audience;
        for (let i = 0; i < audienceList.length; i++) {
          const audience = audienceList[i];
          if (audience.type == 'location_group' && data) {
            if (audience.hasOwnProperty('is_sensitive')) {
              audience.is_sensitive = data[audience.id]?.is_sensitive;
            }
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  function getSelectedDevices(deviceTypes) {
    /**
     * Returns array of currently selected device types (app, web, desktop, ctv, ott).
     * Used to initialize $scope.tempSelectedDeviceTypes.
     */
    let result = [];
    if (deviceTypes.length) {
      if (deviceTypes.includes($scope.technographicsOptions.device.MobileApp.value)) {
        result.push('app');
      }
      if (deviceTypes.includes($scope.technographicsOptions.device.MobileWeb.value)) {
        result.push('web');
      }
      if (deviceTypes.includes($scope.technographicsOptions.device.Desktop.value)) {
        result.push('desktop');
      }
      if (deviceTypes.includes($scope.technographicsOptions.device.CTV.value)) {
        result.push('ctv');
      }
      if (deviceTypes.includes($scope.technographicsOptions.device.OTT.value)) {
        result.push('ott');
      }
      if (deviceTypes.includes($scope.technographicsOptions.device.AudioPodcast.value)) {
        result.push('audio-podcast');
      }
      if (deviceTypes.includes($scope.technographicsOptions.device.AudioStreaming.value)) {
        result.push('audio-streaming');
      }
    } else {
      result.push('app');
    }
    return result;
  }

  function getDeviceTypes() {
    /**
     * Initializes $scope.deviceTypes for device types page based on whether ad group is eligible.
     */
    let result = ['mobile'];

    if ($scope.isDesktopCompatible()) {
      result.push('desktop');
    }
    if (isStreamingServicesCompatible()) {
      result.push('ctv');
      result.push('ott');
    }
    if (isAudioServicesCompatible()) {
      result.push('audio');
    }
    if ($scope.featureFlagFactory.isFeatureEnabled('DIRECT_MAIL')) {
      result.push('directMail');
    }
    return result;
  }

  function initSidebars() {
    var sidebarElement = angular.element('.adgroup-goals-progress-sidebar > ul');
    if (!sidebarElement.length) {
      $scope.booleans.progressSidebarInitialized = false;
      return;
    }
    if ($scope.booleans.progressSidebarInitialized) {
      return;
    }
    var offset = angular.element('.context-header').height();
    generalUtilsFactory.makeElementSticky(sidebarElement, undefined, offset + 45);
    $scope.booleans.progressSidebarInitialized = true;
  }

  $scope.saveTargetingCardSelection = function() {
    var productChanged = true;
    var oldProduct = $scope.adGroup.product;
    if ($scope.selectedGoalCard.value) {
      productChanged = $scope.selectedGoalCard.value !== $scope.adGroup.product;
      $scope.adGroup.product = $scope.selectedGoalCard.value;
    }
    $scope.booleans.saveProgress = true;
    // reload the deviceType options incase the product is changed
    $scope.deviceTypes = getDeviceTypes();

    if (productChanged) {
      campaignManagerFactory
        .saveAdGroupProduct($scope.adGroup, campaignManagerFactory.selectedCampaign.id)
        .then(
          function() {
            if (companyAndAccountFactory.selectedTenant?.type === 2) {
              if ($scope.adGroup.name === '320x50' || $scope.adGroup.name === 'New Ad Group') {
                $scope.adGroup.name = campaignManagerFactory.selectedCampaign.name + '_' + $scope.adGroup.product;
                campaignManagerFactory.selectedAdGroup.name = $scope.adGroup.name;
              }
            }
            $scope.tempSelectedDeviceTypes = getSelectedDevices(
              campaignManagerFactory.selectedAdGroup.targeting.deviceTypes
            );

            // We have updated the campaign's adgroup target goal, logging this event into GA
            typeof $window.gtag === 'function' &&
              $window.gtag('event', GAEventList.CAMPAIGN_TARGET_GOAL, {
                adgroup_id: $scope.adGroup?.id,
                campaign_id: campaignManagerFactory.selectedCampaign.id,
                gt_user_id: $window?.user?.id,
                tenant_id: companyAndAccountFactory.selectedTenant?.tenant_id
              });

            $scope.goToNextStep();
            $scope.updateCompletedSteps();
          },
          function(errors) {
            $scope.adGroup.product = oldProduct;
            $scope.showErrorTt = true;
            $scope.errors = {
              goalSelection: errors
            };
          }
        )
        .finally(function() {
          $scope.booleans.saveProgress = false;
        });
    } else {
      $scope.goToNextStep();
      $scope.updateCompletedSteps();
      init();
      $scope.booleans.saveProgress = false;
    }
  };

  function validateTargeting() {
    let error = null;

    if ($scope.selectedGoalCard.value === 'audience' && !$scope.adGroup.targeting.audience.length) {
      error = ['Please select at least one audience.'];
    }
    if ($scope.selectedGoalCard.value === 'neighborhood' && !$scope.adGroup.targeting.neighborhood.length) {
      error = ['Please select at least one neighborhood.'];
    }
    if ($scope.selectedGoalCard.value === 'onpremise' && !$scope.adGroup.targeting.on_premise.length) {
      error = ['Please select at least one on-premise location.'];
    }
    if ($scope.selectedGoalCard.value === 'weather') {
      const hasWeatherCondition =
        $scope.adGroup.targeting.weather.weather_alert.length ||
        $scope.adGroup.targeting.weather.weather_condition.length ||
        $scope.adGroup.targeting.weather.weather_humidity.length ||
        $scope.adGroup.targeting.weather.weather_pressure.length ||
        $scope.adGroup.targeting.weather.weather_rain_probability.length ||
        $scope.adGroup.targeting.weather.weather_temperature.length ||
        $scope.adGroup.targeting.weather.weather_wind_speed.length;
      if (!hasWeatherCondition) {
        error = ['Please select at least one weather condition.'];
      }
    }

    if (error) {
      angular.copy(error, $scope.errors);
      $scope.showErrorTt = true;
      return false;
    }

    return true;
  }

  $scope.saveTargetingForm = function() {
    if (!validateTargeting()) return;

    if ($scope.selectedGoalCard.value !== 'geotargeting' && !$scope.hasTargetingChanged) {
      $scope.goToNextStep();
      $scope.updateCompletedSteps();
      return;
    }

    $scope.booleans.saveProgress = true;
    $scope.showErrorTt = false;
    saveCommonTargetingData(true);
    if (validateAdgroup($scope.booleans, $scope.adGroup)) {
      campaignManagerFactory
        .saveAdGroup($scope.adGroup, campaignManagerFactory.selectedCampaign.id)
        .then(
          function(data) {
            $scope.hasTargetingChanged = false;
            // We have successfully updated this campaign's agroup targeting, logging this event into GA
            typeof $window.gtag === 'function' &&
              $window.gtag('event', GAEventList.CAMPAIGN_TARGET_COMPLETE, {
                adgroup_id: $scope.adGroup?.id,
                campaign_id: campaignManagerFactory.selectedCampaign.id,
                gt_user_id: $window?.user?.id,
                tenant_id: companyAndAccountFactory.selectedTenant?.tenant_id
              });
            $scope.goToNextStep();
            $scope.updateCompletedSteps();
          },
          function(errors) {
            angular.copy(errors, $scope.errors);
          }
        )
        .finally(function() {
          $scope.booleans.saveProgress = false;
          if ($scope.errors && $scope.errors.length) {
            $scope.showErrorTt = true;
          } else {
            $scope.showErrorTt = false;
          }
          generalUtilsFactory.safeApply($scope);
        });
    }
  };

  $scope.saveBudgetAndScheduleForm = function(launch) {
    var progressKey;
    if (!$scope.isChannelTenant) {
      progressKey = 'saveProgress';
      if (launch) {
        progressKey = 'saveAndLaunchProgress';
      }
    } else {
      $scope.booleans.saveAndCloseProgress = true;
    }
    angular.copy([], $scope.errors);
    $scope.showErrorTt = false;
    if ($scope.saveAdgroup.budgetAndSchedules !== null) {
      if (!$scope.isChannelTenant && progressKey !== undefined) {
        $scope.booleans[progressKey] = true;
      }
      $scope.saveAdgroup
        .budgetAndSchedules(launch)
        .then(
          function(data) {
            $scope.$emit('success-toast', { message: 'Ad Group settings updated successfully.' });
            generalUtilsFactory.nestedUpdateInPlace(campaignManagerFactory.selectedAdGroup, $scope.adGroup);
            if ($scope.isChannelTenant) {
              $scope.booleans.loadSubmitForApproval = true; // need to feed something to pop up the cta-btns
              $state.go('campaigns.campaign', { campaignId: campaignManagerFactory.selectedCampaign.id });
            } else {
              if (launch && !$scope.booleans.leadToPayments) {
                $scope.goToNextStep();
              } else if (launch && $scope.booleans.leadToPayments) {
                $state.go('payment-manager');
              }
            }
            $scope.updateCompletedSteps();
          },
          function(errors) {}
        )
        .finally(function() {
          if ($scope.isChannelTenant) {
            $scope.booleans.saveAndCloseProgress = false;
          } else {
            $scope.booleans[progressKey] = false;
          }
          generalUtilsFactory.safeApply($scope);
        });
    }
  };

  $scope.saveCreative = async function(skipIncompleteCreatives = false) {
    if ($scope.adGroup.creatives !== null) {
      $scope.showErrorTt = false;
      $scope.booleans.saveProgress = true;
      try {
        await $scope.creativeListObject.saveCreative($scope.errors, skipIncompleteCreatives);
        generalUtilsFactory.nestedUpdateInPlace(campaignManagerFactory.selectedAdGroup, $scope.adGroup);
        // We have successfully saved all the creatives, logging this event into GA
        typeof $window.gtag === 'function' &&
          $window.gtag('event', GAEventList.CAMPAIGN_CREATIVES_SAVE, {
            adgroup_id: $scope.adGroup?.id,
            campaign_id: campaignManagerFactory.selectedCampaign.id,
            gt_user_id: $window?.user?.id,
            tenant_id: companyAndAccountFactory.selectedTenant?.tenant_id
          });
        $scope.goToNextStep();
        $scope.updateCompletedSteps();
      } catch (errors) {
        console.error(errors);
        $scope.showErrorTt = true;
      } finally {
        $scope.booleans.saveProgress = false;
        generalUtilsFactory.safeApply($scope);
      }
    }
  };

  $scope.goToNextStep = function() {
    if ($scope.currentStepIndex >= $scope.numOfSteps - 1) {
      $state.go('campaigns.campaign', { campaignId: campaignManagerFactory.selectedCampaign.id });
    } else {
      $scope.currentStepIndex++;

      // Set URL fragment based on current step
      const page = $scope.stepToPage[$scope.currentStepIndex];
      $location.hash(page);
    }

    generalUtilsFactory.scrollToTopOfPage();
    $timeout(function() {
      initSidebars();
    });
    init(true);
  };

  $scope.goToPreviousStep = function() {
    // first step
    $scope.booleans.targetingDataLoaded = false;
    if ($scope.currentStepIndex === 0) {
      $state.go('campaigns.campaign', { campaignId: campaignManagerFactory.selectedCampaign.id });
    } else {
      $scope.currentStepIndex--;

      // Set URL fragment based on current step
      const page = $scope.stepToPage[$scope.currentStepIndex];
      $location.hash(page);
    }

    generalUtilsFactory.scrollToTopOfPage();
    $timeout(function() {
      initSidebars();
      $scope.booleans.targetingDataLoaded = true;
    });
  };

  /**
 * This function is usually called when we switch between different pages like targeting, budget, creatives etc.
 * Parameters:
  - index: denotes the new step.
  - finishLoading (optional) : The targetingDataLoaded toggle is being reset for the targeting page every time this function is called when we switch to targeting page.
      However in the targeting page this function is also called during the initialisation (in the init function),
      Hence the finishLoading param is added to skip the reset in that case, it's default value is true, So in all the cases it will reset the toggle
      Except when its called from the init funtion of targeting page.
 */
  $scope.goToStep = function(index, finishLoading = true) {
    if (index > $scope.numOfSteps - 1 || index < 0 || $scope.getCurrentPage() === 'goals') {
      return;
    }
    $scope.booleans.targetingDataLoaded = false;

    // Set URL fragment based on current step
    const page = $scope.stepToPage[index];
    $location.hash(page);

    campaignManagerFactory
      .getSelectedAdGroup()
      .then(function(data) {
        generalUtilsFactory.nestedUpdateInPlace(campaignManagerFactory.selectedAdGroup, $scope.adGroup);
        $scope.updateCompletedSteps();
        $scope.currentStepIndex = index;
        $timeout(function() {
          initSidebars();
        });
        $scope.booleans.selectionTargetingTableChanged = true;
        $scope.booleans.selectionTargetingExcludeTableChanged = true;
        $scope.booleans.selectionLocationFilterTableChanged = true;
        $scope.booleans.selectionMeasurementTableChanged = true;
        $scope.booleans.neighborhoodTableChanged = true;
        $scope.errors = [];
        $scope.showErrorTt = false;

        if ($scope.adGroup.targeting.audience_exclude && $scope.adGroup.targeting.audience_exclude.length) {
          $scope.booleans.showAudienceExclude = true;
        }
        if ($scope.adGroup.targeting.cpgProducts?.length) {
          $scope.booleans.enableCpgProducts = true;
          $scope.booleans.cpgProductsTableChanged = true;
        }
        if (finishLoading) {
          $timeout(function() {
            $scope.booleans.targetingDataLoaded = true;
          });
        }
      })
      .catch(() => ({}));
  };

  $scope.goToSection = function(id) {
    generalUtilsFactory.smoothScrollToElement(id, 350);
  };

  // Figures out which parts of the form have already been completed so the UI can mark them complete
  $scope.updateCompletedSteps = function() {
    $scope.completedSteps.targetingGoal = $scope.adGroup.product && $scope.adGroup.product != 'default';
    $scope.completedSteps.targeting =
      $scope.adGroup.targeting && !isAdgroupTargetingEmpty() && $scope.getCurrentPage() !== 'goals';
    $scope.completedSteps.budgetAndSchedule =
      ($scope.adGroup.totalBudget > 0 ||
        (campaignManagerFactory.selectedCampaign.budgetType === BudgetType.DAILY &&
          !$scope.adGroup.totalBudget &&
          $scope.adGroup.bidRate)) &&
      $scope.getCurrentPage() !== 'goals';
    $scope.completedSteps.adCreatives =
      $scope.adGroup.creatives && $scope.adGroup.creatives.length && $scope.getCurrentPage() !== 'goals';
    // Checks whether atleast one deviceType is selected
    $scope.completedSteps.deviceType =
      $scope.adGroup.targeting.deviceTypes.length > 0 && $scope.getCurrentPage() !== 'goals';
  };

  $scope.openUploadModal = function(runInterval) {
    if (runInterval) {
      $scope.uploadVars.status = 'In Progress';
      $scope.uploadVars.intervalPromise = $interval($scope.checkUploadProgress, PROGRESS_INTERVAL_DELAY);
    } else {
      const modalOptions = {
        templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.superform_upload_modal.html',
        controller: 'SuperformUploadController',
        size: 'xl',
        keyboard: false,
        backdrop: 'static'
      };
      const data = {
        adGroup: $scope.adGroup,
        uploadVars: $scope.uploadVars
      };
      const handlers = {
        cancelUpload: $scope.cancelUpload,
        checkUploadProgress: $scope.checkUploadProgress
      };

      $scope.booleans.uploadModalOpen = true;
      modalFactory.createModal(modalOptions, data, handlers).then(function() {
        $scope.booleans.uploadModalOpen = false;
        $scope.booleans.selectionLocationFilterTableChanged = true;
        $scope.$broadcast(AdsManagerEvent.AdGroupTargetingLocationFilterTableChanged);
      });
    }
  };

  $scope.stopCheckingUploadProgress = function() {
    if ($scope.uploadVars.intervalPromise) {
      $interval.cancel($scope.uploadVars.intervalPromise);
      $scope.uploadVars.intervalPromise = null;
    } else {
      $log.warn("Attempted to cancel upload while there wasn't one in progress.");
    }
  };

  $scope.resetUpload = function() {
    $scope.uploadVars.status = null;
    $scope.uploadVars.progress = 0;
    $scope.uploadVars.fileName = null;
  };

  $scope.cancelUpload = function() {
    var jobId = $scope.adGroup.targeting.from.geotarget_job_id;

    if ($scope.uploadVars.intervalPromise) {
      $scope.stopCheckingUploadProgress();
    }

    $scope.resetUpload();
    jobFactory.cancelJob(jobId);
  };

  $scope.checkUploadProgress = function() {
    var jobId = $scope.adGroup.targeting.from.geotarget_job_id;
    jobFactory.getJobStatus(jobId).then(
      function(data) {
        if ($scope.uploadVars.status === 'In Progress' && data.status === 'Success') {
          $scope.uploadVars.progress = data.value;
          $scope.uploadVars.output_file_url = data.output_url;
          $scope.uploadVars.message = data.message;
          $scope.stopCheckingUploadProgress();
          $timeout(function() {
            // 1s delay so progress bar animation can finish
            $scope.uploadVars.status = 'Complete';
          }, 1000);
        } else if (data.status === 'Failed') {
          $scope.uploadVars.status = 'Failed';
          $scope.uploadVars.errorMsg = data.message;
          $scope.stopCheckingUploadProgress();
        } else if (!$scope.uploadVars.status) {
          $scope.stopCheckingUploadProgress();
        } else {
          $scope.uploadVars.progress = data.value;
        }
      },
      function(data) {
        $scope.uploadVars.errorMsg = data.message;
      }
    );
  };

  $scope.technographicsAllCheck = function() {
    if ($scope.checkAllMajorSections.technographics) {
      $scope.checkOS($scope.technographicsOptions.os.All.value);
      $scope.adGroup.targeting.privacySafeIdfas = false;

      $scope.showAll.carriers = false;
      $scope.toggleAllCarriers(true, true);
      $scope.checkAllMinorSections.carriers = true;
    }
    showWarningIfCrossDeviceEnabled();
  };

  $scope.demographicsAllCheck = function() {
    if ($scope.checkAllMajorSections.demographics) {
      $scope.checkGender($scope.demographicsOptions.gender.All.value);
      $scope.checkIncome($scope.demographicsOptions.income.All.value);
      $scope.checkEthnicity($scope.demographicsOptions.ethnicity.All.value);
      $scope.checkAge($scope.demographicsOptions.age.All.value);
      $scope.adGroup.targeting.twentyOneAndOver = false;
    }
  };

  var saveCommonTargetingData = function(forActualSave) {
    $scope.adGroup.targeting.demographicsAge = [];
    $scope.adGroup.targeting.demographicsIncome = [];
    $scope.adGroup.targeting.demographicsEthnicity = [];
    $scope.adGroup.targeting.demographicsGender = [];

    if (!$scope.checkAllMajorSections.demographics) {
      var allGenderSelected = true;
      $scope.adGroup.targeting.demographicsGender = [];
      for (var genderKey in $scope.demographicsOptions.gender) {
        var gender = $scope.demographicsOptions.gender[genderKey];
        if (
          gender.value == $scope.demographicsOptions.gender.All.value &&
          $scope.selectedDemographics.gender[gender.value]
        ) {
          allGenderSelected = true;
          break;
        } else if (
          gender.value !== $scope.demographicsOptions.gender.All.value &&
          !$scope.selectedDemographics.gender[gender.value]
        ) {
          allGenderSelected = false;
        }
      }
      if (!allGenderSelected) {
        for (var genderKey in $scope.selectedDemographics.gender) {
          if ($scope.selectedDemographics.gender[genderKey]) {
            $scope.adGroup.targeting.demographicsGender.push(genderKey);
          }
        }
      }

      if (!$scope.selectedDemographics.age[$scope.demographicsOptions.age.All.value]) {
        for (var age_key in $scope.selectedDemographics.age) {
          if ($scope.selectedDemographics.age[age_key]) {
            $scope.adGroup.targeting.demographicsAge.push(age_key);
          }
        }
      }

      var allIncomeSelected = true;
      $scope.adGroup.targeting.demographicsIncome = [];
      for (var incomeKey in $scope.demographicsOptions.income) {
        var income = $scope.demographicsOptions.income[incomeKey];
        if (
          income.value == $scope.demographicsOptions.income.All.value &&
          $scope.selectedDemographics.income[income.value]
        ) {
          allIncomeSelected = true;
          break;
        } else if (
          income.value !== $scope.demographicsOptions.income.All.value &&
          !$scope.selectedDemographics.income[income.value]
        ) {
          allIncomeSelected = false;
        }
      }
      if (!allIncomeSelected) {
        for (var incomeKey in $scope.selectedDemographics.income) {
          if ($scope.selectedDemographics.income[incomeKey]) {
            $scope.adGroup.targeting.demographicsIncome.push(incomeKey);
          }
        }
      }

      if (!$scope.selectedDemographics.ethnicity[$scope.demographicsOptions.ethnicity.All.value]) {
        for (var ethnicityKey in $scope.selectedDemographics.ethnicity) {
          if ($scope.selectedDemographics.ethnicity[ethnicityKey]) {
            $scope.adGroup.targeting.demographicsEthnicity.push(ethnicityKey);
          }
        }
      }
    }

    $scope.adGroup.targeting.viewabilityDataProvider = $scope.viewability.dataProvider;
    $scope.adGroup.targeting.viewabilityScore = $scope.viewability.viewabilityScore;
    $scope.adGroup.targeting.measurabilityScore = $scope.viewability.measurabilityScore;
    if ($scope.viewability.dataProvider !== 'moat' && $scope.viewability.dataProvider !== 'dv') {
      $scope.adGroup.targeting.viewabilityScore = null;
      $scope.adGroup.targeting.measurabilityScore = null;
    }

    $scope.adGroup.targeting.technographics = [];

    if (!$scope.checkAllMajorSections.technographics) {
      var allOSSelected = true;
      for (var osKey in $scope.technographicsOptions.os) {
        var os = $scope.technographicsOptions.os[osKey];
        if (os.value == $scope.technographicsOptions.os.All.value && $scope.selectedTechnographics.os[os.value]) {
          allOSSelected = true;
          break;
        } else if (
          os.value !== $scope.technographicsOptions.os.All.value &&
          !$scope.selectedTechnographics.os[os.value]
        ) {
          allOSSelected = false;
        }
      }
      if (!allOSSelected) {
        for (var key in $scope.selectedTechnographics.os) {
          if ($scope.selectedTechnographics.os[key]) {
            $scope.adGroup.targeting.technographics.push(key);
          }
        }
      }

      $scope.adGroup.targeting.publisherTypes = $scope.adGroup.targeting.deviceTypes; // Avails expects these values
    } else {
      $scope.adGroup.targeting.privacySafeIdfas = false;
      if (!forActualSave) {
        $scope.adGroup.targeting.carriers.forEach(function(carrier) {
          carrier.selected = false;
        });
      }
    }

    if ($scope.booleans.isMeasurementNational) {
      $scope.adGroup.targeting.business_profile.national = true;
      $scope.adGroup.targeting.business_profile.profiles.length = 0;
      $scope.adGroup.targeting.measurements.length = 0;
      $scope.adGroup.enableStoreVisitation = false;
      $scope.adGroup.enableStoreVisitationLift = false;
      $scope.booleans.enableCpgProducts = false;
      $scope.adGroup.targeting.cpgProducts.length = 0;
      //$scope.adGroup.creative_overlay = false;
    } else {
      $scope.adGroup.targeting.business_profile.national = false;
    }

    if ($scope.booleans.programmaticAccount && $scope.selectedDeliveryChannel.value) {
      $scope.adGroup.deliveryChannel_id = $scope.selectedDeliveryChannel.value.deliveryChannelId;
    }

    if (!$scope.booleans.enableCpgProducts) {
      $scope.adGroup.targeting.cpgProducts.length = 0;
    }
  };

  var setupCommonTargetingData = function() {
    // Reset major Section checkbox

    // We don't want "All" selections to be done for CTV
    $scope.checkAllMajorSections.technographics =
      !$scope.areStreamingServicesSelected() && !$scope.isDesktopTargetingSelected();
    $scope.checkAllMajorSections.demographics = true;

    if ($scope.adGroup.product.indexOf('audience') > -1 && !$scope.adGroup.targeting.audience.length) {
      $scope.adGroup.targeting.lookalikeAudienceScale = '1';
    }

    if ($scope.adGroup.targeting.lookalikeAudienceScale) {
      $scope.selectedLookalikeAudience =
        $scope.lookalikeAudienceOptions[$scope.adGroup.targeting.lookalikeAudienceScale];
    }

    $scope.viewability.dataProvider = $scope.adGroup.targeting.viewabilityDataProvider;
    $scope.viewability.measurabilityScore = $scope.adGroup.targeting.measurabilityScore;
    $scope.viewability.viewabilityScore = $scope.adGroup.targeting.viewabilityScore;

    if (
      $scope.adGroup.targeting.demographicsGender !== null &&
      $scope.adGroup.targeting.demographicsGender.length > 0
    ) {
      $scope.adGroup.targeting.demographicsGender.forEach(function(gender) {
        if (!featureFlagFactory.isFeatureEnabled('UNKNOWN_GENDER_AGE_READ') && gender === 'Unknown') {
          return;
        }

        $scope.selectedDemographics.gender[gender] = true;
        $scope.checkAllMajorSections.demographics = false;
      });
    } else {
      $scope.checkGender($scope.demographicsOptions.gender.All.value);
    }

    if (
      $scope.adGroup.targeting.demographicsIncome !== null &&
      $scope.adGroup.targeting.demographicsIncome.length > 0
    ) {
      $scope.adGroup.targeting.demographicsIncome.forEach(function(income) {
        $scope.selectedDemographics.income[income] = true;
        $scope.checkAllMajorSections.demographics = false;
      });
    } else {
      $scope.checkIncome($scope.demographicsOptions.income.All.value);
    }

    if (
      $scope.adGroup.targeting.demographicsEthnicity !== null &&
      $scope.adGroup.targeting.demographicsEthnicity.length > 0
    ) {
      $scope.adGroup.targeting.demographicsEthnicity.forEach(function(ethnicity) {
        $scope.selectedDemographics.ethnicity[ethnicity] = true;
        $scope.checkAllMajorSections.demographics = false;
      });
    } else {
      $scope.checkEthnicity($scope.demographicsOptions.ethnicity.All.value);
    }

    if ($scope.adGroup.targeting.demographicsAge !== null && $scope.adGroup.targeting.demographicsAge.length > 0) {
      $scope.adGroup.targeting.twentyOneAndOver = false;

      $scope.adGroup.targeting.demographicsAge.forEach(function(age) {
        if (!featureFlagFactory.isFeatureEnabled('UNKNOWN_GENDER_AGE_READ') && age === 'Unknown') {
          return;
        }

        $scope.selectedDemographics.age[age] = true;
        $scope.checkAllMajorSections.demographics = false;
      });
    } else {
      if ($scope.adGroup.targeting.twentyOneAndOver) {
        $scope.checkAllMajorSections.demographics = false;
        $scope.selectedDemographics.age[$scope.demographicsOptions.age.All.value] = false;
      } else {
        $scope.checkAge($scope.demographicsOptions.age.All.value);
      }
    }

    if ($scope.adGroup.targeting.technographics !== null && $scope.adGroup.targeting.technographics.length > 0) {
      $scope.adGroup.targeting.technographics.forEach(function(targetedOS) {
        for (var key in $scope.technographicsOptions.os) {
          if ($scope.technographicsOptions.os[key].value == targetedOS) {
            $scope.selectedTechnographics.os[$scope.technographicsOptions.os[key].value] = true;
            $scope.checkAllMajorSections.technographics = false;
          }
        }
      });
    } else {
      $scope.checkOS($scope.technographicsOptions.os.All.value);
    }

    if ($scope.adGroup.targeting.carriers !== null) {
      $scope.adGroup.targeting.carriers.forEach(function(carrier) {
        if (carrier.selected) {
          $scope.checkAllMajorSections.technographics = false;
        }
      });
    }

    if ($scope.adGroup.targeting.privacySafeIdfas) {
      $scope.checkAllMajorSections.technographics = false;
    }

    if (!$scope.adGroup.targeting.business_profile.isNew) {
      // OLD MOBILE SITE
      if ($scope.adGroup.targeting.business_profile.national) {
        $scope.booleans.isMeasurementNational = true;
        $scope.adGroup.enableStoreVisitation = false;
        $scope.booleans.enableCpgProducts = false;
        $scope.adGroup.targeting.cpgProducts.length = 0;
        //$scope.adGroup.creative_overlay = false;
        $scope.onMeasurementsTableUpdated = onMeasurementsTableUpdated;
      } else if (campaignManagerFactory.selectedCampaign.is_cpv) {
        $scope.booleans.isMeasurementNational = false;
      } else {
        $scope.disable.locationGroupAutoComplete = true;
        $scope.onMeasurementsTableUpdated = onOldBusinessProfileRemoved;
        $scope.booleans.isMeasurementNational = false;
      }
    } else {
      // NEW MOBILE SITE
      $scope.onMeasurementsTableUpdated = onMeasurementsTableUpdated;
      if ($scope.adGroup.targeting.measurements.length > 0) {
        $scope.booleans.isMeasurementNational = false;
      } else if (campaignManagerFactory.selectedCampaign.is_cpv) {
        $scope.booleans.isMeasurementNational = false;
      } else {
        $scope.adGroup.enableStoreVisitation = false;
        if ($scope.adGroup.targeting.cpgProducts?.length > 0) {
          $scope.booleans.isMeasurementNational = false;
          $scope.booleans.enableCpgProducts = true;
        } else {
          $scope.booleans.isMeasurementNational = true;
          $scope.booleans.enableCpgProducts = false;
        }
      }
    }
  };

  function onOldBusinessProfileRemoved(item) {
    $scope.adGroup.targeting.business_profile.profiles.length = 0;
    $scope.disable.locationGroupAutoComplete = false;
    onMeasurementsTableUpdated(item);
    return item;
  }

  function onMeasurementsTableUpdated(item) {
    if ($scope.adGroup.targeting.measurements && $scope.adGroup.targeting.measurements.length) {
      $scope.adGroup.enableStoreVisitation = true;
    } else {
      $scope.adGroup.enableStoreVisitation = false;
    }
    return item;
  }

  // TODO remove it as soon as we refactor the entire adgroup targeting
  // $scope.toggleEnableLookalike = function() {
  //   if ($scope.adGroup.targeting.lookalikeAudienceScale) {
  //     $scope.adGroup.targeting.lookalikeAudienceScale = null;
  //   } else {
  //     $scope.adGroup.targeting.lookalikeAudienceScale = '1';
  //     $scope.selectedLookalikeAudience = $scope.lookalikeAudienceOptions['1'];
  //   }
  // };

  //// Publisher Settings ////

  function initPublisherSelection() {
    $scope.showAllPublishers = {};
    $scope.defaultPublishersSelected = {};

    $scope.adGroup.targeting.tierPublisherSettings.tiers.forEach(function(tierObj) {
      var selected = _checkAllDefaultPublishersSelected(tierObj.publishers);
      $scope.defaultPublishersSelected[tierObj.tier] = selected;
      $scope.showAllPublishers[tierObj.tier] = !selected;
    });
  }

  $scope.runOfNetworkChange = function() {
    if ($scope.adGroup.targeting.tierPublisherSettings.runOfNetwork) {
      // The runOfNetwork checkbox is now checked, so de-select all individual publishers
      $scope.adGroup.targeting.tierPublisherSettings.tiers.forEach(function(tierObj) {
        tierObj.bidrate = null;
        tierObj.bidtype = null;

        _setAllDefaultPublishers(tierObj, false);
      });
    } else {
      // The runOfNetwork checkbox is now unchecked, so select all the default publishers in each tier
      $scope.adGroup.targeting.tierPublisherSettings.tiers.forEach(function(tierObj) {
        $scope.showAllPublishers[tierObj.tier] = false;
        _setAllDefaultPublishers(tierObj, true);
      });
    }

    if (!$scope.adGroup.targeting.tierPublisherSettings.tiers[0].bidtype) {
      $scope.adGroup.targeting.tierPublisherSettings.tiers[0].bidtype = 'CPM';
    }
  };

  $scope.toggleExchangePublisher = function() {
    var allSelected = _checkAllDefaultPublishersSelected(
      $scope.adGroup.targeting.tierPublisherSettings.tiers[0].publishers
    );
    $scope.defaultPublishersSelected['EXCHANGE'] = allSelected;
  };

  $scope.toggleTierCPublisher = function(toggle_publisher) {
    var allSelected = _checkAllDefaultPublishersSelected(
      $scope.adGroup.targeting.tierPublisherSettings.tiers[1].publishers
    );
    $scope.defaultPublishersSelected['TIERC'] = allSelected;
  };

  $scope.toggleShowAllExchangePublishers = function() {
    var tier = $scope.adGroup.targeting.tierPublisherSettings.tiers[0];
    $scope.showAllPublishers[tier.tier] = !$scope.showAllPublishers[tier.tier];
    _setAllDefaultPublishers(tier, true);
  };

  $scope.toggleShowAllTierCPublishers = function() {
    var tier = $scope.adGroup.targeting.tierPublisherSettings.tiers[1];
    $scope.showAllPublishers[tier.tier] = !$scope.showAllPublishers[tier.tier];
    _setAllDefaultPublishers(tier, true);
  };

  $scope.toggleSelectAllExchangePublishers = function() {
    var tier = $scope.adGroup.targeting.tierPublisherSettings.tiers[0];
    _setAllDefaultPublishers(tier, !$scope.defaultPublishersSelected[tier.tier]);
  };

  $scope.toggleSelectAllTierCPublishers = function() {
    var tier = $scope.adGroup.targeting.tierPublisherSettings.tiers[1];
    _setAllDefaultPublishers(tier, !$scope.defaultPublishersSelected[tier.tier]);
  };

  function _checkAllDefaultPublishersSelected(publishers) {
    for (let publisher of publishers) {
      if (
        ($scope.adGroup.is_cbd && !publisher.selected) ||
        (!$scope.adGroup.is_cbd && !!publisher.selected == !!publisher.cbd_supported)
      )
        return false;
    }
    return true;
  }

  function _setAllDefaultPublishers(tier, value) {
    $scope.defaultPublishersSelected[tier.tier] = value;
    tier.publishers.forEach(function(publisher) {
      publisher.selected = value && ($scope.adGroup.is_cbd || !publisher.cbd_supported);
    });
  }

  // TODO Remove it as soon as we refactor all adgroup targeting
  // $scope.selectLookalikeAudienceScale = function(selectedLookalikeAudience) {
  //   $scope.adGroup.targeting.lookalikeAudienceScale = selectedLookalikeAudience.value;
  // };

  $scope.toggleAllChannels = function(value, skipShowAll) {
    if (!skipShowAll) {
      $scope.showAll.channels = !$scope.showAll.channels;
    }

    var channels = $scope.adGroup.targeting.publisherSettings;
    if (skipShowAll && !value) {
      var anySelected = false;
      var allSelected = true;
      channels.forEach(function(channel) {
        anySelected = anySelected || channel.selected;
        allSelected = allSelected && channel.selected;
      });
      // If some items have been selected (but not all),
      // all items should now get toggled on.
      if (anySelected && !allSelected) {
        value = true;
      }
    }

    $scope.selectAll.channels = value;
    channels.forEach(function(channel) {
      channel.selected = value;
    });
  };

  $scope.toggleAllCarriers = function(value, skipShowAll) {
    if (!skipShowAll) {
      $scope.showAll.carriers = !$scope.showAll.carriers;
    }

    var carriers = $scope.adGroup.targeting.carriers;
    if (skipShowAll && !value) {
      var anySelected = false;
      var allSelected = true;
      carriers.forEach(function(carrier) {
        anySelected = anySelected || carrier.selected;
        allSelected = allSelected && carrier.selected;
      });
      // If some items have been selected (but not all),
      // all items should now get toggled on.
      if (anySelected && !allSelected) {
        value = true;
      }
    }

    $scope.selectAll.carriers = value;
    carriers.forEach(function(carrier) {
      carrier.selected = value;
    });
  };

  $scope.toggleChannels = function() {
    var allChannelSelected = true;

    $scope.adGroup.targeting.publisherSettings.forEach(function(channel) {
      allChannelSelected = allChannelSelected && channel.selected;
    });

    $scope.checkAllMinorSections.channels = allChannelSelected;
  };

  $scope.toggleCarriers = function() {
    var allCarriersSelected = true;

    $scope.adGroup.targeting.carriers.forEach(function(carrier) {
      allCarriersSelected = allCarriersSelected && carrier.selected;
    });

    $scope.checkAllMinorSections.carriers = allCarriersSelected;
  };

  function showOrHideChannelsOnLoad() {
    var channels = $scope.adGroup.targeting.publisherSettings;
    var showChannels = false;
    for (var i = 0; i < channels.length; i++) {
      if (channels[i].selected) {
        showChannels = true;
        break;
      }
    }

    $scope.showAll.channels = showChannels;
    $scope.checkAllMinorSections.channels = !showChannels;
  }

  function showOrHideCarriersOnLoad() {
    var carriers = $scope.adGroup.targeting.carriers;
    var showCarriers = false;
    for (var i = 0; i < carriers.length; i++) {
      if (carriers[i].selected) {
        showCarriers = true;
        break;
      }
    }

    $scope.showAll.carriers = showCarriers;
    $scope.checkAllMinorSections.carriers = !showCarriers;
  }

  $scope.checkIncome = function(selectedIncome) {
    if (selectedIncome && selectedIncome == $scope.demographicsOptions.income.All.value) {
      $scope.selectedDemographics.income[$scope.demographicsOptions.income.All.value] = true;
      for (var income in $scope.selectedDemographics.income) {
        if (income == $scope.demographicsOptions.income.All.value) {
          $scope.selectedDemographics.income[income] = true;
        } else {
          $scope.selectedDemographics.income[income] = false;
        }
      }
    } else {
      $scope.selectedDemographics.income[$scope.demographicsOptions.income.All.value] = false;
    }
  };

  $scope.checkGender = function(selectedGender) {
    if (selectedGender && selectedGender == $scope.demographicsOptions.gender.All.value) {
      $scope.selectedDemographics.gender[$scope.demographicsOptions.gender.All.value] = true;
      for (var gender in $scope.selectedDemographics.gender) {
        if (gender == $scope.demographicsOptions.gender.All.value) {
          $scope.selectedDemographics.gender[gender] = true;
        } else {
          $scope.selectedDemographics.gender[gender] = false;
        }
      }
    } else {
      $scope.selectedDemographics.gender[$scope.demographicsOptions.gender.All.value] = false;
    }
  };

  $scope.checkEthnicity = function(selectedEthnicity) {
    if (selectedEthnicity && selectedEthnicity == $scope.demographicsOptions.ethnicity.All.value) {
      $scope.selectedDemographics.ethnicity[$scope.demographicsOptions.ethnicity.All.value] = true;
      for (var ethnicity in $scope.selectedDemographics.ethnicity) {
        if (ethnicity == $scope.demographicsOptions.ethnicity.All.value) {
          $scope.selectedDemographics.ethnicity[ethnicity] = true;
        } else {
          $scope.selectedDemographics.ethnicity[ethnicity] = false;
        }
      }
    } else {
      $scope.selectedDemographics.ethnicity[$scope.demographicsOptions.ethnicity.All.value] = false;
    }
  };

  $scope.checkAge = function(selectedAge) {
    $scope.adGroup.targeting.twentyOneAndOver = false;

    if (selectedAge && selectedAge == $scope.demographicsOptions.age.All.value) {
      $scope.selectedDemographics.age[$scope.demographicsOptions.age.All.value] = true;
      for (var age in $scope.selectedDemographics.age) {
        if (age == $scope.demographicsOptions.age.All.value) {
          $scope.selectedDemographics.age[age] = true;
        } else {
          $scope.selectedDemographics.age[age] = false;
        }
      }
    } else {
      $scope.selectedDemographics.age[$scope.demographicsOptions.age.All.value] = false;
    }
  };

  $scope.checkOS = function(selectedOs) {
    var selectedOS = selectedOs;
    if (selectedOs && selectedOs == $scope.technographicsOptions.os.All.value) {
      $scope.selectedTechnographics.os[$scope.technographicsOptions.os.All.value] = true;
      for (var os in $scope.selectedTechnographics.os) {
        if (os == $scope.technographicsOptions.os.All.value) {
          $scope.selectedTechnographics.os[os] = true;
        } else {
          $scope.selectedTechnographics.os[os] = false;
        }
      }
    } else {
      $scope.selectedTechnographics.os[$scope.technographicsOptions.os.All.value] = false;
    }
  };

  const showWarningIfCrossDeviceEnabled = () => {
    $scope.crossDeviceToolTip = null;
  };

  $scope.twentyOneAndOverSelected = function() {
    if ($scope.adGroup.targeting.twentyOneAndOver) {
      $scope.checkAge($scope.demographicsOptions.age.All.value);
      $scope.selectedDemographics.age[$scope.demographicsOptions.age.All.value] = false;
      $scope.adGroup.targeting.twentyOneAndOver = true;
    } else {
      $scope.selectedDemographics.age[$scope.demographicsOptions.age.All.value] = true;
    }
  };

  $scope.optimizationGoalChanged = function() {
    if (featureFlagFactory.isFeatureEnabled('OPTIMIZATION_STRATEGY_WRITE')) {
      switchOptimizationInfoMessage();
      $scope.resetOptimizationGoalDefault();
    }
  };

  function switchOptimizationInfoMessage() {
    for (let i = 0; i < $scope.optimizationGoals.length; i++) {
      if ($scope.adGroup.optimization_strategy === $scope.optimizationGoals[i].value) {
        if (featureFlagFactory.isFeatureEnabled('CHANNEL_OPTIMIZATION_STRATEGY_MESSAGE')) {
          $scope.optimization_info = $scope.channelOptimizationInfoMessage[i];
        } else {
          $scope.optimization_info = $scope.optimizationInfoMessage[i];
        }
        $scope.optimization_secondary_info = $scope.secondaryOptimizationInfoMessage[i];
      }
    }
  }

  $scope.resetOptimizationGoalDefault = function() {
    // when switch strategies, always display the default values of default strategy
    // if not default strategy, then our default settings for each strategy come in
    if ($scope.adGroup.optimization_strategy === 'delivery') {
      resetThresholdToDefault();
      return;
    }
    resetPercentageText();
    if ($scope.adGroup.optimization_strategy === $scope.optimizationGoals[0].value) {
      $scope.booleans.conversion_auto = false;
      $scope.booleans.ctr_auto = true;
      $scope.booleans.sar_auto = false;
      $scope.psvr_auto = false;

      $scope.adGroup.ctr_threshold = null;
      $scope.adGroup.sar_threshold = 0;
      $scope.adGroup.conversion_threshold = 0;
      $scope.adGroup.psvr_threshold = 0;
    } else if ($scope.adGroup.optimization_strategy === $scope.optimizationGoals[1].value) {
      $scope.booleans.conversion_auto = false;
      $scope.booleans.ctr_auto = false;
      $scope.booleans.sar_auto = false;
      $scope.psvr_auto = false;

      $scope.adGroup.ctr_threshold = 0;
      $scope.adGroup.sar_threshold = 0;
      $scope.adGroup.conversion_threshold = 0;
      $scope.adGroup.psvr_threshold = 0;
    } else if ($scope.adGroup.optimization_strategy === $scope.optimizationGoals[2].value) {
      $scope.booleans.conversion_auto = false;
      $scope.booleans.ctr_auto = false;
      $scope.booleans.sar_auto = true;
      $scope.psvr_auto = false;

      $scope.adGroup.ctr_threshold = 0;
      $scope.adGroup.sar_threshold = null;
      $scope.adGroup.conversion_threshold = 0;
      $scope.adGroup.psvr_threshold = 0;
    } else if ($scope.adGroup.optimization_strategy === $scope.optimizationGoals[3].value) {
      $scope.booleans.conversion_auto = true;
      $scope.booleans.ctr_auto = false;
      $scope.booleans.sar_auto = false;
      $scope.psvr_auto = false;

      $scope.adGroup.ctr_threshold = 0;
      $scope.adGroup.sar_threshold = 0;
      $scope.adGroup.conversion_threshold = null;
      $scope.adGroup.psvr_threshold = 0;
    } else if ($scope.adGroup.optimization_strategy === $scope.optimizationGoals[4].value) {
      $scope.booleans.conversion_auto = false;
      $scope.booleans.ctr_auto = false;
      $scope.booleans.sar_auto = false;
      $scope.booleans.psvr_auto = true;

      $scope.adGroup.ctr_threshold = 0;
      $scope.adGroup.sar_threshold = 0;
      $scope.adGroup.conversion_threshold = 0;
      $scope.adGroup.psvr_threshold = null;
    }
    setPercentageText();
  };

  var setPercentageText = function() {
    var ctr = $scope.adGroup.ctr_threshold;
    var sar = $scope.adGroup.sar_threshold;
    var con = $scope.adGroup.conversion_threshold;
    var psvr = $scope.adGroup.psvr_threshold;

    if (ctr >= 0.0 && ctr <= 1.0) {
      $scope.vars.ctr = parseFloat(ctr) + '%';
    }
    if (sar >= 0.0 && sar <= 1.0) {
      $scope.vars.sar = parseFloat(sar) + '';
    }
    if (con >= 0.0 && con <= 1.0) {
      $scope.vars.con = parseFloat(con) + '%';
    }
    if (psvr >= 0.0 && psvr <= 1.0) {
      $scope.vars.psvr = generalUtilsFactory.convertDecimalToPercent(psvr, false);
    }

    // these are for init function
    if ($scope.booleans.ctr_auto) {
      $scope.vars.ctr = '';
    }
    if ($scope.booleans.sar_auto) {
      $scope.vars.sar = '';
    }
    if ($scope.booleans.conversion_auto) {
      $scope.vars.con = '';
    }
    if ($scope.booleans.psvr_auto) {
      $scope.vars.psvr = '';
    }
  };

  var resetPercentageText = function() {
    $scope.vars.ctr = '';
    $scope.vars.sar = '';
    $scope.vars.con = '';
    $scope.vars.psvr = '';
  };

  var resetThresholdToDefault = function() {
    // reset the auto checkbox to false
    $scope.booleans.ctr_auto = false;
    $scope.booleans.sar_auto = false;
    $scope.booleans.conversion_auto = false;
    $scope.booleans.psvr_auto = false;

    // check if we need check the auto checkbox
    if (
      $scope.adGroup.optimization_strategy === $scope.optimizationGoals[0].value &&
      $scope.adGroup.ctr_threshold == null
    ) {
      $scope.booleans.ctr_auto = true;
    } else if (
      $scope.adGroup.optimization_strategy === $scope.optimizationGoals[2].value &&
      $scope.adGroup.sar_threshold == null
    ) {
      $scope.booleans.sar_auto = true;
    } else if (
      $scope.adGroup.optimization_strategy === $scope.optimizationGoals[3].value &&
      $scope.adGroup.conversion_threshold == null
    ) {
      $scope.booleans.conversion_auto = true;
    } else if (
      $scope.adGroup.optimization_strategy === $scope.optimizationGoals[4].value &&
      $scope.adGroup.psvr_threshold == null
    ) {
      $scope.booleans.psvr_auto = true;
    }

    // set the percentage text
    setPercentageText();

    // switch info content
    switchOptimizationInfoMessage();
  };

  /**
   * When 'AUTO' checkbox is checked/unchecked, update appropriate threshold value.
   */
  $scope.defaultValueCheck = function(target) {
    // checked
    if ($scope.booleans.conversion_auto) {
      $scope.adGroup.conversion_threshold = null;
      $scope.vars.con = '';
    } else if ($scope.booleans.ctr_auto) {
      $scope.adGroup.ctr_threshold = null;
      $scope.vars.ctr = '';
    } else if ($scope.booleans.sar_auto) {
      $scope.adGroup.sar_threshold = null;
      $scope.vars.sar = '';
    } else if ($scope.booleans.psvr_auto) {
      $scope.adGroup.psvr_threshold = null;
      $scope.vars.psvr = '';
    } else {
      // unchecked
      if (target === 'ctr') {
        $scope.adGroup.ctr_threshold = 0.3;
      } else if (target === 'sar') {
        $scope.adGroup.sar_threshold = 3;
      } else if (target === 'conversion') {
        $scope.adGroup.conversion_threshold = 0;
        $scope.adGroup.ctr_threshold = 0.3;
        setPercentageText();
      } else if (target === 'visit') {
        $scope.adGroup.psvr_threshold = 0.8;
        setPercentageText();
      } else {
        setPercentageText();
      }
    }
  };

  /**
   * Normalizes/formats threshold text in the view based on the model.
   */
  $scope.thresholdNormalize = function(type) {
    var ctr = $scope.adGroup.ctr_threshold;
    var sar = $scope.adGroup.sar_threshold;
    var con = $scope.adGroup.conversion_threshold;
    var psvr = $scope.adGroup.psvr_threshold;

    if (type === 'ctr_threshold') {
      // when the threshold value is invalid, it will be undefined
      if (ctr === undefined) {
        $scope.vars.ctr = '';
        return;
      }
      $scope.adGroup.ctr_threshold = generalUtilsFactory.keepDecimalNoRounding(ctr, 2);
      $scope.vars.ctr = generalUtilsFactory.getPercentageTextFromDecimal($scope.adGroup.ctr_threshold);
    } else if (type === 'sar_threshold') {
      if (sar === undefined) {
        $scope.vars.sar = '';
        return;
      }
      $scope.adGroup.sar_threshold = generalUtilsFactory.keepDecimalNoRounding(sar, 2);
      $scope.vars.sar = $scope.adGroup.sar_threshold;
    } else if (type === 'conversion_threshold') {
      if (con === undefined) {
        $scope.vars.con = '';
        return;
      }
      $scope.adGroup.conversion_threshold = generalUtilsFactory.keepDecimalNoRounding(con, 2);
      $scope.vars.con = generalUtilsFactory.getPercentageTextFromDecimal($scope.adGroup.conversion_threshold);
    } else if (type === 'psvr_threshold') {
      if (psvr === undefined) {
        $scope.vars.psvr = '';
        return;
      }
      $scope.adGroup.psvr_threshold = generalUtilsFactory.keepDecimalNoRounding(psvr, 2);
      $scope.vars.psvr = generalUtilsFactory.convertDecimalToPercent($scope.adGroup.psvr_threshold, false);
    }
  };

  $scope.isDesktopCompatible = function() {
    return (
      $scope.featureFlagFactory.isFeatureEnabled('DESKTOP_TARGETING') &&
      ($scope.selectedGoalCard.value === 'audience' ||
        $scope.selectedGoalCard.value === 'geotargeting' ||
        $scope.selectedGoalCard.value === 'neighborhood') &&
      !campaignManagerFactory?.selectedCampaign?.is_cpv &&
      !$scope.booleans.programmaticAccount
    );
  };

  function isStreamingServicesCompatible() {
    /**
     * Checks if the adgroup should be able to select `CTV` device type option
     */
    return (
      ($scope.selectedGoalCard.value === 'audience' ||
        $scope.selectedGoalCard.value === 'geotargeting' ||
        $scope.selectedGoalCard.value === 'neighborhood' ||
        $scope.selectedGoalCard.value === 'weather') &&
      $scope.featureFlagFactory.isFeatureEnabled('CTV') &&
      !campaignManagerFactory?.selectedCampaign?.is_cpv &&
      (!$scope.booleans.programmaticAccount || $scope.featureFlagFactory.isFeatureEnabled('PROGRAMMATIC_CTV_OTT'))
    );
  }

  function isAudioServicesCompatible() {
    /**
     * Checks if the adgroup should be able to select `Audio` device type option
     */
    return (
      ($scope.selectedGoalCard.value == 'audience' || $scope.selectedGoalCard.value == 'geotargeting') &&
      $scope.featureFlagFactory.isFeatureEnabled('AUDIO_ADS') &&
      companyAndAccountFactory.isAccountInCountry('US')
    );
  }

  function isAdgroupTargetingEmpty() {
    return (
      $scope.adGroup.targeting.measurements.length === 0 &&
      $scope.adGroup.targeting.location_filters.length === 0 &&
      $scope.adGroup.targeting.audience.length === 0 &&
      $scope.adGroup.targeting.neighborhood.length === 0 &&
      $scope.adGroup.targeting.on_premise.length === 0 &&
      $scope.adGroup.targeting.cpgProducts.length === 0 &&
      isAdGroupWeatherTargetingEmpty()
    );
  }

  /**
   * Check the length for each property array in weather targeting and return true if they are empty.
   * @returns {boolean} True when all properties in weather targeting are empty, false otherwise
   */
  function isAdGroupWeatherTargetingEmpty() {
    const weather = $scope.adGroup.targeting.weather;
    const keys = Object.keys(weather);
    return keys.filter(key => weather[key]?.length > 0).length == 0;
  }

  function isEmptyAdgroup() {
    return (
      $scope.adGroup.creatives.length === 0 &&
      ($scope.adGroup.totalBudget === 0 ||
        ($scope.adGroup.totalBudget === null &&
          campaignManagerFactory.selectedCampaign.budgetType === BudgetType.DAILY)) &&
      isAdgroupTargetingEmpty()
    );
  }

  $scope.saveDeviceTypes = async function() {
    const campaignId = campaignManagerFactory.selectedCampaign.id;
    const adgroupId = $scope.adGroup.id;
    const data = {
      device_types: $scope.tempSelectedDeviceTypes
    };
    try {
      if (!$scope.tempSelectedDeviceTypes.length) {
        throw new Error('Please make a selection before proceeding');
      }

      const showUserConfirmationModal = shouldClearAdgroupData($scope.adGroup.targeting.deviceTypes, data.device_types);

      if (showUserConfirmationModal && !isEmptyAdgroup()) {
        const userClickOk = await showUserConfirmation();
        if (!userClickOk) {
          return;
        }
      }
      $scope.booleans.saveProgress = true;
      await campaignManagerFactory.saveDeviceTypes(campaignId, adgroupId, data);
      const transformedDeviceTypes = transformDeviceTypes($scope.adGroup.targeting.deviceTypes);
      if (!angular.equals(transformedDeviceTypes, data.device_types)) {
        // no need to reload when the device type selections are unchanged
        await $state.reload();
      }
      // We have updated the campaign's adgroup device types, logging this event into GA
      typeof $window.gtag === 'function' &&
        $window.gtag('event', GAEventList.CAMPAIGN_DEVICE_TYPE, {
          campaign_id: campaignId,
          adgroup_id: adgroupId,
          gt_user_id: $window?.user?.id,
          tenant_id: companyAndAccountFactory.selectedTenant?.tenant_id
        });
      await $scope.goToNextStep();
    } catch (e) {
      $scope.showErrorTt = true;
      $scope.errors = e?.data?.message ? [e.data.message] : [e.message];
    } finally {
      $scope.booleans.saveProgress = false;
      generalUtilsFactory.safeApply($scope);
    }
  };

  /**
   * Converts the device types: mobile web, mobile app, desktop, ctv
   * to their comparable counterparts in device type page:
   *
   * e.g:
   *  Mobile Web, App (Mobile App) -> mobile
   *  Desktop -> desktop
   *  CTV -> ctv
   *
   * so:
   *  ['App', 'Desktop'] -> ['mobile', 'desktop']
   */
  function transformDeviceTypes(deviceTypes) {
    let transformedDevices = [];
    if (deviceTypes.length) {
      if (deviceTypes.includes($scope.technographicsOptions.device.MobileApp.value)) {
        transformedDevices.push('mobile');
      }
      if (deviceTypes.includes($scope.technographicsOptions.device.MobileWeb.value)) {
        transformedDevices.push('web');
      }
      if (deviceTypes.includes($scope.technographicsOptions.device.Desktop.value)) {
        transformedDevices.push('desktop');
      }
      if (deviceTypes.includes($scope.technographicsOptions.device.CTV.value)) {
        transformedDevices.push('ctv');
      }
      if (deviceTypes.includes($scope.technographicsOptions.device.OTT.value)) {
        transformedDevices.push('ott');
      }
    } else {
      transformedDevices.push('app');
    }
    return transformedDevices;
  }

  /**
   * @returns {Promise<boolean>}
   */
  async function showUserConfirmation() {
    const modalSetting = {
      title: 'Change Device Type',
      message:
        'Changing the device type may reset some of the targeting settings and creatives for this adgroup. Please review before proceeding.',
      noText: 'Cancel',
      yesText: 'OK'
    };

    return await modalFactory.simpleConfirm(modalSetting);
  }

  /**
   * Used to determine if device types were changed from/to 'ctv' or 'ott'.
   * @returns {boolean}
   */
  function shouldClearAdgroupData(originalDeviceTypes, newDeviceTypes) {
    /**
     * Following case(s) don't require clearing adgroup data:
     *   1. CTV -> OTT: will reduce the min bid rate, and thus the adgroup setup will still remain valid.
     *   2. app/web -> app/web: all the adgroups settings remain the same
     *   3. Device types are unchanged
     */
    if (isEqual(getSelectedDevices(originalDeviceTypes), newDeviceTypes)) {
      return false;
    } else if (
      // app/web -> app/web
      newDeviceTypes.includes('app') &&
      (originalDeviceTypes.includes($scope.technographicsOptions.device.MobileApp.value) ||
        originalDeviceTypes.includes($scope.technographicsOptions.device.MobileWeb.value))
    ) {
      return false;
    } else if (
      // ctv -> ott
      newDeviceTypes.includes('ott') &&
      originalDeviceTypes.includes($scope.technographicsOptions.device.CTV.value)
    ) {
      return false;
    }
    return true;
  }

  function areStreamingServicesSelected() {
    return (
      $scope.adGroup?.targeting?.deviceTypes.includes($scope.technographicsOptions.device.CTV.value) ||
      $scope.adGroup?.targeting?.deviceTypes.includes($scope.technographicsOptions.device.OTT.value)
    );
  }

  function isTargetTypeGeoTargetingWithStreamingRadialFeatureFlag() {
    return (
      $scope.selectedGoalCard.value === 'geotargeting' &&
      featureFlagFactory.isFeatureEnabled('CTV_OTT_RADIAL_TARGETING')
    );
  }

  function isDesktopTargetingSelected() {
    return (
      $scope.adGroup?.targeting?.deviceTypes.includes($scope.technographicsOptions.device.Desktop.value) &&
      featureFlagFactory.isFeatureEnabled('DESKTOP_TARGETING')
    );
  }

  function validateCpgProductSelection(enableCpgProducts, cpgProducts) {
    if (enableCpgProducts && !cpgProducts?.length) {
      throw new Error('In-Stock Targeting requires a UPC code to be selected');
    }
  }

  $scope.desktopOrStreamingServicesSelected = function() {
    return areStreamingServicesSelected() || isDesktopTargetingSelected();
  };

  $scope.showDeviceTargetingNavElement = function() {
    return (
      !$scope.desktopOrStreamingServicesSelected() &&
      featureFlagFactory.isFeatureEnabled('ADGROUP_TECHNOGRAPHIC_TARGETING_READ') &&
      !$scope.isAudioAdGroup()
    );
  };

  $scope.showDemographicsNavElement = function() {
    if ($scope.desktopOrStreamingServicesSelected()) {
      return featureFlagFactory.isFeatureEnabled('GENDER_AND_AGE_TARGETING_FOR_STREAMING_AND_DESKTOP');
    } else {
      return true;
    }
  };

  $scope.showOptimizationStrategyNavElement = function() {
    return !(
      (campaignManagerFactory.selectedCampaign?.is_cpv && !featureFlagFactory.isFeatureEnabled('VISIT_OPTIMIZATION')) ||
      !featureFlagFactory.isFeatureEnabled('OPTIMIZATION_STRATEGY_READ') ||
      areStreamingServicesSelected()
    );
  };

  $scope.showAdvancedPublisherSettingsNavElement = function() {
    return $scope.desktopOrStreamingServicesSelected()
      ? featureFlagFactory.isFeatureEnabled('TIER_SETTINGS_READ')
      : !$scope.isAudioAdGroup();
  };

  $scope.showViewabilityNavElement = function() {
    return (
      !$scope.desktopOrStreamingServicesSelected() &&
      featureFlagFactory.isFeatureEnabled('VIEWABILITY_READ') &&
      !$scope.isAudioAdGroup()
    );
  };

  $scope.getCurrentPage = function() {
    return $scope.stepToPage[$scope.currentStepIndex];
  };

  $scope.isAudioAdGroup = function() {
    const deviceTypes = new Set($scope.adGroup.targeting.deviceTypes ?? []);
    const audioTypes = [
      $scope.technographicsOptions.device.AudioPodcast.value,
      $scope.technographicsOptions.device.AudioStreaming.value
    ];
    return audioTypes.some(audioType => deviceTypes.has(audioType));
  };

  function validateAdgroup(booleans, adgroup) {
    try {
      validateCpgProductSelection(booleans.enableCpgProducts, adgroup.targeting.cpgProducts);
    } catch (e) {
      $scope.showErrorTt = true;
      $scope.errors = [e?.message];
      $scope.booleans.saveProgress = false;
    } finally {
      return !$scope.showErrorTt;
    }
  }

  $scope.areStreamingServicesSelected = areStreamingServicesSelected;
  $scope.isDesktopTargetingSelected = isDesktopTargetingSelected;
  $scope.validateAdgroup = validateAdgroup;
  $scope.validateCpgProductSelection = validateCpgProductSelection;
  init();
}
