'use strict';

export function companyAccountSelect() {
  var directive = {
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.company_account_select.html',
    bindToController: true,
    controller: CompanyAccountSelectController,
    controllerAs: 'vm',
    link: link,
    restrict: 'E',
    scope: {}
  };
  return directive;

  function link(scope, element, attrs) {}
}

CompanyAccountSelectController.$inject = [
  '$rootScope',
  '$state',
  '$filter',
  'companyAndAccountFactory',
  'tenantFactory',
  'featureFlagFactory',
  'userFactory'
];

/**
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {ng.IFilterService} $filter
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {import('../../../factories/types').UserFactoryService} userFactory
 */
function CompanyAccountSelectController(
  $rootScope,
  $state,
  $filter,
  companyAndAccountFactory,
  tenantFactory,
  featureFlagFactory,
  userFactory
) {
  var vm = this;

  vm.progress = false;
  vm.showDropdown = false;

  vm.featureFlagFactory = featureFlagFactory;
  vm.currentCompanyName = companyAndAccountFactory.selectedCompany?.name ?? '';
  vm.currentAccountName = companyAndAccountFactory.selectedAccount?.name ?? '';
  vm.tenants = companyAndAccountFactory.tenants ? $filter('orderBy')(companyAndAccountFactory.tenants, 'name') : [];
  vm.selectedTenant = companyAndAccountFactory.requireSelectedTenant().tenant_id;
  vm.companies = companyAndAccountFactory.companiesAccounts
    ? $filter('orderBy')(companyAndAccountFactory.companiesAccounts, 'name')
    : [];
  vm.selectedCompany = companyAndAccountFactory.selectedCompany ? companyAndAccountFactory.selectedCompany.id : null;
  vm.accounts =
    companyAndAccountFactory.selectedCompany && companyAndAccountFactory.selectedCompany.accounts
      ? $filter('orderBy')(companyAndAccountFactory.selectedCompany.accounts, 'name')
      : [];
  vm.selectedAccount = companyAndAccountFactory.selectedAccount?.id ?? null;
  vm.isSudoerUser = userFactory.currentLoggedInUser.is_sudoer;
  vm.isHoldingCompany = companyAndAccountFactory.selectedTenant?.is_holding_company;

  vm.toggleDropdown = toggleDropdown;
  vm.closeDropdown = closeDropdown;
  vm.changeSelectedTenant = changeSelectedTenant;
  vm.changeSelectedCompany = changeSelectedCompany;
  vm.changeSelectedAccount = changeSelectedAccount;

  vm.tenantChangeListener = null;

  this.$onInit = () => {
    vm.tenantChangeListener = $rootScope.$on('updateTenantCompanyAccount', resetAfterTenantChange);
  };

  this.$onDestroy = () => {
    vm.tenantChangeListener();
  };

  function toggleDropdown(boolean) {
    vm.showDropdown = boolean === false ? boolean : !vm.showDropdown;
  }

  function closeDropdown() {
    vm.showDropdown = false;
  }

  function changeSelectedTenant() {
    vm.progress = true;

    $state
      .go('campaigns.dashboard', { tenantId: vm.selectedTenant, accountId: null, companyId: null })
      .then(function() {
        vm.progress = false;
        resetAfterTenantChange();
      });
  }

  function changeSelectedCompany() {
    const accounts = angular.copy(companyAndAccountFactory.getAccountsForCompany(vm.selectedCompany));
    if (accounts) {
      vm.accounts = $filter('orderBy')(accounts, 'name');
    }
    vm.selectedAccount = null;
  }

  function changeSelectedAccount() {
    vm.progress = true;
    closeDropdown();

    var stateParams = {
      tenantId: vm.selectedTenant,
      companyId: vm.selectedCompany,
      accountId: vm.selectedAccount
    };
    $state.go('campaigns.dashboard', stateParams, { reload: true }).then(function() {
      vm.progress = false;
    });
  }

  function resetAfterTenantChange(e, tenantId) {
    // Reload tenant details if tenantId does not match previous tenant.
    tenantFactory.getTenantDetail(tenantId);

    vm.selectedTenant = companyAndAccountFactory.requireSelectedTenant().tenant_id;
    vm.currentCompanyName = '';
    vm.currentAccountName = '';
    vm.selectedCompany = null;
    vm.selectedAccount = null;
    vm.companies = companyAndAccountFactory.companiesAccounts
      ? $filter('orderBy')(companyAndAccountFactory.companiesAccounts, 'name')
      : [];
    vm.accounts = [];

    if (companyAndAccountFactory.selectedCompany && companyAndAccountFactory.selectedAccount) {
      vm.selectedCompany = companyAndAccountFactory.selectedCompany.id;
      vm.currentCompanyName = companyAndAccountFactory.selectedCompany.name;
      vm.accounts = $filter('orderBy')(companyAndAccountFactory.selectedCompany.accounts, 'name');
      vm.currentAccountName = companyAndAccountFactory.selectedAccount.name;
      vm.selectedAccount = companyAndAccountFactory.selectedAccount.id;
    } else {
      if ($state.current.name !== 'company') {
        $state.go('organization', {}, { reload: true });
      }
    }
  }
}
