import { Audience, AudienceCategory, AudienceType } from '../../../../api/http/data.generated';

export const isAudienceType = (item: AnyTaxonomyItem): item is AudienceType => {
  return (item as AudienceType).categories !== undefined;
};

export const isAudienceCategory = (item: AnyTaxonomyItem): item is AudienceCategory => {
  return (item as AudienceCategory).audiences !== undefined;
};

export const isAudience = (item: AnyTaxonomyItem): item is Audience => {
  return (item as Audience).id !== undefined;
};

export type SelectableTaxonomyItem = Audience | AudienceCategory;

export type AnyTaxonomyItem = SelectableTaxonomyItem | AudienceType;
