import * as React from 'react';
import { ModalBase } from './ModalBase';
import { CircularIndicator } from '../LoadingIndicator';
import { styled } from '@material-ui/core';

export interface LoadingModalProps {
  loading: boolean;
  loadingMessage: string;
}

const LoadingModalContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const LoadingMessageSpan = styled('span')({
  paddingTop: 10
});

export function LoadingModal({ loading, loadingMessage }: LoadingModalProps) {
  return (
    <ModalBase open={loading}>
      <LoadingModalContainer>
        <CircularIndicator />
        <LoadingMessageSpan>{loadingMessage}</LoadingMessageSpan>
      </LoadingModalContainer>
    </ModalBase>
  );
}

export interface UseLoadingModalOptions {
  message?: string;
}

type WaitFunction = <T>(task: Promise<T>) => Promise<T>;

export interface UseLoadingModalResult {
  loading: boolean;
  taskCount: number;
  node: React.ReactNode;
  wait: WaitFunction;
}

export function useLoadingModalEx({ message = 'Please wait...' }: UseLoadingModalOptions = {}): UseLoadingModalResult {
  const [taskCount, setTaskCount] = React.useState(0);

  const loadingNode = React.useMemo(() => <LoadingModal loading={true} loadingMessage={message} />, [message]);
  const node = taskCount > 0 ? loadingNode : null;

  const wait = React.useCallback(async <T extends {}>(task: Promise<T>): Promise<T> => {
    setTaskCount(count => count + 1);
    try {
      return await task;
    } finally {
      setTaskCount(count => count - 1);
    }
  }, []);

  return {
    loading: taskCount > 0,
    taskCount,
    node,
    wait
  };
}

export function useLoadingModal(options?: UseLoadingModalOptions): [React.ReactNode, WaitFunction] {
  const { node, wait } = useLoadingModalEx(options);
  return [node, wait];
}
