import generalAlertTemplate from './general-alert-template.html';

export class alertController {
  alertType!: string;
  hideAlert!: boolean;
  onClose!: () => void;

  alertTypeClass() {
    const alertClassMap = {
      error: 'alert-danger',
      success: 'alert-success',
      info: 'alert-info',
      warning: 'alert-warning',
      transparentError: 'alert-danger-transparent',
      transparentInfo: 'alert-info-transparent'
    };

    const classMap = alertClassMap[this.alertType];
    if (classMap) {
      return classMap;
    } else {
      return 'alert-material-grey';
    }
  }

  closeError() {
    this.hideAlert = true;
    this.onClose();
  }
}

export const alert = {
  template: generalAlertTemplate,
  controller: alertController,
  bindToController: true,
  controllerAs: 'vm',
  bindings: {
    message: '=',
    details: '=',
    alertType: '@',
    onClose: '&',
    containerClass: '@?',
    showIcon: '=?'
  }
};
