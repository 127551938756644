export function gtLoadingBar() {
  var directive = {
    template: '<div class="loading-bar"></div>',
    link: link,
    restrict: 'E',
    scope: {
      color: '@'
    }
  };
  return directive;

  function link(scope, element, attrs) {}
}
