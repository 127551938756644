import { ng1_directive } from '@uirouter/angularjs/lib/directives/stateDirectives';
import template from './weather_operator_value_input.html';

export function weatherOperatorValueInput(): ng.IDirective {
  return {
    template: template,
    bindToController: true,
    controller: WeatherOperatorValueInputController,
    controllerAs: 'vm',
    restrict: 'E',
    link: () => {},
    scope: {
      weatherObject: '=',
      getWeatherObjectMethod: '=',
      rangeAllowed: '=',
      weatherObjectChanged: '=',
      unit: '=',
      validation: '='
    }
  };
}

interface Operator {
  value: string;
}

interface Range {
  val1: number | null;
  val2: number | null;
}

export interface WeatherOperatorValueInputScope extends ng.IScope {
  weatherOperatorForm: any;
}

export class WeatherOperatorValueInputController implements ng.IOnInit {
  scope: WeatherOperatorValueInputScope;
  operator: Operator = { value: 'gte' };
  range: Range = { val1: null, val2: null };
  weatherObject = {} as any;
  rangeAllowed = false;
  //there was a watch on it before and run again init, dont set up now since it works fine
  weatherObjectChanged = false;
  unit = '';
  validation = {};
  getWeatherObjectMethod = {} as any;

  /**@ngInject */
  constructor($scope: WeatherOperatorValueInputScope) {
    this.scope = $scope;
  }

  $onInit(): void {
    /**
     * weatherObject is an object of type selfServeDataFactory.nullWeatherObject like
     * weatherObject = {
        feels_like: false
        operator: "gte"
        time_constraint: "ob"
        value: []
     * }
     * value could be an array, an element or null 
     * Check for if the operator is of type range or not. if the opertor is range then the value will be an array.
     * if the operator is not range type then the value will contain only one element in it.
     */

    if (this.weatherObject) {
      if (
        this.weatherObject.hasOwnProperty('operator') &&
        this.weatherObject.operator !== '' &&
        this.weatherObject.operator !== undefined
      ) {
        this.operator.value = this.weatherObject.operator;
      }

      if (this.weatherObject.hasOwnProperty('value') && this.weatherObject.value !== null) {
        if (this.weatherObject.operator == 'r') {
          if (this.weatherObject.value.length == 2) {
            this.range.val1 = this.weatherObject.value[0];
            this.range.val2 = this.weatherObject.value[1];
          } else if (this.weatherObject.value.length == 1) {
            this.range.val1 = this.weatherObject.value[0];
          }
        } else {
          if (!this.rangeAllowed) {
            this.range.val1 = this.weatherObject.value;
          } else {
            this.range.val1 = this.weatherObject.value[0];
          }
        }
      }
    }

    // this will assign a method to update values for weatherObject
    this.getWeatherObjectMethod.method = this.getWeatherObjectValues;
  }

  operatorChanged = () => {
    if (this.operator.value != 'r') {
      this.range.val2 = null;
    }
    if (this.scope.weatherOperatorForm.$$parentForm) {
      this.scope.weatherOperatorForm.$$parentForm.$setPristine();
      this.scope.weatherOperatorForm.$$parentForm.$setValidity();
    }
    this.scope.weatherOperatorForm.$setPristine();
    this.scope.weatherOperatorForm.$setValidity();
  };

  getWeatherObjectValues = () => {
    let values;
    if (this.rangeAllowed) {
      values = [this.range.val1];
      if (this.operator.value == 'r') {
        values.push(this.range.val2);
      }
    } else {
      values = this.range.val1;
    }
    return {
      operator: this.operator.value,
      values: values
    };
  };
}
