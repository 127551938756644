xadCoreConfig.$inject = ['$provide'];

export function xadCoreConfig($provide) {
  $provide.decorator('limitToFilter', [
    '$delegate',
    '$filter',
    function($delegate, $filter) {
      var srcFilter = $delegate,
        version = angular.version,
        extendsFilter = function() {
          /* istanbul ignore if */
          if (version.major === 1 && version.minor < 4) {
            return $filter('limit').apply(this, arguments);
          }
          return srcFilter.apply(this, arguments);
        };

      return extendsFilter;
    }
  ]);
}

export function $xadUtil() {
  var utils = {};

  utils.getDate = function(date) {
    var year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate();
    return year + '-' + month + '-' + day;
  };

  utils.unpackData = function(data, structure, flat) {
    if (!data || !structure) {
      return data;
    }

    var result, i, j, item, k, key;

    if (angular.isArray(structure) && angular.isArray(data)) {
      result = [];

      for (i = 0; i < data.length; i += 1) {
        item = {};
        k = 0;
        if (!angular.isArray(data[i])) {
          if (flat) {
            k = i % structure.length;
            if (k === 0) {
              item[structure[k]] = data[i];
              result.push(item);
            } else {
              result[result.length - 1][structure[k]] = data[i];
            }
          } else {
            item = data[i];
            result.push(item);
          }
        } else {
          for (j = 0; j < data[i].length; j += 1) {
            if (j < structure.length) {
              item[structure[j]] = data[i][j];
            }
          }
          result.push(item);
        }
      }

      return result;
    }

    result = {};
    for (i in data) {
      /* istanbul ignore else */
      if (data.hasOwnProperty(i)) {
        if (structure.hasOwnProperty(i)) {
          key = i;
        } else {
          key = 'key';
        }
        result[i] = utils.unpackData(data[i], structure[key], flat);
      }
    }
    return result;
  };

  return utils;
}

/*
 * baseExtend, setHashKey, merge
 * port from angular 1.4
 * */

var xad_ported_utils = {
  baseExtend: function(dst, objs, deep) {
    var h = dst.$$hashKey,
      isArray = Array.isArray,
      i,
      ii,
      j,
      jj,
      obj,
      keys,
      key,
      src;

    for (i = 0, ii = objs.length; i < ii; ++i) {
      obj = objs[i];
      if (!angular.isObject(obj) && !angular.isFunction(obj)) {
        continue;
      }
      keys = Object.keys(obj);
      for (j = 0, jj = keys.length; j < jj; j++) {
        key = keys[j];
        src = obj[key];

        if (deep && angular.isObject(src)) {
          if (!angular.isObject(dst[key])) {
            dst[key] = isArray(src) ? [] : {};
          }
          xad_ported_utils.baseExtend(dst[key], [src], true);
        } else {
          dst[key] = src;
        }
      }
    }

    xad_ported_utils.setHashKey(dst, h);
    return dst;
  },

  setHashKey: function(obj, h) {
    if (h) {
      obj.$$hashKey = h;
    } else {
      delete obj.$$hashKey;
    }
  },

  merge: function(dst) {
    var slice = [].slice;
    return xad_ported_utils.baseExtend(dst, slice.call(arguments, 1), true);
  }
};

/* istanbul ignore next */
angular.merge = angular.merge || xad_ported_utils.merge;
