import * as React from 'react';
import PropTypes from 'prop-types';
import { ReportEdit } from './report_form/report_edit';

export interface ReportSchedulerEditProps {
  reportId: string;
}

export function ReportSchedulerEdit(props: ReportSchedulerEditProps) {
  return <ReportEdit reportId={Number(props.reportId)} />;
}

ReportSchedulerEdit.propTypes = {
  reportId: PropTypes.string
};
