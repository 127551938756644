import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import { ReportFormPage } from './report_form_page';
import { useLocalModel } from '../../../utils/react/use_local_model';
import { ReportModel } from '../model/report_model';
import { ReportSchedulerEditFormModel } from '../model/report_scheduler_edit_form_model';

const LoadingIndicatorContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 20
});

export function ReportCreate() {
  const model = useLocalModel(() => new ReportSchedulerEditFormModel(new ReportModel()));

  return <ReportFormPage model={model} />;
}
