import { GeneralUtilsService } from '../../factories/types';
import { creativeAssetTypes, creativeAssetStatus, creativeAssetsAdSizes } from './creative_repository_constants';
import creativeUploadModalTemplate from './creative_asset_upload/creative-asset-upload-modal-template.html';
import { CompanyAndAccountFactoryService, CreativeAsset } from '../../factories/types';
import { creativeRepositoryState } from './creative_repository_state';
import { safeApply } from '../../factories/general_utils';
import addCreativeAdgroupModalTemplate from './add_creative_adgroup_modal/add-creative-adgroup-modal.html';
import { sortBy, difference, includes, flattenDeep, intersection } from 'lodash';

export class creativeRepositoryController {
  private scope: ng.IScope;
  private companyAndAccountFactory: CompanyAndAccountFactoryService;
  private creativeRepositoryState: creativeRepositoryState;
  displayAsTable: boolean;
  searchName: string;
  selectedCreativeAssetsIds: number[];
  loading: boolean;
  private generalUtilsFactory: GeneralUtilsService;
  private itemsPerPage: number;
  private numberOfPages: number;
  private creativeAssetGridItems: CreativeAsset[];
  private selectedTenantName: string;
  private availableLimits: number[];

  // TODO: make interfaces for the following classes
  private modalFactory: any;
  private creativeRepositoryService: any;
  selectedOrganizations: any[];
  selectedAccountIds: any[];
  private selectedAccounts: any[];
  selectedTypes: any[];
  selectedStatus: any[];
  selectedAdSizes: any[];
  private organizationList!: any[];
  private accountList!: any[];
  private creativeAssetTypeList!: any[];
  private statusList!: any[];
  private creativeAssetAdSizeList!: any[];
  private totalPages!: number;
  private universalAlert: any;
  private addToAdgroupButtonDisabled: boolean;
  private currentPage: number;
  sortBy: string;
  sortDesc: boolean;
  totalItems: number;
  tableChanged: boolean;

  constructor(
    $scope,
    generalUtilsFactory: GeneralUtilsService,
    companyAndAccountFactory: CompanyAndAccountFactoryService,
    modalFactory,
    creativeRepositoryService,
    creativeRepositoryState,
    universalAlert
  ) {
    this.scope = $scope;
    this.generalUtilsFactory = generalUtilsFactory;
    this.modalFactory = modalFactory;
    this.creativeRepositoryState = creativeRepositoryState;
    this.creativeRepositoryService = creativeRepositoryService;
    this.displayAsTable = true;
    this.companyAndAccountFactory = companyAndAccountFactory;
    this.universalAlert = universalAlert;

    this.itemsPerPage = 10;
    this.numberOfPages = 0;
    this.availableLimits = [10, 25, 50];
    this.currentPage = 1;
    this.sortBy = '';
    this.sortDesc = false;
    this.totalItems = 0;

    this.selectedOrganizations = [];
    this.selectedAccountIds = [];
    this.selectedAccounts = [];
    this.selectedTypes = [];
    this.selectedStatus = [];
    this.selectedAdSizes = [];
    this.creativeAssetGridItems = [];
    this.searchName = '';
    this.selectedCreativeAssetsIds = [];
    this.addToAdgroupButtonDisabled = true;
    this.loading = false;
    this.selectedTenantName = '';
    this.tableChanged = false;
    this.selectDeselectSearchOptions = this.selectDeselectSearchOptions.bind(this);
    this.updateSelectedOrganization = this.updateSelectedOrganization.bind(this);
    this.updateSelectedAccounts = this.updateSelectedAccounts.bind(this);

    this.openSingleDeleteConfirmation = this.openSingleDeleteConfirmation.bind(this);
    this.deleteCreative = this.deleteCreative.bind(this);
    this.updateCreativeAssetList = this.updateCreativeAssetList.bind(this);

    this.scope.$watch(
      () => {
        return this.selectedCreativeAssetsIds;
      },
      () => {
        this.updateAddToAdgroupState();
      },
      true
    );
  }

  $onInit() {
    this.setDropDownListValues();
    this.updateCreativeAssetList();

    // used for update creative assets after modal actions like add to adgroup, delete, edit etc.
    this.scope.$on('updatedCreativeAssets', () => {
      this.updateCreativeAssetList();
    });

    this.selectedTenantName = this.companyAndAccountFactory.selectedTenant
      ? this.companyAndAccountFactory.selectedTenant.name
      : '';
  }

  updateAddToAdgroupState() {
    if (this.selectedCreativeAssetsIds.length > 10) {
      this.addToAdgroupButtonDisabled = true;
      this.universalAlert.showAlert(
        'You can only add 10 creative assets to ad groups at a time.',
        'transparentError',
        'creative-repo-alert'
      );
    } else if (this.selectedCreativeAssetsIds.length <= 0) {
      this.addToAdgroupButtonDisabled = true;
      this.universalAlert.removeAlert();
    } else {
      this.addToAdgroupButtonDisabled = false;
      this.universalAlert.removeAlert();
    }
  }

  setDropDownListValues() {
    this.setOrganizationAndAccounts();
    this.creativeAssetTypeList = creativeAssetTypes.map(creativeType => ({ title: creativeType }));
    this.statusList = creativeAssetStatus.map(creativeStatus => ({ title: creativeStatus }));
    this.creativeAssetAdSizeList = creativeAssetsAdSizes.map(creativeAssetsAdSize => ({ title: creativeAssetsAdSize }));
  }

  setOrganizationAndAccounts() {
    this.organizationList = sortBy(this.companyAndAccountFactory.companiesAccounts, organization => organization.name);

    /**
     * TODO:
     * NOTE: this is a very bad piece of code. Our current way of getting the list of companies and accounts is through companyAndAccountFactory
     * and companyAndAccountFactory is setting the checked to true. If the checked is true and we don't add to the selectedList the item would be missing from the
     * multi select
     */
    this.organizationList.forEach(organization => {
      organization.checked = false;
      organization.accounts.forEach(account => (account.checked = false));
    });

    this.accountList = [];
  }

  async updateCreativeAssetList(sortBy = this.sortBy, sortDesc = this.sortDesc, clearSelectedAssets = true) {
    if (sortBy || sortDesc) {
      this.sortBy = sortBy;
      this.sortDesc = sortDesc;
    }
    this.loading = true;
    try {
      const creativeAssetData = await this.creativeRepositoryService.getCreativeAssetList({
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        page: this.currentPage,
        limit: this.itemsPerPage,
        organization_ids: this.selectedOrganizations,
        asset_types: this.selectedTypes,
        status: this.selectedStatus,
        size: this.selectedAdSizes,
        account_ids: this.selectedAccountIds,
        search: this.searchName
      });
      this.totalItems = creativeAssetData?.count || 0;
      if (clearSelectedAssets) {
        this.clearAllSelections();
      }
      this.creativeRepositoryState.updateCreativeAssetsList(creativeAssetData.creative_assets);
    } catch (e) {
      this.universalAlert.showAlert('There was an error loading creative repository');
      throw new Error('There was an error loading the creative assets.');
    } finally {
      this.tableChanged = true;
      this.loading = false;
      safeApply(this.scope);
    }
  }

  clearAllSelections() {
    this.selectedCreativeAssetsIds.splice(0, this.selectedCreativeAssetsIds.length);
    this.tableChanged = true;
  }
  openSingleDeleteConfirmation($event, asset_this, creative) {
    this.selectedCreativeAssetsIds = [creative.asset_id];
    this.openDeleteConfirmationModal(true);
  }

  openDeleteConfirmationModal(singleCreative = false) {
    let message;
    if (singleCreative) {
      message = 'Are you sure you want to delete this creative asset?';
    } else {
      message = 'Only creatives not used in campaigns will be deleted.';
    }
    const modalSetting = {
      title: 'Delete Creatives',
      message,
      noText: 'Cancel',
      noClass: 'button-v2 button--tertiary-green',
      yesText: 'Delete',
      yesClass: 'skin-red'
    };

    const handlers = {
      confirm: this.deleteCreative
    };

    this.modalFactory.simpleConfirm(modalSetting, handlers);
  }

  async deleteCreative() {
    try {
      this.loading = true;
      const response = await this.creativeRepositoryService.deleteCreative(this.selectedCreativeAssetsIds);
      this.openStatusModal(response);
      const creativeAssets = this.creativeRepositoryState.listOfCreativeAssets;
      const creativeAssetsIds = this.creativeRepositoryState.listOfCreativeAssets.map(
        creativeAsset => creativeAsset.asset_id
      );

      const newListWithoutDeletedId = difference(creativeAssetsIds, response.success);
      const newCreativeAssets = this.removeDeletedCreativeAssetsFromList(creativeAssets, newListWithoutDeletedId);
      this.creativeRepositoryState.updateCreativeAssetsList(newCreativeAssets);
      this.selectedCreativeAssetsIds = [];
      this.loading = false;
      this.tableChanged = true;

      return null;
    } catch (e) {
      // TODO: Work on the toast mechanism for error handling
      return 'There was an error deleting the selected creative assets.';
    }
  }

  openStatusModal(response) {
    const creativeAssets = this.creativeRepositoryState.listOfCreativeAssets;

    let failedCreatives = response.failure.map(failureItem => {
      let creativeAsset = creativeAssets.find(creativeAsset => creativeAsset.asset_id == failureItem);
      if (creativeAsset) {
        return {
          name: creativeAsset.asset_name,
          success: false,
          message: 'Linked - unable to delete'
        };
      } else {
        throw 'The asset id was not found.';
      }
    });

    let successfulCreatives = response.success.map(successItem => {
      let creativeAsset = creativeAssets.find(creativeAsset => creativeAsset.asset_id == successItem);
      if (creativeAsset) {
        return {
          name: creativeAsset.asset_name,
          success: true,
          message: 'Successfully deleted'
        };
      } else {
        throw 'The asset id was not found.';
      }
    });

    let statuses = [...successfulCreatives, ...failedCreatives];
    const data = {
      title: 'Delete',
      statuses,
      backdrop: 'static'
    };
    this.modalFactory.statusModal(data);
  }

  /**
   * This method only keeps creativeAssets in creativeList if they are in reaminingIds
   */
  removeDeletedCreativeAssetsFromList(creativeList: CreativeAsset[], remainingIds: number[]) {
    return creativeList.filter(creativeAsset => {
      return remainingIds.some(id => {
        return id === creativeAsset.asset_id;
      });
    });
  }

  updateSelectedOrganization(selectedOptions, id) {
    this.selectedOrganizations = selectedOptions.map(selectedOrganization => selectedOrganization.id);

    this.accountList = angular.copy(
      flattenDeep(selectedOptions.map(selectedOrganization => selectedOrganization.accounts))
    );
    this.accountList = sortBy(this.accountList, account => account.name);
    this.selectedAccounts = this.selectedAccounts.filter(selectedAccount => {
      return this.accountList.includes(selectedAccount);
    });
    this.updateCreativeAssetList();
  }

  updateSelectedAccounts(selectedOptions, id) {
    this.selectedAccountIds = selectedOptions.map(selectedAccount => selectedAccount.id);
    this.updateCreativeAssetList();
  }

  clearSearchBar = function() {
    this.searchName = '';
    this.searchListWithName();
  };

  selectDeselectSearchOptions(selectedOptions, id) {
    switch (id) {
      case 'type-multi-select-dropdown':
        this.selectedTypes = selectedOptions.map(selectedType => selectedType.title);
        break;
      case 'status-multi-select-dropdown':
        this.selectedStatus = selectedOptions.map(selectedStatus => selectedStatus.title);
        break;
      case 'ad-size-multi-select-dropdown':
        this.selectedAdSizes = selectedOptions.map(selectedAdsize => selectedAdsize.title);
        break;
    }
    this.updateCreativeAssetList();
  }

  searchListWithName() {
    this.updateCreativeAssetList();
  }

  updateItemsPerPage(page) {
    this.currentPage = 1;
    this.itemsPerPage = page;
    this.updateCreativeAssetList(this.sortBy, this.sortDesc, false);
  }

  updateCurrentPage(currentPage) {
    this.currentPage = currentPage;
    this.updateCreativeAssetList();
  }
  switchToCardView() {
    this.selectedCreativeAssetsIds = [];
    this.displayAsTable = false;
  }

  switchToTableView() {
    this.tableChanged = true;
    this.selectedCreativeAssetsIds = [];
    this.displayAsTable = true;
  }

  openCreativeToAdgroupModal() {
    const modalOptions = {
      template: addCreativeAdgroupModalTemplate,
      controller: 'addCreativeAdgroupModalController',
      keyboard: false,
      backdrop: 'static'
    };

    const data = {
      selectedCreativeAssets: this.selectedCreativeAssetsIds
    };

    this.modalFactory.createModal(modalOptions, data);
  }

  openUploadCreativeModal() {
    const modalOptions = {
      template: creativeUploadModalTemplate,
      controller: 'creativeAssetUploadController',
      size: 'lg',
      keyboard: false,
      backdrop: 'static'
    };

    this.modalFactory.createModal(modalOptions, {});
  }
}

creativeRepositoryController.$inject = [
  '$scope',
  'generalUtilsFactory',
  'companyAndAccountFactory',
  'modalFactory',
  'creativeRepositoryService',
  'creativeRepositoryState',
  'universalAlert'
];
