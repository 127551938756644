import * as React from 'react';
import { useObserver } from 'mobx-react-lite';
import { EditableFormField } from '../index';
import { TextField as OriginalTextField } from '../../reactComponents/Text/TextField';

interface TextFieldProps {
  field: EditableFormField<string>;
  label: string;
  className?: string;
  fullWidth?: boolean;
  isOutlined?: boolean;
}

export const TextField = (props: TextFieldProps) =>
  useObserver(() => (
    <OriginalTextField
      className={props.className}
      label={props.label}
      value={props.field.value}
      onChange={props.field.update}
      errorMessage={props.field.validationMessage}
      fullWidth={props.fullWidth}
      isOutlined={props.isOutlined}
    />
  ));
