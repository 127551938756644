import {
  getKeys,
  isArray,
  nestedUpdateInPlace,
  makeElementSticky,
  daysBetween,
  getCorrectedJSdateFromString,
  getCurrentDate,
  getDateFromToday,
  keepDecimalNoRounding,
  getPercentageTextFromDecimal,
  convertDecimalToPercent,
  stringCssToDictCss,
  dictCssToStringCss,
  round,
  scrollToTopOfPage,
  smoothScrollToElement,
  getFileExtensionFromString,
  downloadPDF,
  DateToString,
  downloadSpreadsheet,
  downloadCSV,
  safeApply,
  selectDeselectOptions,
  searchInOrder,
  shorthandNumber,
  numberToUSString,
  extractErrorsFromResponse,
  getDateWithoutTime
} from './general_utils';

/**
 * @param {ng.ITimeoutService} $timeout
 * @param {ng.IFilterService} $filter
 * @param {ng.FileSaver} FileSaver
 * @ngInject
 */
export function generalUtilsFactory($timeout, $filter, FileSaver) {
  return {
    getKeys,
    isArray,
    nestedUpdateInPlace,
    makeElementSticky,
    daysBetween,
    getCorrectedJSdateFromString,
    getCurrentDate,
    getDateFromToday,
    keepDecimalNoRounding,
    getPercentageTextFromDecimal,
    convertDecimalToPercent,
    stringCssToDictCss,
    dictCssToStringCss,
    round: (num, dec) => round($filter, num, dec),
    shorthandNumber: (num, underThousandDec, dec) => shorthandNumber($filter, num, underThousandDec, dec),
    numberToUSString: (num, dec) => numberToUSString(num, dec),
    scrollToTopOfPage,
    smoothScrollToElement,
    getFileExtensionFromString,
    DateToString,
    downloadPDF: (pdf, filename) => downloadPDF(FileSaver, pdf, filename),
    downloadSpreadsheet: (data, filename) => downloadSpreadsheet(FileSaver, data, filename),
    downloadCSV: (data, filename) => downloadCSV(FileSaver, data, filename),
    safeApply,
    selectDeselectOptions,
    searchInOrder,
    extractErrorsFromResponse,
    getDateWithoutTime: date => getDateWithoutTime(date)
  };
}
