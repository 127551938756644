/**
 * To use this component wrap the item you want to put in grid in gridList component.
 * The attributes that will be passed inside the the component being rendered would have need to access the items using $parent
 * Example:
 * 
 * <grid-list list="vm.creativeAssetListToShow" 
    current-page="vm.currentPage"
    items-per-page="vm.itemsPerPage"
    empty-list-message="There are no creative assets in your repository.">
    <creative-asset-tile class="tile" creative-asset="$parent.item" is-checked="vm.isSelected($parent.item)"
      on-select="vm.onSelect">
    </creative-asset-tile>
  </grid-list>

 */

import template from './grid-list-template.html';

export class GridListController {
  private list!: any[];
  private scope: ng.IRootScopeService;
  private emptyListMessage!: string;
  /** @ngInject */
  constructor($scope) {
    this.scope = $scope;
  }

  $onInit() {
    if (!this.list) {
      this.list = [];
    }

    if (!this.emptyListMessage) {
      this.emptyListMessage = 'No items in the list';
    }
  }
}

export const gridList = {
  transclude: true,
  template: template,
  bindings: {
    emptyListMessage: '@',
    list: '='
  },
  controller: GridListController,
  controllerAs: 'vm'
};
