import * as React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { InfoIcon } from '../../../components/reactComponents/Icons';

import Grid from '@material-ui/core/Grid';

import { RedirectionModal } from '../Modal';
import { styled } from '../../../utils/react/mui_styled';

export interface DeviceTypeProp {
  splashImage?: React.ReactNode;
  name: string;
  label: string;
  icon?: React.ReactNode;
  children?: DeviceTypeProp[];
  parent?: string | null;
  tooltip?: string;
  disabled?: boolean | false;
  selectSibling?: boolean | false;
  selectedDisabled?: boolean | null;
}

export interface DeviceTypesSelectorProps {
  selected: DeviceTypeProp[];
  disabled: DeviceTypeProp[];
  deviceTypes: DeviceTypeProp[];
  subtext: string;
  directMailUrl: string;
  onClick: (value: DeviceTypeProp, selected: boolean) => void;
}

const StyledButton = styled(ButtonBase).extraProps<{ disabled?: boolean; selected?: boolean }>(
  ({ disabled, selected, ...other }) => other
)(({ disabled, selected }) => ({
  '&:hover': {
    cursor: disabled ? 'not-allowed' : 'pointer',
    border: '1px solid var(--color-primary:)',
    backgroundColor: disabled
      ? 'var(--color-background)'
      : selected
      ? 'var(--color-primary)'
      : 'var(--color-primary-container)'
  },
  margin: '1px',
  display: 'inline-block',
  boxSizing: 'border-box',
  color: 'var(--color-on-primary)',
  borderRadius: '10px',
  height: '246px',
  width: '30%',
  maxWidth: '410px',
  fontSize: 'var(--font-size-title-large)',
  fontWeight: 500,
  lineHeight: '130%',
  backgroundColor: disabled ? 'var(--color-background)' : selected ? 'var(--color-primary)' : 'var(--color-background)',
  border: '1px solid var(--color-primary)',
  textAlign: 'center',
  fontFamily: 'var(--secondary-font-family)',
  opacity: disabled ? 0.5 : 1,
  '@media (max-width: 1300px)': {
    width: '292px',
    height: '192px'
  },

  '@media (max-width: 1254px)': {
    width: '45%',
    height: '246px'
  }
}));

const Container = styled('div')({
  padding: '40px',
  width: '100%',
  height: 'auto',
  paddingLeft: '0px',
  backgroundColor: 'var(--color-background)',
  '& .MuiTouchRipple-root': {
    display: 'none'
  }
});

const IconContainer = styled('div').extraProps<{ hasChildren?: boolean }>(({ hasChildren, ...other }) => other)(
  ({ hasChildren }) => ({
    padding: '36px',
    height: hasChildren ? '180px' : '220px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1300px)': {
      height: hasChildren ? '150px' : '180px'
    }
  })
);

const OptionsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: '12px'
});

const InputContainer = styled('div')({
  display: 'inline-block'
});

const StyledInfoIcon = styled(InfoIcon)({
  marginLeft: '10px',
  height: '28px'
});

const StyledDiv = styled('div')({
  gap: '24px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center'
});

const CenteredGridItem = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: 'var(--color-on-primary)',
    borderRadius: '4px',
    color: 'var(--color-background)',
    fontFamily: 'var(--font-family-regular)',
    fontSize: 'var(--font-size-regular)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--line-height)',
    padding: '10px'
  },
  arrow: {
    color: 'var(--color-on-primary)'
  }
})(Tooltip);

const CheckBoxContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '12px',

  '& input[type="checkbox"]': {
    backgroundColor: 'var(--color-background)'
  },

  '& input[type="checkbox"]:checked': {
    border: '2px solid var(--color-outline) !important',
    backgroundColor: 'var(--color-background)'
  },
  '& input[type="checkbox"]:hover': {
    border: '2px solid var(--color-outline) !important'
  },

  '& label': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'var(--color-on-primary)',
    fontFamily: 'var(--font-family-regular)',
    fontSize: 'var(--font-size-regular)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--line-height)'
  },

  '& input[type="checkbox"]:disabled + label': {
    opacity: '0.5'
  }
});

const StyledGridContainer = styled(Grid)({
  rowGap: '20px',
  '@media (max-width: 1300px)': {
    rowGap: '10px'
  },
  '@media (max-width: 1254px)': {
    rowGap: '20px'
  }
});

export function DeviceTypesSelector(props: DeviceTypesSelectorProps) {
  const { deviceTypes, onClick, selected, disabled, subtext, directMailUrl } = props;

  const [showAbdmRedirectModal, setShowAbdmRedirectModal] = React.useState(false);

  const handleChange = (type: DeviceTypeProp, parent?: DeviceTypeProp) => {
    const res = type.children ?? [type];
    const deviceType = type.selectSibling && !selected.includes(type) ? parent! : type!;
    if (!type.disabled || !selected.includes(type)) onClick(deviceType, !selected.some(s => res.includes(s)));
    if (type.name === 'directMail') {
      setShowAbdmRedirectModal(true);
    }
  };

  const handleChangeOnButton = (type: DeviceTypeProp) => {
    if (type!.selectedDisabled) {
      // select only the default selected children when clicking Parent button
      // TODO: Using array.`find` here with assumption that each parent would have
      // only one default item
      const disabledChildren = type!.children!.find(child => child.disabled);
      if (disabledChildren) handleChange(disabledChildren);
    } else {
      handleChange(type);
    }
  };

  const isSelected = (type: DeviceTypeProp) => {
    if (type?.children) return type?.children?.some(child => selected.includes(child));
    return selected.includes(type);
  };

  return (
    <Container>
      <StyledDiv>
        {deviceTypes.map((type, index) => (
          <StyledButton
            key={index}
            disabled={disabled.includes(type)}
            disableRipple={disabled.includes(type)}
            selected={isSelected(type)}
          >
            <IconContainer hasChildren={Boolean(type?.children?.length)} onClick={() => handleChangeOnButton(type)}>
              <StyledGridContainer container>
                <Grid item xs={type.splashImage ? 11 : 12}>
                  {type.icon}
                </Grid>
                {type.splashImage && (
                  <Grid item xs={1}>
                    {type.splashImage}
                  </Grid>
                )}
                <CenteredGridItem item xs={12}>
                  {type.label}
                  {type.tooltip ? (
                    <StyledTooltip title={type.tooltip} placement="right-start">
                      <StyledInfoIcon />
                    </StyledTooltip>
                  ) : null}
                </CenteredGridItem>
              </StyledGridContainer>
            </IconContainer>
            {type.name === 'directMail' && showAbdmRedirectModal ? (
              <RedirectionModal
                url={directMailUrl}
                open={showAbdmRedirectModal}
                onClose={() => setShowAbdmRedirectModal(false)}
                redirectionText={`You will now be redirected to our new Audience Based Direct Mail page.`}
              />
            ) : null}
            <OptionsContainer>
              {type?.children?.map(child => (
                <InputContainer
                  key={child.name}
                  onClick={() => {
                    handleChange(child, type);
                  }}
                >
                  <CheckBoxContainer className="field multiselect-checkbox">
                    <input
                      type="checkbox"
                      id={child.name}
                      value={child.name}
                      onChange={() => null}
                      checked={selected.includes(child)}
                      disabled={selected.includes(child) && child.disabled}
                    />
                    <label
                      onClick={() => {
                        handleChange(child, type);
                      }}
                    >
                      {child.label}
                    </label>
                  </CheckBoxContainer>
                </InputContainer>
              ))}
            </OptionsContainer>
          </StyledButton>
        ))}
      </StyledDiv>
    </Container>
  );
}

DeviceTypesSelector.propTypes = {
  deviceTypes: PropTypes.array,
  onClick: PropTypes.func,
  selected: PropTypes.array,
  disabled: PropTypes.array,
  subtext: PropTypes.string
};
