import { IAngularEvent } from 'angular';

import template from './adgroup_goals.html';
import { FeatureFlagService } from '../../../factories/types';

type IValue = { value?: string };
type Goal = {
  name: string;
  label: string;
  description: string;
  imageSrc: string;
  isEnabled: () => boolean;
  subGoals?: Goal[];
};

export function adgroupGoals(): ng.IDirective {
  return {
    template: template,
    bindToController: true,
    controller: AdgroupGoalsController,
    controllerAs: 'vm',
    restrict: 'E',
    link: () => {},
    scope: {
      selectedGoalCard: '=',
      showError: '=',
      saveInProgress: '=',
      errors: '=',
      goToPreviousStep: '=',
      saveTargetingCardSelection: '=',
      setNavHeader: '='
    }
  };
}

export class AdgroupGoalsController implements ng.IOnInit {
  // Local
  selectedGoal?: string;
  selectedSubGoal?: string;
  readonly goals: Goal[];

  // From directive's parent scope
  readonly showError: boolean = false;
  readonly saveInProgress: boolean = false;
  readonly errors: Record<string, string> = {};
  readonly goToPreviousStep: () => void = () => {};
  readonly saveTargetingCardSelection: () => void = () => {};
  readonly setNavHeader: (selectedGoal: string) => void = () => {};
  // When selections are made on this page, this value is updated directly.
  readonly selectedGoalCard: IValue = {};

  // Factories
  readonly featureFlagFactory: FeatureFlagService;

  /* @ngInject */
  constructor(featureFlagFactory: FeatureFlagService) {
    this.featureFlagFactory = featureFlagFactory;

    this.goals = [
      {
        name: 'audience',
        label: 'Target by Audience',
        description:
          'Retarget people with ads based on the locations they’ve visited.\n' +
          'e.g. People who have been to fast food restaurants.',
        imageSrc: 'maps-icon.svg',
        isEnabled: () => true
      },
      {
        name: 'location',
        label: 'Target by Location',
        description:
          'Send ads to people in real-time based on where they are.\n' +
          'e.g. People who are inside a fast food restaurant.',
        imageSrc: 'location-icon.svg',
        isEnabled: () => true,
        subGoals: [
          {
            name: 'geotargeting',
            label: 'Geotargets',
            description:
              "Send ads to consumers in selected States, DMA's, Counties, Cities, Zip Codes, or in radial " +
              'fences around specific addresses or coordinates.\ne.g. Serve ads to people at an Event, ' +
              'Festival or voting districts.',
            imageSrc: '/ui/images/icon-geotarget.png',
            isEnabled: () => true
          },
          {
            name: 'neighborhood',
            label: 'Neighborhoods',
            description:
              'Send ads to people in nearby areas that show high visitation frequency to a ' +
              'particular store or location. This proprietary technology can be thought of as a ' +
              'store\'s "trade area" or as "look alike audiences".\n' +
              'e.g. Send ads to people in areas that index high for visiting a particular store.',
            imageSrc: '/ui/images/iconneighborhood.png',
            isEnabled: () => true
          },
          {
            name: 'onpremise',
            label: 'On Premise Targeting',
            description:
              'Send ads to people currently in a store, the parking lot, the retail block or ' +
              'within a radial proximity around a location or store.\ne.g. Entice shoppers in a retail ' +
              'block to visit a particular store.',
            imageSrc: '/ui/images/icon-OnPremise.png',
            isEnabled: () => true
          }
        ]
      },
      {
        name: 'weather',
        label: 'Target by Weather',
        description:
          'Send ads to people where they are based on precise weather conditions.\n' +
          'e.g. People who are located in the state of California, when it’s raining.',
        imageSrc: 'light-icon.svg',
        isEnabled: () => featureFlagFactory.isFeatureEnabled('WEATHER_TARGETING_PRODUCT')
      }
    ];
  }

  $onInit() {
    const goal = this.selectedGoalCard.value;
    if (goal) {
      if (['weather', 'audience'].includes(goal)) {
        // Weather and audience don't have subgoals
        this.selectGoal(goal);
      } else {
        this.selectGoal('location', goal);
      }
    } else {
      this.selectGoal('audience');
    }
  }

  selectGoal(goal: string, subGoal?: string, $event?: IAngularEvent): void {
    if (this.selectedGoal === 'location' && goal === 'location' && !subGoal) {
      // Handles the following case:
      // 1. Select Location + On-Premise checkboxes
      // 2. Click the Location checkbox again
      //
      // We don't want it to un-check On-Premise so we'll treat this as a no-op.
      return;
    }

    this.selectedGoalCard.value = subGoal || goal;
    this.selectedGoal = goal;
    this.selectedSubGoal = subGoal;

    if (goal === 'location' && !subGoal) {
      this.selectedSubGoal = 'geotargeting';
      this.selectedGoalCard.value = 'geotargeting';
    }

    if ($event) {
      $event.stopPropagation?.();
    }
    this.setNavHeader(this.selectedGoalCard.value);
  }

  isNextButtonEnabled(): boolean {
    const goal = this.selectedGoal;
    if (goal) {
      // Make sure a goal (and if required, a subgoal) are selected
      const areRequiredSelectionsMade =
        ['weather', 'audience'].includes(goal) || (goal === 'location' && !!this.selectedSubGoal);
      return !this.saveInProgress && areRequiredSelectionsMade;
    }

    // No goal is selected
    return false;
  }
}
