/**
 * @ngInject
 */
export function daypartingSelector($interval) {
  return {
    restrict: 'E',
    scope: {
      numHours: '=',
      days: '=',
      callHours: '&',
      makeDaypartCustom: '&'
    },
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.dayparting_selector.html',
    link: function(scope, el, attr) {
      scope.firstHourClicked;
      scope.firstDayClicked;
      scope.firstHourClickedStatus;
      scope.currentHourHovered;
      scope.currentDayHovered;
      scope.lastHourHovered;
      scope.lastDayHovered;
      scope.isHorizontalDragging = false;
      scope.isVerticalDragging = false;
      scope.isDraggingRectangle = false;
      var mousedownID = -1;

      //mousedown
      scope.draggingHour = function(e) {
        scope.makeDaypartCustom();
        scope.firstDayClicked = scope.days.indexOf(this.$parent.day);
        scope.firstHourClicked = this.hour.index;
        scope.firstHourClickedStatus = this.hour.status;
        if (mousedownID == -1) {
          mousedownID = $interval(1);
          scope.firstHourClickedStatus = !this.hour.status;
          this.hour.status = scope.firstHourClickedStatus;
        }
      };

      //mouseover
      scope.selectingHour = function(e) {
        if (mousedownID != -1) {
          scope.lastHourHovered = scope.currentHourHovered;
          scope.lastDayHovered = scope.currentDayHovered;

          scope.currentDayHovered = scope.days.indexOf(this.$parent.day);
          scope.currentHourHovered = this.hour.index;

          //if dragging horizontal
          if (scope.currentDayHovered == scope.lastDayHovered || scope.currentDayHovered == scope.firstDayClicked) {
            //console.log('horizontal');
            this.hour.status = scope.firstHourClickedStatus;
            scope.fixHorizontalLag(this);
          }
          //if dragging vertical
          if (scope.currentHourHovered == scope.lastHourHovered || scope.currentHourHovered == scope.firstHourClicked) {
            this.hour.status = scope.firstHourClickedStatus;
            scope.fixVerticalLag(this);
          }
          //if dragging diagonally
          if (scope.firstDayClicked < scope.currentDayHovered && scope.firstHourClicked != scope.currentHourHovered) {
            if (scope.firstHourClicked < scope.currentHourHovered) {
              for (let i = scope.firstDayClicked; i < scope.currentDayHovered + 1; i++) {
                for (let j = scope.firstHourClicked; j < scope.currentHourHovered + 1; j++) {
                  scope.days[i].hours[j].status = scope.firstHourClickedStatus;
                }
              }
            } else {
              for (let i = scope.firstDayClicked; i < scope.currentDayHovered + 1; i++) {
                for (let j = scope.firstHourClicked; j > scope.currentHourHovered - 1; j--) {
                  scope.days[i].hours[j].status = scope.firstHourClickedStatus;
                }
              }
            }
          }
          if (scope.firstDayClicked > scope.currentDayHovered && scope.firstHourClicked != scope.currentHourHovered) {
            if (scope.firstHourClicked > scope.currentHourHovered) {
              for (let i = scope.firstDayClicked; i > scope.currentDayHovered - 1; i--) {
                for (let j = scope.firstHourClicked; j > scope.currentHourHovered - 1; j--) {
                  scope.days[i].hours[j].status = scope.firstHourClickedStatus;
                }
              }
            } else {
              for (let i = scope.firstDayClicked; i > scope.currentDayHovered - 1; i--) {
                for (let j = scope.firstHourClicked; j < scope.currentHourHovered + 1; j++) {
                  scope.days[i].hours[j].status = scope.firstHourClickedStatus;
                }
              }
            }
          }
        }
      };

      scope.fixHorizontalLag = function(hourNode) {
        var positionInt = parseInt(hourNode.hour.index);
        var start = scope.firstHourClicked;
        var end = positionInt;
        if (scope.firstHourClicked > positionInt) {
          end = scope.firstHourClicked;
          start = positionInt;
        }
        for (var i = start + 1; i < end; i++) {
          var hour = hourNode.$parent.day.hours[i];
          hour.status = scope.firstHourClickedStatus;
        }
      };

      scope.fixVerticalLag = function(hourNode) {
        var startHour = scope.firstHourClicked;
        var startDay = scope.firstDayClicked;
        var endDay = scope.days.indexOf(hourNode.$parent.day);

        if (startDay > endDay) {
          startDay = endDay;
          endDay = scope.firstDayClicked;
        }
        for (var i = startDay + 1; i < endDay; i++) {
          scope.days[i].hours[startHour].status = scope.firstHourClickedStatus;
        }
      };

      scope.endHourDrag = function(event) {
        if (mousedownID != 1) {
          $interval.cancel(mousedownID);
          mousedownID = -1;
          scope.isDragging = false;
          scope.isDraggingRectangle = false;
          scope.firstHourClicked = null;
          scope.firstDayClicked = null;
          scope.firstHourClickedStatus = null;
          scope.currentHourHovered = null;
          scope.currentDayHovered = null;
          scope.lastHourHovered = null;
          scope.lastDayHovered = null;
        }
      };

      scope.clearDay = function(day) {
        for (let i = 0; i < 24; i++) {
          day.hours[i].status = false;
          scope.makeDaypartCustom();
        }
      };
    } //end link
  };
}
