xadDaterangeSelector.$inject = ['$timeout', '$filter'];

export function xadDaterangeSelector($timeout, $filter) {
  return {
    scope: {
      startDateModel: '=',
      endDateModel: '=',
      minDate: '=?',
      maxDate: '=?',
      dateFormat: '@',
      label: '@',
      parentEl: '@', // If unspecified, daterange picker will be child of <body>
      isDisabled: '=?',
      callback: '&',
      showIcon: '=',
      autoUpdateInput: '=?',
      inputPlaceholder: '@?'
    },
    restrict: 'E',
    replace: false,
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.xad_daterange_selector.html',
    controller: /** @ngInject */ function($scope) {
      this.$onInit = () => {
        if (angular.isUndefined($scope.dateFormat)) {
          $scope.dateFormat = 'YYYY-MM-DD';
        }
        if (angular.isUndefined($scope.isDisabled)) {
          $scope.isDisabled = false;
        }
        if (angular.isUndefined($scope.autoUpdateInput)) {
          $scope.autoUpdateInput = true;
        }
      };
    },
    link: function(scope, element, attrs) {
      $timeout(function() {
        var options = {
          buttonClasses: 'btn-sm',
          locale: {
            format: scope.dateFormat
          },
          startDate: scope.startDateModel,
          endDate: scope.endDateModel,
          autoUpdateInput: scope.autoUpdateInput
        };

        if (angular.isDefined(scope.minDate)) {
          options.minDate = scope.minDate;
        }
        if (angular.isDefined(scope.maxDate)) {
          options.maxDate = scope.maxDate;
        }
        if (angular.isDefined(scope.parentEl)) {
          options.parentEl = scope.parentEl;
        }

        var daterangeElement = element.find('input[name="daterange"]');
        scope.openDatePicker = function() {
          daterangeElement.trigger('click');
        };
        if (daterangeElement) {
          daterangeElement.daterangepicker(options, function(start, end, label) {
            // This function is called when "Apply" is clicked
            scope.$apply(function() {
              scope.startDateModel = $filter('date')(start._d, 'yyyy-MM-dd');
              scope.endDateModel = $filter('date')(end._d, 'yyyy-MM-dd');

              /**
               * https://www.daterangepicker.com/#options is the 3rd party library used here.
               * `autoUpdateInput` when set to `true` the *library* will update the input field
               * automatically. But wehn set to `false` the *directive* will be doing that work.
               * This, way we get to have a custom placeholder in case where the `startDate` and
               * `endDate` are null at first.
               */
              if (!scope.autoUpdateInput) {
                const dateRange = `${scope.startDateModel} - ${scope.endDateModel}`;
                daterangeElement.val(dateRange);
              }

              $timeout(function() {
                if (angular.isDefined(scope.callback)) {
                  scope.callback();
                }
              });
            });
          });
        }
      });
    }
  };
}
