import { camelToHuman } from './camel_to_human.fil';
import { commonFilter } from './common.fil';
import { macroToString } from './macro_to_string.fil';
import { parseHour } from './parse_hour.fil';
import { deepsearch } from './search';
import { cut } from './string_cut.fil';

export const filters = {
  camelToHuman,
  commonFilter,
  macroToString,
  parseHour,
  deepsearch,
  cut
};
