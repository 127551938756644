import { BaseController } from './base.ctrl';
import { CampaignOrAdGroupViewCtrl } from './campaign_router/campaign_or_adgroup_view.ctrl';
import { companyAccountSelect } from './company_account_select/company_account_select.dir';
import { userOptions } from './user_options/user_options.dir';

export const controllers = {
  BaseController,
  CampaignOrAdGroupViewCtrl
};

export const directives = {
  companyAccountSelect,
  userOptions
};
