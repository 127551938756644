selectionListOnpremise.$inject = ['adgroupTargetingFactory', 'searchFactory', 'featureFlagFactory', 'modalFactory'];

export function selectionListOnpremise(adgroupTargetingFactory, searchFactory, featureFlagFactory, modalFactory) {
  return {
    restrict: 'E',
    replace: false,
    scope: {
      items: '=',
      locationManagerUrl: '=',
      radiusUnit: '=',
      sensitiveCheckCallback: '&'
    },
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.selection_list_onpremise.html',
    controller: /** @ngInject */ function($scope) {
      $scope.featureFlagFactory = featureFlagFactory;
      $scope.semiSensitiveWarningMessage = adgroupTargetingFactory.semiSensitiveWarningMessage;
      $scope.pageSize = 10;
      $scope.currentPage = 0;
      $scope.itemsInCurrentPage = [];
      if ($scope.radiusUnit == null || $scope.radiusUnit == undefined) {
        $scope.radiusUnit = 'miles';
      }

      // this gets passed to autocomplete so we know when to re-render table
      $scope.tableUpdated = false;

      $scope.entityDisplayTypes = adgroupTargetingFactory.entityDisplayTypes;

      $scope.entityQuery = '';

      $scope.proximityTypes = {
        inStore: {
          name: 'In Store',
          value: 1
        },
        onLot: {
          name: 'On Lot',
          value: 2
        },
        retailBlock: {
          name: 'Retail Block',
          value: 3
        },
        radial: {
          name: 'Radial',
          value: 'radial'
        }
      };
      $scope.proximityTypesOrder = [
        $scope.proximityTypes.inStore,
        $scope.proximityTypes.onLot,
        $scope.proximityTypes.retailBlock,
        $scope.proximityTypes.radial
      ];

      $scope.itemToAdd = {
        proximity: featureFlagFactory.isFeatureEnabled('BLUEPRINTS_TARGETING')
          ? $scope.proximityTypes.onLot
          : $scope.proximityTypes.radial,
        radius: 1,
        name: null,
        type: null
      };

      $scope.searchBrandsAndCategories = function(query) {
        var filter = {
          targeting_search: 1,
          entity_types: 'brand,category,location_group',
          search_category: 'onpremise'
        };
        return searchFactory.getSearchResults(query, filter);
      };

      $scope.addItem = function(item) {
        $scope.items.push(item);
      };

      $scope.removeItemByIndex = function(indexOnPage) {
        var index = indexOnPage + $scope.currentPage * $scope.pageSize;
        $scope.items.splice(index, 1);
        $scope.updateData();
      };

      $scope.showSemiSensitiveWarningMessage = function(item) {
        let isSemiSensitiveLocation =
          (item.type === 'location_group' && item.is_sensitive) ||
          (['brand', 'category'].includes(item.type) && item.sensitivity === 2);
        if (item.proximity === $scope.proximityTypes.radial.value && item.radius >= 1) {
          return false;
        } else {
          return isSemiSensitiveLocation;
        }
      };

      $scope.checkSensitivity = function() {
        if ($scope.sensitiveCheckCallback && angular.isFunction($scope.sensitiveCheckCallback)) {
          $scope.sensitiveCheckCallback({ selected: $scope.items }).then(function(data) {
            if (data) {
              $scope.addProximityToSelectedItem();
            }
          });
        } else {
          $scope.addProximityToSelectedItem();
        }
      };

      $scope.addProximityToSelectedItem = function() {
        if ($scope.itemToAdd.proximity == $scope.proximityTypes.radial) {
          $scope.items[$scope.items.length - 1].radius = $scope.itemToAdd.radius;
        }
        $scope.items[$scope.items.length - 1].proximity = $scope.itemToAdd.proximity.value;
        $scope.tableUpdated = true;
      };

      $scope.updateData = function() {
        $scope.loadPage($scope.currentPage);
      };

      // Updates $scope.currentPage & $scope.itemsInCurrentPage.
      $scope.loadPage = function(page) {
        if (!$scope.items || !$scope.items.length) {
          $scope.itemsInCurrentPage = [];
          $scope.currentPage = 0;
          return;
        }

        var begin, end;
        $scope.currentPage = page;
        begin = $scope.pageSize * $scope.currentPage;
        end = begin + $scope.pageSize;
        $scope.itemsInCurrentPage = $scope.items.slice(begin, end);

        // If page from argument is empty, recursively finds first populated page and loads.
        if ($scope.itemsInCurrentPage.length <= 0) {
          $scope.loadPage(page - 1);
        }
      };

      $scope.deleteAllSelections = function() {
        const modalSetting = {
          title: 'Are you sure you want to delete all selections?',
          message: 'By confirming this action, the table will be cleared.',
          noText: 'Cancel',
          yesText: 'Yes, Delete',
          yesClass: 'button--green'
        };
        const handlers = {
          confirm: function() {
            return Promise.resolve($scope.clearAllSelections());
          }
        };

        modalFactory.simpleConfirm(modalSetting, handlers);
      };

      $scope.clearAllSelections = function() {
        $scope.items = [];
      };

      $scope.defaultRadial = !featureFlagFactory.isFeatureEnabled('BLUEPRINTS_TARGETING');

      $scope.updateData();
    },
    link: function(scope, element, attrs) {
      scope.$watch(
        function() {
          return scope.tableUpdated;
        },
        function(newValue, oldValue) {
          if (!newValue) {
            return;
          }
          scope.updateData();
          scope.tableUpdated = false;
        }
      );
    }
  };
}
