import template from './nav_element.html';

export function navElement(): ng.IDirective {
  const link = () => {};

  return {
    restrict: 'E',
    scope: {
      goToSection: '=',
      desktopOrStreamingServicesSelected: '=',
      showDeviceTargetingNavElement: '=',
      showDemographicsNavElement: '=',
      showOptimizationStrategyNavElement: '=',
      showAdvancedPublisherSettingsNavElement: '=',
      showViewabilityNavElement: '=',
      showDeliveryChannelNavElement: '=',
      selectedGoal: '=',
      completedSteps: '=',
      getCurrentPage: '=',
      goToStep: '=',
      pageToStep: '=',
      navHeader: '=',
      setNavHeader: '='
    },
    link: link,
    template: template,
    controller: NavElementController,
    controllerAs: 'vm',
    bindToController: true
  };
}

class NavElementController implements ng.IOnInit {
  setNavHeader: any;
  selectedGoal!: string;

  $onInit() {
    this.setNavHeader(this.selectedGoal);
  }
}
