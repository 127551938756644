import React from 'react';
import { Statement } from '../../../api/types/Statement';

interface TransactionStatementSectionProps {
  statements?: Statement[];
}

export function TransactionStatementSection({ statements = [] }: TransactionStatementSectionProps) {
  return (
    <>
      {statements?.length > 0 && (
        <div className="payment-page__section">
          <div className="payment-page__section-title statement-section-title">Transaction Statements</div>
          {statements.map((statement, index) => (
            <div className="statement" key={index}>
              <a href={statement.download_link} target="_blank">
                {statement.name}
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
