import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const TVIcon = createSvgIcon(
  <path
    id="Vector"
    d="M42 6H6C3.8 6 2 7.8 2 10V34C2 36.2 3.8 38 6 38H16V42H32V38H42C44.2 38 45.98 36.2 45.98 34L46 10C46 7.8 44.2 6 42 6ZM42 34H6V10H42V34Z"
    fill="#0A3F36"
  />,
  'GTTV'
);

export default TVIcon;
