import { AccountSelectedTabsCtrl } from './account_selected_tabs.ctrl';
import { NewAccountController } from './account_new/new_account.ctrl';
import { SelectedAccountViewCtrl } from './account_edit/selected_account_view.ctrl';
import { trackingPixelAccountModalController } from './account_tracking_pixels/tracking_pixel_account_modal.ctrl';
import { TrackingPixelController } from './account_tracking_pixels/tracking_pixel.ctrl';
import { AccountPosController } from './account_pos/account_pos.ctrl';

export const controllers = {
  AccountSelectedTabsCtrl,
  NewAccountController,
  SelectedAccountViewCtrl,
  trackingPixelAccountModalController,
  TrackingPixelController,
  AccountPosController
};
