import { safeApply, searchInOrder, selectDeselectOptions } from '../../factories/general_utils';
import template from './multi-select-search-dropdown.html';

export const multiSelectSearchDropdown = () => {
  const link = () => {};

  return {
    restrict: 'E',
    scope: {
      title: '@',
      selectedTitle: '=?',
      getSelectedTitle: '=?',
      displayProp: '@',
      uniqueId: '@', // its a key in api data used for showing ID on ui like tenant id, organisation id etc.
      placeholderTitle: '@',
      selectOne: '=',
      hideId: '@', // its a boolean if true then we will show ID on ui
      list: '=',
      searchCallback: '=',
      height: '@',
      width: '@',
      limitSelection: '@',
      selectedList: '=?',
      selectCallback: '=',
      searchEnabled: '=',
      hideLabelAndShowBorder: '@',
      updateCallback: '=',
      bordered: '@',
      textBoxIcon: '@',
      elementId: '@' // used for creating html element Id
    },
    link: link,
    template: template,
    controller: MultiSelectSearchDropdownController,
    controllerAs: 'vm',
    bindToController: true
  };
};

type ListItem = { checked?: boolean };

class MultiSelectSearchDropdownController<T extends ListItem> implements ng.IController {
  limits!: {
    itemsToShow: number;
    scrollIncrement: number;
  };
  // Scope bound data
  title!: string;
  selectedTitle!: string;
  getSelectedTitle?: (title: string, displayProp: string | undefined, selectedList: readonly T[]) => string;
  displayProp!: string & keyof T;
  uniqueId!: string & keyof T;
  hideId!: boolean;
  selectOne?: boolean;
  list!: readonly T[];
  searchCallback!: (
    searchTerm: string | number,
    list: readonly T[],
    uniqueId: string & keyof T
  ) => Promise<readonly T[]>;
  height!: number;
  bordered!: boolean;
  width!: number;
  textBoxIcon!: string;
  selectedList!: readonly T[];
  selectCallback!: (item: T, id: string, selectedList: readonly T[]) => readonly T[];
  hideLabelAndShowBorder?: boolean;
  updateCallback?: (selectedList: readonly T[], id: string) => void;
  elementId!: string;

  // Private data
  private $scope: ng.IScope;
  private searchTerm: string;
  private progress: boolean;
  private id!: string;
  private placeholderTitle!: string;
  private showList!: ReadonlyArray<T>;
  private selectedListLength!: number;
  private ogSelectedList!: readonly T[];
  private selectAll!: boolean;
  private isOpen: boolean;

  /** @ngInject */
  constructor($scope: ng.IScope) {
    this.$scope = $scope;
    this.searchTerm = '';
    this.progress = false;
    this.selectAll = false;
    this.isOpen = false;
  }

  $onInit() {
    if (!this.selectedList) {
      this.selectedList = [];
    }

    if (!this.displayProp) {
      throw 'Display prop is required';
    }

    if (!this.width) {
      this.width = 250;
    }

    this.id = this.uniqueId || 'id';
    this.selectedTitle = this.selectedTitle || this.placeholderTitle;
    this.showList = this.list;
    this.selectedListLength = this.selectedList ? this.selectedList.length : 0;
    this.ogSelectedList = this.selectedList;
    this.limits = {
      itemsToShow: 100,
      scrollIncrement: 100
    };

    this.updateSelectAllToggle();

    this.$scope.$watch(
      () => {
        return this.selectedList;
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.onSelectionDeselection();
        }
      },
      true
    );
  }

  updateSelectAllToggle() {
    this.selectAll = this.showList.length !== 0 && this.showList.length === this.selectedList.length;
  }

  loadMore() {
    this.limits.itemsToShow = this.limits.itemsToShow + this.limits.scrollIncrement;
  }

  onToggle(open: boolean) {
    this.isOpen = open;
    this.updateSelectAllToggle();
    open ? this.refreshList() : this.checkSelection();
  }

  checkSelection() {
    if (this.selectOne && !this.selectedList.length) {
      this.ogSelectedList[0].checked = true;
      this.selectedList = this.ogSelectedList;
      this.selectedListLength = this.selectedList.length;
    }
  }

  setSelectedTitle() {
    if (this.getSelectedTitle && typeof this.getSelectedTitle === 'function') {
      this.selectedTitle = this.getSelectedTitle(this.title, this.displayProp, this.selectedList);
    } else if (this.selectedList && this.selectedList.length) {
      this.selectedTitle = this.selectedList.map(selectedItem => selectedItem[this.displayProp]).join(', ');
    } else {
      this.selectedTitle = this.placeholderTitle || this.title;
    }
  }

  refreshList() {
    this.searchTerm = '';
    this.showList = this.list;
    this.selectedTitle = this.selectedTitle || this.title;
  }

  clearSearchTerm() {
    this.searchTerm = '';
    this.showList = this.list;
  }

  /**
   * Copies over `checked` items to newly fetched items
   * from an selected list
   * @param selectedItems - List of previously checked items
   * @param newItems - List of newly fetched items
   * @param key - key to look in these list
   */
  preserveChecks<T>(selectedItems: readonly T[], newItems: readonly T[], key: keyof T) {
    const selectedItemsMap = new Map(selectedItems.map(item => [item[key], item]));
    return newItems.map(item => selectedItemsMap.get(item[key]) ?? item);
  }

  async search() {
    this.limits.itemsToShow = this.limits.scrollIncrement;

    if (this.searchCallback) {
      const results = await this.searchCallback(this.searchTerm, this.list, this.uniqueId);
      this.showList = this.preserveChecks(this.selectedList, results, this.uniqueId);
      safeApply(this.$scope);
    } else {
      this.showList = searchInOrder(this.searchTerm, this.list, [this.displayProp, this.id]);
    }
  }

  onCheck(item) {
    if (this.selectOne) {
      // In case of selecting one only item, de-select the current one if any
      if (this.selectedList && this.selectedListLength) {
        this.selectedList[0].checked = false;
        this.selectedList = [];
      }
    }

    if (this.selectCallback) {
      this.selectedList = this.selectCallback(item, this.id, this.selectedList);
    } else {
      this.selectedList = selectDeselectOptions(item, this.selectedList);
    }
    this.selectedListLength = this.selectedList.length;
    this.updateSelectAllToggle();
  }

  onSelectionDeselection() {
    this.setSelectedTitle();
    this.selectedListLength = this.selectedList.length;
    if (this.updateCallback) {
      this.progress = true;
      this.updateCallback(this.selectedList, this.id);
      this.progress = false;
    }
  }

  toggleSelectAll() {
    this.showList.forEach(item => (item.checked = !!this.selectAll));
    if (this.selectAll) {
      this.selectedList = this.showList;
    } else {
      this.selectedList = [];
    }
  }

  getUncheckedLength() {
    return this.showList.filter(item => !item.checked).length;
  }
}
