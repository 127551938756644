import { IOnInit } from 'angular';
import { DeliveryChannel } from '../../../../api/http/data.generated';
import { StaticDataService } from '../../../../factories/types';
import { CampaignManagerService } from '../../../../factories/types';
import template from './adgroup_delivery_channels.html';
import { safeApply } from '../../../../factories/general_utils';

export function adgroupDeliveryChannels(): ng.IDirective {
  return {
    restrict: 'E',
    template: template,
    scope: {
      selectedDeliveryChannel: '=',
      adgroupDeliveryChannelId: '<'
    },
    controller: AdgroupDeliveryChannelController,
    controllerAs: 'vm',
    bindToController: true
  };
}

interface SelectedDeliveryChannelType {
  value: DeliveryChannel | null;
}

export class AdgroupDeliveryChannelController implements IOnInit {
  //Local variables
  $scope!: ng.IScope;
  deliveryChannels: DeliveryChannel[] = [];
  staticDataFactory: StaticDataService;
  campaignManagerFactory: CampaignManagerService;
  //Parent's scope variable
  adgroupDeliveryChannelId?: number = undefined;
  selectedDeliveryChannel: SelectedDeliveryChannelType = { value: null };

  /**@ngInject */
  public constructor(
    $scope: ng.IScope,
    staticDataFactory: StaticDataService,
    campaignManagerFactory: CampaignManagerService
  ) {
    this.$scope = $scope;
    this.staticDataFactory = staticDataFactory;
    this.campaignManagerFactory = campaignManagerFactory;
  }

  async setDeliveryChannel() {
    try {
      const data = await this.staticDataFactory.getDeliveryChannels(
        this.campaignManagerFactory.selectedAdGroup.targeting.deviceTypes[0]
      );
      this.deliveryChannels = angular.copy(data);
      this.deliveryChannels.forEach(deliveryChannel => {
        if (
          deliveryChannel.deliveryChannelId == this.adgroupDeliveryChannelId ||
          this.selectedDeliveryChannel.value == null
        ) {
          this.selectedDeliveryChannel.value = deliveryChannel;
        }
      });
    } catch (e) {
      console.error('Failed to fetch delivery channel', e);
    }
  }

  async $onInit() {
    await this.setDeliveryChannel();
    safeApply(this.$scope);
  }
}
