/**
 * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @ngInject
 */
export function imageLandingPageController(
  $scope,
  staticDataFactory,
  $sce,
  $q,
  $timeout,
  creativeTemplatesFactory,
  generalUtilsFactory
) {
  $scope.adGroupCopy = angular.copy($scope.adGroup);

  $scope.backgroundImageUploader = {};
  $scope.progressBackgroundImageUpload = false;

  $scope.headerImageExpectedSizes = [
    {
      width: 640,
      height: 960
    }
  ];

  $scope.dataChanged = {
    changed: false
  };

  $scope.buttonActions = [
    {
      displayValue: 'Website',
      actionCode: 'website'
    },
    {
      displayValue: 'More Info',
      actionCode: 'moreinfo'
    },
    {
      displayValue: 'App store',
      actionCode: 'appstore'
    },
    {
      displayValue: 'Passbook',
      actionCode: 'passbook'
    },
    {
      displayValue: 'Direction',
      actionCode: 'direction'
    },
    {
      displayValue: 'Coupon',
      actionCode: 'coupon'
    },
    {
      displayValue: 'Media',
      actionCode: 'media'
    }
  ];

  $scope.boolean = {
    backgroundImageFlowDropEnabled: false,
    dataLoaded: false
  };

  $scope.booleanCheckbox = {
    overlayButtonText1Checkbox: true,
    overlayButtonText2Checkbox: true,
    overlayButtonNoText1Checkbox: true,
    overlayButtonNoText2Checkbox: true,
    overlayButtonNoText3Checkbox: true,
    overlayButtonNoText4Checkbox: true,
    overlayVideoCheckbox: true
  };

  $scope.booleanEditMode = {
    enableBackgroundImageEdit: false,
    enableOverlayButtonText1Edit: false,
    enableOverlayButtonText2Edit: false,
    enableCallButtonEdit: false,
    enableDirectionButtonEdit: false,
    enableOverlayButtonNoText1Edit: false,
    enableOverlayButtonNoText2Edit: false,
    enableOverlayButtonNoText3Edit: false,
    enableOverlayButtonNoText4Edit: false,
    enableOverlayVideoEdit: false
  };

  $scope.overlayBox = {
    overlayButtonText1: {
      containerId: 'image-landing-page-container-buttonText1',
      id: 'overlayButtonText1EditorBox',
      distanceText: '',
      css: {}
    },
    overlayButtonText2: {
      containerId: 'image-landing-page-container-buttonText2',
      id: 'overlayButtonText2EditorBox',
      distanceText: '',
      css: {}
    },
    callButton: {
      containerId: 'image-landing-page-container-call',
      id: 'callButtonEditorBox',
      distanceText: '',
      css: {}
    },
    directionButton: {
      containerId: 'image-landing-page-container-direction',
      id: 'directionButtonEditorBox',
      distanceText: '',
      css: {}
    },
    overlayButtonNoText1: {
      containerId: 'image-landing-page-container-buttonNoText1',
      id: 'overlayButtonNoText1EditorBox',
      distanceText: '',
      css: {}
    },
    overlayButtonNoText2: {
      containerId: 'image-landing-page-container-buttonNoText2',
      id: 'overlayButtonNoText2EditorBox',
      distanceText: '',
      css: {}
    },
    overlayButtonNoText3: {
      containerId: 'image-landing-page-container-buttonNoText3',
      id: 'overlayButtonNoText3EditorBox',
      distanceText: '',
      css: {}
    },
    overlayButtonNoText4: {
      containerId: 'image-landing-page-container-buttonNoText4',
      id: 'overlayButtonNoText4EditorBox',
      distanceText: '',
      css: {}
    },
    overlayVideo: {
      containerId: 'image-landing-page-container-video',
      id: 'overlayVideoEditorBox',
      distanceText: '',
      videoUrl: '',
      css: {}
    }
  };

  var addedContainerHeightForVideo = 300;

  var defaultLpFieldValues = {};

  $scope.imageUrl = {
    background: null
  };

  $scope.landingPageMethod.beforeSave = function() {
    $scope.creative.lpConfig.imageUrl_320x480 = $scope.imageUrl.background;

    if ($scope.booleanCheckbox.overlayButtonText1Checkbox) {
      $scope.creative.lpConfig.overlayText1 = $scope.overlayBox.overlayButtonText1.distanceText;
      $scope.creative.lpConfig.overlayText1Css = generalUtilsFactory.dictCssToStringCss(
        $scope.overlayBox.overlayButtonText1.css
      );
    } else {
      $scope.creative.lpConfig.overlayText1 = '';
      $scope.creative.lpConfig.overlayText1Css = '';
    }

    if ($scope.booleanCheckbox.overlayButtonText2Checkbox) {
      $scope.creative.lpConfig.overlayText2 = $scope.overlayBox.overlayButtonText2.distanceText;
      $scope.creative.lpConfig.overlayText2Css = generalUtilsFactory.dictCssToStringCss(
        $scope.overlayBox.overlayButtonText2.css
      );
    } else {
      $scope.creative.lpConfig.overlayText2 = '';
      $scope.creative.lpConfig.overlayText2Css = '';
    }

    if ($scope.creative.lpConfig.enablePhoneButton === 'YES') {
      $scope.creative.lpConfig.phoneButtonCss = generalUtilsFactory.dictCssToStringCss(
        $scope.overlayBox.callButton.css
      );
    } else {
      $scope.creative.lpConfig.phoneButtonCss = '';
    }

    if ($scope.creative.lpConfig.enableDirectionsButton === 'YES') {
      $scope.creative.lpConfig.directionsButtonCss = generalUtilsFactory.dictCssToStringCss(
        $scope.overlayBox.directionButton.css
      );
    } else {
      $scope.creative.lpConfig.directionsButtonCss = '';
    }

    if ($scope.creative.lpConfig.button1Link) {
      $scope.creative.lpConfig.button1Css = generalUtilsFactory.dictCssToStringCss(
        $scope.overlayBox.overlayButtonNoText1.css
      );
    } else {
      $scope.creative.lpConfig.button1Css = '';
    }

    if ($scope.creative.lpConfig.button2Link) {
      $scope.creative.lpConfig.button2Css = generalUtilsFactory.dictCssToStringCss(
        $scope.overlayBox.overlayButtonNoText2.css
      );
    } else {
      $scope.creative.lpConfig.button2Css = '';
    }

    if ($scope.creative.lpConfig.button3Link) {
      $scope.creative.lpConfig.button3Css = generalUtilsFactory.dictCssToStringCss(
        $scope.overlayBox.overlayButtonNoText3.css
      );
    } else {
      $scope.creative.lpConfig.button3Css = '';
    }

    if ($scope.creative.lpConfig.button4Link) {
      $scope.creative.lpConfig.button4Css = generalUtilsFactory.dictCssToStringCss(
        $scope.overlayBox.overlayButtonNoText4.css
      );
    } else {
      $scope.creative.lpConfig.button4Css = '';
    }

    if ($scope.creative.lpConfig.customYouTubeId) {
      $scope.creative.lpConfig.cssYouTube = generalUtilsFactory.dictCssToStringCss($scope.overlayBox.overlayVideo.css);
    } else {
      $scope.creative.lpConfig.cssYouTube = '';
    }
  };

  $scope.init = function() {
    angular.element('#backgroundImageOverlayFieldSet').css('display', 'none');

    var landingPageDfd = staticDataFactory.getLandingPage();
    var creativeFieldDfd = staticDataFactory.getCreativeFields();

    $q.all([landingPageDfd, creativeFieldDfd]).then(function() {
      if (staticDataFactory.landingPage['image']) {
        defaultLpFieldValues = staticDataFactory.landingPage['image'];
        var defaultLpFieldValuesCopy = angular.copy(defaultLpFieldValues);

        // if lpConfig is empty or useLandingPage is not set, get default Landing Pages
        if ($scope.creative && !$scope.creative.useLandingPage) {
          $scope.creative.lpConfig = defaultLpFieldValuesCopy;
          if (
            ($scope.adGroupCopy.product === 'default' || $scope.adGroupCopy.product.indexOf('awareness') > -1) &&
            $scope.creative.lpConfig.hasOwnProperty('enableDirectionsButton')
          ) {
            // set enable directions to false when product is not defined or product is awareness
            $scope.creative.lpConfig.enableDirectionsButton = '';
          }
        }
      } else {
        throw 'Image landing page config not found.';
      }

      $scope.imageUrl.background = $scope.creative.lpConfig.imageUrl_320x480;

      if ($scope.creative.lpConfig.overlayText1 === undefined || $scope.creative.lpConfig.overlayText1 == '') {
        $scope.booleanCheckbox.overlayButtonText1Checkbox = false;
        $scope.overlayBox.overlayButtonText1.distanceText = defaultLpFieldValuesCopy.overlayText1;
        $scope.overlayBox.overlayButtonText1.css = generalUtilsFactory.stringCssToDictCss(
          defaultLpFieldValuesCopy.overlayText1Css
        );
      } else {
        $scope.booleanCheckbox.overlayButtonText1Checkbox = true;
        $scope.overlayBox.overlayButtonText1.distanceText = $scope.creative.lpConfig.overlayText1;
        var overlayText1Css = generalUtilsFactory.stringCssToDictCss($scope.creative.lpConfig.overlayText1Css);
        var defaultText1Css = generalUtilsFactory.stringCssToDictCss(defaultLpFieldValuesCopy.overlayText1Css);

        $scope.overlayBox.overlayButtonText1.css = addMissingAndRemoveAdditionalCssProperties(
          defaultText1Css,
          overlayText1Css
        );
      }

      if ($scope.creative.lpConfig.overlayText2 === undefined || $scope.creative.lpConfig.overlayText2 == '') {
        $scope.booleanCheckbox.overlayButtonText2Checkbox = false;
        $scope.overlayBox.overlayButtonText2.distanceText = defaultLpFieldValuesCopy.overlayText2;
        $scope.overlayBox.overlayButtonText2.css = generalUtilsFactory.stringCssToDictCss(
          defaultLpFieldValuesCopy.overlayText2Css
        );
      } else {
        $scope.booleanCheckbox.overlayButtonText2Checkbox = true;
        $scope.overlayBox.overlayButtonText2.distanceText = $scope.creative.lpConfig.overlayText2;
        var overlayText2Css = generalUtilsFactory.stringCssToDictCss($scope.creative.lpConfig.overlayText2Css);
        var defaultText2Css = generalUtilsFactory.stringCssToDictCss(defaultLpFieldValuesCopy.overlayText2Css);

        $scope.overlayBox.overlayButtonText2.css = addMissingAndRemoveAdditionalCssProperties(
          defaultText2Css,
          overlayText2Css
        );
      }

      var callButtonCss = generalUtilsFactory.stringCssToDictCss($scope.creative.lpConfig.phoneButtonCss);
      var defaultCallButtonCss = generalUtilsFactory.stringCssToDictCss(defaultLpFieldValuesCopy.phoneButtonCss);
      $scope.overlayBox.callButton.css = addMissingAndRemoveAdditionalCssProperties(
        defaultCallButtonCss,
        callButtonCss
      );

      var directionButtonCss = generalUtilsFactory.stringCssToDictCss($scope.creative.lpConfig.directionsButtonCss);
      var defaultDirectionButtonCss = generalUtilsFactory.stringCssToDictCss(
        defaultLpFieldValuesCopy.directionsButtonCss
      );
      $scope.overlayBox.directionButton.css = addMissingAndRemoveAdditionalCssProperties(
        defaultDirectionButtonCss,
        directionButtonCss
      );

      var button1Css = generalUtilsFactory.stringCssToDictCss($scope.creative.lpConfig.button1Css);
      var defaultButton1Css = generalUtilsFactory.stringCssToDictCss(defaultLpFieldValuesCopy.button1Css);
      $scope.overlayBox.overlayButtonNoText1.css = addMissingAndRemoveAdditionalCssProperties(
        defaultButton1Css,
        button1Css
      );

      var button2Css = generalUtilsFactory.stringCssToDictCss($scope.creative.lpConfig.button2Css);
      var defaultButton2Css = generalUtilsFactory.stringCssToDictCss(defaultLpFieldValuesCopy.button2Css);
      $scope.overlayBox.overlayButtonNoText2.css = addMissingAndRemoveAdditionalCssProperties(
        defaultButton2Css,
        button2Css
      );

      var button3Css = generalUtilsFactory.stringCssToDictCss($scope.creative.lpConfig.button3Css);
      var defaultButton3Css = generalUtilsFactory.stringCssToDictCss(defaultLpFieldValuesCopy.button3Css);
      $scope.overlayBox.overlayButtonNoText3.css = addMissingAndRemoveAdditionalCssProperties(
        defaultButton3Css,
        button3Css
      );

      var button4Css = generalUtilsFactory.stringCssToDictCss($scope.creative.lpConfig.button4Css);
      var defaultButton4Css = generalUtilsFactory.stringCssToDictCss(defaultLpFieldValuesCopy.button4Css);
      $scope.overlayBox.overlayButtonNoText4.css = addMissingAndRemoveAdditionalCssProperties(
        defaultButton4Css,
        button4Css
      );

      var youtubeCss = generalUtilsFactory.stringCssToDictCss($scope.creative.lpConfig.cssYouTube);
      var defaultYoutubeCss = generalUtilsFactory.stringCssToDictCss(defaultLpFieldValuesCopy.cssYouTube);
      $scope.overlayBox.overlayVideo.css = addMissingAndRemoveAdditionalCssProperties(defaultYoutubeCss, youtubeCss);
      if ($scope.creative.lpConfig.customYouTubeId !== undefined || $scope.creative.lpConfig.customYouTubeId !== '') {
        $scope.overlayBox.overlayVideo.videoUrl = $sce.trustAsResourceUrl(
          'https://www.youtube.com/embed/' + $scope.creative.lpConfig.customYouTubeId + '?rel=0&showinfo=0'
        );
      } else {
        $scope.overlayBox.overlayVideo.videoUrl = '';
      }

      $('#creative-container-image-landing-page-container-direction').css({ width: 320, height: 480 });
      $('#creative-container-image-landing-page-container-call').css({ width: 320, height: 480 });
      $('#creative-container-image-landing-page-container-buttonText1').css({ width: 320, height: 480 });
      $('#creative-container-image-landing-page-container-buttonText2').css({ width: 320, height: 480 });
      $('#creative-container-image-landing-page-container-buttonNoText1').css({ width: 320, height: 480 });
      $('#creative-container-image-landing-page-container-buttonNoText2').css({ width: 320, height: 480 });
      $('#creative-container-image-landing-page-container-buttonNoText3').css({ width: 320, height: 480 });
      $('#creative-container-image-landing-page-container-buttonNoText4').css({ width: 320, height: 480 });
      $('#creative-container-image-landing-page-container-video').css({
        width: 320,
        height: 480 + addedContainerHeightForVideo
      });

      previewScroll();

      $scope.dataChanged.changed = true;
      $scope.boolean.dataLoaded = true;
    });
  };
  $scope.init();

  $scope.onBackgroundImageFileAdded = function(flow, file, $event) {
    $scope.progressBackgroundImageUpload = true;
    $scope.backgroundImageUploader.flow = flow;
    $('.background-image-error-msg').text('');
    if (file.file.dimensions) {
      file.dimensions = file.file.dimensions;
      if (file.dimensions.width < 100) {
        alert('invalid dimensions');
        return false;
      }
      return true;
    }
    var fileReader = new FileReader();
    fileReader.readAsDataURL(file.file);
    fileReader.onload = function(event) {
      var img = new Image();
      img.onload = function() {
        $timeout(function() {
          file.file.dimensions = {
            width: img.width,
            height: img.height
          };

          var isValidImageSize = creativeTemplatesFactory.IsHeaderImageSizeProportionate(
            file.file.dimensions,
            $scope.headerImageExpectedSizes
          );
          if (!isValidImageSize) {
            $('.background-image-error-msg').text('Invalid image dimension. It should be 640x960 or proportionate.');
            $scope.progressBackgroundImageUpload = false;
            return;
          }

          $scope.backgroundImageUploader.flow.addFile(file.file);
        });
      };
      if (typeof fileReader.result === 'string') img.src = fileReader.result;
    };

    return false;
  };

  $scope.backgroundImageFileAdded = function($flow, flowFile, message) {
    $scope.imageUrl.background = window.mediaCdnUrl + JSON.parse(message).s3_path;
    $scope.progressBackgroundImageUpload = false;
  };

  $scope.uploadBackgroundImageUrl = function() {
    if ($scope.imageUrl.background === undefined || $scope.imageUrl.background === '') {
      return;
    }

    $('.background-image-error-msg').text('');
    $scope.progressBackgroundImageUpload = true;
    creativeTemplatesFactory
      .uploadImageUrlToS3($scope.imageUrl.background, 'image_landing_page')
      .then(
        function(data) {
          $scope.backgroundImageFileAdded(undefined, undefined, data);
        },
        function(data) {
          $('.background-image-error-msg').text('Failed to load the Image.');
        }
      )
      .finally(function() {
        $scope.progressBackgroundImageUpload = false;
      });
  };

  $scope.overlayButtonText1CheckboxChanged = function() {
    if (!$scope.booleanCheckbox.overlayButtonText1Checkbox) {
      $('.' + $scope.overlayBox.overlayButtonText1.id).css('display', 'none');
    } else {
      $('.' + $scope.overlayBox.overlayButtonText1.id).css('display', 'block');
      $scope.overlayBox.overlayButtonText1.distanceText = defaultLpFieldValues.overlayText1;
      $scope.overlayBox.overlayButtonText1.css = generalUtilsFactory.stringCssToDictCss(
        defaultLpFieldValues.overlayText1Css
      );
    }
  };

  $scope.overlayButtonText2CheckboxChanged = function() {
    if (!$scope.booleanCheckbox.overlayButtonText2Checkbox) {
      $('.' + $scope.overlayBox.overlayButtonText2.id).css('display', 'none');
    } else {
      $('.' + $scope.overlayBox.overlayButtonText2.id).css('display', 'block');
      $scope.overlayBox.overlayButtonText2.distanceText = defaultLpFieldValues.overlayText2;
      $scope.overlayBox.overlayButtonText2.css = generalUtilsFactory.stringCssToDictCss(
        defaultLpFieldValues.overlayText2Css
      );
    }
  };

  $scope.videoIdChanged = function() {
    if ($scope.creative.lpConfig.customYouTubeId !== '') {
      $scope.overlayBox.overlayVideo.videoUrl = $sce.trustAsResourceUrl(
        'https://www.youtube.com/embed/' + $scope.creative.lpConfig.customYouTubeId + '?rel=0&showinfo=0'
      );
    } else {
      $scope.booleanEditMode.enableOverlayVideoEdit = false;

      $scope.overlayBox.overlayVideo.videoUrl = '';
    }
  };

  $scope.singleEditModeOn = function(editModeChangedFor) {
    const isTextInEditMode =
      $scope.booleanEditMode.enableOverlayButtonText1Edit || $scope.booleanEditMode.enableOverlayButtonText2Edit;

    for (var key in $scope.booleanEditMode) {
      if ($scope.booleanEditMode.hasOwnProperty(key)) {
        // if any Edit switch is turned ON then all other Edit switches should be turned OFF
        if ($scope.booleanEditMode[editModeChangedFor] && key !== editModeChangedFor) {
          $scope.booleanEditMode[key] = false;
        }
      }
    }

    if ($scope.booleanEditMode.enableBackgroundImageEdit) {
      angular.element('#backgroundImageOverlayFieldSet').css('display', 'block');
      $scope.boolean.backgroundImageFlowDropEnabled = true;
    } else {
      angular.element('#backgroundImageOverlayFieldSet').css('display', 'none');
      $scope.boolean.backgroundImageFlowDropEnabled = false;
    }

    if (editModeChangedFor === 'enableOverlayVideoEdit' && isTextInEditMode) {
      // Addressing the issue of landing page preview iframe getting outside of
      // the borders in some scenarios https://xadinc.atlassian.net/browse/APPS-9921
      setTimeout(() => {
        $('.image-landing-page-preview-iframe').css({
          position: '',
          top: '0px'
        });
        const landingPageSections = document.querySelectorAll('.landing-page-section');
        if (landingPageSections?.length >= 2) {
          const secondElement = landingPageSections[1];
          secondElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });
    }
  };

  // Scrolling of preview with respect to modal.
  var previewScroll = function() {
    // When user changes landing page from standard/super to image,
    // the DOM still has standard/super landing page preview iframe and renders image landing page preview.
    // So if we calulate the $(".image-landing-page-preview-iframe")[0].offsetTop the value is incorrect
    // because both the previews are present in DOM.
    // So below timeout function will execute after DOM removes standard/super landing page preview iframe
    $timeout(function() {
      var previewTop = /** @type {HTMLElement} */ ($('.image-landing-page-preview-iframe')[0]).offsetTop;
      var busyScrolling = false;
      var timer = null;

      $('#modalId')
        .closest('.modal')
        .scroll(function() {
          // busyScrolling is just to throttle the scrolling.
          // It will prevent the next scroll event to stop if previous one is already processing
          if (busyScrolling) {
            return;
          }

          busyScrolling = true;
          var windowTop = $('#modalId')
            .closest('.modal')
            .scrollTop();
          /** @type {HTMLElement} */
          const landingPageFieldElement = ($('.landing-page-fields')[0]);
          if (!landingPageFieldElement) {
            return;
          }
          var fieldFormTopPlusHeight = landingPageFieldElement.offsetHeight + landingPageFieldElement.offsetTop;

          // if user has scrolled past the preview iframe AND preview iframe height is less than fieldFormTopPlusHeight
          // then set preview iframe position and top
          if (
            windowTop >= previewTop &&
            windowTop + /** @type {HTMLElement} */ ($('.image-landing-page-preview-iframe')[0])?.offsetHeight <=
              fieldFormTopPlusHeight
          ) {
            $('.image-landing-page-preview-iframe').css({
              position: 'fixed',
              top: windowTop + 'px'
            });
          } else if (windowTop < previewTop) {
            // if user has scrolled up the preview iframe then set the original position and top
            $('.image-landing-page-preview-iframe').css({
              position: '',
              top: '0px'
            });
          }
          busyScrolling = false;

          // Scroll works fine if scrolled slowly.
          // If user scrolls quickly all the way to the end, below code will handle it.
          // Below code will trigger 200ms after scrolling has ended.
          if (timer !== null) {
            clearTimeout(timer);
          }
          timer = setTimeout(function() {
            const landingPageFieldElement = /** @type {HTMLElement} */ ($('.landing-page-fields')[0]);
            var fieldFormTopPlusFieldFormHeightSubModalBodyPaddingBottom =
              landingPageFieldElement.offsetHeight +
              landingPageFieldElement.offsetTop +
              parseInt($('.modal-body').css('padding-bottom'));
            var modalFooterTop = /** @type {HTMLElement} */ ($('.modal-footer')[0]).offsetTop;

            // if user has scrolled past the preview iframe AND
            // field form top offset + field form height + padding === footer's top offset
            // meaning the scroll has reached end of the modal.
            if (
              windowTop >= previewTop &&
              fieldFormTopPlusFieldFormHeightSubModalBodyPaddingBottom === modalFooterTop
            ) {
              const imageLandingPagePreviewIframeElement = /** @type {HTMLIFrameElement} */ ($(
                '.image-landing-page-preview-iframe'
              )[0]);
              if (!imageLandingPagePreviewIframeElement) {
                return;
              }
              const landingPageFieldElement = /** @type {HTMLElement} */ ($('.landing-page-fields')[0]);
              // this is just to cap the windowTop so that preview iframe does NOT go over the modal footer ( save button)
              windowTop =
                windowTop + imageLandingPagePreviewIframeElement.offsetHeight >
                landingPageFieldElement.offsetHeight + landingPageFieldElement.offsetTop
                  ? landingPageFieldElement.offsetHeight +
                    landingPageFieldElement.offsetTop -
                    imageLandingPagePreviewIframeElement.offsetHeight
                  : windowTop;

              $('.image-landing-page-preview-iframe').css({
                position: 'fixed',
                top: windowTop + 'px'
              });
            }
          }, 200);
        });
    });
  };

  function addMissingAndRemoveAdditionalCssProperties(defaultCss, destinationCss) {
    for (var key in defaultCss) {
      if (defaultCss.hasOwnProperty(key)) {
        if (destinationCss[key] === undefined) {
          destinationCss[key] = defaultCss[key];
        }
      }
    }

    for (var key in destinationCss) {
      if (destinationCss.hasOwnProperty(key)) {
        if (defaultCss[key] === undefined) {
          delete destinationCss[key];
        }
      }
    }

    return destinationCss;
  }
}
