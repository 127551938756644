import { TrackingPixelWithStatus } from '../../../api/types/account_id_pixelStatuses';
import { TrackingPixel } from '../../../api/types/pixels';
import { map, find, merge, pick } from 'lodash';

export function updatePixelStatuses(pixles: TrackingPixel[], pixelsWithStatus: TrackingPixelWithStatus[]) {
  return map(pixles, pixel => {
    const found = find(pixelsWithStatus, { id: pixel.id });
    return (found && merge(pixel, pick(found, ['status']))) || pixel;
  });
}
