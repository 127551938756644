import { send$http } from '../api/def/ngExecutor';
import { put_update_ad_group_device_type_api } from '../api/http/adgroup';
import {
  get_creative_video_transcoder_statuses_api,
  CreativeLPLocationResponse,
  get_creative_html5_statuses_api
} from '../api/http/creative';
import { put_save_all_creatives_api } from '../api/http/data.generated';
import { isEmpty } from 'lodash';

campaignManagerFactory.$inject = [
  '$q',
  '$http',
  'xadcmsFactory',
  'generalUtilsFactory',
  '$state',
  '$filter',
  'selfServeDataFactory',
  'debounce2',
  'reportFactory'
];

/**
 *
 * @param {ng.IQService} $q
 * @param {ng.IHttpService} $http
 * @param {import('./types').GeneralUtilsService} generalUtilsFactory
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {ng.IFilterService} $filter
 * @param {import('./debounce2').Debounce2Service} debounce2
 */
export function campaignManagerFactory(
  $q,
  $http,
  xadcmsFactory,
  generalUtilsFactory,
  $state,
  $filter,
  selfServeDataFactory,
  debounce2,
  reportFactory
) {
  /*
    This factory will maintain a cache of all campaigns which have adgroups in them.
     */
  var campaignManagerFactory = {};

  campaignManagerFactory.init = function() {
    // CONSTANTS
    campaignManagerFactory.statusNames = {
      ACTIVE: 'Active',
      PENDING: 'Pending',
      PAUSED: 'Paused',
      EXPIRED: 'Expired',
      ERROR: 'Error',
      DRAFT: 'Draft',
      SUBMITTED: 'Submitted',
      REJECTED: 'Rejected'
    };

    campaignManagerFactory.selectedCampaign = null;
    campaignManagerFactory.selectedAdGroup = null;

    campaignManagerFactory.widgetColorBasedOnStatus = {};
    // using an IIFE for readability
    (function(w) {
      var statuses = campaignManagerFactory.statusNames;
      w[statuses.ACTIVE] = 'widgetIsActive';
      w[statuses.PENDING] = 'widgetIsPending';
      w[statuses.PAUSED] = 'widgetIsPaused';
      w[statuses.EXPIRED] = 'widgetIsExpired';
      w[statuses.ERROR] = 'widgetIsError';
      w[statuses.DRAFT] = 'widgetIsDraft';
      w[statuses.SUBMITTED] = 'widgetIsSubmitted';
      w[statuses.REJECTED] = 'widgetIsRejected';
    })(campaignManagerFactory.widgetColorBasedOnStatus);

    campaignManagerFactory.clean();
  };

  campaignManagerFactory.clean = function() {
    campaignManagerFactory.selectedCampaign = null;
    campaignManagerFactory.selectedAdGroup = null;
    campaignManagerFactory.selectedCampaignIndex = 'null';
    campaignManagerFactory.selectedCampaignSettings = null;
    campaignManagerFactory.campaigns = [];
    campaignManagerFactory.selectedCampaignAdGroups = {};

    campaignManagerFactory.livecampaigns = [];
    campaignManagerFactory.channelCampaigns = [];
    campaignManagerFactory.channelCampaignsToShow = [];
    campaignManagerFactory.liveCampaignsToShow = [];
    campaignManagerFactory.filteredCampaigns = [];
    campaignManagerFactory.filteredChannelCampaigns = [];
    campaignManagerFactory.noCampaigns = false;
    campaignManagerFactory.numCampaignsToShow = 0;

    campaignManagerFactory.searchCampaign = null;
    campaignManagerFactory.campaignStatus = '';
  };

  campaignManagerFactory.init();

  function adjustCampaignCreatives(campaign) {
    if (campaign.hasOwnProperty('adGroups')) {
      for (var i = 0; i < campaign.adGroups.length; i++) {
        adjustAdGroupCreatives(campaign.adGroups[i]);
      }
    }
  }

  function adjustAdGroupCreatives(adGroup) {
    adGroup.creative = {
      url: null,
      clickTracker: null,
      impressionTracker: null,
      landingPageUrl: null
    };
    if (adGroup.creatives?.length) {
      var creative = adGroup.creatives[0];
      adGroup.creative = creative;
      adGroup.creativeId = creative.id;
    }
  }

  function computeCampaignBudgetAndAdjustCreatives(campaign) {
    computeCampaignBudget(campaign);
    adjustCampaignCreatives(campaign);
  }

  campaignManagerFactory.loadCampaignsWithBudgetsForAccount = async function(selectedCampaignId, selectedAdGroupId) {
    if (campaignManagerFactory.livecampaigns.length) {
      campaignManagerFactory.selectCampaignWithId(selectedCampaignId);
      campaignManagerFactory.selectedCampaign = null;
      return {};
    }

    const campaignPromise = xadcmsFactory.getCampaignsWithBudgetsForAccount(selectedCampaignId, selectedAdGroupId);
    const reportPromise = reportFactory.getAccountTableData({});

    campaignManagerFactory.livecampaigns = await campaignPromise;
    campaignManagerFactory.updateFilteredCampaigns('');
    campaignManagerFactory.noCampaigns = false;
    if (isEmpty(campaignManagerFactory.livecampaigns)) {
      campaignManagerFactory.noCampaigns = true;
      campaignManagerFactory.livecampaigns = [];
    }
    if (!Array.isArray(campaignManagerFactory.livecampaigns)) {
      campaignManagerFactory.livecampaigns = [campaignManagerFactory.livecampaigns];
    }

    campaignManagerFactory.livecampaigns.forEach(campaign => computeCampaignBudgetAndAdjustCreatives(campaign));
    campaignManagerFactory.selectAdGroupWithId(selectedCampaignId, selectedAdGroupId);
    campaignManagerFactory.selectCampaignWithId(selectedCampaignId);

    campaignManagerFactory.loadMoreCampaigns(campaignManagerFactory.livecampaigns.length);

    try {
      const reportData = await reportPromise;
      const dataMap = reportData.reduce(function(acc, item) {
        acc[item.campaign_id] = item;
        return acc;
      }, {});

      campaignManagerFactory.livecampaigns.forEach(function(campaign) {
        if (dataMap[campaign.id]) {
          campaign.spends_spent = dataMap[campaign.id].spends_spent;
          campaign.spends_daily_spent = dataMap[campaign.id].spends_daily_spent;
        }
      });
    } catch (err) {
      // ignore a failure to connect to the reporting api
    }

    return campaignManagerFactory.livecampaigns;
  };

  campaignManagerFactory.abort = function(campaignId) {
    xadcmsFactory.abort(campaignId);
  };

  campaignManagerFactory.loadCampaignWithAdGroups = function(selectedCampaignId, selectedAdGroupId) {
    var d = $q.defer();

    xadcmsFactory
      .getCampaignWithAdGroups(selectedCampaignId, selectedAdGroupId)
      .then(function(data) {
        if (selectedAdGroupId && selectedCampaignId) {
          campaignManagerFactory.updateCampaign(data[0]);
        } else if (data.length !== 1) {
          d.reject();
          return d.promise;
        } else {
          campaignManagerFactory.updateCampaign(data[0]);
        }

        campaignManagerFactory.livecampaigns.forEach(campaign => computeCampaignBudgetAndAdjustCreatives(campaign));
        campaignManagerFactory.selectCampaignWithId(selectedCampaignId);
        campaignManagerFactory.selectAdGroupWithId(selectedCampaignId, selectedAdGroupId);
        campaignManagerFactory.loadMoreCampaigns(campaignManagerFactory.livecampaigns.length);
        d.resolve(data[0]);
      })
      .catch(function(err) {
        console.error(err);
        d.reject(err);
      });
    return d.promise;
  };

  campaignManagerFactory.updateFilteredCampaigns = function(filterValue) {
    var searchFields = {
      id: [0, false],
      name: [0, false],
      status: [1, true],
      adGroups: {
        id: [0, false],
        name: [0, false],
        mode: [0, false],
        status: [1, true]
      }
    };
    if (angular.isDefined(filterValue)) {
      campaignManagerFactory.campaignStatus = filterValue;
    }
    campaignManagerFactory.liveCampaignsToShow.length = 0;
    campaignManagerFactory.filteredCampaigns = $filter('deepsearch')(
      campaignManagerFactory.livecampaigns,
      campaignManagerFactory.searchCampaign,
      campaignManagerFactory.campaignStatus,
      searchFields
    );
    campaignManagerFactory.loadMoreCampaigns(campaignManagerFactory.livecampaigns.length);
  };

  campaignManagerFactory.updateFilteredChannelCampaigns = function(filterValue) {
    var searchFields = {
      id: [0, false],
      name: [0, false],
      status: [1, true]
    };

    if (angular.isDefined(filterValue)) {
      campaignManagerFactory.campaignStatus = filterValue;
    }
    campaignManagerFactory.channelCampaignsToShow = [];
    campaignManagerFactory.filteredChannelCampaigns = $filter('deepsearch')(
      campaignManagerFactory.channelCampaigns,
      campaignManagerFactory.searchCampaign,
      campaignManagerFactory.campaignStatus,
      searchFields
    );
    campaignManagerFactory.channelCampaignsToShow = campaignManagerFactory.filteredChannelCampaigns;
  };

  campaignManagerFactory.selectCampaignWithId = function(campaignId) {
    window.campaign = campaignManagerFactory.selectFromCampaignsWithId(campaignId);
    if (!window.campaign) {
      return false;
    }
    campaignManagerFactory.selectedCampaign = window.campaign;
    return true;
  };

  campaignManagerFactory.selectFromCampaignsWithId = function(selectedCampaignId) {
    if (typeof selectedCampaignId === 'undefined') {
      return false;
    }
    for (var i = 0; i < campaignManagerFactory.livecampaigns.length; i++) {
      // Reminder: type of selectedCampaignId is string, compare it use '=='
      if (campaignManagerFactory.livecampaigns[i]['id'] == selectedCampaignId) {
        return campaignManagerFactory.livecampaigns[i];
      }
    }
  };

  campaignManagerFactory.getSelectedCampaignWithSettings = function() {
    var d = $q.defer();
    if (!isEmpty(campaignManagerFactory.selectedCampaign.settings)) {
      d.resolve(campaignManagerFactory.selectedCampaign);
    } else {
      $http({ method: 'GET', url: `/data/campaigns/${campaignManagerFactory.selectedCampaign.id}/settings` })
        .then(function({ data }) {
          campaignManagerFactory.selectedCampaign.settings = data;
          d.resolve(campaignManagerFactory.selectedCampaign);
        })
        .catch(function() {
          d.reject('An error occurred while getting campaign settings. Please try again later');
        });
    }
    return d.promise;
  };

  campaignManagerFactory.getSelectedAdGroup = function() {
    // if targeting is not there in the adgroup object
    // fetch it and
    var d = $q.defer();
    if (!campaignManagerFactory.selectedAdGroup) {
      d.reject();
    } else if (!isEmpty(campaignManagerFactory.selectedAdGroup.targeting)) {
      d.resolve(campaignManagerFactory.selectedAdGroup);
    } else {
      xadcmsFactory
        .getAdGroupSettingsData(campaignManagerFactory.selectedCampaign.id, campaignManagerFactory.selectedAdGroup.id)
        .then(
          function(data) {
            campaignManagerFactory.selectedAdGroup.targeting = data;
            campaignManagerFactory.selectedAdGroup.product = data.product;
            d.resolve(campaignManagerFactory.selectedAdGroup);
          },
          function(data) {
            // this is the error message displayed to the user
            d.reject('An error occurred. Please try again later.');
          }
        );
    }
    return d.promise;
  };

  campaignManagerFactory.getSelectedAdGroupLocationGroupSensitivityData = function() {
    var d = $q.defer();
    xadcmsFactory
      .getAdGroupLocationGroupSensitivityData(
        campaignManagerFactory.selectedCampaign.id,
        campaignManagerFactory.selectedAdGroup.id
      )
      .then(
        function(data) {
          d.resolve(data);
        },
        function(data) {
          // this is the error message displayed to the user
          d.reject('An error occurred. Please try again later.');
        }
      );
    return d.promise;
  };

  /** Updates cached campaign/adgroups/creatives to match updatedCampaign. Used to save space while moving data.
   *  This function assumes that the adgroups within the cachedCampaign do NOT have any breakdowns expanded. **/
  campaignManagerFactory.updateCampaign = function(updatedCampaign) {
    // Get cached campaign.
    var cachedCampaign = campaignManagerFactory.selectFromCampaignsWithId(updatedCampaign['id']);

    if (cachedCampaign) {
      var updatedAdgroups = updatedCampaign['adGroups'];
      delete updatedCampaign['adGroups'];

      // 1. Update all campaign properties.
      generalUtilsFactory.nestedUpdateInPlace(updatedCampaign, cachedCampaign);

      // Iterate through updatedAdgroups, copying over new data to cachedAdGroups.
      for (var i = 0; i < updatedAdgroups.length; i++) {
        var cachedAdGroup = campaignManagerFactory.selectFromAdGroupsWithId(
          cachedCampaign.adGroups,
          updatedAdgroups[i].id
        );

        if (cachedAdGroup) {
          var updatedCreatives = updatedAdgroups[i].creatives;
          delete updatedAdgroups[i]['creatives'];

          if (!isEmpty(updatedAdgroups[i].targeting) && updatedAdgroups[i].targeting.deviceTypes) {
            // Due to the inconsistency between the naming convention, copying deviceTypes to publisherTypes
            // As heatmap and avails are expecting those values
            updatedAdgroups[i].targeting.publisherTypes = updatedAdgroups[i].targeting.deviceTypes;
          }

          // 2. Update all adgroup properties.
          generalUtilsFactory.nestedUpdateInPlace(updatedAdgroups[i], cachedAdGroup);

          // Same process for creatives.
          for (var c = 0; c < updatedCreatives.length; c++) {
            var cachedCreative = campaignManagerFactory.selectFromCreativesWithId(
              cachedAdGroup.creatives,
              updatedCreatives[c].id
            );

            if (cachedCreative) {
              // 3. Update all creative properties.
              generalUtilsFactory.nestedUpdateInPlace(updatedCreatives[c], cachedCreative);
              angular.copy(updatedCreatives[c].cmsConfig, cachedCreative.cmsConfig);
            } else {
              if (!cachedAdGroup.creatives) {
                cachedAdGroup.creatives = [];
              }
              cachedAdGroup.creatives.push(updatedCreatives[c]);
            }
          }
        } else {
          // No cachedAdGroup with updatedAdGroup's ID. Push to campaign.
          cachedCampaign.adGroups.push(updatedAdgroups[i]);
        }
      }
      computeCampaignBudgetAndAdjustCreatives(cachedCampaign);
    } else {
      computeCampaignBudgetAndAdjustCreatives(updatedCampaign);
      if (campaignManagerFactory.channelCampaigns.length > 0) {
        var index = campaignManagerFactory.getIndexOfChannelCampaign(updatedCampaign);
        if (index === -1) {
          campaignManagerFactory.channelCampaigns.push(updatedCampaign);
        }
        campaignManagerFactory.updateFilteredChannelCampaigns();
      }
      campaignManagerFactory.livecampaigns.push(updatedCampaign);
      campaignManagerFactory.updateFilteredCampaigns();
    }
  };

  campaignManagerFactory.selectFromCreativesWithId = function(creatives, id) {
    return (creatives || []).find(creative => creative.id == id) || null;
  };

  campaignManagerFactory.saveAdGroupCreatives = function(campaignId, adGroup) {
    // get the argument object
    // make an object that can be send to the backend for saving complete adgroup
    // get the return object and update the object in cache
    var d = $q.defer();

    if (adGroup && adGroup.creatives) {
      for (var i = 0; i < adGroup.creatives.length; i++) {
        if (adGroup.creatives[i].startDate) {
          adGroup.creatives[i].startDate = generalUtilsFactory.DateToString(adGroup.creatives[i].startDate);
        }
        if (adGroup.creatives[i].endDate) {
          adGroup.creatives[i].endDate = generalUtilsFactory.DateToString(adGroup.creatives[i].endDate);
        }
      }
    }

    $http({
      method: 'PUT',
      url: `/data/campaigns/${campaignId}/adgroups/${adGroup.id}/creatives`,
      data: adGroup
    })
      .then(function({ data: updatedCampaign }) {
        campaignManagerFactory.updateCampaign(updatedCampaign);
        d.resolve();
      })
      .catch(function({ data }) {
        let errors = generalUtilsFactory.extractErrorsFromResponse(data);
        // this is the error message displayed to the user
        d.reject(errors);
      });

    return d.promise;
  };

  campaignManagerFactory.saveAdGroupCreativesV2 = async function(campaignId, adGroup) {
    if (adGroup?.creatives) {
      for (var i = 0; i < adGroup.creatives.length; i++) {
        if (adGroup.creatives[i].startDate) {
          adGroup.creatives[i].startDate = generalUtilsFactory.DateToString(adGroup.creatives[i].startDate);
        }
        if (adGroup.creatives[i].endDate) {
          adGroup.creatives[i].endDate = generalUtilsFactory.DateToString(adGroup.creatives[i].endDate);
        }
      }
    }
    const creativeSaveV2Request = {
      rotateEvenly: adGroup['rotateEvenly'],
      creatives: adGroup['creatives']
    };

    return await send$http($http, put_save_all_creatives_api, {
      params: {
        campaign_id: campaignId,
        adgroup_id: adGroup['id']
      },
      data: creativeSaveV2Request
    });
  };

  campaignManagerFactory.saveAdGroupBudget = function(adGroup, campaignId) {
    var d = $q.defer();

    $http({
      method: 'PUT',
      url: `/data/campaigns/${campaignId}/adgroups/${adGroup.id}/budget`,
      data: adGroup
    })
      .then(function({ data: updatedAdgroup }) {
        campaignManagerFactory.updateCampaign(updatedAdgroup);
        d.resolve();
      })
      .catch(function({ data }) {
        let errors = generalUtilsFactory.extractErrorsFromResponse(data);
        // this is the error message displayed to the user
        d.reject(errors);
      });

    return d.promise;
  };

  campaignManagerFactory.saveAdGroupProduct = function(adGroup, campaignId) {
    var d = $q.defer();

    $http({ method: 'PUT', url: `/data/campaigns/${campaignId}/adgroups/${adGroup.id}/product`, data: adGroup })
      .then(function({ data: updatedCampaign }) {
        if (
          campaignManagerFactory.selectedAdGroup &&
          adGroup.product !== campaignManagerFactory.selectedAdGroup.product
        ) {
          //clearing all other products of the adgroup
          var productKeys = [
            'onpremise',
            'onpremise_conquest',
            'onpremise_loyalty',
            'onpremise_awareness',
            'proximity_conquest',
            'proximity_loyalty',
            'proximity_awareness',
            'audience_location',
            'geoblock_loyalty',
            'campaign_retargeting',
            'geotargeting_national',
            'geotargeting_state',
            'geotargeting_city',
            'geotargeting_dma',
            'geotargeting_zipcode'
          ];

          if (campaignManagerFactory.selectedAdGroup.targeting !== undefined) {
            for (var pk = 0; pk < productKeys.length; pk++) {
              if (campaignManagerFactory.selectedAdGroup != productKeys[pk]) {
                angular.copy(
                  selfServeDataFactory.nullAdGroup.targeting[productKeys[pk]],
                  campaignManagerFactory.selectedAdGroup.targeting[productKeys[pk]]
                );
              }
            }
          }
        }
        campaignManagerFactory.updateCampaign(updatedCampaign);
        d.resolve();
      })
      .catch(function({ data }) {
        // this is the error message displayed to the user
        d.reject(data && data.message);
      });

    return d.promise;
  };

  campaignManagerFactory.saveAdGroup = function(adGroup, campaignId) {
    // get the argument object
    // make an object that can be send to the backend for saving complete adgroup
    // get the return object and update the object in cache
    var d = $q.defer();

    $http({ method: 'PUT', url: `/data/campaigns/${campaignId}/adgroups/${adGroup.id}`, data: adGroup })
      .then(function({ data: updatedCampaign }) {
        if (
          campaignManagerFactory.selectedAdGroup &&
          adGroup.product !== campaignManagerFactory.selectedAdGroup.product
        ) {
          //clearing all other products of the adgroup
          var productKeys = [
            'onpremise_conquest',
            'onpremise_loyalty',
            'onpremise_awareness',
            'proximity_conquest',
            'proximity_loyalty',
            'proximity_awareness',
            'audience_location',
            'geoblock_loyalty',
            'campaign_retargeting',
            'geotargeting_national',
            'geotargeting_state',
            'geotargeting_city',
            'geotargeting_dma',
            'geotargeting_zipcode'
          ];
          for (var pk = 0; pk < productKeys.length; pk++) {
            if (campaignManagerFactory.selectedAdGroup != productKeys[pk]) {
              angular.copy(
                selfServeDataFactory.nullAdGroup.targeting[productKeys[pk]],
                campaignManagerFactory.selectedAdGroup.targeting[productKeys[pk]]
              );
            }
          }
        }
        campaignManagerFactory.updateCampaign(updatedCampaign);
        d.resolve();
      })
      .catch(function({ data }) {
        let errors = generalUtilsFactory.extractErrorsFromResponse(data);
        // this is the error message displayed to the user
        d.reject(errors);
      });

    return d.promise;
  };

  campaignManagerFactory.createNewAdGroup = function(campaignId, size) {
    var d = $q.defer();

    var dataToSend = {
      creativeSize: size,
      name: 'New Ad Group'
    };

    $http({ method: 'POST', url: `/data/campaigns/${campaignId}/adgroups`, data: dataToSend })
      .then(function({ data: updatedCampaign }) {
        var adGroup = angular.copy(updatedCampaign.adGroups[0]);
        campaignManagerFactory.updateCampaign(updatedCampaign);
        d.resolve(adGroup);
      })
      .catch(function({ data }) {
        // TODO: handle error when saving
        d.reject(data && data.message);
      });
    return d.promise;
  };

  campaignManagerFactory.saveCampaign = function(campaign, confirmBudgetChangeToCampaignLevel) {
    var d = $q.defer();

    //convert to strings as certain browsers screw with the time when converting to json
    const campaignCopy = angular.copy(campaign);
    campaignCopy.confirmBudgetChangeToCampaignLevel = confirmBudgetChangeToCampaignLevel;

    $http({ method: 'PUT', url: `/data/campaigns/${campaignCopy.id}`, data: campaignCopy })
      .then(function({ data: updatedCampaign }) {
        campaignManagerFactory.updateCampaign(updatedCampaign);
        d.resolve({});
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };

  campaignManagerFactory.selectAdGroupWithId = function(campaignId, adGroupId) {
    window.campaign = campaignManagerFactory.selectFromCampaignsWithId(campaignId);
    if (!window.campaign) {
      return false;
    }

    campaignManagerFactory.selectedCampaign = window.campaign;
    campaignManagerFactory.selectedAdGroup = null;
    window.adGroup = campaignManagerFactory.selectFromAdGroupsWithId(window.campaign.adGroups, adGroupId);
    if (window.adGroup) {
      campaignManagerFactory.selectedAdGroup = window.adGroup;
      return true;
    }
    return true;
  };

  campaignManagerFactory.selectCampaign = function(campaign) {
    campaignManagerFactory.selectedCampaign = campaign;
    campaignManagerFactory.selectedAdGroup = null;
  };

  campaignManagerFactory.selectAdGroup = function(campaign, adGroup) {
    campaignManagerFactory.selectedCampaign = campaign;
    campaignManagerFactory.selectedAdGroup = adGroup;
    $state.go('campaigns.adgroup', { campaignId: campaign.id, adGroupId: adGroup.id });
  };

  campaignManagerFactory.selectFromAdGroupsWithId = function(adGroups, selectedAdGroupId) {
    if (typeof selectedAdGroupId === 'undefined' || typeof adGroups === 'undefined' || !adGroups.length) {
      return false;
    }

    // Type coercion is necessary here, function is sometimes called with selectedAdGroupId as a string value.
    for (var i = 0; i < adGroups.length; i++) {
      // If expanded adgroup with breakdowns.
      if (adGroups[i] instanceof Array && adGroups[i][0]['id'] == selectedAdGroupId) {
        return adGroups[i];
      }

      if (adGroups[i]['id'] == selectedAdGroupId) {
        return adGroups[i];
      }
    }
    return false;
  };

  campaignManagerFactory.deleteCreative = function(campaignId, adGroup, creativeId) {
    var d = $q.defer();

    $http({
      method: 'DELETE',
      url: `/data/campaigns/${campaignId}/adgroups/${adGroup.id}/creatives/${creativeId}`,
      data: {}
    })
      .then(function({ data: updatedCampaign }) {
        var creativeIndex = 0;
        while (adGroup.creatives[creativeIndex].id != creativeId) {
          creativeIndex++;
        }
        adGroup.creatives.splice(creativeIndex, 1);
        campaignManagerFactory.updateCampaign(updatedCampaign);
        d.resolve({});
      })
      .catch(function() {
        d.reject("Sorry but we're unable to delete this creative at this time. Please try again later.");
      });

    return d.promise;
  };

  campaignManagerFactory.deleteAdGroup = function(adGroup, campaignId) {
    var d = $q.defer();
    $http({ method: 'DELETE', url: `/data/campaigns/${campaignId}/adgroups/${adGroup.id}`, data: {} })
      .then(function({ data: updatedCampaign, status, headers, config }) {
        const adGroupIndex = campaignManagerFactory.getIndexOfAdGroup(adGroup);
        campaignManagerFactory.selectedCampaign.adGroups.splice(adGroupIndex, 1);
        campaignManagerFactory.updateCampaign(updatedCampaign);
        d.resolve({});
      })
      .catch(function({ data, status, headers, config }) {
        if (data) {
          d.reject(data.message);
        } else {
          d.reject('Action timed out. Please try again later.');
        }
      });

    return d.promise;
  };

  campaignManagerFactory.changeAdGroupStatus = function(adGroup, campaignId, statusString) {
    var d = $q.defer();
    const data = {
      status: statusString.toUpperCase()
    };
    $http({
      method: 'PUT',
      url: `/data/campaigns/${campaignId}/adgroups/${adGroup.id}/status`,
      data: data
    })
      .then(function({ data: updatedCampaign, status, headers, config }) {
        const adGroupIndex = campaignManagerFactory.getIndexOfAdGroup(adGroup);
        campaignManagerFactory.updateCampaign(updatedCampaign);
        d.resolve({});
      })
      .catch(function({ data, status, headers, config }) {
        if (data) {
          d.reject(data.message);
        } else {
          d.reject('Action timed out. Please try again later.');
        }
      });

    return d.promise;
  };

  campaignManagerFactory.changeCreativeStatus = function(creative, campaignId, statusString) {
    var d = $q.defer();
    var copyCreative = angular.copy(creative);
    copyCreative.status = statusString;
    $http({
      method: 'PUT',
      url: `/data/campaigns/${campaignId}/adgroups/${creative.placementId}/creatives/${creative.id}/status`,
      data: copyCreative
    })
      .then(function({ data: updatedCampaign, status, headers, config }) {
        campaignManagerFactory.updateCampaign(updatedCampaign);
        d.resolve({});
      })
      .catch(function({ data, status, headers, config }) {
        if (data) {
          d.reject(data.message);
        } else {
          d.reject('Action timed out. Please try again later.');
        }
      });

    return d.promise;
  };

  campaignManagerFactory.changeCampaignStatus = function(campaign, statusString, comment) {
    comment = typeof comment !== 'undefined' ? comment : '';
    var d = $q.defer();
    var data = {
      status: statusString.toUpperCase(),
      comment: comment
    };
    $http({ method: 'PUT', url: `/data/campaigns/${campaign.id}/status`, data: data })
      .then(function({ data: updatedCampaign }) {
        campaignManagerFactory.updateCampaign(updatedCampaign);
        campaignManagerFactory.updateFilteredCampaigns();
        if (campaignManagerFactory.channelCampaigns.length > 0) {
          var index = campaignManagerFactory.getIndexOfChannelCampaign(campaign.id);
          generalUtilsFactory.nestedUpdateInPlace(updatedCampaign, campaignManagerFactory.channelCampaigns[index]);
          campaignManagerFactory.updateFilteredChannelCampaigns();
        }
        d.resolve({});
      })
      .catch(function({ data }) {
        d.reject(data.message);
      });

    return d.promise;
  };

  campaignManagerFactory.cloneCampaign = function(campaign) {
    var d = $q.defer();
    $http({ method: 'POST', url: `/data/campaigns/${campaign.id}/clone`, data: {} })
      .then(function({ data: updatedCampaign, status, headers, config }) {
        if (campaign.hasOwnProperty('tenant_name')) {
          updatedCampaign.tenant_name = campaign.tenant_name;
        }
        campaignManagerFactory.updateCampaign(updatedCampaign);
        d.resolve();
      })
      .catch(function({ data }) {
        if (data) {
          d.reject(data.message);
        } else {
          d.reject('Sorry, your campaign could not be cloned. Please try again or contact support if issue persists.');
        }
      });

    return d.promise;
  };

  campaignManagerFactory.cloneAdGroup = function(adgroup, campaignId) {
    var d = $q.defer();
    $http({ method: 'POST', url: `/data/campaigns/${campaignId}/adgroups/${adgroup.id}/clone`, data: {} })
      .then(function({ data: updatedCampaign, status, headers, config }) {
        // If you clone older adgroup, the product might be something like "onpremise_conquest".
        // and then when init of adgroup_targeting_superform_controller, if there is targeting
        // it will not make a call to the backend and try to load the page. since the product is old,
        // you will not see the targeting page loaded.
        // https://xadinc.atlassian.net/browse/MPCB-373
        if (updatedCampaign.adGroups.length > 0 && updatedCampaign.adGroups[0].targeting) {
          updatedCampaign.adGroups[0].targeting = null;
        }

        campaignManagerFactory.updateCampaign(updatedCampaign);
        d.resolve();
      })
      .catch(function({ data }) {
        if (data) {
          d.reject(data.message);
        } else {
          d.reject('Action timed out. Please try again later.');
        }
      });
    return d.promise;
  };

  campaignManagerFactory.cloneCreative = function(creative, campaignId) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: `/data/campaigns/${campaignId}/adgroups/${creative.placementId}/creatives/${creative.id}/clone `,
      data: {}
    })
      .then(function({ data: updatedCampaign, status, headers, config }) {
        campaignManagerFactory.updateCampaign(updatedCampaign);
        d.resolve();
      })
      .catch(function({ data }) {
        if (data) {
          d.reject(data.message);
        } else {
          d.reject('Action timed out. Please try again later.');
        }
      });

    return d.promise;
  };

  campaignManagerFactory.deleteCampaign = function(campaign) {
    var d = $q.defer();
    $http({ method: 'DELETE', url: `/data/campaigns/${campaign.id}`, data: {} })
      .then(function({ data, status, headers, config }) {
        let index = campaignManagerFactory.getIndexOfCampaign(campaign.id);
        campaignManagerFactory.livecampaigns.splice(index, 1);
        campaignManagerFactory.updateFilteredCampaigns();
        if (campaignManagerFactory.channelCampaigns.length > 0) {
          index = campaignManagerFactory.getIndexOfChannelCampaign(campaign.id);
          campaignManagerFactory.channelCampaigns.splice(index, 1);
          campaignManagerFactory.updateFilteredChannelCampaigns();
        }
        d.resolve({});
      })
      .catch(function({ data, status, headers, config }) {
        d.reject(data.message);
      });

    return d.promise;
  };

  campaignManagerFactory.deleteCampaigns = function(campaignIds) {
    var d = $q.defer();
    $http({ method: 'POST', url: '/data/campaigns/deletions', data: { campaign_ids: campaignIds } })
      .then(function({ data, status, headers, config }) {
        var campaignIndexes = [];

        for (var i = 0; i < campaignIds.length; i++) {
          campaignIndexes.push(campaignManagerFactory.getIndexOfCampaign(campaignIds[i]));
        }

        campaignIndexes.sort().reverse();

        for (var i = 0; i < campaignIndexes.length; i++) {
          var campaignIndex = campaignIndexes[i];
          campaignManagerFactory.livecampaigns.splice(campaignIndex, 1);
        }
        campaignManagerFactory.updateFilteredCampaigns();

        d.resolve({});
      })
      .catch(function({ data, status, headers, config }) {
        d.reject('The campaigns you have selected could not be deleted!');
      });

    return d.promise;
  };

  campaignManagerFactory.createCampaign = function(obj) {
    var d = $q.defer();
    //create a creativeSizes array with all of the sizes selected

    const dataToSend = {
      name: obj.name,
      creativeSize: obj.adgroupSize,
      bidType: obj.bidType,
      users: obj.users,
      salesforceNumber: obj.salesforceNumber,
      client_io_name: obj.client_io_name,
      is_cbd: obj.isCbd,
      vendorIdentifier: obj.clientIoNumber,
      category: obj.category,
      billing_source_id: obj.billing_source_id,
      enableAutoreconcile: obj.enableAutoreconcile,
      dartBillingAccount: obj.dartAccount,
      budgetLevel: obj.budgetLevel,
      budget: obj.campaignBudget,
      budgetPadding: obj.campaignBudgetPadding,
      budgetType: obj.budgetType,
      freqCapValue: obj.freqCapValue,
      freqCapDuration: obj.freqCapDuration,
      salesEmail: obj.salesEmail,
      opsEmail: obj.opsEmail,
      accountManagerEmail: obj.accountManagerEmail
    };
    var url = '/data/campaigns';
    if (obj.isBulkUpload) {
      url = url + '?skip_adgroup=1';
    }

    $http({ method: 'POST', url: url, data: dataToSend })
      .then(function({ data: newCampaign, status, headers, config }) {
        campaignManagerFactory.updateCampaign(newCampaign);
        d.resolve(newCampaign);
      })
      .catch(function({ data, status, headers, config }) {
        if (typeof data === 'string') {
          d.reject(data);
        } else if (data.errors?.length) {
          const message = data.errors.map(error => error.message).join('\n');
          d.reject(message);
        } else {
          d.reject(data.message);
        }
      });
    return d.promise;
  };

  campaignManagerFactory.getIndexOfAdGroup = function(adGroup) {
    for (var i = 0; i < campaignManagerFactory.selectedCampaign.adGroups.length; i++) {
      if (campaignManagerFactory.selectedCampaign.adGroups[i].id == adGroup.id) {
        return i;
      }
    }
  };

  campaignManagerFactory.getIndexOfCampaign = function(campaignId) {
    for (var i = 0; i < campaignManagerFactory.livecampaigns.length; i++) {
      if (campaignManagerFactory.livecampaigns[i].id === campaignId) {
        return i;
      }
    }
    throw new Error(`Campaign ${campaignId} not found`);
  };

  campaignManagerFactory.getIndexOfChannelCampaign = function(campaignId) {
    for (var i = 0; i < campaignManagerFactory.channelCampaigns.length; i++) {
      if (campaignManagerFactory.channelCampaigns[i].id === campaignId) {
        return i;
      }
    }
    return -1;
  };

  // TODO: this probably belongs elsewhere but im in a hurry
  campaignManagerFactory.getPacingAsPercentage = function(adGroup) {
    if (adGroup.pacing) {
      return Math.round(10000 * adGroup.pacing) / 100;
    }
    return 0;
  };

  /**
   * Adds @param num campaign tiles into the dashboard. This is used by infinite scroll.
   */
  campaignManagerFactory.loadMoreCampaigns = function(num) {
    var numCurrentlyShown = campaignManagerFactory.liveCampaignsToShow.length;
    if (numCurrentlyShown >= campaignManagerFactory.filteredCampaigns.length) {
      // nothing left to load
      return;
    }
    if (typeof num === 'undefined') {
      if (numCurrentlyShown == 0) {
        var numTilesThatFitInWindow =
          Math.floor($('#main-content-frame').width() / 270) * Math.floor($('#main-content-frame').height() / 231);
        num = numTilesThatFitInWindow * 2;
      } else {
        // default
        num = 5;
      }
    }
    const newCampaigns = campaignManagerFactory.filteredCampaigns.slice(numCurrentlyShown, numCurrentlyShown + num);
    campaignManagerFactory.liveCampaignsToShow = campaignManagerFactory.liveCampaignsToShow.concat(newCampaigns);
  };

  campaignManagerFactory.downloadAdGroupLocationReport = async (adgroupId, selectedDurationRange) => {
    var params = {
      adgroup_id: adgroupId,
      start_date: selectedDurationRange.start_date,
      end_date: selectedDurationRange.end_date
    };

    const response = await $http({
      method: 'GET',
      url: '/data/report/export/adgroup',
      params: params,
      responseType: 'arraybuffer'
    });
    return response.data;
  };

  campaignManagerFactory.downloadCampaignLocationReport = async (campaignId, selectedDurationRange) => {
    var params = {
      campaign_id: campaignId,
      start_date: selectedDurationRange.start_date,
      end_date: selectedDurationRange.end_date
    };

    const response = await $http({
      method: 'GET',
      url: '/data/report/export/campaign',
      params: params,
      responseType: 'arraybuffer'
    });
    return response.data;
  };

  campaignManagerFactory.downloadLocationFilters = async (campaignId, adgroupId) => {
    const url = `/data/campaigns/${campaignId}/adgroups/${adgroupId}/location_filters/download`;
    const response = await $http({ method: 'GET', url: url, responseType: 'arraybuffer' });
    return response.data;
  };

  campaignManagerFactory.getAdGroupAvailsData = debounce2(1000, function(
    campaignId,
    adgroup,
    checksum,
    beginCallback,
    successCallback,
    errorCallback
  ) {
    const d = $q.defer();

    beginCallback();

    $http({
      method: 'POST',
      url: `/data/campaigns/${campaignId}/adgroups/${adgroup.id}/avails?checksum=${checksum}`,
      data: adgroup
    })
      .then(function({ data, status, headers, config }) {
        successCallback(data);
        d.resolve(data);
      })
      .catch(function({ data, status, headers, config }) {
        errorCallback(data);
        d.reject(data);
      });
    return d.promise;
  });

  /**
   * @param {number} campaignId
   * @param {number} adgroupId
   * @returns {Promise<CreativeLPLocationResponse>}
   */
  campaignManagerFactory.getLpLocation = function(campaignId, adgroupId) {
    var d = $q.defer();
    $http({
      method: 'GET',
      url: `/data/campaigns/${campaignId}/adgroups/${adgroupId}/creatives/landing_page_location`
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    // @ts-ignore
    return d.promise;
  };

  campaignManagerFactory.getCampaignDomains = function(campaignId, exclude) {
    var d = $q.defer();

    $http({ method: 'GET', url: `/data/campaigns/${campaignId}/domains`, params: { exclude: exclude } })
      .then(function({ data, status, headers, config }) {
        d.resolve(data.domains);
      })
      .catch(function({ data, status, headers, config }) {
        d.reject(data.message);
      });
    return d.promise;
  };

  campaignManagerFactory.getCampaignBlockedAppNames = function(campaignId) {
    var d = $q.defer();

    $http({ method: 'GET', url: `/data/campaigns/${campaignId}/blocked_app_names` })
      .then(function({ data, status, headers, config }) {
        d.resolve(data.blocked_app_names);
      })
      .catch(function({ data, status, headers, config }) {
        d.reject(data.message);
      });
    return d.promise;
  };

  campaignManagerFactory.getCampaignWhitelistAppNames = function(campaignId) {
    var d = $q.defer();

    $http({ method: 'GET', url: `/data/campaigns/${campaignId}/whitelist_app_names` })
      .then(function({ data, status, headers, config }) {
        d.resolve(data.whitelist_app_names);
      })
      .catch(function({ data, status, headers, config }) {
        d.reject(data.message);
      });
    return d.promise;
  };

  campaignManagerFactory.getChannelCampaigns = function() {
    var d = $q.defer();

    $http({ method: 'GET', url: '/data/campaigns/channel_campaigns' })
      .then(function({ data, status, headers, config }) {
        campaignManagerFactory.channelCampaigns = data.channel_campaigns;
        campaignManagerFactory.updateFilteredChannelCampaigns('Submitted');
        d.resolve(data);
      })
      .catch(function({ data, status, headers, config }) {
        d.reject(data.message);
      });
    return d.promise;
  };

  campaignManagerFactory.downloadCampaignPDF = function(campaignId) {
    var d = $q.defer();
    $http({ method: 'GET', url: `/data/campaigns/${campaignId}/pdf`, responseType: 'arraybuffer' })
      .then(function({ data, status, headers, config }) {
        generalUtilsFactory.downloadPDF(data, 'Download_PDF_Campaign:' + campaignId);
        d.resolve({});
      })
      .catch(function({ data, status, headers, config }) {
        d.reject(data.message);
      });
    return d.promise;
  };

  campaignManagerFactory.downloadCampaignSpreadsheet = function(campaignIds) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: '/data/campaigns/spreadsheet',
      responseType: 'arraybuffer',
      data: { campaign_ids: campaignIds }
    })
      .then(function({ data, status, headers, config }) {
        generalUtilsFactory.downloadSpreadsheet(data, 'campaign-summary');
        d.resolve({});
      })
      .catch(function({ data, status, headers, config }) {
        d.reject(data.message);
      });

    return d.promise;
  };

  campaignManagerFactory.saveDeviceTypes = async function(campaignId, adgroupId, data) {
    const params = {
      campaign_id: campaignId,
      adgroup_id: adgroupId
    };

    return await send$http($http, put_update_ad_group_device_type_api, {
      params,
      data
    });
  };

  campaignManagerFactory.getCreativeVideoTranscodeStatuses = function({ adgroupId, creativeIds }) {
    const params = {
      adgroup_id: adgroupId,
      creative_ids: creativeIds
    };

    return send$http($http, get_creative_video_transcoder_statuses_api, { params });
  };

  campaignManagerFactory.getHTML5JobStatuses = function({ adgroupId, creativeIds }) {
    const params = {
      adgroup_id: adgroupId,
      creative_ids: creativeIds
    };

    return send$http($http, get_creative_html5_statuses_api, { params });
  };

  return campaignManagerFactory;
}

export function computeCampaignBudget(campaign) {
  if (!campaign) {
    return;
  }
  if (campaign.budgetAtCampaignLevel) {
    campaign.totalBudget = campaign.spends_budget;
  } else {
    let sumAdgroupBudgets = 0;
    let adGroupWithNullTimeframe = false; // Flag to track if any ad group has null timeframe

    if (campaign.hasOwnProperty('adGroups')) {
      campaign.adGroups.forEach(adGroup => {
        if (adGroup.hasOwnProperty('totalBudget')) {
          sumAdgroupBudgets += adGroup.totalBudget;
        }
        if (adGroup.totalBudget === null) {
          adGroupWithNullTimeframe = true;
        }
      });

      // If any ad group has null timeframe, set campaign totalBudget to null
      if (adGroupWithNullTimeframe) {
        campaign.totalBudget = null;
        return;
      }
    }

    campaign.totalBudget = sumAdgroupBudgets;
  }
}
