import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const ErrorSquaredIcon = createSvgIcon(
  <path
    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
    fill="#BA1A1A"
  />,
  'GtWarningSquared'
);

export const ErrorTriangleIcon = createSvgIcon(
  <path d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z" fill="#FFA500" />,
  'GtWarningTriangle'
);

export const ErrorTriangleRedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="red"
    strokeWidth="2"
    strokeLinecap="square"
    strokeLinejoin="inherit"
    className="feather feather-alert-triangle"
  >
    <path d="M10.29 3.86l-8.66 15a1 1 0 0 0 .87 1.5h17.42a1 1 0 0 0 .87-1.5l-8.66-15a1 1 0 0 0-1.74 0z" />
    <line x1="11" y1="9" x2="11" y2="13" />
    <line x1="11" y1="17" x2="11" y2="17" />
  </svg>
);
