import { useState, useCallback, useEffect } from 'react';
import { universalAlert, $rootScope } from '../services';
import { safeApply } from '../../factories/general_utils';

/**
 * Result of a promise awaiting.
 * It can be either `success: true` with result in `result` field
 * or `success: false` with error in `error` field.
 * It is used in `useSafeAsyncAction` hook to safely complete a promise that can fail.
 */
export type WaitResult<T> =
  | { success: true; result: T; error?: undefined }
  | { success: false; result?: undefined; error: unknown };

/**
 * A function for safe waiting a promise in `useSafeAsyncAction` hook.
 * The returning promise is never failing, and returns the result in a wrapper.
 */
export type WaitFunction =
  /**
   * @param promise A promise to wait
   */
  <T>(promise: Promise<T>) => Promise<WaitResult<T>>;

/**
 * A React hook that simplifies working with async actions.
 * It exposes a tuple of `loading` and a wait function.
 * The wait function allows to wait promise while updating the loading status.
 * If the promise results in an error, the error will be displayed with `unversalLogin`.
 * @returns a tuple of loading status as boolean and a wait function; @see {@link WaitFunction}
 */
export function useSafeAsyncAction(): [boolean, WaitFunction, boolean, any] {
  const [taskCount, updateTaskCount] = useState(0);
  const [errorDetails, setErrorDetails] = useState<{ message?: string; details: string[] }>();
  const [error, setError] = useState(false);
  const dispatch = useCallback<WaitFunction>(async promise => {
    updateTaskCount(c => c + 1);
    try {
      return { success: true, result: await promise };
    } catch (err) {
      let errorResponse: { message?: string; details: string[] } = makeDetailsFromRestError(err?.data);
      setErrorDetails(errorResponse);
      setError(true);
      console.error(err);
      return { success: false, error: err };
    } finally {
      updateTaskCount(c => c - 1);
    }
  }, []);
  return [taskCount > 0, dispatch, error, errorDetails];
}

export function useAsyncInit<T, D = T>(
  initFn: () => Promise<T>,
  defaultValue: D,
  deps?: React.DependencyList
): [boolean, T | D] {
  const [loading, waitAsync] = useSafeAsyncAction();
  const [[started, result], setResult] = useState<[boolean, T | D, typeof deps]>([false, defaultValue, undefined]);

  useEffect(() => {
    const thisDeps = deps;
    setResult([true, defaultValue, thisDeps]);
    waitAsync(
      initFn().then(result =>
        // do not update the result if the dependencies do not match
        // that can happen when result from previous request arrived after changing dependencies
        setResult(prev => (equalDeps(thisDeps, prev[2]) ? [true, result, thisDeps] : prev))
      )
    );
    return () => setResult([false, defaultValue, undefined]);
  }, deps);

  return [loading || !started, result];
}

function equalDeps(deps1: React.DependencyList | undefined, deps2: React.DependencyList | undefined) {
  if (deps1 == deps2) return true;
  if (!deps1) return !deps2 || deps2.length === 0;
  if (!deps2) return deps1.length === 0;
  if (deps1.length !== deps2.length) return false;
  for (let i = 0; i < deps1.length; i++) {
    if (!Object.is(deps1[i], deps2[i])) return false;
  }
  return true;
}

/**
 * This function will handle the rest error.
 * When there are details (i.e. errors) we will just show message in the universal alert
 *
 */
export function makeDetailsFromRestError(errorResponse: {
  message?: string;
  errors?: Array<{ field?: string; message: string }>;
}): { message?: string; details: string[] } {
  if (!errorResponse.errors?.length) {
    return { message: errorResponse?.message, details: [] };
  }
  const errors = errorResponse.errors.map(error => error.message);
  return { details: errors };
}
