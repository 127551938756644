import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import { AudienceLibrary } from './AudienceLibrary';
import { AccordionItem } from './AccordionItem';
import { SelectableTaxonomyItem } from './interfaces';
import { Audience, AudienceCategory } from '../../../../api/http/data.generated';
import { AudienceTaxonomyResponseType } from '../../../../api/http/audience';
import { SearchIcon, WarningIcon } from '../../../../components/reactComponents/Icons';

interface AudienceSearchPanelProps {
  audienceTaxonomyTreeData: AudienceTaxonomyResponseType['taxonomy'];
  includeItemDescription: boolean;
  isLoading: boolean;
  searchResults: { audience: Audience; category: AudienceCategory }[];
  searchTerm: string;
  selectedAudienceIds: Set<string>;
  selectedAudiences: Audience[];
  showError: boolean;
  showLibrary: boolean;
  onCheckboxChange: (item: SelectableTaxonomyItem) => void;
  onRetrySearch: () => void;
}

export function AudienceSearchPanel({
  audienceTaxonomyTreeData,
  includeItemDescription,
  isLoading,
  searchResults,
  searchTerm,
  selectedAudienceIds,
  selectedAudiences,
  showError,
  showLibrary,
  onCheckboxChange,
  onRetrySearch
}: AudienceSearchPanelProps) {
  if (isLoading) {
    return (
      <div className="audience-search-result">
        <div className="audience-search-result__loading">
          <div className="audience-search-result__loading-block">
            <Skeleton animation="wave" className="audience-search-result__skeleton" />
            <Skeleton animation="wave" className="audience-search-result__skeleton" />
            <Skeleton animation="wave" className="audience-search-result__skeleton" />
          </div>
          <div className="audience-search-result__loading-block-shift">
            <Skeleton animation="wave" className="audience-search-result__skeleton" />
          </div>
          <div className="audience-search-result__loading-block-double-shift">
            <Skeleton animation="wave" className="audience-search-result__skeleton" />
            <Skeleton animation="wave" className="audience-search-result__skeleton" />
          </div>
          <Skeleton animation="wave" className="audience-search-result__skeleton" />
          <Skeleton animation="wave" className="audience-search-result__skeleton" />
          <Skeleton animation="wave" className="audience-search-result__skeleton" />
        </div>
      </div>
    );
  }

  if (showLibrary) {
    return (
      <AudienceLibrary
        audienceTaxonomyTreeData={audienceTaxonomyTreeData}
        includeItemDescription={includeItemDescription}
        selectedAudienceIds={selectedAudienceIds}
        selectedAudiences={selectedAudiences}
        showLibrary={showLibrary}
        onCheckboxChange={onCheckboxChange}
      />
    );
  }

  return (
    <>
      {searchTerm != undefined && searchTerm.length < 2 ? null : (
        <div className="audience-search-result">
          <>
            {searchResults.length > 0 ? (
              <div data-testid="audience-search-result__accordion" className="audience-search-result__accordion">
                {searchResults.map((result, index) => (
                  <AccordionItem
                    key={index}
                    item={result?.audience?.id ? result.audience : result.category}
                    includeItemDescription={includeItemDescription}
                    selectedAudiences={selectedAudiences}
                    selectedAudienceIds={selectedAudienceIds}
                    showLibrary={showLibrary}
                    onCheckboxChange={onCheckboxChange}
                  />
                ))}
              </div>
            ) : (
              <div className="audience-search-result__empty">
                <div className="audience-search-result__empty-icon">
                  {showError ? (
                    <WarningIcon className="warning-icon" viewBox="0 0 56 56" />
                  ) : (
                    <SearchIcon viewBox="0 0 47 41" />
                  )}
                </div>
                <p className="audience-search-result__empty-description">
                  {showError ? (
                    <>
                      Sorry, there was a problem loading search results.{' '}
                      <span className="audience-search-result__empty-retry" onClick={onRetrySearch}>
                        Please try again.
                      </span>
                    </>
                  ) : (
                    "Sorry, we couldn't find any results that match your text"
                  )}
                </p>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
}
