export const creativeAssetTypes = ['IMAGE', 'SCRIPT'];
export const creativeAssetStatus = ['Pending', 'Active', 'Paused', 'Expired', 'Unlinked'];
export const creativeAssetsAdSizes = [
  '160x600',
  '300x50',
  '300x250',
  '300x600',
  '320x50',
  '320x480',
  '480x320',
  '600x300',
  '640x160',
  '640x213',
  '640x320',
  '640x360',
  '640x960',
  '720x240',
  '728x90',
  '768x1024',
  '1024x768'
];
