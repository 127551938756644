export function createXadTableConfigForCreativeRepo(editFunction, previewFunction, deleteFunction) {
  const actionColumns = [
    // TODO: V2 we would have a proper view modal
    /*{
      name: '',
      type: 'button',
      components: [
        {
          form: {
            type: 'button',
            text: '',
            class: 'table-btn gt-preview-icon'
          },
          trigger: editFunction
        }
      ],
      sortable: false
    },*/
    {
      name: 'Actions',
      type: 'button',
      components: [
        {
          form: {
            type: 'button',
            title: 'Edit',
            text: '',
            class: 'table-btn gt-edit-icon'
          },
          trigger: previewFunction
        }
      ],
      sortable: false
    },
    {
      name: '',
      type: 'button',
      components: [
        {
          form: {
            type: 'button',
            title: 'Delete',
            text: '',
            class: 'table-btn gt-delete-icon'
          },
          trigger: deleteFunction
        }
      ],
      sortable: false
    }
  ];

  const header = [
    {
      name: 'Preview',
      hidden: false,
      field: 'IMAGE',
      components: [
        {
          field: 'preview_url',
          image: {}
        }
      ],
      sortable: false
    },
    {
      name: 'Name',
      field: 'asset_name',
      components: [
        {
          field: 'asset_name',
          exportField: 'asset_name'
        }
      ],
      sortable: true
    },
    {
      name: 'Type',
      field: 'asset_type',
      components: [
        {
          field: 'asset_type',
          exportField: 'asset_type'
        }
      ],
      sortable: true
    },
    {
      name: 'Extension',
      field: 'file_extension',
      components: [
        {
          field: 'file_extension',
          exportField: 'file_extension'
        }
      ],
      sortable: true
    },
    {
      name: 'Status',
      field: 'status',
      components: [
        {
          field: 'status',
          exportField: 'status',
          getCSSClass: getStatusCSSClass
        }
      ],
      sortable: true
    },
    {
      name: 'Size',
      field: 'size',
      components: [
        {
          field: 'size',
          exportField: 'size'
        }
      ],
      sortable: true
    },
    {
      name: 'Created by',
      field: 'created_by',
      components: [
        {
          field: 'created_by',
          exportField: 'created_by'
        }
      ],
      sortable: true
    },
    {
      name: 'Created on',
      field: 'created_date',
      components: [
        {
          field: 'created_date',
          exportField: 'created_date',
          filter: 'date'
        }
      ],
      sortable: true
    },
    ...actionColumns
  ];

  const tableConfig = {
    tableData: [],
    header,
    actionColumns,
    tableName: 'creativeRepositoryList',
    emptyTableMessage: 'There are no creative assets in your repository.',
    extra: [],
    hideHeader: false,
    defaultOrderField: null,
    draggable: false,
    filter: '',
    customFilter: null,
    theme: 'primary',
    limit: 0,
    expandOrCollapseRowTrigger: null,
    newDataTrigger: null
  };

  return tableConfig;
}

function getStatusCSSClass(status) {
  const rowStatus = status.toLowerCase();
  if (['active', 'pending', 'paused', 'expired', 'unlinked'].includes(rowStatus)) {
    return `status ${rowStatus}-status`;
  }
  return '';
}
