import * as React from 'react';
import { IconButton } from '../../../components/reactComponents/Button/IconButton';
import { PopupMenu, PopupMenuOption } from '../../../components/reactComponents/Menu/PopupMenu';
import { SettingIcon } from '../../../components/reactComponents/Icons';
import { Report, ReportStatus } from '../model/report_scheduler_model';

interface ReportMenuItemProps {
  onDelete: (item: Report) => void;
  onResume: (report: Report) => void;
  onPause: (report: Report) => void;
  onEdit: (item: Report) => void;
  report: Report;
}

const editOption = { title: 'Edit' };
const resumeOption = { title: 'Resume' };
const pauseOption = { title: 'Pause' };
const deleteOption = { title: 'Delete', danger: true };

function getMenuOption(reportType: Report['status']): PopupMenuOption[] {
  if (reportType === ReportStatus.ACTIVE || reportType === ReportStatus.PENDING) {
    return [editOption, pauseOption, deleteOption];
  } else if (reportType === ReportStatus.PAUSED) {
    return [editOption, resumeOption, deleteOption];
  } else if (reportType === ReportStatus.EXPIRED) {
    return [editOption, deleteOption];
  }

  return [];
}
export function ReportItemPopupMenu(props: ReportMenuItemProps) {
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);

  const options = getMenuOption(props.report['status']);
  const openMenu = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSelect = (option: PopupMenuOption) => {
    if (option === resumeOption) {
      props.onResume(props.report);
    } else if (option === pauseOption) {
      props.onPause(props.report);
    } else if (option === editOption) {
      props.onEdit(props.report);
    } else if (option === deleteOption) {
      props.onDelete(props.report);
    }
  };

  return (
    <>
      <IconButton onClick={openMenu} ref={anchorRef}>
        <SettingIcon color="primary" fontSize="24" />
      </IconButton>
      <PopupMenu anchor={open ? anchorRef.current : null} options={options} onClose={onClose} onSelect={onSelect} />
    </>
  );
}
