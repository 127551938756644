import * as React from 'react';
import { TextField, Checkbox } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderOptionState } from '@material-ui/lab';
import { styled } from '../../../utils/react/styled';
import { Search as SearchIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

export interface MultiselctDropdownProps<T> {
  options: T[];
  value: T[];
  onChange?(value: T[]): void;
  placeholder?: string;
  label: string;
  getOptionText?(option: T): string;
  errorMessage?: string;
  className?: string;
  compareOption?: (option: T, value: T) => boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onInputBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const defaultGetOptionText = <T extends unknown>(option: T) => String(option?.name);

const TextFieldTextWrap = styled(TextField)({
  wordBreak: 'break-word'
});

const StyledAutoComplete = withStyles({
  root: {
    '& .MuiFormLabel-root.Mui-error': {
      color: 'var(--color-on-primary)'
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'var(--color-primary)',
      border: '1px solid var(--color-outline) !important',
      backgroundColor: 'var(--color-background) !important'
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'var(--color-primary)',
      fontFamily: 'var(--font-family-regular)',
      fontSize: 'var(--font-size-regular)',
      fontWeight: 'var(--font-weight-bold)',
      lineHeight: 'var(--line-height)'
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: 'var(--color-on-primary)',
      backgroundColor: 'transparent'
    },
    '& .MuiInputBase-root.Mui-error': {
      border: '1px solid var(--color-error)',
      background: 'var(--color-error-container)'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: 'var(--color-error)',
      fontFamily: 'var(--font-family-regular)',
      fontSize: 'var(--font-size-small)',
      fontWeight: 'var(--font-weight-regular)',
      lineHeight: 'var(--line-height)',
      marginTop: '4px'
    }
  },

  input: {
    padding: '0px 72px 0px 0px !important',
    height: '18px',
    minWidth: '70px !important'
  },

  inputRoot: {
    marginTop: '0px !important',
    overflow: 'auto',
    alignContent: 'baseline !important',
    color: 'var(--color-on-primary)',
    fontFamily: 'var(--font-family-regular)',
    fontSize: 'var(--font-size-regular)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--line-height)',
    borderRadius: 'var(--border-radius)',
    border: '1px solid var(--color-outline)',
    minHeight: '20px',
    maxHeight: '220px',
    padding: '12px !important',

    '&:hover': {
      border: '1px solid var(--color-link)'
    },
    '&:focus-within': {
      border: '1px solid var(--color-primary)',
      backgroundColor: 'var(--color-primary-container)'
    },
    '& .MuiAutocomplete-endAdornment': {
      display: 'flex',
      gap: '8px'
    }
  },
  clearIndicator: {
    color: 'var(--color-on-primary)'
  },
  popupIndicator: {
    marginRight: '10px',
    color: 'var(--color-on-primary)'
  }
})(Autocomplete as any) as any;
(StyledAutoComplete as any).displayName = 'StyledAutoComplete';

const StyledSearchIcon = styled(SearchIcon)({
  color: 'gray'
});

const StartEndormentDiv = styled('div')({
  display: 'flex',
  maxWidth: '712px',
  '&>span': {
    paddingTop: 0
  }
});

const CheckBoxContainer = styled('div')({
  '& input[type="checkbox"]': {
    backgroundColor: 'var(--color-background)'
  },

  '& input[type="checkbox"]:checked': {
    border: '2px solid var(--color-outline) !important'
  },
  '& input[type="checkbox"]:hover': {
    border: '2px solid var(--color-outline) !important'
  }
});

export function MultiselectDropdown<T>({
  options,
  onChange,
  value,
  getOptionText = defaultGetOptionText,
  compareOption,
  errorMessage,
  label,
  placeholder,
  className,
  fullWidth,
  disabled,
  onInputChange,
  onInputBlur
}: MultiselctDropdownProps<T>) {
  const passOnInputChange = onInputChange ? { onChange: onInputChange } : {};
  const passOnInputBlur = onInputBlur ? { onBlur: onInputBlur } : {};

  const renderOption = React.useCallback(
    (option: T, params: AutocompleteRenderOptionState) => (
      <>
        <CheckBoxContainer className="field multiselect-checkbox ">
          <input type="checkbox" checked={params.selected} />
          {getOptionText(option)}
        </CheckBoxContainer>
      </>
    ),
    [getOptionText]
  );

  const renderTags = React.useCallback(
    (selected: T[]) => selected.map(option => getOptionText(option)).join(', '), // callback
    [getOptionText] // dependencies
  );

  const onChangeInner = React.useMemo(
    () => (onChange ? (event: unknown, value: T[]) => onChange(value) : undefined), // callback factory
    [onChange] // dependencies
  );

  return (
    <StyledAutoComplete
      id="mui-autocomplete"
      className={value?.length ? 'dropdown-selected' : ' '}
      multiple={true}
      options={options}
      value={value}
      onChange={onChangeInner}
      disablePortal={true}
      disableCloseOnSelect
      getOptionSelected={compareOption}
      renderOption={renderOption}
      renderTags={renderTags}
      disabled={disabled}
      getOptionLabel={getOptionText}
      renderInput={params => (
        <TextFieldTextWrap
          {...params}
          {...passOnInputChange}
          {...passOnInputBlur}
          error={Boolean(errorMessage)}
          helperText={!params.inputProps['aria-controls'] ? errorMessage : null}
          label={label}
          placeholder={placeholder}
          variant="standard"
          margin="normal"
          className={className}
          fullWidth={fullWidth}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            startAdornment: (
              <StartEndormentDiv>
                <span>{params.InputProps.startAdornment}</span>
              </StartEndormentDiv>
            )
          }}
        />
      )}
    />
  );
}
