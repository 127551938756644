AccountPosController.$inject = ['$scope', '$stateParams', 'modalFactory', '$http', '$document'];

/**
 * @param {*} $scope
 * @param {import('@uirouter/angularjs').StateParams} $stateParams
 * @param {*} modalFactory
 * @param {ng.IHttpService} $http
 */
export function AccountPosController($scope, $stateParams, modalFactory, $http, $document) {
  $scope.posClients = [];
  $scope.posLoading = false;
  $scope.errorMessage = '';

  init();

  async function getPosConnections() {
    try {
      const response = await $http({
        method: 'GET',
        url: `/data/check-growth/connections/${$stateParams.selectedAccountId}`,
        timeout: 30000
      });
      if (response.status === 200) {
        $scope.posClients = response.data;
        $scope.$apply();
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function init() {
    $scope.posLoading = true;
    await getPosConnections();
    $scope.posLoading = false;
    initOnConnect();
    $scope.$apply();
  }

  function initOnConnect() {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(window.location.search);
    const authSuccess = queryParams.get('authSuccess');
    const posId = queryParams.get('pos');
    if (authSuccess && posId) {
      queryParams.delete('authSuccess');
      queryParams.delete('pos');
      url.search = queryParams.toString();
      history.pushState(null, '', url.toString());
      const pos = $scope.posClients.find(client => client.id === posId);
      if (pos) {
        $scope.showConnectionModal(pos);
      }
    }
  }

  async function connectPos(pos) {
    if (pos.oAuthUrl) {
      const oAuthObj = new URL(pos.oAuthUrl);
      const queryParams = new URLSearchParams(oAuthObj.search);
      queryParams.set('callbackUrl', window.location.origin + window.location.pathname);
      oAuthObj.search = queryParams.toString();
      window.location.href = oAuthObj.toString();
    } else {
      pos.connecting = true;
      $scope.errorMessage = '';
      try {
        const response = await $http({
          method: 'POST',
          url: '/data/check-growth/connect',
          data: {
            posName: pos.id,
            accountId: $stateParams.selectedAccountId
          },
          timeout: 30000
        });
        if (response.status === 200) {
          await getPosConnections();
        }
      } catch (err) {
        console.error(err);
        const errorMessage = err?.data?.message;
        $scope.errorMessage = errorMessage
          ? errorMessage.substring(0, 300)
          : 'We could not authenticate your credentials with the partner. Please try again.';
      } finally {
        pos.connecting = false;
        $scope.showConnectionModal(pos, $scope.errorMessage);
      }
    }
  }

  $scope.showConnectionModal = (pos, errorMessage) => {
    const modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal_pos_connected.html',
      controller: [
        '$scope',
        '$uibModalInstance',
        ($scope, $uibModalInstance) => {
          $scope.posName = pos.name;
          $scope.errorMessage = errorMessage;
          $scope.continue = () => {
            $uibModalInstance.close();
          };
          $scope.tryAgain = () => {
            $uibModalInstance.close();
            connectPos(pos);
          };
        }
      ],
      size: 'medium-v2'
    };
    modalFactory.createModal(modalOptions);
  };

  $scope.toggleDisconnectMenu = pos => {
    pos.disconnectMenuOpen = !pos.disconnectMenuOpen;
  };

  $scope.connectPos = connectPos;

  $scope.disconnectPos = async pos => {
    try {
      pos.disconnectMenuOpen = false;
      pos.disconnecting = true;
      const response = await $http({
        method: 'POST',
        url: '/data/check-growth/disconnect',
        data: {
          accountId: $stateParams.selectedAccountId,
          posName: pos.id
        },
        timeout: 30000
      });
      if (response.status === 200) {
        await getPosConnections();
      }
    } catch (err) {
      console.error(err);
    } finally {
      pos.disconnecting = false;
      $scope.$apply();
    }
  };

  $scope.isOtherPosConnecting = pos => {
    return !!$scope.posClients.find(p => p.connecting && p.name !== pos.name);
  };

  const handleClickOutsideDisconnect = event => {
    const clickedElement = event.target;

    $scope.posClients.forEach(pos => {
      const element = document.querySelector('.pos-system.' + pos.id);
      const disconnectContainer = element?.querySelector('.pos__disconnect-item-container:not(.ng-hide)');
      const disconnectMenuTrigger = element?.querySelector('.three-dots');

      if (
        pos.disconnectMenuOpen &&
        !clickedElement.isEqualNode(disconnectMenuTrigger) &&
        !disconnectContainer?.contains(clickedElement)
      ) {
        pos.disconnectMenuOpen = false;
      }
    });
    $scope.$apply();
  };

  $document.on('click', handleClickOutsideDisconnect);

  $scope.$on('$destroy', () => {
    $document.off('click', handleClickOutsideDisconnect);
  });
}
