import { replaceAll } from '../../utils/string';

exportDataController.$inject = [
  '$scope',
  '$uibModalInstance',
  'data',
  'handlers',
  'companyAndAccountFactory',
  'campaignManagerFactory',
  'reportFactory',
  'dataExportFactory',
  'FileSaver',
  'Blob',
  '$q',
  'changeLogFactory',
  'tenantFactory',
  'featureFlagFactory',
  'generalUtilsFactory'
];

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 * @param {import('../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {ng.IQService} $q
 * @param {import('../../factories/types').GeneralUtilsService} generalUtilsFactory
 */
export function exportDataController(
  $scope,
  $uibModalInstance,
  data,
  handlers,
  companyAndAccountFactory,
  campaignManagerFactory,
  reportFactory,
  dataExportFactory,
  FileSaver,
  Blob,
  $q,
  changeLogFactory,
  tenantFactory,
  featureFlagFactory,
  generalUtilsFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;

  $scope.campaign = data.campaign;
  $scope.adgroup = data.adgroup;
  $scope.tableLevel = data.tableLevel;
  $scope.reportName = data.reportName;
  $scope.selectedDurationRange = data.selectedDurationRange;
  $scope.selectedCategory = data.selectedCategory;
  $scope.showEndClientReportExport =
    !$scope.campaign.is_cpv && featureFlagFactory.isFeatureEnabled('END_CLIENT_REPORTING');

  const endClientReportKeys = [
    'adgroup_id',
    'adgroup_name',
    'date',
    'spends_spent',
    'impression',
    'click',
    'visits',
    'reach',
    'secondary_actions',
    'website',
    'directions',
    'click_to_call',
    'more_info',
    'coupon',
    'video_start',
    'video_q1',
    'video_q2',
    'video_q3',
    'video_complete',
    'vcr'
  ];

  var campaignStartDate = new Date(replaceAll(data.campaign.timeframe.start, '-', '/') + ' 07:00:00');

  // determine whether or not location reporting should be shown (feature locking is already done in jinja template)
  if (tenantFactory.tenant.type == 1) {
    // self serve tenant
    $scope.showLocationReporting = campaignStartDate >= new Date('2018-08-01');
  } else {
    $scope.showLocationReporting = campaignStartDate >= new Date('2017-11-01');
  }

  var hideReports = ['demographics', 'visitation', 'brand', 'category', 'os', 'device'];
  $scope.showReportData = $scope.reportName && hideReports.indexOf($scope.reportName) === -1;

  var showChangeLog = ['campaigns', 'placements'];
  $scope.showUserChangeLog = showChangeLog.indexOf($scope.tableLevel) > -1;

  $scope.downloadTableDataFn = handlers.downloadTableDataFn;
  $scope.progress = {
    locationReporting: false,
    tableData: false,
    reportData: false,
    userChangeLog: false,
    endClientReport: false
  };

  $scope.errorMsg = {
    locationReporting: '',
    tableData: '',
    reportData: '',
    userChangeLog: '',
    endClientReport: ''
  };

  $scope.downloadTableData = function() {
    $scope.downloadTableDataFn();
  };

  $scope.downloadLocationReport = async () => {
    $scope.errorMsg.locationReporting = '';
    $scope.progress.locationReporting = true;

    try {
      let filename;
      let locationReportinData;
      if ($scope.adgroup) {
        filename = `adgroup_${$scope.adgroup.id}_location_reporting.xlsx`;
        locationReportinData = await campaignManagerFactory.downloadAdGroupLocationReport(
          $scope.adgroup.id,
          $scope.selectedDurationRange
        );
      } else {
        filename = `campaign_${$scope.campaign.id}_location_reporting.xlsx`;
        locationReportinData = await campaignManagerFactory.downloadCampaignLocationReport(
          $scope.campaign.id,
          $scope.selectedDurationRange
        );
      }
      generalUtilsFactory.downloadSpreadsheet(locationReportinData, filename);
      $scope.errorMsg.locationReporting = '';
    } catch (errData) {
      // Since we are downloading a file, we set response type as arraybuffer
      // However, error responses return as JSON, so we need to decode the arraybuffer response
      const decodedResponseStr = String.fromCharCode(...new Uint8Array(errData.data));
      const decodedResponse = JSON.parse(decodedResponseStr);
      $scope.errorMsg.locationReporting = decodedResponse.message;
    } finally {
      $scope.progress.locationReporting = false;
      generalUtilsFactory.safeApply($scope);
    }
  };

  $scope.downloadReportData = function() {
    var promise;
    $scope.errorMsg.reportData = '';
    $scope.progress.reportData = true;

    if ($scope.reportName === 'daily') {
      promise = reportFactory.getDailyTrendExportData(
        $scope.tableLevel,
        $scope.campaign.id,
        $scope.selectedDurationRange
      );
    } else if ($scope.reportName === 'dow') {
      promise = reportFactory.getDOWExportData($scope.tableLevel, $scope.campaign.id, $scope.selectedDurationRange);
    } else if ($scope.reportName === 'tod') {
      promise = reportFactory.getTODExportData($scope.tableLevel, $scope.campaign.id, $scope.selectedDurationRange);
    } else if ($scope.reportName === 'dowtod') {
      promise = reportFactory.getDOWTODExportData($scope.tableLevel, $scope.campaign.id, $scope.selectedDurationRange);
    } else if ($scope.reportName === 'behavioral') {
      promise = reportFactory.getBehavioralAudienceExportData(
        $scope.tableLevel,
        $scope.campaign.id,
        $scope.selectedDurationRange
      );
    } else if ($scope.reportName === 'product') {
      promise = reportFactory.getProductExportData($scope.tableLevel, $scope.campaign.id, $scope.selectedDurationRange);
    } else if ($scope.reportName === 'audiencecategory') {
      promise = reportFactory.getCategoryExportData(
        $scope.tableLevel,
        $scope.campaign.id,
        $scope.selectedDurationRange
      );
    } else if ($scope.reportName === 'brandaffinity') {
      promise = reportFactory.getBrandAffinityExportData(
        $scope.tableLevel,
        $scope.campaign.id,
        $scope.selectedDurationRange,
        $scope.selectedCategory
      );
    } else if ($scope.reportName === 'audienceaffinity') {
      promise = reportFactory.getAudienceAffinityExportData(
        $scope.tableLevel,
        $scope.campaign.id,
        $scope.selectedDurationRange,
        $scope.selectedCategory
      );
    } else if ($scope.reportName === 'publisher') {
      promise = reportFactory.getPublisherExportData(
        $scope.tableLevel,
        $scope.campaign.id,
        $scope.selectedDurationRange
      );
    } else if ($scope.reportName === 'conversions') {
      promise = reportFactory.getConversionsExportData(
        $scope.tableLevel,
        $scope.campaign.id,
        $scope.selectedDurationRange
      );
    } else {
      promise = $q.reject('No matched report name');
    }

    $q.when(promise)
      .then(
        function(data) {
          var filename = generateFileName($scope.campaign.name + '_' + $scope.reportName);
          var csv = dataExportFactory.reportDataToCsv(data, companyAndAccountFactory.requireSelectedAccount().currency);
          generalUtilsFactory.downloadCSV(csv, filename);
        },
        function() {
          $scope.errorMsg.reportData =
            'We are unable to generate the report export data for this configuration. It is possible that there is no data for this yet. Please try again later.';
        }
      )
      .finally(function() {
        $scope.progress.reportData = false;
      });
  };

  function generateFileName(filename) {
    return filename.replace(/[^a-z0-9-]/gi, '_');
  }

  $scope.downloadUserChangeLog = async () => {
    $scope.progress.userChangeLog = true;

    try {
      const changelogData = await changeLogFactory.getUserChangeLog($scope.campaign.id);
      const filename = `campaign_${$scope.campaign.id}_changelog.xlsx`;
      generalUtilsFactory.downloadSpreadsheet(changelogData, filename);
    } catch (errData) {
      // Since we are downloading a file, we set response type as arraybuffer
      // However, error responses return as JSON, so we need to decode the arraybuffer response
      const decodedResponseStr = String.fromCharCode(...new Uint8Array(errData.data));
      const decodedResponse = JSON.parse(decodedResponseStr);
      $scope.errorMsg.userChangeLog = decodedResponse.message;
    } finally {
      $scope.progress.userChangeLog = false;
      generalUtilsFactory.safeApply($scope);
    }
  };

  $scope.downloadEndClientReport = function() {
    $scope.errorMsg.endClientReport = '';
    $scope.progress.endClientReport = true;

    reportFactory
      .getDailyTrendExportData('placements', $scope.campaign.id, $scope.selectedDurationRange)
      .then(function(data) {
        let exportData = processEndClientReportData(data);
        const filename = generateFileName($scope.campaign.name + '_end_client_report');
        const csv = dataExportFactory.reportDataToCsv(
          exportData,
          companyAndAccountFactory.requireSelectedAccount().currency
        );
        generalUtilsFactory.downloadCSV(csv, filename);
      })
      .catch(function(err) {
        console.error(err);
        $scope.errorMsg.endClientReport =
          'We are unable to generate the end client report data for this configuration. It is possible that there is no data for this yet. Please try again later.';
      })
      .finally(function() {
        $scope.progress.endClientReport = false;
      });
  };

  function processEndClientReportData(data) {
    let res = [];
    for (let row of data) {
      /** @type {Record<string, any>} */
      let filteredRow = Object.keys(row)
        .filter(key => endClientReportKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = row[key];
          return obj;
        }, {});

      filteredRow.campaign_id = $scope.campaign.id;
      filteredRow.campaign_name = $scope.campaign.name;

      const adgroup = campaignManagerFactory.selectFromAdGroupsWithId($scope.campaign.adGroups, row.adgroup_id);
      if (adgroup.gross_bid_rate == null) {
        filteredRow.totalSpentGross = '--';
        filteredRow.CPMGross = '--';
      } else {
        const grossBidRate = adgroup.gross_bid_rate;
        filteredRow.totalSpentGross = (grossBidRate / adgroup.bidRate) * row.spends_spent;
        filteredRow.CPMGross = grossBidRate;
      }
      delete filteredRow.spends_spent;
      res.push(filteredRow);
    }
    return res;
  }

  $scope.close = function(selected) {
    $uibModalInstance.close(selected);
  };
}
