export function multiSelectDropdown() {
  var directive = {
    bindToController: true,
    controller: MultiSelectDropdownController,
    controllerAs: 'vm',
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.multi-select-dropdown.html',
    link: link,
    transclude: true,
    restrict: 'E',
    scope: {
      config: '=',
      showCaret: '=',
      refresh: '&'
    }
  };
  return directive;

  function link(scope, element, attrs) {}
}

function MultiSelectDropdownController() {
  var vm = this;
  vm.showDropdown = false;

  vm.toggleDropdown = toggleDropdown;
  vm.toggleType = toggleType;
  vm.toggleRow = toggleRow;
  vm.getTypeDisplayLabel = getTypeDisplayLabel;

  function toggleDropdown(boolean) {
    vm.showDropdown = boolean === false ? boolean : !vm.showDropdown;
  }

  function toggleType(type) {
    var filterTerms = [];
    var filter = {};
    if (vm.config[type] && vm.config[type].rows) {
      for (var i = 0; i < vm.config[type].rows.length; i++) {
        vm.config[type].rows[i].checked = vm.config[type].checked;
        if (vm.config[type].rows[i].checked) {
          filterTerms.push(vm.config[type].rows[i].title);
        }
      }
      filter[type] = filterTerms;
      vm.refresh({ filters: filter });
    }
  }

  function toggleRow(type) {
    var filterTerms = [];
    var filter = {};
    var checked = true;
    if (vm.config[type] && vm.config[type].rows) {
      for (var i = 0; i < vm.config[type].rows.length; i++) {
        if (!vm.config[type].rows[i].checked) {
          checked = false;
        } else {
          filterTerms.push(vm.config[type].rows[i].title);
        }
      }
      vm.config[type].checked = checked;
      filter[type] = filterTerms;
      vm.refresh({ filters: filter });
    }
  }

  function getTypeDisplayLabel(type) {
    return type === 'status' ? 'Select All' : type;
  }
}
