jobFactory.$inject = ['$q', '$http'];

/**
 * @param {ng.IQService} $q
 * @param {ng.IHttpService} $http
 */
export function jobFactory($q, $http) {
  var jobFactory = {};

  jobFactory.jobTypes = {
    GEOTARGET: 0,
    CAMPAIGN_BULK_UPLOAD: 1,
    CAMPAIGN_SUMMARY: 2
  };

  jobFactory.createJob = function(jobType, inputFileURL, campaignId, adgroupId, accountId, appendData) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: '/data/jobs',
      data: {
        input_file_url: inputFileURL,
        job_type: jobType,
        campaign_id: campaignId,
        adgroup_id: adgroupId,
        account_id: accountId,
        append_data: appendData || false
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });

    return d.promise;
  };

  jobFactory.downloadSampleCampaignBulkSpreadsheet = function(campaign_id) {
    var d = $q.defer();
    let url = `/data/campaigns/${campaign_id}/bulk_upload_template`;
    $http({
      method: 'GET',
      url,
      data: {}
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });

    return d.promise;
  };

  jobFactory.acknowledgeJobResults = function(jobId) {
    var d = $q.defer();
    $http({
      method: 'PUT',
      url: '/data/jobs/' + jobId + '/confirm',
      data: {}
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });

    return d.promise;
  };

  jobFactory.cancelJob = function(jobId) {
    var d = $q.defer();
    $http({
      method: 'PUT',
      url: '/data/jobs/' + jobId + '/cancel'
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });

    return d.promise;
  };

  jobFactory.getJobStatus = function(jobId) {
    var d = $q.defer();
    $http({
      method: 'GET',
      url: '/data/jobs/' + jobId + '/status'
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });

    return d.promise;
  };

  return jobFactory;
}
