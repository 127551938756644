import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AccountCreditCardModal } from './AccountCreditCardModal';
import { PaymentsTable } from './PaymentsTable';
import { BillingThresholdSection } from './BillingThresholdSection';
import { Account } from '../../../api/types/companiesAndAccounts';
import { CreditCard as Card } from '../../../api/types/creditCard';
import { Customer } from '../../../api/types/customer';
import { cardStatus } from './CardRow';
import { TransactionStatementSection } from './TransactionStatementSection';
import { Statement } from '../../../api/types/Statement';
import { Promotion } from '../../../api/types/Promotion';
import { PromotionSection } from './PromotionSection';
import { PrimaryButton } from '../../../components/reactComponents/Button';
import { PaymentMethodModal } from './PaymentMethodModal';

export interface CardTypeIcons {
  [propName: string]: string;
}

export interface CreditCard extends Card {
  deleteProgress?: boolean;
  primaryProgress?: boolean;
}

interface BillingsPageViewProps {
  cards: CreditCard[];
  isFeatureEnabled: (featureName: string) => boolean;
  paymentsInProgress: boolean;
  cardTypeIcons: CardTypeIcons;
  allAccounts: Account[];
  updateConnectedAccounts: (accounts: number[]) => void;
  customer: Customer;
  setDefaultCard: (card: CreditCard) => void;
  billingThreshold: number;
  cardRetryInProgress: boolean;
  retryCard: (card: CreditCard) => void;
  deleteCard: (card: CreditCard) => void;
  hasFullPaymentsAccess: boolean;
  isLoading: boolean;
  statements: Statement[];
  promotions: Promotion[];
  submitPaymentMethod: () => void;
  isAccountLevelCreditCardsEnabled: boolean;
}

export function BillingPageView(props: BillingsPageViewProps) {
  const {
    cards = [],
    isFeatureEnabled,
    paymentsInProgress,
    cardTypeIcons,
    allAccounts,
    updateConnectedAccounts,
    customer,
    setDefaultCard,
    billingThreshold,
    cardRetryInProgress,
    retryCard,
    deleteCard,
    hasFullPaymentsAccess,
    isLoading,
    statements,
    promotions,
    submitPaymentMethod,
    isAccountLevelCreditCardsEnabled
  } = props;

  const [showAccountCreditCardModal, setShowAccountCreditCardModal] = useState<boolean>(false);
  const [creditCardForAccountsModal, setCreditCardForAccountsModal] = useState<CreditCard>();
  const [showNewPaymentModal, setShowNewPaymentModal] = useState<boolean>(false);

  const handleConnectedAccountsClick = (card: CreditCard) => {
    setCreditCardForAccountsModal(card);
    setShowAccountCreditCardModal(true);
  };

  const disableConfirm =
    creditCardForAccountsModal?.status === cardStatus.FAILED ||
    creditCardForAccountsModal?.status === cardStatus.PENDING ||
    creditCardForAccountsModal?.status === cardStatus.DISPUTE;

  return (
    <div className="payment__wrapper">
      {showAccountCreditCardModal && creditCardForAccountsModal && (
        <AccountCreditCardModal
          accounts={allAccounts}
          open={showAccountCreditCardModal}
          onClose={() => {
            setShowAccountCreditCardModal(false);
          }}
          onConfirm={updateConnectedAccounts}
          disableConfirm={disableConfirm}
          card={creditCardForAccountsModal}
        />
      )}
      <PaymentMethodModal
        open={showNewPaymentModal}
        onClose={() => setShowNewPaymentModal(false)}
        submitPaymentMethod={submitPaymentMethod}
      />
      {hasFullPaymentsAccess && (
        <div className="payment-page__section">
          <PrimaryButton
            title={
              <span className="section-title__btn-label">
                <i className="far fa-plus-square" />
                Add Payment Method
              </span>
            }
            disabled={isLoading || !isFeatureEnabled('PAYMENT_WRITE')}
            onClick={() => setShowNewPaymentModal(true)}
            className="button-v2 button--green add-payment-button"
          />
          <PaymentsTable
            cards={cards}
            paymentsInProgress={paymentsInProgress}
            cardTypeIcons={cardTypeIcons}
            handleConnectedAccountsClick={handleConnectedAccountsClick}
            customer={customer}
            setDefaultCard={setDefaultCard}
            isFeatureEnabled={isFeatureEnabled}
            isSingleCard={cards.length === 1}
            retryCard={retryCard}
            deleteCard={deleteCard}
            cardRetryInProgress={cardRetryInProgress}
            isLoading={isLoading}
            isAccountLevelCreditCardsEnabled={isAccountLevelCreditCardsEnabled}
          />
        </div>
      )}
      {hasFullPaymentsAccess && billingThreshold && (
        <div className="payment-page__section">
          <BillingThresholdSection billingThreshold={billingThreshold} />
        </div>
      )}
      {hasFullPaymentsAccess && promotions?.length > 0 && <PromotionSection promotions={promotions} />}
      <TransactionStatementSection statements={statements} />
    </div>
  );
}

BillingPageView.propTypes = {
  cards: PropTypes.array,
  isFeatureEnabled: PropTypes.func,
  paymentsInProgress: PropTypes.bool,
  cardTypeIcons: PropTypes.object,
  allAccounts: PropTypes.array,
  connectedAccountsIds: PropTypes.array,
  updateConnectedAccounts: PropTypes.func,
  customer: PropTypes.object,
  setDefaultCard: PropTypes.func,
  billingThreshold: PropTypes.number,
  cardRetryInProgress: PropTypes.bool,
  retryCard: PropTypes.func,
  deleteCard: PropTypes.func,
  hasFullPaymentsAccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  statements: PropTypes.array,
  promotions: PropTypes.array,
  submitPaymentMethod: PropTypes.func,
  isAccountLevelCreditCardsEnabled: PropTypes.bool
};
