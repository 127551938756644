export class statusModalController {
  constructor(data, $uibModalInstance) {
    this.statuses = data.statuses;
    this.title = data.title;
    this.$uibModalInstance = $uibModalInstance;
  }

  close() {
    this.$uibModalInstance.close();
  }
}

statusModalController.$inject = ['data', '$uibModalInstance'];
