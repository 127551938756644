import { observable, computed, action } from 'mobx';
import { promisedComputed } from 'computed-async-mobx';
import { ReportModel, Organization, Account, Campaign } from './report_model';
import {
  FormModelBase,
  textField,
  dateField,
  multiselectField,
  selectField,
  checkboxField,
  listField
} from '../../../components/forms';
import { ReportDeliveryScheduleFrequency } from '../../../api/http/reports';
import { store } from './store';

import { universalAlert, $rootScope } from '../../../utils/services';
import { safeApply } from '../../../factories/general_utils';
import { send$http } from '../../../api/def/ngExecutor';
import { $http } from '../../../utils/services';
import { searchCampaigns, SearchCampaignsSortBy, SearchCampaignsSortDirection } from '../../../api/http/campaigns';

export class ReportFormModel extends FormModelBase {
  @observable private _mobx = true;
  @observable campaignSearchQuery: string = '';
  report: ReportModel;

  name = textField({ required: true });
  type = selectField<string>({
    required: true,
    options: () => store.reportScheduler.reportTypes?.map(rt => rt.report_type) ?? []
  });
  startDate = dateField({ required: true });
  endDate = dateField({ required: true });
  recipients = listField<string>({ required: true });
  organizations = multiselectField<Organization>({
    required: true,
    options: () => this.organizationOptions,
    compare: (option, value) => option.id === value.id
  });
  accounts = multiselectField<Account>({
    required: true,
    options: () => this.accountOptions,
    compare: (option, value) => option.id === value.id
  });
  campaigns = multiselectField<Campaign>({
    required: true,
    options: () => this.campaignOptions,
    compare: (option, value) => option.id === value.id,
    skipMultiValidation: true
  });
  frequency = selectField({
    required: true,
    options: [ReportDeliveryScheduleFrequency.DAILY, ReportDeliveryScheduleFrequency.WEEKLY]
  });
  enableDelivery = checkboxField();
  sendNow = checkboxField();

  baseFields = [
    this.name,
    this.type,
    this.startDate,
    this.endDate,
    this.recipients,
    this.organizations,
    this.accounts,
    this.campaigns,
    this.enableDelivery,
    this.sendNow
  ];

  constructor(report: ReportModel) {
    super();

    this.report = report;
    this.name.set(report.name);
    this.type.set(report.type);
    this.startDate.set(report.startDate);
    this.endDate.set(report.endDate);
    this.recipients.set(report.recipients);
    this.organizations.set(report.organizations);
    this.accounts.set(report.accounts);
    this.campaigns.set(report.campaigns);
    this.frequency.set(report.frequency);
    this.enableDelivery.set(report.enableDelivery);
    this.sendNow.set(report.sendNow);
  }

  @computed get getReportTypeName(): (reportType: string) => string {
    const reportTypes = store.reportScheduler.reportTypes;
    if (reportTypes)
      return reportType => reportTypes.find(t => t.report_type === reportType)?.display_name ?? reportType;
    return reportType => `${reportType} (loading...)`;
  }

  private reportTypeNameVersion = 0;
  @computed get getReportTypeNameVersion() {
    return this.getReportTypeName && ++this.reportTypeNameVersion;
  }

  getFields() {
    return this.enableDelivery.validatedValue ? [...this.baseFields, this.frequency] : this.baseFields;
  }

  @action.bound saveTo(report: ReportModel) {
    report.name = this.name.validatedValue;
    if (this.type.validatedValue) report.type = this.type.validatedValue;
    report.startDate = this.startDate.validatedValue;
    report.endDate = this.endDate.validatedValue;
    report.recipients = this.recipients.validatedValue;
    report.organizations = this.organizations.validatedValue;
    report.accounts = this.accounts.validatedValue;
    report.campaigns = this.campaigns.validatedValue;
    report.enableDelivery = this.enableDelivery.validatedValue;
    if (this.enableDelivery.validatedValue && this.frequency.validatedValue)
      report.frequency = this.frequency.validatedValue;
    report.sendNow = this.sendNow.validatedValue;
  }

  @computed get organizationOptions() {
    return (
      store.companyAndAccount.companiesAccounts
        ?.map(org => ({ id: org.id, name: org.name }))
        .sort((a, b) => a.name.localeCompare(b.name)) ?? []
    );
  }

  @computed get accountOptions() {
    const selectedOrganizations = new Set(this.organizations.value.map(o => o.id));
    const accounts =
      store.companyAndAccount.companiesAccounts?.reduce(
        (prev, org) =>
          selectedOrganizations.has(org.id)
            ? [
                ...prev,
                ...org.accounts.map(account => ({ id: account.id, name: account.name, organization_id: org.id }))
              ]
            : prev,
        [] as Account[]
      ) ?? [];
    return accounts.sort((a, b) => a.name.localeCompare(b.name));
  }

  private _campaignOptions = promisedComputed<Campaign[]>([], async () => {
    if (this.organizations.value.length <= 0 || this.accounts.value.length <= 0) {
      return [];
    }

    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 18);

    try {
      const response = await send$http($http, searchCampaigns, {
        data: {
          search: this.campaignSearchQuery,
          limit: 1000,
          sort_by: SearchCampaignsSortBy.NAME,
          sort_direction: SearchCampaignsSortDirection.ASC,
          min_start_date: startDate.toISOString().substr(0, 10),
          organization_ids: this.organizations.value.map(org => org.id),
          account_ids: this.accounts.value.map(account => account.id)
        }
      });
      return response.campaigns;
    } catch (err) {
      const message = err?.data?.message ?? String(err);
      universalAlert.showAlert(message);
      safeApply($rootScope);
      return [];
    }
  });

  @computed get campaignOptions() {
    return this._campaignOptions.get();
  }

  @action.bound setCampaignSearchQuery(val: string) {
    this.campaignSearchQuery = val;
  }
}
