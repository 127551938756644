import * as React from 'react';
import PropTypes from 'prop-types';
import { ReportCreate } from './report_form/report_create';

export interface ReportSchedulerCreateProps {
  reportId: string;
}

export function ReportSchedulerCreate(props: ReportSchedulerCreateProps) {
  return <ReportCreate />;
}

ReportSchedulerCreate.propTypes = {
  reportId: PropTypes.string
};
