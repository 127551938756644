export const locationGroupWarningTooltip = {
  audience: {
    build:
      'Location Audience for this Location Group is not yet ready. It is currently being built. Please check back in 24 hours. If you think 24 hours have passed since you started building audiences for this Location Group, please contact your account manager for assistance.',
    rebuild:
      'Location Audience for this Location Group is currently updating after your recent change to the Location Group. You can use the Location Group right now but the changes in Location Audience (due to recent changes in the Location Group) will take up to 24 hours to get reflected.',
    expired:
      'Location Audience for this Location Group has expired and will need to be rebuilt again. Save and launch the adgroup to initiate a rebuild. Location Audience will be ready and automatically activated for this adgroup within 24 hours.'
  },
  neighborhood: {
    build:
      'Neighborhoods for this Location Group are not yet ready. They are currently being built. Please check back in 24 hours. If you think 24 hours have passed since you started building neighborhoods for this Location Group, please contact your account manager for assistance.',
    rebuild:
      'Neighborhoods for this Location Group are currently being updated after your recent change to the Location Group. You can use the Location Group right now but the changes in Neighborhoods (due to recent changes in the Location Group) will take up to 24 hours to get reflected.',
    expired:
      'Neighborhoods for this Location Group have expired and will need to be rebuilt again. Save and launch the adgroup to initiate a rebuild. Neighborhoods will be ready and automatically activated for this adgroup within 24 hours.'
  }
};
