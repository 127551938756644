import { StickyStatesPlugin } from '@uirouter/sticky-states';
import reportSchedulerTemplate from './report_scheduler/report-scheduler-template.html';
import directMailOrdersTemplate from './direct_mail_orders/direct-mail-orders-template.html';
import reportSchedulerEditTemplate from './report_scheduler/report-scheduler-edit-template.html';
import reportSchedulerCreateTemplate from './report_scheduler/report-scheduler-create-template.html';
import creativeRepositoryV2Template from './creative_repo_v2/creative-repository-v2-template.html';
import { identifyAppcues } from './appcues';

let IS_BOOTSTRAPPED = false;

const APPCUES_SCRIPT = `
<script async>
  (${identifyAppcues.toString()})();
</script>
`;

const HEAP_SCRIPT = `
    <script async>
        window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
        heap.load(window.heapProductId);
        heap.identify(window.user.id);
        heap.addUserProperties({email: window.user.email, tenant_id: window.user.tenant_id});
    </script>
`;

/**
 * @param {ng.IHttpProvider} $httpProvider
 * @param {ng.ILocationProvider} $locationProvider
 * @param {import('@uirouter/angularjs').UrlRouterProvider} $urlRouterProvider
 * @param {import('@uirouter/angularjs').UIRouter} $uiRouterProvider
 * @param {import('@uirouter/angularjs').StateProvider} $stateProvider
 * @ngInject
 */
export function config(
  flowFactoryProvider,
  $httpProvider,
  $stateProvider,
  $urlRouterProvider,
  $locationProvider,
  $uiRouterProvider
) {
  // for back-compat with Angular <1.5
  // This option is now disabled by default.
  // As of Angular 1.7 it has been removed.
  // During transition, it might be useful to see if any bugs are coming from this.
  // if ($compileProvider.preAssignBindingsEnabled) {
  //   $compileProvider.preAssignBindingsEnabled();
  // }

  $uiRouterProvider.plugin(StickyStatesPlugin);

  // You can also set default events: flowFactoryProvider.on('catchAll', function (event) { ... });
  // Can be used with different implementations of Flow.js
  // flowFactoryProvider.factory = fustyFlowFactory;
  flowFactoryProvider.defaults = {
    target: `/data/upload?subsession=${window.subsession}&type=file`,
    chunkSize: 1024 * 1024 * 1024,
    permanentErrors: [404, 500, 501],
    minFileSize: 0,
    simultaneousUploads: 1,
    testChunks: false
  };

  $httpProvider.interceptors.push('redirectInterceptor');
  $httpProvider.interceptors.push('subsessionInterceptor');

  $stateProvider
    .state('base', {
      abstract: true,
      resolve: {
        bootstrap: function($q, $http, userFactory, featureFlagFactory) {
          // We only need to call this function once on initial page load
          if (IS_BOOTSTRAPPED) {
            return;
          }

          const d = $q.defer();
          $http({
            method: 'GET',
            url: '/bootstrap'
          }).then(
            response => {
              // Set window variables
              Object.assign(window, {
                featureFlagConnInfo: response.data.feature_flag_conn_info,
                checksum: response.data.checksum,
                locationManagerURL: response.data.location_manager_url,
                audienceManagerURL: response.data.audience_manager_url,
                directMailURL: response.data.direct_mail_url,
                discoveryURL: response.data.discovery_url,
                consoleURL: response.data.console_url,
                mediaCdnUrl: response.data.upload_cdn_url,
                stripePublicKey: response.data.stripe_public_key,
                heapProductId: response.data.heap_product_id,
                version: response.data.version,
                user: response.data.user
              });

              // These scripts depend on the current user's info
              $('head').append([APPCUES_SCRIPT, HEAP_SCRIPT]);

              // Initialize necessary factories
              userFactory.currentLoggedInUser = window.user;
              featureFlagFactory.init();

              IS_BOOTSTRAPPED = true;
              d.resolve(response);
            },
            response => {
              d.reject(response.data.message);
            }
          );
          return d.promise;
        },
        companiesAndAccounts: function(bootstrap, companyAndAccountFactory, campaignManagerFactory, $stateParams) {
          return companyAndAccountFactory
            .getCompaniesAndAccounts(
              $stateParams.tenantId,
              $stateParams.companyId || $stateParams.selectedCompanyId,
              $stateParams.accountId || $stateParams.selectedAccountId,
              $stateParams.campaignId
            )
            .then(function(data) {
              campaignManagerFactory.clean();
              return data;
            });
        },
        featureFlags: function(bootstrap, companiesAndAccounts, featureFlagFactory, companyAndAccountFactory) {
          return featureFlagFactory.load(companyAndAccountFactory);
        },
        tenantDetail: function(bootstrap, companiesAndAccounts, companyAndAccountFactory, tenantFactory, $stateParams) {
          return tenantFactory.getTenantDetail($stateParams.tenantId).then(function(data) {
            return data;
          });
        }
      },
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.base.html',
      controller: 'BaseController as vm',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      }
    })
    .state('root', {
      url: '/?createCampaign',
      parent: 'base',
      template: '<div ui-view></div>',
      controller: 'rootController as vm',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null,
        createCampaign: null
      }
    })
    .state('tenantDashboard', {
      parent: 'root',
      sticky: true,
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.tenant-dashboard.html',
      controller: 'tenantDashboardController',
      controllerAs: 'vm',
      data: {
        featureCode: 'TENANT_DASHBOARD'
      },
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      }
    })
    .state('campaigns', {
      parent: 'root',
      abstract: true,
      template: '<div ui-view></div>',
      resolve: {
        campaignsWithAdGroups: function(companiesAndAccounts, campaignManagerFactory, $stateParams) {
          return Promise.resolve();
        }
      }
    })
    .state('campaigns.opsDashboard', {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaign_manager.html',
      controller: 'CampaignOrAdGroupViewCtrl',
      sticky: true,
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      },
      resolve: {
        channelCampaigns: function(companiesAndAccounts, campaignManagerFactory, featureFlagFactory, $stateParams) {
          if (featureFlagFactory.isFeatureEnabled('CAMPAIGN_OPS_DASHBOARD')) {
            return campaignManagerFactory.getChannelCampaigns().then(function(data) {
              return data;
            });
          }
        }
      },
      data: {
        featureCode: 'CAMPAIGN_OPS_DASHBOARD'
      }
    })
    .state('campaigns.dashboard', {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaign_manager.html',
      controller: 'CampaignOrAdGroupViewCtrl',
      redirectTo: async trans => {
        /**
         * Due to the difficulty to get rid of `campaigns.dashboard` state, as it is used in
         * several other places and would require major refactoring. As it'd require setting
         * the account and company before going to this state. We're using redict as a
         * band-aid solution for now.
         */
        const transitionParams = trans.params();

        let params = {
          fromPage: transitionParams?.fromPage,
          createCampaign: transitionParams?.createCampaign
        };

        // In cases, where we know `companyId` and `accountId` we can just redirect
        if (transitionParams.companyId && transitionParams.accountId) {
          return { state: 'campaigns.accountDashboard', transitionParams };
        }

        /**
         * `companiesAndAccounts` promise is inherited so that in case of tenant switch
         * we wouldn't know `companyId` and `accountId` beforehand. So, we allow the `base`
         * state to set tenant first then do the redirection.
         *  */

        let companiesAndAccounts = trans.injector().getAsync('companiesAndAccounts');

        try {
          const companiesAndAccountsData = await companiesAndAccounts;

          if (companiesAndAccountsData) {
            if (companiesAndAccountsData.selected_company_id) {
              params.companyId = companiesAndAccountsData.selected_company_id;
            }
            if (companiesAndAccountsData.selected_account_id) {
              params.accountId = companiesAndAccountsData.selected_account_id;
            }
            if (companiesAndAccountsData.tenant_id) {
              params.tenantId = companiesAndAccountsData.tenant_id;
            }
          }

          if (params.companyId && params.accountId) {
            return { state: 'campaigns.accountDashboard', params };
          }
        } catch (e) {
          console.error(e);
          // In any case where there was issue with setting account or it is a new org.
          // User can go to org page and create/select account.
          return { state: 'organization' };
        }
      },
      params: {
        fromPage: null,
        tenantId: null,
        companyId: null,
        accountId: null,
        createCampaign: null
      }
    })
    .state('campaigns.accountDashboard', {
      url: 'organization/:companyId/account/:accountId?fromPage',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaign_manager.html',
      controller: 'CampaignOrAdGroupViewCtrl',
      params: {
        fromPage: null,
        tenantId: null,
        companyId: null,
        accountId: null
      },
      resolve: {
        accountDashboardCampaigns: function(campaignManagerFactory) {
          return campaignManagerFactory.loadCampaignsWithBudgetsForAccount().then(function(data) {
            return data;
          });
        }
      }
    })
    .state('campaigns.campaign', {
      url: 'campaign/:campaignId',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaign_manager.html',
      controller: 'CampaignOrAdGroupViewCtrl',
      resolve: {
        campaignWithAdGroups: function(
          companiesAndAccounts,
          campaignManagerFactory,
          campaignsWithAdGroups,
          $stateParams
        ) {
          return campaignManagerFactory
            .loadCampaignWithAdGroups($stateParams.campaignId)
            .then(function(data) {
              return data;
            })
            .catch(function(err) {
              // TODO: make not found state and change the state to that
              return null;
            });
        }
      },
      params: {
        isBulkUpload: false,
        tenantId: null,
        companyId: null,
        accountId: null
      }
    })
    .state('campaigns.campaign.settings', {
      url: '/settings',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaign_manager.html',
      controller: 'CampaignOrAdGroupViewCtrl',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      },
      data: {
        featureCode: 'CAMPAIGN_READ'
      }
    })
    .state('campaigns.adgroup', {
      url: 'campaign/:campaignId/adgroup/:adGroupId',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaign_manager.html',
      controller: 'CampaignOrAdGroupViewCtrl',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      },
      resolve: {
        campaignWithAdGroups: function(
          companiesAndAccounts,
          campaignsWithAdGroups,
          campaignManagerFactory,
          featureFlagFactory,
          $stateParams
        ) {
          const isReportingOnlyUser = featureFlagFactory.isFeatureEnabled('REPORTING_ONLY');
          if (isReportingOnlyUser) return null;
          return campaignManagerFactory
            .loadCampaignWithAdGroups($stateParams.campaignId, $stateParams.adGroupId)
            .then(function(data) {
              return data;
            })
            .catch(function() {
              // TODO: make not found state and change the state to that
              // need to have catch function to avoid infinite calls
              return null;
            });
        }
      },
      data: {
        featureCode: 'CAMPAIGN_READ'
      }
    })
    .state('organization', {
      parent: 'base',
      url: '/organization/?action',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.organization_manager.html',
      controller: 'OrganizationManagerCtrl',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      },
      data: {
        featureCode: 'COMPANY_READ'
      }
    })
    .state('company', {
      parent: 'base',
      url: '/organization/{selectedCompanyId:int}',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.organization_manager.html',
      controller: 'OrganizationManagerCtrl',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      },
      data: {
        featureCode: 'COMPANY_READ'
      }
    })
    .state('account', {
      parent: 'base',
      url: '/organization/:selectedCompanyId/account/:selectedAccountId/settings',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.organization_manager.html',
      controller: 'OrganizationManagerCtrl',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      },
      data: {
        featureCode: 'COMPANY_READ'
      }
    })
    .state('users', {
      parent: 'base',
      url: '/user',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.user_manager.html',
      controller: 'UserManagerCtrl',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      },
      data: {
        featureCode: 'COMPANY_READ'
      },
      resolve: {
        checkAccess: featureFlagFactory => {
          const isReportingOnlyUser = featureFlagFactory.isFeatureEnabled('REPORTING_ONLY');
          if (isReportingOnlyUser) return Promise.reject();
        }
      }
    })
    .state('users.list', {
      url: '/',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.user_list_view.html',
      controller: 'UserListViewController',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      },
      data: {
        featureCode: 'COMPANY_READ'
      }
    })
    .state('users.edit-user', {
      url: '/{userId:int}',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.edit_user.html',
      controller: 'UserEditController',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      },
      data: {
        featureCode: 'COMPANY_READ'
      },
      resolve: {
        checkAccess: featureFlagFactory => {
          const isReportingOnlyUser = featureFlagFactory.isFeatureEnabled('REPORTING_ONLY');
          if (isReportingOnlyUser) return Promise.reject();
        }
      }
    })
    .state('users.profile', {
      url: '/profile/{userId:int}',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.edit_user.html',
      controller: 'UserEditController',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null,
        isProfile: true
      },
      data: {
        featureCode: 'COMPANY_READ'
      },
      resolve: {
        checkAccess: featureFlagFactory => {
          const isReportingOnlyUser = featureFlagFactory.isFeatureEnabled('REPORTING_ONLY');
          if (isReportingOnlyUser) return Promise.reject();
        }
      }
    })
    .state('users.new-user', {
      url: '/new-user',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.edit_user.html',
      controller: 'UserEditController',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      },
      data: {
        featureCode: 'USER_MANAGEMENT'
      }
    })
    .state('search', {
      parent: 'base',
      url: '/search?q&filter_by&campaign_start_date&campaign_created_date&campaign_updated_date&account_created_date',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.search.html',
      controller: 'SearchCtrl',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      }
    })
    .state('tenant-manager', {
      parent: 'base',
      url: '/tenant/',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.tenant_manager.html',
      controller: 'tenantEditController',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      }
    })
    .state('payment-manager', {
      parent: 'base',
      url: '/payments/',
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.payments_manager.html',
      controller: 'paymentsController',
      params: {
        tenantId: null,
        companyId: null,
        accountId: null
      },
      resolve: {
        checkAccess: featureFlagFactory => {
          const isReportingOnlyUser = featureFlagFactory.isFeatureEnabled('REPORTING_ONLY');
          if (isReportingOnlyUser) return Promise.reject();
        }
      }
    })
    .state('creative-repository', {
      parent: 'base',
      url: '/creative-repository',
      templateUrl: '/ui/pages/creative_repo/creative-repository-template.html',
      data: {
        featureCode: 'CREATIVE_REPO'
      }
    })
    .state('report-scheduler', {
      parent: 'base',
      url: '/report-scheduler',
      template: reportSchedulerTemplate,
      data: {
        featureCode: 'REPORT_SCHEDULER'
      }
    })
    .state('direct-mail-orders', {
      parent: 'base',
      url: '/direct-mail-orders',
      template: directMailOrdersTemplate,
      controller: 'directMailOrdersDashboardController',
      controllerAs: 'abdmCtrl',
      data: {
        featureCode: 'DIRECT_MAIL'
      }
    })
    .state('report-scheduler-create', {
      parent: 'base',
      url: '/report-scheduler/create',
      template: reportSchedulerCreateTemplate,
      data: {
        featureCode: 'REPORT_SCHEDULER'
      }
    })
    .state('report-scheduler-edit', {
      parent: 'base',
      url: '/report-scheduler/:reportId',
      template: reportSchedulerEditTemplate,
      params: {
        reportId: null
      },
      data: {
        featureCode: 'REPORT_SCHEDULER'
      }
    })
    .state('creative-repository-v2', {
      parent: 'base',
      url: '/creative-repository-v2',
      template: creativeRepositoryV2Template,
      resolve: {
        checkAccess: featureFlagFactory => {
          const isAutomatedCreativesEnabled = featureFlagFactory.isFeatureEnabled('AUTOMATED_CREATIVES');
          if (!isAutomatedCreativesEnabled) return Promise.reject();
        }
      }
    });
  $urlRouterProvider.otherwise('/');
  //coming soon
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });
}

runBlock.$inject = [
  '$rootScope',
  '$window',
  'searchFactory',
  '$document',
  'featureFlagFactory',
  '$transitions',
  'universalAlert'
];

/**
 * @param {import('@uirouter/angularjs').TransitionService} $transitions
 */
export function runBlock(
  $rootScope,
  $window,
  searchFactory,
  $document,
  featureFlagFactory,
  $transitions,
  universalAlert
) {
  $transitions.onStart({}, t => {
    $rootScope.pageLoaded = false;
  });

  $transitions.onSuccess({}, t => {
    universalAlert.removeAlert();
    const toState = t.to();
    $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
    if (toState.name !== 'search') {
      searchFactory.query.text = '';
    }
    $rootScope.pageLoaded = true;
  });

  $rootScope.$on('$locationChangeSuccess', function() {
    if ($window.Appcues) {
      $window.Appcues.page();
    }
  });
}

redirectInterceptor.$inject = ['$q', '$window'];
export function redirectInterceptor($q, $window) {
  return {
    request: function(config) {
      // do something on success
      config.headers.checksum = window.checksum;
      return config;
    },
    response: function(response) {
      // 299 is our special way to say "redirect" without $http actually following it
      if (response.status === 299) {
        let location = response.headers().location;
        if (location.indexOf('/login') > -1) {
          const pathStart = window.location.href.lastIndexOf(window.location.pathname);
          const returnUrl = window.location.href.substring(pathStart);
          if (returnUrl && returnUrl !== '/') {
            // Append current page return URL to the login URL
            // When they log in, it will automatically take them to this page
            // No need to add a return URL if we're at the root page
            location += `?returnUrl=${encodeURIComponent(returnUrl)}`;
          }
        }
        window.location.replace(location);
        return $q.reject(response);
      } else {
        return response;
      }
    }
  };
}

subsessionInterceptor.$inject = ['$location'];
export function subsessionInterceptor($sessionStorage) {
  return {
    request: function(config) {
      config.headers.subsession = window.subsession;
      return config;
    },
    response: function(response) {
      return response;
    }
  };
}

if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  };
}
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function(find, replace) {
    var str = this;
    return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
  };
}
