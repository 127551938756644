import { reactToAngularComponents } from '../../components/reactComponents/reactToAngular';
import { paymentModal, paymentModalController } from './payment_modal';
import { paymentsController } from './payments.ctrl';
import { paymentsFactory } from './payments.fac';
import { BillingPageView } from './reactPaymentsComponents/BillingPageView';
import { AccountCreditCardModal } from './reactPaymentsComponents/AccountCreditCardModal';
import { PaymentMethodModal } from './reactPaymentsComponents/PaymentMethodModal';

export const controllers = {
  paymentModalController,
  paymentsController
};

export const directives = {
  paymentModal
};

export const factories = {
  paymentsFactory
};

const reactComponents = {
  BillingPageView,
  AccountCreditCardModal,
  PaymentMethodModal
};

export const billingPageViewComponents = reactToAngularComponents(reactComponents);
