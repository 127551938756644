import * as React from 'react';
import MaterialButton from '@material-ui/core/Button';
import { styled } from '../../../utils/react/mui_styled';

export interface PrimaryButtonProps {
  title: string | React.ReactElement;
  /** @default false */
  outlined?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  id?: string;
}

const StyledButton = styled(MaterialButton)(({ variant }) =>
  variant !== 'contained'
    ? {
        '& .MuiButton-label': { fontWeight: 100 }
      }
    : {}
);

export const PrimaryButton = React.forwardRef<HTMLButtonElement, PrimaryButtonProps>(function PrimaryButton(
  { outlined, onClick, title, disabled, className, id },
  ref
) {
  return (
    <StyledButton
      ref={ref}
      variant={outlined ? 'outlined' : 'contained'}
      color={outlined ? 'primary' : undefined}
      onClick={onClick}
      disabled={disabled}
      className={className}
      id={id}
    >
      {title}
    </StyledButton>
  );
});
PrimaryButton.displayName = 'PrimaryButton';
