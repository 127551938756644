percentageNotMultiplied100.$inject = ['$filter', '$log'];

export function percentageNotMultiplied100($filter) {
  var DEFAULT_FRACTION_SIZE = 2;
  return function(value, fractionSize) {
    // if null is specified, show '--', as in 'no data'
    if (value == null) {
      return '--';
    }

    if (!angular.isNumber(value)) {
      value = parseFloat(value);
    }

    if (isNaN(value)) {
      value = 0;
    }

    if (value === Number.POSITIVE_INFINITY) {
      return '∞';
    }

    if (value === Number.NEGATIVE_INFINITY) {
      return '-∞';
    }

    if (angular.isUndefined(fractionSize) || fractionSize === null || !angular.isNumber(fractionSize)) {
      fractionSize = DEFAULT_FRACTION_SIZE;
    }

    if (
      fractionSize.toString() === 'NaN' ||
      fractionSize === Number.POSITIVE_INFINITY ||
      fractionSize === Number.NEGATIVE_INFINITY
    ) {
      fractionSize = DEFAULT_FRACTION_SIZE;
    }

    if (fractionSize < 0) {
      fractionSize = 0;
    }

    var result;
    result = $filter('number')(value, fractionSize) + '%';
    return result;
  };
}
