import { CreativeAsset } from '../../factories/types';

interface CreativeAssetEx extends CreativeAsset {
  id: number;
  type: string;
  preview_url?: string;
}

export class creativeRepositoryState {
  private _creativesAssetsList: CreativeAssetEx[];
  private rootScope: ng.IRootScopeService;

  /** @ngInject */
  constructor($rootScope: ng.IRootScopeService) {
    this._creativesAssetsList = [];
    this.rootScope = $rootScope;
  }

  refreshCreativeAssetsList() {
    this.rootScope.$broadcast('updatedCreativeAssets');
  }

  updateCreativeAssetsList(list: CreativeAsset[]) {
    this._creativesAssetsList = list.map(creativeAsset => ({
      ...creativeAsset,
      id: creativeAsset.asset_id,
      type: creativeAsset.asset_type,
      preview_url: creativeAsset.asset_type === 'IMAGE' ? creativeAsset.asset_source : undefined
    }));
  }

  get listOfCreativeAssets(): CreativeAssetEx[] {
    return this._creativesAssetsList;
  }
}
