import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const MobileIcon = createSvgIcon(
  <path
    id="Vector"
    d="M31 2H15C12.24 2 10 4.24 10 7V41C10 43.76 12.24 46 15 46H31C33.76 46 36 43.76 36 41V7C36 4.24 33.76 2 31 2ZM23 44C21.34 44 20 42.66 20 41C20 39.34 21.34 38 23 38C24.66 38 26 39.34 26 41C26 42.66 24.66 44 23 44ZM32 36H14V8H32V36Z"
    fill="#0A3F36"
  />,
  'GTMobile'
);

export default MobileIcon;
