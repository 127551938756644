import React, { useRef, useState } from 'react';
import { Popover, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { cardStatus } from './CardRow';
import { CreditCard } from './BillingPageView';

interface StatusCellProps {
  card: CreditCard;
  showCardFailureContent: (card: CreditCard) => boolean;
  cardRetryInProgress: boolean;
  retryCard: (card: CreditCard) => void;
}

export function StatusCell(props: StatusCellProps) {
  const { card, showCardFailureContent, cardRetryInProgress, retryCard } = props;

  const [openedPopover, setOpenedPopover] = useState(false);

  const popoverAnchor = useRef(null);
  const popoverContainer = useRef(null);

  const useStyles = makeStyles(styles => ({
    popover: {
      pointerEvents: 'none'
    },
    popoverContent: {
      pointerEvents: 'auto'
    }
  }));

  const classes = useStyles();

  const handlePopoverOpen = () => {
    setOpenedPopover(true);
  };

  const handlePopoverClose = () => {
    setOpenedPopover(false);
  };

  const showRetryButton = () => {
    return showCardFailureContent(card) && card.is_retryable;
  };

  const getStatusColorClass = (status: string) => {
    return `${status}-status`;
  };

  return (
    <td className={`card-status ${card.status === cardStatus.FAILED ? 'failed' : ''}`} ref={popoverContainer}>
      <span className={getStatusColorClass(card.status)}>{card.status}</span>
      {card.status === cardStatus.FAILED && (
        <>
          <span
            ref={popoverAnchor}
            className="glyphicon glyphicon-info-sign"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          />
          <Popover
            classes={{ paper: classes.popoverContent }}
            className={classes.popover}
            open={openedPopover}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            anchorEl={popoverAnchor.current}
            PaperProps={{ onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose }}
            container={popoverContainer.current}
          >
            <span>Visit our </span>
            <a target="_blank" href="https://help.groundtruth.com/hc/en-us/articles/16784099276307-Payment-Failures">
              help page
            </a>
            <span> for guidance</span>
          </Popover>
        </>
      )}
      {showRetryButton() && (
        <button
          id="btn-payments-retry-card"
          className="payments-retry-button button-v2 skin-white"
          onClick={() => retryCard(card)}
          disabled={cardRetryInProgress}
        >
          Retry
        </button>
      )}
    </td>
  );
}

StatusCell.propTypes = {
  card: PropTypes.object,
  showCardFailureContent: PropTypes.func,
  cardRetryInProgress: PropTypes.bool,
  retryCard: PropTypes.func
};
