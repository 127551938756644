import { useLocalStore } from 'mobx-react-lite';

/**
 * Creates a new store based on data provided in the source.
 * The `source` first is converted to an observable itself with strong reference.
 * Then the source is passed to the computed.
 * @param factory Factory that creates an observable value based on the provided source
 * @param source Source data to build computed store from. The source must be convertible to an observable.
 *
 * @remarks
 * Do not destructure argument for model factory, because it would destroy its observability.
 *
 * @example
 * ```tsx
 *
 * class FilterModel {
 *   model: StoreModel;
 *   constructor(model: StoreModel) {
 *     this.model = model;
 *   }
 *   get filteredData() {
 *     return this.model.data.filter(d => d.status === 'pending');
 *   }
 * }
 *
 * function FilteredDataComponent(props: { model: StoreModel }) {
 *   const model = useLocalModel(props => new FilterModel(props.model), props);
 *
 *   return <Observer>{() => model.filteredData.map(d => <Data data={d} />)}</Observer>;
 * }
 * ```
 */
export function useLocalModel<T extends object, S extends object>(factory: (source: S) => T, source: S): T;

/**
 * Creates a new store and expose it as a computed value.
 * @param factory Factory that creates an observable value.
 */
export function useLocalModel<T extends object>(factory: () => T): T;
export function useLocalModel<T extends object, S extends object>(factory: (source?: S) => T, source?: S): T {
  return useLocalStore(factory, source);
}
