locationsUpload.$inject = ['$filter', '$timeout'];

/**
 * @param {ng.IFilterService} $filter
 * @param {ng.ITimeoutService} $timeout
 */
export function locationsUpload($filter, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: '/ui/templates/static-templates/locations_upload.html',
    scope: {
      jobId: '=',
      productName: '=',
      driveTo: '=',
      jobStatus: '=',
      addressesCount: '=',
      importWithoutMatchOption: '=',
      importAddressesFunction: '=',
      searchBrandAndCategory: '=',
      setName: '=?',
      getStatusFn: '=',
      reloadFn: '=',
      cancelJobFn: '='
    },
    link: function(scope, el, attr) {},
    controller: /** @ngInject */ function($scope) {
      // if job id then show the 3rd page querying for the progress straight away
      // maybe that should be a function
      $scope.booleans = {
        showUploadProgress: false,
        uploadFileSteps: [true, false, false, false],
        importAllLocationsWithoutMatching: false,
        searchCategories: false,
        searchBrands: false,
        importProgress: false,
        setName: $scope.setName,
        appendToPreviousLocations: false,
        cancelProgress: false
      };

      $scope.autocompleteVars = {
        selectedBrandOrCategory: null,
        brandOrCategorySearchText: '',
        searchBrandsOrCategoryLabel: 'Brands or Categories'
      };

      $scope.vars = {
        uploadAddressesFile: null,
        uploadedAddressFile: null,
        locationsUploadProgressPercent: 0,
        uploader: {},
        matchBrands: [],
        matchCategories: [],
        locationsSetName: '',
        progressTimeout: null,
        errorMsg: '',
        errorMsgFromImport: '',
        cancelState: 'Cancel',
        cancelMessage: null
      };

      if (!$scope.setName) {
        $scope.setName = false;
      }

      $scope.uploadFileGoToStep = function(step_number) {
        var step_to_booleans;
        for (var i = 0; i < 4; i++) {
          $scope.booleans.uploadFileSteps[i] = false;
        }
        $scope.booleans.uploadFileSteps[step_number] = true;
      };

      $scope.cancelUploadJob = function() {
        // send message to backend to end the upload_job
        $scope.vars.cancelState = 'Canceling';
        $scope.booleans.cancelProgress = true;
        $scope.vars.cancelMessage = null;
        $scope
          .cancelJobFn($scope.jobId)
          .then(
            function(res) {
              //No exception happens
              //this does not mean cancel job has done
            },
            function(err) {
              //exception happens
              //not because the uploading files
              $scope.vars.cancelState = 'Cancel';
              $scope.booleans.cancelProgress = false;
              $scope.vars.cancelMessage = err.message;
            }
          )
          .finally(function() {});
      };

      $scope.refreshProgress = function() {
        $scope.vars.progressTimeout = $timeout(function() {
          $scope
            .getStatusFn($scope.jobId)
            .then(
              function(res) {
                var percent = res.progress;
                var progressPercent = parseFloat($filter('number')(percent, 2));
                $scope.vars.locationsUploadProgressPercent = progressPercent > 0 ? progressPercent : 0;
                if (
                  $scope.vars.locationsUploadProgressPercent &&
                  $scope.vars.locationsUploadProgressPercent >= 100.0 &&
                  $scope.reloadFn
                ) {
                  $timeout.cancel($scope.vars.progressTimeout);
                  $scope.reloadFn();
                } else if (progressPercent == -1) {
                  // error raised
                  $scope.vars.locationsUploadProgressPercent = 0.0;
                  $scope.booleans.cancelProgress = false;
                  $scope.vars.cancelState = 'Cancel';
                  $timeout.cancel($scope.vars.progressTimeout);
                  $scope.uploadFileGoToStep(0);
                  if ($scope.vars.cancelMessage != null && $scope.vars.cancelMessage != undefined) {
                    $scope.vars.errorMsg = $scope.vars.cancelMessage;
                  } else {
                    $scope.vars.errorMsg = res.message;
                  }
                } else if (progressPercent == -2) {
                  // on cancelling
                  $scope.refreshProgress();
                } else if (progressPercent == -3) {
                  // cancel job complete
                  $scope.booleans.cancelProgress = false;
                  $scope.vars.cancelState = 'Cancel';
                  $scope.vars.errorMsg = res.message;
                  $scope.vars.locationsUploadProgressPercent = 0.0;
                  $timeout.cancel($scope.vars.progressTimeout);
                  $scope.uploadFileGoToStep(0);
                } else {
                  $scope.refreshProgress();
                }
              },
              function() {}
            )
            .finally(function() {
              //$scope.refreshProgress();
            });
        }, 1000);
      };

      $scope.$on('$destroy', function() {
        if ($scope.vars.progressTimeout) {
          $timeout.cancel($scope.vars.progressTimeout);
          $scope.refreshProgress = function() {};
        }
      });

      $scope.showProgressScreen = function() {
        $scope.uploadFileGoToStep(2);
        $scope.refreshProgress();
      };

      if ($scope.jobId && $scope.jobStatus == 'In Progress') {
        $scope.showProgressScreen();
      }

      $scope.$watch(
        function() {
          return {
            jobId: $scope.jobId,
            jobStatus: $scope.jobStatus
          };
        },
        function() {
          if ($scope.jobId && $scope.jobStatus == 'In Progress') {
            $scope.showProgressScreen();
          } else {
            $scope.uploadFileGoToStep(0);
          }
        },
        true
      );

      $scope.selectBrandAndCategory = function(item) {
        var list = [];

        if (!item) {
          return;
        }

        // TODO: support on premise as well
        if (item.type === 'Brand') {
          list = $scope.vars.matchBrands;
        } else {
          list = $scope.vars.matchCategories;
        }

        for (let i = 0; i < list.length; i += 1) {
          if (item.id === list[i].id && item.sicCode === list[i].sicCode) {
            return;
          }
        }

        list.push(item);

        $scope.autocompleteVars.brandOrCategorySearchText = '';
      };

      $scope.handlePropogationOfBrandClick = function(event, item) {
        if (item.disabled) {
          event.stopPropagation();
        }
      };

      $scope.deleteBrandOrCategory = function(type, index) {
        var i;
        var list;

        if (type == 'Brand') {
          list = $scope.vars.matchBrands;
        } else {
          list = $scope.vars.matchCategories;
        }
        var item = list[index];

        if (!item) {
          return;
        }

        list.splice(index, 1);
      };

      $scope.importAddresses = function() {
        // something should return a promise and we decide if we go to the progress watch page.
        // 'from', cffilename, product, brands, categories, noMatchAddresses, $scope.adGroup
        $scope.vars.errorMsgFromImport = '';
        $scope.booleans.importProgress = true;
        $scope
          .importAddressesFunction(
            $scope.vars.matchBrands,
            $scope.vars.matchCategories,
            $scope.vars.uploadedAddressFile,
            $scope.booleans.importAllLocationsWithoutMatching,
            $scope.vars.locationsSetName,
            $scope.booleans.appendToPreviousLocations
          )
          .then(
            function() {
              //success at which point we have a job id for the adgroup
              //start polling
              //get 100% backend should have updated the adgroup
              $scope.showProgressScreen();
            },
            function(data) {
              //job id could not be generated
              $scope.vars.errorMsgFromImport = data;
            }
          )
          .finally(function() {
            $scope.booleans.importProgress = false;
          });
      };

      $scope.onFileAdded = function($flow, flowFile, message) {
        $scope.vars.errorMsg = '';
        $scope.booleans.showUploadProgress = true;
        $scope.vars.uploadAddressesFile = $flow.file.name;
      };

      $scope.fileAdded = function($flow, flowFile, message) {
        $scope.booleans.showUploadProgress = false;
        $scope.vars.uploadedAddressFile = window.mediaCdnUrl + JSON.parse(message).s3_path;

        // if not brands and categories, just post for job and get the id and start polling here

        $scope.uploadFileGoToStep(1);
      };

      $scope.importAllLocationsWithoutMatchingChanged = function() {
        if ($scope.booleans.importAllLocationsWithoutMatching) {
          $scope.vars.matchBrands.length = 0;
          $scope.vars.autoDetectBrandsForFileUpload = true;
        } else {
          $scope.vars.autoDetectBrandsForFileUpload = false;
        }
      };
    }
  };
}
