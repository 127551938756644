import * as React from 'react';
import { TextField as MaterialTextField, Box, InputLabel, IconButton, FormHelperText } from '@material-ui/core';
import { ClearIcon } from '../../../components/reactComponents/Icons';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { styled } from '../../../utils/react/mui_styled';

export interface TextFieldProps {
  id?: string;
  className?: string;
  /** If true, the outer box fill its container, otherwise it will use a default width */
  fullWidth?: boolean;
  /** Callback function that would be called when the value changes */
  onChange?: (text: string) => void;
  /** The label to be displayed on top of the input field */
  label?: string;
  /** The value we want on in the input field. This is a controlled component so the user has to change the value */
  value?: string | null;
  /** An error message to display if this value is a string or react node */
  errorMessage?: React.ReactNode;
  /** Callback function that would be called when the input goes out of focus */
  onBlur?: (event?: any) => void;
  /** The placeholder you want in the input field */
  placeholder?: string;
  multiline?: boolean;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  name?: string;
  isOutlined?: boolean;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: 'var(--color-primary-container)',
    '& .MuiInputBase-root.is-selected': {
      backgroundColor: 'var(--color-primary-container)',
      border: '1px solid var(--color-primary)'
    }
  },

  input: {
    '&:hover $endAdornment, &.Mui-focused $endAdornment': {
      visibility: 'visible'
    }
  },
  endAdornment: {
    visibility: 'hidden',
    padding: '4px!important',
    width: '20px !important',
    height: '20px !important'
  }
});

const Container = styled('div').extraProps<{ fullWidth?: boolean }>(({ fullWidth, ...others }) => others)(
  ({ fullWidth }) => ({
    marginTop: 16,
    marginBottom: 8,
    width: fullWidth ? '100%' : undefined,
    minWidth: 400
  })
);

export function TextField(props: TextFieldProps) {
  const {
    id,
    className,
    fullWidth,
    onChange,
    label,
    value = null,
    errorMessage = '',
    required = false,
    disabled = false,
    readonly = false,
    isOutlined = true,
    ...others
  } = props;

  const onClearValue: React.ComponentProps<typeof IconButton>['onClick'] = event => {
    if (onChange) onChange('');
  };

  function getClass() {
    return value?.length ? 'is-selected' : '';
  }

  const onChangeValue: React.ComponentProps<typeof MaterialTextField>['onChange'] = event => {
    if (onChange) onChange(event.target.value);
  };

  const classes = useStyles();
  return (
    // TODO the style props here is a temporary hack to fix the width.
    // It should be refactored when a common pattern is determined
    <Container id={id} className={className} fullWidth={fullWidth}>
      <Box display="flex" justifyContent="space-between">
        <InputLabel required={required}>{label}</InputLabel>
      </Box>
      <MaterialTextField
        label=""
        variant={isOutlined ? 'outlined' : 'standard'}
        value={value}
        onChange={onChangeValue}
        error={errorMessage ? true : false}
        fullWidth={true}
        required={required}
        disabled={disabled}
        InputProps={{
          readOnly: readonly,
          margin: 'dense',
          className: `${classes.input} ${getClass()}`,
          disableUnderline: true,
          endAdornment: !readonly && !disabled && value && (
            <IconButton size="small" onClick={onClearValue} className={classes.endAdornment}>
              <ClearIcon />
            </IconButton>
          )
        }}
        {...others}
      />
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Container>
  );
}
