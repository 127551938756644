export function paymentModal() {
  return {
    scope: {
      card: '=',
      stripe: '=',
      payment: '=',
      close: '&',
      submit: '&',
      handlers: '=',
      error: '=',
      inProgress: '='
    },
    restrict: 'E',
    replace: false,
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.new_payment_content.html',
    link: function(scope, element, attrs) {
      scope.stripe = Stripe(window.stripePublicKey);
      var elements = scope.stripe.elements();

      // Custom styling can be passed to options when creating an Element.
      var style = {
        base: {
          iconColor: '#ccc',
          color: '#333',
          fontWeight: 400,
          fontFamily: 'RobotoDraft, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
          fontSize: '14px',
          fontSmoothing: 'antialiased',

          '::placeholder': {
            color: '#cccccc'
          },
          ':-webkit-autofill': {
            color: '#cccccc'
          },
          ':focus': {
            color: '#83bc20'
          }
        },
        invalid: {
          iconColor: '#DD2C00',
          color: '#DD2C00'
        }
      };

      // Create an instance of the card Element
      scope.card = elements.create('card', { style: style, hidePostalCode: true });

      // Add an instance of the card Element into the `card-element` <div>
      scope.card.mount('#inp-newPaymentModal-cardElement');

      scope.card.addEventListener('change', function(event) {
        var displayError = document.getElementById('card-errors');

        if (event.error) {
          if (displayError) displayError.textContent = event.error.message;
          scope.cardError = true;
        } else {
          if (displayError) displayError.textContent = '';
          scope.cardError = false;
        }
        scope.$apply();
      });
    }
  };
}

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 * @ngInject
 */
export function paymentModalController($scope, $uibModalInstance, handlers) {
  $scope.handlers = handlers;
  $scope.inProgress = false;
  $scope.error = null;

  $scope.close = function() {
    $uibModalInstance.dismiss();
  };

  $scope.submit = function() {
    const zip = /** @type {HTMLInputElement} */ (document.getElementById('inp-newPaymentModal-paymentZip'));

    zip.addEventListener('change', function(event) {
      $scope.card.update({ value: { postalCode: zip.value } });
    });

    // Use Stripe.js to create a token. We can also pass
    // in the additional customer data we collected in our form.
    $scope.inProgress = true;
    $scope.stripe.createToken($scope.card, $scope.payment).then(function(result) {
      if (result.error) {
        // Inform the customer that there was an error
        var errorElement = document.getElementById('card-errors');
        if (errorElement) errorElement.textContent = result.error.message;
        $scope.inProgress = false;
      } else {
        // Send the token to your server
        $scope.error = null;
        $scope.handlers.submit(result.token.id).then(function(error) {
          if (error) {
            $scope.inProgress = false;
            $scope.error = error;
          } else {
            $uibModalInstance.close({
              card: $scope.payment,
              token: result.token
            });
          }
        });
      }
      $scope.$apply();
    });
  };
}
