import React from 'react';
import PropTypes from 'prop-types';

import './TopBarLogoV2.scss';

interface TopBarLogoV2Props {
  onLogoClick?: () => void;
  whiteLogo?: boolean;
}

export function TopBarLogoV2({ onLogoClick = () => {}, whiteLogo }: TopBarLogoV2Props) {
  return (
    <div className="top-bar-logo-v2" onClick={onLogoClick}>
      <div className="top-bar-logo__img-container">
        <img src="/ui/images/groundtruth-logo-re-skin.svg" />
      </div>
      <div className="top-bar-logo__text">
        {!whiteLogo && <img src="/ui/images/groundtruth-logo-text-re-skin.svg" />}
        {whiteLogo && <span className="top-bar-logo__text-org-name">GroundTruth</span>}
        <div className={whiteLogo ? 'text-white' : ''}>Ads Manager</div>
      </div>
    </div>
  );
}

TopBarLogoV2.propTypes = {
  onLogoClick: PropTypes.func,
  whiteLogo: PropTypes.bool
};
