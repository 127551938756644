CampaignBulkEditModalInstance.$inject = [
  '$scope',
  '$uibModalInstance',
  '$log',
  '$timeout',
  '$interval',
  'jobFactory',
  'generalUtilsFactory',
  'companyAndAccountFactory',
  'data',
  'handlers'
];

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 */
export function CampaignBulkEditModalInstance(
  $scope,
  $uibModalInstance,
  $log,
  $timeout,
  $interval,
  jobFactory,
  generalUtilsFactory,
  companyAndAccountFactory,
  data,
  handlers
) {
  const PROGRESS_INTERVAL_DELAY = data.PROGRESS_INTERVAL_DELAY;
  $scope.campaignBulkUploadVars = data.campaignBulkUploadVars;
  $scope.checkCampaignBulkUploadProgress = handlers.checkCampaignBulkUploadProgress;
  $scope.campaign = data.campaign;
  $scope.uploadModalVars = {
    uploadError: '',
    fileName: '',
    showTemplateDownloadProgressSpinner: false,
    showUploadingSpreadsheetProgressSpinner: false
  };
  $scope.uploadError = '';
  $scope.companyAndAccountFactory = companyAndAccountFactory;

  $scope.onFileAdded = function(file) {
    $scope.uploadModalVars.uploadError = '';
    var fileExtension = generalUtilsFactory.getFileExtensionFromString(file.name);
    if (!fileExtension || ['csv', 'xlsx'].indexOf(fileExtension) === -1) {
      $scope.uploadError = 'Please upload a .csv or .xlsx file.';
      return false;
    }
    $scope.uploadModalVars.fileName = file.name;
  };

  $scope.fileAdded = function($flow, flowFile, message) {
    $log.info('Successfully uploaded to s3.');
    let s3Url = window.mediaCdnUrl + JSON.parse(message).s3_path;
    const accountId = $scope.companyAndAccountFactory.selectedAccount?.id;
    jobFactory
      .createJob(jobFactory.jobTypes.CAMPAIGN_BULK_UPLOAD, s3Url, $scope.campaign.id, null, accountId, false)
      .then(
        function(result) {
          $scope.campaign.bulk_upload_job_id = result.job_id;
          $scope.campaignBulkUploadVars.intervalPromise = $interval(
            $scope.checkCampaignBulkUploadProgress,
            PROGRESS_INTERVAL_DELAY
          );
          $scope.campaignBulkUploadVars.changeBulkUploadState('campaignBulkUploadInProgress');
        },
        function(result) {
          $scope.uploadModalVars.uploadError = result.message;
        }
      );
  };

  $scope.closeModal = function() {
    // This function when not in progress will reset the starting page to
    // upload slides.
    $timeout(function() {
      if (!$scope.campaign.bulk_upload_job_id) {
        $scope.campaignBulkUploadVars.changeBulkUploadState('campaignBulkUploadSlides');
      }
    });
    $uibModalInstance.close();
  };

  $scope.downloadSampleCampaignBulkSpreadsheet = function() {
    $scope.uploadModalVars.showTemplateDownloadProgressSpinner = true;
    jobFactory
      .downloadSampleCampaignBulkSpreadsheet($scope.campaign.id)
      .then(function(result) {
        var link = document.createElement('a');
        link.setAttribute('href', result.file_url);
        link.setAttribute('download', 'sample_campaign.xlsx');
        link.click();
      })
      .finally(function() {
        $scope.uploadModalVars.showTemplateDownloadProgressSpinner = false;
      });
  };

  $scope.acknowledgeJobResults = function() {
    if ($scope.campaign.bulk_upload_job_id && $scope.campaignBulkUploadVars.states.campaignBulkUploadDone) {
      jobFactory.acknowledgeJobResults($scope.campaign.bulk_upload_job_id).then(function() {
        location.reload();
        $scope.campaign.bulk_upload_job_id = null;
        $uibModalInstance.close();
        $scope.campaignBulkUploadVars.changeBulkUploadState('campaignBulkUploadSlides');
        $scope.campaignBulkUploadVars.progress = 0.0;
        $scope.campaignBulkUploadVars.output_file_url = null;
        $scope.campaignBulkUploadVars.message = null;
        $scope.campaignBulkUploadVars.intervalPromise = null;
      });
    }
  };
}
