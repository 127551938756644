CampaignOrAdGroupViewCtrl.$inject = [
  '$scope',
  '$state',
  '$transitions',
  'campaignManagerFactory',
  'modalFactory',
  'featureFlagFactory'
];

/**
 *
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {import('@uirouter/angularjs').TransitionService} $transitions
 */
export function CampaignOrAdGroupViewCtrl(
  $scope,
  $state,
  $transitions,
  campaignManagerFactory,
  modalFactory,
  featureFlagFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;

  $scope.currentState = $state.current.name;
  $scope.campaignManagerFactory = campaignManagerFactory;

  const isAdgroupFound = () => !!campaignManagerFactory.selectedAdGroup;
  const isCampaignFound = () => !!campaignManagerFactory.selectedCampaign;

  $scope.adgroup_found = $state.current.name !== 'campaigns.adgroup' || isAdgroupFound();
  $scope.campaign_found = $state.current.name !== 'campaigns.campaign' || isCampaignFound();

  const transitionSuccessHandler = $transitions.onSuccess({}, t => {
    const toState = t.to();

    // The controller used to be re-instantiated when transitioned.
    // Now the same controller is reused, so need to update the state.
    // TODO: find a more sustainable solution.
    $scope.currentState = toState.name;

    if (toState.name !== 'campaigns.dashboard') {
      $scope.campaignManagerFactory.campaignsLoading = false;
    }

    if (toState.name === 'campaigns.adgroup') {
      $scope.adgroup_found = isAdgroupFound();
      $scope.campaign_found = true;
    } else if (toState.name === 'campaigns.campaign') {
      $scope.campaign_found = isCampaignFound();
      $scope.adgroup_found = true;
    }
  });

  const transitionStartHandler = $transitions.onStart({}, t => {
    const toState = t.to(),
      fromState = t.from();
    if (toState.name === fromState.name || toState.name === 'campaigns.dashboard') {
      $scope.campaignManagerFactory.campaignsLoading = false;
    } else {
      $scope.campaignManagerFactory.campaignsLoading = true;
    }
  });

  $scope.showCloneAdGroupConfirm = function(adGroup, campaignId) {
    var modalSetting = {
      title: 'Duplicate ad group?',
      message: 'Duplicating an ad group will create new ad group with all of the attributes of the original.',
      noText: 'Cancel',
      yesText: 'Duplicate'
    };

    var handlers = {
      confirm: function() {
        return campaignManagerFactory.cloneAdGroup(adGroup, campaignId).then(
          function() {
            $scope.$broadcast('adgroup-table-update');
          },
          function(msg) {
            return msg;
          }
        );
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.showCloneCreativeConfirm = function(creative) {
    var modalSetting = {
      title: 'Duplicate creative?',
      message: 'Duplicating a creative will create new creative with all of the attributes of the original.',
      noText: 'Cancel',
      yesText: 'Duplicate'
    };

    var handlers = {
      confirm: function() {
        return campaignManagerFactory.cloneCreative(creative, campaignManagerFactory.selectedCampaign.id).then(
          function() {
            $scope.$broadcast('adgroup-table-update');
          },
          function(msg) {
            return msg;
          }
        );
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.showDeleteCampaignConfirm = function(campaign) {
    var modalSetting = {
      title: 'Would you like to delete campaign ' + campaign.name + '?',
      message: 'All of the creatives will be removed. Are you sure?',
      noText: 'Cancel',
      yesText: 'Delete',
      yesClass: 'skin-red'
    };

    var handlers = {
      confirm: function() {
        return campaignManagerFactory.deleteCampaign(campaign).then(
          function() {
            $state.go('campaigns.dashboard');
          },
          function(msg) {
            return msg;
          }
        );
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.openState = function(state) {
    $state.go(state);
  };

  $scope.showDeleteAdGroupConfirm = function(adGroup) {
    var modalSetting = {
      title: 'Delete Ad Group',
      message: 'Deleting this ad group will permanently delete all associated attributes and creatives. Are you sure?',
      noText: 'Cancel',
      yesText: 'Delete',
      yesClass: 'skin-red'
    };

    var handlers = {
      confirm: function() {
        return campaignManagerFactory.deleteAdGroup(adGroup, campaignManagerFactory.selectedCampaign.id).then(
          function() {
            $scope.$broadcast('adgroup-table-update');
            $state.go('campaigns.campaign', { campaignId: campaignManagerFactory.selectedCampaign.id });
          },
          function(msg) {
            return msg;
          }
        );
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.showDeleteCreativeConfirm = function(creative) {
    var adGroups = campaignManagerFactory.selectedCampaign.adGroups;
    var adGroupId;

    if (campaignManagerFactory.selectedAdGroup) {
      adGroupId = campaignManagerFactory.selectedAdGroup.id;
    } else if (creative.placementId) {
      adGroupId = creative.placementId;
    }

    var adGroup = campaignManagerFactory.selectFromAdGroupsWithId(adGroups, adGroupId);

    var modalSetting = {
      title: 'Delete Creative',
      message: 'Deleting this creative will permanently delete all its associated information. Are you sure?',
      noText: 'Cancel',
      yesText: 'Delete',
      yesClass: 'skin-red'
    };

    var handlers = {
      confirm: function() {
        return campaignManagerFactory
          .deleteCreative(campaignManagerFactory.selectedCampaign.id, adGroup, creative.id)
          .then(
            function() {
              $scope.$broadcast('adgroup-table-update');
            },
            function(msg) {
              return msg;
            }
          );
      }
    };

    return modalFactory.simpleConfirm(modalSetting, handlers);
  };

  this.$onDestroy = () => {
    transitionStartHandler();
    transitionSuccessHandler();
  };
}
