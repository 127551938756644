export interface AlertEntry {
  alertMessage?: string;
  details?: string[];
  alertType: string;
  containerClass: string;
  onClose(): void;
}

export interface DetailedInfo {
  message?: string;
  details?: string[];
}

export class universalAlert {
  private _alert: AlertEntry | null;

  constructor() {
    this.removeAlert = this.removeAlert.bind(this);
    this._alert = null;
  }

  showAlert(alertMessage: string | DetailedInfo, alertType = 'error', containerClass = '', moreInfo = []) {
    if (typeof alertMessage === 'string') {
      this._alert = { alertMessage, alertType, containerClass, onClose: this.removeAlert };
    } else {
      this._alert = {
        alertMessage: alertMessage.message,
        details: alertMessage.details,
        alertType,
        containerClass,
        onClose: this.removeAlert
      };
    }
  }

  get alert(): AlertEntry | null {
    return this._alert;
  }

  removeAlert() {
    this._alert = null;
  }
}
