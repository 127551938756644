import * as React from 'react';
import MaterialIconButton from '@material-ui/core/IconButton';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { styled } from '@material-ui/styles';

const StyledMaterialIconButton = styled(MaterialIconButton)({
  padding: 0
});

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
  props: IconButtonProps,
  ref
) {
  return <StyledMaterialIconButton ref={ref} {...props}></StyledMaterialIconButton>;
});

IconButton.displayName = 'IconButton';
