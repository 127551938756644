export function xadSelectionList() {
  var directive = {
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.xad_selection_list.html',
    controller: SelectionListController,
    link: link,
    restrict: 'E',
    scope: {
      items: '=',
      listType: '@', // 'default', 'include', 'exclude', or 'toggle'
      sectionName: '@',
      listName: '@',
      tableUpdated: '=',
      itemRemovedCallback: '&', // called after item is removed
      tableUpdatedCallback: '&', // called each time the table is loaded/updated
      distanceUnit: '=?',
      eventName: '@',
      hideClearAll: '='
    }
  };
  return directive;

  function link(scope, element, attrs) {}
}

SelectionListController.$inject = ['$scope', 'adgroupTargetingFactory', 'tenantFactory', 'modalFactory'];

function SelectionListController($scope, adgroupTargetingFactory, tenantFactory, modalFactory) {
  const vm = this;
  vm.$onInit = function() {
    // this call is required when loading initial the page, we consider the tableUpdated is undefined since when
    // it is defined the watch functionality will be in place
    if (!!$scope.eventName) {
      $scope.loadPage($scope.currentPage);
    }
  };

  $scope.pageSize = 10;
  $scope.currentPage = 0;
  $scope.itemsInCurrentPage = [];
  if (!$scope.distanceUnit) {
    $scope.distanceUnit = 'miles';
  }
  $scope.entityDisplayTypes = adgroupTargetingFactory.entityDisplayTypes;
  $scope.semiSensitiveEligible = tenantFactory.tenant.semi_sensitive_eligible;
  $scope.semiSensitiveWarningMessage = adgroupTargetingFactory.semiSensitiveWarningMessage;
  if (!$scope.semiSensitiveEligible) {
    $scope.semiSensitiveWarningMessage['DriveTo'] = adgroupTargetingFactory.driveToSemiSensitiveLocationWarningMessage;
  }

  if (angular.isUndefined($scope.listType)) {
    $scope.listType = 'default';
  }

  $scope.showSemiSensitiveWarningMessage = function(item, listName, listType) {
    if (listType === 'exclude' && item.type != 'location_group') {
      return false;
    }
    if (listName === 'DriveTo' && $scope.semiSensitiveEligible) {
      return false;
    }
    return (
      (item.type === 'location_group' && item.is_sensitive) ||
      (['brand', 'category'].includes(item.type) && item.sensitivity === 2)
    );
  };

  $scope.removeItemByIndex = function(indexOnPage) {
    var index = indexOnPage + $scope.currentPage * $scope.pageSize;
    $scope.items.splice(index, 1);
    $scope.loadPage($scope.currentPage);
    if ($scope.itemRemovedCallback && angular.isFunction($scope.itemRemovedCallback)) {
      $scope.itemRemovedCallback();
    }
  };

  $scope.deleteAllSelections = function() {
    const modalSetting = {
      title: 'Are you sure you want to delete all selections?',
      message: 'By confirming this action, the table will be cleared.',
      noText: 'Cancel',
      yesText: 'Yes, Delete',
      yesClass: 'button--green'
    };
    const handlers = {
      confirm: function() {
        return Promise.resolve($scope.clearAllSelections());
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.clearAllSelections = function() {
    $scope.items = [];
  };

  $scope.loadPage = function(page) {
    if ($scope.tableUpdatedCallback && angular.isFunction($scope.tableUpdatedCallback)) {
      $scope.tableUpdatedCallback({ items: $scope.items });
    }

    if (!$scope.items || !$scope.items.length) {
      $scope.itemsInCurrentPage = [];
      $scope.currentPage = 0;
      return;
    }

    var begin, end;
    $scope.currentPage = page;
    begin = $scope.pageSize * $scope.currentPage;
    end = begin + $scope.pageSize;
    $scope.itemsInCurrentPage = $scope.items.slice(begin, end);

    // If page from argument is empty, recursively finds first populated page and loads.
    if ($scope.itemsInCurrentPage.length < 1) {
      $scope.loadPage(page - 1);
    }
  };

  $scope.$watch(
    function() {
      return $scope.tableUpdated;
    },
    function(newValue, oldValue) {
      if (!newValue) {
        return;
      }
      $scope.loadPage($scope.currentPage);
      $scope.tableUpdated = false;
    }
  );

  $scope.$on($scope.eventName, () => {
    $scope.loadPage($scope.currentPage);
  });
}
