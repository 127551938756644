import React from 'react';
import PropTypes from 'prop-types';
import { CardRowProps } from './CardRow';
import { Column } from './PaymentsTable';
import { CreditCard } from './BillingPageView';
import { StatusCell } from './StatusCell';
import { PrimaryCell } from './PrimaryCell';
import { ActionCell } from './ActionCell';

interface CardCellProps extends CardRowProps {
  column: Column;
  card: CreditCard;
  showCardFailureContent: (card: CreditCard) => boolean;
}

export function CardCell(props: CardCellProps) {
  const {
    column,
    card,
    cardTypeIcons,
    handleConnectedAccountsClick,
    customer,
    setDefaultCard,
    showCardFailureContent,
    retryCard,
    deleteCard,
    cardRetryInProgress,
    isLoading,
    isAccountLevelCreditCardsEnabled
  } = props;

  const getConnectedAccountsCellText = (card: CreditCard) => {
    const accounts = card.account_ids || [];
    return `${accounts.length} Account${accounts.length != 1 ? 's' : ''}`;
  };

  const renderCell = (column, card) => {
    switch (column.key) {
      case 'primary':
        return <PrimaryCell card={card} customer={customer} setDefaultCard={setDefaultCard} isLoading={isLoading} />;
      case 'card_details':
        return (
          <td>
            <img src={cardTypeIcons[card.card_type]} className="credit-card-icon" />
            <span>{`* ${card.last_4}`}</span>
          </td>
        );
      case 'expiry_date':
        return <td>{`${card.exp_month}/${card.exp_year}`}</td>;
      case 'connected_accounts':
        return (
          <td className="connected-accounts__cell" onClick={() => handleConnectedAccountsClick(card)}>
            <span>{getConnectedAccountsCellText(card)}</span>
          </td>
        );
      case 'status':
        return (
          <StatusCell
            card={card}
            showCardFailureContent={showCardFailureContent}
            cardRetryInProgress={cardRetryInProgress}
            retryCard={retryCard}
          />
        );
      case 'actions':
        return (
          <ActionCell
            card={card}
            customer={customer}
            deleteCard={deleteCard}
            isLoading={isLoading}
            isAccountLevelCreditCardsEnabled={isAccountLevelCreditCardsEnabled}
          />
        );
      default:
        return <td></td>;
    }
  };

  return renderCell(column, card);
}

CardCell.propTypes = {
  card: PropTypes.object,
  column: PropTypes.object,
  cardTypeIcons: PropTypes.object,
  handleConnectedAccountsClick: PropTypes.func,
  customer: PropTypes.object,
  setDefaultCard: PropTypes.func,
  showCardFailureContent: PropTypes.func,
  retryCard: PropTypes.func,
  deleteCard: PropTypes.func,
  cardRetryProgress: PropTypes.bool
};
