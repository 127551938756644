import { UserEditController } from './user_new_and_edit/user_edit.ctrl';
import {
  InviteUserModalInstanceCtrl,
  UserListViewController,
  newForTrue,
  yesNo
} from './user_dashboard/user_list_view.ctrl';
import { UserManagerCtrl } from './user_manager.ctrl';

export const controllers = {
  UserEditController,
  InviteUserModalInstanceCtrl,
  UserListViewController,
  UserManagerCtrl
};

export const filters = {
  newForTrue,
  yesNo
};
