import React from 'react';
import PropTypes from 'prop-types';
import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { CreditCard } from '../../../api/types/creditCard';
import { Customer } from '../../../api/types/customer';
import { CardTypeIcons } from './BillingPageView';

export interface Column {
  title: string;
  key: string;
  checkFeature?: () => boolean;
}

export interface PaymentsTableProps {
  cards: CreditCard[];
  paymentsInProgress: boolean;
  isFeatureEnabled: (featureName: string) => boolean;
  cardTypeIcons: CardTypeIcons;
  customer: Customer;
  setDefaultCard: (card: CreditCard) => void;
  retryCard: (card: CreditCard) => void;
  deleteCard: (card: CreditCard) => void;
  isSingleCard: boolean;
  handleConnectedAccountsClick: (card: CreditCard) => void;
  cardRetryInProgress: boolean;
  isLoading: boolean;
  isAccountLevelCreditCardsEnabled: boolean;
}

export function PaymentsTable(props: PaymentsTableProps) {
  const { cards, isFeatureEnabled, isAccountLevelCreditCardsEnabled } = props;

  const columns: Column[] = [
    { title: 'Primary', key: 'primary' },
    { title: 'Card Details', key: 'card_details' },
    { title: 'Expiration Date', key: 'expiry_date' },
    {
      title: 'Connected Accounts',
      key: 'connected_accounts',
      checkFeature: () => isAccountLevelCreditCardsEnabled
    },
    {
      title: 'Status',
      key: 'status',
      checkFeature: () => isFeatureEnabled('CREDIT_CARD_RETRY')
    },
    { title: '', key: 'actions' }
  ];

  return (
    <table className="xad-table">
      <thead>
        <TableHeader columns={columns} />
      </thead>
      <tbody>
        <TableBody
          {...props}
          cards={cards}
          columns={columns}
          paymentsInProgress={props.paymentsInProgress}
          isAccountLevelCreditCardsEnabled={isAccountLevelCreditCardsEnabled}
        />
      </tbody>
    </table>
  );
}

PaymentsTable.propTypes = {
  cards: PropTypes.array,
  isFeatureEnabled: PropTypes.func,
  paymentsInProgress: PropTypes.bool,
  cardTypeIcons: PropTypes.object,
  handleConnectedAccountsClick: PropTypes.func,
  customer: PropTypes.object,
  setDefaultCard: PropTypes.func,
  retryCard: PropTypes.func,
  deleteCard: PropTypes.func,
  isSingleCard: PropTypes.bool,
  cardRetryProgress: PropTypes.bool,
  isLoading: PropTypes.bool,
  isAccountLevelCreditCardsEnabled: PropTypes.bool
};
