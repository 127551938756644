import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { RenderInputParams } from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import { styled, withStyles } from '@material-ui/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CloseOpenIcon } from '../../../components/reactComponents/Icons';

interface DropdownBaseProps<T> {
  items: T[];
  fullWidth?: boolean;
  title: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  filterItems?(searchInput: string, options: T[]): T[];
  getItemTitle?(item: T): string;
  id?: string;
  className?: string;
}

export interface SingleDropdownProps<T> extends DropdownBaseProps<T> {
  onSelect?(selected: T | null): void;
  value?: T | null;
  defaultValue?: T | null;
}

export interface MultiselectDropdownProps<T> extends DropdownBaseProps<T> {
  onSelected?(selected: T[]): void;
  value?: T[] | null;
}

/**
 * In material UI paddings for different input components are not overridable hence the use of !important
 * use of !important should be avoided as much as possible.
 *
 * **Note**: Material UI `Autocomplete` is now defined to accept a union of props: for single select or multiple select.
 * That makes using `withStyles` impossible to infer the result, so a hack is applied to just to have the same original type.
 */
const StyledAutoComplete: typeof Autocomplete = withStyles({
  input: {
    padding: '0px 0px 0px 10px !important'
  },
  inputFocused: {
    padding: '0px 0px 0px 10px !important'
  },
  inputRoot: {
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
    marginTop: '0px',
    borderRadius: 'var(--border-radius)',
    border: '1px solid var(--color-outline)',
    height: 'var(--action-item-height)',
    '&:hover': {
      borderColor: 'var(--color-link)'
    },
    '&:focus-within': {
      borderColor: 'var(--color-primary)',
      backgroundColor: 'var(--color-primary-container)'
    }
  },
  clearIndicator: {
    color: 'var(--color-on-primary)'
  },
  popupIndicator: {
    marginRight: '10px'
  },
  root: {
    marginTop: 0,
    '& .MuiInputBase-root.Mui-error': {
      border: '1px solid var(--color-error)',
      backgroundColor: 'var(--color-error-container)',
      color: 'var(--color-error)',
      fontFamily: 'var(--font-family-regular)',
      fontSize: 'var(--font-size-small)',
      fontWeight: 'var(--font-weight-regular)',
      lineHeight: 'var(--line-height)'
    }
  }
})(Autocomplete as any) as any;
(StyledAutoComplete as any).displayName = 'StyledAutoComplete';

const StyledInputLabel = withStyles({})(InputLabel);

// This needs to be refactored into a styled helper with better typings.
// The current construct due to incorrect types defined in `@material-ui/styles`.
type ContainerDivProps = React.ComponentProps<'div'> & { fullWidth?: boolean };
const ContainerInnerDiv = ({ fullWidth, ...other }: ContainerDivProps) => <div {...other} />;
const ContainerDiv = styled(ContainerInnerDiv)<typeof ContainerInnerDiv, ContainerDivProps>(({ fullWidth }) => ({
  width: fullWidth ? '100%' : '400px',
  paddingTop: 10,
  '& .MuiFormHelperText-root.Mui-error': {
    color: 'var(--color-error)',
    fontFamily: 'var(--font-family-regular)',
    fontSize: 'var( --font-size-small)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--line-height)',
    marginTop: '-12px'
  }
}));
// Needed for testing
ContainerDiv.displayName = 'ContainerDiv';

function defaultGetItemTitle<T>(item: T): string {
  if (typeof item === 'string') {
    return item;
  } else if (item && 'title' in item) {
    return String(item['title']);
  } else {
    return JSON.stringify(item);
  }
}

export default function DropdownBase<T>(props: SingleDropdownProps<T>) {
  const {
    items,
    placeholder = '',
    title,
    error = false,
    errorMessage = '',
    value,
    fullWidth = false,
    defaultValue,
    onSelect,
    filterItems = defaultFilter,
    getItemTitle = defaultGetItemTitle,
    id,
    className
  } = props;

  function defaultFilter(searchInput: string, options: T[]): T[] {
    return options.filter(option =>
      getItemTitle(option)
        .toLowerCase()
        .includes(searchInput.toLowerCase())
    );
  }

  const handleChange = React.useMemo(
    () => (onSelect ? (event: unknown, value: T | null) => onSelect(value) : undefined),
    [onSelect]
  );
  const filterOptions = React.useCallback((items: T[], { inputValue }) => filterItems(inputValue, items), [
    filterItems
  ]);

  const StyledAutoCompleteDropdown: typeof StyledAutoComplete = withStyles({
    inputRoot: {
      background: value?.length ? 'var(--color-primary-container)' : 'var(--color-background)',
      border: value?.length ? '1px solid var(--color-primary)' : '1px solid var(--color-outline)'
    }
  })(StyledAutoComplete as any) as any;

  const renderInput = React.useCallback(
    (params: RenderInputParams) => (
      <TextField
        {...params}
        error={error}
        label=""
        placeholder={placeholder}
        fullWidth={true}
        margin="normal"
        variant="standard"
        InputProps={{ ...params.InputProps, disableUnderline: true }}
      />
    ),
    [error, placeholder]
  );

  return (
    <ContainerDiv fullWidth={fullWidth} id={id} className={className}>
      <Box display="flex" justifyContent="space-between">
        <StyledInputLabel>{title}</StyledInputLabel>
      </Box>

      <StyledAutoCompleteDropdown
        multiple={false}
        value={value}
        defaultValue={defaultValue ?? undefined}
        size="medium"
        disablePortal={true}
        onChange={handleChange}
        options={items}
        filterOptions={filterOptions}
        getOptionLabel={getItemTitle}
        renderInput={renderInput}
        className="autocomplete-dropdown"
        popupIcon={<CloseOpenIcon />}
      />
      {error && <FormHelperText error>{errorMessage}</FormHelperText>}
    </ContainerDiv>
  );
}

/**
 * Roadmap:
 * - Write more unit tests as the usage of the component increases
 * - Add icon support
 * - Add proper multi select support
 */
