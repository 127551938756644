import { ngWatch } from './ng/watch';
import { companyAndAccountFactory } from '../../../utils/services';

const selectedTenant = ngWatch(() => companyAndAccountFactory.selectedTenant);
const selectedCompany = ngWatch(() => companyAndAccountFactory.selectedCompany);
const selectedAccount = ngWatch(() => companyAndAccountFactory.selectedAccount);
const companiesAccounts = ngWatch(() => companyAndAccountFactory.companiesAccounts);

export const companyAndAccount = {
  get selectedTenant() {
    return selectedTenant.value;
  },
  get selectedCompany() {
    return selectedCompany.value;
  },
  get selectedAccount() {
    return selectedAccount.value;
  },
  get companiesAccounts() {
    return companiesAccounts.value;
  }
};
