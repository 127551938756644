import template from './creative_list.html';
import {
  SelfServeDataService,
  CampaignManagerService,
  GeneralUtilsService,
  CreativeTemplatesFactory,
  CompanyAndAccountFactoryService,
  FeatureFlagService,
  StaticDataService
} from '../../../../factories/types';
import {
  AdgroupExtraType,
  AdgroupCreativeService,
  CreativeExtraType,
  HTML5CMSConfig,
  ErrorResponse
} from '../adgroup_creative.serv';
import { CreativeType } from '../../../../api/types/creative';
import { BudgetType } from '../../../../api/types/common';
import {
  CreativeVideoTranscoderStatusResponse,
  CreativeVideoTranscoderStatusResponse_status,
  CreativeVideoTranscoderStatusListResponse,
  CreativeHTML5StatusResponse,
  CreativeHTML5StatusResponse_status,
  CreativeResponse_status,
  CreativeHTML5StatusListResponse
} from '../../../../api/http/creative';
import { MacrosResponse } from '../../../../api/types';

import { isEmpty } from 'lodash';

export function creativeList(): ng.IDirective {
  return {
    template: template,
    bindToController: true,
    controller: CreativeListController,
    controllerAs: 'vm',
    restrict: 'E',
    link: () => {},
    scope: {
      creativeListObject: '='
    }
  };
}

interface CreativeListObjectType {
  saveCreative: ((errors: ErrorResponse, skipIncompleteCreatives: boolean) => void) | null;
}

export class CreativeListController implements ng.IOnInit {
  $scope: ng.IScope;
  $interval: ng.IIntervalService;
  $q: ng.IQService;
  $location: ng.ILocationService;

  selfServeDataFactory: SelfServeDataService;
  campaignManagerFactory: CampaignManagerService;
  generalUtilsFactory: GeneralUtilsService;
  creativeTemplatesFactory: CreativeTemplatesFactory;
  companyAndAccountFactory: CompanyAndAccountFactoryService;
  featureFlagFactory: FeatureFlagService;
  adgroupCreativeService: AdgroupCreativeService;
  staticDataFactory: StaticDataService;

  adgroup = {} as AdgroupExtraType;
  totalWeight = 0;
  creativeVideoTranscodeStatusInterval = 10000; // 10s
  creativeZipFileStatusInterval = 10000; // 10s

  creativeIframes = {};
  html5CreativeHeights = {};
  creativeOptions = {};
  loadingProgress = true;
  getCreativeVideoTranscodeStatusesPromise!: Promise<CreativeVideoTranscoderStatusListResponse>;
  getHTML5JobStatusesPromise!: Promise<CreativeHTML5StatusListResponse>;
  minCreativeFlightDate!: Date;
  maxCreativeFlightDate!: Date;
  readonly incompleteCreativeWarning = 'Please fill in the data to activate this creative';
  creativeListObject: CreativeListObjectType = { saveCreative: null };
  macros: MacrosResponse = {};
  readonly html5CreativeMaxHeight = 300;
  haveCreativesChanged = false;
  autoOpened = false;
  isPlacementDatesChange = false;

  /**@ngInject */
  constructor(
    $scope: ng.IScope,
    $interval: ng.IIntervalService,
    $q: ng.IQService,
    $location: ng.ILocationService,
    selfServeDataFactory: SelfServeDataService,
    campaignManagerFactory: CampaignManagerService,
    generalUtilsFactory: GeneralUtilsService,
    creativeTemplatesFactory: CreativeTemplatesFactory,
    companyAndAccountFactory: CompanyAndAccountFactoryService,
    featureFlagFactory: FeatureFlagService,
    AdgroupCreativeService: AdgroupCreativeService,
    staticDataFactory: StaticDataService
  ) {
    this.$scope = $scope;
    this.$interval = $interval;
    this.$q = $q;
    this.$location = $location;
    this.selfServeDataFactory = selfServeDataFactory;
    this.campaignManagerFactory = campaignManagerFactory;
    this.generalUtilsFactory = generalUtilsFactory;
    this.creativeTemplatesFactory = creativeTemplatesFactory;
    this.companyAndAccountFactory = companyAndAccountFactory;
    this.featureFlagFactory = featureFlagFactory;
    this.adgroupCreativeService = AdgroupCreativeService;
    this.staticDataFactory = staticDataFactory;
    this.$onInit = this.$onInit.bind(this);
    this.manageCreativeVideoTranscodeStatuses = this.manageCreativeVideoTranscodeStatuses.bind(this);
    this.manageCreativeHTML5JobStatuses = this.manageCreativeHTML5JobStatuses.bind(this);
    this.setUpCreatives = this.setUpCreatives.bind(this);
    this.setUpImageCreative = this.setUpImageCreative.bind(this);
    this.setUpHTMLCreative = this.setUpHTMLCreative.bind(this);
    this.setUpHTML5NewCreative = this.setUpHTML5NewCreative.bind(this);
    this.saveCreative = this.saveCreative.bind(this);
    this.isVideoCreativeInProgress = this.isVideoCreativeInProgress.bind(this);
    this.addWatcher = this.addWatcher.bind(this);
    this.updatePlacementDates = this.updatePlacementDates.bind(this);
  }

  isCampaignDailyBudget = () => {
    return this.campaignManagerFactory.selectedCampaign.budgetType == BudgetType.DAILY;
  };

  async $onInit(noAutoOpenModal = true) {
    await this.fetchAndSetData();
    if (this.shouldOpenCreativeModal(noAutoOpenModal)) {
      this.openCreativeModal();
    }
    this.openCreativeIfExists();
    this.creativeListObject.saveCreative = this.saveCreative;
    this.addWatcher();
  }

  copyWithoutIgnoredProps(creatives: CreativeType[]) {
    return creatives.map(function(creative) {
      //@ts-ignore
      const { scaledHeight, transformScale, creativeSizeRatio, ...copy } = creative;
      return copy;
    });
  }

  addWatcher() {
    this.$scope.$watch(
      () =>
        JSON.stringify(this.copyWithoutIgnoredProps(this.adgroup.creatives)) +
        JSON.stringify(this.adgroup.rotateEvenly),
      (newVal, oldVal) => {
        if (!angular.equals(oldVal, newVal) || this.isPlacementDatesChange) {
          this.haveCreativesChanged = true;
        }
      },
      true
    );
  }

  openCreativeIfExists() {
    const { creativeId } = this.$location.search();
    if (!this.autoOpened && creativeId) {
      const creative = this.adgroup.creatives.find(c => c.id === parseInt(creativeId ?? ''));
      if (creative) {
        this.autoOpened = true;
        this.openCreativeModal(creative);
      } else {
        //remove the search param if the creative doesn't exist in the adgroup
        this.$location.search('creativeId', null);
      }
    }
  }

  updatePlacementDates(creative: CreativeExtraType) {
    this.adgroupCreativeService.usePlacementDates(creative);
    this.isPlacementDatesChange = !this.isPlacementDatesChange;
  }

  shouldOpenCreativeModal(noAutoOpenModal: boolean): boolean {
    return (
      !noAutoOpenModal && isEmpty(this.adgroup.creatives) && this.featureFlagFactory.isFeatureEnabled('CREATIVE_WRITE')
    );
  }

  showAddCreatives() {
    return (
      (!this.adgroup.creatives || !this.adgroup.creatives.length) &&
      !this.loadingProgress &&
      this.featureFlagFactory.isFeatureEnabled('CREATIVE_WRITE')
    );
  }

  handleDatesChange = (startDate, endDate, index) => {
    if (startDate != null) {
      this.adgroup.creatives[index].startDate = startDate;
    }
    this.adgroup.creatives[index].endDate = endDate;
    this.$scope.$apply();
  };

  handleCreativeOptionsChangeCallback = creativeData => {
    this.handleCreativeOptionsChange(
      creativeData.startDate,
      creativeData.endDate,
      creativeData.freqCapValue,
      creativeData.freqCapDuration,
      creativeData.id
    );
  };

  handleCreativeOptionsChange = (startDate, endDate, freqCapValue, freqCapDuration, creativeId) => {
    const currentCreativeOptions = this.creativeOptions[creativeId] || {};
    this.creativeOptions[creativeId] = {
      startDate: startDate || currentCreativeOptions.startDate,
      endDate: endDate || currentCreativeOptions.endDate,
      freqCapValue: freqCapValue ?? currentCreativeOptions.freqCapValue,
      freqCapDuration: freqCapDuration ?? currentCreativeOptions.freqCapDuration
    };
  };

  async fetchAndSetData() {
    angular.merge(this.adgroup, this.selfServeDataFactory.nullAdGroup);

    this.adgroup.rotateEvenly = true;
    this.totalWeight = 0;
    this.loadingProgress = true;

    await this.staticDataFactory.getMacros(this.companyAndAccountFactory.selectedAccount);
    this.macros = this.staticDataFactory.macroMapTable;

    const selectedAdGroupPromise = this.campaignManagerFactory.getSelectedAdGroup();
    const LPLocationPromise = this.campaignManagerFactory.getLpLocation(
      this.campaignManagerFactory.selectedCampaign.id,
      this.campaignManagerFactory.selectedAdGroup.id
    );

    try {
      const [adgroup, LPLocation] = await this.$q.all([selectedAdGroupPromise, LPLocationPromise]);

      this.generalUtilsFactory.nestedUpdateInPlace(adgroup, this.adgroup);

      this.minCreativeFlightDate =
        this.generalUtilsFactory.getCorrectedJSdateFromString(window.adGroup.timeframe.start) ||
        this.minCreativeFlightDate;
      this.maxCreativeFlightDate =
        this.generalUtilsFactory.getCorrectedJSdateFromString(window.adGroup.timeframe.end) ||
        this.maxCreativeFlightDate;

      if (this.adgroup?.creatives?.length === 1) {
        this.adgroup.creatives[0].weight = 1;
      }

      this.adgroup.LpLocation = LPLocation;

      this.setUpCreatives(this.adgroup);
      this.updateTotalCreativeWeight();
    } catch (e) {
      console.error(e);
    } finally {
      if (this.getCreativeVideoTranscodeStatusesPromise) {
        this.$interval.cancel(this.getCreativeVideoTranscodeStatusesPromise);
      }

      if (this.getHTML5JobStatusesPromise) {
        this.$interval.cancel(this.getHTML5JobStatusesPromise);
      }

      this.getCreativeVideoTranscodeStatusesPromise = this.$interval(
        this.manageCreativeVideoTranscodeStatuses,
        this.creativeVideoTranscodeStatusInterval
      ) as Promise<CreativeVideoTranscoderStatusListResponse>;

      this.getHTML5JobStatusesPromise = this.$interval(
        this.manageCreativeHTML5JobStatuses,
        this.creativeZipFileStatusInterval
      ) as Promise<CreativeHTML5StatusListResponse>;

      this.loadingProgress = false;
      this.generalUtilsFactory.safeApply(this.$scope);
    }
  }

  async manageCreativeVideoTranscodeStatuses() {
    const inProgressCreatives = this.adgroupCreativeService.getVideoInProgressCreatives(this.adgroup.creatives);
    if (!inProgressCreatives.length) {
      this.$interval.cancel(this.getCreativeVideoTranscodeStatusesPromise);
      return;
    }

    const data = {
      adgroupId: this.adgroup.id,
      creativeIds: inProgressCreatives.map(creative => creative.id).join(',')
    };

    try {
      const creatives = await this.campaignManagerFactory.getCreativeVideoTranscodeStatuses(data);
      this.updateVideoCreativeTranscodeStatuses(creatives.statuses!);
    } catch (e) {
      console.error(e);
    } finally {
      this.generalUtilsFactory.nestedUpdateInPlace(this.adgroup, this.campaignManagerFactory.selectedAdGroup);
      this.generalUtilsFactory.safeApply(this.$scope);
    }
  }

  async manageCreativeHTML5JobStatuses() {
    const inProgressCreatives = this.adgroupCreativeService.getHTML5InProgressCreatives(this.adgroup.creatives);
    if (!inProgressCreatives.length) {
      this.$interval.cancel(this.getHTML5JobStatusesPromise);
      return;
    }
    const data = {
      adgroupId: this.adgroup.id,
      creativeIds: inProgressCreatives.map(creative => creative.id).join(',')
    };
    try {
      const creatives = await this.campaignManagerFactory.getHTML5JobStatuses(data);
      this.updateHTML5CreativeStatusesAndPreview(creatives.statuses!);
    } catch (e) {
      console.error(e);
    } finally {
      this.generalUtilsFactory.nestedUpdateInPlace(this.adgroup, this.campaignManagerFactory.selectedAdGroup);
      this.generalUtilsFactory.safeApply(this.$scope);
    }
  }

  private updateHTML5CreativeStatusesAndPreview(creativeStatuses: CreativeHTML5StatusResponse[]) {
    const unpackedCreatives = creativeStatuses.map(creative => [creative.creative_id, creative]) as [
      number,
      CreativeHTML5StatusResponse
    ][];
    const creativeHTML5StatusMap: Map<number, CreativeHTML5StatusResponse> = new Map(unpackedCreatives);
    const inProgressCreatives = this.adgroupCreativeService.getHTML5InProgressCreatives(this.adgroup.creatives);
    inProgressCreatives.forEach(creative => {
      const creativeHTML5JobData = creativeHTML5StatusMap.get(creative.id!);
      creative.html5_creative.status = creativeHTML5JobData?.status;
      if (creative.html5_creative.status === CreativeHTML5StatusResponse_status.DONE) {
        creative.status = 'Active';
        this.creativeTemplatesFactory.getCmsConfigForCreative(this.adgroup.id, creative.id).then(creativeData => {
          creative!.cmsConfig = creativeData['cms_config'];
          creative.creativeSize = creativeData['creative_size'];
          this.html5CreativeHeights[creative.id as number] = parseInt(creativeData['creative_size'].split('x')[1]);
          if (this.html5CreativeHeights[creative.id as number] > this.html5CreativeMaxHeight) {
            this.html5CreativeHeights[creative.id as number] = this.html5CreativeMaxHeight;
          }
          const data = this.creativeTemplatesFactory.getHTMLTagForHTML5CreativePreview(creativeData['cms_config']);
          this.creativeIframes[creative.id as number] = data;
          this.generalUtilsFactory.nestedUpdateInPlace(this.adgroup, this.campaignManagerFactory.selectedAdGroup);
          this.generalUtilsFactory.safeApply(this.$scope);
        });
      }
    });
  }

  private updateVideoCreativeTranscodeStatuses(creativeStatuses: CreativeVideoTranscoderStatusResponse[]) {
    const unpackedCreatives = creativeStatuses.map(creative => [creative.creative_id, creative]) as [
      number,
      CreativeVideoTranscoderStatusResponse
    ][];
    const creativeTranscodeStatusMap: Map<number, CreativeVideoTranscoderStatusResponse> = new Map(unpackedCreatives);

    const inProgressCreatives = this.adgroupCreativeService.getVideoInProgressCreatives(this.adgroup.creatives);
    inProgressCreatives.forEach(creative => {
      const creativeTranscodeData = creativeTranscodeStatusMap.get(
        creative.id!
      ) as CreativeVideoTranscoderStatusResponse;
      if (creativeTranscodeData.status === CreativeVideoTranscoderStatusResponse_status.DONE) {
        delete creative.video_transcoder;
        creative!.cmsConfig!.vastTagUrl = creativeTranscodeData.vast_tag_url;
      } else {
        creative.video_transcoder.status = creativeTranscodeData.status;
        creative.video_transcoder.message = creativeTranscodeData.message;
      }
    });
  }

  setUpCreatives(adgroup: AdgroupExtraType) {
    for (let i = 0; i < adgroup.creatives.length; i++) {
      adgroup.creatives[i].placementId = adgroup?.id;

      const creativeCmsConfig = adgroup.creatives[i].cmsConfig;
      if (!adgroup.creatives[i].startDate) {
        adgroup.creatives[i].usePlacementDates = true;
      }

      this.setUpImageCreative(i, adgroup, creativeCmsConfig);
      this.setUpHTMLCreative(i, adgroup);
      this.setUpHTML5NewCreative(i, adgroup);

      if (!adgroup.creatives[i].weight) {
        adgroup.creatives[i].weight = 1;
      }

      if (adgroup.creatives[i].weight > 1) {
        adgroup.rotateEvenly = false;
      }
      this.totalWeight += adgroup.creatives[i].weight;
      adgroup.creatives[i].frequencyUnlimited = true;
      if (adgroup.creatives[i].freqCapValue || adgroup.creatives[i].freqCapDuration) {
        adgroup.creatives[i].frequencyUnlimited = false;
      }
      if (
        adgroup.creatives[i].startDate &&
        (adgroup.creatives[i].endDate || this.isCampaignDailyBudget()) &&
        !adgroup.creatives[i].usePlacementDates
      ) {
        adgroup.creatives[i].usePlacementDates = false;
      } else {
        adgroup.creatives[i].usePlacementDates = true;
        this.adgroupCreativeService.usePlacementDates(adgroup.creatives[i]);
      }

      const creativeId = adgroup.creatives[i]?.id;
      const creativeOptions = creativeId && this.creativeOptions[creativeId];
      if (creativeOptions?.startDate && creativeOptions?.endDate) {
        adgroup.creatives[i].usePlacementDates = false;
        adgroup.creatives[i].startDate = creativeOptions.startDate;
        adgroup.creatives[i].endDate = creativeOptions.endDate;
      }
      if (creativeOptions?.freqCapValue || creativeOptions?.freqCapDuration) {
        adgroup.creatives[i].frequencyUnlimited = false;
        adgroup.creatives[i].freqCapValue = creativeOptions.freqCapValue;
        adgroup.creatives[i].freqCapDuration = creativeOptions.freqCapDuration;
      }
    }
  }

  setUpImageCreative(
    i: number,
    adgroup: AdgroupExtraType,
    creativeCmsConfig: CreativeExtraType['cmsConfig'] | null | undefined
  ) {
    if (adgroup.creatives[i].creativeType === 'IMAGE') {
      if (creativeCmsConfig && adgroup.creatives[i].cmsConfig?.bannerUrl) {
        const imageObj = new Image();

        imageObj.onload = (function(obj, index) {
          return function() {
            const width = obj.width;
            const height = obj.height;

            if (adgroup.creatives[index]?.creativeSize!.indexOf('x') > 0) {
              const creativeWidth = parseInt(adgroup.creatives[index]?.creativeSize!.split('x')[0]);

              if (creativeWidth != width) {
                adgroup.creatives[index].creativeSizeRatio = creativeWidth / width;

                // width is not same so scale the css properties accordingly
                const creativeCssDict = this.generalUtilsFactory.stringCssToDictCss(
                  adgroup.creatives[index].cmsConfig!.cssCustomText
                );
                this.adgroupCreativeService.scaleCreativeDimensions(
                  adgroup.creatives[index].creativeSizeRatio,
                  creativeCssDict
                );
                adgroup.creatives[index].cmsConfig!.cssCustomText = this.generalUtilsFactory.dictCssToStringCss(
                  creativeCssDict
                );
              } else {
                adgroup.creatives[index].creativeSizeRatio = 1;
              }
            }

            if (width > 320) {
              adgroup.creatives[index].transformScale = 320 / Math.floor(width);
            } else {
              adgroup.creatives[index].transformScale = 1;
            }
            adgroup.creatives[index].scaledHeight = adgroup.creatives[index].transformScale * Math.floor(height);
          };
        })(imageObj, i).bind(this);
        imageObj.src = adgroup.creatives[i].cmsConfig?.bannerUrl ?? '';
      }
    }
  }

  getStyles(customText: string): Record<string, string> {
    return this.generalUtilsFactory.stringCssToDictCss(customText);
  }

  setUpHTMLCreative(i: number, adgroup: AdgroupExtraType) {
    if (adgroup.creatives[i].creativeType === 'HTML5') {
      const htmlRenderer = (creative: CreativeExtraType) => {
        const cmsConfig = creative.cmsConfig as HTML5CMSConfig;
        // creatives are saved without # in the db, so this adds # to the color codes
        if (!cmsConfig.businessNameColor?.startsWith('#') && cmsConfig.businessNameColor) {
          cmsConfig.businessNameColor = '#' + cmsConfig.businessNameColor;
        }
        if (
          !cmsConfig.distanceColor?.startsWith('#') &&
          cmsConfig.distanceColor != 'none' &&
          cmsConfig.businessNameColor
        ) {
          cmsConfig.distanceColor = '#' + cmsConfig.distanceColor;
        }
        if (!cmsConfig.captionColor?.startsWith('#') && cmsConfig.businessNameColor) {
          cmsConfig.captionColor = '#' + cmsConfig.captionColor;
        }
        if (!cmsConfig.backgroundColor?.startsWith('#') && cmsConfig.businessNameColor) {
          cmsConfig.backgroundColor = '#' + cmsConfig.backgroundColor;
        }
        this.creativeTemplatesFactory
          .getHtml5BannerPreview(
            cmsConfig.name,
            cmsConfig.caption,
            cmsConfig.backgroundColor,
            cmsConfig.backgroundImage,
            cmsConfig.distanceToggle,
            cmsConfig.logoImage,
            cmsConfig.scrollingTextToggle,
            cmsConfig.businessNameColor,
            cmsConfig.captionColor,
            cmsConfig.distanceColor
          )
          .then((renderedHtml5Preview: string) => {
            this.creativeIframes[creative.id as number] = renderedHtml5Preview;
          });
      };
      htmlRenderer(adgroup.creatives[i]);
    }
  }

  setUpHTML5NewCreative(i: number, adgroup: AdgroupExtraType) {
    if (adgroup.creatives[i].creativeType === 'HTML5_NEW') {
      const html5Preview = this.creativeTemplatesFactory.getHTMLTagForHTML5CreativePreview(
        adgroup.creatives[i].cmsConfig
      );
      this.html5CreativeHeights[adgroup.creatives[i].id as number] = parseInt(
        adgroup.creatives[i].creativeSize.split('x')[1]
      );
      if (this.html5CreativeHeights[adgroup.creatives[i].id as number] > this.html5CreativeMaxHeight) {
        this.html5CreativeHeights[adgroup.creatives[i].id as number] = this.html5CreativeMaxHeight;
      }
      this.creativeIframes[adgroup.creatives[i].id as number] = html5Preview;
    }
  }

  frequencyCappingChange(creative: CreativeExtraType) {
    this.adgroupCreativeService.frequencyCappingChange(creative);
  }

  openCreativeModal(creative: CreativeType | undefined = undefined) {
    this.adgroupCreativeService.openCreativeModal(creative, this.adgroup, this.$onInit);
  }

  deleteCreative(creative: CreativeType) {
    this.adgroupCreativeService.deleteCreative(creative, this.$onInit);
  }

  cloneCreative(creative: CreativeType) {
    this.adgroupCreativeService.cloneCreative(creative, this.$onInit);
  }

  pauseCreative(creative: CreativeType) {
    this.adgroupCreativeService.pauseCreative(creative, this.$onInit);
  }

  activateCreative(creative: CreativeType) {
    this.adgroupCreativeService.activateCreative(creative, this.$onInit);
  }

  updateTotalCreativeWeight() {
    this.totalWeight = this.adgroupCreativeService.getTotalCreativeWeight(this.adgroup);
  }

  $onDestroy() {
    this.$interval.cancel(this.getCreativeVideoTranscodeStatusesPromise);
    this.$interval.cancel(this.getHTML5JobStatusesPromise);
  }

  async saveCreative(errors: ErrorResponse, skipIncompleteCreatives: boolean) {
    const d = this.$q.defer();
    try {
      await this.adgroupCreativeService.saveCreative(
        this.adgroup,
        errors,
        skipIncompleteCreatives,
        this.haveCreativesChanged
      );
      d.resolve();
    } catch (_errors) {
      d.reject(_errors);
    }
    return d.promise;
  }

  isVideoCreativeInProgress(creative: CreativeExtraType): boolean {
    return this.adgroupCreativeService.isVideoCreativeInProgress(creative);
  }

  isHTML5CreativeInProgress(creative: CreativeExtraType): boolean {
    return [CreativeHTML5StatusResponse_status.NEW, CreativeHTML5StatusResponse_status.IN_PROGRESS].includes(
      creative?.html5_creative?.status
    );
  }

  isHTML5CreativeJobFailed(creative: CreativeExtraType): boolean {
    return (
      creative?.creativeType === 'HTML5_NEW' &&
      creative?.html5_creative?.status === CreativeHTML5StatusResponse_status.FAILED
    );
  }

  isHTML5CreativeJobDone(creative: CreativeExtraType): boolean {
    return (
      creative?.creativeType === 'HTML5_NEW' &&
      creative?.html5_creative?.status === CreativeHTML5StatusResponse_status.DONE
    );
  }
}
