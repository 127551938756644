import React from 'react';
import PropTypes from 'prop-types';
import { HeaderCell } from './HeaderCell';
import { Column } from './PaymentsTable';

interface TableHeaderProps {
  columns: Column[];
}

export function TableHeader(props: TableHeaderProps) {
  const { columns } = props;

  return (
    <tr className="payments-table-header">
      {columns.map((column, index) => (
        <HeaderCell column={column} key={index} />
      ))}
    </tr>
  );
}

TableHeader.propTypes = {
  columns: PropTypes.array
};
