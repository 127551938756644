import * as React from 'react';
import { EditableFormField } from '../index';
import { TagsTextField } from './TagsTextField';

export interface EmailListTextFieldProps {
  field: EditableFormField<string[]>;
  label: string;
  className?: string;
  fullWidth?: boolean;
}

const emailListSeparatorKeys = [' ', ',', ';'];

function transformEmails(emails: string[]): string[] {
  return emails.reduce(
    (acc, email) => [
      ...acc,
      ...email
        .split(/\s+|,|;/)
        .map(email => email.trim())
        .filter(email => Boolean(email))
    ],
    [] as string[]
  );
}

export function EmailListTextField(props: EmailListTextFieldProps) {
  return (
    <TagsTextField
      className={props.className}
      label={props.label}
      field={props.field}
      separatorKeys={emailListSeparatorKeys}
      transformOnChange={transformEmails}
      fullWidth={props.fullWidth}
    />
  );
}
