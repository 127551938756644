import React from 'react';

interface BillingThresholdSectionProps {
  billingThreshold: number;
}

export function BillingThresholdSection(props: BillingThresholdSectionProps) {
  const { billingThreshold } = props;
  const formattedBillingThreshold = billingThreshold.toFixed(2);

  return (
    <>
      <div className="payment-page__section-title">How Payments Work</div>
      <p className="payment-section__billing-info">
        You'll be billed on the <b>1st of each month.</b> If your balance reaches your{' '}
        <b>${formattedBillingThreshold} payment threshold</b> before then, you'll be billed immediately.
      </p>
    </>
  );
}
