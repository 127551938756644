import React, { useEffect, useState, useRef } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardNumberElement, StripeCardCvcElement, StripeCardExpiryElement } from '@stripe/stripe-js';
import { PaymentFormTextField } from './PaymentFormTextField';
import { PrimaryButton } from '../../../components/reactComponents/Button';
import { CircularIndicator } from '../../../components/reactComponents/LoadingIndicator/CircularIndicator';

interface PaymentFormProps {
  onClose: () => void;
  submitPaymentMethod: (tokenId: string) => any;
}

export function PaymentForm(props: PaymentFormProps) {
  const { onClose, submitPaymentMethod } = props;
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState('');
  const [hasCardError, setHasCardError] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [cardNumberError, setCardNumberError] = useState<string | undefined>('');
  const [cardExpiryError, setCardExpiryError] = useState<string | undefined>('');
  const [cardCvcError, setCardCvcError] = useState<string | undefined>('');
  const [cardZipError, setCardZipError] = useState<string | undefined>('');

  const stripe = useStripe();
  const elements = useElements();

  const cardNumber = useRef<StripeCardNumberElement | null>(null);
  const cardExpiry = useRef<StripeCardExpiryElement | null>(null);
  const cardCvc = useRef<StripeCardCvcElement | null>(null);

  useEffect(() => {
    if (stripe && elements) {
      if (!cardNumber.current) {
        cardNumber.current = elements.getElement('cardNumber');

        cardNumber.current &&
          cardNumber.current.on('change', ({ error }) => setCardNumberError(error ? error.message : ''));
      }

      if (!cardExpiry.current) {
        cardExpiry.current = elements.getElement('cardExpiry');
        cardExpiry.current &&
          cardExpiry.current.on('change', ({ error }) => setCardExpiryError(error ? error.message : ''));
      }

      if (!cardCvc.current) {
        cardCvc.current = elements.getElement('cardCvc');
        cardCvc.current && cardCvc.current.on('change', ({ error }) => setCardCvcError(error ? error.message : ''));
      }
    }
  });

  useEffect(() => {
    setHasCardError(!!cardExpiryError || !!cardNumberError || !!cardCvcError || !!cardZipError);
  }, [cardExpiryError, cardNumberError, cardCvcError, cardZipError]);

  useEffect(() => {
    const isValid = !!(
      paymentInfo['name'] &&
      paymentInfo['address_line1'] &&
      paymentInfo['address_city'] &&
      paymentInfo['address_state'] &&
      paymentInfo['address_zip']
    );

    setIsFormValid(isValid);
  }, [paymentInfo]);

  // Custom styling can be passed to options when creating an Element.
  const style = {
    base: {
      iconColor: '#53A8BC',
      color: '#0a3f36',
      fontWeight: 400,
      fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      ':focus': {
        color: '#00b200'
      }
    },
    invalid: {
      iconColor: '#ba1a1a',
      color: '#ba1a1a'
    }
  };

  const handleAddNewPayment = async () => {
    // Use Stripe.js to create a token. We can also pass
    // in the additional customer data we collected in our form.
    if (cardNumber.current) {
      setInProgress(true);
      // Any of the card elements can be passed
      // Stripe should know to pull from all elements created on the same instance of Elements
      const stripeResp = await stripe!.createToken(cardNumber.current, paymentInfo);
      if (stripeResp.error) {
        // Inform the customer that there was an error
        if (stripeResp.error.code?.includes('expiry')) {
          setCardExpiryError(stripeResp.error.message);
        } else if (stripeResp.error.code?.includes('cvc')) {
          setCardCvcError(stripeResp.error.message);
        } else if (stripeResp.error.code?.includes('postal_code')) {
          setCardZipError(stripeResp.error.message);
        } else {
          setCardNumberError(stripeResp.error.message);
        }
        setInProgress(false);
      } else {
        // Send the token to the server
        const paymentError = await submitPaymentMethod(stripeResp.token.id);
        if (paymentError) {
          setInProgress(false);
          setError(paymentError);
        } else {
          onClose();
        }
      }
    }
  };

  const handleZipUpdate = updatedValue => {
    handlePaymentInfoChange(updatedValue);
    // This error would only be set on submitting the form, and we want to clear it if the field value has changed
    if (cardZipError) setCardZipError('');
  };

  const handlePaymentInfoChange = updatedValue => {
    setPaymentInfo(paymentInfo => ({
      ...paymentInfo,
      ...updatedValue
    }));
  };

  return (
    <>
      <form name="newPaymentForm" id="payment-form" className="new-payment-form-react">
        <div className="field">
          <label className="stripe-element__label">
            Card Number
            <CardNumberElement options={{ style: style, showIcon: true, placeholder: '' }} className="stripe-element" />
          </label>
          <div id="card-errors" className="signin-errors ">
            {cardNumberError}
          </div>
        </div>
        <div className="stripe-expiry-cvc-fields">
          <div className="field">
            <label className="stripe-element__label">
              MM/YY
              <CardExpiryElement options={{ style: style, placeholder: '' }} className="stripe-element" />
            </label>
            <div id="card-errors" className="signin-errors ">
              {cardExpiryError}
            </div>
          </div>
          <div className="field">
            <label className="stripe-element__label">
              CVC
              <CardCvcElement options={{ style: style, placeholder: '' }} className="stripe-element" />
            </label>
            <div id="card-errors" className="signin-errors ">
              {cardCvcError}
            </div>
          </div>
        </div>

        <PaymentFormTextField
          label="Cardholder Name"
          name="name"
          onChange={handlePaymentInfoChange}
          className="payment-form__cardholder-field"
        />
        <PaymentFormTextField
          label="Billing Address"
          name="address_line1"
          onChange={handlePaymentInfoChange}
          className="payment-form__address-field"
        />
        <PaymentFormTextField
          label="City"
          name="address_city"
          onChange={handlePaymentInfoChange}
          className="payment-form__city-field"
        />
        <div className="payment-form__state-zip-fields">
          <PaymentFormTextField
            label="State / Province"
            name="address_state"
            onChange={handlePaymentInfoChange}
            className="payment-form__state-field"
          />
          <PaymentFormTextField
            label="Zip / Postal Code"
            name="address_zip"
            id="inp-newPaymentModal-paymentZip"
            onChange={handleZipUpdate}
            className="payment-form__zip-field"
            error={cardZipError}
          />
        </div>

        {!inProgress && !error && (
          <div className="default-message">
            <p className="default-message__body">
              To validate the authenticity of your credit card, a $1.00 hold will be applied. This hold is automatically
              reversed in 7 - 10 business days. For debit cards, a $100 hold is applied and automatically refunded in 5
              - 7 days if unused.
            </p>
          </div>
        )}
      </form>
      {error && <p className="error-msg">{error}</p>}
      <div className="with-borders modal-footer" id="new-payment-footer">
        <div className="footer-section">
          <PrimaryButton
            title={inProgress ? <CircularIndicator size={20} /> : 'Add New Card'}
            onClick={handleAddNewPayment}
            className="button-v2 button--green"
            id="btn-newPaymentModal-save"
            disabled={hasCardError || !isFormValid}
          />
          <PrimaryButton
            title="Cancel"
            onClick={onClose}
            className="button-v2 button--tertiary-green"
            id="btn-newPaymentModal-cancel"
          />
        </div>
      </div>
    </>
  );
}
