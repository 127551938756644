import { send$http } from '../api/def/ngExecutor';
import { get_adgroup_pixels_api, get_campaign_pixels_api } from '../api/http/data.generated';
import { convertToLocalTimezoneByDate, convertToLocalTimezoneByTimestamp } from './general_utils';

reportFactory.$inject = ['$q', '$http'];

/**
 * @param {ng.IQService} $q
 * @param {ng.IHttpService} $http
 */
export function reportFactory($q, $http) {
  var reportFactory = {};
  const CAMPAIGN_TABLE_LEVEL = 'campaigns';

  // TOTAL REPORT DATA
  /**
   * @param {{ start_date?: string; end_date?: string; }} selectedDurationRange
   * @param {number[]} id_array
   */
  reportFactory.getAccountTableData = function(selectedDurationRange, id_array = []) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: '/data/report/demand/total/account',
      data: {
        start_date: selectedDurationRange.start_date,
        end_date: selectedDurationRange.end_date,
        id_array: id_array
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };
  reportFactory.getCampaignTableData = function(campaignId, selectedDurationRange, id_array) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: '/data/report/demand/total/campaign',
      data: {
        start_date: selectedDurationRange.start_date,
        end_date: selectedDurationRange.end_date,
        id_array: id_array,
        campaign_id: campaignId
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };
  reportFactory.getAdGroupTableData = function(campaignId, selectedDurationRange, id_array) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: '/data/report/demand/total/adgroup',
      data: {
        start_date: selectedDurationRange.start_date,
        end_date: selectedDurationRange.end_date,
        id_array: id_array,
        campaign_id: campaignId
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };
  reportFactory.getCreativeTableData = function(campaignId, selectedDurationRange, id_array) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: '/data/report/demand/total/creative',
      data: {
        start_date: selectedDurationRange.start_date,
        end_date: selectedDurationRange.end_date,
        id_array: id_array,
        campaign_id: campaignId
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };

  // Category
  reportFactory.getCategoryBreakdownTableData = function(
    campaignId,
    selectedDurationRange,
    selectedTableLevel,
    itemId
  ) {
    var d = $q.defer();
    var url = null;
    if (selectedTableLevel == 'campaigns') {
      url = '/data/report/demand/category/campaign';
    } else if (selectedTableLevel == 'placements') {
      url = '/data/report/demand/category/adgroup';
    } else {
      url = '/data/report/demand/category/creative';
    }
    $http({
      method: 'POST',
      url: url,
      data: {
        start_date: selectedDurationRange.start_date,
        end_date: selectedDurationRange.end_date,
        id_array: [itemId],
        campaign_id: campaignId
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };

  // Device
  reportFactory.getDeviceBreakdownTableData = function(campaignId, selectedDurationRange, selectedTableLevel, itemId) {
    var d = $q.defer();
    var url =
      selectedTableLevel == 'campaigns' ? '/data/report/demand/device/campaign' : '/data/report/demand/device/adgroup';
    $http({
      method: 'POST',
      url,
      data: {
        start_date: selectedDurationRange.start_date,
        end_date: selectedDurationRange.end_date,
        id_array: [itemId],
        campaign_id: campaignId
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };

  // OS
  reportFactory.getOSBreakdownTableData = function(campaignId, selectedDurationRange, selectedTableLevel, itemId) {
    var d = $q.defer();
    var url = selectedTableLevel == 'campaigns' ? '/data/report/demand/os/campaign' : '/data/report/demand/os/adgroup';
    $http({
      method: 'POST',
      url,
      data: {
        start_date: selectedDurationRange.start_date,
        end_date: selectedDurationRange.end_date,
        id_array: [itemId],
        campaign_id: campaignId
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };

  // Brand
  reportFactory.getBrandBreakdownTableData = function(campaignId, selectedDurationRange, selectedTableLevel, itemId) {
    var d = $q.defer();
    var url = null;
    if (selectedTableLevel == 'campaigns') {
      url = '/data/report/demand/brand/campaign';
    } else if (selectedTableLevel == 'placements') {
      url = '/data/report/demand/brand/adgroup';
    } else {
      url = '/data/report/demand/brand/creative';
    }
    $http({
      method: 'POST',
      url: url,
      data: {
        start_date: selectedDurationRange.start_date,
        end_date: selectedDurationRange.end_date,
        id_array: [itemId],
        campaign_id: campaignId
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };

  // Audience
  reportFactory.getAudienceBreakdownTableData = function(
    campaignId,
    selectedDurationRange,
    selectedTableLevel,
    itemId
  ) {
    let d = $q.defer();
    let url = '/data/report/demand/audience/v2/creative';
    if (selectedTableLevel == 'campaigns') {
      url = '/data/report/demand/audience/v2/campaign';
    } else if (selectedTableLevel == 'placements') {
      url = '/data/report/demand/audience/v2/adgroup';
    }
    $http({
      method: 'POST',
      url: url,
      data: {
        start_date: selectedDurationRange.start_date,
        end_date: selectedDurationRange.end_date,
        id_array: [itemId],
        campaign_id: campaignId
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };

  // Age And Gender
  reportFactory.getAgeAndGenderBreakdownTableData = function(
    campaignId,
    selectedDurationRange,
    selectedTableLevel,
    itemId
  ) {
    var d = $q.defer();
    var url = null;
    if (selectedTableLevel == 'campaigns') {
      url = '/data/report/demand/genderage/campaign';
    } else if (selectedTableLevel == 'placements') {
      url = '/data/report/demand/genderage/adgroup';
    } else {
      d.reject('Wrong table level');
      return d;
    }
    $http({
      method: 'POST',
      url: url,
      data: {
        start_date: selectedDurationRange.start_date,
        end_date: selectedDurationRange.end_date,
        id_array: [itemId],
        campaign_id: campaignId
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };

  // Publisher
  reportFactory.getPublisherBreakdownTableData = function(type, id, selectedDurationRange) {
    return reportFactory.getReportingData('publisher/table', type, id, selectedDurationRange);
  };

  reportFactory.getAllZipcodeMetrics = function(type, id, selectedDurationRange) {
    var d = $q.defer();
    var url = '/data/report/map/zipcode';
    var params = {
      start_date: selectedDurationRange.start_date,
      end_date: selectedDurationRange.end_date
    };

    if (type === 'campaign') {
      url += '/campaign';
      params.campaign_id = id;
    } else if (type === 'placements') {
      url += '/adgroup';
      params.adgroup_id = id;
    }

    $http({
      method: 'GET',
      url: url,
      params: params
    })
      .then(function({ data, status, headers, config }) {
        d.resolve(data);
      })
      .catch(function({ data, status, headers, config }) {
        d.reject(data);
      });
    return d.promise;
  };

  // Route lookup table for the following function
  var topOptionRoutes = {
    states: 'state',
    dmas: 'dma',
    counties: 'county',
    zipCodes: 'zipcode',
    stores: 'store'
  };

  var sortOptionRoutes = {
    impressions: 'topImpression',
    clicks: 'topClicks',
    secondaryActions: 'topSecondaryActions',
    visits: 'topVisits'
  };

  // Retrieve top summary data from Reporting API
  reportFactory.getTopSummaryData = function(
    type,
    id,
    selectedDurationRange,
    topOption,
    sortOption,
    northEast,
    southWest
  ) {
    var d = $q.defer();
    var url = '';
    var params = {
      start_date: selectedDurationRange.start_date,
      end_date: selectedDurationRange.end_date
    };

    var topOptionRoute = topOptionRoutes[topOption];
    var sortOptionRoute = sortOptionRoutes[sortOption];
    url = '/data/report/' + sortOptionRoute + '/' + topOptionRoute;

    if (type === 'campaign') {
      url += '/campaign';
      params.campaign_id = id;
    } else if (type === 'placements') {
      url += '/adgroup';
      params.adgroup_id = id;
    }

    if (northEast && southWest) {
      params.minLat = southWest.lat();
      params.minLng = southWest.lng();
      params.maxLat = northEast.lat();
      params.maxLng = northEast.lng();
    }

    $http({
      method: 'GET',
      url: url,
      params: params
    })
      .then(function({ data, status, headers, config }) {
        d.resolve(data);
      })
      .catch(function({ data, status, headers, config }) {
        d.reject(data);
      });

    return d.promise;
  };

  reportFactory.getReportingData = function(
    route,
    type,
    id,
    selectedDurationRange,
    metrics,
    days,
    limit,
    forceCampaign,
    sicCode,
    metric,
    products,
    category_id
  ) {
    var d = $q.defer();

    var url = '/data/report/' + route;
    var params = {
      start_date: selectedDurationRange.start_date,
      end_date: selectedDurationRange.end_date
    };

    if (metrics) {
      params.metrics = metrics.join(',');
    }

    if (days) {
      params.days = days.join(',');
    }

    if (limit) {
      params.limit = limit;
    }

    if (sicCode) {
      params.sicCode = sicCode;
    }

    if (category_id) {
      params.category_id = category_id;
    }

    if (metric) {
      params.metric = metric;
    }

    if (products) {
      params.products = products.join(',');
    }

    // forceCampaign will set the id to the campaign_id field regardless of level
    if (type === 'campaigns') {
      url += '/campaign';
      params.campaign_id = id;
    } else if (type === 'placements') {
      url += '/adgroup';
      if (forceCampaign) {
        params.campaign_id = id;
      } else {
        params.adgroup_id = id;
      }
    } else if (type === 'creatives') {
      url += '/creative';
      if (forceCampaign) {
        params.campaign_id = id;
      } else {
        params.creative_id = id;
      }
    } else {
      d.reject('Invalid type');
      return d.promise;
    }

    $http({
      method: 'GET',
      url: url,
      params: params
    })
      .then(function({ data }) {
        // Ads Manager is currently set up to return [] on all unhandled exceptions on reporting calls
        if (Array.isArray(data) && !data.length) {
          d.reject('There was an unexpected error while loading reporting data. Please try again later!');
        } else {
          d.resolve(data);
        }
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };

  reportFactory.getProductChartData = function(type, id, selectedDurationRange, metric, products) {
    return reportFactory.getReportingData(
      'product/chart',
      type,
      id,
      selectedDurationRange,
      null,
      null,
      null,
      false,
      null,
      metric,
      products
    );
  };

  reportFactory.getProductTableData = function(type, id, selectedDurationRange) {
    return reportFactory.getReportingData('product/table', type, id, selectedDurationRange, null, null);
  };

  reportFactory.getProductExportData = function(type, campaignId, selectedDurationRange) {
    return reportFactory.getReportingData(
      'product/export',
      type,
      campaignId,
      selectedDurationRange,
      null,
      null,
      null,
      true
    );
  };

  reportFactory.getVisitationChartData = function(type, id, selectedDurationRange) {
    return reportFactory.getReportingData('demand/visitation', type, id, selectedDurationRange);
  };

  reportFactory.getDemographicsChartData = function(type, id, selectedDurationRange, metrics) {
    return reportFactory.getReportingData('demographic/chart', type, id, selectedDurationRange, metrics);
  };

  reportFactory.getTimeSeriesData = function(route, type, id, selectedDurationRange, metrics, days, limit) {
    route = 'timeseries/' + route;
    return reportFactory.getReportingData(route, type, id, selectedDurationRange, metrics, days, limit);
  };

  reportFactory.getDailyChartData = function(type, id, selectedDurationRange, metrics) {
    return reportFactory.getTimeSeriesData('daily/chart', type, id, selectedDurationRange, metrics);
  };

  reportFactory.getDailyTableData = function(type, id, selectedDurationRange, limit) {
    return reportFactory.getTimeSeriesData('daily/table', type, id, selectedDurationRange, null, null, limit);
  };

  reportFactory.getTODChartData = function(type, id, selectedDurationRange, metrics) {
    return reportFactory.getTimeSeriesData('tod/chart', type, id, selectedDurationRange, metrics, null);
  };

  reportFactory.getTODTableData = function(type, id, selectedDurationRange) {
    return reportFactory.getTimeSeriesData('tod/table', type, id, selectedDurationRange, null, null);
  };

  reportFactory.getDOWChartData = function(type, id, selectedDurationRange, metrics) {
    return reportFactory.getTimeSeriesData('dow/chart', type, id, selectedDurationRange, metrics, null);
  };

  reportFactory.getDOWTableData = function(type, id, selectedDurationRange) {
    return reportFactory.getTimeSeriesData('dow/table', type, id, selectedDurationRange, null, null);
  };

  reportFactory.getConversionsPixelData = async function(type, campaignId, adgroupId) {
    const query = type === CAMPAIGN_TABLE_LEVEL ? get_campaign_pixels_api : get_adgroup_pixels_api;
    return await send$http($http, query, {
      params: {
        campaign_id: campaignId,
        adgroup_id: adgroupId
      }
    });
  };

  reportFactory.getAllPixelsConversionsChartData = function(type, id, selectedDurationRange, metrics) {
    return reportFactory.getTimeSeriesData('daily/chart', type, id, selectedDurationRange, metrics);
  };

  reportFactory.getSinglePixelConversionsChartData = function(type, id, selectedDurationRange, pixelMetrics) {
    let url = '/data/report/timeseries/pixel/chart';
    let params = {
      start_date: selectedDurationRange.start_date,
      end_date: selectedDurationRange.end_date,
      pixel_ids: pixelMetrics.join(',')
    };
    if (type === 'campaigns') {
      url += '/campaign';
      params.campaign_id = id;
    } else if (type === 'placements') {
      url += '/adgroup';
      params.adgroup_id = id;
    }
    var d = $q.defer();
    $http({
      method: 'GET',
      url: `${url}`,
      params: params
    })
      .then(function({ data }) {
        const map = {};
        for (const pixel of data) {
          const dates = [];
          for (const dateObj of pixel.dates) {
            const localDate = convertToLocalTimezoneByDate(dateObj.date);
            const localTimestamp = convertToLocalTimezoneByTimestamp(dateObj.timestamp);
            dates.push({ date: localDate, timestamp: localTimestamp, conversions: dateObj.conversions });
          }
          map[pixel.pixel_id] = pixel.dates;
        }
        d.resolve(map[pixelMetrics[0]]);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };

  reportFactory.getConversionsChartData = function(type, id, selectedDurationRange, metrics, pixelMetrics) {
    if (pixelMetrics[0] === 'pixels') {
      return reportFactory.getAllPixelsConversionsChartData(type, id, selectedDurationRange, metrics);
    }
    return reportFactory.getSinglePixelConversionsChartData(type, id, selectedDurationRange, pixelMetrics);
  };

  reportFactory.getConversionsTableData = function(type, id, selectedDurationRange) {
    return reportFactory.getTimeSeriesData('pixel/table', type, id, selectedDurationRange, null, null);
  };

  reportFactory.getConversionsExportData = function(type, id, selectedDurationRange) {
    // must return a promise to align with other reportFactory functions
    return reportFactory.getReportingData(
      'timeseries/pixel/export',
      type,
      id,
      selectedDurationRange,
      null,
      null,
      null,
      true,
      null,
      null,
      null
    );
  };

  reportFactory.getDOWTODChartData = function(type, id, selectedDurationRange, metrics, days) {
    return reportFactory.getTimeSeriesData('dow_tod/chart', type, id, selectedDurationRange, metrics, days);
  };

  reportFactory.getDOWTODTableData = function(type, id, selectedDurationRange) {
    return reportFactory.getTimeSeriesData('dow_tod/table', type, id, selectedDurationRange, null, null);
  };

  reportFactory.getBehavioralAudienceChartData = function(type, id, selectedDurationRange, metrics) {
    return reportFactory.getReportingData('audience/chart', type, id, selectedDurationRange, metrics, null, 10);
  };

  reportFactory.getBehavioralAudienceTableData = function(type, id, selectedDurationRange) {
    return reportFactory.getReportingData('audience/table', type, id, selectedDurationRange, null, null, 10);
  };

  reportFactory.getBehavioralAudienceExportData = function(type, campaignId, selectedDurationRange) {
    // only campaign ID is used for export routes
    return reportFactory.getReportingData(
      'audience/export',
      type,
      campaignId,
      selectedDurationRange,
      null,
      null,
      null,
      true
    );
  };

  reportFactory.getBrandAffinityChartData = function(type, id, selectedDurationRange, metrics, sicCode) {
    return reportFactory.getReportingData(
      'brand_affinity/chart',
      type,
      id,
      selectedDurationRange,
      metrics,
      null,
      10,
      null,
      sicCode
    );
  };

  reportFactory.getBrandAffinityTableData = function(type, id, selectedDurationRange, sicCode) {
    return reportFactory.getReportingData(
      'brand_affinity/table',
      type,
      id,
      selectedDurationRange,
      null,
      null,
      10,
      null,
      sicCode
    );
  };

  reportFactory.getBrandAffinityExportData = function(type, campaignId, selectedDurationRange, sicCode) {
    return reportFactory.getReportingData(
      'brand_affinity/export',
      type,
      campaignId,
      selectedDurationRange,
      null,
      null,
      null,
      true,
      sicCode
    );
  };

  reportFactory.getCategoryChartData = function(type, id, selectedDurationRange, metrics) {
    return reportFactory.getReportingData(
      'audience_category/chart',
      type,
      id,
      selectedDurationRange,
      metrics,
      null,
      10
    );
  };

  reportFactory.getCategoryTableData = function(type, id, selectedDurationRange) {
    return reportFactory.getReportingData('audience_category/table', type, id, selectedDurationRange, null, null, 10);
  };

  reportFactory.getCategoryExportData = function(type, campaignId, selectedDurationRange) {
    return reportFactory.getReportingData(
      'audience_category/export',
      type,
      campaignId,
      selectedDurationRange,
      null,
      null,
      null,
      true
    );
  };

  reportFactory.getAudienceAffinityChartData = function(type, id, selectedDurationRange, metrics, category_id) {
    return reportFactory.getReportingData(
      'audience_affinity/chart',
      type,
      id,
      selectedDurationRange,
      metrics,
      null,
      10,
      null,
      null,
      null,
      null,
      category_id
    );
  };

  reportFactory.getAudienceAffinityTableData = function(type, id, selectedDurationRange, metrics, category_id) {
    return reportFactory.getReportingData(
      'audience_affinity/table',
      type,
      id,
      selectedDurationRange,
      metrics,
      null,
      10,
      null,
      null,
      null,
      null,
      category_id
    );
  };

  reportFactory.getAudienceAffinityExportData = function(type, campaignId, selectedDurationRange, category_id) {
    return reportFactory.getReportingData(
      'audience_affinity/export',
      type,
      campaignId,
      selectedDurationRange,
      null,
      null,
      null,
      true,
      null,
      null,
      null,
      category_id
    );
  };

  reportFactory.getDailyTrendExportData = function(type, campaignId, selectedDurationRange) {
    return reportFactory.getReportingData(
      'timeseries/daily/export',
      type,
      campaignId,
      selectedDurationRange,
      null,
      null,
      null,
      true
    );
  };

  reportFactory.getTODExportData = function(type, campaignId, selectedDurationRange) {
    return reportFactory.getReportingData(
      'timeseries/tod/export',
      type,
      campaignId,
      selectedDurationRange,
      null,
      null,
      null,
      true
    );
  };

  reportFactory.getDOWExportData = function(type, campaignId, selectedDurationRange) {
    return reportFactory.getReportingData(
      'timeseries/dow/export',
      type,
      campaignId,
      selectedDurationRange,
      null,
      null,
      null,
      true
    );
  };

  reportFactory.getDOWTODExportData = function(type, campaignId, selectedDurationRange) {
    return reportFactory.getReportingData(
      'timeseries/dow_tod/export',
      type,
      campaignId,
      selectedDurationRange,
      null,
      null,
      null,
      true
    );
  };

  reportFactory.getPublisherExportData = function(type, campaignId, selectedDurationRange) {
    return reportFactory.getReportingData(
      'publisher/export',
      type,
      campaignId,
      selectedDurationRange,
      null,
      null,
      null,
      true
    );
  };

  reportFactory.getPublisherChartData = function(type, id, selectedDurationRange, metric) {
    return reportFactory.getReportingData(
      'publisher/chart',
      type,
      id,
      selectedDurationRange,
      null,
      null,
      null,
      null,
      null,
      metric
    );
  };

  return reportFactory;
}
