import * as React from 'react';
import DropdownBase from './DropdownBase';

interface SimpleSearchDropdownProps<T> {
  items: T[];
  id?: string;
  className?: string;
  title?: string;
  /** If this is set to true the field would be red */
  error?: boolean;
  /** If there is an error we will show this as the error message */
  errorMessage?: string;
  /** Selected value */
  value?: T | null;

  fullWidth?: boolean;
  /** The callback method that would be called when an item is selected. The selectedOption is the selected item */
  onSelect?(selectedOption: T | null): void;
  defaultValue?: T | null;
  placeholder?: string;
  /** This method should return the title that will be rendered as the option and as the selected item */
  getItemTitle?(item: T): string;
  filterItems?(searchInput: string, options: T[]): T[];
}

function defaultOnSelect() {}

export function SimpleSearchDropdown<T>(props: SimpleSearchDropdownProps<T>) {
  const {
    placeholder = '',
    title = '',
    error,
    errorMessage,
    fullWidth = false,
    value,
    getItemTitle,
    defaultValue = undefined,
    onSelect = defaultOnSelect,
    filterItems,
    id,
    className
  } = props;

  return (
    <DropdownBase
      error={error}
      errorMessage={errorMessage}
      title={title}
      defaultValue={defaultValue}
      items={props.items}
      fullWidth={fullWidth}
      value={value}
      onSelect={onSelect}
      placeholder={placeholder}
      filterItems={filterItems}
      getItemTitle={getItemTitle}
      id={id}
      className={className}
    />
  );
}
