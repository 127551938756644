standardSuperLandingPageController.$inject = [
  '$scope',
  'staticDataFactory',
  'companyAndAccountFactory',
  '$sce',
  '$q',
  '$timeout',
  '$http',
  'creativeTemplatesFactory'
];

/**
 * @param {import('../../../factories/static_data.fac').IStaticDataFactory} staticDataFactory
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {ng.ISCEService} $sce
 * @param {ng.IQService} $q
 * @param {ng.ITimeoutService} $timeout
 * @param {ng.IHttpService} $http
 */
export function standardSuperLandingPageController(
  $scope,
  staticDataFactory,
  companyAndAccountFactory,
  $sce,
  $q,
  $timeout,
  $http,
  creativeTemplatesFactory
) {
  $scope.adGroupCopy = angular.copy($scope.adGroup);

  $scope.headerImageUploader = {};
  $scope.couponImageUploader = {};

  $scope.progressHeaderImageUpload = false;
  $scope.progressCouponImageUpload = false;

  var defaultLpFieldValues = {};

  $scope.imageUrl = {
    header: null,
    coupon: null
  };

  $scope.headerImageExpectedSizes = [
    {
      width: 640,
      height: 400
    }
  ];

  $scope.disableField = {
    flipCouponVideo: false,
    buttonColor: false
  };

  $scope.isValid = true;

  $scope.landingPageMethod.validate = function() {
    return $scope.isValid;
  };

  $scope.init = (function() {
    var landingPageDfd = staticDataFactory.getLandingPage();
    var creativeFieldDfd = staticDataFactory.getCreativeFields();
    $q.all([landingPageDfd, creativeFieldDfd]).then(function() {
      if (staticDataFactory.landingPage['standard_super']) {
        $scope.$watch('creative.lpTypeName', function(oldValue, newValue) {
          if (newValue !== oldValue && (newValue == 'Standard Landing Page' || newValue == 'Super Landing Page')) {
            $scope.getLandingPagePreviewUrl();
          }
        });
        defaultLpFieldValues = staticDataFactory.landingPage['standard_super'];

        // if lpConfig is empty or useLandingPage is not set, get default Landing Pages
        if ($scope.creative && !$scope.creative.useLandingPage) {
          $scope.creative.lpConfig = angular.copy(defaultLpFieldValues);
          if (companyAndAccountFactory.selectedAccount?.countryCode == 'JP') {
            $scope.creative.lpConfig.flipAddressFields = 'YES';
          }

          if (
            $scope.adGroupCopy.product &&
            ($scope.adGroupCopy.product === 'default' || $scope.adGroupCopy.product.indexOf('awareness') > -1) &&
            $scope.creative.lpConfig.hasOwnProperty('enableDirectionsButton')
          ) {
            // set enable directions to false when product is not defined or product is awareness
            $scope.creative.lpConfig.enableDirectionsButton = '';
          }
        }
      } else {
        defaultLpFieldValues = [];
      }

      $scope.imageUrl.header = $scope.creative.lpConfig.imageUrl_640x400;
      $scope.imageUrl.coupon = $scope.creative.lpConfig.couponUrl;

      // If header image is null while switching the landing page type, assign the default image url
      !$scope.imageUrl.header ? ($scope.imageUrl.header = defaultLpFieldValues.imageUrl_640x400) : null;
      // If Button color is null while switching the landing page type, assign the default button color
      !$scope.creative.lpConfig.buttonColor
        ? ($scope.creative.lpConfig.buttonColor = defaultLpFieldValues.buttonColor)
        : null;

      if ($scope.creative.useLandingPageSwitchSet && $scope.creative.lpTypeName) {
        // to remove the stuttering effect as iframe is pretty heavy to load along with scroll
        $timeout(function() {
          $scope.getLandingPagePreviewUrl();
        }, 500);
      } else {
        $scope.getLandingPagePreviewUrl();
      }
    });
  })();

  function validate() {
    var d = $q.defer();

    var headerImageDfd = validateImageUrlSize('header', $scope.imageUrl.header);
    headerImageDfd.then(
      function(data) {
        var valid = true;
        if ($scope.landingPageForm && $scope.landingPageForm.$invalid) {
          for (var errorField in $scope.landingPageForm.$error) {
            if (
              $scope.landingPageForm.$error.hasOwnProperty(errorField) &&
              $scope.landingPageForm.$error[errorField].length > 0
            ) {
              $scope.landingPageForm.$error[errorField].forEach(function(value) {
                valid = valid && false;
                value.$setTouched();
              });
            }
          }
        }
        if (valid) {
          d.resolve();
        } else {
          d.reject();
        }
      },
      function(data) {
        d.reject();
      }
    );

    return d.promise;
  }

  $scope.getLandingPagePreviewUrl = function() {
    checkDisableFlags();

    validate().then(
      function() {
        // colorpicker adds # but to render the landing page we need to remove #
        if ($scope.creative.lpConfig.buttonColor.indexOf('#') > -1) {
          $scope.creative.lpConfig.buttonColor = $scope.creative.lpConfig.buttonColor.replace('#', '');
        }

        // make values fixed
        $scope.creative.lpConfig.button1Signal = 'website';
        $scope.creative.lpConfig.button1Css = 'width:100%;height:100%;left:0px;bottom:0px';

        $scope.creative.lpConfig.button3Signal = 'coupon';
        $scope.creative.lpConfig.button3Css = 'width:100%;height:100%;left:0px;bottom:0px';

        if ($scope.creative.lpConfig.enableAdditionalCTA === 'YES') {
          $scope.creative.lpConfig.button2Signal = 'moreinfo';
        } else {
          $scope.creative.lpConfig.button2Signal = '';
        }

        $scope.creative.lpConfig.name = '%%BUSINESSNAME%%';

        if ($scope.creative.lpConfig.disclaimer === '') {
          $scope.creative.lpConfig.disclaimer = ' ';
        }

        $scope.creative.lpConfig.imageUrl_640x400 = $scope.imageUrl.header;
        $scope.creative.lpConfig.couponUrl = $scope.imageUrl.coupon;

        if (Object.keys($scope.creative.lpConfig).length > 0) {
          getLandingPageConfig($scope.creative.lpConfig);
          var configCopy = $scope.creative.lpConfig;
          var formatedLPConfig = formLandingPage(configCopy);

          var landingPageId;
          if ($scope.creative.lpTypeName == 'Standard Landing Page') {
            landingPageId = 819;
          } else {
            landingPageId = 814;
          }

          $http({
            method: 'POST',
            url: `/data/campaigns/creatives/landing_page_preview?landing_page_id=${landingPageId}`,
            data: { lp_config: formatedLPConfig }
          }).then(function({ data: response }) {
            angular.element('#couponOverlayFieldSet').css('display', 'none');
            angular.element('#headerOverlayFieldSet').css('display', 'none');
            let renderedLandingPage = response.rendered_landing_page;
            renderedLandingPage = renderedLandingPage.replace(/http:\/\//g, '//');
            renderedLandingPage = renderedLandingPage.replace(/https:\/\//g, '//');
            renderedLandingPage = renderedLandingPage.replace(/\/\/cf.xadcentral/g, '//cf.groundtruth');
            renderedLandingPage = renderedLandingPage.replace(/\/\/cdn.xad/g, '//cf.groundtruth');
            $scope.landingPagePreviewHtml = $sce.trustAsHtml(renderedLandingPage);
          });
        }

        $scope.isValid = true;
      },
      function() {
        $scope.isValid = false;
      }
    );
  };

  function getLandingPageConfig(config) {
    Object.keys(staticDataFactory.landingPage['standard_super']).forEach(function(key) {
      if (staticDataFactory.creativeFields.hasOwnProperty(key)) {
        var field = staticDataFactory.creativeFields[key];
        if (field && field.is_calculated) {
          config[key] = '%%' + key.toUpperCase() + '%%';
        }
      } else {
        console.log('Unknown field ' + key + ' in LP Config');
      }
    });
  }

  function formLandingPage(config) {
    var newConfigString = JSON.stringify(config);
    newConfigString = newConfigString.replace('%%NOTIFICATIONURL%%', defaultLpFieldValues.notificationUrl || '');

    if (
      $scope.adGroupCopy.product !== 'default' &&
      $scope.adGroupCopy.LpLocation &&
      $scope.adGroupCopy.LpLocation.address
    ) {
      newConfigString = newConfigString.replace('%%BUSINESSNAME%%', $scope.adGroupCopy.LpLocation.name || '');
      newConfigString = newConfigString.replace('%%STREET%%', $scope.adGroupCopy.LpLocation.address || '');
      newConfigString = newConfigString.replace('%%CITY%%', $scope.adGroupCopy.LpLocation.city || '');
      newConfigString = newConfigString.replace('%%STATE%%', $scope.adGroupCopy.LpLocation.state || '');
      newConfigString = newConfigString.replace('%%BUSINESS_LATITUDE%%', $scope.adGroupCopy.LpLocation.lat || '');
      newConfigString = newConfigString.replace('%%BUSINESS_LONGITUDE%%', $scope.adGroupCopy.LpLocation.lng || '');
    } else {
      newConfigString = newConfigString.replace('%%BUSINESSNAME%%', 'GroundTruth');
      newConfigString = newConfigString.replace('%%STREET%%', '123 King St');
      newConfigString = newConfigString.replace('%%CITY%%', 'San Francisco');
      newConfigString = newConfigString.replace('%%STATE%%', 'CA');
      newConfigString = newConfigString.replace('%%BUSINESS_LATITUDE%%', '37.38398595254442');
      newConfigString = newConfigString.replace('%%BUSINESS_LONGITUDE%%', '-122.0131516456604');
    }

    newConfigString = newConfigString.replace('%%PHONE%%', defaultLpFieldValues.phone || '');
    newConfigString = newConfigString.replace('%%ATTRIBUTION%%', ' ');

    return newConfigString;
  }

  $scope.onHeaderImageFileAdded = function(flow, file, $event) {
    $scope.progressHeaderImageUpload = true;
    $scope.headerImageUploader.flow = flow;
    $('.header-error-msg').text('');
    if (file.file.dimensions) {
      file.dimensions = file.file.dimensions;
      if (file.dimensions.width < 100) {
        alert('invalid dimensions');
        return false;
      }
      return true;
    }
    var fileReader = new FileReader();
    fileReader.readAsDataURL(file.file);
    fileReader.onload = function(event) {
      var img = new Image();
      img.onload = function() {
        $timeout(function() {
          file.file.dimensions = {
            width: img.width,
            height: img.height
          };

          var isValidImageSize = creativeTemplatesFactory.IsHeaderImageSizeProportionate(
            file.file.dimensions,
            $scope.headerImageExpectedSizes
          );
          if (!isValidImageSize) {
            $('.header-error-msg').text('Invalid image dimension. It should be 640x400 or proportional.');
            $scope.progressHeaderImageUpload = false;
            return;
          }

          $scope.headerImageUploader.flow.addFile(file.file);
        });
      };
      if (typeof fileReader.result === 'string') img.src = fileReader.result;
    };

    return false;
  };

  $scope.headerImageFileAdded = function($flow, flowFile, message) {
    $scope.imageUrl.header = window.mediaCdnUrl + JSON.parse(message).s3_path;
    $scope.progressHeaderImageUpload = false;
    $scope.getLandingPagePreviewUrl();
  };

  $scope.onCouponImageFileAdded = function(flow, file, $event) {
    $scope.progressCouponImageUpload = true;
    $scope.couponImageUploader.flow = flow;
    $('.coupon-error-msg').text('');
    $scope.couponErrorMsg = '';
    if (file.file.dimensions) {
      file.dimensions = file.file.dimensions;
      if (file.dimensions.width < 100) {
        alert('invalid dimensions');
        return false;
      }
      return true;
    }
    var fileReader = new FileReader();
    fileReader.readAsDataURL(file.file);
    fileReader.onload = function(event) {
      var img = new Image();
      img.onload = function() {
        $timeout(function() {
          file.file.dimensions = {
            width: img.width,
            height: img.height
          };

          $scope.couponImageUploader.flow.addFile(file.file);
        });
      };
      if (typeof fileReader.result === 'string') img.src = fileReader.result;
    };

    return false;
  };

  $scope.couponImageFileAdded = function($flow, flowFile, message) {
    $scope.imageUrl.coupon = window.mediaCdnUrl + JSON.parse(message).s3_path;
    $scope.progressCouponImageUpload = false;
    $scope.getLandingPagePreviewUrl();
  };

  function validateImageUrlSize(type, url) {
    var d = $q.defer();
    var img = new Image();
    img.onload = function() {
      $timeout(function() {
        var isValidImageSize = false;

        if (type === 'header') {
          const actualSize = { width: img.width, height: img.height };
          isValidImageSize = creativeTemplatesFactory.IsHeaderImageSizeProportionate(
            actualSize,
            $scope.headerImageExpectedSizes
          );
          if (!isValidImageSize) {
            $('.header-error-msg').text('Invalid image dimension. It should be 640x400 or proportional.');
          }
        }

        if (!isValidImageSize) {
          d.reject(false);
        } else {
          d.resolve(true);
        }
      });
    };
    img.src = url;

    return d.promise;
  }

  function checkDisableFlags() {
    if (
      $scope.creative.lpConfig.enableDirectionsButton === '' &&
      $scope.creative.lpConfig.enableAdditionalCTA === '' &&
      $scope.creative.lpConfig.enablePhoneButton === ''
    ) {
      $scope.disableField.buttonColor = true;
      $scope.creative.lpConfig.buttonColor = defaultLpFieldValues.buttonColor;
    } else {
      $scope.disableField.buttonColor = false;
    }

    if ($scope.creative.lpConfig.couponUrl === '' || $scope.creative.lpConfig.customYouTubeId === '') {
      $scope.creative.lpConfig.flipCouponVideo = '';
      $scope.disableField.flipCouponVideo = true;
    } else {
      $scope.disableField.flipCouponVideo = false;
    }
  }

  $scope.uploadHeaderImageUrl = function() {
    if ($scope.imageUrl.header === undefined || $scope.imageUrl.header === '') {
      //$scope.getLandingPagePreviewUrl();
      return;
    }

    $('.header-error-msg').text('');
    $scope.progressHeaderImageUpload = true;
    creativeTemplatesFactory
      .uploadImageUrlToS3($scope.imageUrl.header, 'header')
      .then(
        function(data) {
          $scope.headerImageFileAdded(undefined, undefined, data);
        },
        function(data) {
          $('.header-error-msg').text('Failed to load the Image.');
        }
      )
      .finally(function() {
        $scope.progressHeaderImageUpload = false;
      });
  };

  $scope.uploadCouponImageUrl = function() {
    if ($scope.imageUrl.coupon === '') {
      $scope.getLandingPagePreviewUrl();
      return;
    }

    $('.coupon-error-msg').text('');
    $scope.progressCouponImageUpload = true;
    creativeTemplatesFactory
      .uploadImageUrlToS3($scope.imageUrl.coupon, 'coupon')
      .then(
        function(data) {
          $scope.couponImageFileAdded(undefined, undefined, data);
        },
        function(data) {
          $('.coupon-error-msg').text('Failed to load the Image.');
        }
      )
      .finally(function() {
        $scope.progressCouponImageUpload = false;
      });
  };

  // once iframe loads, set the height of iframe and set overlay of coupon/header
  $('#landingPagePreviewIframe').on('load', function() {
    // if coupon is visible set the drag and drop overlay else hide the overlay
    if (
      $('#landingPagePreviewIframe')
        .contents()
        .find('#add-promo')
        .offset() &&
      $scope.imageUrl.coupon
    ) {
      var couponTop =
        $('#landingPagePreviewIframe')
          .contents()
          .find('#add-promo')
          .offset()?.top ?? 0; // not sure if 0 is a good default
      if ($scope.creative.lpTypeName == 'Super Landing Page') {
        couponTop = couponTop + 10;
      }
      var stringTop = couponTop.toString() + 'px';
      angular.element('#couponOverlayDiv').css('top', stringTop);

      if ($scope.imageUrl.coupon !== defaultLpFieldValues.couponUrl) {
        angular.element('#couponOverlayFieldSet').css('display', 'none');
      } else {
        angular.element('#couponOverlayFieldSet').css('display', 'block');
      }
    }

    if (
      $('#landingPagePreviewIframe')
        .contents()
        .find('#xad-standard-lp')
        .height()
    ) {
      if ($scope.imageUrl.header !== defaultLpFieldValues.imageUrl_640x400) {
        angular.element('#headerOverlayFieldSet').css('display', 'none');
      } else {
        angular.element('#headerOverlayFieldSet').css('display', 'block');
      }

      $timeout(function() {
        var heightString =
          (
            $('#landingPagePreviewIframe')
              .contents()
              .find('#xad-standard-lp')
              .height() + 25
          ).toString() + 'px';
        if ($scope.creative.lpTypeName == 'Super Landing Page') {
          heightString =
            (
              $('#landingPagePreviewIframe')
                .contents()
                .find('#xad-standard-lp')
                .height() + 45
            ).toString() + 'px';
        }
        angular.element('.standard-super-landing-page-preview-iframe').css('height', heightString);
      });
    }
  });
}
