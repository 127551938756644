import statusModalTemplate from './statusModal/status-modal-template.html';
modalFactory.$inject = ['$uibModal'];

/**
 *
 * @param {ng.ui.bootstrap.IModalService} $uibModal
 */
export function modalFactory($uibModal) {
  var modalFactory = {
    createModal: createModal,
    simpleConfirm: simpleConfirm,
    simpleAlert: simpleAlert,
    welcome: welcome,
    termsAndConditions: termsAndConditions,
    privacySensitivityConsent: privacySensitivityConsent,
    rejectCampaign: rejectCampaign,
    channelTermsAndConditions: channelTermsAndConditions,
    statusModal,
    campaignCreationHelpWizardModal,
    privacySensitivityConsentMulti
  };

  return modalFactory;

  function createModal(modalOptions, data, handlers) {
    modalOptions.controllerAs = 'vm';
    modalOptions.resolve = {
      data: function() {
        return data;
      },
      handlers: function() {
        return handlers;
      },
      modalOptions
    };

    return $uibModal.open(modalOptions).result.then(
      function(result) {
        return result;
      },
      function() {
        return false;
      }
    );
  }

  function simpleConfirm(data, handlers) {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal_simple_confirm.html',
      controller: 'modalSimpleConfirmController',
      size: 'medium',
      keyboard: false,
      backdrop: 'static'
    };
    if (data.size) {
      modalOptions.size = data.size;
    }

    return modalFactory.createModal(modalOptions, data, handlers);
  }

  function simpleAlert(data) {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal_simple_alert.html',
      controller: 'modalSimpleAlertController',
      size: 'sm',
      keyboard: false,
      backdrop: 'static'
    };

    return modalFactory.createModal(modalOptions, data);
  }

  function welcome(data) {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal_welcome.html',
      controller: 'modalWelcomeController',
      size: 'lg',
      keyboard: !data,
      backdrop: data ? 'static' : true,
      windowClass: 'welcomeModalWindowClass'
    };

    return modalFactory.createModal(modalOptions, data);
  }

  function termsAndConditions() {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.terms_and_conditions_modal.html',
      controller: 'modalSimpleAlertController',
      size: 'xl'
    };

    return modalFactory.createModal(modalOptions);
  }

  function privacySensitivityConsent(item, tenantType, handlers) {
    var data = {
      title: 'Privacy Warning',
      noText: 'Cancel',
      yesText: 'Accept',
      checkbox: true,
      checkboxText:
        'I have read and accept the <a href="https://www.groundtruth.com/content-and-targeting-guidelines/" target="_blank">GroundTruth End User Content Guidelines</a>.',
      message:
        tenantType !== 0
          ? 'You have targeted ' +
            item.name +
            ' ' +
            item.type +
            ' which is considered potentially privacy sensitive. Your use of Ads Manager is subject to the terms and conditions between you and GroundTruth. By continuing, you certify and agree that the Ad creative and targeting strategy for this campaign is in compliance with (i) all applicable laws, regulations and self-regulatory principles applicable to advertising, including without limitation to the mobile advertising space and the subject matter for this campaign, (ii) the Ads Manager Terms and (iii) the content and targeting restrictions available at GroundTruth Content Guidelines. You are solely responsible for any campaign that you initiate through Ads Manager.'
          : 'You have targeted ' +
            item.name +
            ' ' +
            item.type +
            ' which is considered potentially privacy sensitive. Please contact GroundTruth legal for additional approval.'
    };

    return modalFactory.simpleConfirm(data, handlers);
  }

  function privacySensitivityConsentMulti(items, tenantType, handlers) {
    const getItems = items => items.map(item => item.name).join(', ');
    const verb = items.length > 1 ? 'are' : 'is';
    const itemsType = items.length ? 'brands and/or categories ' : ' ';

    var data = {
      title: 'Privacy Warning',
      noText: 'Cancel',
      yesText: 'Accept',
      checkbox: true,
      checkboxText:
        'I have read and accept the <a href="https://www.groundtruth.com/content-and-targeting-guidelines/" target="_blank">GroundTruth End User Content Guidelines</a>.',
      message:
        tenantType !== 0
          ? 'You have targeted ' +
            itemsType +
            getItems(items) +
            ` which ${verb} considered potentially privacy sensitive. Your use of Ads Manager is subject to the terms and conditions between you and GroundTruth. By continuing, you certify and agree that the Ad creative and targeting strategy for this campaign is in compliance with (i) all applicable laws, regulations and self-regulatory principles applicable to advertising, including without limitation to the mobile advertising space and the subject matter for this campaign, (ii) the Ads Manager Terms and (iii) the content and targeting restrictions available at GroundTruth Content Guidelines. You are solely responsible for any campaign that you initiate through Ads Manager.`
          : 'You have targeted ' +
            itemsType +
            getItems(items) +
            ` which ${verb} considered potentially privacy sensitive. Please contact GroundTruth legal for additional approval.`
    };

    return modalFactory.simpleConfirm(data, handlers);
  }

  function rejectCampaign() {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-reject-campaign.html',
      controller: 'modalRejectCampaignController',
      size: 'sm'
    };

    return modalFactory.createModal(modalOptions);
  }

  function channelTermsAndConditions(data) {
    const modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.channel_terms_and_conditions_modal.html',
      controller: 'modalChannelTermsController',
      size: 'xl'
    };

    return modalFactory.createModal(modalOptions, data);
  }

  function statusModal(data) {
    var modalOptions = {
      template: statusModalTemplate,
      controller: 'statusModalController',
      controllerAs: 'vm',
      size: 'md'
    };

    return modalFactory.createModal(modalOptions, data);
  }

  function campaignCreationHelpWizardModal(data) {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal_campaign_creation_wizard.html',
      controller: 'campaignCreationWizardModalController',
      controllerAs: 'vm',
      size: 'md',
      backdrop: 'true',
      backdropClass: 'campaign-wizard-backdrop'
    };

    return modalFactory.createModal(modalOptions, data);
  }
}
