import { uniq } from 'lodash';

campaignBlacklistController.$inject = [
  '$scope',
  'campaignManagerFactory',
  '$q',
  'csvXlsxUtilsFactory',
  'dataExportFactory',
  '$timeout',
  'featureFlagFactory'
];

export function campaignBlacklistController(
  $scope,
  campaignManagerFactory,
  $q,
  csvXlsxUtilsFactory,
  dataExportFactory,
  $timeout,
  featureFlagFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;

  $scope.booleans = {
    progress: false
  };

  $scope.errorMsg = '';
  $scope.blockedAppName = {
    value: ''
  };
  $scope.blockedDomain = {
    value: ''
  };
  $scope.publisherBlockedAppNames = [];
  $scope.publisherBlockedDomains = [];
  $scope.campaign = null;
  $scope.uploadedBlockedName = {
    chipsNumLimit: 25,
    numMoreChips: 0,
    chipsToShow: []
  };
  $scope.uploadedBlockedDomain = {
    chipsNumLimit: 25,
    numMoreChips: 0,
    chipsToShow: []
  };
  $scope.nameUploadPanelVars = {
    showPanel: false,
    file: null,
    error: false,
    errorMsg: '',
    failed: false,
    submitted: false
  };
  $scope.domainUploadPanelVars = {
    showPanel: false,
    file: null,
    error: false,
    errorMsg: '',
    failed: false,
    submitted: false
  };
  $scope.failedDomains = [];

  var validationBeforeSubmit = function() {
    var errorMap = {
      InvalidBlockItems:
        'Blocked apps and domains cannot contain comma in it. Please remove it to continue with your setup.'
    };
    var errorCharsRegex = '[,]';
    var error = undefined;
    var blockNames = $scope.publisherBlockedAppNames;
    var blockDomains = $scope.publisherBlockedDomains;
    for (var i = 0; i < blockNames.length; i++) {
      if (blockNames[i].search(errorCharsRegex) >= 0) {
        error = errorMap['InvalidBlockItems'];
        break;
      }
    }

    for (var i = 0; i < blockDomains.length; i++) {
      if (blockDomains[i].search(errorCharsRegex) >= 0) {
        error = errorMap['InvalidBlockItems'];
        break;
      }
    }

    return error;
  };

  var refreshChipsToShow = function(type) {
    if (type == 'name') {
      $scope.uploadedBlockedName.numMoreChips = 0;
      $scope.uploadedBlockedName.chipsToShow = $scope.publisherBlockedAppNames;
      var numberOfChipsForName = $scope.publisherBlockedAppNames.length;
      if (numberOfChipsForName > $scope.uploadedBlockedName.chipsNumLimit) {
        $scope.uploadedBlockedName.numMoreChips = numberOfChipsForName - $scope.uploadedBlockedName.chipsNumLimit;
      }
    } else {
      $scope.uploadedBlockedDomain.numMoreChips = 0;
      $scope.uploadedBlockedDomain.chipsToShow = $scope.publisherBlockedDomains;
      var numberOfChipsForDomain = $scope.publisherBlockedDomains.length;
      if (numberOfChipsForDomain > $scope.uploadedBlockedDomain.chipsNumLimit) {
        $scope.uploadedBlockedDomain.numMoreChips = numberOfChipsForDomain - $scope.uploadedBlockedDomain.chipsNumLimit;
      }
    }
  };

  $scope.init = function() {
    var selectedCamapaignSettingsDfd = campaignManagerFactory.getSelectedCampaignWithSettings();
    $q.all([selectedCamapaignSettingsDfd]).then(function(data) {
      // init blocked items
      $scope.campaign = angular.copy(data[0]);
      $scope.publisherBlockedDomains = $scope.campaign.publisherBlockedDomains;
      $scope.publisherBlockedAppNames = $scope.campaign.publisherBlockedAppNames;
      if ($scope.publisherBlockedDomains.length != 0) {
        refreshChipsToShow('domain');
      }
      if ($scope.publisherBlockedAppNames.length != 0) {
        refreshChipsToShow('name');
      }
    });
  };

  $scope.saveCampaignBlackList = function() {
    $scope.booleans.progress = true;

    var validationResult = validationBeforeSubmit();
    if (validationResult !== undefined) {
      $scope.errorMsg = validationResult;
      $scope.booleans.progress = false;
      return;
    }
    $scope.campaign.publisherBlockedAppNames = $scope.publisherBlockedAppNames;
    $scope.campaign.publisherBlockedDomains = $scope.publisherBlockedDomains;
    $scope.errorMsg = '';
    campaignManagerFactory
      .saveCampaign($scope.campaign)
      .then(
        function() {
          $scope.$emit('success-toast', { message: 'Successfully updated exclude settings.' });
          $scope.failedDomains = [];
          $scope.init();
        },
        function(data) {
          let errorMsg = data.message;
          for (const error of data.errors || []) {
            if (error.message.indexOf('Invalid domains: ') === 0) {
              errorMsg = 'Failed to update exclude settings.';
              $scope.failedDomains = error.message
                .substring(0, error.message.length - 1)
                .replace('Invalid domains: ', '')
                .split(',');
              break;
            }
          }
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.progress = false;
      });
  };

  $scope.deleteSelectedBlockedItem = function(index, dataSet) {
    if (!Array.isArray(dataSet) || index < 0) {
      return;
    }
    dataSet.splice(index, 1);
  };

  $scope.addComponentIntoBlockList = function(type, $event) {
    if ($event.which === 13) {
      if (type === 'name') {
        if (!Array.isArray($scope.publisherBlockedAppNames)) {
          $scope.publisherBlockedAppNames = [];
        }
        if (
          $scope.publisherBlockedAppNames.indexOf($scope.blockedAppName.value) >= 0 ||
          $scope.blockedAppName.value == ''
        ) {
          // duplicates are not allowed
          return;
        }
        $scope.publisherBlockedAppNames.push(angular.copy($scope.blockedAppName.value));
        $scope.blockedAppName.value = '';
      } else {
        if (!Array.isArray($scope.publisherBlockedDomains)) {
          $scope.publisherBlockedDomains = [];
        }
        if (
          $scope.publisherBlockedDomains.indexOf($scope.blockedDomain.value) >= 0 ||
          $scope.blockedDomain.value == ''
        ) {
          // duplicates are not allowed
          return;
        }
        $scope.publisherBlockedDomains.push(angular.copy($scope.blockedDomain.value));
        $scope.blockedDomain.value = '';
      }
    }
    refreshChipsToShow(type);
  };

  $scope.onChipRemove = function(item, type) {
    var index;
    if (type == 'name') {
      index = $scope.publisherBlockedAppNames.indexOf(item);
      if (index > -1) {
        $scope.publisherBlockedAppNames.splice(index, 1);
      }
    } else {
      index = $scope.publisherBlockedDomains.indexOf(item);
      if (index > -1) {
        $scope.publisherBlockedDomains.splice(index, 1);
      }
    }
    refreshChipsToShow(type);
  };

  $scope.toggleUploadOptions = function(type) {
    if (type == 'name') {
      $scope.nameUploadPanelVars.showPanel = !$scope.nameUploadPanelVars.showPanel;
      $scope.nameUploadPanelVars.submitted = false;
      $scope.nameUploadPanelVars.file = null;
    } else {
      $scope.domainUploadPanelVars.showPanel = !$scope.domainUploadPanelVars.showPanel;
      $scope.domainUploadPanelVars.submitted = false;
      $scope.domainUploadPanelVars.file = null;
    }
  };

  $scope.selectFile = function(file, type) {
    if (type == 'name') {
      $scope.nameUploadPanelVars.failed = false;
      if (!file) {
        $scope.nameUploadPanelVars.submitted = false;
        $scope.nameUploadPanelVars.error = true;
      } else {
        $scope.nameUploadPanelVars.error = false;
        $scope.nameUploadPanelVars.submitted = true;
      }

      $scope.nameUploadPanelVars.file = file;
    } else {
      $scope.domainUploadPanelVars.failed = false;
      if (!file) {
        $scope.domainUploadPanelVars.submitted = false;
        $scope.domainUploadPanelVars.error = true;
      } else {
        $scope.domainUploadPanelVars.error = false;
        $scope.domainUploadPanelVars.submitted = true;
      }

      $scope.domainUploadPanelVars.file = file;
    }
  };

  $scope.removeAppsFile = function() {
    $scope.nameUploadPanelVars.file = null;
    $scope.nameUploadPanelVars.submitted = false;
    $scope.nameUploadPanelVars.error = false;
    $scope.nameUploadPanelVars.failed = false;
  };

  $scope.removeDomainsFile = function() {
    $scope.domainUploadPanelVars.file = null;
    $scope.domainUploadPanelVars.submitted = false;
    $scope.domainUploadPanelVars.error = false;
    $scope.domainUploadPanelVars.failed = false;
  };

  $scope.closePanel = function(type) {
    if (type == 'name') {
      $scope.nameUploadPanelVars.showPanel = false;
      $scope.nameUploadPanelVars.submitted = false;
      $scope.nameUploadPanelVars.file = null;
    } else {
      $scope.domainUploadPanelVars.showPanel = false;
      $scope.domainUploadPanelVars.submitted = false;
      $scope.domainUploadPanelVars.file = null;
    }
  };

  $scope.clearBlockItem = function(type) {
    if (type == 'name') {
      $scope.publisherBlockedAppNames.length = 0;
    } else {
      $scope.publisherBlockedDomains.length = 0;
      $scope.failedDomains.length = 0;
    }
    refreshChipsToShow(type);
  };

  let validateFile = function(file) {
    const supportedExtensions = ['.csv', '.xls', '.xlsx'];
    const extension = file.slice(file.lastIndexOf('.'));

    return !supportedExtensions.includes(extension);
  };

  $scope.importFile = function(type) {
    if (!$scope.nameUploadPanelVars.file && !$scope.domainUploadPanelVars.file) {
      return;
    }

    if (type == 'name') {
      if (validateFile($scope.nameUploadPanelVars.file.name)) {
        $scope.nameUploadPanelVars.error = true;
        $scope.nameUploadPanelVars.failed = true;
        $scope.nameUploadPanelVars.submitted = false;
        $scope.nameUploadPanelVars.errorMsg = 'This file format is not supported.';
        return;
      }
      $scope.nameUploadPanelVars.error = false;
      $scope.nameUploadPanelVars.errorMsg = '';
      if (!$scope.nameUploadPanelVars.submitted) {
        return;
      }

      $scope.nameUploadPanelVars.failed = false;
    } else {
      if (validateFile($scope.domainUploadPanelVars.file.name)) {
        $scope.domainUploadPanelVars.error = true;
        $scope.domainUploadPanelVars.failed = true;
        $scope.domainUploadPanelVars.submitted = false;
        $scope.domainUploadPanelVars.errorMsg = 'This file format is not supported.';
        return;
      }
      $scope.domainUploadPanelVars.error = false;
      $scope.domainUploadPanelVars.errorMsg = '';
      if (!$scope.domainUploadPanelVars.submitted) {
        return;
      }

      $scope.domainUploadPanelVars.failed = false;
    }

    var reader = new FileReader();
    reader.onload = function(e) {
      $timeout(function() {
        var data = e.target?.result;

        var blockItems = undefined;
        if (type == 'name') {
          blockItems = csvXlsxUtilsFactory.readCsvXlsxFile(data, 'excludedapps');
        } else {
          blockItems = csvXlsxUtilsFactory.readCsvXlsxFile(data, 'excludeddomains');
        }

        // get uniq if user import the same sheet again
        if (blockItems === undefined || (Array.isArray(blockItems) && blockItems[0] === undefined)) {
          errorHandler(type);
          return;
        }
        if (type == 'name') {
          Array.prototype.push.apply($scope.publisherBlockedAppNames, blockItems);
          $scope.publisherBlockedAppNames = uniq($scope.publisherBlockedAppNames);
        } else {
          Array.prototype.push.apply($scope.publisherBlockedDomains, blockItems);
          $scope.publisherBlockedDomains = uniq($scope.publisherBlockedDomains);
        }

        refreshChipsToShow(type);

        if (type == 'name') {
          $scope.nameUploadPanelVars.file = null;
          $scope.nameUploadPanelVars.showPanel = false;
        } else {
          $scope.domainUploadPanelVars.file = null;
          $scope.domainUploadPanelVars.showPanel = false;
        }
      });
    };

    var errorHandler = function(type) {
      if (type == 'name') {
        $scope.nameUploadPanelVars.failed = true;
        $scope.nameUploadPanelVars.submitted = false;
        $scope.nameUploadPanelVars.error = true;
        $scope.nameUploadPanelVars.errorMsg = 'Error in uploading exclude names.';
      } else {
        $scope.domainUploadPanelVars.failed = true;
        $scope.domainUploadPanelVars.submitted = false;
        $scope.domainUploadPanelVars.error = true;
        $scope.domainUploadPanelVars.errorMsg = 'Error in uploading exclude domains.';
      }
    };

    reader.onerror = errorHandler;

    if (type == 'name') {
      reader.readAsBinaryString($scope.nameUploadPanelVars.file);
    } else {
      reader.readAsBinaryString($scope.domainUploadPanelVars.file);
    }
  };

  $scope.downloadFailedDomains = function() {
    var filename = 'campaign-' + $scope.campaign.id + '-failed-domains.csv';
    $scope.downloadList($scope.failedDomains, filename, 'ExcludedDomains');
  };

  $scope.downloadBlockedAppNames = function() {
    campaignManagerFactory
      .getCampaignBlockedAppNames($scope.campaign.id)
      .then(
        function(data) {
          var filename = 'campaign-' + $scope.campaign.id + '-excluded-app-names.csv';
          $scope.downloadList(data, filename, 'ExcludedApps');
        },
        function(msg) {
          $scope.errorMsg = 'Failed to download excluded app names.';
        }
      )
      .finally(function() {});
  };

  $scope.downloadBlockedDomains = function() {
    campaignManagerFactory
      .getCampaignDomains($scope.campaign.id, 1)
      .then(
        function(data) {
          var filename = 'campaign-' + $scope.campaign.id + '-excluded-domains.csv';
          $scope.downloadList(data, filename, 'ExcludedDomains');
        },
        function(msg) {
          $scope.errorMsg = 'Failed to download excluded domains.';
        }
      )
      .finally(function() {});
  };

  $scope.downloadList = function(list, fileName, columnName) {
    if (list === null || !list.length) {
      // Hack to make the CSV builder happy
      list = [''];
    }

    var args = {
      data: list.map(function(item) {
        return { item: item };
      }),
      keys: ['item'],
      header: [columnName]
    };
    var csv = dataExportFactory.convertArrayOfObjectsToCSV(args);
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    var link = document.createElement('a');
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', fileName);
    link.click();
  };

  $scope.init();
}
