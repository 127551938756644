import { isEmpty } from 'lodash';
import { safeApply } from '../../../factories/general_utils';
import { BudgetType } from '../../../../ui/api/types/common.ts';
import GAEventList from '../../../components/googleAnalytics/eventList';

export class budgetDateController {
  /**
   * @param {import('@uirouter/angularjs').StateService} $state
   * @param {ng.IQService} $q
   * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
   * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
   * @param {import('../../../factories/static_data.fac').IStaticDataFactory} staticDataFactory
   * @param {import('../adgroup_targeting.fac').adgroupTargetingFactory} adgroupTargetingFactory
   * @param {ng.IWindowService} $window
   */
  constructor(
    $scope,
    $state,
    $q,
    generalUtilsFactory,
    companyAndAccountFactory,
    selfServeDataFactory,
    campaignManagerFactory,
    staticDataFactory,
    searchFactory,
    tenantFactory,
    modalFactory,
    featureFlagFactory,
    adgroupTargetingFactory,
    $window
  ) {
    this.$scope = $scope;
    this.$state = $state;
    this.$q = $q;
    this.generalUtilsFactory = generalUtilsFactory;
    this.companyAndAccountFactory = companyAndAccountFactory;
    this.selfServeDataFactory = selfServeDataFactory;
    this.campaignManagerFactory = campaignManagerFactory;
    this.staticDataFactory = staticDataFactory;
    this.searchFactory = searchFactory;
    this.tenantFactory = tenantFactory;
    this.modalFactory = modalFactory;
    this.featureFlagFactory = featureFlagFactory;
    this.adgroupTargetingFactory = adgroupTargetingFactory;
    this.$window = $window;

    this.technographicsOptions = this.adgroupTargetingFactory.getTechnographicsOptions;

    const today = new Date();

    this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    this.adgroupStartDateDisabled = false;
    this.$scope.errors = [];
    this.$scope.showErrorTt = false;

    this.campaignEndDate = campaignManagerFactory.selectedCampaign.timeframe.end;
    this.isCampaignDailyBudget = campaignManagerFactory.selectedCampaign.budgetType == BudgetType.DAILY;

    this.pixels = [];
    this.adGroup = angular.copy(selfServeDataFactory.nullAdGroup);

    this.booleans = $scope.booleans || {};

    this.booleans.adPackageTableChanged = false;
    this.booleans.validPixelsProgress = false;
    this.booleans.budgetDataLoaded = false;

    this.billabilityOptions = [
      {
        value: 'BILLABLE',
        name: 'Billable'
      },
      {
        value: 'ADDED_VALUE',
        name: 'Added Value'
      },
      {
        value: 'MAKE_GOOD',
        name: 'Make Good'
      },
      {
        value: 'SURVEY',
        name: 'Survey'
      },
      {
        value: 'TEST',
        name: 'Test'
      }
    ];

    this.svlVendors = angular.copy(staticDataFactory.svlVendors);

    this.biddingPacingModeOptions = angular.copy(staticDataFactory.biddingPacingModes);

    if (campaignManagerFactory.selectedCampaign?.is_cpv) {
      this.bidTypes = ['CPV'];
    } else {
      this.bidTypes = ['CPM'];
      // feature enable only for ADGROUP_BID_TYPE_CPC
      if (featureFlagFactory.isFeatureEnabled('ADGROUP_BID_TYPE_CPC')) {
        this.bidTypes.push('CPC');
      }
    }

    this.adGroup.time_period_budgets = [];

    this.adGroup.targeting.daypartingOptions = [
      'All Times',
      'Weekend',
      'Weekdays',
      'Early Morning',
      'Evenings',
      'Late Night',
      'Working Hours',
      'School Hours',
      'Happy Hour',
      'Custom'
    ];

    this.initBudgetData();
  }

  showBiddingPacingMode() {
    const featureFlagFactory = this.featureFlagFactory;
    return this.biddingPacingModeOptions.some(function(option) {
      return featureFlagFactory.isFeatureEnabled(option.featureFlagName);
    });
  }

  updateBidPriceLabel() {
    if (this.adGroup.bidType == 'CPM') {
      this.bidPriceLabel = 'Impressions';
      if (this.isCampaignDailyBudget) {
        this.bidPriceLabel = 'Daily Impressions';
      }
    } else if (this.adGroup.bidType == 'CPV') {
      this.bidPriceLabel = 'Visit Goals';
    } else {
      this.bidPriceLabel = 'Clicks';
    }
  }
  handleDatesChange = (startDate, endDate, index) => {
    if (startDate != null) {
      this.adGroup.time_period_budgets[index].startDate = startDate;
    }
    this.adGroup.time_period_budgets[index].endDate = endDate;
  };

  searchAdPackage(query) {
    const filter = {
      targeting_search: 1,
      filter_measured: 1,
      campaign_id: this.campaignManagerFactory.selectedCampaign.id,
      entity_types: 'adpackage'
    };
    return this.searchFactory.getSearchResults(query, filter).then(results => {
      if (!isEmpty(results)) {
        return { 'Ad Package': results };
      }
      if (query) {
        return { 'Create Ad Package': [{ name: query }] };
      }
      return {};
    });
  }

  makeDaypartCustom() {
    this.adGroup.appData.selectedDaypartOption.option = 'Custom';
  }

  selectDaypartOption() {
    this.clearDaypartSelection();
    this.adGroup.appData.selectedDaypartOption.option = this.selectedDaypart;
    if (this.selectedDaypart == 'All Times') {
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < 24; j++) {
          this.adGroup.targeting.dayParting[i].hours[j].status = true;
        }
      }
    }
    if (this.selectedDaypart == 'Weekend') {
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < 24; j++) {
          if (i == 0 || i == 6) {
            this.adGroup.targeting.dayParting[i].hours[j].status = true;
          }
        }
      }
    }
    if (this.selectedDaypart == 'Weekdays') {
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < 24; j++) {
          if (i != 0 && i != 6) {
            this.adGroup.targeting.dayParting[i].hours[j].status = true;
          }
        }
      }
    }
    if (this.selectedDaypart == 'Early Morning') {
      //Early Morning = 5AM - 9AM
      for (let i = 0; i < 7; i++) {
        for (let j = 5; j < 10; j++) {
          this.adGroup.targeting.dayParting[i].hours[j].status = true;
        }
      }
    }
    if (this.selectedDaypart == 'Evenings') {
      //Evenings = 5PM - 12AM
      for (let i = 0; i < 7; i++) {
        this.adGroup.targeting.dayParting[i].hours[0].status = true;
        for (let j = 17; j < 24; j++) {
          this.adGroup.targeting.dayParting[i].hours[j].status = true;
        }
      }
    }
    if (this.selectedDaypart == 'Late Night') {
      //Late Night = 10PM-3AM
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < 4; j++) {
          this.adGroup.targeting.dayParting[i].hours[j].status = true;
        }
        for (let j = 22; j < 24; j++) {
          this.adGroup.targeting.dayParting[i].hours[j].status = true;
        }
      }
    }
    if (this.selectedDaypart == 'Working Hours') {
      //Working Hours 9AM - 5PM
      for (let i = 0; i < 7; i++) {
        for (let j = 9; j < 18; j++) {
          if (i != 0 && i != 6) {
            this.adGroup.targeting.dayParting[i].hours[j].status = true;
          }
        }
      }
    }
    if (this.selectedDaypart == 'School Hours') {
      //School Hours 9AM - 5PM
      for (let i = 0; i < 7; i++) {
        for (let j = 8; j < 16; j++) {
          if (i != 0 && i != 6) {
            this.adGroup.targeting.dayParting[i].hours[j].status = true;
          }
        }
      }
    }
    if (this.selectedDaypart == 'Happy Hour') {
      //Happy Hour 9AM - 5PM
      for (let i = 0; i < 7; i++) {
        for (let j = 18; j < 21; j++) {
          this.adGroup.targeting.dayParting[i].hours[j].status = true;
        }
      }
    }
    if (this.selectedDaypart == 'Clear' || this.selectedDaypart == 'Custom') {
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < 24; j++) {
          this.adGroup.targeting.dayParting[i].hours[j].status = false;
        }
      }
    }
    if (this.selectedDaypart == 'Clear') {
      this.adGroup.appData.selectedDaypartOption.option = 'Custom';
    } else {
      this.adGroup.appData.selectedDaypartOption.option = this.selectedDaypart;
    }
  }

  totalBudgetChanged() {
    let totalBudget = 0;
    this.adGroup.time_period_budgets.forEach((budget, index) => {
      if (budget.budget == undefined) {
        budget.budget = 0;
      }
      totalBudget = totalBudget + budget.budget;
    });
    this.adGroup.totalBudget = totalBudget;
  }

  trackingPixelModel() {
    const modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.trackingPixelAdGroupModal.html',
      controller: 'trackingPixelAdGroupModalController'
    };

    const data = {
      pixels: this.pixels,
      adGroupPixels: this.adGroup.targeting.adGroupPixels
    };

    this.modalFactory.createModal(modalOptions, data).then(result => {
      if (result) {
        this.adGroup.targeting.adGroupPixels = result;
      }
    });
  }

  removePixel(pixel, index) {
    this.adGroup.targeting.adGroupPixels.splice(index, 1);
  }

  gotoTrackingPixel() {
    this.$state.go('account', {
      selectedCompanyId: this.companyAndAccountFactory.requireSelectedCompany().id,
      selectedAccountId: this.companyAndAccountFactory.requireSelectedAccount().id,
      tab: 'Tracking Pixels'
    });
  }

  addTimePeriodBudget() {
    const length = this.adGroup.time_period_budgets.length;
    this.adGroup.budget_carryover = true;
    if (length > 0) {
      const obj = angular.copy(this.adGroup.time_period_budgets[length - 1]);

      obj.startDate = this.generalUtilsFactory.getCorrectedJSdateFromString(obj.startDate);
      obj.endDate = this.generalUtilsFactory.getCorrectedJSdateFromString(obj.endDate);

      // Check if the budgetType is DAILY
      if (this.campaignManagerFactory.selectedCampaign.budgetType === BudgetType.DAILY) {
        // Set startDate to 7 days ahead and endDate to null
        if (obj.endDate == null) {
          obj.startDate.setTime(obj.startDate.getTime() + 7 * 86400000);
        } else {
          obj.startDate.setTime(obj.endDate.getTime() + 1 * 86400000);
        }
        obj.endDate = null;
      } else {
        // setting new time period with next date
        obj.startDate.setTime(obj.endDate.getTime() + 1 * 86400000);
        obj.endDate.setTime(obj.startDate.getTime() + 7 * 86400000);
      }

      obj.startDate = this.generalUtilsFactory.DateToString(obj.startDate);
      obj.endDate = this.generalUtilsFactory.DateToString(obj.endDate);
      // watch
      obj.watcher = this.createWatcher(obj);
      obj.index = length;
      this.adGroup.time_period_budgets.push(obj);
    }
  }

  removeTimePeriodBudget(item) {
    if (this.adGroup.time_period_budgets.length > 1) {
      // unwatch
      item.watcher();
      this.adGroup.time_period_budgets.splice(item.index, 1);

      for (let i = 0; i < this.adGroup.time_period_budgets.length; i++) {
        this.adGroup.time_period_budgets[i].index = i;
      }
      if (this.adGroup.time_period_budgets.length == 1) {
        this.adGroup.budget_carryover = false;
      }
    }
  }

  removePreviousAdPackage() {
    if (this.adGroup.adPackage.length > 1) {
      this.adGroup.adPackage.splice(0, 1);
    }
    this.booleans.adPackageTableChanged = true;
  }

  showAddedCostCPM() {
    return (
      !this.campaignManagerFactory?.selectedCampaign?.is_cpv &&
      this.featureFlagFactory.isFeatureEnabled('ADDED_COST_CPM') &&
      this.adGroup.bidType === 'CPM'
    );
  }

  showGrossCPM() {
    return (
      !this.campaignManagerFactory?.selectedCampaign?.is_cpv &&
      this.featureFlagFactory.isFeatureEnabled('END_CLIENT_REPORTING') &&
      this.adGroup.bidType === 'CPM'
    );
  }

  addWatcher() {
    this.$scope.$watch(
      () => {
        return angular.toJson(this.adGroup);
      },
      (newVal, oldVal) => {
        if (newVal !== oldVal && this.$scope.updateFns.availsUpdateFn) {
          this.$scope.updateFns.availsUpdateFn(this.adGroup);
        }
      }
    );
  }

  areStreamingServicesSelected() {
    return (
      this.adGroup?.targeting?.deviceTypes.includes(this.technographicsOptions.device.CTV.value) ||
      this.adGroup?.targeting?.deviceTypes.includes(this.technographicsOptions.device.OTT.value)
    );
  }

  isDesktopTargetingSelected() {
    return (
      this.adGroup?.targeting?.deviceTypes.includes(this.technographicsOptions.device.Desktop.value) &&
      this.featureFlagFactory.isFeatureEnabled('DESKTOP_TARGETING')
    );
  }

  showCPC() {
    return (
      !this.campaignManagerFactory?.selectedCampaign?.is_cpv &&
      this.featureFlagFactory.isFeatureEnabled('ADGROUP_BID_TYPE_CPC') &&
      !this.isCampaignDailyBudget &&
      !this.isDesktopTargetingSelected() &&
      !this.areStreamingServicesSelected()
    );
  }

  async saveBudgetAndSchedule(launch) {
    const d = this.$q.defer();

    this.adGroup.timeframe.start = this.generalUtilsFactory.DateToString(this.startDate); // utc date
    this.adGroup.timeframe.end = this.generalUtilsFactory.DateToString(this.endDate); // utc date

    this.adGroup.time_period_budgets = angular.copy(this.adGroup.time_period_budgets);
    this.adGroup.time_period_budgets.forEach(budget => {
      budget.startDate = this.generalUtilsFactory.DateToString(budget.startDate);
      budget.endDate = this.generalUtilsFactory.DateToString(budget.endDate);
    });
    this.adGroup.svlVendors = [];
    this.svlVendors.forEach(value => {
      if (value.selected) {
        this.adGroup.svlVendors.push(value.key);
      }
    });

    this.adGroup.pacingMode = this.adGroup.pacingMode || 'DEFAULT';

    const prevStatusToRevertOnFail = this.adGroup.status;
    if (launch) {
      this.adGroup.status = 'Active';
    }
    // To prevent watcher's to watch for changes in imp, we are copying the this.adGroup and then making changes in it
    const adGroupCopy = angular.copy(this.adGroup);
    adGroupCopy.time_period_budgets.forEach(budget => {
      budget.projection = Math.round(budget.projection);
    });

    this.campaignManagerFactory.saveAdGroupBudget(adGroupCopy, this.campaignManagerFactory.selectedCampaign.id).then(
      data => {
        d.resolve();
        // We have updated the campaign's adgroup budget, logging this event into GA
        let GAEventType = GAEventList.CAMPAIGN_BUDGET_SAVE;
        // --- checking for `save` vs `save and launch` ---
        if (!!launch) GAEventType = GAEventList.CAMPAIGN_SAVE_AND_LAUNCH;
        typeof this.$window.gtag === 'function' &&
          this.$window.gtag('event', GAEventType, {
            adgroup_id: adGroupCopy?.id,
            campaign_id: this.campaignManagerFactory.selectedCampaign.id,
            gt_user_id: this.$window?.user?.id,
            tenant_id: this.companyAndAccountFactory.selectedTenant?.tenant_id
          });

        angular.copy([], this.$scope.errors);
        this.$scope.showErrorTt = false;
        this.initBudgetData();
      },
      errors => {
        this.adGroup.status = prevStatusToRevertOnFail;
        if (typeof errors === 'object') {
          angular.copy(errors, this.$scope.errors);
        } else {
          angular.copy(
            {
              misc: errors
            },
            this.$scope.errors
          );
        }
        if (this.$scope.errors && this.$scope.errors.length) {
          this.$scope.showErrorTt = true;
        } else {
          this.$scope.showErrorTt = false;
        }
        d.reject(errors);
      }
    );

    safeApply(this.$scope);

    return d.promise;
  }

  updateImpression(index) {
    let budget = this.adGroup.time_period_budgets[index].budget;
    if (budget == undefined || isNaN(budget)) {
      return;
    }
    // calculate impressions
    if (this.adGroup.bidType == 'CPM' && this.adGroup.bidRate) {
      this.adGroup.time_period_budgets[index].projection =
        (1000 * this.adGroup.time_period_budgets[index].budget) / this.adGroup.bidRate;
    }

    //calculate clicks or visits
    else if ((this.adGroup.bidType == 'CPC' || this.adGroup.bidType == 'CPV') && this.adGroup.bidRate) {
      this.adGroup.time_period_budgets[index].projection =
        this.adGroup.time_period_budgets[index].budget / this.adGroup.bidRate;
    }
  }

  updateTotalBudget(index) {
    if (this.adGroup.bidType == 'CPM' && this.adGroup.time_period_budgets[index].projection && this.adGroup.bidRate) {
      this.adGroup.time_period_budgets[index].budget = +(
        (this.adGroup.time_period_budgets[index].projection / 1000) *
        this.adGroup.bidRate
      ).toFixed(2);
    }

    if (
      (this.adGroup.bidType == 'CPC' || this.adGroup.bidType == 'CPV') &&
      this.adGroup.time_period_budgets[index].projection &&
      this.adGroup.bidRate
    ) {
      this.adGroup.time_period_budgets[index].budget = +(
        this.adGroup.time_period_budgets[index].projection * this.adGroup.bidRate
      ).toFixed(2);
    }
  }

  clearDaypartSelection() {
    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < 24; j++) {
        this.adGroup.targeting.dayParting[i].hours[j].status = false;
      }
    }
    this.makeDaypartCustom();
  }

  checkForChanges(newValue) {
    if (this.adGroup.time_period_budgets[newValue.index].projection !== newValue.imp) {
      this.impChanged = true;
    }

    if (this.adGroup.time_period_budgets[newValue.index].budget !== newValue.bud) {
      this.budgetChanged = true;
    }

    if (this.adGroup.bidType !== newValue.bidType) {
      this.bidTypeChanged = true;
    }
  }

  createWatcher(item) {
    return this.$scope.$watch(
      () => ({
        index: item.index,
        imp: item.projection,
        bud: item.budget,
        bid: this.adGroup.bidRate,
        bidType: this.adGroup.bidType
      }),
      (newValue, oldValue) => {
        if (newValue.imp !== oldValue.imp) {
          if (this.impChanged) {
            this.impChanged = false;
            return;
          }
          this.updateTotalBudget(newValue.index);
          this.checkForChanges(newValue);
        } else if (newValue.bidType !== oldValue.bidType) {
          if (this.bidTypeChanged) {
            this.bidTypeChanged = false;
            return;
          }
          this.updateImpression(newValue.index);
          this.checkForChanges(newValue);
        } else if (newValue.bid !== oldValue.bid) {
          this.updateImpression(newValue.index);
          this.checkForChanges(newValue);
        } else if (newValue.bud !== oldValue.bud) {
          if (this.budgetChanged) {
            this.budgetChanged = false;
            return;
          }
          this.updateImpression(newValue.index);
          this.checkForChanges(newValue);
        }
      },
      true
    );
  }

  async initBudgetData() {
    try {
      this.$scope.$emit('in progress');
      this.adgroupStartDateDisabled = true;

      const trackingPixelDfd = await this.companyAndAccountFactory.getTrackingPixels();
      const selectedAdGroupDfd = await this.campaignManagerFactory.getSelectedAdGroup();

      this.pixels = trackingPixelDfd;
      this.generalUtilsFactory.nestedUpdateInPlace(selectedAdGroupDfd, this.adGroup);

      // set default bidtype. This is necessary when we create new ad group
      if (this.adGroup.bidType === null || this.adGroup.bidType === undefined) {
        this.adGroup.bidType = this.bidTypes[0];
      }
      this.adGroup.budgetPadding = this.adGroup.budgetPadding === 0 ? null : this.adGroup.budgetPadding;
      this.updateBidPriceLabel();
      this.adgroupStartDateDisabled = this.adGroup.spends_spent > 0;

      if (!this.adGroup.adPackage) {
        this.adGroup.adPackage = [];
      }
      this.booleans.adPackageTableChanged = true;

      this.isPlatformPricing = this.tenantFactory.tenant.platform_pricing;
      this.adGroup.pacingMode = this.adGroup.pacingMode || 'DEFAULT';

      this.svlVendors.forEach(svlVendor => {
        this.adGroup.svlVendors.forEach(adgroupSvlVendor => {
          if (svlVendor.key === adgroupSvlVendor) {
            svlVendor.selected = true;
          }
        });
      });

      if (Array.isArray(this.adGroup.time_period_budgets) && this.adGroup.time_period_budgets.length > 0) {
        for (let i = 0; i < this.adGroup.time_period_budgets.length; i++) {
          this.adGroup.time_period_budgets[i].index = i;
          this.adGroup.time_period_budgets[i].budget =
            this.adGroup.time_period_budgets[i].budget === 0 ? null : this.adGroup.time_period_budgets[i].budget;
          if (this.campaignManagerFactory.selectedCampaign.budgetAtCampaignLevel) {
            this.adGroup.totalBudget = null;
            this.adGroup.time_period_budgets[i].budget = null;
          }

          let currentDate = this.generalUtilsFactory.getCurrentDate();

          let formattedStartDateStr = this.generalUtilsFactory.getCorrectedJSdateFromString(
            this.adGroup.time_period_budgets[i].startDate
          );
          if (formattedStartDateStr) {
            const formattedCurrentDateStr = this.generalUtilsFactory.getCorrectedJSdateFromString(currentDate);
            if (formattedCurrentDateStr && formattedStartDateStr <= formattedCurrentDateStr) {
              this.adGroup.time_period_budgets[i].minStartDate = this.adGroup.time_period_budgets[i].startDate;
            } else {
              this.adGroup.time_period_budgets[i].minStartDate = currentDate;
            }
          }
          this.adGroup.time_period_budgets[i].minEndDate = currentDate;

          this.updateImpression(i);

          this.adGroup.time_period_budgets[i].watcher = this.createWatcher(this.adGroup.time_period_budgets[i]);
        }
      }

      this.adGroup.appData.selectedDaypartOption.option = this.selfServeDataFactory.testDayPartOption(
        this.adGroup.targeting.dayParting
      );

      if (this.adGroup.appData.selectedDaypartOption.option != null) {
        this.selectedDaypart = this.adGroup.appData.selectedDaypartOption.option;
      } else {
        this.selectedDaypart = 'All Times';
      }

      this.$scope.$parent.saveAdgroup.budgetAndSchedules = this.saveBudgetAndSchedule.bind(this);

      this.$scope.$broadcast('load adgroup');
      this.booleans.budgetDataLoaded = true;
    } catch (e) {
      console.error(e);
    } finally {
      safeApply(this.$scope);
      this.addWatcher();
      this.$scope.$emit('end progress');
    }
  }
}

budgetDateController.$inject = [
  '$scope',
  '$state',
  '$q',
  'generalUtilsFactory',
  'companyAndAccountFactory',
  'selfServeDataFactory',
  'campaignManagerFactory',
  'staticDataFactory',
  'searchFactory',
  'tenantFactory',
  'modalFactory',
  'featureFlagFactory',
  'adgroupTargetingFactory',
  '$window'
];
