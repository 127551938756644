export function xadListInput() {
  return {
    restrict: 'E',
    replace: false,
    scope: {
      label: '@',
      modelArr: '=',
      mraidOk: '=?',
      optional: '=?'
    },
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.list_input.html',
    link: function(scope, element, attrs) {
      scope.booleans = {
        notAUrl: false
      };
      scope.itemToAdd = '';
      scope.addItem = function() {
        if (scope.itemToAdd) {
          if (
            scope.itemToAdd.startsWith('http://') ||
            scope.itemToAdd.startsWith('https://') ||
            (scope.mraidOk && scope.itemToAdd.toLowerCase() == 'mraid.js')
          ) {
            scope.booleans.notAUrl = false;
            scope.modelArr.push(scope.itemToAdd);
            scope.itemToAdd = '';
          } else {
            scope.booleans.notAUrl = true;
          }
        }
      };
      scope.removeItem = function(index) {
        scope.modelArr.splice(index, 1);
      };
    },
    controller: function() {}
  };
}
