import { observable, action, computed, runInAction } from 'mobx';
import { promisedComputed } from 'computed-async-mobx';
import { send$http } from '../../../api/def/ngExecutor';
import {
  getReports,
  getReport,
  getReportTypes,
  ReportType,
  createReport,
  updateReport,
  ReportInput,
  PreviewReport as Report,
  ReportStatus,
  updateReportStatus,
  deleteReport,
  UpdateReportStatus
} from '../../../api/http/reports';
import { $http, $state } from '../../../utils/services';
import { ReportModel } from './report_model';

export { Report, ReportStatus, ReportType };

export class ReportSchedulerModel {
  @observable.ref reports: Report[] | null = null;
  @observable itemsPerPage: number = 10;

  private _reportTypes = promisedComputed<ReportType[] | null>(null, async () => {
    const response = await send$http($http, getReportTypes);
    return response.report_types;
  });

  @computed get reportTypes() {
    return this._reportTypes.get();
  }

  @computed get getItemsPerPage() {
    return this.itemsPerPage;
  }

  @action async loadReports() {
    const reportList = await send$http($http, getReports);
    runInAction(() => {
      this.reports = reportList.reports;
    });
  }

  @action updateItemsPerPage(itemsPerpage) {
    this.itemsPerPage = itemsPerpage;
  }

  private async updateReportStatus(report: Report, status: UpdateReportStatus) {
    const updateReportStatusResponse = await send$http($http, updateReportStatus, {
      params: { report_id: report.report_id },
      data: { status }
    });
    if (updateReportStatusResponse.success) {
      this.loadReports();
    } else {
      throw 'Cannot change status of the report';
    }
  }

  @action async resumeReport(report: Report) {
    await this.updateReportStatus(report, UpdateReportStatus.PENDING);
  }

  @action async pauseReport(report: Report) {
    await this.updateReportStatus(report, UpdateReportStatus.PAUSED);
  }

  @action async deleteReport(report: Report) {
    const deleteReportResponse = await send$http($http, deleteReport, { params: { report_id: report.report_id } });
    if (deleteReportResponse.success) {
      this.loadReports();
    } else {
      throw 'Cannot delete report';
    }
  }

  @action async loadReportDetails(id: number) {
    const response = await send$http($http, getReport, { params: { report_id: id } });
    return new ReportModel(response.report);
  }

  @action async createReport(data: ReportInput) {
    const response = await send$http($http, createReport, { data: data });
    return response;
  }

  @action async updateReportDetails(id: number, data: ReportInput) {
    const response = await send$http($http, updateReport, { params: { report_id: id }, data: data });
    return response;
  }

  @action.bound navigateToReportListPage() {
    $state.go('report-scheduler');
  }

  @action.bound navigateToReportCreatePage() {
    $state.go('report-scheduler-create');
  }

  @action.bound navigateToReportEditPage(reportId: number) {
    $state.go('report-scheduler-edit', { reportId });
  }
}
