import { configure } from 'mobx';
import { StoreModel } from './store_model';

export const store = new StoreModel();

if (IS_DEV) {
  // make store available as a global variable on window
  (window as any)._store = store;
  // turn on strict mode on mobx
  configure({
    enforceActions: 'observed'
  });
}
