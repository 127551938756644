import { modalChannelTermsController } from './modal-channel-terms.ctrl';
import { ColumnPickerModalController } from './modal-column-picker.ctrl';
import { ProductPickerModalController } from './modal-product-picker.ctrl';
import { modalRejectCampaignController } from './modal-reject-campaign.ctrl';
import { modalSimpleAlertController } from './modal-simple-alert.ctrl';
import { modalSimpleConfirmController } from './modal-simple-confirm.ctrl';
import { statusModalController } from './statusModal/statusModalController';
import { campaignCreationWizardModalController } from './modal-campaign-creation-wizard';
import { modalWelcomeController } from './modal-welcome.ctrl';
import { modalFactory } from './modal.fac';

export const controllers = {
  modalChannelTermsController,
  ColumnPickerModalController,
  ProductPickerModalController,
  modalRejectCampaignController,
  modalSimpleAlertController,
  modalSimpleConfirmController,
  modalWelcomeController,
  statusModalController,
  campaignCreationWizardModalController
};

export const factories = {
  modalFactory
};
