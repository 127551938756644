import { gtBlue, gtGreen, gtGray, primary2Palette, primary3Palette } from './colorConstants';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import { AutocompleteClassKey } from '@material-ui/lab';
import { ThemeOptions, createTheme } from '@material-ui/core/styles';

type OverridesNameToClassKey = Required<
  {
    [P in keyof MuiPickersOverrides]: keyof NonNullable<MuiPickersOverrides[P]>;
  }
>;

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends OverridesNameToClassKey {
    MuiAutocomplete: AutocompleteClassKey;
  }
}

const customTheme: ThemeOptions = {
  palette: {
    primary: {
      main: gtBlue
    },
    secondary: {
      main: gtGreen
    }
  },
  typography: {
    fontFamily: 'Akkurat Std Light, Flexo-Medium, sans-serif',
    htmlFontSize: 10, // this values of '10px' of html font size is used in bootstrap
    fontSize: 14 // this is a desired value of MUI size
  },
  shape: {
    borderRadius: 0
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: gtGray,
        color: 'white'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 2,
        fontWeight: 'bold',
        height: 40,
        textTransform: 'none',
        fontSize: 14
      },
      contained: {
        color: 'white',
        backgroundColor: gtBlue,
        '&:hover': {
          backgroundColor: '#2e73ab'
        },
        fontWeight: 100
      },
      outlinedPrimary: {
        backgroundColor: 'white',
        borderColor: primary3Palette['200'],
        border: 'solid 1px',
        color: gtBlue
      }
    },
    MuiPickersDay: {
      daySelected: {
        '&:hover': {
          backgroundColor: primary2Palette['200']
        }
      }
    },
    MuiInputLabel: {
      formControl: {
        position: 'initial',
        transform: 'none',
        marginBottom: '8px'
      }
    },
    MuiFormLabel: {
      root: {
        '&focused': {
          color: () => gtTheme.palette.text.secondary
        }
      }
    },
    MuiFormControl: {
      root: {
        marginTop: 10,
        minWidth: 400
      }
    },
    MuiAutocomplete: {
      option: {
        paddingTop: 'auto',
        paddingBottom: 'auto'
      },
      root: {
        minWidth: 400,
        marginTop: 16,
        marginBottom: 8
      }
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderWidth: 1
        }
      }
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0
      }
    },
    MuiTooltip: {
      arrow: {
        color: 'black'
      },
      tooltip: {
        backgroundColor: 'black',
        fontSize: 14
      }
    },
    MuiTypography: {
      h5: {
        fontSize: 15
      },
      h4: {
        fontSize: 22
      },
      h3: {
        fontSize: 29
      },
      h2: {
        fontSize: 42
      },
      h1: {
        fontSize: 60
      }
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        backgroundColor: primary2Palette['50']
      },
      label: {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    MuiTextField: {
      root: {
        marginTop: 0
      }
    }
  },
  props: {
    MuiInputLabel: {
      variant: 'standard',
      shrink: false,
      disableAnimation: true
    },
    MuiOutlinedInput: {
      notched: false
    },
    MuiTextField: {
      variant: 'outlined'
    },
    MuiInputBase: {
      margin: 'dense'
    },
    MuiCheckbox: {
      color: 'primary'
    },
    MuiTooltip: {
      arrow: true
    }
  }
};

export const gtTheme = createTheme(customTheme);
