import GAEventList from '../../components/googleAnalytics/eventList';
import { CREDIT_CARD_TENANT_TYPES, NotificationTypes, TenantPaymentType } from '../../api/types/common';

paymentsController.$inject = [
  '$scope',
  'paymentsFactory',
  'modalFactory',
  '$filter',
  'notificationFactory',
  'featureFlagFactory',
  'companyAndAccountFactory',
  '$window'
];

/**
 * @param {import('../../factories/types').PaymentsFactory} paymentsFactory
 */
export function paymentsController(
  $scope,
  paymentsFactory,
  modalFactory,
  $filter,
  notificationFactory,
  featureFlagFactory,
  companyAndAccountFactory,
  $window
) {
  $scope.isNewAccount;

  $scope.init = function() {
    $scope.featureFlagFactory = featureFlagFactory;
    $scope.paymentsProgress = true;
    $scope.cardRetryProgress = false;
    $scope.promotionsProgress = false;
    $scope.allAccounts = companyAndAccountFactory.allAccounts;
    $scope.isPaymentModalOpen = false;

    $scope.hasFullPaymentsAccess = CREDIT_CARD_TENANT_TYPES.includes(
      companyAndAccountFactory.selectedTenant?.payment_type
    );
    $scope.isAccountLevelCreditCardsEnabled =
      companyAndAccountFactory.selectedTenant?.payment_type === TenantPaymentType.ACCOUNT_LEVEL_CREDIT_CARD;

    $scope.excludeNotificationTypes = [NotificationTypes.NO_VALID_ACCOUNT_LEVEL_CREDIT_CARD];

    // load all data on initialization if the tenant has full billing page access, else just load the statements
    // NOTE: these get calls don't need the customer to be preloaded because backend will fetch Customer ID
    if ($scope.hasFullPaymentsAccess) {
      var customerPromise = $scope.loadCustomer();
      var cardsPromise = $scope.loadCards();
      $scope.loadPromotions();
      $scope.loadBillingThreshold();
      $scope.cardStatus = paymentsFactory.cardStatus;

      // customer and card info are essential, other failures are non-fatal
      Promise.all([customerPromise, cardsPromise]).then(function() {
        $scope.paymentsProgress = false;

        /**
         * Checking if the parent is `new-account.html`, if yes pre-select 'Primary card'
         *
         * To check that, we're defining a variable 'isNewAccount'
         * And then updating this variable from the ng-include definition.
         */
        if ($scope.isNewAccount === true) $scope.selectPrimaryCard();

        // Finally updating the scope
        $scope.$apply();
      });
    } else {
      $scope.paymentsProgress = false;
    }
    $scope.loadStatements();
  };

  /**
   * Function to poplutate `Primary card` by default when this component is used in the new account create modal
   */
  $scope.selectPrimaryCard = function() {
    // If ALCC feature is enabled for the tanant then populate primary card by default
    if ($scope.isAccountLevelCreditCardsEnabled) {
      if ($scope.cards.length > 0) {
        // We have multiple cards, we filter out the primary cards
        const primaryCardArray = $scope.cards.filter(card => card?.card_id === $scope.customer?.default_card_id);
        /*
         * The following code make sure that there is an existing primary card.
         *
         * Note:
         * There could just be one "primary card"
         * Hence the second parameter of 'selectCard' function will always be = 1.
         */
        if (primaryCardArray.length > 0) $scope.selectCard(primaryCardArray[0], primaryCardArray.length);
      }
    }
  };

  // check if the page is loading in any kind of way
  $scope.isLoading = function() {
    if ($scope.paymentsProgress || $scope.promotionsProgress || $scope.statementsProgress || $scope.cardRetryProgress) {
      return true;
    }

    if ($scope.cards) {
      for (var i = 0; i < $scope.cards.length; i++) {
        var card = $scope.cards[i];
        if (card.deleteProgress || card.primaryProgress) {
          return true; // if a card is being deleted or set as primary
        }
      }
    }

    return false;
  };

  $scope.showError = function(message) {
    var modalSetting = {
      titleClass: 'alert',
      title: 'Error',
      message: message
    };

    modalFactory.simpleAlert(modalSetting);
  };

  $scope.loadBillingThreshold = function() {
    $scope.billingThreshold = null;
    return paymentsFactory.getBillingThreshold().then(
      function(data) {
        $scope.billingThreshold = data;
      },
      function(error) {
        $scope.showError('Unable to load billing threshold. Please try again later. (Error: ' + error + ')');
        throw error;
      }
    );
  };

  $scope.loadCards = function() {
    return paymentsFactory.getCards().then(
      function(data) {
        $scope.cards = data || [];
      },
      function(error) {
        $scope.showError('Unable to load card data. Please try again later. (Error: ' + error + ')');
        throw error;
      }
    );
  };

  $scope.loadCustomer = function() {
    return paymentsFactory.getCustomer().then(
      function(data) {
        $scope.customer = data;
      },
      function(error) {
        $scope.showError('Unable to load payments information. Please try again later. (Error: ' + error + ')');
        throw error;
      }
    );
  };

  $scope.loadPromotions = function() {
    $scope.promotionsProgress = true;
    return paymentsFactory
      .getPromotions()
      .then(function(data) {
        $scope.promotions = data || [];
      })
      .catch(function(error) {
        $scope.showError('Unable to load promotions and credits. Please try again later. (Error: ' + error + ')');
        throw error;
      })
      .finally(function() {
        $scope.promotionsProgress = false;
      });
  };

  $scope.loadStatements = function() {
    $scope.statementsProgress = true;
    return paymentsFactory
      .getStatements()
      .then(function(data) {
        var statements = data || [];

        for (var i = 0; i < statements.length; i++) {
          var statement = statements[i];
          var statementDate = new Date(statement.billing_year, statement.billing_month - 1);
          statement.name = 'Statement ' + $filter('date')(statementDate, 'MMMM, yyyy');
        }

        $scope.statements = statements;
      })
      .catch(function(error) {
        $scope.showError('Unable to load statements. Please try again later. (Error: ' + error + ')');
        throw error;
      })
      .finally(function() {
        $scope.statementsProgress = false;
      });
  };

  $scope.getCustomerId = function() {
    if ($scope.customer) {
      return $scope.customer.customer_id;
    } else {
      return null;
    }
  };

  // should be able to remove this after fully switching to react view
  $scope.addPaymentMethod = function() {
    const modalOptions = {
      template:
        '<payment-modal card="card" stripe="stripe" payment="payment" close="close()" submit="submit()" handlers="handlers" error="error" in-progress="inProgress"></payment-modal>',
      controller: 'paymentModalController',
      keyboard: false,
      backdrop: 'static'
    };

    const handlers = {
      submit: $scope.submitPaymentMethod
    };

    modalFactory.createModal(modalOptions, null, handlers);
  };

  // this is for the react payments model
  $scope.submitPaymentMethod = function(tokenId) {
    const customerId = $scope.getCustomerId();
    return paymentsFactory
      .createCard(customerId, tokenId)
      .then(function(data) {
        $scope.customer = data.customer; // update customer object in case of changes
        $scope.cards.push(data.card); // add resulting card to local data

        // We have added a new payment method, logging this event into GA
        typeof $window.gtag === 'function' &&
          $window.gtag('event', GAEventList.PAYMENT_METHOD_ADDED, {
            customer_id: customerId,
            gt_user_id: $window?.user?.id
          });
      })
      .catch(function(error) {
        return 'Unable to create card. (Error: ' + error + ')';
      })
      .finally(function() {
        notificationFactory.getNotifications();
        $scope.loadCards();
      });
  };

  $scope.setDefaultCard = function(card) {
    card.primaryProgress = true;

    var customerId = $scope.getCustomerId();

    paymentsFactory
      .setDefaultCard(customerId, card.card_id)
      .then(
        function(data) {
          $scope.loadCustomer().then(function() {
            card.primaryProgress = false;
          }); // reload customer
        },
        function(error) {
          card.primaryProgress = false;
          $scope.showError('Unable to set as primary. (Error: ' + error + ')');
        }
      )
      .finally(function() {
        notificationFactory.getNotifications();
        $scope.loadCards();
      });
  };

  $scope.deleteCard = function(card) {
    var modalSetting = {
      title: 'Are you sure you want to delete the payment method?',
      message:
        'As soon as you delete the payment method, all its accounts will be automatically transferred to the primary one.',
      noText: 'Cancel',
      yesText: 'Delete'
    };

    if ($scope.cards.length === 1) {
      modalSetting.title = 'Warning';
      modalSetting.message =
        'This is your only card on file and your campaigns will stop from spending if deleted. Please cancel and add a new payment method before deleting your last payment card.';
      modalSetting.yesText = 'Delete Anyway';
    }

    var handlers = {
      confirm: function() {
        card.deleteProgress = true;
        var customerId = $scope.getCustomerId();
        return paymentsFactory
          .deleteCard(customerId, card.card_id)
          .then(
            function() {
              $scope.loadCards();
            },
            function(error) {
              return 'Unable to delete your card. (Error: ' + error + ')';
            }
          )
          .finally(function() {
            card.deleteProgress = false;
            notificationFactory.getNotifications();
          });
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.retryCard = function(card) {
    $scope.cardRetryProgress = true;

    paymentsFactory
      .retryCard(card.card_id)
      .catch(function(error) {
        $scope.showError('Unable to retry the card. (Error: ' + error + ')');
        throw error;
      })
      .finally(function() {
        $scope.loadCards();
        notificationFactory.getNotifications();
        $scope.cardRetryProgress = false;
      });
  };

  $scope.showCardFailureDetails = function(card) {
    return (
      $scope.featureFlagFactory.isFeatureEnabled('CREDIT_CARD_RETRY') &&
      (($scope.cards.length > 1 && card.card_id == $scope.customer.default_card_id) || $scope.cards.length === 1) &&
      card.status === $scope.cardStatus.FAILED
    );
  };

  $scope.showRetryButton = function(card) {
    return $scope.showCardFailureDetails(card) && card.is_retryable;
  };

  $scope.updateConnectedAccounts = function(accountIds, cardId) {
    paymentsFactory
      .updateConnectedAccounts(cardId, accountIds)
      .then(function() {
        $scope.loadCards();
      })
      .catch(function(error) {
        $scope.showError('Unable to connect accounts the card. (Error: ' + error + ')');
        throw error;
      });
  };

  $scope.closePaymentModal = function() {
    $scope.isPaymentModalOpen = false;
    $scope.$apply();
  };

  $scope.cardTypeIcons = {
    Visa: '/ui/images/visa.svg',
    MasterCard: '/ui/images/MasterCard.svg',
    'American Express': '/ui/images/Amex.svg',
    UnionPay: '/ui/images/UnionPay.svg',
    JCB: '/ui/images/JCB.svg',
    Discover: '/ui/images/Discover.svg',
    'Diners Club': '/ui/images/DinersClub.svg'
  };

  $scope.init();
}
