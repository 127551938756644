dataExportFactory.$inject = ['$filter'];

export function dataExportFactory($filter) {
  var dataExportFactory = {};

  var columnHeaders = {
    campaign_id: {
      name: 'Campaign ID',
      filter: ''
    },
    campaign_name: {
      name: 'Campaign Name',
      filter: ''
    },
    creative_id: {
      name: 'Creative ID',
      filter: ''
    },
    creative_name: {
      name: 'Creative Name',
      filter: ''
    },
    creative_size: {
      name: 'Creative Size',
      filter: ''
    },
    adgroup_id: {
      name: 'Ad Group ID',
      filter: ''
    },
    adgroup_name: {
      name: 'Ad Group Name',
      filter: ''
    },
    pixel_id: {
      name: 'Pixel Id',
      filter: ''
    },
    pixel_name: {
      name: 'Pixel Name',
      filter: ''
    },
    targeted_product: {
      name: 'Targeting Indicator',
      filter: ''
    },
    adpackage_name: {
      name: 'Ad Package Name',
      filter: ''
    },
    vendorIdentifier: {
      name: 'Third Party Placement ID',
      filter: ''
    },
    client_placement_name: {
      name: 'Third Party Placement Name',
      filter: ''
    },
    adgroup_status: {
      name: 'Ad Group Status',
      filter: ''
    },
    adgroup_start_date: {
      name: 'Ad Group Start Date',
      filter: ''
    },
    adgroup_end_date: {
      name: 'Ad Group End Date',
      filter: ''
    },
    brand_name: {
      name: 'Brand Name',
      filter: ''
    },
    audience_name: {
      name: 'Audience Name',
      filter: ''
    },
    taxonomy_audience_name: {
      name: 'GT Audience',
      filter: ''
    },
    category_name: {
      name: 'Category Name',
      filter: ''
    },
    product_group: {
      name: 'Product Group',
      filter: ''
    },
    product: {
      name: 'Product',
      filter: ''
    },
    constraint: {
      name: 'Constraint',
      filter: ''
    },
    poi: {
      name: 'Business Name',
      filter: ''
    },
    dmas: {
      name: 'Dma',
      filter: ''
    },
    counties: {
      name: 'County',
      filter: ''
    },
    states: {
      name: 'State',
      filter: ''
    },
    city: {
      name: 'City',
      filter: ''
    },
    zipCodes: {
      name: 'Zip',
      filter: ''
    },
    address: {
      name: 'Address',
      filter: ''
    },
    country: {
      name: 'Country',
      filter: ''
    },
    lat: {
      name: 'Latitude',
      filter: ''
    },
    lng: {
      name: 'Longitude',
      filter: ''
    },
    vr: {
      name: 'VR',
      filter: 'percentage'
    },
    ctr: {
      name: 'CTR',
      filter: 'percentage'
    },
    sar: {
      name: 'SAR',
      filter: 'percentage'
    },
    date: {
      name: 'Date',
      filter: ''
    },
    day: {
      name: 'Day',
      filter: ''
    },
    hour: {
      name: 'Hour',
      filter: ''
    },
    matched_impressions: {
      name: 'Matched Impressions',
      filter: 'customNumber'
    },
    matched_clicks: {
      name: 'Matched Clicks',
      filter: 'customNumber'
    },
    matched_visits: {
      name: 'Matched Visits',
      filter: 'customNumber'
    },
    matched_open_hour_visits: {
      name: 'Matched Open Hour Visits',
      filter: 'customNumber'
    },
    matched_projected_visits: {
      name: 'Matched Projected Visits',
      filter: 'customNumber'
    },
    matched_secondary_actions: {
      name: 'Matched Secondary Actions',
      filter: 'customNumber'
    },
    totalBudget: {
      name: 'Total Budget',
      filter: 'currency'
    },
    spends_spent: {
      name: 'Total Spent',
      filter: 'currency'
    },
    spends_daily_spent: {
      name: 'Spend Today',
      filter: 'currency'
    },
    totalSpentGross: {
      name: 'Total Spent Gross',
      filter: 'currency'
    },
    CPMGross: {
      name: 'CPM Gross',
      filter: 'currency'
    },
    impression: {
      name: 'Impressions',
      filter: 'customNumber'
    },
    click: {
      name: 'Clicks',
      filter: 'customNumber'
    },
    visits: {
      name: 'Visits',
      filter: 'customNumber'
    },
    conversions: {
      name: 'Web Conversions',
      filter: 'customNumber'
    },
    view_conversions: {
      name: 'View Conversions',
      filter: 'customNumber'
    },
    click_conversions: {
      name: 'Click Conversions',
      filter: 'customNumber'
    },
    open_hour_visits: {
      name: 'Open Hour Visits',
      filter: 'customNumber'
    },
    projected_visits: {
      name: 'Projected Visits (v1)',
      filter: 'customNumber'
    },
    reach: {
      name: 'Reach',
      filter: 'customNumber'
    },
    secondary_actions: {
      name: 'Total Secondary Actions',
      filter: 'customNumber'
    },
    website: {
      name: 'Header Website',
      filter: 'customNumber'
    },
    directions: {
      name: 'Directions',
      filter: 'customNumber'
    },
    click_to_call: {
      name: 'Click to Call',
      filter: 'customNumber'
    },
    more_info: {
      name: 'CTA More Info',
      filter: 'customNumber'
    },
    coupon: {
      name: 'Promo Coupon',
      filter: 'customNumber'
    },
    pacing: {
      name: 'Pacing',
      filter: 'percentage'
    },
    timeframe_start_string: {
      name: 'Start',
      filter: ''
    },
    timeframe_end_string: {
      name: 'End',
      filter: ''
    },
    bidRate: {
      name: 'Max CPM Bid',
      filter: 'customNumber'
    },
    creativeSize: {
      name: 'Creative Size',
      filter: ''
    },
    creativeType: {
      name: 'Creative Type',
      filter: ''
    },
    video_start: {
      name: 'Video Start',
      filter: 'customNumber'
    },
    video_q1: {
      name: 'First Quartile',
      filter: 'customNumber'
    },
    video_q2: {
      name: 'Midpoint',
      filter: 'customNumber'
    },
    video_q3: {
      name: 'Third Quartile',
      filter: 'customNumber'
    },
    video_complete: {
      name: 'Video Complete',
      filter: 'customNumber'
    },
    publisher: {
      name: 'Publisher',
      filter: ''
    },
    campaign_start_date: {
      name: 'Campaign Start Date',
      filter: ''
    },
    campaign_end_date: {
      name: 'Campaign End Date',
      filter: ''
    },
    vcr: {
      name: 'VCR',
      filter: 'percentage'
    }
  };

  var keyDelimiter = '"';
  var columnDelimiter = ',';
  var lineDelimiter = '\n';

  dataExportFactory.convertArrayOfObjectsToCSV = function(args) {
    /*
     *
     * args = {
     *      keys   : array,    // keys is used for mapping data  (required)
     *      header : array,    // header used for display column in csv file (required)
     *      data     : array,  (required)
     *      filename : string,
     *      columnDelimiter : string,
     *      lineDelimiter   : string,
     *
     * }
     *
     * return false if args has not correct
     * return result(string) if everything is correct
     * *
     */

    var result, ctr, keys, data, firstRowData, header;

    columnDelimiter = args.columnDelimiter || columnDelimiter;
    lineDelimiter = args.lineDelimiter || lineDelimiter;

    // validate data
    data = args.data || null;
    if (data == null || !data.length) {
      return false;
    }

    // validate header and keys
    if (!args.hasOwnProperty('keys') || !args.hasOwnProperty('header')) {
      return false;
    }
    keys = args.keys;
    header = args.header;
    if (header.length !== keys.length) {
      return false;
    }
    firstRowData = data[0];
    keys.forEach(function(item, index) {
      if (!firstRowData.hasOwnProperty(item)) {
        return false;
      }
      header[index] = '"' + header[index] + '"';
    });

    // assemble result string
    result = '';
    result += header.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function(item) {
      ctr = 0;
      keys.forEach(function(key) {
        if (ctr > 0) {
          result += columnDelimiter;
        }
        // to avoid comma or space
        if (item[key] !== undefined) {
          result += '"' + item[key] + '"';
        }
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };

  dataExportFactory.reportDataToCsv = function(data, currencyCode) {
    if (!data.length) {
      return ''; // return nothing if there is no data
    }
    var result = '';
    var i;
    var j;
    var key;
    var header;
    var expectedKeys = Object.keys(columnHeaders);
    var dataKeys = Object.keys(data[0]);
    var matchedKeys = []; // keys that will be properly exported
    var unrecognizedKeys = []; // unrecognized keys that will still be exported, but without proper column headers

    // first figure out which columns will be used
    // NOTE: we'll assume that all rows will have the same keys
    for (i = 0; i < expectedKeys.length; i++) {
      key = expectedKeys[i];
      if (dataKeys.indexOf(key) > -1) {
        matchedKeys.push(key);
      }
    }

    // find all unexpected keys
    for (i = 0; i < dataKeys.length; i++) {
      key = dataKeys[i];
      if (expectedKeys.indexOf(key) === -1) {
        unrecognizedKeys.push(key);
      }
    }

    // create column headers in the first row (matched ones first, then the unrecognized ones)
    for (i = 0; i < matchedKeys.length; i++) {
      // Add currency code to headers.
      if (columnHeaders[matchedKeys[i]].filter === 'currency') {
        result += keyDelimiter + columnHeaders[matchedKeys[i]].name + ' (' + currencyCode + ')' + keyDelimiter;
      } else {
        result += keyDelimiter + columnHeaders[matchedKeys[i]].name + keyDelimiter;
      }
      result += columnDelimiter;
    }

    for (i = 0; i < unrecognizedKeys.length; i++) {
      // just use the keys since we don't have a name for the column
      result += keyDelimiter + unrecognizedKeys[i] + keyDelimiter;
      result += columnDelimiter;
    }

    result += lineDelimiter; // new line before we start working on the data

    // process actual data with the column keys that we've decided on
    for (i = 0; i < data.length; i++) {
      for (j = 0; j < matchedKeys.length; j++) {
        key = matchedKeys[j];
        header = columnHeaders[key];
        result += keyDelimiter;

        // guard here just in case a row doesn't have this key
        if (data[i].hasOwnProperty(key)) {
          // Don't add default '$' for currency values.
          if (header.filter === 'currency') {
            if (data[i][key] === '--') {
              result += data[i][key];
            } else {
              result += $filter(header.filter)(data[i][key], '');
            }
          } else {
            // for null values returning `--`
            const value = data[i][key] == null ? '--' : data[i][key];
            result += header.filter ? $filter(header.filter)(value) : value;
          }
        }

        result += keyDelimiter;
        result += columnDelimiter;
      }

      for (j = 0; j < unrecognizedKeys.length; j++) {
        key = unrecognizedKeys[j];
        result += keyDelimiter;

        // guard here just in case a row doesn't have this key
        if (data[i].hasOwnProperty(key)) {
          result += data[i][key];
        }

        result += keyDelimiter;
        result += columnDelimiter;
      }

      result += lineDelimiter;
    }

    return result;
  };

  return dataExportFactory;
}
