import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const SplashIcon = createSvgIcon(
  <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_597_451)">
      <path
        d="M47 1.03647L54.4082 10.173L54.7407 10.5831L55.2112 10.3437L65.695 5.01023L68.7466 16.3701L68.8835 16.8799L69.4107 16.8526L81.1576 16.2444L79.3248 27.8633L79.2425 28.3848L79.7353 28.5743L90.7139 32.7965L84.3138 42.6654L84.0265 43.1084L84.3996 43.4819L92.7117 51.8045L82.8509 58.217L82.4083 58.5048L82.5972 58.9978L86.8056 69.9818L75.189 71.8291L74.6676 71.912L74.6397 72.4392L74.0167 84.1853L62.653 81.148L62.143 81.0117L61.903 81.4819L56.5564 91.9591L47.4105 84.5624L47 84.2305L46.5895 84.5624L37.4436 91.9591L32.097 81.4819L31.857 81.0117L31.347 81.148L19.9833 84.1853L19.3603 72.4392L19.3324 71.912L18.811 71.8291L7.19442 69.9818L11.4028 58.9978L11.5917 58.5048L11.1491 58.217L1.28826 51.8045L9.60042 43.4819L9.97349 43.1084L9.68624 42.6654L3.28609 32.7965L14.2647 28.5743L14.7575 28.3848L14.6752 27.8633L12.8424 16.2444L24.5893 16.8526L25.1165 16.8799L25.2534 16.3701L28.3049 5.01023L38.7888 10.3437L39.2593 10.5831L39.5918 10.173L47 1.03647Z"
        fill="#C9FF43"
        stroke="#AAED05"
        strokeWidth="1.30556"
      />
      <path
        d="M36.1132 53.7222H32.9877L27.7602 45.8079V53.7222H24.6347V40.8912H27.7602L32.9877 48.842V40.8912H36.1132V53.7222ZM47.9929 48.4582C47.9929 48.7506 47.9746 49.0552 47.938 49.3721H40.8645C40.9133 50.0057 41.1143 50.4931 41.4677 50.8343C41.8332 51.1633 42.278 51.3278 42.802 51.3278C43.5818 51.3278 44.1241 50.9988 44.4287 50.3408H47.7552C47.5847 51.011 47.2739 51.6141 46.8231 52.1503C46.3844 52.6864 45.83 53.1068 45.1598 53.4114C44.4896 53.7161 43.7402 53.8684 42.9116 53.8684C41.9125 53.8684 41.0229 53.6551 40.2431 53.2287C39.4632 52.8022 38.854 52.1929 38.4153 51.4009C37.9766 50.6089 37.7573 49.6828 37.7573 48.6227C37.7573 47.5626 37.9705 46.6365 38.397 45.8444C38.8357 45.0524 39.445 44.4431 40.2248 44.0167C41.0047 43.5902 41.9003 43.3769 42.9116 43.3769C43.8986 43.3769 44.776 43.5841 45.5436 43.9984C46.3113 44.4127 46.9084 45.0037 47.3349 45.7713C47.7735 46.539 47.9929 47.4346 47.9929 48.4582ZM44.7942 47.6357C44.7942 47.0995 44.6115 46.673 44.2459 46.3562C43.8804 46.0394 43.4234 45.881 42.8751 45.881C42.3511 45.881 41.9064 46.0333 41.5408 46.3379C41.1874 46.6426 40.9681 47.0751 40.8828 47.6357H44.7942ZM64.2822 43.5232L61.5223 53.7222H58.0678L56.4593 47.1056L54.796 53.7222H51.3598L48.5816 43.5232H51.7071L53.151 50.816L54.8692 43.5232H58.1774L59.9138 50.7794L61.3395 43.5232H64.2822ZM69.5866 40.4342L69.2211 49.1893H66.5708L66.2052 40.4342H69.5866ZM67.9416 53.8684C67.3933 53.8684 66.9424 53.71 66.5891 53.3932C66.2479 53.0642 66.0773 52.6621 66.0773 52.1868C66.0773 51.6994 66.2479 51.2912 66.5891 50.9622C66.9424 50.6332 67.3933 50.4687 67.9416 50.4687C68.4778 50.4687 68.9164 50.6332 69.2576 50.9622C69.611 51.2912 69.7877 51.6994 69.7877 52.1868C69.7877 52.6621 69.611 53.0642 69.2576 53.3932C68.9164 53.71 68.4778 53.8684 67.9416 53.8684Z"
        fill="#262626"
      />
    </g>
    <defs>
      <clipPath id="clip0_597_451">
        <rect width="94" height="94" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'GTSplash'
);

export default SplashIcon;
