import template from '../../templates/static-templates/gt-spinner.html';

export function gtSpinner() {
  var directive = {
    template,
    link: link,
    restrict: 'E',
    scope: {
      size: '@',
      color: '@'
    }
  };
  return directive;

  function link(scope, element, attrs) {
    var classNames = ['spinner-' + scope.size, scope.color];
    scope.classNames = classNames.join(' ');
  }
}
