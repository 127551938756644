/**
 * @param {ng.IQService} $q
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @ngInject
 */
export function mapProximityController($scope, $q, mapsUtilsFactory, companyAndAccountFactory) {
  $scope.companyAndAccountFactory = companyAndAccountFactory;
  const grayScaleMapTheme = [...mapsUtilsFactory.MAP_THEME_LIGHT];
  grayScaleMapTheme[0].stylers[1].saturation = -100;
  $scope.mapEngine = {
    gmap: null,
    countryGeocodeObj: null,
    mapState: null,
    markers: [],
    heatmap: null,
    heatMapThreshold: 6,
    heatMapData: null,
    targetedLocations: 0,
    options: {
      scrollwheel: true,
      streetViewControl: false,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.SMALL,
        position: google.maps.ControlPosition.LEFT_TOP
      },
      panControl: false,
      mapTypeControl: false,
      styles: grayScaleMapTheme,
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.ROADMAP]
      },
      tilt: 0
    },

    drawOnPremiseHeatMap: function(data) {
      $scope.mapEngine.emptyHeatMap();
      $scope.mapEngine.emptyMarkers();
      if ($scope.mapEngine.getNewState() != 'HEATMAP') {
        return;
      }
      var heatmapData = data.results.map(function(item) {
        return new google.maps.LatLng(item[0], item[1], item[3]);
      });
      $scope.mapEngine.heatmap.setData(heatmapData);
      $scope.mapEngine.mapState = 'HEATMAP';
    },

    drawProximityHeatMap: function(data) {
      $scope.mapEngine.emptyHeatMap();
      $scope.mapEngine.emptyMarkers();
      if ($scope.mapEngine.getNewState() != 'HEATMAP') {
        return;
      }
      var heatmapData = data.results.map(function(item) {
        return new google.maps.LatLng(item[0], item[1]);
      });
      $scope.adGroup.targeting.location_filters.forEach(function(item) {
        if (item.lat && item.lng && !item.exclude) {
          heatmapData.push(new google.maps.LatLng(item.lat, item.lng));
        }
      });
      $scope.mapEngine.heatmap.setData(heatmapData);
      $scope.mapEngine.mapState = 'HEATMAP';
    },

    emptyHeatMap: function() {
      if ($scope.mapEngine.heatmap) {
        $scope.mapEngine.heatmap.setData([]);
      } else {
        $scope.mapEngine.heatmap = new google.maps.visualization.HeatmapLayer();
        $scope.mapEngine.heatmap.set('maxIntensity', 1);
        $scope.mapEngine.heatmap.set('radius', 5);
        const gradient = [
          'rgba(205, 255, 61, 0)',
          'rgba(205, 255, 61, 1)',
          'rgba(32, 210, 181, 1)',
          'rgba(0, 131, 198, 1)'
        ];
        $scope.mapEngine.heatmap.set('gradient', $scope.mapEngine.heatmap.get('gradient') ? null : gradient);
        $scope.mapEngine.heatmap.setMap($scope.mapEngine.gmap);
      }
    },

    drawOnPremiseMarkers: function(force) {
      $scope.mapEngine.emptyHeatMap();
      $scope.mapEngine.emptyMarkers();

      var getInfoWindow = function(brandName, address, city, state, zipcode, hashKey) {
        var infoWindowText = '<h4>' + brandName + '</h4>';
        infoWindowText += '<p>' + address + ', ' + city + ', ' + state + ', ' + zipcode + '</p>';
        //infoWindowText += '<p>' + hashKey + "</p>";
        var infowindow = new google.maps.InfoWindow({
          content: '<div>' + infoWindowText + '</div>'
        });
        return infowindow;
      };
      $scope.mapEngine.getLocationsInBoundingBox().then(function(data) {
        var headers = data.header.split(',');
        var headerMap = {};
        for (var i = 0; i < headers.length; i++) {
          headerMap[headers[i].replace(/\s|\[|\]/g, '')] = i;
        }
        data.results.forEach(function(item) {
          var infowindow = getInfoWindow(
            item[headerMap['companyName']],
            item[headerMap['address']],
            item[headerMap['city']],
            item[headerMap['state']],
            item[headerMap['zip']],
            item[headerMap['poiHash']]
          );
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(item[headerMap['centerLat']], item[headerMap['centerLng']]),
            map: $scope.mapEngine.gmap,
            icon: '/ui/images/Icon-include-location-on.svg'
          });

          google.maps.event.addListener(marker, 'mouseover', function() {
            $scope.mapEngine.clearInfoWindow();
            const position = marker.getPosition();
            if (position) infowindow.setPosition(position);
            infowindow.open($scope.mapEngine.gmap);
            $scope.mapEngine.currentInfoWindow = infowindow;
          });

          google.maps.event.addListener(infowindow, 'closeclick', function() {
            $scope.mapEngine.clearInfoWindow();
          });

          $scope.mapEngine.markers.push(marker);
        });
      });
      $scope.mapEngine.mapState = 'MARKERS';
    },

    clearInfoWindow: function() {
      if ($scope.mapEngine.currentInfoWindow) {
        $scope.mapEngine.currentInfoWindow.close();
      }
      $scope.mapEngine.currentInfoWindow = null;
    },

    drawProximityMarkers: function(force) {
      $scope.mapEngine.emptyHeatMap();
      if (!force && $scope.mapEngine.getNewState() == $scope.mapEngine.mapState) {
        return;
      }
      var getInfoWindow = function(item) {
        var infoWindowText = '';
        if (item.type == 'state') {
          infoWindowText = 'State';
          infoWindowText += '<p>' + item.name + '</p>';
        } else if (item.type == 'city') {
          infoWindowText = 'City';
          infoWindowText += '<p>' + item.name + '</p>';
        } else if (item.type == 'dma') {
          infoWindowText = 'DMA';
          infoWindowText += '<p>' + item.name + '</p>';
        } else if (item.type == 'zip_code') {
          infoWindowText = 'Zipcode';
          infoWindowText += '<p>' + item.name + '</p>';
        } else if (item.type == 'address') {
          infoWindowText = 'Address';
          infoWindowText += '<p>' + item.address + ', ' + item.city + ', ' + item.state + ', ' + item.zipcode + '</p>';
        } else if (item.type == 'latlng') {
          infoWindowText = 'Coordinate';
          infoWindowText += '<p>' + item.lat + ', ' + item.lng + '</p>';
        } else if (item.type == 'county') {
          infoWindowText = 'County';
          infoWindowText += '<p>' + item.name + '</p>';
        }
        var infowindow = new google.maps.InfoWindow({
          content: '<div>' + infoWindowText + '</div>'
        });
        return infowindow;
      };
      $scope.adGroup.targeting.location_filters.forEach(function(item) {
        var icon = '/ui/images/Icon-include-location-on.svg';
        if (item.exclude) {
          icon = '/ui/images/Icon-exclude-location-on.svg';
        }
        if (item.lat && item.lng) {
          // remove the marker if some marker is already present (red/green) at the same location
          $scope.mapEngine.removeMarker(item);
          var infowindow = getInfoWindow(item);
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(item.lat, item.lng),
            map: $scope.mapEngine.gmap,
            icon: icon
          });

          const position = marker.getPosition();
          google.maps.event.addListener(marker, 'mouseover', function() {
            $scope.mapEngine.clearInfoWindow();
            if (position) infowindow.setPosition(position);
            infowindow.open($scope.mapEngine.gmap);
            $scope.mapEngine.currentInfoWindow = infowindow;
          });

          google.maps.event.addListener(infowindow, 'closeclick', function() {
            $scope.mapEngine.clearInfoWindow();
          });

          $scope.mapEngine.markers.push(marker);
        }
      });
      $scope.mapEngine.mapState = 'MARKERS';
    },

    emptyMarkers: function() {
      $scope.mapEngine.markers.forEach(function(marker) {
        marker.setMap(null);
      });
      $scope.mapEngine.markers.length = 0;
    },

    removeMarker: function(item) {
      $scope.mapEngine.markers.forEach(function(marker) {
        if (marker.position.lat() == item.lat && marker.position.lng() == item.lng) {
          marker.setMap(null);
        }
      });
    },

    mapEngineInit: function() {
      var d = $q.defer();
      $scope.mapEngine.markerMechanism = 'rtree';
      if (!$scope.mapEngine.gmap) {
        var getAccountPromise = companyAndAccountFactory.getSelectedAccount();
        $q.all([getAccountPromise]).then(function(data) {
          var countryGeocodeDeferred = mapsUtilsFactory.getGeocodeForCountry(data[0].countryCode);
          countryGeocodeDeferred.then(function(data) {
            $scope.mapEngine.countryGeocodeObj = data;
            var mapElement = document.getElementById('google-map-area-avails');
            if (mapElement) {
              $scope.mapEngine.gmap = new google.maps.Map(mapElement, $scope.mapEngine.options);
              $scope.mapEngine.gmap.fitBounds($scope.mapEngine.countryGeocodeObj.geometry.viewport);
              google.maps.event.addListener($scope.mapEngine.gmap, 'idle', function() {
                $scope.mapEngine.render();
              });
              d.reject();
            }
          });
        });
      } else {
        d.resolve();
      }
      return d.promise;
    },

    getNewState: function() {
      if ($scope.mapEngine.gmap.getZoom() < $scope.mapEngine.heatMapThreshold) {
        return 'HEATMAP';
      } else {
        return 'MARKERS';
      }
    },

    getHeatMapData: function() {
      const d = $q.defer();
      if ($scope.mapEngine.heatMapData) {
        d.resolve($scope.mapEngine.heatMapData);
      } else {
        const map = $scope.mapEngine.gmap;
        const requestData = angular.copy($scope.adGroup);
        requestData.zoomLevel = map.getZoom();
        mapsUtilsFactory.getHeatMapDataForAdGroup(requestData, function successCallback(data) {
          d.resolve(data);
        });
      }
      return d.promise;
    },

    getLocationsInBoundingBox: function() {
      return mapsUtilsFactory.getBoundingBoxMarkers($scope.adGroup, $scope.mapEngine.gmap);
    },

    render: function(force) {
      if (
        $scope.adGroup.product == 'geotargeting' ||
        $scope.adGroup.product == 'weather' ||
        $scope.adGroup.product == 'audience' ||
        $scope.adGroup.product == 'neighborhood'
      ) {
        $scope.mapEngine.drawHeatMap = $scope.mapEngine.drawProximityHeatMap;
        $scope.mapEngine.drawMarkers = $scope.mapEngine.drawProximityMarkers;
      } else if ($scope.adGroup.product == 'onpremise') {
        $scope.mapEngine.drawHeatMap = $scope.mapEngine.drawOnPremiseHeatMap;
        $scope.mapEngine.drawMarkers = $scope.mapEngine.drawOnPremiseMarkers;
      }
      $scope.mapEngine.getHeatMapData().then(function(data) {
        if ($scope.mapEngine.getNewState() == 'HEATMAP') {
          $scope.mapEngine.clearInfoWindow();
          $scope.mapEngine.drawHeatMap(data);
        } else if ($scope.mapEngine.getNewState() == 'MARKERS') {
          if (force) {
            $scope.mapEngine.clearInfoWindow();
          }
          $scope.mapEngine.drawMarkers(force);
        }
      });
    }
  };

  $scope.updateMap = function() {
    $scope.mapEngine.heatMapData = null;
    $scope.mapEngine.mapEngineInit().then(function() {
      $scope.mapEngine.render(true);
    });
  };

  $scope.updateFns.mapUpdateFn = $scope.updateMap;
  $scope.updateMap();
}
