'use strict';

export function userOptions() {
  var directive = {
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.user_options.html',
    bindToController: true,
    controller: UserOptionsController,
    controllerAs: 'vm',
    link: link,
    restrict: 'E',
    scope: {
      currentUser: '='
    }
  };
  return directive;

  function link(scope, element, attrs) {}
}

UserOptionsController.$inject = ['$window', '$state', 'md5', 'modalFactory', 'featureFlagFactory'];

/**
 * @param {ng.IWindowService} $window
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {*} md5
 * @param {import('../../../factories/types').ModalFactory} modalFactory,
 * @param {import('../../../factories/feature_flag.fac').FeatureFlagService} featureFlagFactory
 */
function UserOptionsController($window, $state, md5, modalFactory, featureFlagFactory) {
  var vm = this;

  vm.featureFlagFactory = featureFlagFactory;

  vm.openDropdown = false;
  this.$onInit = () => {
    vm.userPhotoUrl =
      vm.currentUser.username !== undefined
        ? 'https://www.gravatar.com/avatar/' + md5.createHash(vm.currentUser.username) + '?s=38'
        : '';
  };

  vm.openProfile = openProfile;
  vm.openHelpModal = openHelpModal;
  vm.logout = logout;

  function openProfile() {
    if (vm.currentUser.id != null) {
      $state.go('users.profile', { userId: vm.currentUser.id });
    }
  }

  function openHelpModal() {
    modalFactory.welcome();
  }

  function logout() {
    $window.location.replace('/logout');
  }
}
