import React from 'react';
import { IconButton } from '@material-ui/core';

import { formatNumber } from './utils';
import { PlusIcon } from '../../../../components/reactComponents/Icons';
import { Audience } from '../../../../api/http/data.generated';

export interface RecommendationItemProps {
  item: Audience;
  onAddAudience: (item: Audience) => void;
}

export function RecommendationItem({ item, onAddAudience }: RecommendationItemProps) {
  return (
    <div className="recommendation-item">
      <IconButton onClick={() => onAddAudience(item)} className="recommendation-item__icon">
        <PlusIcon />
      </IconButton>
      <div className="recommendation-item__title" title={item.name ?? ''}>
        {item.name}
      </div>
      <div className="recommendation-item__size">{formatNumber(item.size)}</div>
    </div>
  );
}
