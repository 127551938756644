import servicesModule from '../../utils/services';
import { reactToAngularComponents } from '../../components/reactComponents/reactToAngular';
import { DeviceType } from './adgroup_device_type';

const reactComponents = {
  DeviceType
};

export default angular
  .module('app.adgroup', [servicesModule.name])
  .component(reactToAngularComponents(reactComponents));
