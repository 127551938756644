import GAEventList from '../../../components/googleAnalytics/eventList';

/**
 * @param {ng.IQService} $q
 * @param {ng.IHttpService} $http
 * @ngInject
 */
export function tenantAssignmentController($scope, $q, $http, tenantFactory, modalFactory, staticDataFactory) {
  $scope.vars = {
    inputServingCountry: null,
    inputPaymentCurrency: null,
    inputCompanyAddress1: null,
    inputCompanyAddress2: null,
    inputCompanyCity: null,
    inputCompanyState: null,
    inputCompanyZipcode: null,
    inputCompanyCountry: null,
    inputCompanyObjective: null,
    inputCompanyOrganizationType: null,
    inputTenantVertical: null,
    inputCompanyNoOfEmployees: null,
    inputWebsiteURL: null,
    inputCompanyAdsManagerExperience: null,
    inputCompanyObjectiveOther: null
  };

  $scope.booleans = {
    creatingCompanyProgress: null,
    requestingAccessProgress: null,
    disableSelectingNumberOfClients: true
  };

  $scope.errors = {
    message: null
  };

  $scope.countries = {};
  $scope.countryStates = {};
  $scope.currencies = [];

  $scope.init = function() {
    $scope.tenantAssignment = staticDataFactory.tenantAssignment;

    var countryDfd = tenantFactory.getCountriesForTenants();
    var statesDfd = tenantFactory.getCountryStates();
    let tenantVertical = tenantFactory.getTenantVertical();

    $q.all([countryDfd, statesDfd, tenantVertical]).then(function([country, states, tenantVertical]) {
      $scope.countries = tenantFactory.tenantCountries;
      $scope.tenantVertical = tenantVertical;
      // default country US
      $scope.vars.inputServingCountry = $scope.countries['US'];
      $scope.vars.inputCompanyCountry = $scope.countries['US'];

      $scope.selectedServingCountryChange();
      $scope.selectedCompanyCountryChange();
    });
  };
  $scope.init();

  $scope.selectedCompanyCountryChange = function() {
    // load states for countries
    $scope.countryStates = tenantFactory.countryStates[$scope.vars.inputCompanyCountry.code];
  };

  $scope.selectedServingCountryChange = function() {
    $scope.currencies = [];
    $scope.currencies = $scope.currencies.concat($scope.countries[$scope.vars.inputServingCountry.code].currency);

    // default currency
    if ($scope.currencies.length > 0) {
      $scope.vars.inputPaymentCurrency = $scope.currencies[0];
    }
  };

  $scope.createTenantAndCompany = function() {
    if ($scope.booleans.creatingCompanyProgress) {
      return false;
    }
    if (
      !$scope.vars.inputCompanyAddress1 ||
      !$scope.vars.inputCompanyCity ||
      !$scope.vars.inputCompanyState ||
      !$scope.vars.inputCompanyZipcode
    ) {
      $scope.errors.message = 'Please fill out all fields.';
      return;
    }
    $scope.booleans.creatingCompanyProgress = true;
    $scope.errors.message = null;
    var d = $q.defer();

    const deviceType = {};
    $scope.tenantAssignment.deviceType.forEach(item => {
      deviceType[item.value] = item.selected;
    });
    const orgType = $scope.vars.inputCompanyOrganizationType ? $scope.vars.inputCompanyOrganizationType.value : null;
    const vertical = $scope.vars.inputTenantVertical ? $scope.vars.inputTenantVertical.id : null;
    const companyObjective = $scope.vars.inputCompanyObjective?.value ?? null;
    $http({
      method: 'POST',
      url: '/data/tenant',
      data: {
        company_address1: $scope.vars.inputCompanyAddress1,
        company_city: $scope.vars.inputCompanyCity,
        company_state_code: $scope.vars.inputCompanyState.stateCode,
        company_zipcode: $scope.vars.inputCompanyZipcode,
        company_country: $scope.vars.inputCompanyCountry.code,
        serving_country_code: $scope.vars.inputServingCountry.code,
        payment_currency: $scope.vars.inputPaymentCurrency,
        company_objective: companyObjective,
        company_objective_other: $scope.vars.inputCompanyObjectiveOther,
        company_organization_type: orgType,
        tenant_vertical: vertical,
        no_of_employees: $scope.vars.inputCompanyNoOfEmployees,
        experience_level: $scope.vars.inputCompanyAdsManagerExperience,
        device_type: deviceType,
        website_url: $scope.vars.inputWebsiteURL
      }
    })
      .then(function successCallback({ data: response }) {
        // from response figure out if you have to redirect
        // redirect to adding account page.
        window.location.href = '/organization/' + response.created_company_id;
        // We have successfully created both tenant and organization, logging this event into GA
        typeof window.gtag === 'function' &&
          window.gtag('event', GAEventList.TENANT_AND_ORGANIZATION_CREATE, {
            organization_id: response.created_company_id,
            gt_user_id: window?.user_id
          });
        d.resolve(response);
      })
      .catch(function({ data, status, headers, config }) {
        if (typeof data === 'string') {
          $scope.vars.createCompanyResponseMessage = data;
          d.reject(data);
        } else {
          $scope.vars.createCompanyResponseMessage = data.message;
          d.reject(data.message);
        }
      })
      .finally(function() {
        $scope.booleans.creatingCompanyProgress = false;
      });
    return d.promise;
  };

  $scope.openConditionsModal = function() {
    modalFactory.termsAndConditions();
  };

  $scope.handleOrganizationTypeChange = () => {
    if (
      $scope.vars.inputCompanyOrganizationType &&
      $scope.vars.inputCompanyOrganizationType.disableClientCountSelection
    ) {
      $scope.booleans.disableSelectingNumberOfClients = true;
      $scope.vars.inputCompanyNoOfClients = null;
      return;
    }
    $scope.booleans.disableSelectingNumberOfClients = false;
  };
}
