import React, { useState, useEffect, useRef } from 'react';
import moment, { Moment } from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import PropTypes from 'prop-types';
import './DateRangePicker.css';
import { CalendarIcon } from '../../../components/reactComponents/Icons';

/**
 * React-dates: Feature-rich React Datepicker
 *
 * Fully controlled, supports null dates,
 * customizable calendar view, and localized date display.
 *
 * npm: https://www.npmjs.com/package/react-dates
 */

export interface CustomDateRangePickerProps {
  startDate: string | null;
  endDate: string | null;
  minDate: Date | null | string;
  maxDate?: Date | null | string;
  onDatesChange: (startDate: string | null, endDate: string | null, index: number) => void;
  index: number;
  onApplyClick?: () => void;
  applyCallbackData?: object;
}

export const CustomDateRangePicker = (props: CustomDateRangePickerProps) => {
  const {
    startDate: initialStartDate,
    endDate: initialEndDate,
    minDate: initialMinDate,
    maxDate: initialMaxDate,
    onDatesChange,
    index,
    onApplyClick,
    applyCallbackData
  } = props;
  const datePickerRef = useRef(null);
  const [minDate, setMinDate] = useState<Moment | null>(initialMinDate ? moment(initialMinDate, 'YYYY-MM-DD') : null);
  const [maxDate, setMaxDate] = useState<Moment | null>(initialMaxDate ? moment(initialMaxDate, 'YYYY-MM-DD') : null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const [currStartDate, setCurrStartDate] = useState<Moment | null>(
    initialStartDate ? moment(initialStartDate, 'YYYY-MM-DD') : null
  );
  const [currEndDate, setCurrEndDate] = useState<Moment | null>(
    initialEndDate ? moment(initialEndDate, 'YYYY-MM-DD') : null
  );
  const [prevStartDate, setPrevStartDate] = useState<Moment | null>(
    initialStartDate ? moment(initialStartDate, 'YYYY-MM-DD') : null
  );
  const [prevEndDate, setPrevEndDate] = useState<Moment | null>(
    initialEndDate ? moment(initialEndDate, 'YYYY-MM-DD') : null
  );

  const handleDatesChange = (arg: { startDate: Moment | null; endDate: Moment | null }) => {
    setCurrStartDate(arg.startDate);
    setCurrEndDate(arg.endDate);
    setFocusedInput(prevFocusedInput => {
      if (arg.startDate && !arg.endDate) {
        return 'endDate';
      } else if (!arg.startDate && arg.endDate) {
        return 'startDate';
      } else if (!arg.startDate && !arg.endDate) {
        return 'startDate';
      }
      return prevFocusedInput === 'startDate' ? 'endDate' : 'startDate';
    });
  };

  const handleApplyClick = () => {
    let formattedStartDate: any = currStartDate?.format('YYYY-MM-DD');
    if (currStartDate == null) {
      formattedStartDate = null;
      setCurrStartDate(moment(prevStartDate, 'YYYY-MM-DD'));
    }
    const endDateInput = document.getElementsByClassName('DateInput_input')[index * 2 + 1] as HTMLInputElement;
    if (endDateInput.value != '') {
      if (currEndDate < minDate) {
        setCurrEndDate(minDate);
        setCurrStartDate(minDate);
        setPrevStartDate(minDate);
        setPrevEndDate(minDate);
        let formattedDate: any = minDate?.format('YYYY-MM-DD');
        onDatesChange(formattedDate, formattedDate, index);
        if (onApplyClick) {
          onApplyClick(applyCallbackData);
        }
        setFocusedInput(null);
        return;
      }
    }
    const formattedEndDate = currEndDate?.format('YYYY-MM-DD') ?? null;
    onDatesChange(formattedStartDate, formattedEndDate, index);
    if (onApplyClick) {
      onApplyClick(applyCallbackData);
    }
    setPrevStartDate(currStartDate ?? prevStartDate);
    setPrevEndDate(currEndDate);
    setFocusedInput(null);
  };

  const handleCancelClick = () => {
    // Revert to the previous dates without applying changes
    setCurrStartDate(moment(prevStartDate, 'YYYY-MM-DD'));
    setCurrEndDate(prevEndDate ? moment(prevEndDate, 'YYYY-MM-DD') : null);
    setFocusedInput(null);
  };

  const handleFocusChange = (arg: FocusedInputShape | null) => {
    if (!focusedInput) {
      setFocusedInput(arg);
    }
  };

  const isApplyButtonDisabled = maxDate !== null && currEndDate === null;

  const renderCalendarFooter = () => {
    return (
      <div className="date-picker-footer">
        <button
          className="apply-button button-v2 button--green"
          type="button"
          onClick={handleApplyClick}
          disabled={isApplyButtonDisabled}
        >
          Apply
        </button>
        <button className="button--tertiary-green button-v2" type="button" onClick={handleCancelClick}>
          Cancel
        </button>
      </div>
    );
  };

  const isOutsideRange = day =>
    moment(day).isBefore(moment(minDate).startOf('day')) || moment(day).isAfter(moment(maxDate).endOf('day'));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        // Clicked outside the date picker, close it
        setCurrStartDate(moment(prevStartDate, 'YYYY-MM-DD'));
        setCurrEndDate(prevEndDate ? moment(prevEndDate, 'YYYY-MM-DD') : null);
        setFocusedInput(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [prevEndDate, prevStartDate]);

  return (
    <div ref={datePickerRef}>
      <DateRangePicker
        startDatePlaceholderText="From"
        endDatePlaceholderText="To"
        startDate={currStartDate}
        startDateId="datepicker"
        minDate={minDate}
        maxDate={maxDate}
        isOutsideRange={isOutsideRange}
        endDate={currEndDate}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        endDateId="datepicker"
        transitionDuration={0}
        onFocusChange={handleFocusChange}
        renderCalendarInfo={renderCalendarFooter}
        keepOpenOnDateSelect={true}
        displayFormat={() => 'YYYY-MM-DD'}
        minimumNights={0}
        showDefaultInputIcon
        inputIconPosition="after"
        customInputIcon={<CalendarIcon />}
      />
    </div>
  );
};
CustomDateRangePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onDatesChange: PropTypes.func,
  index: PropTypes.number,
  onApplyClick: PropTypes.func,
  applyCallbackData: PropTypes.object
};
