export function floatToInteger() {
  const directive = {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function(value) {
        return '' + value;
      });
      ngModel.$formatters.push(function(value) {
        return Math.round(parseFloat(value));
      });
    }
  };
  return directive;
}
