export function chartUtilsFactory() {
  const TICK_AMOUNT = 6;

  const getYAxisMaxValuesTodDow = (targetSeriesOption, allSeriesOptions, yAxisMetrics) => {
    const option = allSeriesOptions[yAxisMetrics[0].value];
    const yAxisMaxValues = [];
    let mergedTargetSeriesOption = [];
    for (let i = 0; i < targetSeriesOption.length; i++) {
      mergedTargetSeriesOption = [...mergedTargetSeriesOption, ...targetSeriesOption[i].data];
    }
    yAxisMaxValues.push(computeMaxValues(mergedTargetSeriesOption, option.dataLabel));

    return yAxisMaxValues;
  };

  const getYAxisMaxValues = (lineChartType, targetSeriesOption, allSeriesOptions, yAxisMetrics) => {
    const yAxisMaxValues = [];

    yAxisMetrics.forEach(function(metrics) {
      const options = allSeriesOptions[metrics.value];
      const name = lineChartType === 'conversions' ? options.value : options.name;
      const filteredOptions = targetSeriesOption.filter(function(item) {
        return name === item.name;
      });
      if (filteredOptions.length) {
        yAxisMaxValues.push(computeMaxValues(filteredOptions[0].data, options.dataLabel));
      }
    });

    return yAxisMaxValues;
  };

  const computeMaxValues = (data, optionsDataLabel) => {
    let _max;
    let _min;
    let _amount;
    let _diff;

    /** @type {([number, number] | null | number)[]} */

    _max = data.reduce(
      /**
       * @param a {number}
       * @param b {[number, number] | number | null}
       */ function(a, b) {
        return Math.max(a, b != null && typeof b === 'object' && b.length ? b[1] : typeof b === 'number' ? b : 0);
      },
      0
    );

    _min = data.reduce(
      /**
       * @param a {number}
       * @param b {[number, number] | number | null}
       */ function(a, b) {
        return Math.min(a, b != null && typeof b === 'object' && b.length ? b[1] : typeof b === 'number' ? b : 0);
      },
      _max
    );

    // for rounding purposes
    if (optionsDataLabel === 'percent') {
      _max *= 100;
      _min *= 100;
    }

    _diff = Math.floor(_max - _min);

    _amount = Math.max(Math.min(_diff, TICK_AMOUNT), 2);

    if (_max !== 0 && _amount < TICK_AMOUNT) {
      if (_max < TICK_AMOUNT) {
        _max = _max - _min > 0 ? TICK_AMOUNT * (_max - _min) : 6;
      }

      _min = 0;
      _amount = TICK_AMOUNT;
    }

    if (_max === 0) {
      _max = 1;
    }

    // converting back to decimal
    if (optionsDataLabel === 'percent') {
      _max /= 100;
      _min /= 100;
    }

    return {
      max: _max,
      min: _min,
      amount: _amount
    };
  };

  return {
    getYAxisMaxValues,
    getYAxisMaxValuesTodDow
  };
}
