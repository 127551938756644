import * as React from 'react';
import { useObserver } from 'mobx-react-lite';
import { styled } from '@material-ui/core/styles';
import { store } from '../model/store';
import { PrimaryButton } from '../../../components/reactComponents/Button/PrimaryButton';
import { Heading } from '../../../components/reactComponents/Typography';
import { primary3Palette } from '../../../components/reactComponents/styles/colorConstants';

const HeadingContainer = styled('div')({
  width: '100%',
  height: '72px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'var(--color-primary-container)',
  borderBottom: '1px solid var(--color-outline)',
  padding: '12px 40px'
});
const StyledHeader = styled('span')({
  paddingBottom: 5,
  color: 'var(--color-caption)',
  fontFamily: 'var(--secondary-font-family)',
  fontSize: '13px',
  fontWeight: 'var(--font-weight-light-bold)',
  lineHeight: '120%',
  textTransform: 'uppercase'
});

const TenantCompanyHeader = styled(Heading)({
  color: 'var(--color-on-primary)',
  fontFamily: 'var(--secondary-font-family)',
  fontSize: '20px',
  fontWeight: 'var(--font-weight-bold)',
  lineHeight: '140%'
});

export function Header() {
  return (
    <HeadingContainer>
      <div>
        <StyledHeader>Reports</StyledHeader>
        <TenantCompanyHeader variant="h6">
          {useObserver(() => store.companyAndAccount.selectedTenant?.name ?? '')}
        </TenantCompanyHeader>
      </div>
    </HeadingContainer>
  );
}
