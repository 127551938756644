import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const DirectMailIcon = createSvgIcon(
  <path
    id="Vector"
    d="M40 8H8C5.8 8 4.02 9.8 4.02 12L4 36C4 38.2 5.8 40 8 40H40C42.2 40 44 38.2 44 36V12C44 9.8 42.2 8 40 8ZM40 36H8V16L24 26L40 16V36ZM24 22L8 12H40L24 22Z"
    fill="#0A3F36"
  />,
  'GtDirectMail'
);

export default DirectMailIcon;
