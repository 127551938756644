// Implementation ported from 'ui_router.js'
export function format(source: string, ...args: string[]) {
  return source.replace(/{(\d+)}/g, function(match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
}

// Implementation ported from 'ui_router.js
export function replaceAll(source: string, find: string, replace: string) {
  return source.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
}
