import * as t from 'io-ts';
import { http } from '../def';

const AssociatedAdgroup = t.type(
  {
    adgroup_name: t.string,
    campaign_id: t.number,
    adgroup_id: t.number
  },
  'AssociatedAdgroup'
);

const Response = t.type(
  {
    adgroups: t.array(AssociatedAdgroup)
  },
  'CreativeAssetsAdgroupsGetResponse'
);
export type Response = t.TypeOf<typeof Response>;

export const getApi = http.get<number>`/data/creative_assets/${id => id}/adgroups`.returnsJsonOf(Response);
