import { reactToAngularComponents } from './reactToAngular';
import { PrimaryButton, RedirectButton } from './Button';
import { ButtonGroup } from './ButtonGroup/ButtonGroup';
import { Link } from './Link';
import { CircularIndicator } from './LoadingIndicator';
import { SimpleSearchDropdown } from './Dropdowns/SimpleSearchDropdown';
import { MultiselectDropdown } from './Dropdowns/MultiselectDropdown';
import { DatePicker } from './DatePicker/DatePicker';
import { TextField } from './Text';
import { Tooltip } from './Tooltip/Tooltip';
import { DeviceTypesSelector } from './DeviceType';
import { LoadingZebraBar } from './LoadingZebraBar';
import { AutoCompleteV2 } from './AutoCompleteV2';
import { CustomDateRangePicker } from './DatePicker/DateRangePicker';
import { AvailableApps } from './AvailableApps/AvailableApps';
import { TopBarLogoV2 } from './TopBar/TopBarLogoV2/TopBarLogoV2';
import { SearchBarV2 } from './SearchBar/SearchBarV2';

export {
  PrimaryButton,
  ButtonGroup,
  Link,
  CircularIndicator,
  SimpleSearchDropdown,
  MultiselectDropdown,
  DatePicker,
  TextField,
  Tooltip,
  DeviceTypesSelector,
  LoadingZebraBar,
  AutoCompleteV2,
  CustomDateRangePicker,
  AvailableApps,
  TopBarLogoV2,
  SearchBarV2
};

/**
 * React components will be converted to angular components by their key name
 * Example: testComponent: reactTestComponent can be used as <test-component><test-component/>
 */
export const components = {
  PrimaryButton,
  Link,
  DeviceTypesSelector,
  LoadingZebraBar,
  AutoCompleteV2,
  RedirectButton,
  CustomDateRangePicker,
  AvailableApps,
  TopBarLogoV2,
  SearchBarV2
};

export const reactAngularComponents = reactToAngularComponents(components);
