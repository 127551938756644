type PossiblePromiseResult<T> = T extends PromiseLike<infer R> ? R : T;

interface Debounce2ControlFunctions<T extends (...args: any[]) => any> {
  /**
   * Forces the execution of the last scheduled call to happen
   * immediately (if any). It will also return the result of that
   * call. Note that for asynchronous operations, you'll need to
   * return a promise and wait for that one to resolve.
   */
  flush(): ReturnType<T>;

  /**
   * Forces the execution of pending calls
   */
  flushPending(): ReturnType<T>;

  /**
   * Cancels the queued execution if any
   */
  cancel(): void;
}

type Debounce2Result<T extends (...args: any[]) => any> = ((
  ...args: Parameters<T>
) => ng.IPromise<PossiblePromiseResult<ReturnType<T>>>) &
  Debounce2ControlFunctions<T>;

export type Debounce2Service = <T extends (...args: any[]) => any>(
  wait: number | undefined,
  fn: T
) => Debounce2Result<T>;

/** @ngInject */
export function debounce2($timeout: ng.ITimeoutService): Debounce2Service {
  return function(wait, fn) {
    var args, context, result, timeout;

    // Execute the callback function
    function ping() {
      result = fn.apply(context || this, args || []);
      context = args = null;
      return result;
    }

    // Cancel the timeout (for rescheduling afterwards).
    function cancel() {
      if (timeout) {
        $timeout.cancel(timeout);
        timeout = null;
      }
    }

    // This is the actual result of the debounce call. It is a
    // wrapper function which you can invoke multiple times and
    // which will only be called once every "wait" milliseconds.
    function wrapper() {
      context = this;
      args = arguments;
      cancel();
      timeout = $timeout(ping, wait);
      return timeout;
    }

    // Forces the execution of pending calls
    function flushPending() {
      var pending = !!context;
      if (pending) {
        // Call pending, do it now.
        cancel();
        ping();
      }
      return pending;
    }

    // The wrapper also has a flush method, which you can use to
    // force the execution of the last scheduled call to happen
    // immediately (if any). It will also return the result of that
    // call. Note that for asynchronous operations, you'll need to
    // return a promise and wait for that one to resolve.
    wrapper.flush = function() {
      if (!flushPending() && !timeout) {
        // Never been called.
        ping();
      }
      return result;
    };

    // Flushes pending calls if any
    wrapper.flushPending = function() {
      flushPending();
      return result;
    };

    // Cancels the queued execution if any
    wrapper.cancel = cancel;

    return wrapper;
  };
}
