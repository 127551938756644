import template from './adgroup_targeting_geotargeting.html';
import { AdGroupTargeting } from '../../../../api/types/adgroup';
import { AdgroupTargetingService } from '../../adgroup_targeting.serv';
import { AbstractAdgroupTargetingController } from '../adgroup_targeting_base/abstract_adgroup_targeting';
import { safeApply } from '../../../../factories/general_utils';
import {
  CampaignManagerService,
  FeatureFlagService,
  CompanyAndAccountFactoryService,
  SearchService
} from '../../../../factories/types';

export function adgroupTargetingGeotargeting(): ng.IDirective {
  return {
    template: template,
    bindToController: true,
    controller: AdgroupTargetingGeotargetingController,
    controllerAs: 'vm',
    restrict: 'E',
    link: () => {},
    scope: {
      adgroupTargeting: '=',
      openUploadModal: '='
    }
  };
}

export class AdgroupTargetingGeotargetingController extends AbstractAdgroupTargetingController implements ng.IOnInit {
  adgroupTargeting: AdGroupTargeting = {};
  featureFlagFactory: FeatureFlagService;

  /**@ngInject */
  constructor(
    $scope: ng.IScope,
    featureFlagFactory: FeatureFlagService,
    companyAndAccountFactory: CompanyAndAccountFactoryService,
    adgroupTargetingService: AdgroupTargetingService,
    searchFactory: SearchService,
    campaignManagerFactory: CampaignManagerService
  ) {
    super($scope, companyAndAccountFactory, adgroupTargetingService, searchFactory, campaignManagerFactory);
    this.featureFlagFactory = featureFlagFactory;
  }

  getAdgroupTargeting() {
    return this.adgroupTargeting;
  }

  $onInit() {
    this.showAudienceExclude = !!this.adgroupTargeting.audience_exclude?.length;
    this.locationFilterSearchPlaceholder = this.adgroupTargetingService.getLocationFilterSearchPlaceholder(
      this.adgroupTargeting
    );
  }

  handleExcludeAudienceChange = () => {
    if (!this.adgroupTargeting.showAudienceExclude) {
      this.adgroupTargeting.audience_exclude = [];
    }
    safeApply(this.scope);
  };

  updateExcludeAudiences = excluded_audiences => {
    this.adgroupTargeting.audience_exclude = excluded_audiences;
    safeApply(this.scope);
  };
}
