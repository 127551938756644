const CHART_GREY = '#d5d5d5';
const CHART_GREEN = '#81d135';
const CHART_BLUE = '#4e92df';
const VISIT_INDICATOR_LEN = 100;
const MINIMUM_RIGHT_BOUNDARY = 30;
const SIDE_BAR_TOTAL_LEN = 2000;

/**
 * This directive takes in two mandatory paramters and one optional paramter.
 * totalBudgetedVisits and totalBudgetedVisits are required - they are of type numbers
 * disableBudgetedVisits - of type boolean (optional). setting it to true will grey out the Available Budgeted Visits to grey
 */
class XadVisitChart {
  constructor() {
    this.restrict = 'E';
    (this.scope = {
      dailyBudgetedVisits: '=dailyVisits', //blue
      totalBudgetedVisits: '=budgetedVisits', //green
      dailyVisitErrorRange: '=errorRange',
      disableBudgetedVisits: '=disableBudgetedVisits'
    }),
      (this.templateUrl = '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.xad-visit-chart.html');
    this.controller = VisitChartController;
  }

  link(scope, element, attrs, controller) {
    scope.$watchGroup(
      ['dailyBudgetedVisits', 'totalBudgetedVisits', 'dailyVisitErrorRange', 'disableBudgetedVisits'],
      function(newValue, oldValue) {
        let newSeries = controller.makeSeriesData(newValue[0], newValue[1], newValue[2], newValue[3]);
        let index = 0;
        for (let i in newSeries) {
          scope.chart.series[index].update(newSeries[i], false);
          index++;
        }
        scope.chart.redraw();
      }
    );
    scope.chart = controller.makeChart(
      scope.dailyBudgetedVisits,
      scope.totalBudgetedVisits,
      element[0].querySelector('#visitChart')
    );
  }
}
class VisitChartController {
  /**
   * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
   * @ngInject
   */
  constructor(generalUtilsFactory) {
    this.generalUtilsFactory = generalUtilsFactory;
  }
  /**
   *
   * @param dailyVisits dailyVisits number of daily visits. Blue
   * @param budgetedVisits budgetedVisits number of budgeted visits. Green
   * @param errorRange deviation about daily visits, number between 0 to 1.
   * @param disableBudgetedVisits
   * @returns {*[]} an array of objects that give us the overlapping bars that make the visit chart
   * (green, grey and blue). Doesn't handle negative values
   */
  makeSeriesData(dailyVisits = 0, budgetedVisits = 0, errorRange = 0, disableBudgetedVisits = false) {
    if (Number.isNaN(dailyVisits) || !isFinite(dailyVisits)) {
      dailyVisits = 0;
    }
    if (Number.isNaN(budgetedVisits) || !isFinite(budgetedVisits)) {
      budgetedVisits = 0;
    }
    if (Number.isNaN(errorRange) || !isFinite(errorRange)) {
      errorRange = 0;
    }
    dailyVisits = dailyVisits < 0 ? 0 : Math.round(dailyVisits);
    budgetedVisits = budgetedVisits < 0 ? 0 : Math.round(budgetedVisits);
    errorRange = Math.max(0, errorRange);

    /**
     *
     * @param dailyVisits {Number} number of daily visits
     * @param errorRange {Number} deviation about daily visits, number between 0 to 1.
     * @returns This method will return the max value of daily visit and the number between max daily visits and min daily visits
     */
    function getDailyVisitsMaxAndRange(dailyVisits, errorRange) {
      let dailyMaxVisits = 0;
      let dailyRange = 0;
      if (dailyVisits === 0 && errorRange === 0) {
        return { dailyRange, dailyMaxVisits };
      } else {
        dailyMaxVisits = dailyVisits * (1 + errorRange);
        if (dailyMaxVisits < MINIMUM_RIGHT_BOUNDARY) {
          dailyMaxVisits = MINIMUM_RIGHT_BOUNDARY;
          dailyRange = MINIMUM_RIGHT_BOUNDARY;
        } else {
          dailyRange = dailyVisits * 2 * errorRange;
        }
        return { dailyRange, dailyMaxVisits };
      }
    }

    /**
     * The method will return scaled value of dailyMaxVisits, dailyVisitsRange and budgetVisits. They are scaled out of 2000
     * @param {number} dailyMaxVisits: number of max daily visits
     * @param {number} budgetedVisits
     * @param {number} dailyRange: number of daily visits range
     * @returns {{dailyVisitsRange: number, dailyVisitsMaxProgress: number, budgetedVisitsProgress: number}}
     */
    function getScaledValues(dailyMaxVisits, budgetedVisits, dailyRange) {
      let dailyVisitsMaxProgress = VISIT_INDICATOR_LEN;
      let dailyVisitsRange = VISIT_INDICATOR_LEN;
      let budgetedVisitsProgress = 0;
      if (dailyMaxVisits === 0 && budgetedVisits === 0) {
        return { dailyVisitsRange, dailyVisitsMaxProgress, budgetedVisitsProgress };
      } else {
        if (dailyMaxVisits > budgetedVisits) {
          dailyVisitsMaxProgress = SIDE_BAR_TOTAL_LEN;
          dailyVisitsRange = SIDE_BAR_TOTAL_LEN * (dailyRange / dailyMaxVisits);
          budgetedVisitsProgress = SIDE_BAR_TOTAL_LEN * (budgetedVisits / dailyMaxVisits);
        } else {
          budgetedVisitsProgress = SIDE_BAR_TOTAL_LEN;
          dailyVisitsRange = SIDE_BAR_TOTAL_LEN * (dailyRange / budgetedVisits);
          dailyVisitsMaxProgress = SIDE_BAR_TOTAL_LEN * (dailyMaxVisits / budgetedVisits);
        }

        if (budgetedVisitsProgress <= 0) {
          budgetedVisitsProgress = 0;
        } else if (budgetedVisitsProgress === SIDE_BAR_TOTAL_LEN) {
          budgetedVisitsProgress = budgetedVisitsProgress - VISIT_INDICATOR_LEN;
        } else if (budgetedVisitsProgress >= VISIT_INDICATOR_LEN / 2) {
          budgetedVisitsProgress = budgetedVisitsProgress - 50;
        }

        if (dailyVisitsRange <= VISIT_INDICATOR_LEN) {
          dailyVisitsRange = VISIT_INDICATOR_LEN;
        }
        if (dailyVisitsMaxProgress <= VISIT_INDICATOR_LEN) {
          dailyVisitsMaxProgress = VISIT_INDICATOR_LEN;
        }

        dailyVisitsRange = Math.round(dailyVisitsRange);
        dailyVisitsMaxProgress = Math.round(dailyVisitsMaxProgress);
        budgetedVisitsProgress = Math.round(budgetedVisitsProgress);

        return { dailyVisitsRange, dailyVisitsMaxProgress, budgetedVisitsProgress };
      }
    }

    const BORDER_RADIUS = 10;
    const { dailyRange, dailyMaxVisits } = getDailyVisitsMaxAndRange(dailyVisits, errorRange);
    const { dailyVisitsRange, dailyVisitsMaxProgress, budgetedVisitsProgress } = getScaledValues(
      dailyMaxVisits,
      budgetedVisits,
      dailyRange
    );
    const budgetedVisitLabel = this.generalUtilsFactory.shorthandNumber(budgetedVisits, 0, 2);
    const dailyVisitMin = this.generalUtilsFactory.shorthandNumber(dailyMaxVisits - dailyRange, 0, 2);
    const dailyVisitMax = this.generalUtilsFactory.shorthandNumber(dailyMaxVisits, 0, 2);
    const visitRangeLabel =
      dailyVisitMax === '0' || dailyVisitMin === dailyVisitMax
        ? dailyVisitMax
        : dailyMaxVisits <= MINIMUM_RIGHT_BOUNDARY
        ? '<30'
        : dailyVisitMin + '-' + dailyVisitMax;

    return [
      {
        //green part
        data: [VISIT_INDICATOR_LEN],
        stack: 2,
        borderRadius: BORDER_RADIUS,
        borderColor: 'transparent',
        color: disableBudgetedVisits ? 'transparent' : CHART_GREEN,
        zIndex: 7,
        dataLabels: {
          enabled: false
        },
        tooltip: {
          pointFormat: `<span style="color:${CHART_GREEN}; font-size:30px; vertical-align:middle;">●</span>
                <span style="vertical-align:text-top;font-size:11px;">Daily Budget Visits ${budgetedVisits}</span>`
        }
      },
      {
        data: [budgetedVisitsProgress],
        stack: 2,
        color: 'transparent',
        dataLabels: {
          style: {
            color: CHART_GREEN,
            fontSize: '16px',
            fontWeight: '600'
          },
          enabled: !disableBudgetedVisits,
          x: 14,
          y: 18,
          format: budgetedVisitLabel
        },
        enableMouseTracking: false
      },
      {
        //grey background
        data: [SIDE_BAR_TOTAL_LEN],
        stack: 0,
        color: CHART_GREY,
        dataLabels: {
          enabled: false
        },
        enableMouseTracking: false
      },
      {
        //blue part
        data: [dailyVisitsRange],
        stack: 1,
        borderRadius: BORDER_RADIUS,
        zIndex: 5,
        borderColor: 'transparent',
        color: CHART_BLUE,
        dataLabels: {
          style: {
            color: CHART_BLUE,
            fontSize: '16px',
            fontWeight: '600'
          },
          enabled: visitRangeLabel !== '0',
          x: 14,
          format: visitRangeLabel
        },
        tooltip: {
          pointFormat: `<span style="color:${CHART_BLUE}; font-size:30px; vertical-align:middle;">●</span>
                <span style="vertical-align:text-top;font-size:11px;">Daily Available Visits ${visitRangeLabel}</span>`
        }
      },
      {
        data: [dailyVisitsMaxProgress - dailyVisitsRange],
        stack: 1,
        color: 'transparent',
        zIndex: 5,
        dataLabels: {
          style: {
            color: CHART_BLUE
          },
          enabled: visitRangeLabel === '0',
          x: 14,
          format: visitRangeLabel
        },
        enableMouseTracking: false
      }
    ];
  }

  /**
   *
   * @param {*} dailyVisits
   * @param {*} budgetedVisits
   * @param {*} chartArea
   * @returns Highchart instance
   */
  makeChart(dailyVisits, budgetedVisits, chartArea) {
    const seriesData = this.makeSeriesData(dailyVisits, budgetedVisits);

    return Highcharts.chart(chartArea, {
      chart: {
        type: 'bar',
        height: 88,
        width: 319,
        style: {
          fontFamily: 'Poppins',
          fontSize: '16px'
        }
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      xAxis: {
        visible: false
      },
      yAxis: {
        visible: false,
        max: SIDE_BAR_TOTAL_LEN
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        borderColor: 'black',
        shape: 'square',
        backgroundColor: 'black',
        useHTML: true,
        headerFormat: '<div style="line-height:23px;">',
        footerFormat: '</div>',
        style: {
          color: 'white',
          opacity: 0.8,
          margin: 30
        }
      },
      plotOptions: {
        bar: {
          stacking: 'normal',
          grouping: false,
          borderRadius: 10,
          borderColor: 'transparent',
          pointWidth: 7
        },
        series: {
          dataLabels: {
            align: 'right',
            y: 75,
            x: 0,
            overflow: 'allow',
            crop: false,
            style: {
              textOutline: 'none'
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      series: seriesData
    });
  }
}

export function xadVisitChart() {
  return new XadVisitChart();
}
