import * as React from 'react';
import MaterialButtonGroup from '@material-ui/core/ButtonGroup';
import PropTypes from 'prop-types';

type Size = 'small' | 'medium' | 'large';

interface ButtonGroupProps {
  /** @default 'medium' */
  size?: Size;
  children: React.ReactNode;
}

export function ButtonGroup({ size = 'medium', children }: ButtonGroupProps) {
  return <MaterialButtonGroup size={size}>{children}</MaterialButtonGroup>;
}

ButtonGroup.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** You have to wrap your component into a React element */
  children: PropTypes.element
};
