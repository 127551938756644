paymentsFactory.$inject = ['$q', '$http'];

export function paymentsFactory($q, $http) {
  const paymentsFactory = {};

  paymentsFactory.cardStatus = {
    FAILED: 'failed',
    PENDING: 'pending',
    CHARGEABLE: 'chargeable',
    DISPUTE: 'dispute'
  };

  paymentsFactory.getValidPaymentWays = function() {
    const d = $q.defer();

    $http({ method: 'GET', url: '/data/payments/validity' }).then(
      function(response) {
        d.resolve(true);
      },
      function(response) {
        d.resolve(false);
      }
    );

    return d.promise;
  };

  paymentsFactory.getCustomer = function() {
    const d = $q.defer();

    $http({ method: 'GET', url: '/data/payments/customer' }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );

    return d.promise;
  };

  paymentsFactory.getCards = function() {
    const d = $q.defer();

    $http({ method: 'GET', url: '/data/payments/cards' }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );

    return d.promise;
  };

  paymentsFactory.getAcountCards = function(accountId) {
    const d = $q.defer();

    $http({ method: 'GET', url: `/data/payments/account/${accountId}/card` }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );

    return d.promise;
  };

  paymentsFactory.createCard = function(customerId, cardToken) {
    const d = $q.defer();

    var data = {
      customerId: customerId,
      cardToken: cardToken
    };

    $http({ method: 'POST', url: '/data/payments/card/create', data: data }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );

    return d.promise;
  };

  paymentsFactory.setDefaultCard = function(customerId, cardId) {
    const d = $q.defer();

    const data = {
      customerId: customerId,
      cardId: cardId
    };

    $http({ method: 'PUT', url: '/data/payments/card/default', data: data }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );
    return d.promise;
  };

  paymentsFactory.deleteCard = function(customerId, cardId) {
    const d = $q.defer();

    const data = {
      customerId: customerId,
      cardId: cardId
    };

    $http({ method: 'DELETE', url: '/data/payments/card/delete', data: data }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );
    return d.promise;
  };

  paymentsFactory.getBillingThreshold = function() {
    const d = $q.defer();

    $http({ method: 'GET', url: '/data/payments/billing_threshold' }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );

    return d.promise;
  };

  // get statements that are inside this customer profile and repeat them in pdf links
  paymentsFactory.getStatements = function() {
    const d = $q.defer();

    // NOTE: limit/offset is supported for pagination

    $http({ method: 'GET', url: '/data/payments/statements' }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );

    return d.promise;
  };

  // get  whatever promotions are linked to this account, display in table
  paymentsFactory.getPromotions = function() {
    const d = $q.defer();

    $http({ method: 'GET', url: '/data/payments/credits' }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );

    return d.promise;
  };

  paymentsFactory.retryCard = function(cardId) {
    const d = $q.defer();

    const data = {
      cardId: cardId
    };

    $http({ method: 'POST', url: '/data/payments/card/retry', data: data }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );

    return d.promise;
  };

  paymentsFactory.updateConnectedAccounts = function(cardId, accountIds) {
    const d = $q.defer();

    const data = {
      accountIds
    };

    $http({ method: 'POST', url: `/data/payments/cards/${cardId}/accounts`, data: data }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );

    return d.promise;
  };

  return paymentsFactory;
}
