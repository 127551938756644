creativeTemplatesFactory.$inject = ['$q', '$http', '$sce'];

/**
 * @param {ng.IQService} $q
 * @param {ng.IHttpService} $http
 * @param {ng.ISCEService} $sce
 */
export function creativeTemplatesFactory($q, $http, $sce) {
  var creativeTemplateFactory = {};
  /** @type {{  revisionId: string | number, themeId: string }[]} */
  creativeTemplateFactory.templates = [];
  const html5RevisionId = 195;
  const html5ThemeId = '130';
  creativeTemplateFactory.creativeTypeCMSConfigs = {
    IMAGE: {
      bannerUrl: '',
      cssDistance: '',
      cssCustomText: '',
      customText: '',
      distance_text: '',
      externalImpressionUrls: [],
      externalScriptUrls: [],
      externalClickUrl: ''
    },
    SCRIPT: {
      script_tag: '',
      externalImpressionUrls: [],
      externalScriptUrls: []
    },
    VIDEO: {
      vastTagUrl: '',
      externalImpressionUrls: [],
      externalScriptUrls: []
    },
    AUDIO: {
      vastTagUrl: '',
      externalImpressionUrls: [],
      externalScriptUrls: []
    },
    HTML5: {
      themeId: null,
      caption: '',
      name: '',
      backgroundColor: '',
      scrollingTextToggle: '',
      distanceToggle: '',
      backgroundImage: '',
      logoImage: '',
      businessNameColor: '',
      captionColor: '',
      distanceColor: ''
    },
    NATIVE: {
      // TODO: add the config
    },
    HTML5_NEW: {
      html_tag: '',
      externalImpressionUrls: [],
      externalScriptUrls: [],
      externalClickUrl: '',
      s3_file_name: ''
    }
  };

  const requestObjMapping = {
    '195': {
      themeId: '',
      name: '',
      caption: '',
      distanceUnit: 'miles',
      clickUrl: '',
      distance: '2.4',
      imageUrl_50x50: '', //not there
      backgroundImage: '',
      backgroundColor: '',
      distanceToggle: '',
      logoImage: '',
      businessNameColor: '',
      captionColor: '',
      distanceColor: '',
      scrollingTextToggle: '',
      externalImpressionUrl: '',
      impressionUrl: '',
      externalClickUrl: ''
    }
  };

  creativeTemplateFactory.IsHeaderImageSizeProportionate = function(imageSize, allSizes) {
    for (var i = 0; i < allSizes.length; i++) {
      const widthRatio = imageSize.width / allSizes[i].width;
      const heightRatio = imageSize.height / allSizes[i].height;

      // the following formula is to allow a slight amount of discrepancy
      // xAd's creative team thinks 375x234 is the perfect size where as the engineering team thinks 640x400 is ideal
      // this will allow both to coexist
      if (Math.abs(widthRatio / heightRatio - 1) < 0.01) {
        return true;
      }
    }

    return false;
  };

  creativeTemplateFactory.validateVast = function(url) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: '/data/campaigns/creatives/vast_validation',
      data: {
        vast_tag_url: url
      }
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data.message);
      });

    return d.promise;
  };

  creativeTemplateFactory.getHtml5BannerPreview = function(
    name,
    caption,
    backgroundColor,
    backgroundImage,
    distanceToggle,
    logoImage,
    scrollingTextToggle,
    businessNameColor,
    captionColor,
    distanceColor
  ) {
    var d = $q.defer();
    const r = angular.copy(requestObjMapping[html5RevisionId]);
    r.themeId = html5ThemeId;
    r.name = name?.replace(/</g, '&lt;');
    r.caption = caption?.replace(/</g, '&lt;');
    r.backgroundColor = backgroundColor?.replace('#', '');
    r.backgroundImage = backgroundImage;
    r.distanceToggle = distanceToggle;
    r.logoImage = logoImage;
    r.scrollingTextToggle = scrollingTextToggle;
    r.businessNameColor = businessNameColor?.replace('#', '');
    r.captionColor = captionColor?.replace('#', '');
    r.distanceColor = distanceColor?.replace('#', '');

    $http({
      method: 'POST',
      url: `/data/campaigns/creatives/html5_banner_preview?revision_id=${html5RevisionId}`,
      data: { cms_config: JSON.stringify(r) }
    })
      .then(function({ data: response }) {
        let renderedHtml5Preview = $sce.trustAsHtml(response.rendered_html5_preview);
        d.resolve(renderedHtml5Preview);
      })
      .catch(function({ data }) {
        d.reject(data.message);
      });

    return d.promise;
  };

  creativeTemplateFactory.uploadImageUrlToS3 = function(imageUrl, type) {
    var d = $q.defer();
    $http({
      method: 'POST',
      url: '/data/upload/creative_image',
      data: {
        imageUrl: imageUrl,
        type: type
      }
    })
      .then(function({ data }) {
        d.resolve(JSON.stringify(data));
      })
      .catch(function({ data }) {
        d.reject(data.message);
      });

    return d.promise;
  };

  creativeTemplateFactory.getCmsConfigForCreative = function(adgroup_id, creative_id) {
    var d = $q.defer();
    $http({
      method: 'GET',
      url: `/data/campaigns/adgroups/${adgroup_id}/creatives/${creative_id}/cms_config`
    })
      .then(function({ data }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data.message);
      });
    return d.promise;
  };

  creativeTemplateFactory.getHTMLTagForHTML5CreativePreview = function(cmsConfig) {
    // Converting to string because this value might come wrapped with $sce
    const htmlTag = cmsConfig['html_tag'].toString();
    // Removing the image tag with macro for preview
    const htmlWithoutMacros = htmlTag && htmlTag.replace('<img src="%%IMPRESSIONURL%%"/>', '');

    const htmlPreview = $sce.trustAsHtml(htmlWithoutMacros);
    return htmlPreview;
  };

  return creativeTemplateFactory;
}
