userFactory.$inject = ['$q', '$http', 'generalUtilsFactory'];

/**
 * @param {ng.IQService} $q
 * @param {ng.IHttpService} $http
 * @param {import('./types').GeneralUtilsService} generalUtilsFactory
 * @returns {import('./types').UserFactoryService}
 */
export function userFactory($q, $http, generalUtilsFactory) {
  /** @type {import('./types').UserFactoryService} */
  var userFactory = {};

  userFactory.nullUser = {
    id: null,
    username: null,
    email: null,
    firstName: null,
    lastName: null,
    groupList: [],
    authorized_for_mpcb: false,
    authorized_apps: ''
  };

  userFactory.users = [];
  userFactory.groups = [];
  // @ts-ignore
  userFactory.currentLoggedInUser = {};

  userFactory.clean = function() {
    userFactory.users.length = 0;
    userFactory.groups.length = 0;
  };

  userFactory.getUsers = function() {
    var d = $q.defer();
    if (userFactory.users.length > 0) {
      // if cached
      d.resolve(userFactory.users);
      return d.promise;
    } else {
      $http({ method: 'GET', url: '/data/users_for_tenant' }).then(
        function(response) {
          angular.copy(response.data, userFactory.users);
          d.resolve(response.data);
        },
        function(response) {
          return $q.reject(response.data.message);
        }
      );
      return d.promise;
    }
  };

  userFactory.createUser = function(user) {
    var d = $q.defer();
    var promise = $http({ method: 'POST', url: '/users', timeout: d.promise, data: { user: user } }).then(
      function(response) {
        userFactory.users.push(response.data);
        return response.data;
      },
      function(response) {
        return $q.reject(response.data.message);
      }
    );

    return promise;
  };

  userFactory.handleUserRequest = function(user, accept) {
    var d = $q.defer();
    var promise = $http({
      method: 'PUT',
      url: '/data/users/' + user.id + '/request',
      timeout: d.promise,
      data: {
        accept: accept
      }
    }).then(
      function(response) {
        const updatedUser = response.data;
        if (!accept) {
          userFactory.updateUsersData(user, true);
        } else {
          userFactory.updateUsersData(updatedUser, false);
        }
        d.resolve();
        return response.data;
      },
      function(response) {
        return $q.reject(response.data.message);
      }
    );

    return promise;
  };

  userFactory.updateUser = function(user) {
    var d = $q.defer();
    var promise = $http({
      method: 'PUT',
      url: '/update_user/' + user.id,
      timeout: d.promise,
      data: { user: user }
    }).then(
      function(response) {
        const updatedUser = response.data;
        userFactory.updateUsersData(updatedUser, false);
        return response.data;
      },
      function(response) {
        return $q.reject(response.data.message);
      }
    );

    return promise;
  };

  userFactory.deleteUser = function(user) {
    var d = $q.defer();
    var promise = $http({ method: 'DELETE', url: '/update_user/' + user.id, timeout: d.promise, data: { user: user } })
      .then(function({ data: response }) {
        userFactory.updateUsersData(user, true);
        return response.data;
      })
      .catch(function({ data: response }) {
        return $q.reject(response.message);
      });

    return promise;
  };

  userFactory.sendActivationEmail = function(user) {
    var d = $q.defer();
    var promise = $http({
      method: 'POST',
      url: '/sendActivationEmail/' + user.id,
      timeout: d.promise,
      data: { user: user }
    }).then(
      function(response) {
        return response.data;
      },
      function(response) {
        return $q.reject(response.data.message);
      }
    );

    return promise;
  };

  userFactory.sendForgetPasswordEmail = function(user) {
    var d = $q.defer();
    var promise = $http({
      method: 'POST',
      url: '/forgot_password_from_manager/' + user.id,
      timeout: d.promise,
      data: { user: user }
    }).then(
      function(response) {
        return response.data;
      },
      function(response) {
        return $q.reject(response.data.message);
      }
    );

    return promise;
  };

  userFactory.getGroups = function() {
    var d = $q.defer();
    if (userFactory.groups.length > 0) {
      userFactory.groups.forEach(function(group) {
        group.checked = false;
      });
      d.resolve(userFactory.groups);
      return d.promise;
    } else {
      $http({ method: 'GET', url: '/data/static/user_groups' }).then(
        function(response) {
          if (response.data) {
            response.data.forEach(function(group) {
              group.checked = false;
            });
            angular.copy(response.data, userFactory.groups);
            d.resolve(response.data);
          }
        },
        function(response) {
          return $q.reject(response.data.message);
        }
      );

      return d.promise;
    }
  };

  userFactory.getCurrentLoggedInUser = async () => {
    return userFactory.currentLoggedInUser;
  };

  userFactory.updateUsersData = function(updatedUser, isDelete) {
    for (var i = 0; i < userFactory.users.length; i++) {
      if (userFactory.users[i].id == updatedUser.id) {
        if (isDelete) {
          userFactory.users.splice(i, 1);
        } else {
          generalUtilsFactory.nestedUpdateInPlace(updatedUser, userFactory.users[i]);
          break;
        }
      }
    }
  };

  userFactory.getSelectedUser = function(userId) {
    for (var i = 0; i < userFactory.users.length; i++) {
      if (userFactory.users[i].id == userId) {
        return userFactory.users[i];
      }
    }
  };

  userFactory.inviteUserSendEmail = function(inviteUserEmail) {
    var d = $q.defer();
    var promise = $http({
      method: 'POST',
      url: '/data/invite_user',
      timeout: d.promise,
      data: { email: inviteUserEmail }
    }).then(
      function(response) {
        return response.data;
      },
      function(response) {
        return $q.reject(response.data.message);
      }
    );

    return promise;
  };

  return userFactory;
}
