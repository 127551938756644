import * as t from 'io-ts';

export const PositiveNumber = t.refinement(t.Integer, (n): n is any => n > 0, 'PositiveNumber');
export const Id = t.refinement(PositiveNumber, (n): n is any => true, 'Id');

/**
 * A string that represents a date.
 */
export const DateString = t.refinement(t.string, (s): s is any => !isNaN(Date.parse(s)), 'DateString');

/**
 * A string that represents date and time.
 */
export const DateTimeString = t.refinement(t.string, (s): s is any => !isNaN(Date.parse(s)), 'DateTimeString');

/**
 * A string that represents a URL.
 * At the moment the string is not validated for format, only that it is not empty.
 */
export const UrlString = t.refinement(t.string, s => !!s, 'UrlString');

/**
 * A string that represents an email.
 * At the moment the string is not validated for format, only that it is not empty.
 */
export const EmailString = t.refinement(t.string, s => !!s, 'EmailString');

export class EnumerationType<E> extends t.Type<E[keyof E]> {
  readonly _tag: 'EnumerationType' = 'EnumerationType';
  constructor(
    name: string,
    is: EnumerationType<E>['is'],
    validate: EnumerationType<E>['validate'],
    encode: EnumerationType<E>['encode'],
    readonly enumDef: E
  ) {
    super(name, is, validate, encode);
  }
}

export function enumeration<E>(enumDef: E, name?: string): EnumerationType<E> {
  const entries = Object.keys(enumDef)
    .map(k => [k, enumDef[k]] as [string, E[keyof E]])
    .filter(([k, v]) => v !== undefined && String(parseInt(k, 10)) != k);
  const is = (u: unknown): u is E[keyof E] => entries.some(([, v]) => u === v);

  name = name ?? `(${entries.map(([k, v]) => k).join(' | ')})`;

  return new EnumerationType<E>(name, is, (u, c) => (is(u) ? t.success(u) : t.failure(u, c)), t.identity, enumDef);
}

export function nullable<C extends t.Mixed>(codec: C) {
  return t.union([codec, t.null]);
}
