import * as React from 'react';
import { useObserver, Observer } from 'mobx-react-lite';
import { styled } from '../../../utils/react/styled';
import { Header } from './header';
import { TableView, Column } from '../../../components/reactComponents/Table/TableView';
import { Link, CircularIndicator } from '../../../components/reactComponents';
import { Report, ReportStatus } from '../model/report_scheduler_model';
import { statusColor, gtBlue } from '../../../components/reactComponents/styles/colorConstants';
import { toFirstCharUpperCase } from '../../../factories/general_utils';
import { ReportItemPopupMenu } from './report_item_popup_menu';
import { useLocalModel } from '../../../utils/react/use_local_model';
import { ReportListPageModel } from './report_list_page_model';
import { Search } from './search';
import { LoadingModal } from '../../../components/reactComponents/Modal';
import { useSafeAsyncAction } from '../../../utils/react/use_async_action';
import { store } from '../model/store';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { ErrorSquaredIcon } from '../../../components/reactComponents/Icons';

import { useConfirmationModal } from '../../../components/reactComponents/Modal/useConfirmationModal';

const StyledText = styled('span')(({ status }: { status: string }) => ({
  color: statusColor[status],
  fontFamily: 'var(--font-family-regular)',
  fontSize: 'var(--font-size-regular)',
  fontWeight: 'var(--font-weight-bold)',
  lineHeight: 'var(--line-height)'
}));

const LoadingIndicatorContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center'
});

const NumberOfRecipients = styled('span')({
  color: 'var(--color-on-primary)'
});

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: 'var(--color-on-primary)',
    borderRadius: '4px',
    color: 'var(--color-background)',
    fontFamily: 'var(--font-family-regular)',
    fontSize: 'var(--font-size-regular)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--line-height)',
    padding: '10px'
  },
  arrow: {
    color: 'var(--color-on-primary)'
  }
})(Tooltip);

interface StatusTextProps {
  status: ReportStatus;
}

const StatusText = (props: StatusTextProps) => (
  <StyledText status={props.status}>{toFirstCharUpperCase(props.status.toLowerCase())}</StyledText>
);

function columnSelector(column: keyof Report) {
  return `.${column}`;
}

const ReportTableView = styled(TableView)({
  [columnSelector('report_type')]: {
    width: '16rem'
  },
  [columnSelector('created_by')]: {
    width: '16rem'
  },
  [columnSelector('created_date')]: {
    width: '16rem'
  },
  [columnSelector('number_of_recipients')]: {
    width: '16rem'
  },
  [columnSelector('status')]: {
    width: '16rem'
  },
  ['.action']: {
    width: '10rem'
  }
}) as typeof TableView;

const tooltipRender = (item: Report) => {
  const recepientNumber = <NumberOfRecipients>{item['number_of_recipients']}</NumberOfRecipients>;
  if (item.email_recipients.length > 0 && item.number_of_recipients) {
    let emailListAsString = item.email_recipients.join(', ');
    let restOfEmailCount = item.number_of_recipients - item.email_recipients.length;
    let titleString = restOfEmailCount ? `${emailListAsString} (${restOfEmailCount} more)` : emailListAsString;
    return <StyledTooltip title={titleString}>{recepientNumber}</StyledTooltip>;
  }

  return recepientNumber;
};

export const ReportList = () => {
  const model = useLocalModel(p => new ReportListPageModel(p.store.reportScheduler), { store });

  const sortField = useObserver(() => model.sortField);
  const sortDirection = useObserver(() => model.sortDirection);
  const reportTypes = useObserver(() => store.reportScheduler.reportTypes);
  const itemsPerPage = useObserver(() => store.reportScheduler.getItemsPerPage);
  const [confirmationModal, confirm] = useConfirmationModal();
  const [loading, waitAsync, hasError, error] = useSafeAsyncAction();
  const [, noLoadingWaitAsync] = useSafeAsyncAction();

  const column = (field: keyof Report): Column<Report> => ({
    onSort: () => model.sortReportList(field),
    sortedDirection: sortField === field ? sortDirection : null,
    selector: item => item[field],
    title: field,
    className: field
  });

  const onPauseReport = (report: Report) => {
    setIsAlertVisible(true);
    waitAsync(store.reportScheduler.pauseReport(report));
  };

  const onResumeReport = (report: Report) => {
    setIsAlertVisible(true);
    waitAsync(store.reportScheduler.resumeReport(report));
  };

  const onDelete = (report: Report) => {
    confirm({
      title: 'Delete?',
      confirmationText: `Are you sure you want to delete report '${report.report_name}'?`,
      onConfirm: () => noLoadingWaitAsync(store.reportScheduler.deleteReport(report))
    });
  };

  const onEdit = (report: Report) => store.reportScheduler.navigateToReportEditPage(report.report_id);

  const columns: Column<Report>[] = [
    {
      ...column('report_name'),
      title: 'Report Name',
      selector: item => <Link href={'/report-scheduler/' + item.report_id}>{item.report_name}</Link>
    },
    {
      ...column('report_type'),
      title: 'Report Type',
      selector: item =>
        reportTypes?.find(o => o.report_type === item.report_type)?.display_name ?? String(item.report_type)
    },
    {
      ...column('created_by'),
      title: 'Created By'
    },
    {
      ...column('created_date'),
      title: 'Creation Date'
    },
    {
      ...column('number_of_recipients'),
      title: '# Recipients',
      selector: tooltipRender
    },
    {
      ...column('status'),
      title: 'Status',
      selector: item => <StatusText status={item.status} />
    },
    {
      title: 'Action',
      className: 'action',
      selector: report => (
        <ReportItemPopupMenu
          onDelete={onDelete}
          onEdit={onEdit}
          onResume={onResumeReport}
          onPause={onPauseReport}
          report={report}
        />
      )
    }
  ];

  const onItemsPerPageChange = itemsPerPage => {
    store.reportScheduler.updateItemsPerPage(itemsPerPage);
  };

  React.useEffect(() => {
    waitAsync(store.reportScheduler.loadReports());
  }, []);
  const [isAlertVisible, setIsAlertVisible] = React.useState(true);
  return (
    <div className="report-list">
      <Header />
      {hasError && isAlertVisible && (
        <Alert
          severity="error"
          icon={<ErrorSquaredIcon className="error-icon" />}
          onClose={() => {
            setIsAlertVisible(false);
          }}
        >
          {error?.details?.map(item => {
            return <>{item}</>;
          })}
        </Alert>
      )}
      <Search reportListPageModel={model}></Search>
      <Observer>
        {() =>
          model.loading ? (
            <LoadingIndicatorContainer>
              <CircularIndicator />
            </LoadingIndicatorContainer>
          ) : (
            <></>
          )
        }
      </Observer>
      <Observer>
        {() =>
          model.reportsVirtualList ? (
            <ReportTableView
              columns={columns}
              itemsVirtualList={model.reportsVirtualList}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={onItemsPerPageChange}
              emptyTableMessage="No reports available."
            />
          ) : (
            <></>
          )
        }
      </Observer>

      {confirmationModal}

      <LoadingModal loading={loading} loadingMessage="Please wait" />
    </div>
  );
};
