import * as React from 'react';
import { ReportStatus } from '../model/report_scheduler_model';
import { styled } from '../../../utils/react/styled';
import { ReportListPageModel } from './report_list_page_model';
import { Observer } from 'mobx-react-lite';
import { PrimaryButton } from '../../../components/reactComponents/Button';
import { store } from '../model/store';
import { SearchBarV2 } from '../../../components/reactComponents';

const SearchContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: 12px 40px;
  height: 'var(--top-bar-height)';
`;

const SearchButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const StyledSectionTitle = styled('span')({
  display: 'flex'
});

const StyledIcon = styled('i')({
  fontSize: 'var(--icon-size)',
  marginRight: '12px'
});

const ButtonGroupContainer = styled('div')`
  justify-content: center;
`;
const SearchButton = styled(PrimaryButton)`
  & .MuiButton-label {
    font-weight: var(--font-weight-light-bold);
  }
`;
const StyledSearchTextFieldContainer = styled('div')`
  width: 300px;
`;

export const Search = ({ reportListPageModel }: { reportListPageModel: ReportListPageModel }) => {
  const onButtonSelect = (reportButton: ReportStatus | null) => {
    reportListPageModel.updateStatusFilter(reportButton);
  };

  const isSearchButtonOutlined = (
    reportStatus: ReportStatus | null,
    currentSelectedModelFilter: ReportStatus | null
  ) => {
    return reportStatus !== currentSelectedModelFilter;
  };
  const getButtonClassName = (status: ReportStatus | null) => {
    const isOutlined = !isSearchButtonOutlined(status, reportListPageModel.statusFilter);
    return isOutlined ? 'status-filter-button active' : 'status-filter-button';
  };

  return (
    <SearchContainer>
      <PrimaryButton
        title={
          <StyledSectionTitle className="section-title__btn-label">
            <StyledIcon className="far fa-plus-square"> </StyledIcon>
            Create Report
          </StyledSectionTitle>
        }
        onClick={store.reportScheduler.navigateToReportCreatePage}
        className="button-v2 button--green add-payment-button"
      />
      <SearchButtonContainer>
        <ButtonGroupContainer>
          <Observer>
            {() => (
              <>
                <div className="report-action-container">
                  <div className="status-filter-field">
                    <SearchButton
                      onClick={() => {
                        onButtonSelect(null);
                      }}
                      title="All"
                      className={
                        !isSearchButtonOutlined(null, reportListPageModel.statusFilter)
                          ? 'status-filter-button active'
                          : 'status-filter-button'
                      }
                      outlined={isSearchButtonOutlined(null, reportListPageModel.statusFilter)}
                    />
                    <SearchButton
                      onClick={() => {
                        onButtonSelect(ReportStatus.PENDING);
                      }}
                      title="Pending"
                      className={getButtonClassName(ReportStatus.PENDING)}
                      outlined={isSearchButtonOutlined(ReportStatus.PENDING, reportListPageModel.statusFilter)}
                    />
                    <SearchButton
                      onClick={() => {
                        onButtonSelect(ReportStatus.ACTIVE);
                      }}
                      title="Active"
                      className={getButtonClassName(ReportStatus.ACTIVE)}
                      outlined={isSearchButtonOutlined(ReportStatus.ACTIVE, reportListPageModel.statusFilter)}
                    />
                    <SearchButton
                      onClick={() => {
                        onButtonSelect(ReportStatus.PAUSED);
                      }}
                      title="Paused"
                      className={getButtonClassName(ReportStatus.PAUSED)}
                      outlined={isSearchButtonOutlined(ReportStatus.PAUSED, reportListPageModel.statusFilter)}
                    />
                    <SearchButton
                      onClick={() => {
                        onButtonSelect(ReportStatus.EXPIRED);
                      }}
                      title="Expired"
                      className={getButtonClassName(ReportStatus.EXPIRED)}
                      outlined={isSearchButtonOutlined(ReportStatus.EXPIRED, reportListPageModel.statusFilter)}
                    />
                  </div>
                </div>
              </>
            )}
          </Observer>
        </ButtonGroupContainer>
      </SearchButtonContainer>
      <StyledSearchTextFieldContainer>
        <SearchBarV2
          searchValue={reportListPageModel.searchText}
          onSearch={reportListPageModel.updateSearchTextField}
          classname="search_account_field"
          placeholder="Search Reports"
        />
      </StyledSearchTextFieldContainer>
    </SearchContainer>
  );
};
