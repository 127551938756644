import { tenantDashboardController } from './tenant_dashboard.ctrl';
import { tenantDashboardService } from './tenant_dashboard.serv';

export const controllers = {
  tenantDashboardController
};

export const services = {
  tenantDashboardService
};
