import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

const StyledPath = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGPathElement>) => (
  <path fill="none" stroke="#0083c6" strokeLinecap="round" strokeLinejoin="round" {...props} />
);

const StyleLine = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGLineElement>) => (
  <line fill="none" stroke="#0083c6" strokeLinecap="round" strokeLinejoin="round" {...props} />
);

export const OTTIcon = createSvgIcon(
  <path
    id="Vector"
    d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM20 33V15L32 24L20 33Z"
    fill="#0A3F36"
  />,
  'GTOTT'
);

export default OTTIcon;
