import { safeApply, downloadSpreadsheet, getDateFromToday, getCurrentDate } from '../../../factories/general_utils';

export class tenantDashboardController {
  /**
   * @param {import('@uirouter/angularjs').StateService} $state
   * @param {ng.ITimeoutService} $timeout
   * @param {ng.IFilterService} $filter
   * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
   * @param {import('ngstorage').ngStorage.StorageService} $localStorage
   * @param {import('../../../factories/debounce2').Debounce2Service} debounce2
   *
   */
  constructor(
    $scope,
    $state,
    $timeout,
    $filter,
    tenantDashboardService,
    companyAndAccountFactory,
    modalFactory,
    $localStorage,
    FileSaver,
    debounce2,
    featureFlagFactory
  ) {
    this.$scope = $scope;
    this.$state = $state;
    this.$timeout = $timeout;
    this.$filter = $filter;
    this.debounce2 = debounce2;
    this.tenantDashboardService = tenantDashboardService;
    this.FileSaver = FileSaver;
    this.companyAndAccountFactory = companyAndAccountFactory;
    this.modalFactory = modalFactory;
    this.featureFlagFactory = featureFlagFactory;
    this.selectedTenantName = companyAndAccountFactory.selectedTenant?.name;
    this.currentPage = 1;
    this.lastRequestBody = {};
    this.statusFilter = [
      {
        value: '',
        name: 'All'
      },
      {
        value: 'Pending',
        name: 'Pending'
      },
      {
        value: 'Active',
        name: 'Active'
      },
      {
        value: 'Paused',
        name: 'Paused'
      },
      {
        value: 'Expired',
        name: 'Expired'
      }
    ];

    this.tableClasses = ['scrollable-table dashboard-table'];
    this.tableName = 'tenantDashboard';

    this.allHeaders = [
      {
        name: 'Organization Name',
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'organization_name',
        components: [{ field: 'organization_name' }]
      },
      {
        name: 'Organization ID',
        hide: true,
        type: 'dimension',
        field: 'organization_id',
        hideInBreakdown: true,
        components: [{ field: 'organization_id' }]
      },
      {
        name: 'Account Name',
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'account_name',
        components: [
          {
            field: 'account_name',
            link: {
              title: 'account dashboard',
              target: '_blank',
              getUrl: this.getAccountDashboardUrl,
              field: ['organization_id', 'account_id']
            }
          }
        ]
      },
      {
        name: 'Account ID',
        hide: true,
        type: 'dimension',
        field: 'account_id',
        hideInBreakdown: true,
        components: [{ field: 'account_id' }]
      },
      {
        name: 'Campaign Name',
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'campaign_name',
        components: [
          {
            field: 'campaign_name',
            link: {
              title: 'campaign dashboard',
              target: '_blank',
              getUrl: this.getCampaignDashboardUrl,
              field: 'campaign_id'
            }
          }
        ]
      },
      {
        name: 'Campaign ID',
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'campaign_id',
        components: [{ field: 'campaign_id' }]
      },
      {
        name: 'Status',
        mustShow: true,
        hide: false,
        type: 'dimension',
        field: 'status',
        components: [{ field: 'status', getCSSClass: this.getStatusCSSClass }]
      },
      {
        name: 'SF#',
        hide: true,
        type: 'dimension',
        field: 'salesforce_number',
        components: [{ field: 'salesforce_number' }]
      },

      {
        name: 'Pacing',
        hide: false,
        type: 'delivery',
        field: 'pacing',
        components: [{ field: 'pacing', filter: 'percentage' }]
      },
      {
        name: 'Total Spent',
        sortable: false,
        hide: false,
        type: 'delivery',
        field: 'spends_spent',
        components: [{ field: 'spends_spent' }]
      },
      {
        name: 'Spent Today',
        sortable: false,
        hide: true,
        type: 'delivery',
        field: 'daily_spend',
        components: [{ field: 'daily_spend' }]
      },
      {
        name: 'Budget',
        hide: false,
        type: 'delivery',
        field: 'budget',
        components: [{ field: 'budget' }]
      },
      {
        name: 'Impressions',
        sortable: false,
        hide: false,
        type: 'delivery',
        field: 'impressions',
        components: [{ field: 'impressions' }]
      },
      {
        name: 'Clicks',
        sortable: false,
        hide: true,
        type: 'delivery',
        field: 'clicks',
        components: [{ field: 'clicks' }]
      },
      {
        name: 'CTR',
        sortable: false,
        hide: true,
        type: 'delivery',
        field: 'ctr',
        components: [{ field: 'ctr', filter: 'percentageNotMultiplied100' }] // CTR is returned as a percentage
      },
      {
        name: 'Reach',
        sortable: false,
        hide: true,
        type: 'delivery',
        field: 'reach',
        components: [{ field: 'reach' }]
      },

      {
        name: 'Days Remaining',
        hide: false,
        type: 'flight and budget',
        field: 'days_remaining',
        components: [{ field: 'days_remaining' }]
      },
      {
        name: 'Start',
        hide: false,
        type: 'flight and budget',
        field: 'start_date',
        components: [{ field: 'start_date' }]
      },
      {
        name: 'End',
        hide: false,
        type: 'flight and budget',
        field: 'end_date',
        components: [{ field: 'end_date' }]
      },

      {
        name: 'Total Sec. Actions',
        sortable: false,
        hide: true,
        type: 'Total Sec. Actions',
        field: 'secondary_actions',
        components: [{ field: 'secondary_actions' }]
      },
      {
        name: 'SAR',
        sortable: false,
        hide: true,
        type: 'Total Sec. Actions',
        field: 'sar',
        filter: 'percentage',
        components: [{ field: 'sar', filter: 'percentage' }] // SAR is returned as a raw decimal
      },

      {
        name: 'Visits',
        sortable: false,
        hide: true,
        type: 'visits',
        field: 'visits',
        components: [{ field: 'visits' }]
      },
      {
        name: 'VR',
        sortable: false,
        hide: true,
        type: 'visits',
        field: 'vr',
        components: [{ field: 'vr', filter: 'percentage' }] // VR is returned as a raw decimal
      },
      {
        name: 'Web Conversions',
        sortable: false,
        hide: true,
        type: 'visits',
        field: 'conversions',
        components: [{ field: 'conversions' }]
      },
      {
        name: 'VCR',
        sortable: false,
        hide: true,
        type: 'video',
        field: 'vcr',
        components: [{ field: 'vcr', filter: 'percentage' }]
      }
    ];

    this.allHeaders = this.allHeaders.filter(header => !header.removeIf);

    this.tableConfig = {
      tableData: [],
      header: [
        { name: '', hide: false, field: 'checkbox', checked: false, components: [{ field: 'checkbox' }] },
        ...this.allHeaders
      ],
      tableName: this.tableName,
      paginationTrigger: angular.noop,
      emptyTableMessage: 'There are no campaigns that match the selected filters.'
    };

    this.booleans = {
      selectionProgress: true,
      tableChanged: false
    };
    this.checkedIds = [];
    this.sortDesc = false;
    this.sortBy = '';
    this.currentCampaignFilterStatus = '';
    this.selectedCompanyIds = [];
    this.accountIds = [];
    this.searchCampaign = null;
    this.itemsPerPage = 10;
    this.availableLimits = [10, 25, 50];
    this.$storage = $localStorage;
    this.isExportInProgress = false;
    this.campaignsUnderTenantCompanyAccount = [];
    this.countOfCampaigns = 0;

    this.pullFromLocalStorage();
    this.updateItemsPerPage(false);
    this.updatePagination();
    this.initTableData();
    this.debouncedUpdate = this.debounce2(1000, this.updateCampaignList).bind(this);

    this.$scope.$on('companyOrAccountChanged', (_event, payload) => {
      this.selectedCompanyIds = payload.selectedCompanies;
      this.accountIds = payload.selectedAccounts;
      this.debouncedUpdate();
    });
    this.clearSearch = () => {
      this.searchCampaign = ''; // Clear the search input field
      this.updateCampaignList();
    };
  }

  goToTenantPage() {
    this.$state.go('tenant-manager');
  }

  pullFromLocalStorage() {
    if (!this.$storage.tenantDashboard) {
      this.$storage.tenantDashboard = {};
    }

    if (this.$storage.tenantDashboard.selectedCompanies) {
      this.selectedCompanyIds = this.$storage.tenantDashboard.selectedCompanies;
    }
    if (this.$storage.tenantDashboard.selectedAccounts) {
      this.accountIds = this.$storage.tenantDashboard.selectedAccounts;
    }

    if (this.$storage.tenantDashboard.tableConfig) {
      this.allHeaders.forEach(headerItem => {
        headerItem.hide = !this.$storage.tenantDashboard.tableConfig[headerItem.field];
      });
    }

    if (this.$storage.selectedDurationRange) {
      this.selectedDurationRange = {
        startDate: this.$storage.selectedDurationRange.startDate,
        endDate: this.$storage.selectedDurationRange.endDate
      };
    }
  }

  updateItemsPerPage(triggerUpdate = true) {
    const triggerCampaignUpdate = triggerUpdate && this.itemsPerPage !== this.$storage.tablesLimit.tenantDashboard;
    if (this.$storage.tablesLimit && this.$storage.tablesLimit.tenantDashboard) {
      this.itemsPerPage = this.$storage.tablesLimit.tenantDashboard;
    } else {
      this.itemsPerPage = 10;
    }

    if (triggerCampaignUpdate) {
      this.updateCampaignList(this.sortBy, this.sortDesc, false);
    }
  }

  getStatusCSSClass(status) {
    const rowStatus = status.toLowerCase();
    if (['active', 'pending', 'paused', 'expired'].includes(rowStatus)) {
      return `status ${rowStatus}-status`;
    }
    return '';
  }

  getAccountDashboardUrl([companyId, accountId]) {
    return `/organization/${companyId}/account/${accountId}`;
  }

  getCampaignDashboardUrl(campaignId) {
    return `/campaign/${campaignId}`;
  }

  async paginationTrigger() {
    await this.updateCampaignList(this.sortBy, this.sortDesc, false);
    this.clearOutGlobalCheck();
    safeApply(this.$scope);
  }

  clearOutGlobalCheck() {
    if (!this.campaignsUnderTenantCompanyAccount || !this.campaignsUnderTenantCompanyAccount.length) {
      return;
    }

    const oneUnChecked = this.campaignsUnderTenantCompanyAccount.find(campaign => !campaign.checked);
    this.tableConfig.header.forEach(header => {
      if (header.field === 'checkbox') {
        header.checked = !oneUnChecked;
      }
    });
  }

  clearAllSelections() {
    this.checkedIds.splice(0, this.checkedIds.length);
    this.campaignsUnderTenantCompanyAccount.forEach(campaign => (campaign.checked = false));
    this.clearOutGlobalCheck();
    this.booleans.tableChanged = true;
  }

  updatePagination() {
    this.numberOfPages = Math.ceil(this.countOfCampaigns / this.itemsPerPage) || 1;
  }

  updateSpinnerPosition() {
    const table = /** @type {HTMLElement} */ (document.querySelector('#tenant-dashboard-table'));
    this.spinnerOffset = table.offsetHeight / 2 + table.offsetTop;
    this.spinnerOffset = this.spinnerOffset < window.innerHeight ? this.spinnerOffset : window.innerHeight / 2;
    this.spinnerOffset += 60; // table header offset;
    this.spinnerTopOffsetInPixels = `${this.spinnerOffset}px`;
  }

  formatReportingDimensions(item) {
    if (item.budget !== undefined) {
      item.budget = this.$filter('isoCurrency')(item.budget, item.currency);
    }
    if (item.end_date == null) {
      item.budget = null;
    }
    if (item.spends_spent !== undefined) {
      item.spends_spent = this.$filter('isoCurrency')(item.spends_spent, item.currency);
    }
    if (item.daily_spend !== undefined) {
      item.daily_spend = this.$filter('isoCurrency')(item.daily_spend, item.currency);
    }

    this.allHeaders.forEach(header => {
      if (item[header.field] === undefined || item[header.field] === null) {
        item[header.field] = '--';
      }
    });
  }

  formatData(data, clearCheck) {
    const checkedCampaignsSet = new Set(this.checkedIds);
    data.forEach(item => {
      item.id = item.campaign_id;
      if (!clearCheck) {
        checkedCampaignsSet.has(item.id) ? (item.checked = true) : (item.checked = false);
      }
      this.formatReportingDimensions(item);
    });
    return data;
  }

  /**
   * Updates the list of campaigns to be showned
   * @param {string} sortBy - column header name to sort the table
   * @param {boolean} sortDesc - sort by descending
   * @param {boolean} clearCheck - if `true` clears out selected campaigns, else preserves them
   * @returns {Promise} - list of campaigns with count of all campaigns required for pagination
   */
  async updateCampaignList(sortBy = this.sortBy, sortDesc = this.sortDesc, clearCheck = true) {
    this.updateSpinnerPosition();
    if (sortBy || sortDesc) {
      this.sortBy = sortBy;
      this.sortDesc = sortDesc;
    }
    this.booleans.selectionProgress = true;
    this.selectedDurationRange = {
      startDate: this.$storage.selectedDurationRange.startDate,
      endDate: this.$storage.selectedDurationRange.endDate
    };
    const body = {
      status: this.currentCampaignFilterStatus,
      sort_by: sortBy,
      sort_desc: sortDesc,
      page: this.currentPage,
      limit: this.itemsPerPage,
      organization_ids: this.selectedCompanyIds,
      account_ids: this.accountIds,
      min_date: this.selectedDurationRange?.startDate,
      max_date: this.selectedDurationRange?.endDate,
      search: this.searchCampaign
    };
    // The lastRequestBody is used to compare to response's request body
    // And will only update state if the two body match;
    this.lastRequestBody = body;
    try {
      const result = await this.tenantDashboardService.getCampaigns(body);
      if (result && result.data && this.lastRequestBody === result.config.data) {
        this.campaignsUnderTenantCompanyAccount = this.formatData(result.data.campaigns, clearCheck);
        this.countOfCampaigns = result.data.count ? result.data.count : 0;
        if (clearCheck) {
          this.clearAllSelections();
        }
      }
    } catch (e) {
      console.error(e);
    }

    this.booleans.tableChanged = true;
    this.booleans.selectionProgress = false;
    this.updatePagination();
    safeApply(this.$scope);
    return this.campaignsUnderTenantCompanyAccount;
  }

  filterCampaignsByStatus(field) {
    if (field !== this.currentCampaignFilterStatus) {
      this.currentCampaignFilterStatus = field;
      this.updateCampaignList();
    }
  }

  openColumnPickerModal() {
    let modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-column-picker.html',
      controller: 'ColumnPickerModalController',
      size: 'xl'
    };

    let data = {
      // generate the current set of available columns
      availableHeaders: this.allHeaders
    };

    this.modalFactory.createModal(modalOptions, data).then(data => {
      if (!data) {
        return;
      }
      let tableHeaderConfig = {};
      this.allHeaders.forEach(header => {
        tableHeaderConfig[header.field] = header.mustShow || !header.hide;
      });
      this.$storage.tenantDashboard.tableConfig = tableHeaderConfig;
    });
  }

  getExportFileName(fileName) {
    if (fileName) {
      const fileNameRegExp = new RegExp(/\bfilename="([^"]+)"/);
      const result = fileName.match(fileNameRegExp);
      if (result !== null && result[1]) {
        return result[1];
      }
    }
    return `Tenant_Report.xlsx`;
  }

  async exportData() {
    try {
      this.isExportInProgress = true;
      const shownHeaders = this.allHeaders
        .filter(header => header.mustShow || !header.hide)
        .map(header => header.field);
      const body = {
        campaign_ids: this.checkedIds,
        columns: shownHeaders,
        min_date: this.selectedDurationRange?.startDate,
        max_date: this.selectedDurationRange?.endDate
      };
      const response = await this.tenantDashboardService.getExportData(body);
      const fileName = this.getExportFileName(response.headers('Content-Disposition'));
      downloadSpreadsheet(this.FileSaver, response.data, fileName);
    } catch (e) {
      console.error(e);
    } finally {
      this.isExportInProgress = false;
      safeApply(this.$scope);
    }
  }

  /**
   * Initializes the table with data
   * @returns {Promise<void>}
   */
  async initTableData() {
    try {
      const data = await this.getTableInitData();
      if (!data || this.campaignsUnderTenantCompanyAccount.length) {
        // If the initial request's response came after a subsequent call's response, then do nothing
        // This could happen if the initial call took a long time. In the meantime, the user
        // may have changed a filter, which results in a second call. If the second call responded first,
        // then we need to make sure the initial call doesn't override that.
        return;
      }

      this.campaignsUnderTenantCompanyAccount = this.formatData(data.campaigns);
      this.countOfCampaigns = data.count;
      this.updatePagination();
      this.booleans.tableChanged = true;
    } catch (e) {
      console.error(e);
    } finally {
      this.booleans.selectionProgress = false;
      safeApply(this.$scope);
    }
  }

  /**
   * Fetches the initial table data using default filters or filters saved in local storage
   * @returns {Promise<*>}
   */
  async getTableInitData() {
    let selectedAccounts = [],
      selectedCompanies = [],
      limit = 10,
      selectedDurationRange = {
        startDate: getDateFromToday(-29),
        endDate: getCurrentDate()
      };
    if (this.$storage.tablesLimit) {
      if (this.$storage.tablesLimit.tenantDashboard) {
        limit = this.$storage.tablesLimit.tenantDashboard;
      }
    } else {
      this.$storage.tablesLimit = {
        tenantDashboard: 10
      };
    }
    if (this.$storage.selectedDurationRange) {
      selectedDurationRange = {
        startDate: this.$storage.selectedDurationRange.startDate,
        endDate: this.$storage.selectedDurationRange.endDate
      };
    } else {
      this.$storage.selectedDurationRange = {
        startDate: selectedDurationRange.startDate,
        endDate: selectedDurationRange.endDate
      };
    }

    if (this.$storage.tenantDashboard) {
      if (this.$storage.tenantDashboard.selectedAccounts) {
        selectedAccounts = this.$storage.tenantDashboard.selectedAccounts;
      }
      if (this.$storage.tenantDashboard.selectedCompanies) {
        selectedCompanies = this.$storage.tenantDashboard.selectedCompanies;
      }
    }

    this.pullFromLocalStorage();

    const response = await this.tenantDashboardService.getCampaigns({
      organization_ids: selectedCompanies,
      account_ids: selectedAccounts,
      page: 1,
      limit: limit,
      min_date: selectedDurationRange.startDate,
      max_date: selectedDurationRange.endDate
    });
    if (response.status !== 200) {
      throw new Error('Failed to load tenant dashboard data');
    }

    return response.data;
  }
}

tenantDashboardController.$inject = [
  '$scope',
  '$state',
  '$timeout',
  '$filter',
  'tenantDashboardService',
  'companyAndAccountFactory',
  'modalFactory',
  '$localStorage',
  'FileSaver',
  'debounce2',
  'featureFlagFactory'
];
