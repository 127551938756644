enum GAEventList {
  AUDIENCE_RECOMMENDATIONS_LOAD = 'audience-recommendations-load',
  ACCOUNT_CREATE = 'account-create',
  CAMPAIGN_BUDGET_SAVE = 'campaign-budget-save',
  CAMPAIGN_CREATIVES_SAVE = 'campaign-creatives-save',
  CAMPAIGN_DEVICE_TYPE = 'campaign-device-type',
  CAMPAIGN_SAVE_AND_LAUNCH = 'campaign-save-and-launch',
  CAMPAIGN_START = 'campaign-start',
  CAMPAIGN_TARGET_GOAL = 'campaign-target-goal',
  CAMPAIGN_TARGET_AUDIENCE = 'campaign-target-audience',
  CAMPAIGN_TARGET_COMPLETE = 'campaign-target-complete',
  CAMPAIGN_TARGET_DRIVE_TO = 'campaign-target-drive-to',
  CAMPAIGN_TARGET_LOCATION = 'campaign-target-location',
  ORGANIZATION_CREATE = 'organization-create',
  PAYMENT_METHOD_ADDED = 'payment-method-added',
  RECOMMENDED_AUDIENCE_ALL_SELECTED = 'recommended-audience-all-selected',
  RECOMMENDED_AUDIENCE_SINGLE_SELECTED = 'recommended-audience-single-selected',
  SIGNUP_START = 'signup-start',
  SIGNUP_COMPLETE = 'signup-complete',
  TENANT_AND_ORGANIZATION_CREATE = 'tenant-and-organization-create',
  TENANT_USER_INVITE = 'tenant-user-invite'
}

export default GAEventList;
