export class campaignCreationWizardModalController {
  constructor($uibModalInstance, $sce, data) {
    this.$uibModalInstance = $uibModalInstance;
    this.$sce = $sce;
    this.baseVideoUrl = `https://player.vimeo.com/video/429742744`;
    this.timeToStartAt = this.getTimeToStart(data);
    this.videoUrl = this.$sce.trustAsResourceUrl(this.getVideoUrl(this.baseVideoUrl, this.timeToStartAt));
  }

  getVideoUrl(baseVideoUrl, timeToStartAt) {
    return `${baseVideoUrl}?#t=${timeToStartAt}`;
  }

  getTimeToStart({ urlHash, state }) {
    const timerForSection = {
      '#goals': '1m39s',
      '#deviceType': '1m57s',
      '#targeting': '2m5s',
      '#creatives': '2m37s',
      '#budget': '3m8s'
    };

    const timerForOrgStates = {
      organization: '55s',
      company: '55s',
      account: '55s',
      'campaigns.accountDashboard': '1m7s'
    };

    if (!urlHash) {
      return timerForOrgStates[state] ?? 0;
    }
    return timerForSection[urlHash] ?? 0;
  }

  close() {
    this.$uibModalInstance.close();
  }
}

campaignCreationWizardModalController.$inject = ['$uibModalInstance', '$sce', 'data'];
