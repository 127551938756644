trackingPixelAccountModalController.$inject = [
  '$scope',
  '$uibModalInstance',
  'companyAndAccountFactory',
  'featureFlagFactory',
  'data'
];

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 */
export function trackingPixelAccountModalController(
  $scope,
  $uibModalInstance,
  companyAndAccountFactory,
  featureFlagFactory,
  data
) {
  $scope.featureFlagFactory = featureFlagFactory;
  $scope.pixel = data;
  $scope.enableSiteRetargeting = false;

  $scope.updateEnableSiteRetargeting = function() {
    $scope.enableSiteRetargeting = !$scope.enableSiteRetargeting;
  };

  $scope.createPixel = function() {
    if (!validate()) {
      return;
    }
    $scope.error = '';
    if (!$scope.pixelName) {
      return;
    }
    $scope.checkingURL = true;

    companyAndAccountFactory
      .newConversionTrackingPixel($scope.pixelName, $scope.websiteURL, $scope.enableSiteRetargeting)
      .then(
        function(newPixel) {
          $uibModalInstance.close(newPixel);
        },
        function(error) {
          $scope.error = error;
        }
      )
      .finally(function() {
        $scope.checkingURL = false;
      });
  };

  $scope.closePixelModal = function() {
    $uibModalInstance.close();
  };

  function validate() {
    return (
      ($scope.pixelName || '').trim() &&
      ($scope.websiteURL || '').trim() &&
      ($scope.websiteURL || '').length <= 256 &&
      ($scope.pixelName || '').length <= 128
    );
  }
}
