import { http } from '../def';
import { Response } from '../types/companiesAndAccounts';

interface GetParams {
  tenant_id?: number;
  company_id?: number;
  account_id?: number;
}

export const getApi = http.get`/data/companiesAndAccounts`.withParams<GetParams>().returnsJsonOf(Response);
