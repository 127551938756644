import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const CalendarIcon = createSvgIcon(
  <path
    d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V8H20V21Z"
    fill="#0A3F36"
  />,
  'GtCalendarIcon'
);

export default CalendarIcon;
