import { creativeRepositoryService } from '../creative_repository.serv';
import { getFileExtensionFromString } from '../../../factories/general_utils';
import { creativeRepositoryState } from '../creative_repository_state';

enum ModalState {
  UPLOAD = 'upload',
  UPLOADING = 'uploading',
  RESULT = 'result',
  UPLOADED = 'uploaded'
}

interface UploadedFile {
  name: string;
  url: string;
  file_type: string | null;
}

interface InsertionStatus {
  success: boolean;
  name?: string;
  error_message?: string | null;
}

export class creativeAssetUploadController {
  scope: ng.IRootScopeService;
  uibModalInstance: ng.ui.bootstrap.IModalServiceInstance;
  uploadedFiles: UploadedFile[];
  nameOfFilesToUpload: string[];
  uploading: boolean;
  modalOptions: any;
  currentUploadingFile: string;
  insertionStatus: InsertionStatus[];
  creativeRepositoryService: creativeRepositoryService;
  creativeRepositoryState: creativeRepositoryState;
  ModalState: typeof ModalState;
  currentModalState: ModalState;
  loading: boolean;
  errorMessage: string;
  showWarning: boolean;
  warningMessage: string;

  constructor($scope, data, modalOptions, creativeRepositoryService, creativeRepositoryState, $uibModalInstance) {
    this.scope = $scope;
    this.uibModalInstance = $uibModalInstance;
    this.uploadedFiles = [];
    this.nameOfFilesToUpload = [];
    this.currentUploadingFile = '';
    this.creativeRepositoryService = creativeRepositoryService;
    this.creativeRepositoryState = creativeRepositoryState;
    this.ModalState = ModalState;
    this.currentModalState = ModalState.UPLOAD;
    this.modalOptions = modalOptions;
    this.insertionStatus = [];

    this.loading = false;
    this.uploading = false;
    this.errorMessage = '';
    this.showWarning = false;
    this.warningMessage = '';
    this.closeWarning = this.closeWarning.bind(this);
  }

  fileUploadedSuccessfully(file, event, message) {
    let s3Url = window.mediaCdnUrl + JSON.parse(message).s3_path;
    let fileExtension = getFileExtensionFromString(event.name) || '';
    fileExtension = `.${fileExtension.toUpperCase()}`;
    this.uploadedFiles.push({
      name: event.name,
      url: s3Url,
      file_type: fileExtension
    });
    this.nameOfFilesToUpload.shift();
    if (!this.nameOfFilesToUpload.length) {
      this.currentModalState = ModalState.UPLOADED;
    } else {
      this.currentUploadingFile = this.nameOfFilesToUpload[0];
    }

    return true;
  }

  removeImageFromUploadList(index) {
    if (this.uploadedFiles.length === 1) {
      // TODO: Currently unable to get the ng-flow to work after you have already used it
      this.close();
    }
    this.uploadedFiles.splice(index, 1);
  }

  filesAdded($files, $event, $flow) {
    let filesToUpload = [...$files];
    if ($files.length > 10) {
      filesToUpload = filesToUpload.slice(0, 10);
    }
    if ($files.length) {
      this.nameOfFilesToUpload = filesToUpload.map(file => file.name);
      this.currentUploadingFile = this.nameOfFilesToUpload[0];
      this.currentModalState = ModalState.UPLOADING;
    }

    return true;
  }

  uploadFiles($files, $event, $flow) {
    if ($files.length > 10) {
      this.showWarning = true;
      this.warningMessage = 'Only the first 10 files will be uploaded.';

      $files.forEach((file, index) => {
        if (index > 9) {
          $flow.removeFile(file);
        }
      });
    }
    $flow.upload();
  }

  async submit() {
    this.loading = true;
    try {
      const result = await this.creativeRepositoryService.insertIntoCreativeRepository(this.uploadedFiles);
      this.insertionStatus = result;
      this.creativeRepositoryState.refreshCreativeAssetsList();

      this.currentModalState = ModalState.RESULT;
    } catch (e) {
      console.error(e);
      // show error
    } finally {
      this.loading = false;
    }
    this.scope.$apply();
  }

  close() {
    this.uibModalInstance.close();
  }

  closeWarning() {
    this.showWarning = false;
  }
}

creativeAssetUploadController.$inject = [
  '$scope',
  'data',
  'modalOptions',
  'creativeRepositoryService',
  'creativeRepositoryState',
  '$uibModalInstance'
];
