import React from 'react';

import { AudienceListItem } from './AudienceListItem';
import { Audience } from '../../../../api/http/data.generated';
import { send$http } from '../../../../api/def/ngExecutor';
import { $http } from '../../../../utils/services';
import { getLocationGroupStatusApi, productFlag } from '../../../../api/http/audience';
import Divider from '@material-ui/core/Divider';

interface AudienceListProps {
  selectedAudiences: Audience[];
  includeItemDescription: boolean;
  isExcluded: boolean;
  onRemoveItem: (item: Audience) => void;
  onClearSelections: () => void;
}

interface StatusMap {
  [id: number]: string;
}

export function AudienceList({
  selectedAudiences,
  includeItemDescription,
  isExcluded,
  onRemoveItem,
  onClearSelections
}: AudienceListProps) {
  // map to store the status of each location group
  const [locationGroupStatusMap, setLocationGroupStatusMap] = React.useState<StatusMap>({});
  const getLocationGroupStatus = async (item: Audience) => {
    try {
      const response = await send$http($http, getLocationGroupStatusApi, {
        params: { location_group_id: item?.id as number, product_flag: productFlag.AUDIENCE }
      });
      return { id: item.id, status: response.status };
    } catch (error) {
      // if the api fails, we assume the location group is ready and don't show any error icon
      return { id: item.id, status: 'ready' };
    }
  };

  React.useEffect(() => {
    Promise.all(
      selectedAudiences
        .filter((item: Audience) => item?.type === 'location_group' && !locationGroupStatusMap[item?.id as number])
        .map(getLocationGroupStatus)
    ).then(updatedStatuses => {
      const newStatusMap: StatusMap = { ...locationGroupStatusMap };
      updatedStatuses.forEach(({ id, status }) => {
        newStatusMap[id as number] = status as string;
      });
      setLocationGroupStatusMap(newStatusMap);
    });
  }, [selectedAudiences]);

  return (
    <div className="audience-list">
      {selectedAudiences.length > 0 ? (
        <div data-testid="audience-list__found">
          <div className="audience-list__clear">
            <div className="audience-list__header"> {isExcluded ? 'EXCLUDED AUDIENCES' : 'AUDIENCES'}</div>
            <button className="audience-list__clear-button" onClick={onClearSelections}>
              Clear All Selections
            </button>
          </div>
          <ul className="audience-list__items">
            {selectedAudiences.map((item, index) => (
              <>
                {/* // just pass ready as status for other audience types which are not location groups or if the status is not available to not show any error icon */}
                <AudienceListItem
                  isExcluded={isExcluded}
                  key={index}
                  includeItemDescription={includeItemDescription}
                  item={item}
                  status={
                    item?.type === 'location_group' ? locationGroupStatusMap[item?.id as number] ?? 'ready' : 'ready'
                  }
                  onRemoveItem={onRemoveItem}
                />
                <Divider />
              </>
            ))}
          </ul>
        </div>
      ) : (
        <div className="audience-list__empty">
          <p className="audience-list__empty-title">No Audiences added yet</p>
          <p className="audience-list__empty-description">Use search to add audiences</p>
        </div>
      )}
    </div>
  );
}
