import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';

import { RecommendationItem } from './RecommendationItem';
import { Audience } from '../../../../api/http/data.generated';
import { PrimaryButton } from '../../../../components/reactComponents';
import { CloseIconWhite, CustomizationToggleIcon } from '../../../../components/reactComponents/Icons';

interface AudienceRecommendationPopupProps {
  isLoading: boolean;
  recommendations: Audience[];
  onPopupClose: () => void;
  onAddAllRecommendations: () => void;
  onAddAudience: (item: Audience) => void;
}

export function AudienceRecommendationPopup({
  isLoading,
  recommendations,
  onPopupClose,
  onAddAllRecommendations,
  onAddAudience
}: AudienceRecommendationPopupProps) {
  let mapping;

  const getRecommendations = () => {
    if (isLoading) {
      return (
        <div>
          {Array.from({ length: 5 }).map((_, i) => (
            <Skeleton key={i} animation="wave" className="audience-recommendation-popup__skeleton" />
          ))}
        </div>
      );
    } else {
      return recommendations.map((recommendation, i) => (
        <RecommendationItem key={i} item={recommendation} onAddAudience={onAddAudience} />
      ));
    }
  };

  if (!recommendations.length && !isLoading) {
    mapping = {
      title: 'Audience Recommendation',
      description: 'We don’t have any recommendations yet. Try searching for an audience to get started.',
      content: (
        <div className="audience-recommendation-popup__icon-no-recommendations">
          <CustomizationToggleIcon />
        </div>
      ),
      actionButton: (
        <PrimaryButton
          title="Okay"
          onClick={onPopupClose}
          className="audience-recommendation-popup__action-button button-v2 button--green"
        />
      )
    };
  } else {
    mapping = {
      title: 'Audience Recommendation',
      description: 'Increase your reach by layering in additional relevant audiences often selected together:',
      content: getRecommendations(),
      actionButton: (
        <PrimaryButton
          title={`Add ${recommendations.length} Audience${recommendations.length > 1 ? 's' : ''}`}
          onClick={onAddAllRecommendations}
          className="audience-recommendation-popup__action-button button-v2 button--green"
          disabled={isLoading}
        />
      )
    };
  }

  return (
    <div className="audience-recommendation-popup">
      <div className="audience-recommendation-popup__section">
        <div className="audience-recommendation-popup__header">
          <div className="audience-recommendation-popup__header-title">{mapping.title}</div>
          <div role="button" className="audience-recommendation-popup__header-close" onClick={onPopupClose}>
            <CloseIconWhite />
          </div>
        </div>
        <div className="audience-recommendation-popup__description">{mapping.description}</div>
      </div>
      <div
        className={
          !recommendations.length
            ? 'audience-recommendation-popup__content-icon'
            : 'audience-recommendation-popup__content'
        }
      >
        {mapping.content}
      </div>
      <div className="audience-recommendation-popup__action">{mapping.actionButton}</div>
    </div>
  );
}

AudienceRecommendationPopup.propTypes = {
  isLoading: PropTypes.bool,
  recommendations: PropTypes.array,
  onPopupClose: PropTypes.func,
  onAddAllRecommendations: PropTypes.func,
  onAddAudience: PropTypes.func
};
