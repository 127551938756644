import React from 'react';
import PropTypes from 'prop-types';
import { Column } from './PaymentsTable';

interface HeaderCellProps {
  column: Column;
}

export function HeaderCell(props: HeaderCellProps) {
  const { column } = props;
  return !column.checkFeature || !!column.checkFeature() ? <th>{column.title}</th> : null;
}

HeaderCell.propTypes = {
  column: PropTypes.object
};
