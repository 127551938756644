import template from './adgroup_targeting_neighborhood.html';
import { AdGroupTargeting } from '../../../../api/types/adgroup';
import { AdgroupTargetingService } from '../../adgroup_targeting.serv';
import { AbstractAdgroupTargetingController } from '../adgroup_targeting_base/abstract_adgroup_targeting';
import {
  CampaignManagerService,
  FeatureFlagService,
  CompanyAndAccountFactoryService,
  SearchService
} from '../../../../factories/types';
import { AdGroupTargeting_neighborhood_typesItem } from '../../../../api/http/data.generated';
import { safeApply } from '../../../../factories/general_utils';

export function adgroupTargetingNeighborhood(): ng.IDirective {
  return {
    template: template,
    bindToController: true,
    controller: AdgroupTargetingNeighborhoodController,
    controllerAs: 'vm',
    restrict: 'E',
    link: () => {},
    scope: {
      adgroupTargeting: '=',
      checkSensitivity: '=',
      openUploadModal: '=',
      getLocationGroupsStatus: '=',
      selectionTargetingTableChanged: '='
    }
  };
}

export class AdgroupTargetingNeighborhoodController extends AbstractAdgroupTargetingController implements ng.IOnInit {
  adgroupTargeting: AdGroupTargeting = {};
  locationManagerURL = '';
  neighborhoodTypes = [
    {
      name: 'Business Neighborhood',
      value: AdGroupTargeting_neighborhood_typesItem.BUSINESS,
      selected: true
    },
    {
      name: 'Residential Neighborhood',
      value: AdGroupTargeting_neighborhood_typesItem.RESIDENTIAL,
      selected: true
    }
  ];
  featureFlagFactory: FeatureFlagService;

  /** @ngInject */
  constructor(
    $scope: ng.IScope,
    featureFlagFactory: FeatureFlagService,
    companyAndAccountFactory: CompanyAndAccountFactoryService,
    adgroupTargetingService: AdgroupTargetingService,
    searchFactory: SearchService,
    campaignManagerFactory: CampaignManagerService
  ) {
    super($scope, companyAndAccountFactory, adgroupTargetingService, searchFactory, campaignManagerFactory);
    this.featureFlagFactory = featureFlagFactory;
  }

  getAdgroupTargeting() {
    return this.adgroupTargeting;
  }

  $onInit() {
    this.showAudienceExclude = !!this.adgroupTargeting.audience_exclude?.length;
    this.locationManagerURL = `//${window.locationManagerURL}`;

    this.locationFilterSearchPlaceholder = this.adgroupTargetingService.getLocationFilterSearchPlaceholder(
      this.adgroupTargeting
    );

    const neighborhoodTypes = this.adgroupTargeting.neighborhood_types;
    if (neighborhoodTypes?.length) {
      this.neighborhoodTypes.forEach(type => {
        type.selected = neighborhoodTypes.includes(type.value);
      });
    } else {
      // Legacy adgroups will not have any neighborhood types selected in targeting - mark as if all are selected.
      this.neighborhoodTypes.forEach(type => {
        type.selected = true;
      });
    }
  }

  /**
   * Updates adgroupTargeting.neighborhoodTypes based on selections
   */
  updateNeighborhoodTypes() {
    this.adgroupTargeting.neighborhood_types = [];
    for (const type of this.neighborhoodTypes) {
      if (type.selected) {
        this.adgroupTargeting.neighborhood_types.push(type.value);
      }
    }
  }

  handleExcludeAudienceChange = () => {
    if (!this.adgroupTargeting.showAudienceExclude) {
      this.adgroupTargeting.audience_exclude = [];
    }
    safeApply(this.scope);
  };

  updateExcludeAudiences = excluded_audiences => {
    this.adgroupTargeting.audience_exclude = excluded_audiences;
    safeApply(this.scope);
  };
}
