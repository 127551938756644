import React, { useState, useRef } from 'react';
import { Popover, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CreditCard } from './BillingPageView';
import { Customer } from '../../../api/types/customer';
import { CircularIndicator } from '../../../components/reactComponents/LoadingIndicator/CircularIndicator';

interface ActionCellProps {
  card: CreditCard;
  customer: Customer;
  deleteCard: (card: CreditCard) => void;
  isLoading: boolean;
  isAccountLevelCreditCardsEnabled: boolean;
}

export function ActionCell(props: ActionCellProps) {
  const { card, customer, deleteCard, isLoading, isAccountLevelCreditCardsEnabled } = props;
  const [openedPopover, setOpenedPopover] = useState(false);

  const popoverAnchor = useRef(null);
  const popoverContainer = useRef(null);

  const classes = makeStyles(styles => ({
    popover: {
      pointerEvents: 'none'
    }
  }))();

  const handlePopoverOpen = () => {
    setOpenedPopover(true);
  };

  const handlePopoverClose = () => {
    setOpenedPopover(false);
  };

  const canDelete = () => {
    const isPrimaryCard = card.card_id === customer.default_card_id;

    if (isPrimaryCard && !isAccountLevelCreditCardsEnabled) {
      return card.allowDelete && !card.deleteProgress;
    }
    return !isPrimaryCard && !card.deleteProgress;
  };

  const handleDelete = () => {
    if (isLoading) {
      return;
    }

    deleteCard(card);
  };

  return (
    <td className="action" ref={popoverContainer}>
      {card.deleteProgress && <CircularIndicator color="inherit" size={24} className="action-cell--loading" />}
      {canDelete() && (
        <>
          <span
            className={`glyphicon glyphicon-trash icon--margin--right action--delete ${!isLoading && 'enabled'}`}
            onClick={handleDelete}
            ref={popoverAnchor}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            data-testid="delete-icon"
          />
          {isAccountLevelCreditCardsEnabled && (
            <Popover
              className={classes.popover}
              open={openedPopover}
              transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              anchorEl={popoverAnchor.current}
              container={popoverContainer.current}
            >
              <div> The accounts will be moved to the Primary Card </div>
            </Popover>
          )}
        </>
      )}
      {card.card_id === customer.default_card_id && !canDelete() && !isLoading && (
        <>
          <span
            ref={popoverAnchor}
            className="glyphicon glyphicon-info-sign"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            data-testid="info-icon"
          ></span>
          <Popover
            className={classes.popover}
            open={openedPopover}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            anchorEl={popoverAnchor.current}
            container={popoverContainer.current}
          >
            <div>To delete this card please choose the next primary card first</div>
          </Popover>
        </>
      )}
    </td>
  );
}

ActionCell.propTypes = {
  card: PropTypes.object,
  customer: PropTypes.object,
  deleteCard: PropTypes.func,
  isLoading: PropTypes.bool,
  isAccountLevelCreditCardsEnabled: PropTypes.bool
};
