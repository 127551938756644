import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '../../../components/reactComponents/Text/TextField';

interface PaymentFormTextFieldProps {
  label?: string;
  required?: boolean;
  name: string;
  id?: string;
  placeholder?: string;
  onChange: (info) => void;
  className?: string;
  error?: string;
}

export function PaymentFormTextField(props: PaymentFormTextFieldProps) {
  const { placeholder, required, name, id, onChange, className = '', error, label } = props;
  const [showError, setShowError] = useState(false);
  const [customError, setCustomError] = useState(error);
  const fieldRef = useRef(null);

  const checkErrors = value => {
    setShowError(value === '');
  };

  const handleOnChange = value => {
    if (value) {
      fieldRef.current.classList.add('has-content');
    } else {
      fieldRef.current.classList.remove('has-content');
    }
    const trimmedValue = value.trim();
    checkErrors(trimmedValue);
    onChange({
      [name]: trimmedValue
    });
  };

  useEffect(() => {
    setCustomError(error);
    setShowError(!!error);
  }, [error]);

  return (
    <div className={`field ${showError ? 'has-errors' : ''} ${className}`} ref={fieldRef}>
      <TextField
        placeholder={placeholder}
        onBlur={event => checkErrors(event.target.value)}
        onChange={handleOnChange}
        required={required}
        name={name}
        id={id}
        className="payment-info-field"
        label={label}
      />
      {showError && (
        <div className="signin-errors">
          <div className="ng-message">{customError || 'This is required'}</div>
        </div>
      )}
    </div>
  );
}
