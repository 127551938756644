UserEditController.$inject = [
  '$scope',
  '$state',
  '$stateParams',
  'userFactory',
  '$timeout',
  '$q',
  'generalUtilsFactory',
  'companyAndAccountFactory',
  'modalFactory',
  'featureFlagFactory'
];

/**
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {import('@uirouter/angularjs').StateParams} $stateParams
 * @param {import('../../../factories/types').UserFactoryService} userFactory
 * @param {ng.ITimeoutService} $timeout
 * @param {ng.IQService} $q
 * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 */
export function UserEditController(
  $scope,
  $state,
  $stateParams,
  userFactory,
  $timeout,
  $q,
  generalUtilsFactory,
  companyAndAccountFactory,
  modalFactory,
  featureFlagFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;
  $scope.userFactory = userFactory;

  $scope.booleans = {
    passwordByEmail: true,
    canManageUser: false,
    tooltipVisible: false,
    usersLoading: false,
    noUserFound: false,
    saveProgressForSendActivationEmail: false,
    saveProgressForSendForgetPasswordEmail: false,
    creatingUser: false,
    acceptingUserRequest: false,
    rejectingUserRequest: false,
    showDeleteButton: true,
    isCurrentUser: false,
    showUserEmailButtons: false,
    showAdsManagerAccessCheckbox: false
  };

  $scope.groups = [];

  $scope.search = {
    user: ''
  };

  angular.copy($scope.userFactory.nullUser, $scope.selectedUser);

  $scope.init = function() {
    var userId = $stateParams.userId;
    $scope.isProfile = !!$stateParams.isProfile;
    if (userId) {
      $scope.booleans.creatingUser = false;
      if (userId === userFactory.currentLoggedInUser.id) {
        $scope.booleans.isCurrentUser = true;
      }
    } else {
      $scope.booleans.creatingUser = true;
    }

    var deferreds = [];
    var getGroupDfd = userFactory.getGroups();
    deferreds.push(getGroupDfd);

    if (userId) {
      deferreds.push(userFactory.getUsers());
    }

    deferreds.push(userFactory.getCurrentLoggedInUser());

    $scope.$emit('in progress');
    $q.all(deferreds)
      .then(function(responses) {
        angular.copy(responses[0], $scope.groups);
        const user = (userId && userFactory.getSelectedUser(userId)) || null;

        if (!!userId && !user) {
          $scope.booleans.noUserFound = true;
        } else {
          $scope.booleans.noUserFound = false;
        }

        var tenantType = companyAndAccountFactory.requireSelectedTenant().type;
        if (user && !$scope.booleans.creatingUser) {
          tenantType = user.tenant_type;
        }

        $scope.groups = $scope.groups.filter(function(group) {
          return group.tenant_type === tenantType;
        });

        if (user) {
          generalUtilsFactory.nestedUpdateInPlace(user, $scope.selectedUser);

          $scope.booleans.showUserEmailButtons =
            (userFactory.currentLoggedInUser.tenant_type !== 1 || userFactory.currentLoggedInUser.is_sudoer) &&
            !!$scope.selectedUser.id;
          $scope.booleans.showAdsManagerAccessCheckbox =
            featureFlagFactory.isFeatureEnabled('USER_ROLE_MANAGEMENT') &&
            userFactory.currentLoggedInUser.tenant_type !== 1 &&
            $scope.selectedUser.id;

          $scope.groups.forEach(function(group) {
            if ($scope.selectedUser.group_list.indexOf(group.group_id) > -1) {
              group.checked = true;
              if (
                (group.group_name === 'Admin' || group.group_name === 'Self Service Admin') &&
                $scope.selectedUser.id == userFactory.currentLoggedInUser.id
              ) {
                group.disabled = true;
                $scope.booleans.showDeleteButton = false;
              } else {
                group.disabled = false;
              }
            } else {
              group.checked = false;
            }
          });
        } else {
          // If user does not exist that means this is a new user
        }
      })
      .finally(function() {
        $scope.$emit('end progress');
      });
  };

  $scope.acceptRequest = function() {
    $scope.errorMsg = '';
    $scope.booleans.acceptingUserRequest = true;
    userFactory
      .handleUserRequest($scope.selectedUser, true)
      .then(
        function(response) {
          $scope.$emit('success-toast', { message: 'Accepted user.' });
          $state.go('users.list');
        },
        function(response) {
          $scope.errorMsg = response;
        }
      )
      .finally(function() {
        $scope.booleans.acceptingUserRequest = false;
      });
  };

  $scope.rejectRequest = function() {
    $scope.errorMsg = '';
    $scope.booleans.rejectingUserRequest = true;
    userFactory
      .handleUserRequest($scope.selectedUser, false)
      .then(
        function(response) {
          $scope.$emit('success-toast', { message: 'Rejected user.' });
          $state.go('users.list');
        },
        function(response) {
          $scope.errorMsg = response;
        }
      )
      .finally(function() {
        $scope.booleans.rejectingUserRequest = false;
      });
  };

  $scope.saveUser = function() {
    if (!validateUser()) {
      return;
    }

    $scope.errorMsg = '';

    $scope.selectedUser.group_list = [];
    for (var i = 0; i < $scope.groups.length; i++) {
      if ($scope.groups[i].checked) {
        $scope.selectedUser.group_list.push($scope.groups[i].group_id);
      }
    }

    if ($scope.selectedUser.id) {
      $scope.saveProgressForSaveUser = true;

      if ($scope.selectedUser.id !== userFactory.currentLoggedInUser.id) {
        // Strip out the password
        // If you try to update the password of others, backend will give an error
        delete $scope.selectedUser.password;
      }

      userFactory
        .updateUser($scope.selectedUser)
        .then(
          function(response) {
            $scope.$emit('success-toast', { message: 'Updated user successfully.' });
            $state.go('users.list');
          },
          function(response) {
            $scope.errorMsg = response;
          }
        )
        .finally(function() {
          $scope.saveProgressForSaveUser = false;
        });
    } else {
      if ($scope.booleans.passwordByEmail) {
        delete $scope.selectedUser.password;
      }

      $scope.saveProgressForSaveUser = true;
      userFactory
        .createUser($scope.selectedUser)
        .then(
          function(response) {
            $scope.$emit('success-toast', { message: 'Created user successfully.' });
            // when password is set by mail, the backend sends a mail anyway.
            if (!$scope.booleans.passwordByEmail) {
              $scope.sendActivationEmail(response);
            }
            $state.go('users.list');
          },
          function(response) {
            $scope.errorMsg = response;
          }
        )
        .finally(function() {
          $scope.saveProgressForSaveUser = false;
        });
    }
  };

  $scope.deleteUser = function() {
    var modalSetting = {
      title: 'Delete User?',
      message: 'Are you sure you want to delete this User?',
      noText: 'Cancel',
      yesText: 'Delete',
      yesClass: 'skin-red'
    };

    var handlers = {
      confirm: function() {
        if ($scope.selectedUser.id) {
          return userFactory.deleteUser($scope.selectedUser).then(
            function() {
              // If user deleted himself, redirect to logout
              if ($scope.selectedUser.id == userFactory.currentLoggedInUser.id) {
                window.location.replace('/logout');
              }

              $scope.$emit('success-toast', { message: 'Deleted user successfully.' });
              $state.go('users.list');
            },
            function(response) {
              return response.data.message;
            }
          );
        }
      }
    };

    modalFactory.simpleConfirm(modalSetting, handlers);
  };

  $scope.deleteUserMethod = function() {
    $scope.errorMsg = '';
    if ($scope.selectedUser.id) {
      $scope.deleteProgress = true;
      userFactory
        .deleteUser($scope.selectedUser)
        .then(
          function(response) {
            // If user deleted himself, redirect to logout
            if ($scope.selectedUser.id == userFactory.currentLoggedInUser.id) {
              window.location.replace('/logout');
            }

            $scope.$emit('success-toast', { message: 'Deleted user successfully.' });
            $state.go('users.list');
          },
          function(response) {
            $scope.errorMsg = response.data.message;
          }
        )
        .finally(function() {
          $scope.deleteProgress = false;
        });
    }
  };

  $scope.sendActivationEmail = function(target) {
    var emailTargetUser = null;
    if (target == undefined || target == null) {
      emailTargetUser = $scope.selectedUser;
    } else {
      emailTargetUser = target;
    }
    $scope.errorMsg = '';
    if ($scope.selectedUser.id || target != undefined) {
      $scope.saveProgressForSendActivationEmail = true;
      userFactory
        .sendActivationEmail(emailTargetUser)
        .then(
          function(response) {
            $scope.$emit('success-toast', { message: 'Successfully sent activation email.' });
          },
          function(response) {
            $scope.errorMsg = response;
          }
        )
        .finally(function() {
          $scope.saveProgressForSendActivationEmail = false;
        });
    }
  };

  $scope.sendForgetPasswordEmail = function() {
    $scope.errorMsg = '';
    if ($scope.selectedUser.id) {
      $scope.saveProgressForSendForgetPasswordEmail = true;
      userFactory
        .sendForgetPasswordEmail($scope.selectedUser)
        .then(
          function(response) {
            $scope.$emit('success-toast', { message: 'Successfully sent forgot password email.' });
          },
          function(response) {
            $scope.errorMsg = response;
          }
        )
        .finally(function() {
          $scope.saveProgressForSendForgetPasswordEmail = false;
        });
    }
  };

  function validateUser() {
    var isValid = true;
    if ($scope.userForm.$invalid) {
      for (var errorField in $scope.userForm.$error) {
        if ($scope.userForm.$error.hasOwnProperty(errorField) && $scope.userForm.$error[errorField].length > 0) {
          $scope.userForm.$error[errorField].forEach(function(value) {
            value.$setTouched();
            isValid = isValid && false;
          });
        }
      }
    }
    return isValid;
  }

  $scope.fillFirstNameLastNameForXadEmail = function() {
    var xadDomain = '@xad.com';
    if (
      $scope.selectedUser.username !== undefined &&
      $scope.selectedUser.username?.indexOf(xadDomain, $scope.selectedUser.username.length - xadDomain.length) != -1
    ) {
      var username = $scope.selectedUser.username?.split('@')[0];
      var names = username?.indexOf('.') > -1 ? username.split('.') : null;
      if (!!names && names.length > 0) {
        $scope.selectedUser.firstName = names[0].charAt(0).toUpperCase() + names[0].slice(1);
        $scope.selectedUser.lastName = names[1].charAt(0).toUpperCase() + names[1].slice(1);
      }
    }
  };

  $timeout($scope.init);
}
