import { xadBarChart } from './xadBarChart/xad-bar-chart';
import { xadColumnChart } from './xadColumnChart/xad-column-chart';
import { xadFastChart } from './xadFastChart/xadFastChartDirective';
import { xadLineChart } from './xadLineChartMulti/xad-line-chart-multi';
import { xadSingleLineChart } from './xadLineChartSingle/xad-line-chart-single';
import { xadNegativeStackBarChart } from './xadNegativeStackBarChart/xad-negative-stack-bar-chart';
import { xadVisitChart } from './xadVisitChart/xad-visit-chart';
import { xadImpressionChart } from './xadImpressionChart/xad-impression-chart';

export const directives = {
  xadBarChart,
  xadColumnChart,
  xadFastChart,
  xadLineChart,
  xadSingleLineChart,
  xadNegativeStackBarChart,
  xadVisitChart,
  xadImpressionChart
};
