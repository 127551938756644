export class rootController {
  /**
   * @ngInject
   * @param {import("@uirouter/core").StateService} $state
   * @param {ng.ITimeoutService} $timeout
   * @param {import("../../factories/types").FeatureFlagService} featureFlagFactory
   */
  constructor($state, $timeout, featureFlagFactory) {
    this.$state = $state;
    this.$timeout = $timeout;
    this.featureFlagFactory = featureFlagFactory;
    this.handleRouting();
  }

  handleRouting() {
    // $timeout is used because: https://stackoverflow.com/a/36693297/7907756
    if (this.$state.current && this.$state.current.name !== 'root') {
      return;
    } else if (this.featureFlagFactory.isFeatureEnabled('TENANT_DASHBOARD')) {
      this.$timeout(() => this.$state.go('tenantDashboard'));
    } else if (this.featureFlagFactory.isFeatureEnabled('CAMPAIGN_OPS_DASHBOARD')) {
      this.$timeout(() => this.$state.go('campaigns.opsDashboard'));
    } else {
      this.$timeout(() => this.$state.go('campaigns.dashboard', this.$state.params));
    }
  }
}

rootController.$inject = ['$state', '$timeout', 'featureFlagFactory'];
