export const gtGreen = '#83bc20';
export const gtLightBlue = '#109ee5';
export const gtBlue = '#0083C6';
export const gtGray = '#63666A';
export const deleteColor = '#D53E4F';

export const primary1Palette = {
  100: '#A4E504',
  200: '#92CC04',
  300: '#6D9903',
  400: '#496602',
  500: '#243301'
};

export const primary2Palette = {
  50: 'rgba(0,131,198,.2)',
  100: '#00689E',
  200: '#004E76',
  300: '#00344F',
  400: '#001A27',
  500: '#000C13'
};

export const primary3Palette = {
  100: '#f5f5f5',
  200: '#eaecef',
  300: '#B8BABB',
  400: '#A1A3A5',
  500: '#898C8F',
  600: '#616161'
};

export const statusColor = {
  ACTIVE: '#00B200',
  PAUSED: '#FFA500',
  PENDING: '#53ABBC',
  EXPIRED: '#F00'
};
