import { Errors, Type, TypeOf } from 'io-ts';
import { either } from 'fp-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';

interface Config {
  validate: 'none' | 'throw' | 'log' | 'log.warn' | 'log.error';
}

let currentConfig: Config = {
  validate: IS_TEST ? 'throw' : IS_DEV ? 'log.error' : 'none'
};

export function getConfig(): Readonly<Config> {
  return currentConfig;
}

export function configure(config: Partial<Config>): Readonly<Config> {
  currentConfig = { ...currentConfig, ...config };
  return currentConfig;
}

function format(errors: Errors) {
  const messages = PathReporter.report(either.left(errors))
    .map(e => '\t' + e)
    .join('\n');
  return `Type validation error:${messages}`;
}

export class ValidationError extends Error {
  constructor(public errors: Errors, public data: unknown) {
    super(format(errors));
  }
}

function report(errors: Errors, data: unknown) {
  const { validate } = currentConfig;
  if (validate === 'throw') {
    throw new ValidationError(errors, data);
  } else {
    const args = [
      format(errors),
      `\nDetails:`,
      {
        errors,
        data
      }
    ];

    if (validate === 'log.warn') {
      console.warn(...args);
    } else if (validate === 'log') {
      console.log(...args);
    } else {
      console.error(...args);
    }
  }
}

export function validate<T extends Type<any>>(type: T, data: unknown): TypeOf<T> {
  if (currentConfig.validate === 'none') {
    return data;
  }

  return either.fold<Errors, TypeOf<T>, TypeOf<T>>(
    errors => {
      report(errors, data);
      return data; // pass them through
    },
    data => data
  )(type.decode(data));
}
