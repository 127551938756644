import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const SearchIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="47" height="41" viewBox="0 0 47 41" fill="none">
    <path
      d="M35.167 25.6667H33.3236L32.6703 25.0367C34.957 22.3767 36.3336 18.9233 36.3336 15.1667C36.3336 6.79 29.5436 0 21.167 0C13.187 0 6.65365 6.16 6.07031 14H10.7836C11.367 8.75 15.7536 4.66667 21.167 4.66667C26.977 4.66667 31.667 9.35667 31.667 15.1667C31.667 20.9767 26.977 25.6667 21.167 25.6667C20.7703 25.6667 20.397 25.5967 20.0003 25.55V30.2633C20.397 30.31 20.7703 30.3333 21.167 30.3333C24.9236 30.3333 28.377 28.9567 31.037 26.67L31.667 27.3233V29.1667L43.3336 40.81L46.8103 37.3333L35.167 25.6667Z"
      fill="#0A3F36"
    />
    <path
      d="M14.0969 18.2471L8.33357 24.0104L2.57024 18.2471L0.913574 19.9037L6.67691 25.6671L0.913574 31.4304L2.57024 33.0871L8.33357 27.3237L14.0969 33.0871L15.7536 31.4304L9.99024 25.6671L15.7536 19.9037L14.0969 18.2471Z"
      fill="#0A3F36"
    />
  </svg>,
  'GTSearch'
);
