import { replaceAll } from '../../utils/string';
import { NotificationTypes } from '../../api/types/common';

contextualHeader.$inject = [
  '$timeout',
  'campaignManagerFactory',
  'companyAndAccountFactory',
  'generalUtilsFactory',
  'reportFactory',
  'userFactory',
  'notificationFactory',
  'featureFlagFactory',
  '$transitions'
];

/**
 * @param {import('@uirouter/angularjs').TransitionService} $transitions
 * @param {import('../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @param {import('../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {import('@uirouter/angularjs').TransitionService} $transitions
 */
export function contextualHeader(
  $timeout,
  campaignManagerFactory,
  companyAndAccountFactory,
  generalUtilsFactory,
  reportFactory,
  userFactory,
  notificationFactory,
  featureFlagFactory,
  $transitions
) {
  return {
    restrict: 'E',
    replace: false,
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.contextual-header.html',
    scope: {
      noEdit: '=',
      noBackButton: '=',
      user: '=',
      excludeNotificationTypes: '='
    },
    controller: /** @ngInject */ function($scope, $rootScope, $state, $stateParams, $attrs) {
      function getDashboardState() {
        if (featureFlagFactory.isFeatureEnabled('CAMPAIGN_OPS_DASHBOARD')) {
          return 'campaigns.opsDashboard';
        } else if (featureFlagFactory.isFeatureEnabled('TENANT_DASHBOARD')) {
          return 'tenantDashboard';
        }
        return 'campaigns.dashboard';
      }

      function getPrevState() {
        // this figures out where the back button should direct the user
        switch ($scope.currentView) {
          case 'campaigns.campaign':
            $scope.prevState = featureFlagFactory.isFeatureEnabled('CAMPAIGN_OPS_DASHBOARD')
              ? 'campaigns.opsDashboard'
              : 'campaigns.dashboard';
            $scope.prevStateParams = {};
            break;
          case 'campaigns.campaign.settings':
            $scope.prevState = 'campaigns.campaign';
            $scope.prevStateParams = { campaignId: $stateParams.campaignId };
            break;
          case 'campaigns.adgroup':
            $scope.prevState = 'campaigns.campaign';
            $scope.prevStateParams = { campaignId: $stateParams.campaignId };
            break;
          case 'company':
            $scope.prevState = 'organization';
            $scope.prevStateParams = {};
            break;
          case 'account':
            $scope.prevState = 'company';
            $scope.prevStateParams = { selectedCompanyId: $stateParams.selectedCompanyId };
            break;
          case 'users.edit-user':
            $scope.prevState = 'users.list';
            $scope.prevStateParams = {};
            break;
          case 'users.new-user':
            $scope.prevState = 'users.list';
            $scope.prevStateParams = {};
            break;
          case 'users.list':
            $scope.prevState = getDashboardState();
            $scope.prevStateParams = {};
            break;
          case 'tenant-manager':
            $scope.prevState = getDashboardState();
            $scope.prevStateParams = {};
            break;
          case 'payment-manager':
            $scope.prevState = getDashboardState();
            $scope.prevStateParams = {
              fromPage: 'payments'
            };
            break;
          default:
            $scope.prevState = getDashboardState();
            $scope.stateOpts = {};
        }
      }

      var filters = {
        showAll: {
          value: '',
          name: 'All'
        },
        pending: {
          value: 'Pending',
          name: 'Pending'
        },
        active: {
          value: 'Active',
          name: 'Active'
        },
        paused: {
          value: 'Paused',
          name: 'Paused'
        },
        expired: {
          value: 'Expired',
          name: 'Expired'
        }
      };
      $scope.$state = $state;
      $scope.currentView = $scope.$state.current.name;

      const helpPageLink = 'https://help.groundtruth.com/hc/en-us/articles/12770162930707-Total-Visits';
      const observedVisitHelpPageLink = 'https://help.groundtruth.com/hc/en-us/articles/360000695267-Observed-Visits';

      $scope.dashboardFilters = [filters.showAll, filters.pending, filters.active, filters.paused, filters.expired];

      $scope.featureFlagFactory = featureFlagFactory;
      $scope.companyAndAccountFactory = companyAndAccountFactory;
      $scope.campaignManagerFactory = campaignManagerFactory;
      $scope.generalUtilsFactory = generalUtilsFactory;

      $scope.totalVisitTooltip = `Projected Visits is a panel-based projection metric based on our Visits data.
                                  It provides a more accurate view of your campaign performance and the overall number of visits driven.
                                  <br/><br/> <strong>Want to learn more?</strong>
                                  <br/><br/> Please visit our <a href="${helpPageLink}" target="_blank">help page</a> or
                                  reach out to your GroundTruth representative.`;

      $scope.observedVisitTooltip = `Observed Visits is the deterministic visit count to your designated points of interest within 14 days of a GroundTruth ad exposure.
                                     Please note that there is a 7-day verification process for the final Observed Visits tally.
                                     <a href="${observedVisitHelpPageLink}" target="_blank">Learn more.</a>`;

      $scope.editing = false;
      $scope.tabName = 'summary';

      $scope.goBack = function() {
        if ($scope.prevState === 'campaigns.dashboard' && companyAndAccountFactory.canShowDashBoard()) {
          $scope.campaignManagerFactory.campaignsLoading = false;
          $scope.currentView = $scope.prevState;
          $scope.campaignManagerFactory.abort($scope.selectedCampaignId);
          $state.go($scope.prevState, $scope.prevStateParams);
        } else if ($scope.prevState === 'campaigns.opsDashboard') {
          $rootScope.$broadcast('in progress');
          $state.go($scope.prevState, $scope.prevStateParams, { reload: true });
        } else if ($scope.prevState !== 'campaigns.dashboard') {
          $state.go($scope.prevState, $scope.prevStateParams);
        }
      };

      $scope.getAdgroupBudgetValue = () =>
        campaignManagerFactory.selectedAdGroup.totalBudget != null
          ? generalUtilsFactory.shorthandNumber(campaignManagerFactory.selectedAdGroup.totalBudget, 2, 2)
          : '--';

      $scope.getCampaignBudgetValue = () =>
        campaignManagerFactory.selectedCampaign.totalBudget != null
          ? generalUtilsFactory.shorthandNumber(campaignManagerFactory.selectedCampaign.totalBudget, 2, 2)
          : '--';

      $scope.getTooltipCampaignBudgetValue = () =>
        campaignManagerFactory.selectedCampaign.totalBudget != null
          ? `${generalUtilsFactory.numberToUSString(campaignManagerFactory.selectedCampaign.totalBudget, 2)} ${
              companyAndAccountFactory.selectedAccount?.currency
            }`
          : '--';

      companyAndAccountFactory.selectFromCompaniesAndAccountsForOrg(
        $stateParams.selectedCompanyId,
        $stateParams.selectedAccountId
      );
      $scope.selectedTenantId = angular.copy(companyAndAccountFactory.selectedTenant);
      if (companyAndAccountFactory.selectedCompanyForOrg !== null) {
        $scope.selectedCompany = angular.copy(companyAndAccountFactory.selectedCompanyForOrg);
      }
      if (companyAndAccountFactory.selectedAccountForOrg !== null) {
        $scope.selectedAccount = angular.copy(companyAndAccountFactory.selectedAccountForOrg);
      }

      $scope.notificationFactory = notificationFactory;
      $scope.userFactory = userFactory;

      getUpdatedDataFromCampaignManagerFactory();
      updateReportingData();

      function updateReportingData() {
        if (!$scope.campaignManagerFactory.selectedCampaign) {
          return;
        }
        // Update observed visits data till 3 weeks after end date
        // i.e. 21 days including 2 week visitation lag + approx 1 week of visit processing delay

        const campaign = $scope.campaignManagerFactory.selectedCampaign;
        const startDate = campaign.timeframe && campaign.timeframe.start ? campaign.timeframe.start : null;
        const endDate = campaign.timeframe && campaign.timeframe.end ? dateAddDays(campaign.timeframe.end, 21) : null;
        const campaignPacing = $scope.campaignManagerFactory.selectedCampaign['pacing'];
        $scope.campaignPacing =
          campaignPacing != null ? generalUtilsFactory.convertDecimalToPercent(campaignPacing, false) : '--';

        if ($scope.currentView == 'campaigns.campaign' || $scope.currentView == 'campaigns.campaign.settings') {
          reportFactory
            .getCampaignTableData($stateParams.campaignId, { start_date: startDate, end_date: endDate }, [
              $stateParams.campaignId
            ])
            .then(function(data) {
              $scope.campaign_impression = data[0].impression;
              $scope.campaign_reach = data[0].reach;
              $scope.campaign_ctr = data[0].ctr;
              $scope.total_attributed_visits = data[0].total_attributed_visits;
              $scope.total_spends = data[0].spends_spent;
              $scope.total_attributed_visits_lower = data[0].tav_lower_estimate;
              $scope.total_attributed_visits_point = data[0].tav_point_estimate;
              $scope.total_attributed_visits_upper = data[0].tav_upper_estimate;
              $scope.campaign_total_attributed_visits_shorthand = generalUtilsFactory.shorthandNumber(
                data[0].total_attributed_visits,
                0
              );
              $scope.campaign_visits = data[0].visits;
            });
        } else if ($scope.currentView == 'campaigns.adgroup') {
          reportFactory
            .getAdGroupTableData($stateParams.campaignId, { start_date: null, end_date: null }, [
              $stateParams.adGroupId
            ])
            .then(function(data) {
              $scope.total_spends = data[0].spends_spent;
              $scope.total_attributed_visits_lower = data[0].tav_lower_estimate;
              $scope.total_attributed_visits_point = data[0].tav_point_estimate;
              $scope.total_attributed_visits_upper = data[0].tav_upper_estimate;
            });
        }
      }

      function dateAddDays(/*string yyyy-mm-dd*/ dateStr, /*int*/ ndays) {
        // Add N number of days to the date string of format yyyy-mm-dd
        let fmtDateStr = replaceAll(dateStr, '-', '/');
        let dateObj = new Date(fmtDateStr);
        dateObj.setDate(dateObj.getDate() + (ndays || 1));

        return [
          dateObj.getFullYear(),
          (dateObj.getMonth() + 1 > 9 ? '' : '0') + (dateObj.getMonth() + 1), // getMonth() is zero-based
          (dateObj.getDate() > 9 ? '' : '0') + dateObj.getDate()
        ].join('-');
      }

      function getUpdatedDataFromCampaignManagerFactory() {
        if (campaignManagerFactory.selectCampaignWithId($stateParams.campaignId)) {
          $scope.selectedCampaign = angular.copy(campaignManagerFactory.selectedCampaign);

          if (campaignManagerFactory.selectAdGroupWithId($scope.selectedCampaign.id, $stateParams.adGroupId)) {
            $scope.selectedAdGroup = angular.copy(campaignManagerFactory.selectedAdGroup);
          }
        }
      }
      getPrevState();

      var adGroupWatch = undefined;
      adGroupWatcher();
      function adGroupWatcher() {
        if ($scope.currentView == 'campaigns.adgroup') {
          adGroupWatch = $scope.$watch(
            'campaignManagerFactory.selectedAdGroup',
            function() {
              $scope.selectedAdGroup = angular.copy(campaignManagerFactory.selectedAdGroup);
            },
            true
          );
        } else if (adGroupWatch !== undefined) {
          adGroupWatch();
        }
      }

      const transitionStartHandler = $transitions.onStart({}, t => {
        //aborting whenever we leave campaigns.campaign state
        const toState = t.to();
        $scope.currentView = toState.name;
        $scope.currentView && $scope.currentView === 'campaigns.campaign' && $scope.selectedCampaignId
          ? $scope.campaignManagerFactory.abort($scope.selectedCampaignId)
          : null;
        $scope.selectedCampaignId = toState.params && toState.params.campaignId;
        adGroupWatcher();
        getPrevState();
      });

      $scope.sendActivationEmail = function() {
        userFactory
          .sendActivationEmail(window.user)
          .then(
            function(response) {
              $scope.$emit('success-toast', { message: 'Successfully sent activation email.' });
            },
            function(response) {
              $scope.errorMsg = response;
            }
          )
          .catch(err => {
            console.error(err);
          });
      };

      $scope.showCampaignDashboardWithCompanyAndAccount = function(tenantId, companyId, accountId) {
        $rootScope.pageLoaded = false;
        var stateParams = {
          tenantId: tenantId,
          companyId: companyId,
          accountId: accountId
        };
        $state.go('campaigns.dashboard', stateParams);
      };

      $scope.pauseAdGroup = function(adGroup) {
        $scope.actionInProgress = true;
        campaignManagerFactory
          .changeAdGroupStatus(adGroup, $scope.selectedCampaign.id, campaignManagerFactory.statusNames.PAUSED)
          .then(
            function() {
              $scope.errorMsg = '';
            },
            function(msg) {
              $scope.errorMsg = msg;
            }
          )
          .finally(function() {
            $scope.actionInProgress = false;
          });
      };

      $scope.activateAdGroup = function(adGroup) {
        $scope.actionInProgress = true;
        campaignManagerFactory
          .changeAdGroupStatus(adGroup, $scope.selectedCampaign.id, campaignManagerFactory.statusNames.ACTIVE)
          .then(
            function() {
              $scope.errorMsg = '';
            },
            function(msg) {
              $scope.errorMsg = msg;
            }
          )
          .finally(function() {
            $scope.actionInProgress = false;
          });
      };

      $scope.goToCampaignSettings = function() {
        $state.go('campaigns.campaign.settings', { campaignId: $stateParams.campaignId });
      };

      $scope.shouldRenderNotification = function(notification) {
        const { type } = notification;
        const { notificationMap, notificationState, isNotificationTypePresent } = notificationFactory;
        const excludeNotificationTypes = $scope.excludeNotificationTypes ?? [];
        // We want to to make sure the email not activated notification is shown first.
        // Once the user closes the email not activated notification, we want to show the rest.
        if (
          type !== NotificationTypes.EMAIL_NOT_ACTIVATED &&
          isNotificationTypePresent(NotificationTypes.EMAIL_NOT_ACTIVATED) &&
          notificationState['EMAIL_NOT_ACTIVATED'].shouldShow
        ) {
          return false;
        }
        return !excludeNotificationTypes.includes(type) && notificationState[notificationMap[type]].shouldShow;
      };

      $rootScope.$on('forecastedChartData', function(event, data) {
        $scope.forecastedChartData = data;
      });

      $scope.isDangerNotification = notificationType => {
        return [
          NotificationTypes.NO_VALID_CREDIT_CARD,
          NotificationTypes.PAUSED_ACCOUNT_FOUND,
          NotificationTypes.NO_VALID_ACCOUNT_LEVEL_CREDIT_CARD,
          NotificationTypes.EMAIL_NOT_ACTIVATED
        ].includes(notificationType);
      };

      $rootScope.$on('selectedReportChanged', function(event, data) {
        $scope.selectedReport = data;
      });

      $rootScope.$on('currentReportTab', function(event, data) {
        $scope.tabName = data;
      });

      $scope.isInfoNotification = notificationType => {
        return [NotificationTypes.VIEW_ONLY, NotificationTypes.REPORTING_ONLY].includes(notificationType);
      };

      $scope.isWarningNotification = notificationType => {
        return NotificationTypes.SPEND_LIMIT_TENANT === notificationType;
      };

      $scope.isEmailNotActivatedNotification = notificationType => {
        return NotificationTypes.EMAIL_NOT_ACTIVATED === notificationType;
      };

      this.$onDestroy = () => {
        transitionStartHandler();
      };
    },
    link: function(scope, el, attrs) {
      scope.editFocus = function() {
        scope.editing = true;
        $timeout(function() {
          $(el)
            .find('input')
            .focus();
        });
      };
    }
  };
}
