import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '../../../utils/react/mui_styled';
import { gtBlue, gtLightBlue } from '../styles/colorConstants';
import makeStyles from '@material-ui/core/styles/makeStyles';

export interface LoadingZebraBarProps {
  color?: string;
  shade?: string;
  animate?: boolean;
}

const Container = styled('div').extraProps<{ color?: string; shade?: string; animate?: boolean }>(
  ({ color, shade, animate, ...others }) => others
)(({ color, shade, animate }) => ({
  borderRadius: '12px',
  height: '8px',
  width: '100%',
  border: 'none',
  fontSize: '30px',
  background: `repeating-linear-gradient(-55deg, ${shade || gtLightBlue} 0 10px, ${color || gtBlue}  10px 20px)`,
  backgroundSize: '200% 200%',
  animation: animate ? 'loading-zebra-bar-animation 5s linear infinite reverse' : ''
}));

const useStyles = makeStyles({
  '@global': {
    '@keyframes loading-zebra-bar-animation': {
      '0%': { backgroundPosition: '0% 5%' },
      '50%': { backgroundPosition: '45% 50%' },
      '100%': { backgroundPosition: '95% 100%' }
    }
  }
});

export function LoadingZebraBar(props: LoadingZebraBarProps) {
  const styles = useStyles();

  return <Container className={styles['@global']} {...props} />;
}

LoadingZebraBar.propTypes = {
  color: PropTypes.string,
  shade: PropTypes.string,
  animate: PropTypes.bool
};
