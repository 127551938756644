import { creativeRepoListView } from './creative_asset_list_view/creative_asset_list_view';
import { creativeAssetGrid } from './creative_asset_grid/creative_asset_grid';
import { creativeAssetTile } from './creative_asset_tile/creative_asset_tile';
import { creativeRepositoryController } from './creative_repository.ctrl';
import { creativeRepositoryService } from './creative_repository.serv';
import { creativeRepositoryState } from './creative_repository_state';
import { creativeAssetUploadController } from './creative_asset_upload/creative_asset_upload.ctrl';
import { creativeAssetPreviewEditModalController } from './creative_asset_preview_edit_modal/creative_asset_preview_edit_modal.ctrl';
import { addCreativeAdgroupModalController } from './add_creative_adgroup_modal/add_creative_adgroup_modal.ctrl';

export const controllers = {
  creativeRepositoryController,
  creativeAssetUploadController,
  addCreativeAdgroupModalController,
  creativeAssetPreviewEditModalController
};

export const services = {
  creativeRepositoryService,
  creativeRepositoryState
};

export const directives = {
  creativeAssetTile,
  creativeRepoListView
};

export const components = {
  creativeAssetGrid
};
