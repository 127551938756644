import { isArray, isEmpty } from 'lodash';
import { format } from '../../../../utils/string';

import template from './adgroup_targeting_weather.html';
import { AdGroupTargeting } from '../../../../api/types/adgroup';
import { AdgroupTargetingService } from '../../adgroup_targeting.serv';
import { AbstractAdgroupTargetingController } from '../adgroup_targeting_base/abstract_adgroup_targeting';
import {
  CompanyAndAccountFactoryService,
  SelfServeDataService,
  SearchService,
  FeatureFlagService,
  CampaignManagerService
} from '../../../../factories/types';

import {
  CONDITION_OPTIONS,
  WEATHER_ALERT_OPTIONS,
  TIMEFRAME_OPTIONS,
  WEATHER_CONDITION_OPTIONS
} from './adgroup_targeting_weather_constants';
import angular from 'angular';
import { WeatherAlert, WeatherCondition } from '../../../../api/http/data.generated';
import { safeApply } from '../../../../factories/general_utils';

export function adgroupTargetingWeather(): ng.IDirective {
  return {
    template: template,
    bindToController: true,
    controller: AdgroupTargetingWeatherController,
    controllerAs: 'vm',
    restrict: 'E',
    link: () => {},
    scope: {
      adgroupTargeting: '=',
      openUploadModal: '='
    }
  };
}

interface WeatherTargetingError {
  message: string;
}

interface WeatherObjectMethodResult {
  values: string[];
  operator: string;
}

interface WeatherObjectMethod {
  method?: (() => WeatherObjectMethodResult) | null;
}

interface WeatherOptionValidation {
  min: number;
  max: number;
}

interface WeatherOption {
  text: string;
  key_in_dict: string;
  addFunction: () => void;
  removeFunction: (key_in_dict?: string) => void;
  unit: string;
  validation?: WeatherOptionValidation;
}

interface WeatherTargetingOptions {
  primaryOptions: WeatherOption[];
  targetType: {
    key_in_dict: string;
    validation?: WeatherOptionValidation;
    addFunction: () => void;
  } | null;
}

export class AdgroupTargetingWeatherController extends AbstractAdgroupTargetingController implements ng.IOnInit {
  adgroupTargeting: AdGroupTargeting = {};
  selfServeDataFactory: SelfServeDataService;
  weatherDataChanged = false;
  weatherTargetingError: WeatherTargetingError = {
    message: ''
  };
  getWeatherObjectMethod: WeatherObjectMethod = {
    method: undefined
  };
  conditionOptionOrder = [
    angular.copy(CONDITION_OPTIONS.temperature),
    angular.copy(CONDITION_OPTIONS.wind),
    angular.copy(CONDITION_OPTIONS.rain),
    angular.copy(CONDITION_OPTIONS.humidity)
  ];
  weatherAlertTextFromCode = {};
  xAdWeatherConditionTextFromCode = {};
  timeframeOptionOrder = [
    angular.copy(TIMEFRAME_OPTIONS.current),
    angular.copy(TIMEFRAME_OPTIONS.today),
    angular.copy(TIMEFRAME_OPTIONS.tomorrow),
    angular.copy(TIMEFRAME_OPTIONS.threeDays)
  ];
  weatherTargetingOptions = {} as WeatherTargetingOptions;
  weatherConditionOptions = angular.copy(WEATHER_CONDITION_OPTIONS);
  weatherAlertOptions = angular.copy(WEATHER_ALERT_OPTIONS);
  newWeatherCondition = {} as any;
  adgroupTargetingForm = {} as any;
  progress = false;
  featureFlagFactory: FeatureFlagService;

  /**@ngInject */
  constructor(
    $scope: ng.IScope,
    featureFlagFactory: FeatureFlagService,
    selfServeDataFactory: SelfServeDataService,
    companyAndAccountFactory: CompanyAndAccountFactoryService,
    adgroupTargetingService: AdgroupTargetingService,
    searchFactory: SearchService,
    campaignManagerFactory: CampaignManagerService
  ) {
    super($scope, companyAndAccountFactory, adgroupTargetingService, searchFactory, campaignManagerFactory);
    this.featureFlagFactory = featureFlagFactory;
    this.selfServeDataFactory = selfServeDataFactory;
    this.weatherAlertOptions.forEach(alert => (this.weatherAlertTextFromCode[alert['value']] = alert['text']));
    this.weatherConditionOptions.forEach(
      condition => (this.xAdWeatherConditionTextFromCode[condition['value']] = condition['text'])
    );
  }

  getAdgroupTargeting() {
    return this.adgroupTargeting;
  }

  $onInit() {
    this.showAudienceExclude = !!this.adgroupTargeting.audience_exclude?.length;
    this.locationFilterSearchPlaceholder = this.adgroupTargetingService.getLocationFilterSearchPlaceholder(
      this.adgroupTargeting
    );
    this.weatherTargetingOptions = {
      primaryOptions: [
        {
          text: 'Condition',
          key_in_dict: 'weather_condition',
          addFunction: this.conditionWeatherAddFunction,
          removeFunction: this.conditionWeatherRemoveFunction,
          unit: ''
        },
        {
          text: 'Humidity',
          key_in_dict: 'weather_humidity',
          addFunction: this.genericWeatherAddFunction,
          removeFunction: this.genericWeatherRemoveFunction,
          unit: '%',
          validation: {
            min: 0,
            max: 100
          }
        },
        {
          text: 'Temperature',
          key_in_dict: 'weather_temperature',
          addFunction: this.genericWeatherAddFunction,
          removeFunction: this.genericWeatherRemoveFunction,
          unit: this.adgroupTargeting.country?.code == 'US' ? 'F' : 'C',
          validation: {
            min: this.adgroupTargeting.country?.code == 'US' ? -40 : -40,
            max: this.adgroupTargeting.country?.code == 'US' ? 134 : 57
          }
        },
        {
          text: 'Wind',
          key_in_dict: 'weather_wind_speed',
          addFunction: this.genericWeatherAddFunction,
          removeFunction: this.genericWeatherRemoveFunction,
          unit: this.adgroupTargeting.country?.code == 'US' ? 'mph' : 'meters per second',
          validation: {
            min: this.adgroupTargeting.country?.code == 'US' ? 0 : 0,
            max: this.adgroupTargeting.country?.code == 'US' ? 74 : 120
          }
        },
        {
          text: 'Rain Probability',
          key_in_dict: 'weather_rain_probability',
          addFunction: this.genericWeatherAddFunction,
          removeFunction: this.genericWeatherRemoveFunction,
          unit: '%',
          validation: {
            min: 0,
            max: 100
          }
        },
        {
          text: 'Pressure',
          key_in_dict: 'weather_pressure',
          addFunction: this.genericWeatherAddFunction,
          removeFunction: this.genericWeatherRemoveFunction,
          unit: 'mbar',
          validation: {
            min: 950,
            max: 1100
          }
        },
        {
          text: 'Alert',
          key_in_dict: 'weather_alert',
          addFunction: this.alertWeatherAddFunction,
          removeFunction: this.alertWeatherRemoveFunction,
          unit: ''
        }
      ],
      targetType: null
    };
  }

  prepareGenericSentence = (condition, obj) => {
    const temperature_sentence_us = '{0} degree fahrenheit{1}{2}.';
    const temperature_sentence_no_us = '{0} degree celsius{1}{2}.';
    const humidity_sentence = '{0} percent{1}.';
    const precipitation_sentence = '{0} percent{1}.';
    const wind_sentence_us = '{0} mile per hour{1}.';
    const wind_sentence_no_us = '{0} meters per second{1}.';
    const pressure_sentence = '{0} mbar{1}.';

    const alert_sentence = '{0}';

    const xad_weather_condition_sentence = '{0}{1}';

    switch (condition) {
      case 'weather_temperature':
        if (this.adgroupTargeting.country?.code == 'US') {
          return this.generic_weather_sentence_fn(obj, temperature_sentence_us);
        } else {
          return this.generic_weather_sentence_fn(obj, temperature_sentence_no_us);
        }
      case 'weather_humidity':
        return this.generic_weather_sentence_fn(obj, humidity_sentence);
      case 'weather_rain_probability':
        return this.generic_weather_sentence_fn(obj, precipitation_sentence);
      case 'weather_wind_speed':
        if (this.adgroupTargeting.country?.code == 'US') {
          return this.generic_weather_sentence_fn(obj, wind_sentence_us);
        } else {
          return this.generic_weather_sentence_fn(obj, wind_sentence_no_us);
        }
      case 'weather_pressure':
        return this.generic_weather_sentence_fn(obj, pressure_sentence);
      case 'weather_alert':
        return format(alert_sentence, this.weatherAlertTextFromCode[obj.phenomena]);
      case 'weather_condition':
        const condition_sentence = format(
          xad_weather_condition_sentence,
          this.xAdWeatherConditionTextFromCode[obj['condition']]
        );
        return this.generic_weather_sentence_fn(obj, condition_sentence);
      default:
        break;
    }
    return '';
  };

  getTriggerTitle = condition => {
    switch (condition) {
      case 'weather_temperature':
        return 'TEMPERATURE';
      case 'weather_humidity':
        return 'HUMIDITY';
      case 'weather_rain_probability':
        return 'RAIN PROBABILITY';
      case 'weather_wind_speed':
        return 'WIND SPEEDS';
      case 'weather_pressure':
        return 'PRESSURE';
      default:
        break;
    }
    return '';
  };

  getConditionKeysToDisplay = () => {
    const returnList: string[] = [];

    this.weatherTargetingOptions.primaryOptions.forEach(obj => {
      let condition = obj.key_in_dict;
      if (this.adgroupTargeting.weather && this.adgroupTargeting.weather[condition].length > 0) {
        returnList.push(condition);
      }
    });
    return returnList;
  };

  genericWeatherAddFunction = () => {
    let key_in_dict = this.weatherTargetingOptions.targetType?.key_in_dict;

    if (this.getWeatherObjectMethod.method) {
      let temp_obj = this.getWeatherObjectMethod.method();
      if (Array.isArray(temp_obj.values)) {
        if (temp_obj.operator == 'r') {
          if (
            temp_obj.values[0] == null ||
            temp_obj.values[1] == null ||
            temp_obj.values[0] >= temp_obj.values[1] ||
            !this.validateWeatherValues(temp_obj.values, this.weatherTargetingOptions.targetType)
          ) {
            this.weatherTargetingError.message = 'Invalid value provided.';
            return;
          }
        } else {
          if (
            temp_obj.values[0] == null ||
            !this.validateWeatherValues(temp_obj.values, this.weatherTargetingOptions.targetType)
          ) {
            this.weatherTargetingError.message = 'Invalid value provided.';
            return;
          }
        }
      } else {
        if (
          temp_obj.values == null ||
          !this.validateWeatherValues(temp_obj.values, this.weatherTargetingOptions.targetType)
        ) {
          this.weatherTargetingError.message = 'Invalid value provided.';
          return;
        }
      }

      this.newWeatherCondition.operator = temp_obj.operator;
      this.newWeatherCondition.value = temp_obj.values;
    }

    let obj;
    if (key_in_dict && this.selfServeDataFactory.nullWeatherObject.hasOwnProperty(key_in_dict)) {
      obj = angular.copy(this.selfServeDataFactory.nullWeatherObject[key_in_dict]);
    } else {
      obj = angular.copy(this.selfServeDataFactory.nullWeatherObject.generic);
    }

    for (let key in obj) {
      if (this.newWeatherCondition.hasOwnProperty(key)) {
        obj[key] = this.newWeatherCondition[key];
      }
    }

    if (this.adgroupTargeting.weather && key_in_dict) {
      this.adgroupTargeting.weather[key_in_dict].length = 0;
      this.adgroupTargeting.weather[key_in_dict].push(obj);
    }
  };

  genericWeatherRemoveFunction = (key_in_dict?: string): void => {
    if (this.adgroupTargeting.weather && key_in_dict) {
      this.adgroupTargeting.weather[key_in_dict].length = 0;
    }
  };

  generic_weather_sentence_fn(obj, sentence_to_use: string) {
    let sentence_1 = '';
    let sentence_2 = '';
    let sentence_3 = '';

    const feels_like_sentence = ' and feels like temperatures';

    const ob_forecast_sentence = ' using current conditions';
    const d0_forecast_sentence = " using today's forecast";
    const d1_forecast_sentence = " using tomorrow's forecast";
    const d2_forecast_sentence = ' using 3 day forecast';

    const gte_sentence = 'Greater than {0}';
    const lte_sentence = 'Less than {0}';
    const r_sentence = 'Between {0} and {1}';

    let val1 = '';
    let val2 = '';
    if (!isArray(obj.value)) {
      val1 = obj.value;
    } else {
      val1 = obj.value[0];
      val2 = obj.value[1];
    }

    if (obj.operator == 'gte') {
      sentence_1 = format(gte_sentence, val1);
    } else if (obj.operator == 'lte') {
      sentence_1 = format(lte_sentence, val1);
    } else if (obj.operator == 'r') {
      sentence_1 = format(r_sentence, val1, val2);
    }

    if (obj.time_constraint == 'ob') {
      sentence_2 = ob_forecast_sentence;
    } else if (obj.time_constraint == 'd0') {
      sentence_2 = d0_forecast_sentence;
    } else if (obj.time_constraint == 'd1') {
      sentence_2 = d1_forecast_sentence;
    } else if (obj.time_constraint == 'd2') {
      sentence_2 = d2_forecast_sentence;
    }

    if (obj.feels_like == true) {
      sentence_3 = feels_like_sentence;
    }

    return format(sentence_to_use, sentence_1, sentence_2, sentence_3);
  }

  conditionWeatherAddFunction = () => {
    this.adgroupTargeting.weather!.weather_condition!.length = 0;

    for (let i = 0; i < this.weatherConditionOptions.length; i++) {
      if (this.weatherConditionOptions[i].selected) {
        const obj = (angular.copy(
          this.selfServeDataFactory.nullWeatherObject.weather_condition
        ) as unknown) as WeatherCondition;

        obj.condition = this.weatherConditionOptions[i].value;
        obj.time_constraint = this.weatherConditionOptions[i].forecast;

        this.adgroupTargeting.weather!.weather_condition!.push(obj);
      }
    }
  };

  conditionWeatherRemoveFunction = () => {
    this.adgroupTargeting.weather!.weather_condition!.length = 0;
    for (let i = 0; i < this.weatherConditionOptions.length; i++) {
      this.weatherConditionOptions[i].selected = false;
      this.weatherConditionOptions[i].forecast = 'ob';
    }
  };

  alertWeatherAddFunction = () => {
    this.adgroupTargeting.weather!.weather_alert!.length = 0;

    for (let i = 0; i < this.weatherAlertOptions.length; i++) {
      if (this.weatherAlertOptions[i].selected) {
        let obj = (angular.copy(this.selfServeDataFactory.nullWeatherObject.weather_alert) as unknown) as WeatherAlert;
        obj.phenomena = this.weatherAlertOptions[i].value;
        this.adgroupTargeting.weather!.weather_alert!.push(obj);
      }
    }
  };

  alertWeatherRemoveFunction = () => {
    this.adgroupTargeting.weather!.weather_alert!.length = 0;

    for (let i = 0; i < this.weatherAlertOptions.length; i++) {
      this.weatherAlertOptions[i].selected = false;
    }
  };

  validateWeatherValues(values, targetType) {
    let valid = true;

    if (
      targetType.validation &&
      targetType.validation.hasOwnProperty('min') &&
      targetType.validation.hasOwnProperty('max')
    ) {
      if (Array.isArray(values)) {
        for (let i = 0; i < values.length; i++) {
          if (values[i] < targetType.validation.min || values[i] > targetType.validation.max) {
            valid = false;
            break;
          }
        }
      } else {
        if (values < targetType.validation.min || values > targetType.validation.max) {
          valid = false;
        }
      }
    }

    return valid;
  }

  removeWeatherSelection = (key: string) => {
    for (let i = 0; i < this.weatherTargetingOptions.primaryOptions.length; i++) {
      if (this.weatherTargetingOptions.primaryOptions[i].key_in_dict === key) {
        this.weatherTargetingOptions.primaryOptions[i].removeFunction(key);
        break;
      }
    }
  };

  removeWeatherSelectionItem = (key: string, idx: number) => {
    this.adgroupTargeting?.weather?.[key].splice(idx, 1);
  };

  changeWeatherTargetType = () => {
    this.weatherTargetingError.message = '';
    let targetType = this.weatherTargetingOptions.targetType;
    this.getWeatherObjectMethod.method = null;

    let key_in_dict = targetType?.key_in_dict;

    if (key_in_dict) {
      if (this.adgroupTargeting.weather![key_in_dict].length == 0) {
        if (this.selfServeDataFactory.nullWeatherObject.hasOwnProperty(key_in_dict)) {
          this.newWeatherCondition = angular.copy(this.selfServeDataFactory.nullWeatherObject[key_in_dict]);
        } else {
          this.newWeatherCondition = angular.copy(this.selfServeDataFactory.nullWeatherObject.generic);
        }
      } else {
        this.newWeatherCondition = angular.copy(this.adgroupTargeting.weather![key_in_dict][0]);
      }
      this.weatherDataChanged = true;
    }
  };

  addWeatherOption = () => {
    this.weatherTargetingError.message = '';
    if (this.weatherTargetingOptions.targetType) {
      this.weatherTargetingOptions.targetType.addFunction();
      this.weatherTargetingOptions.targetType = null;
    }
  };

  handleExcludeAudienceChange = () => {
    if (!this.adgroupTargeting.showAudienceExclude) {
      this.adgroupTargeting.audience_exclude = [];
    }
    safeApply(this.scope);
  };

  updateExcludeAudiences = excluded_audiences => {
    this.adgroupTargeting.audience_exclude = excluded_audiences;
    safeApply(this.scope);
  };
}
