import * as React from 'react';
import { ModalBase } from './ModalBase';
import { PrimaryButton } from '../Button';
import { styled } from '../../../utils/react/mui_styled';
import { CircularIndicator } from '../LoadingIndicator';
import { DangerButton } from '../Button/DangerButton';

interface ConfirmationModalProps {
  open: boolean;
  confirmationText: string;
  confirmButtonLabel?: string | null;
  cancelButtonLabel?: string;
  title: string;
  loading?: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmationText = styled('span')(({ theme }) => ({
  color: 'var(--color-on-primary)',
  fontFamily: 'var( --font-family-regular)',
  fontSize: 'var(--font-size-regular)',
  fontWeight: 'var(--font-weight-bold)',
  lineHeight: '130%'
}));

const ActionSection = styled('div')(({ theme }) => ({
  display: 'flex',
  '& button': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

export function ConfirmationModal({
  open,
  onClose,
  cancelButtonLabel,
  confirmationText,
  onConfirm,
  confirmButtonLabel = null,
  loading = false,
  ...others
}: ConfirmationModalProps) {
  const actionSection = (
    <ActionSection>
      <DangerButton
        title={confirmButtonLabel ? confirmButtonLabel : 'Delete'}
        onClick={onConfirm}
        disabled={loading}
        className="button-v2 skin-red"
      />
      <PrimaryButton
        title={cancelButtonLabel ? cancelButtonLabel : 'Cancel'}
        onClick={onClose}
        outlined
        className="button-v2 button--tertiary-green"
        disabled={loading}
      />
    </ActionSection>
  );

  return (
    <ModalBase
      open={open}
      onClose={onClose}
      className="confirmation-delete-modal"
      actionSection={actionSection}
      showCloseButton={true}
      {...others}
    >
      {loading && <CircularIndicator />}
      {!loading && <ConfirmationText>{confirmationText}</ConfirmationText>}
    </ModalBase>
  );
}
