export function xadPagination() {
  var DEFAULT_THEME = 'primary',
    DEFAULT_PAGE_SIZE = 10,
    DEFAULT_PAGING_RANGE = 5;

  return {
    restrict: 'E',
    scope: {
      currentPage: '=xadCurrentPage',
      pageSize: '=xadPageSize',
      count: '=xadCount',
      trigger: '=xadTrigger'
    },
    controller: /** @ngInject */ function($scope) {
      $scope.currentPage = $scope.currentPage || 0;
      $scope.pageSize = $scope.pageSize || DEFAULT_PAGE_SIZE;
      $scope.count = $scope.count || 0;
      $scope.pagingRange = DEFAULT_PAGING_RANGE;
      $scope.theme = DEFAULT_THEME;

      $scope.goToPage = function(num) {
        if (!angular.isNumber(num) || isNaN(num) || num < 0 || num >= $scope.totalPageNum) {
          return;
        }
        if (angular.isFunction($scope.trigger)) {
          $scope.trigger(num);
        }
        $scope.currentPage = num;
      };

      $scope.firstPage = function() {
        $scope.goToPage(0);
      };

      $scope.lastPage = function() {
        $scope.goToPage($scope.totalPageNum - 1);
      };

      $scope.nextPage = function() {
        $scope.goToPage($scope.currentPage + 1);
      };

      $scope.prevPage = function() {
        $scope.goToPage($scope.currentPage - 1);
      };

      $scope.getPageSize = function(count, pageSize) {
        return Math.floor(count / pageSize) + (count % pageSize === 0 ? 0 : 1);
      };

      $scope.totalPageNum = $scope.getPageSize($scope.count, $scope.pageSize);

      $scope.$watch('count', function(newValue, oldValue) {
        $scope.totalPageNum = $scope.getPageSize(newValue, $scope.pageSize);
      });

      $scope.pages = function() {
        var result = [],
          i,
          start = Math.max(0, $scope.currentPage - Math.floor($scope.pagingRange / 2)),
          end = Math.min($scope.totalPageNum - 1, $scope.currentPage + Math.floor($scope.pagingRange / 2));

        if (end - start + 1 < $scope.pagingRange) {
          if (end - $scope.pagingRange + 1 >= 0) {
            start = end - $scope.pagingRange + 1;
          }
          if (start + $scope.pagingRange < $scope.totalPageNum) {
            end = start + $scope.pagingRange - 1;
          }
        }

        for (i = start; i <= end; i += 1) {
          result.push(i);
        }

        return result;
      };
    },
    template:
      '<div class="pagination">' +
      '<a id="pagination-prev" ng-class="{disabled: currentPage == 0}" ng-click="prevPage()" aria-label="Previous Page">' +
      '<a id="pagination-next" ng-class="{disabled: currentPage >= totalPageNum - 1}" ng-click="nextPage()" aria-label="Next Page">' +
      '</div>'
  };
}
