/**
 * @param {object} o
 * @returns {string[]}
 */
export function getKeys(o) {
  if (o !== Object(o)) throw new TypeError('Object.keys called on non-object');
  var ret = [],
    p;
  for (p in o) if (Object.prototype.hasOwnProperty.call(o, p)) ret.push(p);
  return ret;
}

/**
 * @param {unknown} o
 * @returns {o is unknown[]}
 */
export function isArray(o) {
  return Object.prototype.toString.call(o) === '[object Array]';
}

export function nestedUpdateInPlace(src, dest) {
  // this is a helper function to update keys both present in src and dest
  // keys with values of type object will be updated inplace and not pointing to new objects.
  // this helps angular binding to continue working
  if (!src) {
    return;
  }

  var keys = getKeys(src);
  for (var i = 0; i < keys.length; i++) {
    try {
      var key = keys[i];
      if (typeof src[key] === 'object' && !dest[key]) {
        //arr or obj, if right side is null, create a new copy
        dest[key] = angular.copy(src[key]);
      } else if (isArray(src[key])) {
        //TODO: this isnt tested
        angular.copy(src[key], dest[key]);
      } else if (src[key] && typeof src[key] === 'object') {
        nestedUpdateInPlace(src[key], dest[key]);
      } else {
        dest[key] = src[key];
      }
    } catch (err) {
      //TODO: fix the function :)
      console.warn(err);
    }
  }
}

/** @returns {p is Promise<any>} */
export function isPromise(p) {
  return p && typeof p.then === 'function';
}

/**
 * @param {string | any[] | JQuery | undefined} element
 * @returns {JQuery}
 */
function asJquery(element) {
  if (element instanceof jQuery)
    // @ts-ignore
    return element;
  // @ts-ignore `element` is actually allowed to be `undefined`
  return $(element);
}

/**
 * Since TS does not allow to augment static part of a class,
 * and Waypoint is declared as a class, this is a hack to make TS beleive that class Waypoint
 * may have Sticky
 * @param {typeof Waypoint & { Sticky?: unknown }} waypoint
 * @returns {waypoint is { Sticky: WaypointStickyConstructor}}
 */
function supportsSticky(waypoint) {
  return !!waypoint.Sticky;
}

/**
 * Uses Sticky Table Headers library for table headers, and Waypoint Sticky library for generic elements.
 * @param {string | any[] | JQuery | undefined} context should be the scrollable element that
 * @param {string | any[] | JQuery} element is nested in
 * @param {number} [offset=0]
 * @param {object} [options={}]
 * @returns the Waypoint Sticky instance object if Waypoint was used to make the element sticky, else returns null
 */
export function makeElementSticky(element, context, offset, options) {
  context = asJquery(context);
  element = asJquery(element);
  if (angular.isUndefined(offset)) {
    offset = 0;
  }
  if (angular.isUndefined(options)) {
    options = {};
  }

  if (element instanceof jQuery) {
    element;
  }

  if (angular.isUndefined(element) || angular.isUndefined(element[0])) {
    return;
  }

  var table;
  var elTag = element[0].nodeName;
  if (elTag == 'TR' || elTag == 'THEAD') {
    table = element.closest('table');
  } else if (elTag == 'TABLE') {
    table = element;
  }

  if (table && table.stickyTableHeaders) {
    table.stickyTableHeaders({
      scrollableArea: context,
      fixedOffset: offset
    });
  } else {
    const waypoint = Waypoint;
    if (supportsSticky(waypoint)) {
      // return Waypoint Sticky instance object in case we want to destroy it later
      return new waypoint.Sticky({ element: element[0], context: context[0], offset: offset, options: options });
    }
  }
  return null; // Sticky Table Headers library does not provide an instance object, so return null
}

/**
 * @param {string | number | Date} date
 */
function treatAsUTC(date) {
  var result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}

/**
 * Counts number of days between two dates.
 * This function treats dates as UTC because each day in UTC is guaranteed to be 24 hours (no daylight savings)
 * @param {string | number | Date} startDate
 * @param {string | number | Date} endDate
 */
export function daysBetween(startDate, endDate) {
  if (typeof startDate === 'string') {
    startDate = Date.parse(startDate);
  }
  if (typeof endDate === 'string') {
    endDate = Date.parse(endDate);
  }
  var millisecondsPerDay = 24 * 60 * 60 * 1000;
  return Math.round((treatAsUTC(endDate).valueOf() - treatAsUTC(startDate).valueOf()) / millisecondsPerDay);
}

/**
 * @param {Date | string} date
 */
export function getCorrectedJSdateFromString(date) {
  if (!date) return null;
  if (date instanceof Date) return date;
  var d = new Date(date.replace(/-/g, '/'));
  return d;
}

/**
 * @returns {string}
 */
export function getCurrentDate() {
  return formatDate(new Date());
}

/**
 * @param {number} addDays day difference from today (positive - after today, negative - before today)
 * @returns {string} Formatted date in form 'yyyy-MM-dd'
 */
export function getDateFromToday(addDays) {
  let today = new Date(Date.now());
  return formatDate(new Date(today.valueOf() + addDays * 24 * 60 * 60 * 1000));
}

/**
 * Formats date in 'yyyy-MM-dd' form.
 * @param {Date} date Date to format
 */
export function formatDate(date) {
  return date.toISOString().substr(0, 10);
}

/**
 * Checks if `obj` has `clicks` and `impressions` fields.
 * @param {unknown} obj
 * @returns {obj is { clicks: number; impressions: number; ctr?: number; }}
 */
function hasImpressions(obj) {
  return angular.isObject(obj) && obj.hasOwnProperty('clicks') && obj.hasOwnProperty('impressions');
}

/**
 * Drops extra digits after the decimal point without rounding (for positive numbers).
 * @param {number} num
 */
export function keepDecimalNoRounding(num, precision = 2) {
  return Math.floor(num * 10 ** precision) / 10 ** precision;
}

/**
 * @param {string | number} num
 */
export function getPercentageTextFromDecimal(num) {
  num = num + '%';
  return num;
}

/**
 * @param {number} num
 * @param {boolean} [noMultipleBy100]
 */
export function convertDecimalToPercent(num, noMultipleBy100) {
  if (angular.isNumber(num)) {
    var val = num;
    if (!noMultipleBy100) {
      val = num * 100;
    }
    return val.toFixed(2) + '%';
  } else {
    return '%';
  }
}

/**
 * @param {string | undefined} css
 * @returns {Record<string, string>}
 */
export function stringCssToDictCss(css) {
  /** @type {Record<string, string>} */
  var css_dict = {};

  if (!css) {
    return css_dict;
  }

  const parts = css.trim().split(';');
  for (var i = 0; i < parts.length; i++) {
    if (parts[i].trim().indexOf(':') > -1) {
      var cssKeyValue = parts[i].trim().split(':');
      css_dict[cssKeyValue[0].trim()] = cssKeyValue[1].trim();
    }
  }

  return css_dict;
}

/**
 * @param {{ [x: string]: string; } | undefined} css
 */
export function dictCssToStringCss(css) {
  var string_css = '';

  if (!css) {
    return string_css;
  }

  [].forEach.call(getKeys(css), function(key) {
    if (key) {
      string_css += key + ':' + css[key] + ';';
    }
  });

  return string_css;
}

/**
 * Rounds @param num to @param dec decimal places.
 * @param {ng.IFilterService} $filter
 * @param {string | number} num
 * @param {string | number} [dec]
 */
export function round($filter, num, dec) {
  return +$filter('number')(num, dec);
}

/**
 * Converts @param num to a string with 'K' for thousands and 'M' for millions.
 * By default, all values will be rounded to 1 decimal place.
 * @param {ng.IFilterService} $filter
 * @param {number} num
 * @param {number | null} [underThousandDec] is the number of decimal places to show for numbers under 1000
 * @param {number | null} [dec] is the number of decimal places to show for all other numbers
 */
export function shorthandNumber($filter, num, underThousandDec, dec) {
  if (underThousandDec == null || angular.isUndefined(underThousandDec)) {
    underThousandDec = 1;
  }
  if (dec == null || angular.isUndefined(dec)) {
    dec = 1;
  }

  if (num >= 1000000) {
    return $filter('number')(num / 1000000, dec) + 'M';
  } else if (num >= 1000) {
    return $filter('number')(num / 1000, dec) + 'K';
  } else {
    return $filter('number')(num, underThousandDec);
  }
}

/**
 * Converts number to string with US language sensitive representation keeping all the digits (for example 111,222.666)
 * @param {number} num
 * @param {number | null} [dec] The number of decimal places
 */
export function numberToUSString(num, dec) {
  if (num == undefined || num == null) {
    return;
  }
  if (dec != undefined) {
    return num.toLocaleString('en-US', { minimumFractionDigits: dec, maximumFractionDigits: dec });
  }
  return num.toLocaleString('en-US');
}

export function scrollToTopOfPage() {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

/**
 * @param {string} id
 * @param {string | number} [duration]
 */
export function smoothScrollToElement(id, duration) {
  var element = document.getElementById(id);

  if (!element) {
    return;
  }
  if (angular.isUndefined(duration)) {
    duration = 350;
  }

  var scrollPosition = element.offsetTop;
  angular.element('html').animate(
    {
      scrollTop: scrollPosition
    },
    duration
  );
}

/**
 * @param {string} str
 */
export function getFileExtensionFromString(str) {
  var re = /(?:\.([^.]+))?$/;
  const match = re.exec(str);
  return match ? match[1] : null;
}

/**
 * @param {Date | string} date
 */
export function DateToString(date) {
  if (!date) return '';
  else if (typeof date === 'string') return date;
  // if date is already string, do nothing
  else return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
}

/**
 * @param {ng.FileSaver} FileSaver
 * @param {BlobPart} pdf
 * @param {string} filename
 */
export function downloadPDF(FileSaver, pdf, filename) {
  const file = new Blob([pdf], { type: 'application/pdf;charset=utf-8' });
  if (!filename.endsWith('.pdf')) {
    filename += '.pdf';
  }
  FileSaver.saveAs(file, filename);
}

/**
 * @param {ng.FileSaver} FileSaver
 * @param {BlobPart} data
 * @param {string} filename
 */
export function downloadSpreadsheet(FileSaver, data, filename) {
  const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  if (!filename.endsWith('.xlsx')) {
    filename += '.xlsx';
  }
  FileSaver.saveAs(file, filename);
}

/**
 * @param {ng.FileSaver} FileSaver
 * @param {BlobPart} data
 * @param {string} filename
 */
export function downloadCSV(FileSaver, data, filename) {
  const file = new Blob(['\ufeff', data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=charset=utf-8'
  });
  if (!filename.endsWith('.csv')) {
    filename += '.csv';
  }
  FileSaver.saveAs(file, filename);
}

/**
 * This function is to avoid $apply being called multiple times. This can happen when we are interacting with 3rd libraries or a non angular event. This can happen due to the newer async await in es6
 *
 * Please refer
 * Why does it happen? https://gist.github.com/siongui/4969449
 * Fix: This code has been taken from https://gist.github.com/siongui/4969449
 * @param {ng.IScope} scope
 */
export function safeApply(scope) {
  let phase = scope.$root?.$$phase;
  if (phase == '$apply' || phase == '$digest') scope.$eval();
  else scope.$apply();
}

/**
 *
 * @param {string} str
 * @returns Returns the first character as uppercase
 */
export function toFirstCharUpperCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// TODO: check if this should go here
/**
 * @template {{ checked?: boolean; }} T
 * @param {T} selected
 * @param {readonly T[]} selectedList
 */
export function selectDeselectOptions(selected, selectedList) {
  if (selected.checked) {
    return [...selectedList, selected];
  } else {
    return selectedList.filter(item => item.checked);
  }
}

/**
 * Searches the list and returns elements in the following order:
 * 1. Exact matches
 * 2. Prefix matches
 * 3. Substring matches
 *
 * @template T
 * @param {string} term - the term to search in the list
 * @param {readonly T[]} list - The list to search in
 * @param {readonly string[]} propsToSearch - will match the search term with given attributes
 * @return list of filtered items in the specified order
 */
export function searchInOrder(term, list, propsToSearch) {
  const lowerCasedTerm = term.toString().toLowerCase();

  if (term) {
    const exactMatches = [],
      prefixMatches = [],
      substringMatches = [];

    list.forEach(item => {
      propsToSearch.some(searchProp => {
        if (!item[searchProp]) {
          return false;
        }
        const lowerCasedItemName = item[searchProp].toString().toLowerCase();

        if (lowerCasedItemName === lowerCasedTerm) {
          // Exact match
          exactMatches.push(item);
          return true;
        } else if (lowerCasedItemName.startsWith(lowerCasedTerm)) {
          // Prefix match
          prefixMatches.push(item);
          return true;
        } else if (lowerCasedItemName.includes(lowerCasedTerm)) {
          // Substring match
          substringMatches.push(item);
          return true;
        }
      });
    });
    return [...exactMatches, ...prefixMatches, ...substringMatches];
  }

  return list;
}

/**
 * @param { object } data
 * @returns {string[]}
 */
export function extractErrorsFromResponse(data) {
  let errors = [];
  if (data.errors && data.errors.length) {
    for (let error of data.errors) {
      errors.push(error.message);
    }
  }

  if (!errors.length) {
    errors.push(data.message);
  }
  return errors;
}

export function getFirstAndLastIndexBasedOnPage(totalItemsPerPage, pageNumber) {
  let firstIndex = totalItemsPerPage * (pageNumber - 1);
  let lastIndex = totalItemsPerPage * pageNumber;

  return {
    firstIndex,
    lastIndex
  };
}
/**
 * @param { string } date
 * @returns {Date} format date without time
 */
export function getDateWithoutTime(date) {
  let dateObj = date ? new Date(date) : new Date();
  // set time as 0
  return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 0, 0, 0);
}

/**
 * @param {string} utcDate
 * @returns {string} the date string based on the browser default locale
 */
export function convertToLocalTimezoneByDate(utcDate) {
  const date = new Date(utcDate);
  const localDateString = date.toLocaleDateString();
  return localDateString;
}

/**
 * @param {string} utcTimestamp
 * @returns {string} the timestamp string based on the browser default locale
 */
export function convertToLocalTimezoneByTimestamp(utcTimestamp) {
  const date = new Date(utcTimestamp);
  const localTimestamp = utcTimestamp + date.getTimezoneOffset() * 60 * 1000;
  return localTimestamp;
}
