import { VirtualList } from './virtual_list';

export class ArrayVirtualList<T> implements VirtualList<T> {
  private data: T[] | undefined;
  private dataPromise: Promise<T[]> | undefined;

  constructor(data: T[] | Promise<T[]>) {
    if (data && Array.isArray(data)) {
      this.data = data;
    } else if (data && data.then) {
      this.dataPromise = data.then(data => {
        this.data = data;
        return data;
      });
    } else {
      throw new Error('ArrayVirtualList accepts an array or a promise of an array.');
    }
  }

  getCount() {
    if (this.data !== undefined) {
      return this.data.length;
    }
    return this.dataPromise!.then(data => data.length);
  }

  getItems(from: number, count: number) {
    if (this.data !== undefined) {
      return getItems(this.data, from, count);
    }
    return this.dataPromise!.then(data => getItems(data, from, count));
  }
}

function getItems<T>(data: T[], from: number, count: number) {
  if (from === 0 && count >= data.length) return data;
  return data.filter((_, index) => index >= from && index < from + count);
}

/**
 * Creates a virtual list from an array source or a promise of the array source.
 *
 * @param data the source array or a promise of the source array.
 *
 * @example
 *
 * // preloaded array
 * const data = [object1, object2];
 * const list = virtualListFromArray(data);
 *
 * // promise
 * const dataPromise = getListFromHttp();
 * const list = virtualListFromArray(dataPromise); // without await
 */
export function virtualListFromArray<T>(data: Promise<T[]> | T[]) {
  return new ArrayVirtualList(data);
}
