import * as React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export const DesktopIcon = createSvgIcon(
  <path
    d="M42 4H6C3.8 4 2 5.8 2 8V32C2 34.2 3.8 36 6 36H20L16 42V44H32V42L28 36H42C44.2 36 46 34.2 46 32V8C46 5.8 44.2 4 42 4ZM42 28H6V8H42V28Z"
    fill="#0A3F36"
  />,
  'GTDesktop'
);

export default DesktopIcon;
