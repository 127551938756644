import * as Sentry from '@sentry/browser';
import { Angular as AngularIntegration } from '@sentry/integrations';

class SentryService {
  DEVELOP = 'develop';
  PROD = 'prod';
  ENABLE_SENTRY = IS_PROD && ADS_MANAGER_ENVIRONMENT !== 'DEV';
  private readonly PUBLIC_DSN = this.ENABLE_SENTRY
    ? 'https://325a049ff80245deb43be14683c53849@apps-sentry.groundtruth.com/10'
    : '';

  setup(angular: ng.IAngularStatic) {
    const fullEnvironment = this.getEnvironment(ADS_MANAGER_VERSION, ADS_MANAGER_ENVIRONMENT);
    Sentry.init({
      dsn: this.PUBLIC_DSN,
      integrations: [new AngularIntegration({ angular })],
      environment: fullEnvironment,
      enabled: this.ENABLE_SENTRY
    });

    Sentry.setUser({ email: window?.user?.username });
  }

  getEnvironment(version: string, env: string) {
    if (version.toLowerCase() === this.DEVELOP) {
      return this.DEVELOP;
    } else {
      const [majorVersion, minorVersion, _patch] = version.split('.');
      const _environment = env.toLowerCase() === this.PROD ? 'pr' : 'np';
      return `release-${majorVersion}-${minorVersion}-${_environment}`;
    }
  }
}

export const SentryAngular = new SentryService();
