import { IOnInit } from 'angular';
import { isEmpty } from 'lodash';
import { AdGroupTargeting } from '../../../../api/types/adgroup';
import { CampaignManagerService, FeatureFlagService, SearchService } from '../../../../factories/types';
import template from './adgroup_targeting_measurement.html';

export function adgroupTargetingMeasurement(): ng.IDirective {
  return {
    restrict: 'E',
    template: template,
    scope: {
      adgroupTargeting: '=',
      booleans: '=',
      checkSensitivity: '=',
      locationGroupAutoCompleteDisabled: '=',
      onMeasurementsTableUpdated: '=',
      callDriveToGaEvent: '=',
      enableStoreVisitation: '=',
      enableStoreVisitationLift: '='
    },
    controller: AdGroupTargetingMeasurementController,
    controllerAs: 'vm',
    bindToController: true
  };
}

interface LocationGroupType {
  account_id?: number;
  description: string;
  exclude: boolean;
  id: number;
  name: string;
  proximity?: number;
  tag_type: number;
  tenant_id: number;
  type: string;
}

interface BrandType {
  category: number;
  exclude: boolean;
  id: number;
  name: string;
  parent_id: number;
  parent_name: string;
  proximity: number;
  sensitivity: number;
  type: string;
}

type AllMeasurementType = BrandType | LocationGroupType;
type OnMeasurementsTableUpdated = (item: AllMeasurementType[]) => AllMeasurementType[];
export class AdGroupTargetingMeasurementController implements IOnInit {
  // Scope's data
  adgroupTargeting: AdGroupTargeting = {};
  onMeasurementsTableUpdated: OnMeasurementsTableUpdated = item => item;
  enableStoreVisitation = false;
  booleans: Record<string, boolean> = {};
  locationGroupAutoCompleteDisabled = false;

  // Factories
  readonly searchFactory: SearchService;
  readonly featureFlagFactory: FeatureFlagService;
  readonly campaignManagerFactory: CampaignManagerService;

  locationManagerURL = '';

  /** @ngInject */
  public constructor(
    searchFactory: SearchService,
    featureFlagFactory: FeatureFlagService,
    campaignManagerFactory: CampaignManagerService
  ) {
    this.searchFactory = searchFactory;
    this.featureFlagFactory = featureFlagFactory;
    this.campaignManagerFactory = campaignManagerFactory;
  }

  $onInit() {
    this.locationManagerURL = `//${window.locationManagerURL}`;
  }

  async searchMeasurements(query) {
    const filter = {
      targeting_search: 1,
      entity_types: 'brand,location_group',
      search_category: 'drive_to'
    };
    return this.searchFactory.getSearchResults(query, filter);
  }

  removePreviousCpgProducts() {
    if (this.adgroupTargeting.cpgProducts!.length > 1) {
      this.adgroupTargeting.cpgProducts!.splice(0, 1);
    }
    this.booleans.cpgProductsTableChanged = true;
  }

  async searchCpgProducts(query) {
    const filter = {
      targeting_search: 1,
      entity_types: 'cpg_product'
    };
    return this.searchFactory.getSearchResults(query, filter).then(function(results) {
      if (isEmpty(results)) {
        return {};
      }
      return {
        // replace name with upc|name here
        Products: results.map(function(item) {
          item.type = '';
          item.name = item.upc + ' | ' + item.name;
          return item;
        })
      };
    });
  }
}
