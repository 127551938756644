/**
 * @param {ng.IFilterService} $filter
 * @param {ng.IParseService} $parse
 * @param {ng.ILogService} $log
 * @param {ng.ITimeoutService} $timeout
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {import('../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {ng.IWindowService} $window
 * @param {import('ngstorage').ngStorage.StorageService} $localStorage
 * @ngInject
 */
export function xadTable(
  $filter,
  $parse,
  $log,
  $timeout,
  $state,
  generalUtilsFactory,
  companyAndAccountFactory,
  dataExportFactory,
  campaignManagerFactory,
  $window,
  $localStorage,
  featureFlagFactory
) {
  var DEFAULT_THEME = 'primary',
    DEFAULT_PAGE_SIZE = 0;

  const DEFAULT_BREAKDOWN_ROWS_LIMIT = 100;
  return {
    restrict: 'E',
    scope: {
      tableChanged: '=',
      config: '=xadConfig',
      content: '<xadContent',
      dataField: '@xadDataField',
      countField: '=xadCountField',
      tableClasses: '=',
      selectable: '=?',
      highlightedAdGroup: '=',
      stickyHeader: '=',
      dailySpend: '=',
      currentTab: '@',
      level: '@',
      report: '@',
      noPagination: '=',
      filterCallback: '&',
      actionSheetBooleans: '=?',
      actionSheetError: '=?',
      actionSheetCallback: '&?',
      checkedIds: '=?',
      customSort: '&?',
      isNestedDirective: '=?'
    },
    controller: /** @ngInject */ function($scope, $element) {
      /*
       *
       * config: {
       *   header: [
       *       {
       *           name: String,
       *           field: String with prefix [=, &, @],
       *           items: [],
       *           filter: String,
       *           hide: Boolean,
       *           sortable: Boolean,
       *           editable: Boolean,
       *           trigger: function // not supporting yet
       *       }
       *   ],
       *   items: {
       *       field:
       *       filter:
       *       trigger:
       *       link:
       *       image:
       *       editable:
       *   }
       *   extra: [
       *       {
       *           value: String with prefix
       *           fields: Array,
       *           filter: String,
       *           func: [sum, count, min, max, avg]
       *       }
       *   ],
       *   defaultOrderField: String,
       *   draggable: Boolean, // not supporting yet
       *   filter: String,
       *   theme: String, // not supporting yet
       *   limit: Number,
       *   count: Number,
       *   rowTrigger: function,
       *   newDataTrigger: function,
       * }
       *
       * */
      $scope.currentState = $state.current.name;
      $scope.$storage = $localStorage;

      var previousPage = 0;
      var numRowsInWindow;
      $scope.data = [];
      $scope.paginatedData = [];
      $scope.count = 0;
      $scope.reverse = false;
      $scope.tableDataArray = [];
      $scope.allChecked = { checked: false };
      $scope.pagination = {
        currentPage: 1
      };
      $scope.levelNames = {
        campaigns: 'campaigns',
        placements: 'ad groups',
        creatives: 'creatives'
      };
      $scope.availableLimits = [10, 25, 50];
      $scope.checkedIds = [];
      $scope.isReportingOnlyUser = featureFlagFactory.isFeatureEnabled('REPORTING_ONLY');

      function preConfiguration() {
        var config = {
            tableName: 'default',
            header: [],
            extra: [],
            hideHeader: false,
            defaultOrderField: null,
            draggable: false,
            filter: '',
            customFilter: null,
            theme: DEFAULT_THEME,
            limit: DEFAULT_PAGE_SIZE,
            rowTrigger: null,
            expandOrCollapseRowTrigger: null,
            extraRowTrigger: null,
            newDataTrigger: null,
            paginationTrigger: null
          },
          column = {
            name: null,
            components: [],
            layout: 'column',
            field: null,
            sortable: true,
            flex: 'auto',
            trigger: null
          },
          component = {
            field: null,
            flex: 1,
            filter: null,
            form: null,
            link: null,
            image: null,
            custom: null,
            editable: false,
            trigger: null
          },
          extraColumn = {
            value: null,
            fields: [],
            filter: null,
            func: null
          },
          form = {
            type: null,
            text: null,
            value: null
          },
          image = {
            width: 'auto',
            height: 'auto',
            alt: null
          },
          link = {
            title: null,
            target: '_blank'
          },
          keys = [],
          i,
          j,
          key;

        if ($scope.data.length > 0 && angular.isDefined($scope.data[0])) {
          if (Array.isArray($scope.data[0])) {
            keys = Object.keys($scope.data[0][0]);
          } else {
            keys = Object.keys($scope.data[0]);
          }
        }

        if (angular.isUndefined($scope.config) || $scope.config === null) {
          $scope.config = config;

          for (const field of keys) {
            $scope.config.header.push({ ...column, name: field, field, components: [{ ...component, field }] });
          }
        } else {
          for (key in config) {
            if (config.hasOwnProperty(key) && !$scope.config.hasOwnProperty(key)) {
              $scope.config[key] = config[key];
            }
          }

          if ($scope.config.header.length === 0) {
            for (const field of keys) {
              $scope.config.header.push({ ...column, name: field, field, components: [{ ...component, field }] });
            }
          } else {
            for (i = 0; i < $scope.config.header.length; i += 1) {
              for (key in column) {
                /* istanbul ignore else */
                if (column.hasOwnProperty(key)) {
                  if (!$scope.config.header[i].hasOwnProperty(key)) {
                    $scope.config.header[i][key] = column[key];
                    if ((key === 'name' || key === 'field') && i < keys.length) {
                      $scope.config.header[i][key] = keys[i];
                    }
                  }

                  if (key === 'components') {
                    if ($scope.config.header[i][key].length === 0) {
                      $scope.config.header[i][key].push(angular.copy(component));
                      $scope.config.header[i][key][0].field = i < keys.length ? keys[i] : null;
                    } else {
                      for (j = 0; j < $scope.config.header[i][key].length; j += 1) {
                        var cellConfig = $scope.config.header[i][key][j];
                        cellConfig = angular.merge(angular.copy(component), cellConfig);
                        if (cellConfig.form !== null) {
                          cellConfig.form = angular.merge(angular.copy(form), cellConfig.form);
                        }
                        if (cellConfig.link !== null) {
                          cellConfig.link = angular.merge(angular.copy(link), cellConfig.link);
                        }
                        if (cellConfig.image !== null) {
                          cellConfig.image = angular.merge(angular.copy(image), cellConfig.image);
                        }
                      }
                    }
                  }

                  if (key === 'trigger') {
                    if (angular.isFunction($scope.config.header[i][key])) {
                      var headerTriggerFunction = $scope.config.header[i][key];
                      $scope.config.header[i][key] = function(item) {
                        headerTriggerFunction(item, $scope.data);
                      };
                    }
                  }
                }
              }
            }
          }

          if ($scope.config.extra && $scope.config.extra.length > 0) {
            for (i = 0; i < $scope.config.extra.length; i += 1) {
              for (key in extraColumn) {
                if (extraColumn.hasOwnProperty(key) && !$scope.config.extra[i].hasOwnProperty(key)) {
                  $scope.config.extra[i][key] = extraColumn[key];
                }
              }
            }
          }
        }

        $scope.tableName = $scope.config.tableName;
        $scope.isAdgroup = $scope.tableName === 'campaignSummary';
        $scope.isCreativeRepositoryList = $scope.tableName === 'creativeRepositoryList';
        $scope.isCampaignClientDashboard = $scope.tableName === 'campaignClientDashboard';
        $scope.isCampaignOpsDashboard = $scope.tableName === 'campaignOpsDashboard';
        $scope.isChannelDashboard = $scope.isCampaignClientDashboard || $scope.isCampaignOpsDashboard;
        $scope.isCampaignDashboard = $scope.tableName === 'campaignDashboard' || $scope.isChannelDashboard;
        $scope.header = $scope.config.header;
        $scope.extraRow = $scope.config.extra;
        $scope.predicate = $scope.predicate || $scope.config.defaultOrderField;
        $scope.count = $scope.data.length;

        if (!$scope.$storage.tablesLimit) {
          $scope.$storage.tablesLimit = {};
        }
        if (!$scope.$storage.tablesLimit[$scope.tableName]) {
          $scope.$storage.tablesLimit[$scope.tableName] = $scope.availableLimits[0];
        }

        $scope.totalPageNum = Math.ceil($scope.count / $scope.$storage.tablesLimit[$scope.tableName]);
        $scope.rowTrigger =
          $scope.config.rowTrigger === null
            ? null
            : function(row) {
                row = !row || angular.equals($scope.highlightedAdGroup, row) ? null : row;
                if (row) {
                  $scope.config.rowTrigger(row);
                }
              };
        $scope.expandOrCollapseRowTrigger =
          $scope.config.expandOrCollapseRowTrigger === null
            ? null
            : function(row) {
                $scope.config.expandOrCollapseRowTrigger(row);
              };
        $scope.newDataTrigger = $scope.config.newDataTrigger;
        $scope.extraRowTrigger =
          $scope.config.extraRowTrigger === null
            ? null
            : function(row) {
                $scope.config.extraRowTrigger(row);
              };
        $scope.paginationTrigger = function() {
          $scope.paginateData();

          if ($scope.config.paginationTrigger !== null) {
            $scope.config.paginationTrigger();
          }
        };

        if ($scope.isCampaignDashboard || $scope.isChannelDashboard) {
          $scope.sort('id'); // Show newest campaigns at top of table by default
        }
      }

      function getData(content, field) {
        if (angular.isArray(content)) {
          return content;
        }
        if (field && content.hasOwnProperty(field)) {
          return content[field];
        }

        return [];
      }

      function tableDefaultOrderReset() {
        $scope.reverse = true;
        $scope.predicate = $scope.config.defaultOrderField;
      }

      function tableHeaderSwitch() {
        //swtich header to dailySpendHeader
        var temp = angular.copy($scope.config.header);
        $scope.config.header = $scope.config.dailySpendHeader;
        $scope.config.dailySpendHeader = temp;
      }

      function updateBreakdownLimit() {
        $scope.breakdownLimit =
          $scope.report === 'daily' || $scope.currentTab === 'audience' ? DEFAULT_BREAKDOWN_ROWS_LIMIT : undefined;
      }

      $scope.showMoreTrigger = function() {
        $scope.breakdownLimit += DEFAULT_BREAKDOWN_ROWS_LIMIT;
      };
      $scope.breakdownShowMoreEnabled = $scope?.config?.breakdownShowMoreEnabled;

      $scope.paginateData = function() {
        removeGlobalCheckSelection();

        var nextPageIndex =
          $scope.data.length < $scope.$storage.tablesLimit[$scope.tableName]
            ? 0
            : ($scope.pagination.currentPage - 1) * $scope.$storage.tablesLimit[$scope.tableName];
        $scope.paginatedData = $scope.noPagination
          ? angular.copy($scope.data)
          : $filter('limitTo')(angular.copy($scope.data), $scope.$storage.tablesLimit[$scope.tableName], nextPageIndex);

        toggleCheckboxes();
        $scope.allChecked.checked = allCheckedInPage();
      };

      function toggleCheckboxes() {
        $scope.paginatedData.forEach(row => {
          if ($scope.checkedIds.includes(row.id)) {
            row.checked = true;
          }
        });
      }

      function removeGlobalCheckSelection() {
        $scope.config.header.forEach(header => {
          if (header.field === 'checkbox') {
            header.checked = false;
          }
        });
      }

      function updateData(value) {
        if (!value) {
          return;
        }

        if (value.then) {
          value
            .then(function(data) {
              $scope.data = getData(data, $scope.dataField);
              preConfiguration();
              $scope.sort($scope.predicate);
              $scope.applyFilters();
            })
            .catch(function(reason) {
              $log.debug(reason);
              $scope.data = [];
              preConfiguration();
            });
        } else {
          $scope.data = getData(value, $scope.dataField);
          preConfiguration();
          !$scope.customSort ? $scope.sort($scope.predicate) : null;
          $scope.applyFilters();
        }

        updateBreakdownLimit();
        $scope.paginateData();
      }

      $scope.applyFilters = function() {
        var data = $scope.data;

        data = data.reduce(function(acc, dataVal) {
          return acc.concat(dataVal);
        }, []);
        for (var i = 0; i < data.length; i++) {
          var row = data[i];
          if (angular.isUndefined(row)) {
            return;
          }
          row.filteredValues = {};
          for (var column_index in $scope.header) {
            var column = $scope.header[column_index];
            for (var component_index in column.components) {
              var component = column.components[component_index];
              // components can have the keys ['field', 'filter', 'export_field']
              if (component.field !== 'filteredValues') {
                if (component.filter) {
                  row.filteredValues[component.field] = $scope.fieldFilter(
                    $scope.fieldParser(row, component.field),
                    component.filter
                  );
                } else {
                  row.filteredValues[component.field] = $scope.fieldParser(row, component.field, null, false);
                }
                if (component.exportField) {
                  if (component.exportFilter) {
                    row.filteredValues[component.exportField] = $scope.fieldFilter(
                      $scope.fieldParser(row, component.exportField),
                      component.exportFilter
                    );
                  } else {
                    row.filteredValues[component.exportField] = $scope.fieldFilter(
                      $scope.fieldParser(row, component.exportField),
                      component.exportFilter
                    );
                  }
                }
              }
            }
          }
        }
      };

      $scope.getFieldValue = function(row, component) {
        const field = $scope.fieldParser(row, component.field);
        if (component.filter && field != '--') {
          return $scope.fieldFilter(field, component.filter);
        } else {
          return field ?? '--';
        }
      };

      $scope.getCampaignDashboardFieldValue = function(row, col) {
        const field = row[col.components[0].field];
        const filter = col.components[0].filter;
        if (filter) {
          return $scope.fieldFilter(field, filter);
        } else {
          return field ? field : '--';
        }
      };

      $scope.getStatusCSSClass = function(row, col) {
        const field = col.components?.[0]?.field;
        if (field === 'status') {
          const status = row[col.components[0].field]?.toLowerCase();
          if (['active', 'pending', 'paused', 'expired'].includes(status)) {
            return `status ${status}-status`;
          }
          return '';
        }
      };

      $scope.getBreakdownFieldValue = function(breakdownRow, col) {
        const field = breakdownRow[col.components[0].field];
        const filter = col.components[0].filter;
        if (filter) {
          return $scope.fieldFilter(breakdownRow[col.field], filter);
        } else {
          return col.field !== 'id' && col.field !== 'name' && field === undefined ? '--' : field;
        }
      };

      $scope.fieldFilter = function(value, filter) {
        if (angular.isUndefined(value) || value === null || !angular.isString(filter)) {
          return '--';
        }
        var params = filter.split(':').map(function(obj) {
          return obj.trim();
        });

        var filterName = params[0];
        params[0] = value;

        try {
          return $filter(filterName).apply(this, params);
        } catch (e) {
          $log.warn(e);
          return value;
        }
      };

      $scope.fieldParser = function(item, field, link = null, hasFilter = true) {
        if (!angular.isObject(item) || !angular.isString(field) || field.length === 0) {
          return '';
        }

        if (field[0] === '=') {
          field = field.substring(1);
        } else if (field[0] === '&') {
          field = field.substring(1);
          item[field] = $parse(field)(item);
        } else if (field[0] === '@') {
          return field.substring(1);
        }

        if (Array.isArray(item)) {
          return item[0][field];
        }

        if (link && link.hasOwnProperty('getUrl')) {
          if (Array.isArray(link.field)) {
            let params = link.field.map(fieldName => item[fieldName]);
            return link.getUrl(params);
          }
          return link.getUrl(item[link.field]);
        }

        if (item.hasOwnProperty(field)) {
          if (item[field] == null && !hasFilter) {
            return '--';
          }
          return item[field];
        }

        return '';
      };

      $scope.extraFieldParser = function(value, fields, func) {
        if (!angular.isString(value) || value.length === 0) {
          return '';
        }

        if (angular.isUndefined(func) || func === null) {
          func = 'sum';
        }

        var i,
          j,
          tmp,
          item = {};

        if (value[0] === '=') {
          value = value.substring(1);
          fields = [value];
        } else if (value[0] === '&') {
          value = value.substring(1);
        } else if (value[0] === '@') {
          return value.substring(1);
        } else {
          fields = [value];
        }

        if (!angular.isArray(fields)) {
          fields = [value];
        }

        for (i = 0; i < fields.length; i += 1) {
          tmp = [];
          for (j = 0; j < $scope.data.length; j += 1) {
            if ($scope.data[j].hasOwnProperty(fields[i])) {
              tmp.push($scope.data[j][fields[i]]);
            }
          }

          if (angular.isFunction(func)) {
            item[fields[i]] = func.apply(null, tmp);
          } else {
            item[fields[i]] = $scope.executeFunc(func, tmp);
          }
        }

        return $parse(value)(item);
      };

      $scope.executeFunc = function(func, arr) {
        var functions = {
          sum: function(data) {
            if (data.length === 0) {
              return 0;
            }
            return data.reduce(function(a, b) {
              a = parseFloat(a);
              b = parseFloat(b);

              a = isNaN(a) ? 0 : a;
              b = isNaN(b) ? 0 : b;

              return a + b;
            });
          },
          avg: function(data) {
            if (data.length === 0) {
              return 0;
            }
            return this.sum(data) / this.count(data);
          },
          count: function(data) {
            return data.length;
          },
          max: function(data) {
            return Math.max.apply(null, data);
          },
          min: function(data) {
            return Math.min.apply(null, data);
          }
        };

        if (functions.hasOwnProperty(func) && angular.isArray(arr)) {
          return functions[func](arr);
        }

        return '';
      };

      /* there is different type of implementaion for function call for nested directive.
       Reference: https://stackoverflow.com/questions/29762926/angularjs-passing-function-arguments-across-multiple-isolated-scopes-with-angul */
      async function performCustomSort(predicate) {
        $scope.isNestedDirective
          ? await $scope.customSort()(predicate, !$scope.reverse, false)
          : await $scope.customSort({ sortBy: predicate, sortDesc: !$scope.reverse, clearCheck: false });
      }

      /**
       * Sorting function for table rows.
       *
       * TODO: Avoid assigning with non-existing predicates, sort function invoked 4 times in initial load(?).
       * */
      $scope.sort = function(predicate, headerClicked) {
        // If invalid predicate, return.
        if (!angular.isString(predicate) || predicate.length === 0) {
          return;
        }

        // If predicate starts with special char, drop first char of field name.
        if (predicate[0] === '=' || predicate[0] === '&' || predicate[0] === '@') {
          predicate = predicate.substring(1);
        }

        // Only reverse sort order if user clicked the table header, and the predicate is the same.
        if ($scope.predicate === predicate && headerClicked) {
          $scope.reverse = !$scope.reverse;
        } else if ($scope.predicate !== predicate) {
          $scope.predicate = predicate;
          $scope.reverse = false;
        }

        if (headerClicked && $scope.customSort && typeof $scope.customSort === 'function') {
          performCustomSort(predicate);
          return; // Custom sort uses an API call for backend sorting, no need to continue.
        }

        // Sort $scope.data based on sort logic.
        $scope.data.sort(sortLogic);

        // Sort breakdowns, other than 'day-of-week time-of-day' option.
        if ($scope.report !== 'dowtod') {
          for (let i = 0; i < $scope.data.length; i++) {
            // If array, store all except index [0] as breakdown. ([0] contains metadata).
            if (Array.isArray($scope.data[i])) {
              let breakdowns = $scope.data[i].splice(1);

              // If breakdowns don't have predicate, no need to sort.
              let needSorting = false;
              for (let j = 0; j < breakdowns.length; j++) {
                if (breakdowns[j].hasOwnProperty(predicate)) {
                  needSorting = true;
                  break;
                }
              }

              if (needSorting) {
                breakdowns.sort(sortLogic);
              }

              $scope.data[i] = $scope.data[i].concat(breakdowns);
              break;
            }
          }
        }

        $scope.paginateData();

        // Comparator function for sorting based on values of $scope.predicate, $scope.data, $scope.reverse.
        function sortLogic(a, b) {
          if (!$scope.reverse) {
            const temp = angular.copy(a);
            a = b;
            b = temp;
          }

          // Extract data values for sorting. If array, extract from index [0].
          let val1, val2;
          if (Array.isArray(a)) {
            val1 = a[0][predicate];
          } else {
            val1 = a[predicate];
          }
          if (Array.isArray(b)) {
            val2 = b[0][predicate];
          } else {
            val2 = b[predicate];
          }

          // If values are arrays, sort based on first element and sort breakdowns afterwards.
          if (Array.isArray(val1) || Array.isArray(val2)) {
            val1 = val1[0];
            val2 = val2[0];
          }

          // In case of undefined values (e.g. spends_spent is undefined when no data exists), push to top.
          if ([undefined, null].includes(val1) && [undefined, null].includes(val2)) {
            return 0;
          } else if ([undefined, null].includes(val1)) {
            return -1;
          } else if ([undefined, null].includes(val2)) {
            return 1;
          }

          // Creative size -> split dimensions, compare width, height.
          if ($scope.predicate === 'creativeSize' || $scope.predicate === 'size') {
            val1 = val1.split('x');
            val2 = val2.split('x');

            return val1[0] === val2[0] ? val1[1] - val2[1] : val1[0] - val2[0];
          }

          // Days remaining -> "timeframe_remain" contains both numeric and string values, convert to number.
          if ($scope.predicate === 'timeframe_remain') {
            val1 = Number(val1);
            val2 = Number(val2);
          }

          // Parse if date values (stored as string).
          if ($scope.predicate === 'timeframe_start_string' || $scope.predicate === 'timeframe_end_string') {
            val1 = new Date(val1.split('-'));
            val2 = new Date(val2.split('-'));
          }

          // If string values, ignore casing in comparision.
          if (typeof val1 === 'string' || typeof val2 === 'string') {
            return val1.localeCompare(val2, { sensitivity: 'base' });
          }

          // Default sort logic.
          if (val1 < val2) {
            return -1;
          } else if (val1 > val2) {
            return 1;
          } else {
            return 0;
          }
        }
      };

      $scope.checkOrder = function(field) {
        if (!angular.isString(field) || field.length === 0) {
          return null;
        }

        if (field[0] === '=' || field[0] === '&' || field[0] === '@') {
          field = field.substring(1);
        }

        if ($scope.predicate === field && $scope.reverse) {
          return true;
        }

        if ($scope.predicate === field && !$scope.reverse) {
          return false;
        }

        return null;
      };

      $scope.checkEditModeOn = function(row, field) {
        if (row.hasOwnProperty('editMode')) {
          if (row.editMode.hasOwnProperty(field)) {
            return row.editMode[field];
          }
          row.editMode[field] = false;
        } else {
          row.editMode = {};
          row.editMode[field] = false;
        }

        return false;
      };

      $scope.startEditData = function($event, row, col) {
        if (!row.hasOwnProperty('editMode')) {
          row.editMode = {};
        }
        row.editMode[col.field] = true;
      };

      $scope.stopEditData = function($event, row, col) {
        if (!row.hasOwnProperty('editMode')) {
          row.editMode = {};
        }
        row.editMode[col.field] = false;

        if (angular.isFunction(col.trigger)) {
          col.trigger(row);
        }
      };

      $scope.isFunction = function(obj) {
        if (angular.isFunction(obj)) {
          return obj.apply(this, Array.prototype.slice.call(arguments, 1));
        }
        return obj;
      };

      updateData($scope.content);

      $scope.$on('downloadTableData', function(event, args) {
        downloadTableData();
      });

      function filenameValidation(str) {
        var encode = encodeURI(str);
        encodeURI(str)
          .replace(/\%20/g, '_')
          .replace(/[^a-z0-9-]/gi, '_');
        return decodeURI(encode);
      }

      function downloadTableData() {
        // prepare args
        var args = {};
        var keys = [];
        var header = [];

        $scope.header.forEach(function(item) {
          if (item.name !== '' && item.name.length > 0 && !item.hide) {
            // Push headers. If a currency column, append currency code to column header.
            if (
              item.components &&
              item.components.length > 0 &&
              item.components[0]['field'].includes('WithCurrencyCode')
            ) {
              header.push(item.name + ' (' + companyAndAccountFactory.requireSelectedAccount().currency + ')');
            } else {
              header.push(item.name);
            }

            //remove "&" before we store them
            if (item.field.indexOf('&') !== -1) {
              keys.push(item.field.slice(1, item.field.length));
            } else {
              if (item.components && item.components.length > 0) {
                var components = item.components[0];
                if (components.exportField) {
                  keys.push(components.exportField);
                } else {
                  keys.push(item.components[0].field);
                }
              } else {
                keys.push(item.field);
              }
            }
          }
        });

        args.keys = keys;
        args.header = header;
        args.data = $scope.data;

        if ($scope.currentState === 'campaigns.campaign') {
          var fastTableData = [];
          $scope.data.forEach(function(row, index) {
            // if breakdown, its array
            var rowArr = [];
            var toPushItem = {};
            if (Array.isArray(row)) {
              rowArr = row;
            } else {
              rowArr = [row];
            }
            rowArr.forEach(function(item, index) {
              toPushItem = item.filteredValues;

              var budgetValue = row.budgetWithCurrencyCode;
              toPushItem.budgetWithCurrencyCode = budgetValue;
              fastTableData.push(toPushItem);
            });
          });
          args.data = fastTableData;
        }

        var csv = dataExportFactory.convertArrayOfObjectsToCSV(args);
        var filename = 'export';
        if ($scope.currentState === 'campaigns.adgroup') {
          filename = filenameValidation(campaignManagerFactory.selectedAdGroup.name);
        } else if ($scope.currentState === 'campaigns.campaign') {
          filename = filenameValidation(campaignManagerFactory.selectedCampaign.name);
        }
        filename = filename + '.csv';
        if (csv === false || csv == null) {
          // args is not correct format
          $log.debug('table data prepared is not correct!');
        } else {
          generalUtilsFactory.downloadCSV(csv, filename);
        }
      }

      if (!$scope.checkedIds) {
        $scope.checkedIds = [];
      }

      $scope.checkboxChanged = function(row, checkbox, id) {
        addRemoveCheckedId(row.checked, id);
      };

      $scope.selectRow = function(row, id) {
        if ($scope.selectable) {
          row.checked = !row.checked;
          addRemoveCheckedId(row.checked, id);
        }
      };

      $scope.globalCheckboxChanged = function(item) {
        const setOfCheckedIds = new Set($scope.checkedIds);
        if ($scope.noPagination) {
          if (!item.checked) {
            customUnchecking(setOfCheckedIds);
          }
        } else {
          $scope.checkedIds.splice(0, $scope.checkedIds.length);
        }

        for (var row of $scope.paginatedData) {
          row.checked = item.checked;

          if (item.checked && !setOfCheckedIds.has(row.id)) {
            $scope.checkedIds.push(row.id);
          }
        }
      };

      $scope.onGlobalCheckboxChange = function(checked) {
        $scope.allChecked.checked = checked;
        $scope.header[0].checked = checked;

        for (let row of $scope.paginatedData) {
          row.checked = checked;
        }

        if (checked) {
          let checkedInPaginatedData = $scope.paginatedData.map(data => data.id);
          let allSelectedSet = new Set([...$scope.checkedIds, ...checkedInPaginatedData]);
          $scope.checkedIds = Array.from(allSelectedSet);
        } else {
          $scope.checkedIds = [];
        }
      };

      /**
       * This function will add or remove the id from the checkedId array
       */
      function addRemoveCheckedId(checked, id) {
        if (checked) {
          $scope.checkedIds.push(id);
        } else {
          let index = $scope.checkedIds.indexOf(id);
          $scope.checkedIds.splice(index, 1);
        }

        $scope.allChecked.checked = allCheckedInPage();
      }

      function allCheckedInPage() {
        if (!$scope.paginatedData.length) {
          return false;
        }
        for (let data of $scope.paginatedData) {
          if (!$scope.checkedIds.includes(data.id)) {
            return false;
          }
        }

        return true;
      }
      /**
       * This is a helper function for cases where pagination is done on backend and
       * we want to maintain a list of checked item's id across pages
       * */

      function customUnchecking(setOfCheckedIds) {
        $scope.paginatedData.forEach(item => {
          if (setOfCheckedIds.delete(item.id)) {
            item.checked = false;
          }
        });
        $scope.checkedIds.splice(0, $scope.checkedIds.length);
        setOfCheckedIds.forEach(id => $scope.checkedIds.push(id));
      }

      $scope.$watch(
        function() {
          return $scope.tableChanged;
        },
        function(newValue, oldValue) {
          if (!newValue) {
            return;
          }
          updateData($scope.content);
          $scope.tableChanged = false;
        },
        true
      );
    },
    link: function(scope, element, attrs) {
      var table = $(element).find('table');
      var scrollableContentElement = angular.element($window);
      if (scope.stickyHeader) {
        $timeout(function() {
          var offset = angular.element('.adgroup-table-actions').outerHeight();
          scope.stickyHeaderInstance = generalUtilsFactory.makeElementSticky(table, scrollableContentElement, offset);
        });
      }
    },
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.xad_table.html'
  };
}
