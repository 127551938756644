import * as React from 'react';
import { useObserver } from 'mobx-react-lite';
import { EditableFormField } from '../index';
import { TagsTextField as OriginalTagsTextField } from '../../reactComponents/Text/TagsTextField';

export interface TagsTextFieldProps {
  field: EditableFormField<string[]>;
  label: string;
  className?: string;
  separatorKeys?: string[];
  transformOnChange?: (value: string[]) => string[] | undefined;
  fullWidth?: boolean;
}

function defaultTransformOnChange() {
  return undefined;
}

export function TagsTextField(props: TagsTextFieldProps) {
  const { transformOnChange = defaultTransformOnChange } = props;
  const handleChange = React.useCallback((value: string[]) => props.field.update(transformOnChange(value) ?? value), [
    props.field.update,
    transformOnChange
  ]);
  return useObserver(() => (
    <OriginalTagsTextField
      className={props.className}
      label={props.label}
      value={props.field.value}
      onChange={handleChange}
      errorMessage={props.field.isValid ? undefined : props.field.validationMessage}
      separatorKeys={props.separatorKeys}
      fullWidth={props.fullWidth}
    />
  ));
}
