import { safeApply, searchInOrder } from '../../../factories/general_utils';

const creativeAdgroupModalState = {
  ADDED: 'added',
  LOADING: 'loading',
  ADD_CREATIVE: 'addCreative'
};

const placeholderTitle = 'Ad groups';

export class addCreativeAdgroupModalController {
  constructor(
    $scope,
    adGroupService,
    $uibModalInstance,
    data,
    creativeRepositoryService,
    creativeRepositoryState,
    universalAlert
  ) {
    this.scope = $scope;
    this.adGroupService = adGroupService;
    this.uibModalInstance = $uibModalInstance;

    this.placeholderTitle = placeholderTitle;

    this.selectedAdGroups = [];
    this.selectedCreativeAssets = data.selectedCreativeAssets;
    this.showAlert = false;
    this.alertType = 'error';
    this.creativeAssetsAdded = false;
    this.setUpAdGroupList();

    this.creativeRepositoryService = creativeRepositoryService;
    this.creativeRepositoryState = creativeRepositoryState;

    this.creativeAdgroupModalState = creativeAdgroupModalState;
    this.currentView = creativeAdgroupModalState.LOADING;
    this.universalAlert = universalAlert;

    this.maxiumumSelectionOfAdgroups = 10;
    this.selectAdGroup = this.selectAdGroup.bind(this);
    this.searchAdGroup = this.searchAdGroup.bind(this);
  }

  getSelectedTitle = () => {
    // TODO: We need to have a parameter in the directive which allows an option of appending the selections or not.
    // This is hacky
    return placeholderTitle;
  };

  async setUpAdGroupList() {
    try {
      this.adGroupList = await this.adGroupService.getListOfAdgroups();
      this.currentView = creativeAdgroupModalState.ADD_CREATIVE;
      this.scope.$apply();
    } catch (err) {
      console.error(err);
      this.raiseError('There was an error setting the list of ad groups.');
    }
  }

  deleteAdGroup(adGroupToDelete) {
    this.selectedAdGroups = this.selectedAdGroups.filter(currentAdGroup => {
      return currentAdGroup.adgroup_id != adGroupToDelete.adgroup_id;
    });

    this.adGroupList.forEach(adgroup => {
      if (adgroup.adgroup_id === adGroupToDelete.adgroup_id) {
        adgroup.checked = false;
      }
    });
    safeApply(this.scope);
  }

  selectAdGroup(items) {
    this.selectedAdGroups = items;

    if (this.selectedAdGroups.length >= this.maxiumumSelectionOfAdgroups) {
      this.raiseError(`You can only select up to ${this.maxiumumSelectionOfAdgroups} ad groups at a time.`);
    } else {
      this.showAlert = false;
    }
  }

  async searchAdGroup(searchText) {
    try {
      let resultAdgroupList = await this.adGroupService.getListOfAdgroups(searchText);
      return searchInOrder(searchText, resultAdgroupList, ['adgroup_id', 'adgroup_name']);
    } catch (e) {
      this.raiseError('There was an error retrieving the list of ad groups.');
      return [];
    }
  }

  async addToAdgroup() {
    try {
      this.currentView = creativeAdgroupModalState.LOADING;
      let selectedAdgroupsId = this.selectedAdGroups.map(adGroup => adGroup.adgroup_id);
      let response = await this.adGroupService.addCreativesAssetsToAdGroups(
        selectedAdgroupsId,
        this.selectedCreativeAssets
      );
      if (!response.success) {
        throw 'Failed to insert creative(s).';
      }
      this.currentView = creativeAdgroupModalState.ADDED;
    } catch (e) {
      this.currentView = creativeAdgroupModalState.ADD_CREATIVE;
      this.raiseError(
        'There was an error trying to add the selected creative(s) to your ad group(s). Please try again or contact support.'
      );
    }
    safeApply(this.scope);
  }

  close() {
    this.uibModalInstance.dismiss('cancel');
  }

  async closeAndUpdate() {
    try {
      this.currentView = creativeAdgroupModalState.LOADING;
      this.creativeRepositoryState.refreshCreativeAssetsList();
    } catch (e) {
      this.universalAlert.showAlert('There was an error trying to retrieve the latest creative assets.');
    } finally {
      this.uibModalInstance.dismiss('cancel');
    }
  }

  raiseError(error, type = 'error') {
    this.errorMessage = error;
    this.showAlert = true;
    this.alertType = type;
  }

  closeError() {
    this.showAlert = false;
  }
}

addCreativeAdgroupModalController.$inject = [
  '$scope',
  'adGroupService',
  '$uibModalInstance',
  'data',
  'creativeRepositoryService',
  'creativeRepositoryState',
  'universalAlert'
];
