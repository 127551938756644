searchFactory.$inject = [
  '$rootScope',
  '$q',
  '$http',
  '$state',
  'campaignManagerFactory',
  'companyAndAccountFactory',
  'userFactory',
  'featureFlagFactory'
];

/**
 * @param {ng.IQService} $q
 * @param {ng.IHttpService} $http
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {import('./types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {import('./types').UserFactoryService} userFactory
 */
export function searchFactory(
  $rootScope,
  $q,
  $http,
  $state,
  campaignManagerFactory,
  companyAndAccountFactory,
  userFactory,
  featureFlagFactory
) {
  var searchFactory = {};

  searchFactory.query = {
    text: ''
  };

  /**
   *
   * @param {string | null} query
   * @param {object | null} filters
   * @param {boolean} includeAllTab - If `true` parses the response and adds a
   *    `All` field else map the keys to value specified in nameMapping.
   * @returns
   */
  searchFactory.getSearchResults = function(query, filters, includeAllTab = true) {
    var d = $q.defer();
    var params = {
      searchText: query,
      cutoff_score: 0.7
    };
    angular.extend(params, filters);

    $http({ method: 'GET', url: '/data/search/entities', params: params }).then(
      function(response) {
        if (params.hasOwnProperty('entity_types')) {
          d.resolve(searchFactory.parseSearchResult(response.data, params.entity_types, includeAllTab));
        } else {
          d.resolve(response.data);
        }
      },
      function(data) {
        d.reject(data.message);
      }
    );

    return d.promise;
  };

  searchFactory.parseSearchResult = function(data, entityTypes, includeAllTab) {
    if (angular.equals({}, data)) {
      return data;
    }
    var nameMapping = {
      behavior: 'Behavior',
      custom_audience: 'Custom',
      segment: 'Segment',
      brand: 'Brand',
      category: 'Category',
      location_group: 'Location Group',
      city: 'City',
      state: companyAndAccountFactory.isAccountInCountry('CA') ? 'Province' : 'State',
      dma: 'DMA',
      county: 'county',
      zip_code: companyAndAccountFactory.isAccountInCountry('CA') ? 'Postal Code' : 'ZIP Code',
      latlng: 'LatLng',
      address: 'Address',
      adpackage: 'Ad Package'
    };
    var types = entityTypes.split(',');
    var result = {};
    var i;
    var name;

    function compare(a, b) {
      var nameA = a.name.toLowerCase();
      var nameB = b.name.toLowerCase();
      var comparision = 0;

      if (nameA > nameB) {
        comparision = 1;
      } else if (nameA < nameB) {
        comparision = -1;
      }
      return comparision;
    }

    // if there's only one entity type, it's not tabbed result, so just return the object
    if (types.length === 1) {
      return data[types[0]];
    } else if (includeAllTab) {
      result.All = [];
    }

    // loop through the types and combine the results to create 'all' tab
    for (i = 0; i < types.length; i++) {
      if (data.hasOwnProperty(types[i])) {
        name = nameMapping[types[i]];
        result[name] = data[types[i]];
        if (includeAllTab) result.All = result.All.concat(data[types[i]]);
      }
    }

    // sort the all tab based on name
    if (includeAllTab) result.All.sort(compare);
    return result;
  };

  searchFactory.selectEntityBySearch = function(entityType, entityObject) {
    var d = $q.defer();

    if (entityObject.tenant_id === undefined || entityObject.tenant_id === null) {
      d.reject();
      return d.promise;
    }

    // For all entities other than tenant, there are two parts
    // 1. If its same tenant search
    // 2. If its cross tenant search
    // For 1, to speed up the process we do not load companies and accounts again. Hence you will see 4th option as true in getCompaniesAndAccounts
    // For 2, As its cross tenant, we have to load companies and accounts.
    if (entityType === 'tenant') {
      if (entityObject.tenant_id === companyAndAccountFactory.selectedTenant?.tenant_id) {
        $state.go('campaigns.dashboard');
      } else {
        // switch tenant
        companyAndAccountFactory.clean();
        userFactory.clean();
        $state.go('root', { tenantId: entityObject.tenant_id }, { reload: true });
      }
    } else if (entityType === 'company') {
      if (entityObject.tenant_id === companyAndAccountFactory.selectedTenant?.tenant_id) {
        companyAndAccountFactory
          .getCompaniesAndAccounts(entityObject.tenant_id, entityObject.company_id, null)
          .then(function() {
            $rootScope.$broadcast('updateTenantCompanyAccount', entityObject.tenant_id);
            campaignManagerFactory.clean();
            campaignManagerFactory.loadCampaignsWithBudgetsForAccount().then(function() {
              d.resolve();

              if (featureFlagFactory.isFeatureEnabled('COMPANY_READ')) {
                $state.go('company', { selectedCompanyId: entityObject.company_id });
              } else {
                $state.go('campaigns.dashboard');
              }
            });
          });
      } else {
        companyAndAccountFactory.clean();
        userFactory.clean();
        companyAndAccountFactory
          .getCompaniesAndAccounts(entityObject.tenant_id, entityObject.company_id)
          .then(function() {
            $rootScope.$broadcast('updateTenantCompanyAccount', entityObject.tenant_id);
            campaignManagerFactory.clean();
            campaignManagerFactory.loadCampaignsWithBudgetsForAccount().then(function() {
              d.resolve();
              if (featureFlagFactory.isFeatureEnabled('COMPANY_READ')) {
                $state.go('company', { selectedCompanyId: entityObject.company_id }, { reload: true });
              } else {
                $state.go('campaigns.dashboard', { reload: true });
              }
            });
            d.resolve();
          });
      }
    } else if (entityType === 'account') {
      if (entityObject.tenant_id === companyAndAccountFactory.selectedTenant?.tenant_id) {
        companyAndAccountFactory
          .getCompaniesAndAccounts(entityObject.tenant_id, entityObject.company_id, entityObject.account_id)
          .then(function() {
            $rootScope.$broadcast('updateTenantCompanyAccount', entityObject.tenant_id);
            campaignManagerFactory.clean();
            campaignManagerFactory.loadCampaignsWithBudgetsForAccount().then(function() {
              d.resolve();
              if (
                featureFlagFactory.isFeatureEnabled('COMPANY_READ') &&
                companyAndAccountFactory.selectedCompany &&
                companyAndAccountFactory.selectedAccount
              ) {
                $state.go('account', {
                  selectedCompanyId: companyAndAccountFactory.selectedCompany.id,
                  selectedAccountId: companyAndAccountFactory.selectedAccount.id
                });
              } else {
                $state.go('campaigns.dashboard');
              }
            });
          });
      } else {
        companyAndAccountFactory.clean();
        userFactory.clean();
        companyAndAccountFactory
          .getCompaniesAndAccounts(entityObject.tenant_id, entityObject.company_id, entityObject.account_id)
          .then(function() {
            $rootScope.$broadcast('updateTenantCompanyAccount', entityObject.tenant_id);
            campaignManagerFactory.clean();
            campaignManagerFactory.loadCampaignsWithBudgetsForAccount().then(function() {
              d.resolve();
              if (
                featureFlagFactory.isFeatureEnabled('COMPANY_READ') &&
                companyAndAccountFactory.selectedCompany &&
                companyAndAccountFactory.selectedAccount
              ) {
                $state.go(
                  'account',
                  {
                    selectedCompanyId: companyAndAccountFactory.selectedCompany.id,
                    selectedAccountId: companyAndAccountFactory.selectedAccount.id
                  },
                  { reload: true }
                );
              } else {
                $state.go('campaigns.dashboard', { reload: true });
              }
            });
          });
      }
    } else if (entityType === 'campaign') {
      if (entityObject.tenant_id === companyAndAccountFactory.selectedTenant?.tenant_id) {
        if (
          entityObject.company_id === companyAndAccountFactory.selectedCompany?.id &&
          entityObject.account_id === companyAndAccountFactory.selectedAccount?.id
        ) {
          d.resolve();
          $state.go('campaigns.campaign', { campaignId: entityObject.campaign_id });
        } else {
          companyAndAccountFactory
            .getCompaniesAndAccounts(entityObject.tenant_id, entityObject.company_id, entityObject.account_id)
            .then(function() {
              $rootScope.$broadcast('updateTenantCompanyAccount', entityObject.tenant_id);
              campaignManagerFactory.clean();
              campaignManagerFactory.loadCampaignsWithBudgetsForAccount().then(function() {
                d.resolve();
                $state.go('campaigns.campaign', { campaignId: entityObject.campaign_id });
              });
            });
        }
      } else {
        companyAndAccountFactory.clean();
        userFactory.clean();
        companyAndAccountFactory
          .getCompaniesAndAccounts(entityObject.tenant_id, entityObject.company_id, entityObject.account_id)
          .then(function() {
            $rootScope.$broadcast('updateTenantCompanyAccount', entityObject.tenant_id);
            campaignManagerFactory.clean();
            campaignManagerFactory.loadCampaignsWithBudgetsForAccount().then(function() {
              d.resolve();
              $state.go('campaigns.campaign', { campaignId: entityObject.campaign_id }, { reload: true });
            });
          });
      }
    } else if (entityType === 'adgroup' || entityType === 'creative') {
      if (entityObject.tenant_id === companyAndAccountFactory.selectedTenant?.tenant_id) {
        if (
          entityObject.company_id === companyAndAccountFactory.selectedCompany?.id &&
          entityObject.account_id === companyAndAccountFactory.selectedAccount?.id
        ) {
          d.resolve();
          $state.go('campaigns.adgroup', { campaignId: entityObject.campaign_id, adGroupId: entityObject.adgroup_id });
        } else {
          companyAndAccountFactory
            .getCompaniesAndAccounts(entityObject.tenant_id, entityObject.company_id, entityObject.account_id)
            .then(function() {
              $rootScope.$broadcast('updateTenantCompanyAccount', entityObject.tenant_id);
              campaignManagerFactory.clean();
              campaignManagerFactory.loadCampaignsWithBudgetsForAccount().then(function() {
                d.resolve();
                $state.go('campaigns.adgroup', {
                  campaignId: entityObject.campaign_id,
                  adGroupId: entityObject.adgroup_id
                });
              });
            });
        }
      } else {
        companyAndAccountFactory.clean();
        userFactory.clean();
        companyAndAccountFactory
          .getCompaniesAndAccounts(entityObject.tenant_id, entityObject.company_id, entityObject.account_id)
          .then(function() {
            $rootScope.$broadcast('updateTenantCompanyAccount', entityObject.tenant_id);
            campaignManagerFactory.clean();
            campaignManagerFactory.loadCampaignsWithBudgetsForAccount().then(function() {
              d.resolve();
              $state.go(
                'campaigns.adgroup',
                {
                  campaignId: entityObject.campaign_id,
                  adGroupId: entityObject.adgroup_id
                },
                { reload: true }
              );
            });
          });
      }
    }

    return d.promise;
  };

  searchFactory.getGeoCodedAddressObjFromPlaceId = function(address) {
    var d = $q.defer();

    var params = {
      address: address
    };

    $http({ method: 'GET', url: '/data/search/latLngFromGooglePlaceId', params: params })
      .then(function({ data, status, headers, config }) {
        d.resolve(data);
      })
      .catch(function({ data }) {
        d.reject(data.message);
      });

    return d.promise;
  };

  searchFactory.clearSearchQueryText = () => {
    searchFactory.query.text = '';
  };

  return searchFactory;
}
