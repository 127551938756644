import { ReportSchedulerModel, Report } from '../model/report_scheduler_model';
import { observable, computed, action } from 'mobx';
import { SortingDirection } from '../../../components/reactComponents/Table/BaseTable';
import { virtualListFromArray } from '../../../utils/virtuallist/array_virtual_list';

function fieldSelector<T, K extends keyof T>(field: K): (item: T) => T[K] {
  return item => item[field];
}

function sort<T>(array: T[], sortDirection: SortingDirection, selector: (item: T) => any) {
  return [...array].sort((a, b) => {
    const avalue = selector(a);
    const bvalue = selector(b);
    if (avalue.localeCompare) {
      return avalue.localeCompare(bvalue) * sortDirection;
    } else {
      return (avalue - bvalue) * sortDirection;
    }
  });
}

export interface Filter {
  searchName: string | null;
  reportStatus: Report['status'] | null;
}

// write unit test for this
function filterReports(reports: Report[], searchName: string | null, reportStatus: Report['status'] | null) {
  let filteredReports: Report[] = reports.filter(report => {
    const matchesName = searchName ? report.report_name.toLowerCase().includes(searchName.toLowerCase()) : true;
    const matchesStatus = reportStatus ? report.status === reportStatus : true;
    return matchesName && matchesStatus;
  });
  return filteredReports;
}

export class ReportListPageModel {
  @observable.ref reports: Report[] | null = null;
  @observable.ref sortField: keyof Report | null = null;
  @observable.ref sortDirection = SortingDirection.ASCENDING;
  @observable loading: boolean = false;

  @observable searchTextField: string = '';
  @observable searchStatusFilter: Report['status'] | null = null;

  reportSchedulerModel: ReportSchedulerModel | null = null;

  constructor(reportSchedulerModel: ReportSchedulerModel) {
    this.reportSchedulerModel = reportSchedulerModel;
  }

  @computed get reportsVirtualList() {
    if (this.reportSchedulerModel?.reports) {
      let reports = this.reportSchedulerModel.reports;
      if (this.sortField) {
        reports = sort(reports, this.sortDirection, fieldSelector(this.sortField));
      }
      reports = filterReports(reports, this.searchTextField, this.searchStatusFilter);
      return virtualListFromArray(reports);
    }
    return null;
  }

  @action updateSearchTextField = (searchText: string) => {
    this.searchTextField = searchText;
  };

  @action updateStatusFilter(status: Report['status'] | null) {
    this.searchStatusFilter = status;
  }

  @computed get searchText() {
    return this.searchTextField;
  }

  @computed get statusFilter() {
    return this.searchStatusFilter;
  }

  @action sortReportList(field: keyof Report) {
    if (this.sortDirection == SortingDirection.DESCENDING || this.sortField !== field) {
      this.sortDirection = SortingDirection.ASCENDING;
      this.sortField = field;
    } else {
      this.sortDirection = SortingDirection.DESCENDING;
    }
  }
}
