import { observable } from 'mobx';
import { userFactory } from '../../../utils/services';
import { DetailedReport, ReportInput, ReportDeliveryScheduleFrequency } from '../../../api/http/reports';
import { format } from 'date-fns';
import { getCorrectedJSdateFromString } from '../../../factories/general_utils';

export interface Organization {
  id: number;
  name: string;
}

export interface Account extends Organization {
  organization_id: number;
}

export interface Campaign extends Account {
  account_id: number;
}

export class ReportModel {
  id: number | null;
  @observable name: string;
  @observable type: string;
  @observable startDate: Date | null;
  @observable endDate: Date | null;
  @observable recipients: string[];
  @observable.shallow organizations: Organization[];
  @observable.shallow accounts: Account[];
  @observable.shallow campaigns: Campaign[];
  @observable enableDelivery: boolean;
  @observable frequency: ReportDeliveryScheduleFrequency;
  @observable sendNow: boolean;

  constructor(data?: DetailedReport) {
    this.id = null;
    this.name = '';
    this.type = 'daily_trend';
    this.startDate = null;
    this.endDate = null;
    this.recipients = [userFactory.currentLoggedInUser.username];
    this.organizations = [];
    this.accounts = [];
    this.campaigns = [];
    this.enableDelivery = true;
    this.frequency = ReportDeliveryScheduleFrequency.WEEKLY;
    this.sendNow = false;

    if (data) this.fromDetailedReport(data);
  }

  fromDetailedReport(data: DetailedReport) {
    this.id = data.report_id;
    this.name = data.report_name;
    this.type = data.report_type;
    this.startDate = getCorrectedJSdateFromString(data.start_date);
    this.endDate = getCorrectedJSdateFromString(data.end_date);
    this.recipients = data.email_recipients;
    this.organizations = data.organizations;
    this.accounts = data.accounts;
    this.campaigns = data.campaigns;
    this.enableDelivery = data.delivery_schedule?.frequency !== undefined;
    this.frequency = data.delivery_schedule?.frequency ?? ReportDeliveryScheduleFrequency.WEEKLY;
    this.sendNow = false;
  }

  static createDateString(dateObj) {
    return format(dateObj, 'yyyy-MM-dd');
  }

  getReportInput(): ReportInput | null {
    if (!this.startDate || !this.endDate) {
      return null;
    }

    return {
      report_name: this.name,
      report_type: this.type,
      start_date: ReportModel.createDateString(this.startDate),
      end_date: ReportModel.createDateString(this.endDate),
      email_recipients: this.recipients.map(recipient => recipient),
      organization_ids: this.organizations.map(org => org.id),
      account_ids: this.accounts.map(account => account.id),
      campaign_ids: this.campaigns.map(campaign => campaign.id),
      delivery_schedule: { frequency: this.enableDelivery ? this.frequency : null },
      send_now: this.sendNow
    };
  }
}
