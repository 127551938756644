xadBarChart.$inject = ['$timeout'];

export function xadBarChart($timeout) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      active: '=',
      changed: '=',
      chartProgress: '=',
      chartClass: '=',
      chartData: '=',
      heading: '@' // title displayed to the user (optional)
    },
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.xad-bar-chart.html',
    controller: /** @ngInject */ function($scope) {},
    link: function(scope, element, attrs) {
      var chartContainer = element[0].querySelector('.chart');

      function createChart() {
        scope.chart = Highcharts.chart(chartContainer, {
          title: {
            text: ''
          },
          chart: {
            marginBottom: 50,
            height: 370,
            type: 'column'
          },
          xAxis: {
            type: 'category',
            labels: {
              style: {
                fontSize: '14px',
                fontFamily: 'Poppins',
                fontWeight: '400',
                color: '#0A3F36',
                fill: '#0A3F36'
              }
            },
            tickColor: '#FFFFFF',
            tickmarkPlacement: 'on'
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Visits After Impressions',
              align: 'high',
              rotation: 0,
              offset: -110,
              y: -30,
              style: {
                color: '#3c89bb',
                fontSize: '14px'
              }
            },
            labels: {
              style: {
                color: '#3c89bb',
                fontSize: '14px'
              }
            },
            lineColor: '#e6e6e6'
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            column: {
              borderRadiusTopLeft: 100,
              borderRadiusTopRight: 100
            }
          },
          series: [
            {
              type: 'column',
              name: 'Visits After Impressions',
              data: scope.chartData,
              dataLabels: {
                enabled: false
              },
              pointWidth: 50
            }
          ],
          tooltip: {
            backgroundColor: '#ffffff',
            borderWidth: 1,
            borderColor: '#BCF1E9',
            borderRadius: 10,
            padding: 24,
            style: {
              color: '#0A3F36',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '41.6px',
              fontFamily: 'Roboto',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.05)'
            }
          },
          exporting: {
            enabled: true,
            scale: 1,
            sourceWidth: 800,
            sourceHeight: 500,
            buttons: {
              contextButton: {
                symbol: 'url(/ui/images/export-icon-download.svg)',
                menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF'],
                text: 'Download',
                verticalAlign: 'top',
                height: 40,
                symbolY: 20,
                symbolX: 20,
                y: -43,
                x: 5
              }
            },
            chartOptions: {
              chart: {
                marginTop: 70,
                marginRight: 50,
                marginBottom: 50,
                marginLeft: 80
              }
            }
          },
          credits: {
            enabled: false
          }
        });
        $timeout(function() {
          scope.chart.reflow();
        });
      }

      // Listen for changes
      scope.$watch('changed', function(newValue, oldValue) {
        if (newValue === true) {
          if (scope.chart) {
            scope.chart.destroy();
          }

          createChart();
          scope.changed = false;
        }
      });

      // When chart becomes active, fit the chart inside its container
      scope.$watch('active', function(newValue, oldValue) {
        if (newValue === true) {
          if (!scope.chart) {
            return;
          }
          $timeout(function() {
            scope.chart.reflow();
          });
        }
      });
    }
  };
}
