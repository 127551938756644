import { xadPagination } from '../../components/xadPagination/xadPaginationDirective';
import { xadTable } from '../../components/xadTable/xadTable';
import { xadCoreConfig, $xadUtil } from '../xad';
import { capitalize } from '../filters/capitalize.fil';
import { customNumber } from '../filters/custom_number.fil';
import { join } from '../filters/join.fil';
import { limit } from '../filters/limit.fil';
import { percentage } from '../filters/percentage.fil';
import { percentageNotMultiplied100 } from '../filters/percentage_not_multiplied_100.fil';
import { shortnumber } from '../filters/short_number.fil';

export default angular
  .module('xad.core', [])
  .config(xadCoreConfig)
  .filter({
    capitalize,
    customNumber,
    join,
    limit,
    percentage,
    percentageNotMultiplied100,
    shortnumber
  })
  .factory({
    $xadUtil
  })
  .directive({
    xadPagination,
    xadTable
  });
