import React from 'react';
import PropTypes from 'prop-types';

import { RedirectIcon } from '../../../components/reactComponents/Icons';

interface RedirectButtonProps {
  label: string;
  redirectTo: string;
}

export function RedirectButton({ label, redirectTo }: RedirectButtonProps) {
  return (
    <a className="redirect-button" target="_blank" href={redirectTo}>
      <div>
        <RedirectIcon className="redirect-button__icon" viewBox="0 0 28 28" />
      </div>
      <p className="button-v2  button--tertiary-green">{label}</p>
    </a>
  );
}

RedirectButton.propTypes = {
  label: PropTypes.string,
  redirectTo: PropTypes.string
};
