import React from 'react';
import { AudienceTaxonomyResponseType } from '../../../../api/http/audience';

import { AccordionItem } from './AccordionItem';
import { Audience } from '../../../../api/http/data.generated';
import { SelectableTaxonomyItem } from './interfaces';

interface AudienceLibraryProps {
  audienceTaxonomyTreeData: AudienceTaxonomyResponseType['taxonomy'];
  includeItemDescription: boolean;
  selectedAudiences: Audience[];
  selectedAudienceIds: Set<string>;
  showLibrary: boolean;
  onCheckboxChange: (item: SelectableTaxonomyItem) => void;
}

export function AudienceLibrary({
  audienceTaxonomyTreeData,
  includeItemDescription,
  selectedAudiences,
  selectedAudienceIds,
  showLibrary,
  onCheckboxChange
}: AudienceLibraryProps) {
  return (
    <div className="audience-library">
      {audienceTaxonomyTreeData!.map((item, index) => (
        <AccordionItem
          key={index}
          includeItemDescription={includeItemDescription}
          item={item}
          selectedAudiences={selectedAudiences}
          selectedAudienceIds={selectedAudienceIds}
          showLibrary={showLibrary}
          onCheckboxChange={onCheckboxChange}
        />
      ))}
    </div>
  );
}
