/**
 * DO NOT EDIT THIS FILE MANUALLY!
 */
import * as t from 'io-ts';
import * as tx from '../def/io-ts';
import { http } from '../def';

export type Entity = t.TypeOf<typeof Entity>;
export const Entity = t.type(
  {
    id: t.Integer,
    name: t.string
  },
  'Entity'
);
export type Account = t.TypeOf<typeof Account>;
export const Account = t.intersection(
  [
    Entity,
    t.type({
      /**
       * Organization id.
       */
      organization_id: t.Integer
    })
  ],
  'Account'
);
/**
 * @example "behavior"
 */
export enum AdGroupAudienceTargeting_type {
  BEHAVIOR = 'behavior',
  CUSTOM_AUDIENCE = 'custom_audience',
  SEGMENT = 'segment',
  BRAND = 'brand',
  CATEGORY = 'category',
  LOCATION_GROUP = 'location_group'
}
export type AdGroupAudienceTargeting = t.TypeOf<typeof AdGroupAudienceTargeting>;
export const AdGroupAudienceTargeting = t.intersection(
  [
    t.type({
      /**
       * @example "behavior"
       */
      type: tx.enumeration(AdGroupAudienceTargeting_type, 'AdGroupAudienceTargeting_type')
    }),
    t.partial({
      id: tx.nullable(t.Integer),
      name: tx.nullable(t.string),
      sicCode: tx.nullable(t.string),
      sensitivity: tx.nullable(t.Integer),
      backend_key: tx.nullable(t.string),
      size: tx.nullable(t.Integer)
    })
  ],
  'AdGroupAudienceTargeting'
);
export type AdGroupBudget = t.TypeOf<typeof AdGroupBudget>;
export const AdGroupBudget = t.partial(
  {
    /**
     * @example "1980-01-31"
     */
    start_date: tx.nullable(tx.DateString),
    /**
     * @example "1980-01-31"
     */
    end_date: tx.nullable(tx.DateString),
    budget: tx.nullable(t.number)
  },
  'AdGroupBudget'
);
export type DayPartingHour = t.TypeOf<typeof DayPartingHour>;
export const DayPartingHour = t.partial(
  {
    status: tx.nullable(t.boolean),
    index: tx.nullable(t.Integer)
  },
  'DayPartingHour'
);
export type DayParting = t.TypeOf<typeof DayParting>;
export const DayParting = t.partial(
  {
    hours: tx.nullable(t.array(DayPartingHour))
  },
  'DayParting'
);
export type Pixel = t.TypeOf<typeof Pixel>;
export const Pixel = t.partial(
  {
    account_id: tx.nullable(t.Integer),
    /**
     * @example "https://www.groundtruth.com"
     */
    url: tx.nullable(tx.UrlString),
    url_v2: tx.nullable(t.string),
    id: tx.nullable(t.Integer),
    alias: tx.nullable(t.string),
    /**
     * @example "https://www.groundtruth.com"
     */
    website_url: tx.nullable(tx.UrlString)
  },
  'Pixel'
);
export type AdGroupBudgetTargeting = t.TypeOf<typeof AdGroupBudgetTargeting>;
export const AdGroupBudgetTargeting = t.partial(
  {
    dayParting: tx.nullable(t.array(DayParting)),
    adGroupPixels: tx.nullable(t.array(Pixel))
  },
  'AdGroupBudgetTargeting'
);
/**
 * @example "CPM"
 */
export enum AdGroupBudgetUpdate_bidType {
  CPM = 'CPM',
  CPC = 'CPC',
  CPV = 'CPV'
}
/**
 * @example "BILLABLE"
 */
export enum AdGroupBudgetUpdate_billability_type {
  BILLABLE = 'BILLABLE',
  ADDED_VALUE = 'ADDED_VALUE',
  MAKE_GOOD = 'MAKE_GOOD',
  SURVEY = 'SURVEY',
  TEST = 'TEST'
}
export type AdPackage = t.TypeOf<typeof AdPackage>;
export const AdPackage = t.partial(
  {
    name: tx.nullable(t.string),
    id: tx.nullable(t.Integer)
  },
  'AdPackage'
);
/**
 * @example "USAMP"
 */
export enum AdGroupBudgetUpdate_svlVendorsItem {
  USAMP = 'USAMP',
  PLACED = 'PLACED',
  LIVERAMP = 'LIVERAMP',
  XAD = 'XAD',
  NEUSTAR = 'NEUSTAR',
  CARDLYTICS = 'CARDLYTICS',
  AFFINITY = 'AFFINITY'
}
/**
 * @example "ACCELERATED"
 */
export enum AdGroupBudgetUpdate_pacingMode {
  ACCELERATED = 'ACCELERATED',
  ASAP = 'ASAP',
  DEFAULT = 'DEFAULT'
}
export type AdGroupBudgetUpdate = t.TypeOf<typeof AdGroupBudgetUpdate>;
export const AdGroupBudgetUpdate = t.intersection(
  [
    t.type({
      /**
       * @example "CPM"
       */
      bidType: tx.enumeration(AdGroupBudgetUpdate_bidType, 'AdGroupBudgetUpdate_bidType'),
      bidRate: t.number
    }),
    t.partial({
      budget_carryover: tx.nullable(t.boolean),
      budgetPadding: tx.nullable(t.number),
      gross_bid_rate: tx.nullable(t.number),
      /**
       * @example "BILLABLE"
       */
      billability_type: tx.nullable(
        tx.enumeration(AdGroupBudgetUpdate_billability_type, 'AdGroupBudgetUpdate_billability_type')
      ),
      clientIoName: tx.nullable(t.string),
      clientIoNumber: tx.nullable(t.string),
      time_period_budgets: tx.nullable(t.array(AdGroupBudget)),
      adPackage: tx.nullable(t.array(AdPackage)),
      svlVendors: tx.nullable(
        t.array(tx.enumeration(AdGroupBudgetUpdate_svlVendorsItem, 'AdGroupBudgetUpdate_svlVendorsItem'))
      ),
      dailyImp: tx.nullable(t.Integer),
      targeting: tx.nullable(AdGroupBudgetTargeting),
      is_CPV: tx.nullable(t.boolean),
      /**
       * @example "ACCELERATED"
       */
      pacingMode: tx.nullable(tx.enumeration(AdGroupBudgetUpdate_pacingMode, 'AdGroupBudgetUpdate_pacingMode'))
    })
  ],
  'AdGroupBudgetUpdate'
);
export type AdGroupCreateRequest = t.TypeOf<typeof AdGroupCreateRequest>;
export const AdGroupCreateRequest = t.partial(
  {
    name: tx.nullable(t.string),
    creativeSize: tx.nullable(t.string)
  },
  'AdGroupCreateRequest'
);
/**
 * @example "app"
 */
export enum AdGroupDeviceTypeUpdate_device_typesItem {
  APP = 'app',
  WEB = 'web',
  DESKTOP = 'desktop',
  CTV = 'ctv',
  OTT = 'ott',
  AUDIO_PODCAST = 'audio-podcast',
  AUDIO_STREAMING = 'audio-streaming'
}
export type AdGroupDeviceTypeUpdate = t.TypeOf<typeof AdGroupDeviceTypeUpdate>;
export const AdGroupDeviceTypeUpdate = t.type(
  {
    device_types: t.array(
      tx.enumeration(AdGroupDeviceTypeUpdate_device_typesItem, 'AdGroupDeviceTypeUpdate_device_typesItem')
    )
  },
  'AdGroupDeviceTypeUpdate'
);
/**
 * @example "city"
 */
export enum AdGroupLocationFilter_type {
  CITY = 'city',
  STATE = 'state',
  ZIP_CODE = 'zip_code',
  DMA = 'dma',
  ADDRESS = 'address',
  LATLNG = 'latlng',
  COUNTY = 'county'
}
export type AdGroupLocationFilter = t.TypeOf<typeof AdGroupLocationFilter>;
export const AdGroupLocationFilter = t.intersection(
  [
    t.type({
      /**
       * @example "city"
       */
      type: tx.enumeration(AdGroupLocationFilter_type, 'AdGroupLocationFilter_type')
    }),
    t.partial({
      geotarget_id: tx.nullable(t.Integer),
      county_fips: tx.nullable(t.string),
      city: tx.nullable(t.string),
      state: tx.nullable(t.string),
      dmacode: tx.nullable(t.Integer),
      address: tx.nullable(t.string),
      zipcode: tx.nullable(t.string),
      lat: tx.nullable(t.number),
      lng: tx.nullable(t.number),
      radius: tx.nullable(t.number),
      name: tx.nullable(t.string),
      /**
       * Whether or not to exclude this location from targeting
       */
      exclude: tx.nullable(t.boolean)
    })
  ],
  'AdGroupLocationFilter'
);
export type AdGroup = t.TypeOf<typeof AdGroup>;
export const AdGroup = t.type(
  {
    adgroup_id: t.Integer,
    adgroup_name: t.string
  },
  'AdGroup'
);
/**
 * @example "behavior"
 */
export enum AdGroupNeighborhoodTargeting_type {
  BEHAVIOR = 'behavior',
  BRAND = 'brand',
  CATEGORY = 'category',
  LOCATION_GROUP = 'location_group'
}
export type AdGroupNeighborhoodTargeting = t.TypeOf<typeof AdGroupNeighborhoodTargeting>;
export const AdGroupNeighborhoodTargeting = t.intersection(
  [
    t.type({
      /**
       * @example "behavior"
       */
      type: tx.enumeration(AdGroupNeighborhoodTargeting_type, 'AdGroupNeighborhoodTargeting_type')
    }),
    t.partial({
      id: tx.nullable(t.Integer),
      name: tx.nullable(t.string),
      proximity: tx.nullable(t.Integer),
      sensitivity: tx.nullable(t.Integer)
    })
  ],
  'AdGroupNeighborhoodTargeting'
);
/**
 * @example "brand"
 */
export enum AdGroupOnPremiseTargeting_type {
  BRAND = 'brand',
  CATEGORY = 'category',
  LOCATION_GROUP = 'location_group'
}
export type AdGroupOnPremiseTargeting = t.TypeOf<typeof AdGroupOnPremiseTargeting>;
export const AdGroupOnPremiseTargeting = t.intersection(
  [
    t.type({
      /**
       * @example "brand"
       */
      type: tx.enumeration(AdGroupOnPremiseTargeting_type, 'AdGroupOnPremiseTargeting_type')
    }),
    t.partial({
      id: tx.nullable(t.Integer),
      name: tx.nullable(t.string),
      proximity: tx.nullable(t.unknown),
      sensitivity: tx.nullable(t.Integer)
    })
  ],
  'AdGroupOnPremiseTargeting'
);
export type AdGroupProductUpdate = t.TypeOf<typeof AdGroupProductUpdate>;
export const AdGroupProductUpdate = t.intersection(
  [
    t.type({
      product: t.string
    }),
    t.partial({
      status: tx.nullable(t.string),
      name: tx.nullable(t.string)
    })
  ],
  'AdGroupProductUpdate'
);
export type PublisherSettings = t.TypeOf<typeof PublisherSettings>;
export const PublisherSettings = t.partial(
  {
    parentChannel_id: tx.nullable(t.Integer),
    name: tx.nullable(t.string),
    constraint: tx.nullable(t.string),
    selected: tx.nullable(t.boolean),
    iabCode: tx.nullable(t.string),
    id: tx.nullable(t.Integer)
  },
  'PublisherSettings'
);
/**
 * @example "iOS"
 */
export enum AdGroupTargeting_technographicsItem {
  IOS = 'iOS',
  ANDROID = 'Android',
  OTHER = 'Other'
}
export type Carrier = t.TypeOf<typeof Carrier>;
export const Carrier = t.partial(
  {
    carrier_code: tx.nullable(t.string),
    selected: tx.nullable(t.boolean)
  },
  'Carrier'
);
/**
 * @example "App"
 */
export enum AdGroupTargeting_deviceTypesItem {
  APP = 'App',
  MOBILE_WEB = 'Mobile Web',
  DESKTOP = 'Desktop'
}
/**
 * @example "18-24"
 */
export enum AdGroupTargeting_demographicsAgeItem {
  _18_24 = '18-24',
  _25_34 = '25-34',
  _35_44 = '35-44',
  _45_54 = '45-54',
  _55_64 = '55-64',
  _65_PLUS = '65+',
  UNKNOWN = 'Unknown'
}
/**
 * @example "200-"
 */
export enum AdGroupTargeting_demographicsIncomeItem {
  _200_ = '200-',
  _150_200 = '150-200',
  _100_150 = '100-150',
  _50_100 = '50-100',
  _50 = '-50'
}
/**
 * @example "aa"
 */
export enum AdGroupTargeting_demographicsEthnicityItem {
  AA = 'aa',
  AS = 'as',
  HL = 'hl'
}
/**
 * @example "residential"
 */
export enum AdGroupTargeting_neighborhood_typesItem {
  RESIDENTIAL = 'residential',
  BUSINESS = 'business'
}
export type WeatherAlert = t.TypeOf<typeof WeatherAlert>;
export const WeatherAlert = t.type(
  {
    phenomena: t.string
  },
  'WeatherAlert'
);
export type WeatherCondition = t.TypeOf<typeof WeatherCondition>;
export const WeatherCondition = t.type(
  {
    condition: t.Integer,
    time_constraint: t.string
  },
  'WeatherCondition'
);
export type WeatherHumidity = t.TypeOf<typeof WeatherHumidity>;
export const WeatherHumidity = t.type(
  {
    operator: t.string,
    value: t.Integer,
    time_constraint: t.string
  },
  'WeatherHumidity'
);
export type WeatherPressure = t.TypeOf<typeof WeatherPressure>;
export const WeatherPressure = t.type(
  {
    operator: t.string,
    value: t.Integer,
    time_constraint: t.string
  },
  'WeatherPressure'
);
export type WeatherRainProbability = t.TypeOf<typeof WeatherRainProbability>;
export const WeatherRainProbability = t.type(
  {
    operator: t.string,
    value: t.Integer,
    time_constraint: t.string
  },
  'WeatherRainProbability'
);
export type WeatherTemperature = t.TypeOf<typeof WeatherTemperature>;
export const WeatherTemperature = t.intersection(
  [
    t.type({
      operator: t.string,
      time_constraint: t.string
    }),
    t.partial({
      value: tx.nullable(t.array(t.Integer)),
      feels_like: tx.nullable(t.boolean)
    })
  ],
  'WeatherTemperature'
);
export type WeatherWindSpeed = t.TypeOf<typeof WeatherWindSpeed>;
export const WeatherWindSpeed = t.type(
  {
    operator: t.string,
    value: t.Integer,
    time_constraint: t.string
  },
  'WeatherWindSpeed'
);
export type AdGroupWeatherTargeting = t.TypeOf<typeof AdGroupWeatherTargeting>;
export const AdGroupWeatherTargeting = t.partial(
  {
    weather_alert: tx.nullable(t.array(WeatherAlert)),
    weather_condition: tx.nullable(t.array(WeatherCondition)),
    weather_humidity: tx.nullable(t.array(WeatherHumidity)),
    weather_pressure: tx.nullable(t.array(WeatherPressure)),
    weather_rain_probability: tx.nullable(t.array(WeatherRainProbability)),
    weather_temperature: tx.nullable(t.array(WeatherTemperature)),
    weather_wind_speed: tx.nullable(t.array(WeatherWindSpeed))
  },
  'AdGroupWeatherTargeting'
);
/**
 * @example "location_group"
 */
export enum DriveToLocation_type {
  LOCATION_GROUP = 'location_group',
  BRAND = 'brand'
}
export type DriveToLocation = t.TypeOf<typeof DriveToLocation>;
export const DriveToLocation = t.intersection(
  [
    t.type({
      /**
       * @example "location_group"
       */
      type: tx.enumeration(DriveToLocation_type, 'DriveToLocation_type'),
      id: t.Integer
    }),
    t.partial({
      name: tx.nullable(t.string)
    })
  ],
  'DriveToLocation'
);
export type BusinessProfile = t.TypeOf<typeof BusinessProfile>;
export const BusinessProfile = t.partial(
  {
    isNew: tx.nullable(t.boolean),
    id: tx.nullable(t.Integer),
    name: tx.nullable(t.string),
    address_count: tx.nullable(t.Integer)
  },
  'BusinessProfile'
);
/**
 * @example "Male"
 */
export enum AdGroupTargeting_demographicsGenderItem {
  MALE = 'Male',
  FEMALE = 'Female',
  UNKNOWN = 'Unknown'
}
/**
 * @example "dv"
 */
export enum AdGroupTargeting_viewabilityDataProvider {
  DV = 'dv',
  MOAT = 'moat'
}
export type Publisher = t.TypeOf<typeof Publisher>;
export const Publisher = t.partial(
  {
    selected: tx.nullable(t.boolean),
    displayText: tx.nullable(t.string),
    id: tx.nullable(t.Integer),
    cbd_supported: tx.nullable(t.boolean),
    pmpDisabled: tx.nullable(t.boolean)
  },
  'Publisher'
);
export type PublisherTier = t.TypeOf<typeof PublisherTier>;
export const PublisherTier = t.partial(
  {
    bidrate: tx.nullable(t.number),
    bidtype: tx.nullable(t.string),
    publishers: tx.nullable(t.array(Publisher)),
    tier: tx.nullable(t.string)
  },
  'PublisherTier'
);
export type TierPublisherSettings = t.TypeOf<typeof TierPublisherSettings>;
export const TierPublisherSettings = t.partial(
  {
    runOfNetwork: tx.nullable(t.boolean),
    tiers: tx.nullable(t.array(PublisherTier))
  },
  'TierPublisherSettings'
);
export type CpgProducts = t.TypeOf<typeof CpgProducts>;
export const CpgProducts = t.intersection(
  [
    t.type({
      upc: t.string
    }),
    t.partial({
      name: tx.nullable(t.string)
    })
  ],
  'CpgProducts'
);
/**
 * Note: "default" will only be given when the product type has not been set yet
 * @example "audience"
 */
export enum AdGroupTargeting_product {
  AUDIENCE = 'audience',
  GEOTARGETING = 'geotargeting',
  ONPREMISE = 'onpremise',
  NEIGHBORHOOD = 'neighborhood',
  WEATHER = 'weather',
  DEFAULT = 'default'
}
export type TargetingCountry = t.TypeOf<typeof TargetingCountry>;
export const TargetingCountry = t.partial(
  {
    id: tx.nullable(t.Integer),
    code: tx.nullable(t.string),
    name: tx.nullable(t.string)
  },
  'TargetingCountry'
);
export type AdGroupTargeting = t.TypeOf<typeof AdGroupTargeting>;
export const AdGroupTargeting = t.partial(
  {
    lookalikeAudienceScale: tx.nullable(t.string),
    audience: tx.nullable(t.array(AdGroupAudienceTargeting)),
    audience_exclude: tx.nullable(t.array(AdGroupAudienceTargeting)),
    publisherSettings: tx.nullable(t.array(PublisherSettings)),
    /**
     * List of mobile operating systems to target
     */
    technographics: tx.nullable(
      t.array(tx.enumeration(AdGroupTargeting_technographicsItem, 'AdGroupTargeting_technographicsItem'))
    ),
    carriers: tx.nullable(t.array(Carrier)),
    /**
     * List of device types to target
     */
    deviceTypes: tx.nullable(
      t.array(tx.enumeration(AdGroupTargeting_deviceTypesItem, 'AdGroupTargeting_deviceTypesItem'))
    ),
    /**
     * List of age ranges to target
     */
    demographicsAge: tx.nullable(
      t.array(tx.enumeration(AdGroupTargeting_demographicsAgeItem, 'AdGroupTargeting_demographicsAgeItem'))
    ),
    /**
     * List of income ranges to target
     */
    demographicsIncome: tx.nullable(
      t.array(tx.enumeration(AdGroupTargeting_demographicsIncomeItem, 'AdGroupTargeting_demographicsIncomeItem'))
    ),
    /**
     * List of ethnicities to target (AFRICAN AMERICAN = aa, ASIAN AMERICAN = as, HISPANIC/LATINO = hl)
     */
    demographicsEthnicity: tx.nullable(
      t.array(tx.enumeration(AdGroupTargeting_demographicsEthnicityItem, 'AdGroupTargeting_demographicsEthnicityItem'))
    ),
    name: tx.nullable(t.string),
    on_premise: tx.nullable(t.array(AdGroupOnPremiseTargeting)),
    neighborhood: tx.nullable(t.array(AdGroupNeighborhoodTargeting)),
    /**
     * List of neighborhood types to target
     */
    neighborhood_types: tx.nullable(
      t.array(tx.enumeration(AdGroupTargeting_neighborhood_typesItem, 'AdGroupTargeting_neighborhood_typesItem'))
    ),
    weather: tx.nullable(AdGroupWeatherTargeting),
    location_filters: tx.nullable(t.array(AdGroupLocationFilter)),
    measurements: tx.nullable(t.array(DriveToLocation)),
    business_profile: tx.nullable(BusinessProfile),
    /**
     * List of genders to target
     */
    demographicsGender: tx.nullable(
      t.array(tx.enumeration(AdGroupTargeting_demographicsGenderItem, 'AdGroupTargeting_demographicsGenderItem'))
    ),
    twentyOneAndOver: tx.nullable(t.boolean),
    privacySafeIdfas: tx.nullable(t.boolean),
    /**
     * @example "dv"
     */
    viewabilityDataProvider: tx.nullable(
      tx.enumeration(AdGroupTargeting_viewabilityDataProvider, 'AdGroupTargeting_viewabilityDataProvider')
    ),
    viewabilityScore: tx.nullable(t.Integer),
    measurabilityScore: tx.nullable(t.Integer),
    tierPublisherSettings: tx.nullable(TierPublisherSettings),
    cpgProducts: tx.nullable(t.array(CpgProducts)),
    /**
     * Note: "default" will only be given when the product type has not been set yet
     * @example "audience"
     */
    product: tx.nullable(tx.enumeration(AdGroupTargeting_product, 'AdGroupTargeting_product')),
    country: tx.nullable(TargetingCountry)
  },
  'AdGroupTargeting'
);
/**
 * Note: "default" will only be given when the product type has not been set yet
 * @example "audience"
 */
export enum AdGroupUpdate_product {
  AUDIENCE = 'audience',
  GEOTARGETING = 'geotargeting',
  ONPREMISE = 'onpremise',
  NEIGHBORHOOD = 'neighborhood',
  WEATHER = 'weather',
  DEFAULT = 'default'
}
export type AdGroupUpdate = t.TypeOf<typeof AdGroupUpdate>;
export const AdGroupUpdate = t.partial(
  {
    enableStoreVisitation: tx.nullable(t.boolean),
    enableStoreVisitationLift: tx.nullable(t.boolean),
    /**
     * Note: "default" will only be given when the product type has not been set yet
     * @example "audience"
     */
    product: tx.nullable(tx.enumeration(AdGroupUpdate_product, 'AdGroupUpdate_product')),
    id: tx.nullable(t.Integer),
    deliveryChannel_id: tx.nullable(t.Integer),
    ctr_threshold: tx.nullable(t.number),
    conversion_threshold: tx.nullable(t.number),
    basic_delivery: tx.nullable(t.boolean),
    sar_threshold: tx.nullable(t.number),
    psvr_threshold: tx.nullable(t.number),
    optimization_strategy: tx.nullable(t.string),
    name: tx.nullable(t.string),
    status: tx.nullable(t.string),
    timezone: tx.nullable(t.string),
    buildSegment: tx.nullable(t.boolean),
    segementName: tx.nullable(t.string),
    proximity_mode: tx.nullable(t.Integer),
    targeting: tx.nullable(AdGroupTargeting),
    ignore_missing_product_data: tx.nullable(t.boolean),
    ignore_missing_drive_to_data: tx.nullable(t.boolean)
  },
  'AdGroupUpdate'
);
export type AdGroupsResponse = t.TypeOf<typeof AdGroupsResponse>;
export const AdGroupsResponse = t.type(
  {
    adgroups: t.array(AdGroup)
  },
  'AdGroupsResponse'
);
/**
 * @example "BILLABLE"
 */
export enum AdgroupResponse_billability_type {
  BILLABLE = 'BILLABLE',
  ADDED_VALUE = 'ADDED_VALUE',
  MAKE_GOOD = 'MAKE_GOOD',
  SURVEY = 'SURVEY',
  TEST = 'TEST'
}
/**
 * @example "CPM"
 */
export enum AdgroupResponse_bidType {
  CPM = 'CPM',
  CPC = 'CPC',
  CPV = 'CPV'
}
/**
 * Note: "default" will only be given when the product type has not been set yet
 * @example "audience"
 */
export enum AdgroupResponse_product {
  AUDIENCE = 'audience',
  GEOTARGETING = 'geotargeting',
  ONPREMISE = 'onpremise',
  NEIGHBORHOOD = 'neighborhood',
  WEATHER = 'weather',
  DEFAULT = 'default'
}
export type TimeFrame = t.TypeOf<typeof TimeFrame>;
export const TimeFrame = t.partial(
  {
    /**
     * @example "1980-01-31"
     */
    start: tx.nullable(tx.DateString),
    /**
     * @example "1980-01-31"
     */
    end: tx.nullable(tx.DateString),
    remain: tx.nullable(t.number)
  },
  'TimeFrame'
);
/**
 * @example "USAMP"
 */
export enum AdgroupResponse_svlVendorsItem {
  USAMP = 'USAMP',
  PLACED = 'PLACED',
  LIVERAMP = 'LIVERAMP',
  XAD = 'XAD',
  NEUSTAR = 'NEUSTAR',
  CARDLYTICS = 'CARDLYTICS',
  AFFINITY = 'AFFINITY'
}
export type LPConfig = t.TypeOf<typeof LPConfig>;
export const LPConfig = t.partial(
  {
    imageUrl_640x400: tx.nullable(t.string),
    vanityPhone: tx.nullable(t.string),
    button1Css: tx.nullable(t.string),
    button2Css: tx.nullable(t.string),
    button3Css: tx.nullable(t.string),
    button4Css: tx.nullable(t.string),
    button1Signal: tx.nullable(t.string),
    button2Signal: tx.nullable(t.string),
    button3Signal: tx.nullable(t.string),
    button4Signal: tx.nullable(t.string),
    button1Link: tx.nullable(t.string),
    button2Link: tx.nullable(t.string),
    button3Link: tx.nullable(t.string),
    button4Link: tx.nullable(t.string),
    buttonColor: tx.nullable(t.string),
    customCss: tx.nullable(t.string),
    street: tx.nullable(t.string),
    cssYouTube: tx.nullable(t.string),
    city: tx.nullable(t.string),
    enableAdditionalCTA: tx.nullable(t.string),
    externalClickUrl: tx.nullable(t.string),
    state: tx.nullable(t.string),
    additionalCTA: tx.nullable(t.string),
    attribution_overide: tx.nullable(t.string),
    customYouTubeId: tx.nullable(t.string),
    enableDirectionsButton: tx.nullable(t.string),
    business_latitude: tx.nullable(t.string),
    couponUrl: tx.nullable(t.string),
    business_longitude: tx.nullable(t.string),
    notificationUrl: tx.nullable(t.string),
    phone: tx.nullable(t.string),
    disclaimerTitle: tx.nullable(t.string),
    callUrl: tx.nullable(t.string),
    enablePhoneButton: tx.nullable(t.string),
    name: tx.nullable(t.string),
    flipCouponVideo: tx.nullable(t.string),
    disclaimer: tx.nullable(t.string),
    couponWebUrl: tx.nullable(t.string),
    flipAddressFields: tx.nullable(t.string),
    google_analytics_key: tx.nullable(t.string),
    localized_text_call: tx.nullable(t.string),
    localized_text_directions: tx.nullable(t.string),
    landing_page_impression_url: tx.nullable(t.string),
    directionsButtonCss: tx.nullable(t.string),
    directionsLabel: tx.nullable(t.string),
    imageUrl_320x480: tx.nullable(t.string),
    overlayText1: tx.nullable(t.string),
    overlayText1Css: tx.nullable(t.string),
    overlayText2: tx.nullable(t.string),
    overlayText2Css: tx.nullable(t.string),
    phoneButtonCss: tx.nullable(t.string),
    showPhoneNumber: tx.nullable(t.string),
    lpRevisionId: tx.nullable(t.unknown)
  },
  'LPConfig'
);
/**
 * @example "Active"
 */
export enum CreativeResponse_status {
  ACTIVE = 'Active',
  PAUSED = 'Paused',
  EXPIRED = 'Expired',
  PENDING = 'Pending'
}
/**
 * @example "HTML5"
 */
export enum CreativeResponse_creativeType {
  HTML5 = 'HTML5',
  IMAGE = 'IMAGE',
  SCRIPT = 'SCRIPT',
  VIDEO = 'VIDEO',
  NATIVE = 'NATIVE'
}
export type CMSConfig = t.TypeOf<typeof CMSConfig>;
export const CMSConfig = t.partial(
  {
    externalImpressionUrls: tx.nullable(t.array(t.string)),
    creativeId: tx.nullable(t.string),
    impressionUrl: tx.nullable(t.string),
    clickUrl: tx.nullable(t.string),
    externalClickUrl: tx.nullable(t.string),
    externalScriptUrls: tx.nullable(t.array(t.string)),
    bannerWidth: tx.nullable(t.string),
    bannerHeight: tx.nullable(t.string),
    bannerUrl: tx.nullable(t.string),
    cssCustomText: tx.nullable(t.string),
    cssDistance: tx.nullable(t.string),
    customText: tx.nullable(t.string),
    distance_text: tx.nullable(t.string),
    distance: tx.nullable(t.string),
    distanceUnit: tx.nullable(t.string),
    externalImpressionUrl: tx.nullable(t.string),
    themeId: tx.nullable(t.Integer),
    caption: tx.nullable(t.string),
    name: tx.nullable(t.string),
    imageUrl_50x50: tx.nullable(t.string),
    backgroundColor: tx.nullable(t.string),
    scrollingTextToggle: tx.nullable(t.string),
    distanceToggle: tx.nullable(t.string),
    backgroundImage: tx.nullable(t.string),
    logoImage: tx.nullable(t.string),
    businessNameColor: tx.nullable(t.string),
    captionColor: tx.nullable(t.string),
    distanceColor: tx.nullable(t.string),
    script_tag: tx.nullable(t.string),
    videoEventStart: tx.nullable(t.string),
    videoEventExpand: tx.nullable(t.string),
    videoEventFullscreen: tx.nullable(t.string),
    vastTagUrl: tx.nullable(t.string),
    videoEventMidPoint: tx.nullable(t.string),
    videoEventAcceptInvitation: tx.nullable(t.string),
    videoEventCreativeView: tx.nullable(t.string),
    videoEventPause: tx.nullable(t.string),
    videoEventFirstQuartile: tx.nullable(t.string),
    videoEventThirdQuartile: tx.nullable(t.string),
    videoEventComplete: tx.nullable(t.string),
    videoEventClose: tx.nullable(t.string),
    videoEndUrl: tx.nullable(t.string),
    videoEventCollapse: tx.nullable(t.string),
    comscoreUrl: tx.nullable(t.string),
    videoEventRewind: tx.nullable(t.string),
    videoEventMute: tx.nullable(t.string),
    videoEventResume: tx.nullable(t.string),
    videoEventUnmute: tx.nullable(t.string),
    videoStartUrl: tx.nullable(t.string),
    advertiserName: tx.nullable(t.string),
    cta: tx.nullable(t.string),
    description: tx.nullable(t.string),
    iconImage: tx.nullable(t.string),
    mainImage: tx.nullable(t.string),
    title: tx.nullable(t.string),
    price: tx.nullable(t.unknown),
    prog_outbound_deal_id: tx.nullable(t.string),
    prog_inbound_deal_id: tx.nullable(t.string),
    html_tag: tx.nullable(t.string),
    s3_file_name: tx.nullable(t.string)
  },
  'CMSConfig'
);
/**
 * @example "Standard Landing Page"
 */
export enum CreativeResponse_lpTypeName {
  STANDARD_LANDING_PAGE = 'Standard Landing Page',
  SUPER_LANDING_PAGE = 'Super Landing Page',
  IMAGE_LANDING_PAGE = 'Image Landing Page'
}
/**
 * @example "New"
 */
export enum VideoTranscodingStatus_status {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  DONE = 'Done',
  FAILED = 'Failed',
  CANCELLED = 'Cancelled',
  CONFIRMED = 'Confirmed',
  EXPIRED = 'Expired'
}
export type VideoTranscodingStatus = t.TypeOf<typeof VideoTranscodingStatus>;
export const VideoTranscodingStatus = t.partial(
  {
    s3_file_name: tx.nullable(t.string),
    message: tx.nullable(t.string),
    /**
     * @example "New"
     */
    status: tx.nullable(tx.enumeration(VideoTranscodingStatus_status, 'VideoTranscodingStatus_status'))
  },
  'VideoTranscodingStatus'
);
/**
 * @example "New"
 */
export enum HTML5CreativeStatus_status {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  DONE = 'Done',
  FAILED = 'Failed',
  CANCELLED = 'Cancelled',
  CONFIRMED = 'Confirmed',
  EXPIRED = 'Expired'
}
export type HTML5CreativeStatus = t.TypeOf<typeof HTML5CreativeStatus>;
export const HTML5CreativeStatus = t.partial(
  {
    s3_file_name: tx.nullable(t.string),
    message: tx.nullable(t.string),
    /**
     * @example "New"
     */
    status: tx.nullable(tx.enumeration(HTML5CreativeStatus_status, 'HTML5CreativeStatus_status'))
  },
  'HTML5CreativeStatus'
);
export type CreativeResponse = t.TypeOf<typeof CreativeResponse>;
export const CreativeResponse = t.partial(
  {
    creativeIUrl: tx.nullable(t.string),
    freqCapValue: tx.nullable(t.Integer),
    lpConfig: tx.nullable(LPConfig),
    name: tx.nullable(t.string),
    creativeInterstitial: tx.nullable(t.boolean),
    proximity_mode: tx.nullable(t.Integer),
    id: tx.nullable(t.Integer),
    /**
     * @example "1980-01-31"
     */
    startDate: tx.nullable(tx.DateString),
    creative_api: tx.nullable(t.string),
    landingPageUrl: tx.nullable(t.string),
    /**
     * @example "Active"
     */
    status: tx.nullable(tx.enumeration(CreativeResponse_status, 'CreativeResponse_status')),
    creativeSize: tx.nullable(t.string),
    /**
     * @example "HTML5"
     */
    creativeType: tx.nullable(tx.enumeration(CreativeResponse_creativeType, 'CreativeResponse_creativeType')),
    freqCapDuration: tx.nullable(t.Integer),
    weight: tx.nullable(t.Integer),
    /**
     * @example "1980-01-31"
     */
    endDate: tx.nullable(tx.DateString),
    cmsConfig: tx.nullable(CMSConfig),
    mpcb_supported_creative: tx.nullable(t.boolean),
    useLandingPage: tx.nullable(t.boolean),
    vendorIdentifier: tx.nullable(t.string),
    /**
     * @example "Standard Landing Page"
     */
    lpTypeName: tx.nullable(tx.enumeration(CreativeResponse_lpTypeName, 'CreativeResponse_lpTypeName')),
    video_transcoder: tx.nullable(VideoTranscodingStatus),
    html5_creative: tx.nullable(HTML5CreativeStatus)
  },
  'CreativeResponse'
);
/**
 * @example "Active"
 */
export enum AdgroupResponse_status {
  ACTIVE = 'Active',
  PAUSED = 'Paused',
  EXPIRED = 'Expired',
  PENDING = 'Pending'
}
/**
 * @example "click"
 */
export enum AdgroupResponse_optimization_strategy {
  CLICK = 'click',
  DELIVERY = 'delivery',
  SAR = 'sar',
  CONVERSION = 'conversion',
  VISIT = 'visit'
}
/**
 * @example "ACCELERATED"
 */
export enum AdgroupResponse_pacingMode {
  ACCELERATED = 'ACCELERATED',
  ASAP = 'ASAP',
  DEFAULT = 'DEFAULT'
}
export type AdgroupResponse = t.TypeOf<typeof AdgroupResponse>;
export const AdgroupResponse = t.partial(
  {
    time_period_budgets: tx.nullable(t.array(AdGroupBudget)),
    dailyImp: tx.nullable(t.Integer),
    conversion_threshold: tx.nullable(t.number),
    /**
     * @example "BILLABLE"
     */
    billability_type: tx.nullable(tx.enumeration(AdgroupResponse_billability_type, 'AdgroupResponse_billability_type')),
    segmentName: tx.nullable(t.string),
    creativeId: tx.nullable(t.Integer),
    /**
     * @example "CPM"
     */
    bidType: tx.nullable(tx.enumeration(AdgroupResponse_bidType, 'AdgroupResponse_bidType')),
    id: tx.nullable(t.Integer),
    enableStoreVisitationLift: tx.nullable(t.boolean),
    totalBudget: tx.nullable(t.number),
    deliveryChannel_id: tx.nullable(t.Integer),
    clientIoName: tx.nullable(t.string),
    /**
     * Note: "default" will only be given when the product type has not been set yet
     * @example "audience"
     */
    product: tx.nullable(tx.enumeration(AdgroupResponse_product, 'AdgroupResponse_product')),
    timeframe: tx.nullable(TimeFrame),
    svlVendors: tx.nullable(t.array(tx.enumeration(AdgroupResponse_svlVendorsItem, 'AdgroupResponse_svlVendorsItem'))),
    creatives: tx.nullable(t.array(CreativeResponse)),
    clientIoNumber: tx.nullable(t.string),
    sar_threshold: tx.nullable(t.number),
    budgetPadding: tx.nullable(t.number),
    added_cost: tx.nullable(t.number),
    rotation: tx.nullable(t.Integer),
    created_date: tx.nullable(t.string),
    is_cbd: tx.nullable(t.boolean),
    name: tx.nullable(t.string),
    buildSegment: tx.nullable(t.boolean),
    is_CPV: tx.nullable(t.boolean),
    basic_delivery: tx.nullable(t.boolean),
    budget_carryover: tx.nullable(t.boolean),
    buildSegmentIntoId: tx.nullable(t.Integer),
    gross_bid_rate: tx.nullable(t.number),
    /**
     * @example "Active"
     */
    status: tx.nullable(tx.enumeration(AdgroupResponse_status, 'AdgroupResponse_status')),
    ctr_threshold: tx.nullable(t.number),
    creative: tx.nullable(CreativeResponse),
    bizAllRadius: tx.nullable(t.number),
    bidRate: tx.nullable(t.number),
    enableStoreVisitation: tx.nullable(t.boolean),
    /**
     * @example "click"
     */
    optimization_strategy: tx.nullable(
      tx.enumeration(AdgroupResponse_optimization_strategy, 'AdgroupResponse_optimization_strategy')
    ),
    adPackage: tx.nullable(t.array(AdPackage)),
    spends_budget: tx.nullable(t.number),
    pacing: tx.nullable(t.number),
    proximity_mode: tx.nullable(t.string),
    timezone: tx.nullable(t.string),
    psvr_threshold: tx.nullable(t.number),
    targeting: tx.nullable(AdGroupTargeting),
    /**
     * @example "ACCELERATED"
     */
    pacingMode: tx.nullable(tx.enumeration(AdgroupResponse_pacingMode, 'AdgroupResponse_pacingMode'))
  },
  'AdgroupResponse'
);
export type Audience = t.TypeOf<typeof Audience>;
export const Audience = t.partial(
  {
    id: tx.nullable(t.unknown),
    type: tx.nullable(t.string),
    name: tx.nullable(t.string),
    size: tx.nullable(t.Integer),
    description: tx.nullable(t.string),
    sensitivity: tx.nullable(t.Integer),
    backend_key: tx.nullable(t.string),
    sicCode: tx.nullable(t.string)
  },
  'Audience'
);
export type AudienceCategory = t.TypeOf<typeof AudienceCategory>;
export const AudienceCategory = t.partial(
  {
    name: tx.nullable(t.string),
    size: tx.nullable(t.Integer),
    description: tx.nullable(t.string),
    audiences: tx.nullable(t.array(Audience))
  },
  'AudienceCategory'
);
export type AudienceRecommendationsRequest = t.TypeOf<typeof AudienceRecommendationsRequest>;
export const AudienceRecommendationsRequest = t.type(
  {
    audience_ids: t.array(t.string)
  },
  'AudienceRecommendationsRequest'
);
export type AudienceRecommendationsResponse = t.TypeOf<typeof AudienceRecommendationsResponse>;
export const AudienceRecommendationsResponse = t.partial(
  {
    recommendations: tx.nullable(t.array(Audience))
  },
  'AudienceRecommendationsResponse'
);
export type AudienceSearchResult = t.TypeOf<typeof AudienceSearchResult>;
export const AudienceSearchResult = t.partial(
  {
    category: tx.nullable(AudienceCategory),
    audience: tx.nullable(Audience)
  },
  'AudienceSearchResult'
);
export type AudienceSearchResponse = t.TypeOf<typeof AudienceSearchResponse>;
export const AudienceSearchResponse = t.partial(
  {
    results: tx.nullable(t.array(AudienceSearchResult))
  },
  'AudienceSearchResponse'
);
export type AudienceType = t.TypeOf<typeof AudienceType>;
export const AudienceType = t.partial(
  {
    name: tx.nullable(t.string),
    categories: tx.nullable(t.array(AudienceCategory))
  },
  'AudienceType'
);
export type AudienceTaxonomyResponse = t.TypeOf<typeof AudienceTaxonomyResponse>;
export const AudienceTaxonomyResponse = t.partial(
  {
    taxonomy: tx.nullable(t.array(AudienceType))
  },
  'AudienceTaxonomyResponse'
);
export type BillingSource = t.TypeOf<typeof BillingSource>;
export const BillingSource = t.partial(
  {
    id: tx.nullable(t.Integer),
    /**
     * Billing source name
     */
    value: tx.nullable(t.string)
  },
  'BillingSource'
);
export type BlockedAppNamesResponse = t.TypeOf<typeof BlockedAppNamesResponse>;
export const BlockedAppNamesResponse = t.type(
  {
    blocked_app_names: t.array(t.string)
  },
  'BlockedAppNamesResponse'
);
export type BulkUploadTemplateResponse = t.TypeOf<typeof BulkUploadTemplateResponse>;
export const BulkUploadTemplateResponse = t.type(
  {
    file_url: t.string
  },
  'BulkUploadTemplateResponse'
);
export type CampaignCategory = t.TypeOf<typeof CampaignCategory>;
export const CampaignCategory = t.partial(
  {
    sicCode: tx.nullable(t.string),
    categoryName: tx.nullable(t.string),
    billing_source_display_name: tx.nullable(t.string),
    billing_source_id: tx.nullable(t.Integer),
    parent_category_id: tx.nullable(t.Integer),
    parent_category_name: tx.nullable(t.string),
    parent_sic_code: tx.nullable(t.string)
  },
  'CampaignCategory'
);
export type CampaignDomainsResponse = t.TypeOf<typeof CampaignDomainsResponse>;
export const CampaignDomainsResponse = t.type(
  {
    domains: t.array(t.string)
  },
  'CampaignDomainsResponse'
);
export type Campaign = t.TypeOf<typeof Campaign>;
export const Campaign = t.intersection(
  [
    Entity,
    t.type({
      /**
       * account id.
       */
      account_id: t.Integer,
      /**
       * Organization id.
       */
      organization_id: t.Integer
    })
  ],
  'Campaign'
);
/**
 * @example "CPM"
 */
export enum CampaignPostRequest_bidType {
  CPM = 'CPM',
  CPC = 'CPC',
  CPV = 'CPV'
}
/**
 * DAILY = 3, LIFETIME = 0, PERIODIC = 1, TIME_PERIOD = 2
 */
export enum CampaignPostRequest_budgetType {
  _0 = 0,
  _1 = 1,
  _2 = 2,
  _3 = 3
}
export type CategoryRequest = t.TypeOf<typeof CategoryRequest>;
export const CategoryRequest = t.partial(
  {
    id: tx.nullable(t.Integer),
    name: tx.nullable(t.string),
    sicCode: tx.nullable(t.string),
    type: tx.nullable(t.string)
  },
  'CategoryRequest'
);
export type CampaignUser = t.TypeOf<typeof CampaignUser>;
export const CampaignUser = t.partial(
  {
    email: tx.nullable(t.string),
    firstName: tx.nullable(t.string),
    lastName: tx.nullable(t.string),
    tenant_id: tx.nullable(t.Integer),
    userId: tx.nullable(t.Integer)
  },
  'CampaignUser'
);
export type CampaignUsers = t.TypeOf<typeof CampaignUsers>;
export const CampaignUsers = t.partial(
  {
    'Account Manager': tx.nullable(t.array(CampaignUser)),
    'Direct Ops': tx.nullable(t.array(CampaignUser)),
    'Direct Sales': tx.nullable(t.array(CampaignUser))
  },
  'CampaignUsers'
);
export type CampaignPostRequest = t.TypeOf<typeof CampaignPostRequest>;
export const CampaignPostRequest = t.intersection(
  [
    t.type({
      /**
       * @example "CPM"
       */
      bidType: tx.enumeration(CampaignPostRequest_bidType, 'CampaignPostRequest_bidType'),
      billing_source_id: t.Integer,
      budgetLevel: t.string,
      enableAutoreconcile: t.boolean,
      is_cbd: t.boolean,
      name: t.string
    }),
    t.partial({
      accountManagerEmail: tx.nullable(t.string),
      budget: tx.nullable(t.number),
      budgetPadding: tx.nullable(t.number),
      /**
       * DAILY = 3, LIFETIME = 0, PERIODIC = 1, TIME_PERIOD = 2
       */
      budgetType: tx.nullable(tx.enumeration(CampaignPostRequest_budgetType, 'CampaignPostRequest_budgetType')),
      category: tx.nullable(CategoryRequest),
      client_io_name: tx.nullable(t.string),
      freqCapDuration: tx.nullable(t.Integer),
      freqCapValue: tx.nullable(t.Integer),
      opsEmail: tx.nullable(t.string),
      salesEmail: tx.nullable(t.string),
      salesforceNumber: tx.nullable(t.string),
      users: tx.nullable(CampaignUsers),
      vendorIdentifier: tx.nullable(t.string)
    })
  ],
  'CampaignPostRequest'
);
export type CampaignSearchResult = t.TypeOf<typeof CampaignSearchResult>;
export const CampaignSearchResult = t.type(
  {
    /**
     * Campaign id.
     */
    id: t.Integer,
    /**
     * Campaign name.
     */
    name: t.string,
    /**
     * Account id.
     */
    account_id: t.Integer,
    /**
     * Organization id.
     */
    organization_id: t.Integer
  },
  'CampaignSearchResult'
);
export type CampaignSettingsResponse = t.TypeOf<typeof CampaignSettingsResponse>;
export const CampaignSettingsResponse = t.partial(
  {
    category: tx.nullable(CampaignCategory),
    users: tx.nullable(CampaignUsers)
  },
  'CampaignSettingsResponse'
);
/**
 * @example "Active"
 */
export enum CampaignTreeResponse_status {
  ACTIVE = 'Active',
  PAUSED = 'Paused',
  EXPIRED = 'Expired',
  PENDING = 'Pending',
  DRAFT = 'Draft',
  REJECTED = 'Rejected',
  SUBMITTED = 'Submitted'
}
/**
 * DAILY = 3, LIFETIME = 0, PERIODIC = 1, TIME_PERIOD = 2
 */
export enum CampaignTreeResponse_budgetType {
  _0 = 0,
  _1 = 1,
  _2 = 2,
  _3 = 3
}
export type CampaignTreeResponse = t.TypeOf<typeof CampaignTreeResponse>;
export const CampaignTreeResponse = t.partial(
  {
    salesforceNumber: tx.nullable(t.string),
    globalWhitelist: tx.nullable(t.boolean),
    bulk_upload_job_id: tx.nullable(t.Integer),
    budgetAtCampaignLevel: tx.nullable(t.boolean),
    id: tx.nullable(t.Integer),
    timeframe: tx.nullable(TimeFrame),
    freqCapDuration: tx.nullable(t.Integer),
    client_io_name: tx.nullable(t.string),
    partner_account_manager: tx.nullable(t.string),
    publisherBlockedDomains: tx.nullable(t.array(t.string)),
    billing_source: tx.nullable(t.Integer),
    spends_budget: tx.nullable(t.number),
    dart_account_id: tx.nullable(t.Integer),
    partner_ops: tx.nullable(t.string),
    budgetPadding: tx.nullable(t.number),
    clientIoNumber: tx.nullable(t.string),
    publisherBlockedAppNames: tx.nullable(t.array(t.string)),
    freqCapValue: tx.nullable(t.Integer),
    is_cbd: tx.nullable(t.boolean),
    name: tx.nullable(t.string),
    enableAutoreconcile: tx.nullable(t.Integer),
    /**
     * @example "Active"
     */
    status: tx.nullable(tx.enumeration(CampaignTreeResponse_status, 'CampaignTreeResponse_status')),
    is_cpv: tx.nullable(t.boolean),
    publisherWhitelistDomains: tx.nullable(t.array(t.string)),
    /**
     * DAILY = 3, LIFETIME = 0, PERIODIC = 1, TIME_PERIOD = 2
     */
    budgetType: tx.nullable(tx.enumeration(CampaignTreeResponse_budgetType, 'CampaignTreeResponse_budgetType')),
    partner_sales: tx.nullable(t.string),
    publisherWhitelistAppNames: tx.nullable(t.array(t.string)),
    app_used: tx.nullable(t.string),
    pacing: tx.nullable(t.number),
    timezone: tx.nullable(t.string),
    accountBillingSource_id: tx.nullable(t.Integer),
    /**
     * This only applies to POST /campaigns
     */
    new_adgroup_id: tx.nullable(t.Integer),
    adGroups: tx.nullable(t.array(AdgroupResponse)),
    settings: tx.nullable(CampaignSettingsResponse)
  },
  'CampaignTreeResponse'
);
/**
 * @example "createdDate"
 */
export enum CampaignsSearchRequest_sort_by {
  CREATEDDATE = 'createdDate',
  NAME = 'name'
}
/**
 * @example "asc"
 */
export enum CampaignsSearchRequest_sort_direction {
  ASC = 'asc',
  DESC = 'desc'
}
export type CampaignsSearchRequest = t.TypeOf<typeof CampaignsSearchRequest>;
export const CampaignsSearchRequest = t.intersection(
  [
    t.type({
      account_ids: t.array(t.Integer),
      organization_ids: t.array(t.Integer)
    }),
    t.partial({
      search: tx.nullable(t.string),
      limit: tx.nullable(t.Integer),
      /**
       * @example "createdDate"
       */
      sort_by: tx.nullable(tx.enumeration(CampaignsSearchRequest_sort_by, 'CampaignsSearchRequest_sort_by')),
      /**
       * @example "asc"
       */
      sort_direction: tx.nullable(
        tx.enumeration(CampaignsSearchRequest_sort_direction, 'CampaignsSearchRequest_sort_direction')
      ),
      /**
       * @example "1980-01-31"
       */
      min_start_date: tx.nullable(tx.DateString)
    })
  ],
  'CampaignsSearchRequest'
);
export type Category = t.TypeOf<typeof Category>;
export const Category = t.partial(
  {
    id: tx.nullable(t.Integer),
    name: tx.nullable(t.string)
  },
  'Category'
);
export type CategorySearchResponse = t.TypeOf<typeof CategorySearchResponse>;
export const CategorySearchResponse = t.partial(
  {
    results: tx.nullable(t.array(Category))
  },
  'CategorySearchResponse'
);
export type ChannelCampaignResponse = t.TypeOf<typeof ChannelCampaignResponse>;
export const ChannelCampaignResponse = t.intersection(
  [
    t.type({
      id: t.Integer
    }),
    t.partial({
      name: tx.nullable(t.string),
      company_id: tx.nullable(t.Integer),
      account_id: tx.nullable(t.Integer),
      tenant_id: tx.nullable(t.Integer),
      /**
       * @example "1980-01-31"
       */
      submissionDate: tx.nullable(tx.DateString),
      /**
       * @example "1980-01-31"
       */
      startDate: tx.nullable(tx.DateString),
      /**
       * @example "1980-01-31"
       */
      endDate: tx.nullable(tx.DateString),
      tenant_name: tx.nullable(t.string),
      timezone: tx.nullable(t.string),
      status: tx.nullable(t.string)
    })
  ],
  'ChannelCampaignResponse'
);
export type ChannelCampaignsResponse = t.TypeOf<typeof ChannelCampaignsResponse>;
export const ChannelCampaignsResponse = t.type(
  {
    channel_campaigns: t.array(ChannelCampaignResponse)
  },
  'ChannelCampaignsResponse'
);
export type CheckGrowthConnectDisconnectRequest = t.TypeOf<typeof CheckGrowthConnectDisconnectRequest>;
export const CheckGrowthConnectDisconnectRequest = t.type(
  {
    accountId: t.string,
    /**
     * Name of pos to be connected/disconnected
     */
    posName: t.string
  },
  'CheckGrowthConnectDisconnectRequest'
);
export type CheckGrowthConnectDisconnectResponse = t.TypeOf<typeof CheckGrowthConnectDisconnectResponse>;
export const CheckGrowthConnectDisconnectResponse = t.type(
  {
    message: t.string,
    status: t.string
  },
  'CheckGrowthConnectDisconnectResponse'
);
export type CheckGrowthConnectionsResponse = t.TypeOf<typeof CheckGrowthConnectionsResponse>;
export const CheckGrowthConnectionsResponse = t.intersection(
  [
    t.type({
      /**
       * pos id
       */
      id: t.string,
      /**
       * pos name
       */
      name: t.string,
      /**
       * pos icon
       */
      icon: t.string,
      /**
       * pos connected/disconnected
       */
      isConnected: t.boolean,
      /**
       * Disable pos connect button
       */
      canConnect: t.boolean,
      /**
       * Disable pos disconnect
       */
      canDisconnect: t.boolean
    }),
    t.partial({
      /**
       * oAuth url for self-service connection if supported
       */
      oAuthUrl: tx.nullable(t.string)
    })
  ],
  'CheckGrowthConnectionsResponse'
);
export type CreateAssetsRequestModelItem = t.TypeOf<typeof CreateAssetsRequestModelItem>;
export const CreateAssetsRequestModelItem = t.type(
  {
    /**
     * Name of this asset
     */
    name: t.string,
    /**
     * CDN URL of this asset
     * @example "https://www.groundtruth.com"
     */
    url: tx.UrlString,
    /**
     * File type of the asset stored at the url location
     */
    file_type: t.string
  },
  'CreateAssetsRequestModelItem'
);
export type CreateAssetsRequest = t.TypeOf<typeof CreateAssetsRequest>;
export const CreateAssetsRequest = t.type(
  {
    creative_assets: t.array(CreateAssetsRequestModelItem)
  },
  'CreateAssetsRequest'
);
export type CreateAssetsResponseModelResult = t.TypeOf<typeof CreateAssetsResponseModelResult>;
export const CreateAssetsResponseModelResult = t.intersection(
  [
    t.type({
      /**
       * Name of asset that was processed
       */
      name: t.string,
      /**
       * Success or failure of the asset creation
       */
      success: t.boolean
    }),
    t.partial({
      /**
       * Error message if success is false
       */
      error_message: tx.nullable(t.string)
    })
  ],
  'CreateAssetsResponseModelResult'
);
export type CreateAssetsResponse = t.TypeOf<typeof CreateAssetsResponse>;
export const CreateAssetsResponse = t.type(
  {
    result: t.array(CreateAssetsResponseModelResult)
  },
  'CreateAssetsResponse'
);
/**
 * CAMPAIGN_BULK_UPLOAD = 1, CAMPAIGN_SUMMARY = 2, CREATIVE_BULK_UPLOAD = 6, GEOTARGET = 0, HTML5_CREATIVE = 7, VIDEO_TRANSCODER = 3
 */
export enum CreateJobExpected_job_type {
  CAMPAIGN_BULK_UPLOAD = 1,
  CAMPAIGN_SUMMARY = 2,
  CREATIVE_BULK_UPLOAD = 6,
  GEOTARGET = 0,
  HTML5_CREATIVE = 7,
  VIDEO_TRANSCODER = 3
}
export type CreateJobExpected = t.TypeOf<typeof CreateJobExpected>;
export const CreateJobExpected = t.intersection(
  [
    t.type({
      /**
       * Input spreadsheet URL
       * @example "https://www.groundtruth.com"
       */
      input_file_url: tx.UrlString,
      /**
       * CAMPAIGN_BULK_UPLOAD = 1, CAMPAIGN_SUMMARY = 2, CREATIVE_BULK_UPLOAD = 6, GEOTARGET = 0, HTML5_CREATIVE = 7, VIDEO_TRANSCODER = 3
       */
      job_type: tx.enumeration(CreateJobExpected_job_type, 'CreateJobExpected_job_type'),
      campaign_id: t.Integer,
      account_id: t.Integer
    }),
    t.partial({
      /**
       * Must be given for geotarget upload
       */
      adgroup_id: tx.nullable(t.Integer),
      /**
       * For geotarget upload, append geotargets to existing saved ones
       */
      append_data: tx.nullable(t.boolean)
    })
  ],
  'CreateJobExpected'
);
export type CreateJobResponse = t.TypeOf<typeof CreateJobResponse>;
export const CreateJobResponse = t.partial(
  {
    job_id: tx.nullable(t.Integer)
  },
  'CreateJobResponse'
);
export type CreativeAssetAssociatedAdGroup = t.TypeOf<typeof CreativeAssetAssociatedAdGroup>;
export const CreativeAssetAssociatedAdGroup = t.type(
  {
    adgroup_id: t.Integer,
    adgroup_name: t.string,
    campaign_id: t.Integer
  },
  'CreativeAssetAssociatedAdGroup'
);
export type CreativeAssetAssociatedAdGroupsResponse = t.TypeOf<typeof CreativeAssetAssociatedAdGroupsResponse>;
export const CreativeAssetAssociatedAdGroupsResponse = t.partial(
  {
    adgroups: tx.nullable(t.array(CreativeAssetAssociatedAdGroup))
  },
  'CreativeAssetAssociatedAdGroupsResponse'
);
/**
 * Creative asset type.
 * @example "IMAGE"
 */
export enum CreativeAsset_asset_type {
  IMAGE = 'IMAGE',
  SCRIPT = 'SCRIPT'
}
/**
 * Creative asset file extension. This field will only be returned for full load
 * @example ".JPG"
 */
export enum CreativeAsset_file_extension {
  JPG = '.JPG',
  GIF = '.GIF',
  PNG = '.PNG',
  JPEG = '.JPEG',
  TEXT = '.TEXT',
  TXT = '.TXT',
  HTML = '.HTML'
}
/**
 * The asset status is derived from associated creatives' status. The priority order of creative status to derive the asset order is active, paused, pending and expired. For example, if any of the creatives'status linked to the asset is active,the asset status will be active. This field will only be returned for full load
 * @example "Active"
 */
export enum CreativeAsset_status {
  ACTIVE = 'Active',
  PAUSED = 'Paused',
  PENDING = 'Pending',
  EXPIRED = 'Expired',
  UNLINKED = 'Unlinked'
}
export type CreativeAsset = t.TypeOf<typeof CreativeAsset>;
export const CreativeAsset = t.partial(
  {
    /**
     * Creative asset id.
     */
    asset_id: tx.nullable(t.Integer),
    /**
     * Creative asset name
     */
    asset_name: tx.nullable(t.string),
    /**
     * Size for creative asset
     * @example "640x360, 300x250"
     */
    size: tx.nullable(t.string),
    /**
     * Creative asset type.
     * @example "IMAGE"
     */
    asset_type: tx.nullable(tx.enumeration(CreativeAsset_asset_type, 'CreativeAsset_asset_type')),
    /**
     * Creative asset file extension. This field will only be returned for full load
     * @example ".JPG"
     */
    file_extension: tx.nullable(tx.enumeration(CreativeAsset_file_extension, 'CreativeAsset_file_extension')),
    /**
     * Uploaded creative asset URL
     */
    asset_source: tx.nullable(t.string),
    /**
     * Name of user who creates the creative asset. This field will only be returned for full load
     */
    created_by: tx.nullable(t.string),
    /**
     * Creative asset created date. This field will only be returned for full load
     * @example "1980-01-31"
     */
    created_date: tx.nullable(tx.DateString),
    /**
     * The asset status is derived from associated creatives' status. The priority order of creative status to derive the asset order is active, paused, pending and expired. For example, if any of the creatives'status linked to the asset is active,the asset status will be active. This field will only be returned for full load
     * @example "Active"
     */
    status: tx.nullable(tx.enumeration(CreativeAsset_status, 'CreativeAsset_status'))
  },
  'CreativeAsset'
);
export type CreativeAssetUpdateRequest = t.TypeOf<typeof CreativeAssetUpdateRequest>;
export const CreativeAssetUpdateRequest = t.type(
  {
    asset_name: t.string
  },
  'CreativeAssetUpdateRequest'
);
export type CreativeAssetsDeleteRequest = t.TypeOf<typeof CreativeAssetsDeleteRequest>;
export const CreativeAssetsDeleteRequest = t.type(
  {
    asset_ids: t.array(t.Integer)
  },
  'CreativeAssetsDeleteRequest'
);
export type CreativeAssetsDeleteResponse = t.TypeOf<typeof CreativeAssetsDeleteResponse>;
export const CreativeAssetsDeleteResponse = t.partial(
  {
    success: tx.nullable(t.array(t.Integer)),
    failure: tx.nullable(t.array(t.Integer))
  },
  'CreativeAssetsDeleteResponse'
);
export type CreativeAssetsDownloadRequest = t.TypeOf<typeof CreativeAssetsDownloadRequest>;
export const CreativeAssetsDownloadRequest = t.type(
  {
    asset_ids: t.array(t.Integer)
  },
  'CreativeAssetsDownloadRequest'
);
/**
 * @example "IMAGE"
 */
export enum CreativeAssetsRequest_asset_typesItem {
  IMAGE = 'IMAGE',
  SCRIPT = 'SCRIPT'
}
/**
 * Creative asset status
 * @example "Active"
 */
export enum CreativeAssetsRequest_statusItem {
  ACTIVE = 'Active',
  PAUSED = 'Paused',
  PENDING = 'Pending',
  EXPIRED = 'Expired',
  UNLINKED = 'Unlinked'
}
/**
 * Size for creative asset
 * @example "160x600"
 */
export enum CreativeAssetsRequest_sizeItem {
  _160X600 = '160x600',
  _300X50 = '300x50',
  _300X250 = '300x250',
  _300X600 = '300x600',
  _320X50 = '320x50',
  _320X480 = '320x480',
  _480X320 = '480x320',
  _600X300 = '600x300',
  _640X160 = '640x160',
  _640X213 = '640x213',
  _640X320 = '640x320',
  _640X360 = '640x360',
  _640X960 = '640x960',
  _720X240 = '720x240',
  _728X90 = '728x90',
  _768X1024 = '768x1024',
  _1024X768 = '1024x768'
}
/**
 * Number of assets to return
 * @example 10
 */
export enum CreativeAssetsRequest_limit {
  _10 = 10,
  _25 = 25,
  _50 = 50
}
/**
 * @example "asset_name"
 */
export enum CreativeAssetsRequest_sort_by {
  ASSET_NAME = 'asset_name',
  ASSET_TYPE = 'asset_type',
  FILE_EXTENSION = 'file_extension',
  STATUS = 'status',
  SIZE = 'size',
  CREATED_BY = 'created_by',
  CREATED_DATE = 'created_date'
}
export type CreativeAssetsRequest = t.TypeOf<typeof CreativeAssetsRequest>;
export const CreativeAssetsRequest = t.intersection(
  [
    t.type({
      /**
       * Number of assets to return
       * @example 10
       */
      limit: tx.enumeration(CreativeAssetsRequest_limit, 'CreativeAssetsRequest_limit'),
      page: t.Integer
    }),
    t.partial({
      organization_ids: tx.nullable(t.array(t.Integer)),
      account_ids: tx.nullable(t.array(t.Integer)),
      /**
       * Creative asset type.
       */
      asset_types: tx.nullable(
        t.array(tx.enumeration(CreativeAssetsRequest_asset_typesItem, 'CreativeAssetsRequest_asset_typesItem'))
      ),
      status: tx.nullable(
        t.array(tx.enumeration(CreativeAssetsRequest_statusItem, 'CreativeAssetsRequest_statusItem'))
      ),
      size: tx.nullable(t.array(tx.enumeration(CreativeAssetsRequest_sizeItem, 'CreativeAssetsRequest_sizeItem'))),
      /**
       * @example "asset_name"
       */
      sort_by: tx.nullable(tx.enumeration(CreativeAssetsRequest_sort_by, 'CreativeAssetsRequest_sort_by')),
      sort_desc: tx.nullable(t.boolean),
      /**
       * Search string for creative asset name
       */
      search: tx.nullable(t.string)
    })
  ],
  'CreativeAssetsRequest'
);
export type CreativeAssetsResponse = t.TypeOf<typeof CreativeAssetsResponse>;
export const CreativeAssetsResponse = t.partial(
  {
    page: tx.nullable(t.Integer),
    /**
     * Total number of assets
     */
    count: tx.nullable(t.Integer),
    creative_assets: tx.nullable(t.array(CreativeAsset))
  },
  'CreativeAssetsResponse'
);
export type CreativeCmsConfigResponse = t.TypeOf<typeof CreativeCmsConfigResponse>;
export const CreativeCmsConfigResponse = t.partial(
  {
    cms_config: tx.nullable(CMSConfig),
    creative_size: tx.nullable(t.string)
  },
  'CreativeCmsConfigResponse'
);
/**
 * @example "In Progress"
 */
export enum CreativeHTML5StatusResponse_status {
  IN_PROGRESS = 'In Progress',
  FAILED = 'Failed',
  DONE = 'Done',
  NEW = 'New'
}
export type CreativeHTML5StatusResponse = t.TypeOf<typeof CreativeHTML5StatusResponse>;
export const CreativeHTML5StatusResponse = t.partial(
  {
    creative_id: tx.nullable(t.Integer),
    /**
     * @example "In Progress"
     */
    status: tx.nullable(tx.enumeration(CreativeHTML5StatusResponse_status, 'CreativeHTML5StatusResponse_status')),
    message: tx.nullable(t.string)
  },
  'CreativeHTML5StatusResponse'
);
export type CreativeHTML5StatusListResponse = t.TypeOf<typeof CreativeHTML5StatusListResponse>;
export const CreativeHTML5StatusListResponse = t.partial(
  {
    statuses: tx.nullable(t.array(CreativeHTML5StatusResponse))
  },
  'CreativeHTML5StatusListResponse'
);
export type CreativeLPLocationResponse = t.TypeOf<typeof CreativeLPLocationResponse>;
export const CreativeLPLocationResponse = t.partial(
  {
    name: tx.nullable(t.string),
    address: tx.nullable(t.string),
    city: tx.nullable(t.string),
    state: tx.nullable(t.string),
    lat: tx.nullable(t.string),
    lng: tx.nullable(t.string)
  },
  'CreativeLPLocationResponse'
);
export type CreativeRequestModelForV2 = t.TypeOf<typeof CreativeRequestModelForV2>;
export const CreativeRequestModelForV2 = t.partial(
  {
    weight: tx.nullable(t.number),
    /**
     * @example "1980-01-31"
     */
    startDate: tx.nullable(tx.DateString),
    /**
     * @example "1980-01-31"
     */
    endDate: tx.nullable(tx.DateString),
    freqCapValue: tx.nullable(t.Integer),
    freqCapDuration: tx.nullable(t.Integer)
  },
  'CreativeRequestModelForV2'
);
export type CreativeSizeResponse = t.TypeOf<typeof CreativeSizeResponse>;
export const CreativeSizeResponse = t.partial(
  {
    size: tx.nullable(t.string),
    interstitial: tx.nullable(t.boolean),
    size_key: tx.nullable(t.string),
    width: tx.nullable(t.number),
    height: tx.nullable(t.number)
  },
  'CreativeSizeResponse'
);
export type CreativeSizesResponse = t.TypeOf<typeof CreativeSizesResponse>;
export const CreativeSizesResponse = t.partial(
  {
    HTML5: tx.nullable(t.array(CreativeSizeResponse)),
    IMAGE: tx.nullable(t.array(CreativeSizeResponse)),
    SCRIPT: tx.nullable(t.array(CreativeSizeResponse)),
    VIDEO: tx.nullable(t.array(CreativeSizeResponse)),
    NATIVE: tx.nullable(t.array(CreativeSizeResponse))
  },
  'CreativeSizesResponse'
);
/**
 * @example "Paused"
 */
export enum CreativeStatusRequest_status {
  PAUSED = 'Paused',
  ACTIVE = 'Active'
}
export type CreativeStatusRequest = t.TypeOf<typeof CreativeStatusRequest>;
export const CreativeStatusRequest = t.type(
  {
    /**
     * @example "Paused"
     */
    status: tx.enumeration(CreativeStatusRequest_status, 'CreativeStatusRequest_status')
  },
  'CreativeStatusRequest'
);
export type CreativeTemplateId = t.TypeOf<typeof CreativeTemplateId>;
export const CreativeTemplateId = t.partial(
  {
    revisionId: tx.nullable(t.Integer),
    themeId: tx.nullable(t.Integer)
  },
  'CreativeTemplateId'
);
export type CreativeTemplateIdsResponse = t.TypeOf<typeof CreativeTemplateIdsResponse>;
export const CreativeTemplateIdsResponse = t.type(
  {
    creative_template_ids: t.array(CreativeTemplateId)
  },
  'CreativeTemplateIdsResponse'
);
/**
 * @example "In Progress"
 */
export enum CreativeVideoTranscoderStatusResponse_status {
  IN_PROGRESS = 'In Progress',
  FAILED = 'Failed',
  DONE = 'Done',
  NEW = 'New'
}
export type CreativeVideoTranscoderStatusResponse = t.TypeOf<typeof CreativeVideoTranscoderStatusResponse>;
export const CreativeVideoTranscoderStatusResponse = t.partial(
  {
    creative_id: tx.nullable(t.Integer),
    /**
     * @example "In Progress"
     */
    status: tx.nullable(
      tx.enumeration(CreativeVideoTranscoderStatusResponse_status, 'CreativeVideoTranscoderStatusResponse_status')
    ),
    message: tx.nullable(t.string),
    vast_tag_url: tx.nullable(t.string)
  },
  'CreativeVideoTranscoderStatusResponse'
);
export type CreativeVideoTranscoderStatusListResponse = t.TypeOf<typeof CreativeVideoTranscoderStatusListResponse>;
export const CreativeVideoTranscoderStatusListResponse = t.partial(
  {
    statuses: tx.nullable(t.array(CreativeVideoTranscoderStatusResponse))
  },
  'CreativeVideoTranscoderStatusListResponse'
);
export type CreativeVideoTranscoderStatusRequest = t.TypeOf<typeof CreativeVideoTranscoderStatusRequest>;
export const CreativeVideoTranscoderStatusRequest = t.intersection(
  [
    t.type({
      /**
       * The ID coming from transcoder service
       */
      transcoder_job_id: t.string
    }),
    t.partial({
      vast_tag_url: tx.nullable(t.string),
      success: tx.nullable(t.boolean),
      message: tx.nullable(t.string)
    })
  ],
  'CreativeVideoTranscoderStatusRequest'
);
export type DeleteCampaignsRequest = t.TypeOf<typeof DeleteCampaignsRequest>;
export const DeleteCampaignsRequest = t.type(
  {
    campaign_ids: t.array(t.Integer)
  },
  'DeleteCampaignsRequest'
);
export type DeliveryChannel = t.TypeOf<typeof DeliveryChannel>;
export const DeliveryChannel = t.type(
  {
    deliveryChannelId: t.Integer,
    deliveryChannelName: t.string
  },
  'DeliveryChannel'
);
/**
 * Frequency for report delivery.
 * @example "DAILY"
 */
export enum DeliverySchedule_frequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY'
}
export type DeliverySchedule = t.TypeOf<typeof DeliverySchedule>;
export const DeliverySchedule = t.partial(
  {
    /**
     * Frequency for report delivery.
     * @example "DAILY"
     */
    frequency: tx.nullable(tx.enumeration(DeliverySchedule_frequency, 'DeliverySchedule_frequency'))
  },
  'DeliverySchedule'
);
export type DetailedCampaignsResponse = t.TypeOf<typeof DetailedCampaignsResponse>;
export const DetailedCampaignsResponse = t.partial(
  {
    campaigns: tx.nullable(t.array(CampaignTreeResponse))
  },
  'DetailedCampaignsResponse'
);
/**
 * Usually reports only have two status, ACTIVE or PAUSED, for different date range, we add two more status, if a report start date is in future, status is PENDING and if a report's end date is passed, we set it to EXPIRED.
 * @example "ACTIVE"
 */
export enum Report_status {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  PAUSED = 'PAUSED'
}
export type Report = t.TypeOf<typeof Report>;
export const Report = t.type(
  {
    /**
     * Report id.
     */
    report_id: t.Integer,
    /**
     * Report name.
     */
    report_name: t.string,
    /**
     * Report type.
     */
    report_type: t.string,
    /**
     * Name of user who creates the report.
     */
    created_by: t.string,
    /**
     * List of Email recipients.
     */
    email_recipients: t.array(tx.EmailString),
    /**
     * Report start date.
     * @example "1980-01-31"
     */
    start_date: tx.DateString,
    /**
     * Report end date.
     * @example "1980-01-31"
     */
    end_date: tx.DateString,
    /**
     * Report created date.
     * @example "1980-01-31"
     */
    created_date: tx.DateString,
    /**
     * Usually reports only have two status, ACTIVE or PAUSED, for different date range, we add two more status, if a report start date is in future, status is PENDING and if a report's end date is passed, we set it to EXPIRED.
     * @example "ACTIVE"
     */
    status: tx.enumeration(Report_status, 'Report_status')
  },
  'Report'
);
export type Organization = t.TypeOf<typeof Organization>;
export const Organization = t.intersection([Entity, t.type({})], 'Organization');
export type DetailedReport = t.TypeOf<typeof DetailedReport>;
export const DetailedReport = t.intersection(
  [
    Report,
    t.type({
      /**
       * List of campaigns with name and id.
       */
      campaigns: t.array(Campaign),
      /**
       * List of accounts with name and id.
       */
      accounts: t.array(Account),
      /**
       * List of organizations with name and id.
       */
      organizations: t.array(Organization),
      delivery_schedule: DeliverySchedule
    })
  ],
  'DetailedReport'
);
export type GetAccountVerticalsResponse = t.TypeOf<typeof GetAccountVerticalsResponse>;
export const GetAccountVerticalsResponse = t.partial(
  {
    id: tx.nullable(t.Integer),
    name: tx.nullable(t.string)
  },
  'GetAccountVerticalsResponse'
);
export type GetBillingSourcesResponse = t.TypeOf<typeof GetBillingSourcesResponse>;
export const GetBillingSourcesResponse = t.partial(
  {
    billing_sources: tx.nullable(t.array(BillingSource))
  },
  'GetBillingSourcesResponse'
);
/**
 * EMAIL_NOT_ACTIVATED = 7, NO_VALID_ACCOUNT_LEVEL_CREDIT_CARD = 6, NO_VALID_CREDIT_CARD = 1, PAUSED_ACCOUNT_FOUND = 2, REPORTING_ONLY = 5, SPEND_LIMIT_TENANT = 4, VIEW_ONLY = 3
 * @example 1
 */
export enum GetNotificationsResponse_type {
  EMAIL_NOT_ACTIVATED = 7,
  NO_VALID_ACCOUNT_LEVEL_CREDIT_CARD = 6,
  NO_VALID_CREDIT_CARD = 1,
  PAUSED_ACCOUNT_FOUND = 2,
  REPORTING_ONLY = 5,
  SPEND_LIMIT_TENANT = 4,
  VIEW_ONLY = 3
}
export type GetNotificationsResponse = t.TypeOf<typeof GetNotificationsResponse>;
export const GetNotificationsResponse = t.partial(
  {
    /**
     * EMAIL_NOT_ACTIVATED = 7, NO_VALID_ACCOUNT_LEVEL_CREDIT_CARD = 6, NO_VALID_CREDIT_CARD = 1, PAUSED_ACCOUNT_FOUND = 2, REPORTING_ONLY = 5, SPEND_LIMIT_TENANT = 4, VIEW_ONLY = 3
     * @example 1
     */
    type: tx.nullable(tx.enumeration(GetNotificationsResponse_type, 'GetNotificationsResponse_type')),
    /**
     * Notification message
     */
    message: tx.nullable(t.string)
  },
  'GetNotificationsResponse'
);
/**
 * CHANNEL = 2, MANAGED = 0, SELFSERVE = 1
 */
export enum GetUserGroupsResponse_tenant_type {
  MANAGED = 0,
  SELFSERVE = 1,
  CHANNEL = 2
}
export type GetUserGroupsResponse = t.TypeOf<typeof GetUserGroupsResponse>;
export const GetUserGroupsResponse = t.partial(
  {
    group_id: tx.nullable(t.Integer),
    group_name: tx.nullable(t.string),
    /**
     * CHANNEL = 2, MANAGED = 0, SELFSERVE = 1
     */
    tenant_type: tx.nullable(tx.enumeration(GetUserGroupsResponse_tenant_type, 'GetUserGroupsResponse_tenant_type'))
  },
  'GetUserGroupsResponse'
);
export type GraylistConsentPost = t.TypeOf<typeof GraylistConsentPost>;
export const GraylistConsentPost = t.partial(
  {
    brand_id: tx.nullable(t.Integer),
    category_sic_code: tx.nullable(t.string)
  },
  'GraylistConsentPost'
);
export type Html5BannerPreviewRequest = t.TypeOf<typeof Html5BannerPreviewRequest>;
export const Html5BannerPreviewRequest = t.partial(
  {
    /**
     * JSON string containing fields required for Html5 preview
     */
    cmsConfig: tx.nullable(t.string)
  },
  'Html5BannerPreviewRequest'
);
export type Html5BannerPreviewResponse = t.TypeOf<typeof Html5BannerPreviewResponse>;
export const Html5BannerPreviewResponse = t.partial(
  {
    /**
     * Rendered Html5 preview
     */
    rendered_html5_preview: tx.nullable(t.string)
  },
  'Html5BannerPreviewResponse'
);
export type InsertCreativesfromAssetsRequest = t.TypeOf<typeof InsertCreativesfromAssetsRequest>;
export const InsertCreativesfromAssetsRequest = t.type(
  {
    asset_ids: t.array(t.Integer),
    adgroup_ids: t.array(t.Integer)
  },
  'InsertCreativesfromAssetsRequest'
);
/**
 * @example "Geotarget Upload"
 */
export enum JobResponse_type {
  GEOTARGET_UPLOAD = 'Geotarget Upload',
  CAMPAIGN_UPLOAD = 'Campaign Upload'
}
/**
 * @example "New"
 */
export enum JobResponse_status {
  NEW = 'New',
  IN_PROGRESS = 'In Progress'
}
export type JobResponse = t.TypeOf<typeof JobResponse>;
export const JobResponse = t.type(
  {
    id: t.Integer,
    tenant_id: t.Integer,
    campaign_id: t.Integer,
    adgroup_id: t.Integer,
    /**
     * @example "Geotarget Upload"
     */
    type: tx.enumeration(JobResponse_type, 'JobResponse_type'),
    /**
     * @example "New"
     */
    status: tx.enumeration(JobResponse_status, 'JobResponse_status'),
    /**
     * Last updated timestamp of job's DB record
     * @example "2019-01-31 00:00:00"
     */
    last_updated: tx.DateTimeString,
    /**
     * Timestamp of when job's progress last changed
     * @example "2019-01-31 00:00:00"
     */
    progress_last_updated: tx.DateTimeString,
    username: t.string,
    account_id: t.Integer,
    num_spreadsheet_rows: t.Integer,
    input_file_url: t.string,
    progress: t.number,
    /**
     * Job's output message
     */
    message: t.string,
    /**
     * Only applies to geotarget upload - if locations are to be appended to existing ones
     */
    append_data: t.boolean,
    output_file_url: t.string
  },
  'JobResponse'
);
/**
 * Job status
 * @example "New"
 */
export enum JobStatusResponse_status {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  FAILED = 'Failed',
  SUCCESS = 'Success',
  CANCELLED = 'Cancelled'
}
export type JobStatusResponse = t.TypeOf<typeof JobStatusResponse>;
export const JobStatusResponse = t.partial(
  {
    /**
     * Job status
     * @example "New"
     */
    status: tx.nullable(tx.enumeration(JobStatusResponse_status, 'JobStatusResponse_status')),
    /**
     * @example "Spreadsheet processed successfully"
     */
    message: tx.nullable(t.string),
    /**
     * Job progress value
     */
    value: tx.nullable(t.number),
    /**
     * Output spreadsheet URL (once job is complete)
     * @example "https://www.groundtruth.com"
     */
    output_url: tx.nullable(tx.UrlString)
  },
  'JobStatusResponse'
);
export type JobsResponse = t.TypeOf<typeof JobsResponse>;
export const JobsResponse = t.type(
  {
    jobs: t.array(JobResponse)
  },
  'JobsResponse'
);
export type LandingPagePreviewRequest = t.TypeOf<typeof LandingPagePreviewRequest>;
export const LandingPagePreviewRequest = t.partial(
  {
    lpConfig: tx.nullable(t.string)
  },
  'LandingPagePreviewRequest'
);
export type LandingPagePreviewResponse = t.TypeOf<typeof LandingPagePreviewResponse>;
export const LandingPagePreviewResponse = t.partial(
  {
    /**
     * Rendered landing page
     */
    rendered_landing_page: tx.nullable(t.string)
  },
  'LandingPagePreviewResponse'
);
export type LocationFiltersDownloadConfirmation = t.TypeOf<typeof LocationFiltersDownloadConfirmation>;
export const LocationFiltersDownloadConfirmation = t.type(
  {
    location_filters: t.array(AdGroupLocationFilter)
  },
  'LocationFiltersDownloadConfirmation'
);
/**
 * Build status of location group
 * @example "build"
 */
export enum LocationGroupStatusResponse_status {
  BUILD = 'build',
  EXPIRED = 'expired',
  NOT_REQUIRED = 'not_required',
  READY = 'ready',
  REBUILD = 'rebuild'
}
export type LocationGroupStatusResponse = t.TypeOf<typeof LocationGroupStatusResponse>;
export const LocationGroupStatusResponse = t.partial(
  {
    /**
     * Build status of location group
     * @example "build"
     */
    status: tx.nullable(tx.enumeration(LocationGroupStatusResponse_status, 'LocationGroupStatusResponse_status'))
  },
  'LocationGroupStatusResponse'
);
export type Pixels = t.TypeOf<typeof Pixels>;
export const Pixels = t.partial(
  {
    pixels: tx.nullable(t.array(Pixel))
  },
  'Pixels'
);
export type PreviewReport = t.TypeOf<typeof PreviewReport>;
export const PreviewReport = t.intersection(
  [
    Report,
    t.partial({
      /**
       * Total number of recipients for this report.
       */
      number_of_recipients: tx.nullable(t.Integer)
    })
  ],
  'PreviewReport'
);
export type PublisherReportChartAdgroupResponse = t.TypeOf<typeof PublisherReportChartAdgroupResponse>;
export const PublisherReportChartAdgroupResponse = t.intersection(
  [
    t.type({
      publisher: t.string
    }),
    t.partial({
      video_start: tx.nullable(t.Integer),
      video_q1: tx.nullable(t.Integer),
      video_q2: tx.nullable(t.Integer),
      video_q3: tx.nullable(t.Integer),
      video_complete: tx.nullable(t.Integer),
      vcr: tx.nullable(t.number),
      impression: tx.nullable(t.Integer)
    })
  ],
  'PublisherReportChartAdgroupResponse'
);
export type PublisherReportChartCampaignResponse = t.TypeOf<typeof PublisherReportChartCampaignResponse>;
export const PublisherReportChartCampaignResponse = t.intersection(
  [
    t.type({
      publisher: t.string
    }),
    t.partial({
      video_start: tx.nullable(t.Integer),
      video_q1: tx.nullable(t.Integer),
      video_q2: tx.nullable(t.Integer),
      video_q3: tx.nullable(t.Integer),
      video_complete: tx.nullable(t.Integer),
      vcr: tx.nullable(t.number),
      impression: tx.nullable(t.Integer)
    })
  ],
  'PublisherReportChartCampaignResponse'
);
export type PublisherReportingExportAdgroupResponse = t.TypeOf<typeof PublisherReportingExportAdgroupResponse>;
export const PublisherReportingExportAdgroupResponse = t.type(
  {
    video_start: t.Integer,
    video_q1: t.Integer,
    video_q2: t.Integer,
    video_q3: t.Integer,
    video_complete: t.Integer,
    vcr: t.number,
    publisher: t.string,
    impression: t.Integer,
    adgroup_id: t.Integer,
    adgroup_name: t.string
  },
  'PublisherReportingExportAdgroupResponse'
);
export type PublisherReportingExportCampaignResponse = t.TypeOf<typeof PublisherReportingExportCampaignResponse>;
export const PublisherReportingExportCampaignResponse = t.type(
  {
    video_start: t.Integer,
    video_q1: t.Integer,
    video_q2: t.Integer,
    video_q3: t.Integer,
    video_complete: t.Integer,
    vcr: t.number,
    publisher: t.string,
    impression: t.Integer,
    campaign_id: t.Integer,
    campaign_name: t.string
  },
  'PublisherReportingExportCampaignResponse'
);
export type PublisherReportingTableBreakdownResponse = t.TypeOf<typeof PublisherReportingTableBreakdownResponse>;
export const PublisherReportingTableBreakdownResponse = t.type(
  {
    video_start: t.Integer,
    video_q1: t.Integer,
    video_q2: t.Integer,
    video_q3: t.Integer,
    video_complete: t.Integer,
    vcr: t.number,
    publisher: t.string,
    impression: t.Integer
  },
  'PublisherReportingTableBreakdownResponse'
);
export type ReportCreateResponse = t.TypeOf<typeof ReportCreateResponse>;
export const ReportCreateResponse = t.partial(
  {
    /**
     * Report id.
     */
    report_id: tx.nullable(t.Integer)
  },
  'ReportCreateResponse'
);
export type ReportGet = t.TypeOf<typeof ReportGet>;
export const ReportGet = t.type(
  {
    report: DetailedReport
  },
  'ReportGet'
);
export type ReportInput = t.TypeOf<typeof ReportInput>;
export const ReportInput = t.intersection(
  [
    t.type({
      /**
       * Report name.
       */
      report_name: t.string,
      /**
       * Report type.
       */
      report_type: t.string,
      /**
       * Report start date.
       * @example "1980-01-31"
       */
      start_date: tx.DateString,
      /**
       * Report end date.
       * @example "1980-01-31"
       */
      end_date: tx.DateString,
      /**
       * List of Email recipients.
       */
      email_recipients: t.array(tx.EmailString),
      /**
       * List of campaign ids.
       */
      campaign_ids: t.array(t.Integer),
      /**
       * List of account ids.
       */
      account_ids: t.array(t.Integer),
      /**
       * List of organization ids.
       */
      organization_ids: t.array(t.Integer),
      send_now: t.boolean
    }),
    t.partial({
      delivery_schedule: tx.nullable(DeliverySchedule)
    })
  ],
  'ReportInput'
);
/**
 * PAUSED = PAUSED, PENDING = PENDING
 * @example "PAUSED"
 */
export enum ReportStatusRequest_status {
  PAUSED = 'PAUSED',
  PENDING = 'PENDING'
}
export type ReportStatusRequest = t.TypeOf<typeof ReportStatusRequest>;
export const ReportStatusRequest = t.type(
  {
    /**
     * PAUSED = PAUSED, PENDING = PENDING
     * @example "PAUSED"
     */
    status: tx.enumeration(ReportStatusRequest_status, 'ReportStatusRequest_status')
  },
  'ReportStatusRequest'
);
export type ReportType = t.TypeOf<typeof ReportType>;
export const ReportType = t.type(
  {
    report_type: t.string,
    display_name: t.string
  },
  'ReportType'
);
export type ReportTypesGetResponse = t.TypeOf<typeof ReportTypesGetResponse>;
export const ReportTypesGetResponse = t.type(
  {
    report_types: t.array(ReportType)
  },
  'ReportTypesGetResponse'
);
export type ReportsGetResponse = t.TypeOf<typeof ReportsGetResponse>;
export const ReportsGetResponse = t.type(
  {
    reports: t.array(PreviewReport)
  },
  'ReportsGetResponse'
);
export type SaveAllCreativesRequest = t.TypeOf<typeof SaveAllCreativesRequest>;
export const SaveAllCreativesRequest = t.partial(
  {
    rotateEvenly: tx.nullable(t.boolean),
    creatives: tx.nullable(t.array(CreativeRequestModelForV2))
  },
  'SaveAllCreativesRequest'
);
export type SearchCampaignsResponse = t.TypeOf<typeof SearchCampaignsResponse>;
export const SearchCampaignsResponse = t.type(
  {
    campaigns: t.array(CampaignSearchResult)
  },
  'SearchCampaignsResponse'
);
export type SearchRequest = t.TypeOf<typeof SearchRequest>;
export const SearchRequest = t.type(
  {
    search_text: t.string
  },
  'SearchRequest'
);
export type SensitivityConsentMultiCheckPost = t.TypeOf<typeof SensitivityConsentMultiCheckPost>;
export const SensitivityConsentMultiCheckPost = t.partial(
  {
    brand_ids: tx.nullable(t.array(t.Integer)),
    category_sic_codes: tx.nullable(t.array(t.string))
  },
  'SensitivityConsentMultiCheckPost'
);
export type SensitivityConsentMultiPost = t.TypeOf<typeof SensitivityConsentMultiPost>;
export const SensitivityConsentMultiPost = t.partial(
  {
    brand_id: tx.nullable(t.array(t.Integer)),
    category_sic_code: tx.nullable(t.array(t.string))
  },
  'SensitivityConsentMultiPost'
);
export type SensitivityConsentResponse = t.TypeOf<typeof SensitivityConsentResponse>;
export const SensitivityConsentResponse = t.type(
  {
    consent_given: t.boolean
  },
  'SensitivityConsentResponse'
);
export type SuccessResponse = t.TypeOf<typeof SuccessResponse>;
export const SuccessResponse = t.type(
  {
    success: t.boolean
  },
  'SuccessResponse'
);
export type TenantDashboardCampaign = t.TypeOf<typeof TenantDashboardCampaign>;
export const TenantDashboardCampaign = t.partial(
  {
    tenant_id: tx.nullable(t.Integer),
    organization_name: tx.nullable(t.string),
    organization_id: tx.nullable(t.Integer),
    account_name: tx.nullable(t.string),
    account_id: tx.nullable(t.Integer),
    currency: tx.nullable(t.string),
    campaign_name: tx.nullable(t.string),
    campaign_id: tx.nullable(t.Integer),
    /**
     * @example "1980-01-31"
     */
    start_date: tx.nullable(tx.DateString),
    /**
     * @example "1980-01-31"
     */
    end_date: tx.nullable(tx.DateString),
    status: tx.nullable(t.string),
    days_remaining: tx.nullable(t.Integer),
    budget: tx.nullable(t.number),
    salesforce_number: tx.nullable(t.string),
    pacing: tx.nullable(t.number),
    conversions: tx.nullable(t.Integer),
    impressions: tx.nullable(t.Integer),
    clicks: tx.nullable(t.Integer),
    visits: tx.nullable(t.Integer),
    vr: tx.nullable(t.number),
    vcr: tx.nullable(t.number),
    ctr: tx.nullable(t.number),
    /**
     * Today's spend for the campaign
     */
    daily_spend: tx.nullable(t.number),
    /**
     * Total spent for campaign, including today's spend
     */
    spends_spent: tx.nullable(t.number),
    secondary_actions: tx.nullable(t.Integer),
    sar: tx.nullable(t.number),
    reach: tx.nullable(t.number)
  },
  'TenantDashboardCampaign'
);
/**
 * @example "organization_name"
 */
export enum TenantDashboardExportRequest_columnsItem {
  ORGANIZATION_NAME = 'organization_name',
  ORGANIZATION_ID = 'organization_id',
  ACCOUNT_NAME = 'account_name',
  ACCOUNT_ID = 'account_id',
  CAMPAIGN_NAME = 'campaign_name',
  CAMPAIGN_ID = 'campaign_id',
  STATUS = 'status',
  DAYS_REMAINING = 'days_remaining',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  BUDGET = 'budget',
  SALESFORCE_NUMBER = 'salesforce_number',
  PACING = 'pacing',
  CONVERSIONS = 'conversions',
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  VISITS = 'visits',
  VCR = 'vcr',
  VR = 'vr',
  CTR = 'ctr',
  DAILY_SPEND = 'daily_spend',
  SPENDS_SPENT = 'spends_spent',
  SECONDARY_ACTIONS = 'secondary_actions',
  SAR = 'sar',
  REACH = 'reach'
}
export type TenantDashboardExportRequest = t.TypeOf<typeof TenantDashboardExportRequest>;
export const TenantDashboardExportRequest = t.intersection(
  [
    t.type({
      campaign_ids: t.array(t.Integer),
      columns: t.array(
        tx.enumeration(TenantDashboardExportRequest_columnsItem, 'TenantDashboardExportRequest_columnsItem')
      )
    }),
    t.partial({
      /**
       * @example "1980-01-31"
       */
      min_date: tx.nullable(tx.DateString),
      /**
       * @example "1980-01-31"
       */
      max_date: tx.nullable(tx.DateString)
    })
  ],
  'TenantDashboardExportRequest'
);
/**
 * @example "organization_name"
 */
export enum TenantDashboardRequest_sort_by {
  ORGANIZATION_NAME = 'organization_name',
  ORGANIZATION_ID = 'organization_id',
  ACCOUNT_NAME = 'account_name',
  ACCOUNT_ID = 'account_id',
  CAMPAIGN_NAME = 'campaign_name',
  CAMPAIGN_ID = 'campaign_id',
  STATUS = 'status',
  DAYS_REMAINING = 'days_remaining',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  BUDGET = 'budget',
  PACING = 'pacing',
  SALESFORCE_NUMBER = 'salesforce_number'
}
export type TenantDashboardRequest = t.TypeOf<typeof TenantDashboardRequest>;
export const TenantDashboardRequest = t.intersection(
  [
    t.type({
      /**
       * Number of campaigns to return
       * @example 10
       */
      limit: t.union([t.literal(10), t.literal(25), t.literal(50)]),
      page: t.Integer
    }),
    t.partial({
      organization_ids: tx.nullable(t.array(t.Integer)),
      account_ids: tx.nullable(t.array(t.Integer)),
      /**
       * Campaigns returned will have started on or after this date
       * @example "1980-01-31"
       */
      min_date: tx.nullable(tx.DateString),
      /**
       * Campaigns returned will have ended on or before this date
       * @example "1980-01-31"
       */
      max_date: tx.nullable(tx.DateString),
      /**
       * @example "organization_name"
       */
      sort_by: tx.nullable(tx.enumeration(TenantDashboardRequest_sort_by, 'TenantDashboardRequest_sort_by')),
      sort_desc: tx.nullable(t.boolean),
      /**
       * Search string for campaign name/ID search
       */
      search: tx.nullable(t.string)
    })
  ],
  'TenantDashboardRequest'
);
export type TenantDashboardResponse = t.TypeOf<typeof TenantDashboardResponse>;
export const TenantDashboardResponse = t.partial(
  {
    page: tx.nullable(t.Integer),
    /**
     * Total number of campaigns
     */
    total: tx.nullable(t.Integer),
    campaigns: tx.nullable(t.array(TenantDashboardCampaign))
  },
  'TenantDashboardResponse'
);
export type TenantHasCampaigns = t.TypeOf<typeof TenantHasCampaigns>;
export const TenantHasCampaigns = t.partial(
  {
    tenant_has_campaigns: tx.nullable(t.boolean)
  },
  'TenantHasCampaigns'
);
export type TestVastTagUrlRequest = t.TypeOf<typeof TestVastTagUrlRequest>;
export const TestVastTagUrlRequest = t.type(
  {
    vast_tag_url: t.string
  },
  'TestVastTagUrlRequest'
);
export type TestVastTagUrlResponse = t.TypeOf<typeof TestVastTagUrlResponse>;
export const TestVastTagUrlResponse = t.type(
  {
    result: t.string
  },
  'TestVastTagUrlResponse'
);
/**
 * @example "paused"
 */
export enum UpdateStatus_status {
  PAUSED = 'paused'
}
export type UpdateStatus = t.TypeOf<typeof UpdateStatus>;
export const UpdateStatus = t.type(
  {
    /**
     * @example "paused"
     */
    status: tx.enumeration(UpdateStatus_status, 'UpdateStatus_status')
  },
  'UpdateStatus'
);
/**
 * @example "header"
 */
export enum UploadCreativeImageRequest_type {
  HEADER = 'header',
  IMAGE_LANDING_PAGE = 'image_landing_page',
  COUPON = 'coupon'
}
export type UploadCreativeImageRequest = t.TypeOf<typeof UploadCreativeImageRequest>;
export const UploadCreativeImageRequest = t.type(
  {
    /**
     * @example "https://www.groundtruth.com"
     */
    imageUrl: tx.UrlString,
    /**
     * @example "header"
     */
    type: tx.enumeration(UploadCreativeImageRequest_type, 'UploadCreativeImageRequest_type')
  },
  'UploadCreativeImageRequest'
);
export type UploadCreativeImageResponse = t.TypeOf<typeof UploadCreativeImageResponse>;
export const UploadCreativeImageResponse = t.partial(
  {
    /**
     * Path to uploaded file in S3
     */
    s3_path: tx.nullable(t.string)
  },
  'UploadCreativeImageResponse'
);
export type UploadFileResponse = t.TypeOf<typeof UploadFileResponse>;
export const UploadFileResponse = t.partial(
  {
    /**
     * Path to uploaded file in S3
     */
    s3_path: tx.nullable(t.string)
  },
  'UploadFileResponse'
);
export type WhitelistAppNamesResponse = t.TypeOf<typeof WhitelistAppNamesResponse>;
export const WhitelistAppNamesResponse = t.type(
  {
    whitelist_app_names: t.array(t.string)
  },
  'WhitelistAppNamesResponse'
);
/**
 * Gets all matched audience categories
 */
export const post_audience_categories_api = http.post`/data/audiences/categories`
  .acceptsJsonOf(SearchRequest)
  .returnsJsonOf(CategorySearchResponse);
/**
 * Gets audience recommendations based on current selections
 */
export const post_audience_recommendations_api = http.post`/data/audiences/recommendations`
  .acceptsJsonOf(AudienceRecommendationsRequest)
  .returnsJsonOf(AudienceRecommendationsResponse);
/**
 * Searches audiences
 */
export const post_audience_search_api = http.post`/data/audiences/search`
  .acceptsJsonOf(SearchRequest)
  .returnsJsonOf(AudienceSearchResponse);
/**
 * Gets full audience taxonomy
 */
export const get_audience_taxonomy_api = http.get`/data/audiences/taxonomy`.returnsJsonOf(AudienceTaxonomyResponse);
/**
 * Gets campaigns with adgroups
 */
export const get_campaign_collection_resource = http.get`/data/campaigns`.returnsJsonOf(DetailedCampaignsResponse);
/**
 * Creates a new campaign
 */
export const post_campaign_collection_resource = http.post`/data/campaigns`
  .acceptsJsonOf(CampaignPostRequest)
  .returnsNone();
/**
 * Query parameters used in URL `/campaigns/adgroups` for {@linkcode get_get_ad_groups_api}.
 */
export type get_get_ad_groups_api_QueryParams = t.TypeOf<typeof get_get_ad_groups_api_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/adgroups` for {@linkcode get_get_ad_groups_api}.
 */
export const get_get_ad_groups_api_QueryParams = t.partial(
  {
    search: tx.nullable(t.string),
    limit: tx.nullable(t.string)
  },
  'get_get_ad_groups_api_QueryParams'
);
/**
 * Get all adgroups associated with a tenant for creative repository
 */
export const get_get_ad_groups_api = http.get`/data/campaigns/adgroups`
  .withParams(get_get_ad_groups_api_QueryParams)
  .returnsJsonOf(AdGroupsResponse);
/**
 * Path parameters used in URL `/campaigns/adgroups/{adgroup_id}/creatives/{creative_id}/cms_config` for {@linkcode get_creative_cms_config_api}.
 */
export type get_creative_cms_config_api_PathParams = t.TypeOf<typeof get_creative_cms_config_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/adgroups/{adgroup_id}/creatives/{creative_id}/cms_config` for {@linkcode get_creative_cms_config_api}.
 */
export const get_creative_cms_config_api_PathParams = t.type(
  {
    adgroup_id: t.Integer,
    creative_id: t.Integer
  },
  'get_creative_cms_config_api_PathParams'
);
/**
 * Gets cms_config for creative
 */
export const get_creative_cms_config_api = http.get<
  get_creative_cms_config_api_PathParams
>`/data/campaigns/adgroups/${p => p.adgroup_id}/creatives/${p => p.creative_id}/cms_config`.returnsJsonOf(
  CreativeCmsConfigResponse
);
/**
 * Gets channel campaigns
 */
export const get_channel_campaigns_api = http.get`/data/campaigns/channel_campaigns`.returnsJsonOf(
  ChannelCampaignsResponse
);
/**
 * Inserts creatives under the specified groups by using asset ids
 */
export const post_insert_creatives_from_assets_api = http.post`/data/campaigns/creatives`
  .acceptsJsonOf(InsertCreativesfromAssetsRequest)
  .returnsJsonOf(SuccessResponse);
/**
 * Gets field keys for creative
 */
export const get_field_keys_api = http.get`/data/campaigns/creatives/creative_fields`.returnsNone();
/**
 * Gets creative sizes
 */
export const get_creative_sizes_api = http.get`/data/campaigns/creatives/creative_sizes`.returnsJsonOf(
  CreativeSizesResponse
);
/**
 * Gets creative template ids
 */
export const get_creative_template_ids_api = http.get`/data/campaigns/creatives/creative_template_ids`.returnsJsonOf(
  CreativeTemplateIdsResponse
);
/**
 * Renders a HTML5 creative banner
 */
export const post_html5_banner_preview_api = http.post`/data/campaigns/creatives/html5_banner_preview`
  .acceptsJsonOf(Html5BannerPreviewRequest)
  .returnsJsonOf(Html5BannerPreviewResponse);
/**
 * Query parameters used in URL `/campaigns/creatives/html5_results` for {@linkcode get_creative_html5_statuses_api}.
 */
export type get_creative_html5_statuses_api_QueryParams = t.TypeOf<typeof get_creative_html5_statuses_api_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/creatives/html5_results` for {@linkcode get_creative_html5_statuses_api}.
 */
export const get_creative_html5_statuses_api_QueryParams = t.partial(
  {
    /**
     * Comma separated list of creative-ids
     */
    creative_ids: tx.nullable(t.array(t.Integer)),
    adgroup_id: tx.nullable(t.Integer)
  },
  'get_creative_html5_statuses_api_QueryParams'
);
/**
 * Get HTML5 creative processing statuses for the given list of creative id-s
 */
export const get_creative_html5_statuses_api = http.get`/data/campaigns/creatives/html5_results`
  .withParams(get_creative_html5_statuses_api_QueryParams)
  .returnsJsonOf(CreativeHTML5StatusListResponse);
/**
 * Gets static creative landing page configs
 */
export const get_landing_page_configs_api = http.get`/data/campaigns/creatives/landing_page_configs`.returnsNone();
/**
 * Renders a creative landing page
 */
export const post_landing_page_preview_api = http.post`/data/campaigns/creatives/landing_page_preview`
  .acceptsJsonOf(LandingPagePreviewRequest)
  .returnsJsonOf(LandingPagePreviewResponse);
/**
 * Path parameters used in URL `/campaigns/creatives/status/asset/{asset_id}` for {@linkcode put_update_creative_status_by_asset_idapi}.
 */
export type put_update_creative_status_by_asset_idapi_PathParams = t.TypeOf<
  typeof put_update_creative_status_by_asset_idapi_PathParams
>;
/**
 * Path parameters used in URL `/campaigns/creatives/status/asset/{asset_id}` for {@linkcode put_update_creative_status_by_asset_idapi}.
 */
export const put_update_creative_status_by_asset_idapi_PathParams = t.type(
  {
    asset_id: t.Integer
  },
  'put_update_creative_status_by_asset_idapi_PathParams'
);
/**
 * Updates the status of existing creatives associated with an asset
 */
export const put_update_creative_status_by_asset_idapi = http.put<
  put_update_creative_status_by_asset_idapi_PathParams
>`/data/campaigns/creatives/status/asset/${p => p.asset_id}`
  .acceptsJsonOf(UpdateStatus)
  .returnsJsonOf(SuccessResponse);
/**
 * Query parameters used in URL `/campaigns/creatives/transcoder_results` for {@linkcode get_creative_video_transcoder_statuses_api}.
 */
export type get_creative_video_transcoder_statuses_api_QueryParams = t.TypeOf<
  typeof get_creative_video_transcoder_statuses_api_QueryParams
>;
/**
 * Query parameters used in URL `/campaigns/creatives/transcoder_results` for {@linkcode get_creative_video_transcoder_statuses_api}.
 */
export const get_creative_video_transcoder_statuses_api_QueryParams = t.partial(
  {
    /**
     * Comma separated list of creative-ids
     */
    creative_ids: tx.nullable(t.array(t.Integer)),
    adgroup_id: tx.nullable(t.Integer)
  },
  'get_creative_video_transcoder_statuses_api_QueryParams'
);
/**
 * Get video creative processing statuses for the given list of creative id-s
 */
export const get_creative_video_transcoder_statuses_api = http.get`/data/campaigns/creatives/transcoder_results`
  .withParams(get_creative_video_transcoder_statuses_api_QueryParams)
  .returnsJsonOf(CreativeVideoTranscoderStatusListResponse);
/**
 * Tests vast tag url for video creative type
 */
export const post_vast_tag_url_validation_api = http.post`/data/campaigns/creatives/vast_validation`
  .acceptsJsonOf(TestVastTagUrlRequest)
  .returnsJsonOf(TestVastTagUrlResponse);
/**
 * Deletes campaigns
 */
export const post_delete_campaigns_api = http.post`/data/campaigns/deletions`
  .acceptsJsonOf(DeleteCampaignsRequest)
  .returnsJsonOf(SuccessResponse);
/**
 * Gets campaigns under entity type and ids
 */
export const post_search_campaigns_api = http.post`/data/campaigns/search`
  .acceptsJsonOf(CampaignsSearchRequest)
  .returnsJsonOf(SearchCampaignsResponse);
/**
 * Downloads campaign data in spreadsheet format
 */
export const post_export_spread_sheet_api = http.post`/data/campaigns/spreadsheet`.returnsNone();
/**
 * Used to populate the front-end tenant dashboard table.
 */
export const post_tenant_dashboard_resource = http.post`/data/campaigns/tenant_dashboard`
  .acceptsJsonOf(TenantDashboardRequest)
  .returnsJsonOf(TenantDashboardResponse);
/**
 * Used to export tenant dashboard table data based on the selected filters.
 */
export const post_tenant_dashboard_export_api = http.post`/data/campaigns/tenant_dashboard/export`
  .acceptsJsonOf(TenantDashboardExportRequest)
  .returnsNone();
/**
 * Tenant has campaign(s)
 */
export const get_tenant_has_campaign_api = http.get`/data/campaigns/tenant_has_campaign`.returnsJsonOf(
  TenantHasCampaigns
);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}` for {@linkcode delete_campaign_resource}.
 */
export type delete_campaign_resource_PathParams = t.TypeOf<typeof delete_campaign_resource_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}` for {@linkcode delete_campaign_resource}.
 */
export const delete_campaign_resource_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'delete_campaign_resource_PathParams'
);
/**
 * Deletes an existing campaign
 */
export const delete_campaign_resource = http.delete<delete_campaign_resource_PathParams>`/data/campaigns/${p =>
  p.campaign_id}`.returnsJsonOf(SuccessResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}` for {@linkcode put_campaign_resource}.
 */
export type put_campaign_resource_PathParams = t.TypeOf<typeof put_campaign_resource_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}` for {@linkcode put_campaign_resource}.
 */
export const put_campaign_resource_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'put_campaign_resource_PathParams'
);
/**
 * Updates an existing campaign
 */
export const put_campaign_resource = http.put<put_campaign_resource_PathParams>`/data/campaigns/${p =>
  p.campaign_id}`.returnsJsonOf(CampaignTreeResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups` for {@linkcode post_ad_group_collection_resource}.
 */
export type post_ad_group_collection_resource_PathParams = t.TypeOf<
  typeof post_ad_group_collection_resource_PathParams
>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups` for {@linkcode post_ad_group_collection_resource}.
 */
export const post_ad_group_collection_resource_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'post_ad_group_collection_resource_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups` for {@linkcode post_ad_group_collection_resource}.
 */
export type post_ad_group_collection_resource_QueryParams = t.TypeOf<
  typeof post_ad_group_collection_resource_QueryParams
>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups` for {@linkcode post_ad_group_collection_resource}.
 */
export const post_ad_group_collection_resource_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'post_ad_group_collection_resource_QueryParams'
);
/**
 * Creates a new ad group
 */
export const post_ad_group_collection_resource = http.post<
  post_ad_group_collection_resource_PathParams
>`/data/campaigns/${p => p.campaign_id}/adgroups`
  .withParams(post_ad_group_collection_resource_QueryParams)
  .acceptsJsonOf(AdGroupCreateRequest)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}` for {@linkcode get_ad_group_resource}.
 */
export type get_ad_group_resource_PathParams = t.TypeOf<typeof get_ad_group_resource_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}` for {@linkcode get_ad_group_resource}.
 */
export const get_ad_group_resource_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'get_ad_group_resource_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}` for {@linkcode get_ad_group_resource}.
 */
export type get_ad_group_resource_QueryParams = t.TypeOf<typeof get_ad_group_resource_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}` for {@linkcode get_ad_group_resource}.
 */
export const get_ad_group_resource_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'get_ad_group_resource_QueryParams'
);
/**
 * Gets an existing ad group
 */
export const get_ad_group_resource = http.get<get_ad_group_resource_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}`
  .withParams(get_ad_group_resource_QueryParams)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}` for {@linkcode delete_ad_group_resource}.
 */
export type delete_ad_group_resource_PathParams = t.TypeOf<typeof delete_ad_group_resource_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}` for {@linkcode delete_ad_group_resource}.
 */
export const delete_ad_group_resource_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'delete_ad_group_resource_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}` for {@linkcode delete_ad_group_resource}.
 */
export type delete_ad_group_resource_QueryParams = t.TypeOf<typeof delete_ad_group_resource_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}` for {@linkcode delete_ad_group_resource}.
 */
export const delete_ad_group_resource_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'delete_ad_group_resource_QueryParams'
);
/**
 * Deletes an existing ad group
 */
export const delete_ad_group_resource = http.delete<delete_ad_group_resource_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}`
  .withParams(delete_ad_group_resource_QueryParams)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}` for {@linkcode put_ad_group_resource}.
 */
export type put_ad_group_resource_PathParams = t.TypeOf<typeof put_ad_group_resource_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}` for {@linkcode put_ad_group_resource}.
 */
export const put_ad_group_resource_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'put_ad_group_resource_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}` for {@linkcode put_ad_group_resource}.
 */
export type put_ad_group_resource_QueryParams = t.TypeOf<typeof put_ad_group_resource_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}` for {@linkcode put_ad_group_resource}.
 */
export const put_ad_group_resource_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'put_ad_group_resource_QueryParams'
);
/**
 * Updates an existing ad group
 */
export const put_ad_group_resource = http.put<put_ad_group_resource_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}`
  .withParams(put_ad_group_resource_QueryParams)
  .acceptsJsonOf(AdGroupUpdate)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/avails` for {@linkcode post_adgroup_avails_api}.
 */
export type post_adgroup_avails_api_PathParams = t.TypeOf<typeof post_adgroup_avails_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/avails` for {@linkcode post_adgroup_avails_api}.
 */
export const post_adgroup_avails_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'post_adgroup_avails_api_PathParams'
);
/**
 * Gets avails for a given adgroup
 */
export const post_adgroup_avails_api = http.post<post_adgroup_avails_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}/avails`.returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/budget` for {@linkcode put_update_ad_group_budget_api}.
 */
export type put_update_ad_group_budget_api_PathParams = t.TypeOf<typeof put_update_ad_group_budget_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/budget` for {@linkcode put_update_ad_group_budget_api}.
 */
export const put_update_ad_group_budget_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'put_update_ad_group_budget_api_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/budget` for {@linkcode put_update_ad_group_budget_api}.
 */
export type put_update_ad_group_budget_api_QueryParams = t.TypeOf<typeof put_update_ad_group_budget_api_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/budget` for {@linkcode put_update_ad_group_budget_api}.
 */
export const put_update_ad_group_budget_api_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'put_update_ad_group_budget_api_QueryParams'
);
/**
 * Updates an existing ad group's budget and schedule
 */
export const put_update_ad_group_budget_api = http.put<put_update_ad_group_budget_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}/budget`
  .withParams(put_update_ad_group_budget_api_QueryParams)
  .acceptsJsonOf(AdGroupBudgetUpdate)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/clone` for {@linkcode post_ad_group_clone_api}.
 */
export type post_ad_group_clone_api_PathParams = t.TypeOf<typeof post_ad_group_clone_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/clone` for {@linkcode post_ad_group_clone_api}.
 */
export const post_ad_group_clone_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'post_ad_group_clone_api_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/clone` for {@linkcode post_ad_group_clone_api}.
 */
export type post_ad_group_clone_api_QueryParams = t.TypeOf<typeof post_ad_group_clone_api_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/clone` for {@linkcode post_ad_group_clone_api}.
 */
export const post_ad_group_clone_api_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'post_ad_group_clone_api_QueryParams'
);
/**
 * Clones an existing ad group
 */
export const post_ad_group_clone_api = http.post<post_ad_group_clone_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}/clone`
  .withParams(post_ad_group_clone_api_QueryParams)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives` for {@linkcode put_creative_collection_resource}.
 */
export type put_creative_collection_resource_PathParams = t.TypeOf<typeof put_creative_collection_resource_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives` for {@linkcode put_creative_collection_resource}.
 */
export const put_creative_collection_resource_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'put_creative_collection_resource_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives` for {@linkcode put_creative_collection_resource}.
 */
export type put_creative_collection_resource_QueryParams = t.TypeOf<
  typeof put_creative_collection_resource_QueryParams
>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives` for {@linkcode put_creative_collection_resource}.
 */
export const put_creative_collection_resource_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'put_creative_collection_resource_QueryParams'
);
/**
 * Updates existing creative/creatives
 */
export const put_creative_collection_resource = http.put<
  put_creative_collection_resource_PathParams
>`/data/campaigns/${p => p.campaign_id}/adgroups/${p => p.adgroup_id}/creatives`
  .withParams(put_creative_collection_resource_QueryParams)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/landing_page_location` for {@linkcode get_landing_page_location_api}.
 */
export type get_landing_page_location_api_PathParams = t.TypeOf<typeof get_landing_page_location_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/landing_page_location` for {@linkcode get_landing_page_location_api}.
 */
export const get_landing_page_location_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'get_landing_page_location_api_PathParams'
);
/**
 * Gets creative lp location
 */
export const get_landing_page_location_api = http.get<get_landing_page_location_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}/creatives/landing_page_location`.returnsJsonOf(
  CreativeLPLocationResponse
);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/v2` for {@linkcode put_save_all_creatives_api}.
 */
export type put_save_all_creatives_api_PathParams = t.TypeOf<typeof put_save_all_creatives_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/v2` for {@linkcode put_save_all_creatives_api}.
 */
export const put_save_all_creatives_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'put_save_all_creatives_api_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/v2` for {@linkcode put_save_all_creatives_api}.
 */
export type put_save_all_creatives_api_QueryParams = t.TypeOf<typeof put_save_all_creatives_api_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/v2` for {@linkcode put_save_all_creatives_api}.
 */
export const put_save_all_creatives_api_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'put_save_all_creatives_api_QueryParams'
);
/**
 * Saves existing creative/creatives
 */
export const put_save_all_creatives_api = http.put<put_save_all_creatives_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}/creatives/v2`
  .withParams(put_save_all_creatives_api_QueryParams)
  .acceptsJsonOf(SaveAllCreativesRequest)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}` for {@linkcode delete_creative_resource}.
 */
export type delete_creative_resource_PathParams = t.TypeOf<typeof delete_creative_resource_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}` for {@linkcode delete_creative_resource}.
 */
export const delete_creative_resource_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer,
    creative_id: t.Integer
  },
  'delete_creative_resource_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}` for {@linkcode delete_creative_resource}.
 */
export type delete_creative_resource_QueryParams = t.TypeOf<typeof delete_creative_resource_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}` for {@linkcode delete_creative_resource}.
 */
export const delete_creative_resource_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'delete_creative_resource_QueryParams'
);
/**
 * Deletes an existing creative
 */
export const delete_creative_resource = http.delete<delete_creative_resource_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}/creatives/${p => p.creative_id}`
  .withParams(delete_creative_resource_QueryParams)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}/clone` for {@linkcode post_creative_clone_api}.
 */
export type post_creative_clone_api_PathParams = t.TypeOf<typeof post_creative_clone_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}/clone` for {@linkcode post_creative_clone_api}.
 */
export const post_creative_clone_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer,
    creative_id: t.Integer
  },
  'post_creative_clone_api_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}/clone` for {@linkcode post_creative_clone_api}.
 */
export type post_creative_clone_api_QueryParams = t.TypeOf<typeof post_creative_clone_api_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}/clone` for {@linkcode post_creative_clone_api}.
 */
export const post_creative_clone_api_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'post_creative_clone_api_QueryParams'
);
/**
 * Clones an existing creative
 */
export const post_creative_clone_api = http.post<post_creative_clone_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}/creatives/${p => p.creative_id}/clone`
  .withParams(post_creative_clone_api_QueryParams)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}/doc_data` for {@linkcode get_doc_data_api}.
 */
export type get_doc_data_api_PathParams = t.TypeOf<typeof get_doc_data_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}/doc_data` for {@linkcode get_doc_data_api}.
 */
export const get_doc_data_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer,
    creative_id: t.Integer
  },
  'get_doc_data_api_PathParams'
);
/**
 * Gets preview link for creatives
 */
export const get_doc_data_api = http.get<get_doc_data_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}/creatives/${p => p.creative_id}/doc_data`.returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}/status` for {@linkcode put_update_creative_status_api}.
 */
export type put_update_creative_status_api_PathParams = t.TypeOf<typeof put_update_creative_status_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}/status` for {@linkcode put_update_creative_status_api}.
 */
export const put_update_creative_status_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer,
    creative_id: t.Integer
  },
  'put_update_creative_status_api_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}/status` for {@linkcode put_update_creative_status_api}.
 */
export type put_update_creative_status_api_QueryParams = t.TypeOf<typeof put_update_creative_status_api_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/creatives/{creative_id}/status` for {@linkcode put_update_creative_status_api}.
 */
export const put_update_creative_status_api_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'put_update_creative_status_api_QueryParams'
);
/**
 * Updates status for an existing creative
 */
export const put_update_creative_status_api = http.put<put_update_creative_status_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}/creatives/${p => p.creative_id}/status`
  .withParams(put_update_creative_status_api_QueryParams)
  .acceptsJsonOf(CreativeStatusRequest)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/device_types` for {@linkcode put_update_ad_group_device_type_api}.
 */
export type put_update_ad_group_device_type_api_PathParams = t.TypeOf<
  typeof put_update_ad_group_device_type_api_PathParams
>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/device_types` for {@linkcode put_update_ad_group_device_type_api}.
 */
export const put_update_ad_group_device_type_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'put_update_ad_group_device_type_api_PathParams'
);
/**
 * Updates an existing ad group's device type
 */
export const put_update_ad_group_device_type_api = http.put<
  put_update_ad_group_device_type_api_PathParams
>`/data/campaigns/${p => p.campaign_id}/adgroups/${p => p.adgroup_id}/device_types`
  .acceptsJsonOf(AdGroupDeviceTypeUpdate)
  .returnsJsonOf(SuccessResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/jobs/{job_id}/confirmation` for {@linkcode put_adgroup_confirm_job_api}.
 */
export type put_adgroup_confirm_job_api_PathParams = t.TypeOf<typeof put_adgroup_confirm_job_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/jobs/{job_id}/confirmation` for {@linkcode put_adgroup_confirm_job_api}.
 */
export const put_adgroup_confirm_job_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer,
    job_id: t.Integer
  },
  'put_adgroup_confirm_job_api_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/jobs/{job_id}/confirmation` for {@linkcode put_adgroup_confirm_job_api}.
 */
export type put_adgroup_confirm_job_api_QueryParams = t.TypeOf<typeof put_adgroup_confirm_job_api_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/jobs/{job_id}/confirmation` for {@linkcode put_adgroup_confirm_job_api}.
 */
export const put_adgroup_confirm_job_api_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'put_adgroup_confirm_job_api_QueryParams'
);
/**
 * Confirms job results for an existing adgroup
 */
export const put_adgroup_confirm_job_api = http.put<put_adgroup_confirm_job_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}/jobs/${p => p.job_id}/confirmation`
  .withParams(put_adgroup_confirm_job_api_QueryParams)
  .returnsJsonOf(LocationFiltersDownloadConfirmation);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/location_filters/download` for {@linkcode get_download_adgroup_location_filters_api}.
 */
export type get_download_adgroup_location_filters_api_PathParams = t.TypeOf<
  typeof get_download_adgroup_location_filters_api_PathParams
>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/location_filters/download` for {@linkcode get_download_adgroup_location_filters_api}.
 */
export const get_download_adgroup_location_filters_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'get_download_adgroup_location_filters_api_PathParams'
);
/**
 * Downloads all location filters of an adgroup
 */
export const get_download_adgroup_location_filters_api = http.get<
  get_download_adgroup_location_filters_api_PathParams
>`/data/campaigns/${p => p.campaign_id}/adgroups/${p => p.adgroup_id}/location_filters/download`.returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/location_groups/sensitivity` for {@linkcode get_ad_group_location_filter_sensitivity_api}.
 */
export type get_ad_group_location_filter_sensitivity_api_PathParams = t.TypeOf<
  typeof get_ad_group_location_filter_sensitivity_api_PathParams
>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/location_groups/sensitivity` for {@linkcode get_ad_group_location_filter_sensitivity_api}.
 */
export const get_ad_group_location_filter_sensitivity_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'get_ad_group_location_filter_sensitivity_api_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/location_groups/sensitivity` for {@linkcode get_ad_group_location_filter_sensitivity_api}.
 */
export type get_ad_group_location_filter_sensitivity_api_QueryParams = t.TypeOf<
  typeof get_ad_group_location_filter_sensitivity_api_QueryParams
>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/location_groups/sensitivity` for {@linkcode get_ad_group_location_filter_sensitivity_api}.
 */
export const get_ad_group_location_filter_sensitivity_api_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'get_ad_group_location_filter_sensitivity_api_QueryParams'
);
/**
 * Gets an existing ad group sensitivity data for Location groups
 */
export const get_ad_group_location_filter_sensitivity_api = http.get<
  get_ad_group_location_filter_sensitivity_api_PathParams
>`/data/campaigns/${p => p.campaign_id}/adgroups/${p => p.adgroup_id}/location_groups/sensitivity`
  .withParams(get_ad_group_location_filter_sensitivity_api_QueryParams)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/pixels` for {@linkcode get_adgroup_pixels_api}.
 */
export type get_adgroup_pixels_api_PathParams = t.TypeOf<typeof get_adgroup_pixels_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/pixels` for {@linkcode get_adgroup_pixels_api}.
 */
export const get_adgroup_pixels_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'get_adgroup_pixels_api_PathParams'
);
/**
 * Gets pixels for a given adgroup
 */
export const get_adgroup_pixels_api = http.get<get_adgroup_pixels_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}/pixels`.returnsJsonOf(Pixels);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/product` for {@linkcode put_update_ad_group_product_api}.
 */
export type put_update_ad_group_product_api_PathParams = t.TypeOf<typeof put_update_ad_group_product_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/product` for {@linkcode put_update_ad_group_product_api}.
 */
export const put_update_ad_group_product_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'put_update_ad_group_product_api_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/product` for {@linkcode put_update_ad_group_product_api}.
 */
export type put_update_ad_group_product_api_QueryParams = t.TypeOf<typeof put_update_ad_group_product_api_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/product` for {@linkcode put_update_ad_group_product_api}.
 */
export const put_update_ad_group_product_api_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'put_update_ad_group_product_api_QueryParams'
);
/**
 * Updates an existing ad group's product type
 */
export const put_update_ad_group_product_api = http.put<
  put_update_ad_group_product_api_PathParams
>`/data/campaigns/${p => p.campaign_id}/adgroups/${p => p.adgroup_id}/product`
  .withParams(put_update_ad_group_product_api_QueryParams)
  .acceptsJsonOf(AdGroupProductUpdate)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/sensitivity_consent` for {@linkcode post_adgroup_sensitivity_consent_api}.
 */
export type post_adgroup_sensitivity_consent_api_PathParams = t.TypeOf<
  typeof post_adgroup_sensitivity_consent_api_PathParams
>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/sensitivity_consent` for {@linkcode post_adgroup_sensitivity_consent_api}.
 */
export const post_adgroup_sensitivity_consent_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'post_adgroup_sensitivity_consent_api_PathParams'
);
/**
 * Confirms sensitivity consent for an existing adgroup
 */
export const post_adgroup_sensitivity_consent_api = http.post<
  post_adgroup_sensitivity_consent_api_PathParams
>`/data/campaigns/${p => p.campaign_id}/adgroups/${p => p.adgroup_id}/sensitivity_consent`
  .acceptsJsonOf(GraylistConsentPost)
  .returnsJsonOf(SuccessResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/sensitivity_consent_multi` for {@linkcode post_adgroup_sensitivity_consent_multi_api}.
 */
export type post_adgroup_sensitivity_consent_multi_api_PathParams = t.TypeOf<
  typeof post_adgroup_sensitivity_consent_multi_api_PathParams
>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/sensitivity_consent_multi` for {@linkcode post_adgroup_sensitivity_consent_multi_api}.
 */
export const post_adgroup_sensitivity_consent_multi_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'post_adgroup_sensitivity_consent_multi_api_PathParams'
);
/**
 * Confirms sensitivity consent for an existing adgroup
 */
export const post_adgroup_sensitivity_consent_multi_api = http.post<
  post_adgroup_sensitivity_consent_multi_api_PathParams
>`/data/campaigns/${p => p.campaign_id}/adgroups/${p => p.adgroup_id}/sensitivity_consent_multi`
  .acceptsJsonOf(SensitivityConsentMultiPost)
  .returnsJsonOf(SuccessResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/status` for {@linkcode put_update_ad_group_status_api}.
 */
export type put_update_ad_group_status_api_PathParams = t.TypeOf<typeof put_update_ad_group_status_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/status` for {@linkcode put_update_ad_group_status_api}.
 */
export const put_update_ad_group_status_api_PathParams = t.type(
  {
    campaign_id: t.Integer,
    adgroup_id: t.Integer
  },
  'put_update_ad_group_status_api_PathParams'
);
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/status` for {@linkcode put_update_ad_group_status_api}.
 */
export type put_update_ad_group_status_api_QueryParams = t.TypeOf<typeof put_update_ad_group_status_api_QueryParams>;
/**
 * Query parameters used in URL `/campaigns/{campaign_id}/adgroups/{adgroup_id}/status` for {@linkcode put_update_ad_group_status_api}.
 */
export const put_update_ad_group_status_api_QueryParams = t.partial(
  {
    bulk_upload: tx.nullable(t.string),
    trafficking_api: tx.nullable(t.string)
  },
  'put_update_ad_group_status_api_QueryParams'
);
/**
 * Updates the status of an existing ad group
 */
export const put_update_ad_group_status_api = http.put<put_update_ad_group_status_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/adgroups/${p => p.adgroup_id}/status`
  .withParams(put_update_ad_group_status_api_QueryParams)
  .acceptsJsonOf(UpdateStatus)
  .returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/blocked_app_names` for {@linkcode get_campaign_blocked_app_names_api}.
 */
export type get_campaign_blocked_app_names_api_PathParams = t.TypeOf<
  typeof get_campaign_blocked_app_names_api_PathParams
>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/blocked_app_names` for {@linkcode get_campaign_blocked_app_names_api}.
 */
export const get_campaign_blocked_app_names_api_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'get_campaign_blocked_app_names_api_PathParams'
);
/**
 * Gets campaign blocked app names
 */
export const get_campaign_blocked_app_names_api = http.get<
  get_campaign_blocked_app_names_api_PathParams
>`/data/campaigns/${p => p.campaign_id}/blocked_app_names`.returnsJsonOf(BlockedAppNamesResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/bulk_upload_template` for {@linkcode get_campaign_bulk_upload_template_api}.
 */
export type get_campaign_bulk_upload_template_api_PathParams = t.TypeOf<
  typeof get_campaign_bulk_upload_template_api_PathParams
>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/bulk_upload_template` for {@linkcode get_campaign_bulk_upload_template_api}.
 */
export const get_campaign_bulk_upload_template_api_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'get_campaign_bulk_upload_template_api_PathParams'
);
/**
 * Gets campaign bulk upload template
 */
export const get_campaign_bulk_upload_template_api = http.get<
  get_campaign_bulk_upload_template_api_PathParams
>`/data/campaigns/${p => p.campaign_id}/bulk_upload_template`.returnsJsonOf(BulkUploadTemplateResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/changelog` for {@linkcode get_change_log_api}.
 */
export type get_change_log_api_PathParams = t.TypeOf<typeof get_change_log_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/changelog` for {@linkcode get_change_log_api}.
 */
export const get_change_log_api_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'get_change_log_api_PathParams'
);
/**
 * Downloads a changelog spreadsheet for a given campaign
 */
export const get_change_log_api = http.get<get_change_log_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/changelog`.returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/clone` for {@linkcode post_campaign_clone_api}.
 */
export type post_campaign_clone_api_PathParams = t.TypeOf<typeof post_campaign_clone_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/clone` for {@linkcode post_campaign_clone_api}.
 */
export const post_campaign_clone_api_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'post_campaign_clone_api_PathParams'
);
/**
 * Clones an existing campaign
 */
export const post_campaign_clone_api = http.post<post_campaign_clone_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/clone`.returnsJsonOf(CampaignTreeResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/domains` for {@linkcode get_campaign_domains_api}.
 */
export type get_campaign_domains_api_PathParams = t.TypeOf<typeof get_campaign_domains_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/domains` for {@linkcode get_campaign_domains_api}.
 */
export const get_campaign_domains_api_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'get_campaign_domains_api_PathParams'
);
/**
 * Gets campaign domains
 */
export const get_campaign_domains_api = http.get<get_campaign_domains_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/domains`.returnsJsonOf(CampaignDomainsResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/pdf` for {@linkcode get_export_pdf_api}.
 */
export type get_export_pdf_api_PathParams = t.TypeOf<typeof get_export_pdf_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/pdf` for {@linkcode get_export_pdf_api}.
 */
export const get_export_pdf_api_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'get_export_pdf_api_PathParams'
);
/**
 * Downloads campaign data in a PDF format
 */
export const get_export_pdf_api = http.get<get_export_pdf_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/pdf`.returnsNone();
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/pixels` for {@linkcode get_campaign_pixels_api}.
 */
export type get_campaign_pixels_api_PathParams = t.TypeOf<typeof get_campaign_pixels_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/pixels` for {@linkcode get_campaign_pixels_api}.
 */
export const get_campaign_pixels_api_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'get_campaign_pixels_api_PathParams'
);
/**
 * Gets pixels for a given campaign
 */
export const get_campaign_pixels_api = http.get<get_campaign_pixels_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/pixels`.returnsJsonOf(Pixels);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/sensitivity_consent` for {@linkcode get_sensitivity_consent_api}.
 */
export type get_sensitivity_consent_api_PathParams = t.TypeOf<typeof get_sensitivity_consent_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/sensitivity_consent` for {@linkcode get_sensitivity_consent_api}.
 */
export const get_sensitivity_consent_api_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'get_sensitivity_consent_api_PathParams'
);
/**
 * Determines if consent has been given for a sensitive brand or category in a given campaign
 */
export const get_sensitivity_consent_api = http.get<get_sensitivity_consent_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/sensitivity_consent`.returnsJsonOf(SensitivityConsentResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/sensitivity_consent_multi` for {@linkcode post_sensitivity_consent_multi_api}.
 */
export type post_sensitivity_consent_multi_api_PathParams = t.TypeOf<
  typeof post_sensitivity_consent_multi_api_PathParams
>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/sensitivity_consent_multi` for {@linkcode post_sensitivity_consent_multi_api}.
 */
export const post_sensitivity_consent_multi_api_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'post_sensitivity_consent_multi_api_PathParams'
);
/**
 * Determines consent status for sensitive brands or categories for a campaign
 */
export const post_sensitivity_consent_multi_api = http.post<
  post_sensitivity_consent_multi_api_PathParams
>`/data/campaigns/${p => p.campaign_id}/sensitivity_consent_multi`
  .acceptsJsonOf(SensitivityConsentMultiCheckPost)
  .returnsJsonOf(SensitivityConsentResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/settings` for {@linkcode get_campaign_setting_api}.
 */
export type get_campaign_setting_api_PathParams = t.TypeOf<typeof get_campaign_setting_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/settings` for {@linkcode get_campaign_setting_api}.
 */
export const get_campaign_setting_api_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'get_campaign_setting_api_PathParams'
);
/**
 * Gets settings from a campaign
 */
export const get_campaign_setting_api = http.get<get_campaign_setting_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/settings`.returnsJsonOf(CampaignSettingsResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/status` for {@linkcode put_update_campaign_status_api}.
 */
export type put_update_campaign_status_api_PathParams = t.TypeOf<typeof put_update_campaign_status_api_PathParams>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/status` for {@linkcode put_update_campaign_status_api}.
 */
export const put_update_campaign_status_api_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'put_update_campaign_status_api_PathParams'
);
/**
 * Updates campaign status
 */
export const put_update_campaign_status_api = http.put<put_update_campaign_status_api_PathParams>`/data/campaigns/${p =>
  p.campaign_id}/status`.returnsJsonOf(CampaignTreeResponse);
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/whitelist_app_names` for {@linkcode get_campaign_whitelist_app_names_api}.
 */
export type get_campaign_whitelist_app_names_api_PathParams = t.TypeOf<
  typeof get_campaign_whitelist_app_names_api_PathParams
>;
/**
 * Path parameters used in URL `/campaigns/{campaign_id}/whitelist_app_names` for {@linkcode get_campaign_whitelist_app_names_api}.
 */
export const get_campaign_whitelist_app_names_api_PathParams = t.type(
  {
    campaign_id: t.Integer
  },
  'get_campaign_whitelist_app_names_api_PathParams'
);
/**
 * Gets campaign whitelisted app names
 */
export const get_campaign_whitelist_app_names_api = http.get<
  get_campaign_whitelist_app_names_api_PathParams
>`/data/campaigns/${p => p.campaign_id}/whitelist_app_names`.returnsJsonOf(WhitelistAppNamesResponse);
/**
 * Makes pos connection
 */
export const post_check_growth_connect = http.post`/data/check-growth/connect`
  .acceptsJsonOf(CheckGrowthConnectDisconnectRequest)
  .returnsJsonOf(CheckGrowthConnectDisconnectResponse);
/**
 * Path parameters used in URL `/check-growth/connections/{account_id}` for {@linkcode get_check_growth_connections}.
 */
export type get_check_growth_connections_PathParams = t.TypeOf<typeof get_check_growth_connections_PathParams>;
/**
 * Path parameters used in URL `/check-growth/connections/{account_id}` for {@linkcode get_check_growth_connections}.
 */
export const get_check_growth_connections_PathParams = t.type(
  {
    account_id: t.Integer
  },
  'get_check_growth_connections_PathParams'
);
/**
 * Gets pos connections
 */
export const get_check_growth_connections = http.get<
  get_check_growth_connections_PathParams
>`/data/check-growth/connections/${p => p.account_id}`.returnsJsonOf(t.array(CheckGrowthConnectionsResponse));
/**
 * Makes pos disconnect
 */
export const post_check_growth_disconnect = http.post`/data/check-growth/disconnect`
  .acceptsJsonOf(CheckGrowthConnectDisconnectRequest)
  .returnsJsonOf(CheckGrowthConnectDisconnectResponse);
/**
 * Insert records for creative assets already uploaded to the CDN
 */
export const post_creative_asset_collection_resource = http.post`/data/creative_assets`
  .acceptsJsonOf(CreateAssetsRequest)
  .returnsJsonOf(CreateAssetsResponse);
/**
 * Delete a list of creative assets
 */
export const post_delete_creative_assets_api = http.post`/data/creative_assets/deletions`
  .acceptsJsonOf(CreativeAssetsDeleteRequest)
  .returnsJsonOf(CreativeAssetsDeleteResponse);
/**
 * Download a creatives spreadsheet based on selected creative assets
 */
export const post_creative_asset_download_api = http.post`/data/creative_assets/download`
  .acceptsJsonOf(CreativeAssetsDownloadRequest)
  .returnsNone();
/**
 * Get limited assets using pagination associated with a tenant
 */
export const post_creative_asset_collection = http.post`/data/creative_assets/v2`
  .acceptsJsonOf(CreativeAssetsRequest)
  .returnsJsonOf(CreativeAssetsResponse);
/**
 * Path parameters used in URL `/creative_assets/{asset_id}` for {@linkcode put_creative_asset_resource}.
 */
export type put_creative_asset_resource_PathParams = t.TypeOf<typeof put_creative_asset_resource_PathParams>;
/**
 * Path parameters used in URL `/creative_assets/{asset_id}` for {@linkcode put_creative_asset_resource}.
 */
export const put_creative_asset_resource_PathParams = t.type(
  {
    asset_id: t.Integer
  },
  'put_creative_asset_resource_PathParams'
);
/**
 * Update an existing creative asset
 */
export const put_creative_asset_resource = http.put<put_creative_asset_resource_PathParams>`/data/creative_assets/${p =>
  p.asset_id}`
  .acceptsJsonOf(CreativeAssetUpdateRequest)
  .returnsJsonOf(SuccessResponse);
/**
 * Path parameters used in URL `/creative_assets/{asset_id}/adgroups` for {@linkcode get_creative_asset_ad_groups_resource}.
 */
export type get_creative_asset_ad_groups_resource_PathParams = t.TypeOf<
  typeof get_creative_asset_ad_groups_resource_PathParams
>;
/**
 * Path parameters used in URL `/creative_assets/{asset_id}/adgroups` for {@linkcode get_creative_asset_ad_groups_resource}.
 */
export const get_creative_asset_ad_groups_resource_PathParams = t.type(
  {
    asset_id: t.Integer
  },
  'get_creative_asset_ad_groups_resource_PathParams'
);
/**
 * Get associated ad groups for a creative asset
 */
export const get_creative_asset_ad_groups_resource = http.get<
  get_creative_asset_ad_groups_resource_PathParams
>`/data/creative_assets/${p => p.asset_id}/adgroups`.returnsJsonOf(CreativeAssetAssociatedAdGroupsResponse);
export enum get_job_collection_resource_QueryParams_filter {
  UNHEALTHY = 'unhealthy'
}
/**
 * Query parameters used in URL `/jobs` for {@linkcode get_job_collection_resource}.
 */
export type get_job_collection_resource_QueryParams = t.TypeOf<typeof get_job_collection_resource_QueryParams>;
/**
 * Query parameters used in URL `/jobs` for {@linkcode get_job_collection_resource}.
 */
export const get_job_collection_resource_QueryParams = t.partial(
  {
    filter: tx.nullable(
      tx.enumeration(get_job_collection_resource_QueryParams_filter, 'get_job_collection_resource_QueryParams_filter')
    ),
    campaign_id: tx.nullable(t.Integer),
    adgroup_id: tx.nullable(t.Integer)
  },
  'get_job_collection_resource_QueryParams'
);
/**
 * Gets all active/queued jobs
 */
export const get_job_collection_resource = http.get`/data/jobs`
  .withParams(get_job_collection_resource_QueryParams)
  .returnsJsonOf(JobsResponse);
/**
 * Creates a new background job
 */
export const post_job_collection_resource = http.post`/data/jobs`
  .acceptsJsonOf(CreateJobExpected)
  .returnsJsonOf(CreateJobResponse);
/**
 * Path parameters used in URL `/jobs/{job_id}/cancel` for {@linkcode put_cancel_job_api}.
 */
export type put_cancel_job_api_PathParams = t.TypeOf<typeof put_cancel_job_api_PathParams>;
/**
 * Path parameters used in URL `/jobs/{job_id}/cancel` for {@linkcode put_cancel_job_api}.
 */
export const put_cancel_job_api_PathParams = t.type(
  {
    job_id: t.Integer
  },
  'put_cancel_job_api_PathParams'
);
/**
 * Cancels a background job. This only works for geotarget upload jobs.
 */
export const put_cancel_job_api = http.put<put_cancel_job_api_PathParams>`/data/jobs/${p =>
  p.job_id}/cancel`.returnsNone();
/**
 * Path parameters used in URL `/jobs/{job_id}/confirm` for {@linkcode put_confirm_job_api}.
 */
export type put_confirm_job_api_PathParams = t.TypeOf<typeof put_confirm_job_api_PathParams>;
/**
 * Path parameters used in URL `/jobs/{job_id}/confirm` for {@linkcode put_confirm_job_api}.
 */
export const put_confirm_job_api_PathParams = t.type(
  {
    job_id: t.Integer
  },
  'put_confirm_job_api_PathParams'
);
/**
 * Confirms the results of a background job
 */
export const put_confirm_job_api = http.put<put_confirm_job_api_PathParams>`/data/jobs/${p =>
  p.job_id}/confirm`.returnsNone();
/**
 * Path parameters used in URL `/jobs/{job_id}/status` for {@linkcode get_job_status_api}.
 */
export type get_job_status_api_PathParams = t.TypeOf<typeof get_job_status_api_PathParams>;
/**
 * Path parameters used in URL `/jobs/{job_id}/status` for {@linkcode get_job_status_api}.
 */
export const get_job_status_api_PathParams = t.type(
  {
    job_id: t.Integer
  },
  'get_job_status_api_PathParams'
);
/**
 * Retrieves status information about a background job
 */
export const get_job_status_api = http.get<get_job_status_api_PathParams>`/data/jobs/${p =>
  p.job_id}/status`.returnsJsonOf(JobStatusResponse);
/**
 * Path parameters used in URL `/location_groups/{location_group_id}/status` for {@linkcode get_location_group_status_api}.
 */
export type get_location_group_status_api_PathParams = t.TypeOf<typeof get_location_group_status_api_PathParams>;
/**
 * Path parameters used in URL `/location_groups/{location_group_id}/status` for {@linkcode get_location_group_status_api}.
 */
export const get_location_group_status_api_PathParams = t.type(
  {
    location_group_id: t.Integer
  },
  'get_location_group_status_api_PathParams'
);
export enum get_location_group_status_api_QueryParams_product_flag {
  NEIGHBORHOOD = 'neighborhood',
  AUDIENCE = 'audience'
}
/**
 * Query parameters used in URL `/location_groups/{location_group_id}/status` for {@linkcode get_location_group_status_api}.
 */
export type get_location_group_status_api_QueryParams = t.TypeOf<typeof get_location_group_status_api_QueryParams>;
/**
 * Query parameters used in URL `/location_groups/{location_group_id}/status` for {@linkcode get_location_group_status_api}.
 */
export const get_location_group_status_api_QueryParams = t.type(
  {
    product_flag: tx.enumeration(
      get_location_group_status_api_QueryParams_product_flag,
      'get_location_group_status_api_QueryParams_product_flag'
    )
  },
  'get_location_group_status_api_QueryParams'
);
/**
 * Gets the build status of a neighborhood or audience location group
 */
export const get_location_group_status_api = http.get<
  get_location_group_status_api_PathParams
>`/data/location_groups/${p => p.location_group_id}/status`
  .withParams(get_location_group_status_api_QueryParams)
  .returnsJsonOf(LocationGroupStatusResponse);
/**
 * Gets all notifications for a tenant/user
 */
export const get_notification_collection_resource = http.get`/data/notifications`.returnsJsonOf(
  GetNotificationsResponse
);
export enum get_publisher_reporting_chart_for_adgroup_QueryParams_metric {
  VIDEO_START = 'video_start',
  VIDEO_Q1 = 'video_q1',
  VIDEO_Q2 = 'video_q2',
  VIDEO_Q3 = 'video_q3',
  VIDEO_COMPLETE = 'video_complete',
  VCR = 'vcr',
  IMPRESSION = 'impression'
}
/**
 * Query parameters used in URL `/report/publisher/chart/adgroup` for {@linkcode get_publisher_reporting_chart_for_adgroup}.
 */
export type get_publisher_reporting_chart_for_adgroup_QueryParams = t.TypeOf<
  typeof get_publisher_reporting_chart_for_adgroup_QueryParams
>;
/**
 * Query parameters used in URL `/report/publisher/chart/adgroup` for {@linkcode get_publisher_reporting_chart_for_adgroup}.
 */
export const get_publisher_reporting_chart_for_adgroup_QueryParams = t.intersection(
  [
    t.type({
      adgroup_id: t.Integer,
      metric: tx.enumeration(
        get_publisher_reporting_chart_for_adgroup_QueryParams_metric,
        'get_publisher_reporting_chart_for_adgroup_QueryParams_metric'
      )
    }),
    t.partial({
      start_date: tx.nullable(tx.DateString),
      end_date: tx.nullable(tx.DateString)
    })
  ],
  'get_publisher_reporting_chart_for_adgroup_QueryParams'
);
export const get_publisher_reporting_chart_for_adgroup = http.get`/data/report/publisher/chart/adgroup`
  .withParams(get_publisher_reporting_chart_for_adgroup_QueryParams)
  .returnsJsonOf(t.array(PublisherReportChartAdgroupResponse));
export enum get_publisher_reporting_chart_for_campaign_QueryParams_metric {
  VIDEO_START = 'video_start',
  VIDEO_Q1 = 'video_q1',
  VIDEO_Q2 = 'video_q2',
  VIDEO_Q3 = 'video_q3',
  VIDEO_COMPLETE = 'video_complete',
  VCR = 'vcr',
  IMPRESSION = 'impression'
}
/**
 * Query parameters used in URL `/report/publisher/chart/campaign` for {@linkcode get_publisher_reporting_chart_for_campaign}.
 */
export type get_publisher_reporting_chart_for_campaign_QueryParams = t.TypeOf<
  typeof get_publisher_reporting_chart_for_campaign_QueryParams
>;
/**
 * Query parameters used in URL `/report/publisher/chart/campaign` for {@linkcode get_publisher_reporting_chart_for_campaign}.
 */
export const get_publisher_reporting_chart_for_campaign_QueryParams = t.intersection(
  [
    t.type({
      campaign_id: t.Integer,
      metric: tx.enumeration(
        get_publisher_reporting_chart_for_campaign_QueryParams_metric,
        'get_publisher_reporting_chart_for_campaign_QueryParams_metric'
      )
    }),
    t.partial({
      start_date: tx.nullable(tx.DateString),
      end_date: tx.nullable(tx.DateString)
    })
  ],
  'get_publisher_reporting_chart_for_campaign_QueryParams'
);
export const get_publisher_reporting_chart_for_campaign = http.get`/data/report/publisher/chart/campaign`
  .withParams(get_publisher_reporting_chart_for_campaign_QueryParams)
  .returnsJsonOf(t.array(PublisherReportChartCampaignResponse));
/**
 * Query parameters used in URL `/report/publisher/export/adgroup` for {@linkcode get_publisher_reporting_export_for_ad_group}.
 */
export type get_publisher_reporting_export_for_ad_group_QueryParams = t.TypeOf<
  typeof get_publisher_reporting_export_for_ad_group_QueryParams
>;
/**
 * Query parameters used in URL `/report/publisher/export/adgroup` for {@linkcode get_publisher_reporting_export_for_ad_group}.
 */
export const get_publisher_reporting_export_for_ad_group_QueryParams = t.intersection(
  [
    t.type({
      campaign_id: t.Integer
    }),
    t.partial({
      start_date: tx.nullable(tx.DateString),
      end_date: tx.nullable(tx.DateString)
    })
  ],
  'get_publisher_reporting_export_for_ad_group_QueryParams'
);
export const get_publisher_reporting_export_for_ad_group = http.get`/data/report/publisher/export/adgroup`
  .withParams(get_publisher_reporting_export_for_ad_group_QueryParams)
  .returnsJsonOf(t.array(PublisherReportingExportAdgroupResponse));
/**
 * Query parameters used in URL `/report/publisher/export/campaign` for {@linkcode get_publisher_reporting_export_for_campaign}.
 */
export type get_publisher_reporting_export_for_campaign_QueryParams = t.TypeOf<
  typeof get_publisher_reporting_export_for_campaign_QueryParams
>;
/**
 * Query parameters used in URL `/report/publisher/export/campaign` for {@linkcode get_publisher_reporting_export_for_campaign}.
 */
export const get_publisher_reporting_export_for_campaign_QueryParams = t.intersection(
  [
    t.type({
      campaign_id: t.Integer
    }),
    t.partial({
      start_date: tx.nullable(tx.DateString),
      end_date: tx.nullable(tx.DateString)
    })
  ],
  'get_publisher_reporting_export_for_campaign_QueryParams'
);
export const get_publisher_reporting_export_for_campaign = http.get`/data/report/publisher/export/campaign`
  .withParams(get_publisher_reporting_export_for_campaign_QueryParams)
  .returnsJsonOf(t.array(PublisherReportingExportCampaignResponse));
/**
 * Query parameters used in URL `/report/publisher/table/adgroup` for {@linkcode get_publisher_reporting_data_for_ad_group}.
 */
export type get_publisher_reporting_data_for_ad_group_QueryParams = t.TypeOf<
  typeof get_publisher_reporting_data_for_ad_group_QueryParams
>;
/**
 * Query parameters used in URL `/report/publisher/table/adgroup` for {@linkcode get_publisher_reporting_data_for_ad_group}.
 */
export const get_publisher_reporting_data_for_ad_group_QueryParams = t.intersection(
  [
    t.type({
      adgroup_id: t.Integer
    }),
    t.partial({
      start_date: tx.nullable(tx.DateString),
      end_date: tx.nullable(tx.DateString)
    })
  ],
  'get_publisher_reporting_data_for_ad_group_QueryParams'
);
export const get_publisher_reporting_data_for_ad_group = http.get`/data/report/publisher/table/adgroup`
  .withParams(get_publisher_reporting_data_for_ad_group_QueryParams)
  .returnsJsonOf(t.array(PublisherReportingTableBreakdownResponse));
/**
 * Query parameters used in URL `/report/publisher/table/campaign` for {@linkcode get_publisher_reporting_data_for_campaign}.
 */
export type get_publisher_reporting_data_for_campaign_QueryParams = t.TypeOf<
  typeof get_publisher_reporting_data_for_campaign_QueryParams
>;
/**
 * Query parameters used in URL `/report/publisher/table/campaign` for {@linkcode get_publisher_reporting_data_for_campaign}.
 */
export const get_publisher_reporting_data_for_campaign_QueryParams = t.intersection(
  [
    t.type({
      campaign_id: t.Integer
    }),
    t.partial({
      start_date: tx.nullable(tx.DateString),
      end_date: tx.nullable(tx.DateString)
    })
  ],
  'get_publisher_reporting_data_for_campaign_QueryParams'
);
export const get_publisher_reporting_data_for_campaign = http.get`/data/report/publisher/table/campaign`
  .withParams(get_publisher_reporting_data_for_campaign_QueryParams)
  .returnsJsonOf(t.array(PublisherReportingTableBreakdownResponse));
/**
 * Gets all reports associated with a tenant
 */
export const get_report_collection_resource = http.get`/data/reports`.returnsJsonOf(ReportsGetResponse);
/**
 * Creates a new report.
 */
export const post_report_collection_resource = http.post`/data/reports`
  .acceptsJsonOf(ReportInput)
  .returnsJsonOf(ReportCreateResponse);
/**
 * Gets report type list
 */
export const get_report_types_api = http.get`/data/reports/report_types`.returnsJsonOf(ReportTypesGetResponse);
/**
 * Path parameters used in URL `/reports/{report_id}` for {@linkcode get_report_resource}.
 */
export type get_report_resource_PathParams = t.TypeOf<typeof get_report_resource_PathParams>;
/**
 * Path parameters used in URL `/reports/{report_id}` for {@linkcode get_report_resource}.
 */
export const get_report_resource_PathParams = t.type(
  {
    report_id: t.Integer
  },
  'get_report_resource_PathParams'
);
/**
 * Gets an existing report
 */
export const get_report_resource = http.get<get_report_resource_PathParams>`/data/reports/${p =>
  p.report_id}`.returnsJsonOf(ReportGet);
/**
 * Path parameters used in URL `/reports/{report_id}` for {@linkcode delete_report_resource}.
 */
export type delete_report_resource_PathParams = t.TypeOf<typeof delete_report_resource_PathParams>;
/**
 * Path parameters used in URL `/reports/{report_id}` for {@linkcode delete_report_resource}.
 */
export const delete_report_resource_PathParams = t.type(
  {
    report_id: t.Integer
  },
  'delete_report_resource_PathParams'
);
/**
 * Deletes an existing report
 */
export const delete_report_resource = http.delete<delete_report_resource_PathParams>`/data/reports/${p =>
  p.report_id}`.returnsJsonOf(SuccessResponse);
/**
 * Path parameters used in URL `/reports/{report_id}` for {@linkcode put_report_resource}.
 */
export type put_report_resource_PathParams = t.TypeOf<typeof put_report_resource_PathParams>;
/**
 * Path parameters used in URL `/reports/{report_id}` for {@linkcode put_report_resource}.
 */
export const put_report_resource_PathParams = t.type(
  {
    report_id: t.Integer
  },
  'put_report_resource_PathParams'
);
/**
 * Updates an existing report
 */
export const put_report_resource = http.put<put_report_resource_PathParams>`/data/reports/${p => p.report_id}`
  .acceptsJsonOf(ReportInput)
  .returnsJsonOf(SuccessResponse);
/**
 * Path parameters used in URL `/reports/{report_id}/status` for {@linkcode put_report_status_api}.
 */
export type put_report_status_api_PathParams = t.TypeOf<typeof put_report_status_api_PathParams>;
/**
 * Path parameters used in URL `/reports/{report_id}/status` for {@linkcode put_report_status_api}.
 */
export const put_report_status_api_PathParams = t.type(
  {
    report_id: t.Integer
  },
  'put_report_status_api_PathParams'
);
/**
 * Updates a report status
 */
export const put_report_status_api = http.put<put_report_status_api_PathParams>`/data/reports/${p =>
  p.report_id}/status`
  .acceptsJsonOf(ReportStatusRequest)
  .returnsJsonOf(SuccessResponse);
/**
 * Gets all account verticals
 */
export const get_account_verticals_api = http.get`/data/static/account_verticals`.returnsJsonOf(
  t.array(GetAccountVerticalsResponse)
);
/**
 * Gets all billing sources
 */
export const get_billing_sources_api = http.get`/data/static/billing_sources`.returnsJsonOf(GetBillingSourcesResponse);
/**
 * Gets all countries
 */
export const get_countries_api = http.get`/data/static/countries`.returnsNone();
/**
 * Gets a mapping of countries to states
 */
export const get_country_states_api = http.get`/data/static/country_states`.returnsNone();
/**
 * Gets all creative macros
 */
export const get_creative_macros_api = http.get`/data/static/creative_macros`.returnsNone();
/**
 * Gets a mapping of delivery channels to creative types
 */
export const get_delivery_channel_creative_map_api = http.get`/data/static/delivery_channel_creative_map`.returnsNone();
/**
 * Query parameters used in URL `/static/delivery_channels` for {@linkcode get_delivery_channels_api}.
 */
export type get_delivery_channels_api_QueryParams = t.TypeOf<typeof get_delivery_channels_api_QueryParams>;
/**
 * Query parameters used in URL `/static/delivery_channels` for {@linkcode get_delivery_channels_api}.
 */
export const get_delivery_channels_api_QueryParams = t.partial(
  {
    device_type: tx.nullable(t.string)
  },
  'get_delivery_channels_api_QueryParams'
);
/**
 * Gets all delivery channels
 */
export const get_delivery_channels_api = http.get`/data/static/delivery_channels`
  .withParams(get_delivery_channels_api_QueryParams)
  .returnsJsonOf(t.array(DeliveryChannel));
/**
 * Gets all user groups
 */
export const get_user_groups_api = http.get`/data/static/user_groups`.returnsJsonOf(GetUserGroupsResponse);
export enum post_upload_file_api_QueryParams_type {
  FILE = 'file',
  VIDEO = 'video',
  HTML5 = 'html5',
  AUDIO = 'audio'
}
/**
 * Query parameters used in URL `/upload` for {@linkcode post_upload_file_api}.
 */
export type post_upload_file_api_QueryParams = t.TypeOf<typeof post_upload_file_api_QueryParams>;
/**
 * Query parameters used in URL `/upload` for {@linkcode post_upload_file_api}.
 */
export const post_upload_file_api_QueryParams = t.type(
  {
    type: tx.enumeration(post_upload_file_api_QueryParams_type, 'post_upload_file_api_QueryParams_type')
  },
  'post_upload_file_api_QueryParams'
);
/**
 * Uploads file to S3
 */
export const post_upload_file_api = http.post`/data/upload`
  .withParams(post_upload_file_api_QueryParams)
  .returnsJsonOf(UploadFileResponse);
/**
 * Validates a creative image and uploads it to S3
 */
export const post_upload_creative_image_api = http.post`/data/upload/creative_image`
  .acceptsJsonOf(UploadCreativeImageRequest)
  .returnsJsonOf(UploadCreativeImageResponse);
