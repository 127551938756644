import {
  services as factoriesServices,
  factories as factoriesFactories,
  controllers as factoriesControllers
} from '../factories';
import { filters as filtersFilters } from './filters';
import { outsideClick } from './outside_click.dir';
import { sglclick } from './sgl_click';
import { floatToInteger } from './float_to_integer.dir';

export const services = {
  ...factoriesServices
};

export const factories = {
  ...factoriesFactories
};

export const controllers = {
  ...factoriesControllers
};

export const filters = {
  ...filtersFilters
};

export const directives = {
  outsideClick,
  sglclick,
  floatToInteger: floatToInteger
};
