import { CompanyAndAccountFactoryService, UserFactoryService } from './types';
import { isEqual } from 'lodash';

export interface FeatureFlagService {
  isLoaded: boolean;
  lastContext: Context | null;
  isFeatureEnabled: (featureFlagName: string) => boolean;
  load: (companyAndAccountFactory: CompanyAndAccountFactoryService) => Promise<void>;
  init: () => void;
}

interface Context {
  tenant_id: number;
  tenant_type: number;
  tenant_tier: number;
  account_country_code: string | undefined;
  user_id: number;
  is_sudoer: boolean;
  user_group_ids: number[];
  version: string;
  environment: string;
}

export function featureFlagFactory(): FeatureFlagService {
  const featureFlagFactory = {} as FeatureFlagService;

  featureFlagFactory.isLoaded = false;
  featureFlagFactory.lastContext = null;

  featureFlagFactory.init = () => {
    const userKey = '' + window.user.id;
    const host = window.featureFlagConnInfo.host;
    const port = window.featureFlagConnInfo.port;
    const signature = window.featureFlagConnInfo.signature;
    this.featureFlagManager = new FeatureFlagManager(host, 1, userKey, signature, port);
  };

  featureFlagFactory.isFeatureEnabled = (featureFlagName: string): boolean => {
    if (!featureFlagFactory.isLoaded) {
      return false;
    }

    return this.featureFlagManager.isFeatureFlagEnabled(featureFlagName);
  };

  featureFlagFactory.load = async (companyAndAccountFactory: CompanyAndAccountFactoryService) => {
    const context = getContext(companyAndAccountFactory);
    if (!isEqual(context, this.lastContext)) {
      try {
        await this.featureFlagManager.load(context);
        this.lastContext = context;
        featureFlagFactory.isLoaded = true;
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getContext = (companyAndAccountFactory: CompanyAndAccountFactoryService): Context => {
    const tenant = companyAndAccountFactory.selectedTenant || {};
    const tenantId = tenant.tenant_id;
    const tenantType = tenant.type;
    const tenantTier = tenant.tenant_tier;
    const accountCountryCode = (companyAndAccountFactory.selectedAccount || {}).countryCode;

    const user = window.user || {};
    const userId = user.id;
    const isSudoer = user.is_sudoer;
    const userGroupIds = user.group_ids || [];

    return {
      tenant_id: tenantId,
      tenant_type: tenantType,
      tenant_tier: tenantTier,
      account_country_code: accountCountryCode,
      user_id: userId,
      is_sudoer: isSudoer,
      user_group_ids: userGroupIds,
      version: ADS_MANAGER_VERSION,
      environment: ADS_MANAGER_ENVIRONMENT
    };
  };

  return featureFlagFactory;
}
