import { ModalFactory, CampaignManagerService } from '../../../factories/types';
import { CreativeType } from '../../../api/types/creative';
import { AdgroupType } from '../../../api/types/adgroup';
import * as GeneralUtils from '../../../factories/general_utils';
import {
  CreativeVideoTranscoderStatusResponse_status,
  CreativeLPLocationResponse,
  CreativeHTML5StatusResponse_status
} from '../../../api/http/creative';
import { CMSConfig, HTML5CreativeStatus, VideoTranscodingStatus } from '../../../api/http/data.generated';

export interface AdsManagerError {
  code: string;
  message: string;
}

export interface ErrorResponse {
  errors: AdsManagerError[];
  message: string;
}

export interface CreativeExtraType extends CreativeType {
  scaledHeight: number;
  html5CreativeHeight: number;
  transformScale: number;
  placementId: number | undefined | null;
  video_transcoder: VideoTranscodingStatus;
  html5_creative: HTML5CreativeStatus;
  creativeSizeRatio: number;
  usePlacementDates: boolean;
  frequencyUnlimited: boolean;
  weight: number;
}

export interface HTML5CMSConfig extends CMSConfig {
  name: string;
}

export interface AdgroupExtraType extends AdgroupType {
  LpLocation: CreativeLPLocationResponse;
  rotation: number;
  rotateEvenly: boolean;
  creatives: CreativeExtraType[];
}

export interface ModalSetting {
  title: string;
  message: string;
  noText: string;
  yesText: string;
}

export class AdgroupCreativeService {
  modalFactory: ModalFactory;
  campaignManagerFactory: CampaignManagerService;
  $q: ng.IQService;
  $location: ng.ILocationService;

  /**@ngInject */
  constructor(
    $q: ng.IQService,
    $location: ng.ILocationService,
    modalFactory: ModalFactory,
    campaignManagerFactory: CampaignManagerService
  ) {
    this.$q = $q;
    this.modalFactory = modalFactory;
    this.campaignManagerFactory = campaignManagerFactory;
    this.$location = $location;

    this.changeCreativeStatus = this.changeCreativeStatus.bind(this);
  }

  frequencyCappingChange(creative: CreativeExtraType) {
    if (creative.frequencyUnlimited) {
      creative.freqCapValue = null;
      creative.freqCapDuration = null;
    } else {
      creative.freqCapDuration = 1;
    }
  }

  usePlacementDates(creative: CreativeExtraType) {
    // startDate and endDate cannot be null here in order to initialize daterangepicker properly
    // when we save the creative, we will set startDate and endDate to null if creative uses placement dates
    creative.startDate = window.adGroup.timeframe.start;
    creative.endDate = window.adGroup.timeframe.end;
  }

  getTotalCreativeWeight(adgroup: AdgroupType): number {
    let totalWeight = 0;
    adgroup?.creatives?.forEach(creative => (totalWeight += creative!.weight ?? 0));
    return totalWeight;
  }

  openCreativeModal(creative: CreativeType | undefined, adgroup: AdgroupType, callback: Function) {
    if (creative?.id) {
      this.$location.search('creativeId', creative?.id);
    }
    const modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-creative.html',
      controller: 'CreativeModalInstanceCtrl',
      size: 'xl',
      backdrop: 'static'
    };

    const data = {
      creative,
      adGroup: adgroup
    };

    this.modalFactory.createModal(modalOptions, data).then(function() {
      callback(true);
    });
  }

  deleteCreative(creative: CreativeType, callback: Function) {
    const modalSetting = {
      title: 'Delete Creative',
      message: 'Deleting this creative will permanently delete all its associated information. Are you sure?',
      noText: 'Cancel',
      yesText: 'Delete',
      yesClass: 'skin-red'
    };

    const handlers = {
      confirm: () =>
        this.campaignManagerFactory
          .deleteCreative(
            this.campaignManagerFactory.selectedCampaign.id,
            this.campaignManagerFactory.selectedAdGroup,
            creative.id
          )
          .then(function() {
            callback(false);
          })
    };

    this.modalFactory.simpleConfirm(modalSetting, handlers);
  }

  cloneCreative(creative: CreativeType, callback: Function) {
    const modalSetting = {
      title: 'Duplicate creative?',
      message: 'Duplicating a creative will create new creative with all of the attributes of the original.',
      noText: 'Cancel',
      yesText: 'Duplicate'
    };

    const handlers = {
      confirm: () =>
        this.campaignManagerFactory
          .cloneCreative(creative, this.campaignManagerFactory.selectedCampaign.id)
          .then(function() {
            callback(false);
          })
          .catch(function(error) {
            return error;
          })
    };

    this.modalFactory.simpleConfirm(modalSetting, handlers);
  }

  pauseCreative(creative: CreativeType, callback: Function) {
    const modalSetting = {
      title: 'Pause creative?',
      message: '',
      noText: 'Cancel',
      yesText: 'Pause'
    };

    this.changeCreativeStatus(creative, callback, modalSetting, this.campaignManagerFactory.statusNames.PAUSED);
  }

  activateCreative(creative: CreativeType, callback: Function) {
    const modalSetting = {
      title: 'Activate creative?',
      message: '',
      noText: 'Cancel',
      yesText: 'Activate'
    };

    this.changeCreativeStatus(creative, callback, modalSetting, this.campaignManagerFactory.statusNames.ACTIVE);
  }

  private changeCreativeStatus(creative: CreativeType, callback: Function, modalSetting: ModalSetting, status: string) {
    const handlers = {
      confirm: () =>
        this.campaignManagerFactory
          .changeCreativeStatus(creative, this.campaignManagerFactory.selectedCampaign.id, status)
          .then(
            () => {
              if (callback) {
                callback(false);
              }
            },
            error => {
              return error;
            }
          )
    };

    this.modalFactory.simpleConfirm(modalSetting, handlers);
  }

  scaleCreativeDimensions(ratio: number, css: Record<string, any>) {
    if (css.hasOwnProperty('font-size') && css['font-size'].indexOf('px') > 0) {
      css['font-size'] = Math.floor(parseFloat(css['font-size'].split('px')[0]) / ratio);
      css['font-size'] = css['font-size'].toString() + 'px';
    }

    if (css.hasOwnProperty('left') && css['left'].indexOf('px') > 0) {
      css['left'] = parseFloat(css['left'].split('px')[0]) / ratio;
      css['left'] = css['left'].toString() + 'px';
    }

    if (css.hasOwnProperty('top') && css['top'].indexOf('px') > 0) {
      css['top'] = parseFloat(css['top'].split('px')[0]) / ratio;
      css['top'] = css['top'].toString() + 'px';
    }

    if (css.hasOwnProperty('height') && css['height'].indexOf('px') > 0) {
      css['height'] = parseFloat(css['height'].split('px')[0]) / ratio;
      css['height'] = css['height'].toString() + 'px';
    }

    if (css.hasOwnProperty('width') && css['width'].indexOf('px') > 0) {
      css['width'] = parseFloat(css['width'].split('px')[0]) / ratio;
      css['width'] = css['width'].toString() + 'px';
    }

    if (css.hasOwnProperty('bottom') && css['bottom'].indexOf('px') > 0) {
      css['bottom'] = parseFloat(css['bottom'].split('px')[0]) / ratio;
      css['bottom'] = css['bottom'].toString() + 'px';
    }

    if (css.hasOwnProperty('right') && css['right'].indexOf('px') > 0) {
      css['right'] = parseFloat(css['right'].split('px')[0]) / ratio;
      css['right'] = css['right'].toString() + 'px';
    }
  }

  async saveCreative(
    adgroup: AdgroupExtraType,
    errors: Array<string>,
    skipIncompleteCreatives = false,
    haveCreativesChanged = false
  ) {
    const d = this.$q.defer();

    const adGroupCopy = angular.copy(adgroup);
    const allCreatives = adGroupCopy.creatives;
    if (skipIncompleteCreatives) {
      // TODO: add incomplete status to creative status
      // @ts-ignore
      adGroupCopy.creatives = adGroupCopy!.creatives!.filter(creative => creative!.status !== 'Incomplete');
    }

    for (let i = 0; i < adGroupCopy.creatives.length; i++) {
      if (adGroupCopy.rotateEvenly) {
        adGroupCopy.creatives[i].weight = 1;
      }
      if (adGroupCopy.creatives[i].usePlacementDates) {
        adGroupCopy!.creatives[i]!.startDate = null;
        adGroupCopy!.creatives[i]!.endDate = null;
      }

      const creativeCssDict = GeneralUtils.stringCssToDictCss(
        adGroupCopy?.creatives[i]?.cmsConfig?.cssCustomText ?? ''
      );
      this.scaleCreativeDimensions(1 / adGroupCopy.creatives[i].creativeSizeRatio, creativeCssDict);
      adGroupCopy.creatives[i]!.cmsConfig!.cssCustomText = GeneralUtils.dictCssToStringCss(creativeCssDict);
    }
    if (adGroupCopy.rotateEvenly) {
      adGroupCopy.rotation = 0;
    } else {
      adGroupCopy.rotation = 1;
    }

    if (haveCreativesChanged) {
      try {
        await this.campaignManagerFactory.saveAdGroupCreativesV2(
          this.campaignManagerFactory.selectedCampaign.id,
          adGroupCopy
        );
        d.resolve();
      } catch ({ data }) {
        angular.copy(GeneralUtils.extractErrorsFromResponse(data), errors);
        d.reject(errors);
      } finally {
        // Before save, we removed all "incomplete" creatives... so resetting creatives list here
        adGroupCopy.creatives = allCreatives;
        GeneralUtils.nestedUpdateInPlace(adGroupCopy, this.campaignManagerFactory.selectedAdGroup);
      }
    } else {
      d.resolve();
    }

    return d.promise;
  }

  isVideoCreativeInProgress(creative: CreativeExtraType) {
    return [
      CreativeVideoTranscoderStatusResponse_status.NEW,
      CreativeVideoTranscoderStatusResponse_status.IN_PROGRESS
    ].includes(creative?.video_transcoder?.status);
  }

  isCreativeIncompleteOrInProgress(creative: CreativeExtraType) {
    // TODO add incomplete as status on creative
    // @ts-ignore
    return (
      creative.status === 'Incomplete' ||
      this.isVideoCreativeInProgress(creative) ||
      this.isHTML5CreativeInProgress(creative)
    );
  }

  isHTML5CreativeInProgress(creative: CreativeExtraType) {
    return [CreativeHTML5StatusResponse_status.NEW, CreativeHTML5StatusResponse_status.IN_PROGRESS].includes(
      creative?.html5_creative?.status
    );
  }

  getVideoInProgressCreatives(creatives: CreativeExtraType[]) {
    return creatives.filter(this.isVideoCreativeInProgress);
  }

  getHTML5InProgressCreatives(creatives: CreativeExtraType[]) {
    return creatives.filter(this.isHTML5CreativeInProgress);
  }
}
