import * as React from 'react';
import { ReportFormPage } from './report_form_page';
import { CircularIndicator } from '../../../components/reactComponents';
import { store } from '../model/store';
import { styled } from '@material-ui/core/styles';
import { ReportSchedulerEditFormModel } from '../model/report_scheduler_edit_form_model';
import { useAsyncInit } from '../../../utils/react/use_async_action';

const LoadingIndicatorContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 20
});

export interface ReportEditProps {
  reportId: number;
}

export function ReportEdit(props: ReportEditProps) {
  const { reportId } = props;
  const [loading, model] = useAsyncInit(
    async () => {
      const report = await store.reportScheduler.loadReportDetails(reportId);
      return new ReportSchedulerEditFormModel(report);
    },
    null,
    [reportId]
  );

  return loading ? (
    <LoadingIndicatorContainer>
      <CircularIndicator />
    </LoadingIndicatorContainer>
  ) : model ? (
    <ReportFormPage model={model} />
  ) : (
    <span>No Report Found</span>
  );
}
