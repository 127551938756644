import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { primary3Palette } from '../styles/colorConstants';

interface ModalBaseProps {
  title?: string;
  children: React.ReactNode;
  actionSection?: React.ReactNode;
  open?: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
  className?: string;
}

const StyledDialogAction = styled(DialogActions)({
  justifyContent: 'center',
  borderTop: '1px solid var(--color-outline)'
});

const StyledDialogTitle = styled(DialogTitle)({
  borderBottom: `1px solid ${primary3Palette[200]}`,
  color: '#5c7683',
  fontSize: 17
});

const StyledDialogContent = styled(DialogContent)({
  minHeight: 115,
  minWidth: 330,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
});

const CloseButtonIcon = styled(IconButton)({
  position: 'absolute',
  right: 0,
  top: 0
});

export function ModalBase({
  title,
  children,
  onClose,
  actionSection,
  open = false,
  showCloseButton,
  ...others
}: ModalBaseProps) {
  return (
    <Dialog open={open} onClose={onClose} {...others}>
      {title && (
        <StyledDialogTitle disableTypography={true}>
          <span>{title}</span>
          {showCloseButton && (
            <CloseButtonIcon aria-label="close" onClick={onClose} className="close-icon" disableRipple>
              <CloseIcon />
            </CloseButtonIcon>
          )}
        </StyledDialogTitle>
      )}
      <StyledDialogContent>{children}</StyledDialogContent>
      {actionSection && <StyledDialogAction>{actionSection}</StyledDialogAction>}
    </Dialog>
  );
}
