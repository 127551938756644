import Button from '@material-ui/core/Button';
import * as React from 'react';
import { withStyles } from '@material-ui/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const PageNumberButton = withStyles({
  root: {
    height: 'var(--action-item-height)',
    fontWeight: 'var(--font-weight-light-bold)',
    fontSize: 'var(--font-size-regular)',
    padding: '0px',
    color: 'var(--color-on-primary)',
    border: '1px solid var(--color-primary)',
    background: 'var(--color-background)'
  },
  containedSecondary: {
    boxShadow: 'none',
    width: '48px',
    height: 'var(--action-item-height)',
    padding: '10px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    border: '2px solid  var(--color-primary) !important',
    background: 'var(--color-primary-container)',
    color: 'var(--color-on-primary)',
    fontFamily: 'var(--secondary-font-family)',
    fontSize: 'var(--font-size-regular)',
    fontWeight: 'var(--font-weight-light-bold)',
    lineHeight: '150%'
  }
})(Button);

const StyledButtonGroup = withStyles({
  root: {
    paddingTop: '40px',
    marginLeft: '12px',
    marginRight: '12px',
    paddingBottom: '40px'
  }
})(ButtonGroup);

const FirstLastToggleButton = withStyles({
  root: {
    borderRadius: 'var(--border-radius)'
  }
})(PageNumberButton);

interface PaginationProps {
  totalPages: number;
  activeIndex: number;
  onChange: (changePageTo: number) => void;
}

export function Pagination(props: PaginationProps) {
  const { activeIndex, totalPages, onChange } = props;
  let pages: React.ReactNode[] = [];

  function changeActivePage(pageToMoveTo: number) {
    if (pageToMoveTo >= 0 && pageToMoveTo < totalPages) {
      onChange(pageToMoveTo);
    }
  }

  if (activeIndex > totalPages) {
    return <></>;
  } else {
    let section = Math.floor(activeIndex / 5);
    for (let index = 0; index < 5; index++) {
      let indexNumber = section * 5 + index;
      if (indexNumber >= totalPages) break;

      if (indexNumber === activeIndex) {
        pages.push(
          <PageNumberButton key={indexNumber} variant="contained" color="secondary">
            {indexNumber + 1}
          </PageNumberButton>
        );
      } else {
        pages.push(
          <PageNumberButton
            key={indexNumber}
            onClick={() => {
              changeActivePage(indexNumber);
            }}
          >
            {indexNumber + 1}
          </PageNumberButton>
        );
      }
    }

    let nextPage = section * 5 + 5;
    let previousPage = section * 5 - 1;
    if (totalPages > nextPage) {
      pages.push(
        <PageNumberButton
          key="previousSection"
          onClick={() => {
            changeActivePage(nextPage);
          }}
        >
          ...
        </PageNumberButton>
      );
    }

    if (0 < previousPage) {
      pages.unshift(
        <PageNumberButton
          key="nextSection"
          onClick={() => {
            changeActivePage(previousPage);
          }}
        >
          ...
        </PageNumberButton>
      );
    }
  }

  return (
    <span className="report-pagination">
      <FirstLastToggleButton
        key="firstPage"
        onClick={() => {
          changeActivePage(0);
        }}
        disabled={!(totalPages > 1 && activeIndex != 0)}
      >
        First
      </FirstLastToggleButton>
      <StyledButtonGroup size="small" className="pagination-group">
        <PageNumberButton
          key="previousPage"
          onClick={() => {
            changeActivePage(activeIndex - 1);
          }}
          disabled={!(totalPages > 1 && activeIndex != 0)}
        >
          &#60;
        </PageNumberButton>
        {pages}
        <PageNumberButton
          key="nextPage"
          onClick={() => {
            changeActivePage(activeIndex + 1);
          }}
          disabled={activeIndex == totalPages - 1}
        >
          &#62;
        </PageNumberButton>
      </StyledButtonGroup>
      <FirstLastToggleButton
        key="lastPage"
        onClick={() => {
          changeActivePage(totalPages - 1);
        }}
        disabled={activeIndex == totalPages - 1}
      >
        Last
      </FirstLastToggleButton>
    </span>
  );
}
