import creativeRepoListViewTemplate from './creative-repo-list-view-template.html';
import { createXadTableConfigForCreativeRepo } from './creative_asset_list_xad_table_config.js';
import creativeAssetPreviewTemplate from '../creative_asset_preview_edit_modal/creative-asset-preview-edit-modal-template.html';

export const creativeRepoListView = () => {
  const link = (scope, element, attrs, controller) => {
    scope.creativeListXadTableConfig = createXadTableConfigForCreativeRepo(
      controller.editCreativeAsset,
      controller.previewCreativeAsset,
      controller.onDelete
    );
  };
  return {
    restrict: 'E',
    scope: {
      creativeAssetList: '=creativeAssetList',
      onDelete: '=',
      selectedCreativeAssetIds: '=',
      availableLimits: '=',
      itemsPerPage: '=',
      updateItemsPerPage: '&',
      totalItems: '=',
      currentPage: '=',
      numberOfPages: '=',
      customSort: '&',
      updateCurrentPage: '&',
      tableChanged: '=', // used for update table data
      clearAllSelections: '&',
      isNestedDirective: '=' // function call is different based on nested directive..
    },
    template: creativeRepoListViewTemplate,
    controller: CreativeRepositoryListViewController,
    controllerAs: 'vm',
    bindToController: true,
    link
  };
};

class CreativeRepositoryListViewController {
  constructor($scope, modalFactory) {
    this.modalFactory = modalFactory;
    this.editCreativeAsset = this.editCreativeAsset.bind(this);
    this.previewCreativeAsset = this.previewCreativeAsset.bind(this);
  }

  editCreativeAsset($event, item_this, creative) {
    this.openCreativeAssetEditPreviewModal(creative);
  }

  previewCreativeAsset($event, item_this, creative) {
    this.openCreativeAssetEditPreviewModal(creative, true);
  }

  openCreativeAssetEditPreviewModal(creative, editMode = false) {
    const modalOptions = {
      template: creativeAssetPreviewTemplate,
      controller: 'creativeAssetPreviewEditModalController',
      size: 'lg',
      windowClass: 'creative-preview-edit-modal',
      backdrop: 'static'
    };

    const data = {
      creative,
      editMode: editMode
    };

    this.modalFactory.createModal(modalOptions, data);
  }
}

CreativeRepositoryListViewController.$inject = ['$scope', 'modalFactory'];
