import template from '../../templates/static-templates/adgroup-action-sheet.html';

export function adgroupActionSheet() {
  var directive = {
    template,
    bindToController: true,
    controller: AdgroupActionSheetController,
    controllerAs: 'vm',
    link: link,
    restrict: 'E',
    scope: {
      disableIcons: '=',
      booleans: '=',
      errorMsg: '=error',
      callback: '&'
    }
  };
  return directive;

  function link(scope, element, attrs) {}
}

function AdgroupActionSheetController() {}
