ColumnPickerModalController.$inject = ['$uibModalInstance', 'data'];

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 */
export function ColumnPickerModalController($uibModalInstance, data) {
  var vm = this;

  vm.availableHeaders = data.availableHeaders;

  vm.dismiss = dismiss;
  vm.save = save;
  vm.selectPreset = selectPreset;
  vm.checkSection = checkSection;
  vm.columnCheckboxChanged = columnCheckboxChanged;

  init();

  function init() {
    vm.presets = { all: [] };
    var i;
    for (i = 0; i < vm.availableHeaders.length; i++) {
      if (!vm.presets.hasOwnProperty(vm.availableHeaders[i].type)) {
        vm.presets[vm.availableHeaders[i].type] = [];
      }
      vm.presets[vm.availableHeaders[i].type].push(vm.availableHeaders[i]);
    }
  }

  function dismiss() {
    $uibModalInstance.dismiss();
  }

  function save() {
    var selectionMapping = {};

    for (var i = 0; i < vm.availableHeaders.length; i++) {
      selectionMapping[vm.availableHeaders[i].field] = vm.availableHeaders[i].selected;
    }

    $uibModalInstance.close(selectionMapping);
  }

  function selectPreset(presetName) {
    var i;
    for (i = 0; i < vm.availableHeaders.length; i++) {
      if (vm.availableHeaders[i].mustShow) {
        continue; // ignore if must show
      }

      vm.availableHeaders[i].hide =
        presetName !== 'all' && vm.availableHeaders[i].type !== presetName && vm.availableHeaders[i].type !== 'details';
      vm.availableHeaders[i].selected = !vm.availableHeaders[i].hide;
    }
  }

  function columnCheckboxChanged(header) {
    header.selected = !header.hide;
  }

  function checkSection(presetName) {
    var i;
    for (i = 0; i < vm.availableHeaders.length; i++) {
      if (vm.availableHeaders[i].mustShow) {
        continue; // ignore if must show
      }

      if (vm.availableHeaders[i].type === presetName) {
        vm.availableHeaders[i].hide = !vm.availableHeaders[i].hide;
        vm.availableHeaders[i].selected = !vm.availableHeaders[i].hide;
      }
    }
  }
}
