import * as React from 'react';
import { TextField as MaterialTextField, Box, InputLabel, IconButton, FormHelperText, styled } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { CloseOpenIcon } from '../Icons';

export interface SearchFieldsProps {
  id?: string;
  className?: string;
  /** If true, the outer box fill its container, otherwise it will use a default width */
  fullWidth?: boolean;
  /** Callback function that would be called when the value changes */
  onChange?: (text: string) => void;
  /** The label to be displayed on top of the input field */
  label: string;
  /** The value we want on in the input field. This is a controlled component so the user has to change the value */
  value?: string;
  /** Callback function that would be called when the input goes out of focus */
  onBlur?: () => void;
  /** The placeholder you want in the input field */
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
}

const StyledSearchIcon = styled(SearchIcon)({
  color: 'gray'
});

const StyledTextField = styled(MaterialTextField)({
  minWidth: 300
});

export function SearchTextFiled(props: SearchFieldsProps) {
  const { id, className, fullWidth, onChange, label, value, disabled = false, readonly = false, ...others } = props;

  const onChangeValue: React.ComponentProps<typeof MaterialTextField>['onChange'] = event => {
    if (onChange) onChange(event.target.value);
  };

  const onClearValue: React.ComponentProps<typeof IconButton>['onClick'] = event => {
    if (onChange) onChange('');
  };

  return (
    // TODO the style props here is a temporary hack to fix the width.
    // It should be refactored when a common pattern is determined
    <div id={id} className={className} style={{ width: fullWidth ? '100%' : '400px' }}>
      <Box display="flex" justifyContent="space-between"></Box>
      <StyledTextField
        label=""
        variant="standard"
        value={value}
        placeholder={label}
        onChange={onChangeValue}
        fullWidth={true}
        disabled={disabled}
        InputProps={{
          readOnly: readonly,
          margin: 'dense',
          endAdornment: !readonly && !disabled && value && (
            <IconButton size="small" onClick={onClearValue}>
              <CloseOpenIcon />
            </IconButton>
          ),
          startAdornment: <StyledSearchIcon />
        }}
        {...others}
      />
    </div>
  );
}
