/**
 * @param {ng.ITimeoutService} $timeout
 * @param {ng.IFilterService} $filter
 * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @param {import('../../../factories/types').HighchartsService} highChartsFactory
 * @param {import('../../modal/types').ModalService} modalFactory
 * @ngInject
 */
export function xadColumnChart($timeout, $filter, generalUtilsFactory, highChartsFactory, modalFactory) {
  return {
    restrict: 'E',
    scope: {
      active: '=',
      changed: '=',
      chartProgress: '=',
      chartClass: '=',
      chartData: '=',
      seriesOptions: '=',
      metrics: '=',
      metricsChanged: '=',
      searchCategories: '&?',
      categoryChanged: '&?',
      productOptions: '='
    },
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.xad-column-chart.html',
    controller: /** @ngInject */ function($scope) {
      $scope.autocompleteConfig = {
        selectPlaceholder: 'All Categories',
        inputPlaceholder: 'Search by category name',
        key: 'name'
      };

      $scope.isEmpty = function() {
        return angular.equals($scope.chartData, []);
      };

      $scope.getYAxisLabel = function(selectedMetric) {
        let metricValue = $scope.metrics.filter(metric => metric.value === selectedMetric.value);
        if (!metricValue.length) {
          return null;
        }
        return metricValue[0].displayName;
      };

      $scope.resetSelectedCategory = function() {
        $scope.resetCategory = true;
      };

      if (angular.isUndefined($scope.type)) {
        $scope.type = 'behavioral';
      }
      $scope.yAxisMetrics = [];
      Highcharts.setOptions({
        lang: {
          thousandsSep: ','
        }
      });

      $scope.yAxisMetrics = highChartsFactory.updateMetrics($scope.seriesOptions);
      $scope.yAxisTitle = $scope.getYAxisLabel($scope.yAxisMetrics[0]);
    },
    link: function(scope, element, attrs) {
      var seriesOptions = [];
      scope.selectedOptions = [];
      scope.displayFilters = 'All Products';

      scope.resetProductOptions = function() {
        for (var optionTitle in scope.productOptions) {
          for (var item in scope.productOptions[optionTitle]) {
            scope.productOptions[optionTitle][item].checked = true;
          }
        }
      };

      scope.resetProductOptions();

      var getSelectedOptions = function(selectedOptions) {
        scope.selectedFilters = '';
        scope.selectedFilters = selectedOptions.join(', ');
        // this condition will remain same because if taxonomy is enabled then in place of Location Audience and
        // Behavioral Audience, we will simply show GT Audience and Location Group Audience. So, it will be 14 only.
        scope.displayFilters = selectedOptions.length === 14 ? 'All Products' : scope.selectedFilters;

        scope.changeMetrics(0);
      };

      scope.openProductPickerModal = function() {
        var modalOptions = {
          templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-product-picker.html',
          controller: 'ProductPickerModalController',
          size: 'xl'
        };

        var data = {
          productOptions: scope.productOptions,
          selectedOptions: scope.selectedOptions
        };

        var handlers = {
          getSelectedOptions: getSelectedOptions
        };

        return modalFactory.createModal(modalOptions, data, handlers);
      };

      var tooltipFormat = function() {
        var color = this.series.color;
        var value = this.y;
        var name = this.series.name;
        name = name.charAt(0).toUpperCase() + name.slice(1);
        var dataLabel = scope.seriesOptions[scope.yAxisMetrics[0].value].dataLabel;

        if (dataLabel === 'percent') {
          value = $filter('percentage')(value, 2);
        } else {
          if (value < 1) {
            value = $filter('number')(value, 1);
          } else {
            value = $filter('number')(value, 0);
          }
        }

        return '<span style="color:' + color + ';font-weight: 700;">' + value + '</span><br/>';
      };

      var yAxisLabelFn = function() {
        return generalUtilsFactory.shorthandNumber(this.value, 0, 1);
      };

      var yAxisFloatNumberLabelFn = function() {
        return generalUtilsFactory.shorthandNumber(this.value, 1, 1);
      };

      var yAxisLabelPercentFn = function() {
        return $filter('percentage')(this.value, 2);
      };

      function createChart() {
        if (scope.isEmpty()) {
          return;
        }
        var chartContainer = element[0].querySelector('.chart');

        var formatLabelFn = [];
        /** @type {number | undefined} */ var minTickInterval = 1;
        scope.yAxisMetrics.forEach(function(metrics) {
          var fn = yAxisLabelFn;

          if (metrics && metrics.value === 'vr') {
            fn = yAxisFloatNumberLabelFn;
          }
          var dataLabel = scope.seriesOptions[metrics ? metrics.value : 'vr'].dataLabel;
          formatLabelFn.push(dataLabel && dataLabel === 'percent' ? yAxisLabelPercentFn : fn);

          if (dataLabel === 'percent') {
            minTickInterval = undefined;
          }
        });

        scope.chart = Highcharts.chart(chartContainer, {
          title: {
            text: ''
          },
          chart: {
            height: 370,
            marginBottom: 50,
            type: 'column',
            style: {
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontWeight: '400'
            },
            plotBorderWidth: 0
          },
          xAxis: {
            type: 'category',
            labels: {
              style: {
                fontSize: '14px',
                fontFamily: 'Poppins',
                fontWeight: '400'
              },
              useHTML: true,
              align: 'center'
            }
          },
          yAxis: {
            min: 0,
            minTickInterval: minTickInterval,
            title: {
              text: scope.yAxisTitle,
              align: 'high',
              rotation: 0,
              offset: -10,
              x: -10,
              y: -25,
              style: {
                color: '#65c5a5'
              }
            },
            labels: {
              formatter: formatLabelFn[0],
              style: {
                color: '#65c5a5',
                fontSize: '14px'
              }
            },
            lineColor: '#e6e6e6'
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            column: {
              borderRadiusTopLeft: 100,
              borderRadiusTopRight: 100,
              pointWidth: 30
            },
            series: {
              color: '#65c5a5'
            }
          },
          tooltip: {
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderColor: '#BCF1E9',
            borderRadius: 10,
            padding: 24,
            pointFormatter: tooltipFormat,
            valueDecimals: 2,
            style: {
              color: '#0A3F36',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '41.6px',
              fontFamily: 'Roboto',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.30), 0px 8px 12px 6px rgba(0, 0, 0, 0.05)'
            }
          },
          series: [
            {
              type: 'column',
              name: '',
              data: scope.chartData,
              dataLabels: {
                enabled: false
              }
            }
          ],
          exporting: {
            enabled: true,
            allowHTML: true,
            scale: 1,
            sourceWidth: 1300,
            sourceHeight: 500,
            buttons: {
              contextButton: {
                symbol: 'url(/ui/images/export-icon-download.svg)',
                menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF'],
                text: 'Download',
                verticalAlign: 'top',
                height: 40,
                symbolY: 20,
                symbolX: 20,
                y: -46,
                x: 5
              }
            },
            chartOptions: {
              chart: {
                marginTop: 50,
                marginRight: 50,
                marginBottom: 200,
                marginLeft: 150
              },
              xAxis: {
                labels: {
                  align: 'center'
                }
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true
                  }
                }
              }
            }
          },
          credits: {
            enabled: false
          }
        });
        $timeout(function() {
          scope.chart.reflow();
        });
      }

      scope.changeMetrics = function(index) {
        var data = highChartsFactory.changeMetrics(
          scope.seriesOptions,
          scope.yAxisMetrics,
          scope.metrics,
          scope.yAxisTitle,
          index
        );
        scope.seriesOptions = data.options;
        scope.yAxisMetrics = data.yMetrics;
        scope.metrics = data.metrics;
        scope.yAxisTitle = data.title;

        scope.metricsChanged();
      };

      // Listen for changes
      scope.$watch('changed', function(newValue, oldValue) {
        if (newValue === true) {
          if (scope.chart) {
            scope.chart.destroy();
            scope.chart = null;
          }

          createChart();
          scope.changed = false;
        }
      });

      // When chart becomes active, fit the chart inside its container
      scope.$watch('active', function(newValue, oldValue) {
        if (newValue === true) {
          if (!scope.chart) {
            return;
          }
          $timeout(function() {
            scope.chart.reflow();
          });
        }
      });
    }
  };
}
