import { $http } from '../../../utils/services';
import { send$http } from '../../../api/def/ngExecutor';
import { searchCategories } from '../../../api/http/audience';
import { replaceAll } from '../../../utils/string';
import { searchInOrder } from '../../../factories/general_utils';
import { getDateXAxisConfigurationFn, getTimeXAxisConfigurationFn } from './utils';

campaignReportController.$inject = [
  '$scope',
  '$log',
  '$interval',
  '$timeout',
  'campaignManagerFactory',
  'companyAndAccountFactory',
  'reportFactory',
  'staticDataFactory',
  '$state',
  '$filter',
  '$stateParams',
  '$localStorage',
  'searchFactory',
  'jobFactory',
  'tenantFactory',
  'modalFactory',
  'featureFlagFactory',
  '$transitions'
];

/**
 * @param {ng.ILogService} $log
 * @param {ng.IIntervalService} $interval
 * @param {ng.ITimeoutService} $timeout
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {import('@uirouter/angularjs').TransitionService} $transitions
 */
export function campaignReportController(
  $scope,
  $log,
  $interval,
  $timeout,
  campaignManagerFactory,
  companyAndAccountFactory,
  reportFactory,
  staticDataFactory,
  $state,
  $filter,
  $stateParams,
  $localStorage,
  searchFactory,
  jobFactory,
  tenantFactory,
  modalFactory,
  featureFlagFactory,
  $transitions
) {
  $scope.$storage = $localStorage;
  $scope.featureFlagFactory = featureFlagFactory;
  $scope.shouldPixelOptionDefault = true;
  $scope.chartLoading = false;

  var campaign = campaignManagerFactory.selectedCampaign;
  var campaignId = campaign.id;
  var start = campaign.timeframe && campaign.timeframe.start ? replaceAll(campaign.timeframe.start, '-', '/') : null;
  var end = campaign.timeframe && campaign.timeframe.end ? replaceAll(campaign.timeframe.end, '-', '/') : null;
  var startDate = new Date(start + ' 07:00:00');
  var endDate = new Date(end + ' 07:00:00');

  var features = {
    enableMaxCPMBid: featureFlagFactory.isFeatureEnabled('MAX_CPM_BID_READ'),
    enableVisits: featureFlagFactory.isFeatureEnabled('VISITS_READ'),
    enableOpenHourVisits: featureFlagFactory.isFeatureEnabled('OPEN_HOUR_VISITS_READ'),
    enableProjectedVisits: featureFlagFactory.isFeatureEnabled('PROJECTED_VISITS_READ'),
    enableConversions: featureFlagFactory.isFeatureEnabled('CONVERSIONS_READ'),
    enableSVR: featureFlagFactory.isFeatureEnabled('SVR_READ'),
    enableSABreakdown: featureFlagFactory.isFeatureEnabled('SA_BREAKDOWN_READ'),
    enableECPMandECPV: tenantFactory.tenant.platform_pricing || tenantFactory.tenant.tenant_tier === 14,
    enableReportingCTS: featureFlagFactory.isFeatureEnabled('REPORTING_CTS'),
    enableAudienceTaxonomy: featureFlagFactory.isFeatureEnabled('AUDIENCE_TAXONOMY'),
    enableCTVOTTReport: featureFlagFactory.isFeatureEnabled('CTV_OTT_REPORT_ENHANCEMENT'),
    enableCheckGrowth: featureFlagFactory.isFeatureEnabled('CHECK_GROWTH')
  };

  // these immutable templates determine which columns are available (or must be shown) for each report group
  // groups are identified by their arbitrary key names
  // ad groups are synonymous with placements (legacy name)
  var reportGroups = {
    // the "reportGroup" prefix is added to the names for easier management
    reportGroupDailyTrend: {
      campaigns: {
        // field keys are used to identify columns
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },

          totalBudget: { defaultShow: true },
          timeframe_start_string: { defaultShow: true },
          timeframe_end_string: { defaultShow: true },
          cpvBidRate: { defaultShow: true },
          cpmBidRate: { defaultShow: true },
          pacing: { defaultShow: true },
          spends_spent: { defaultShow: true },
          spends_daily_spent: { defaultShow: true },
          impression: { defaultShow: true },
          click: { defaultShow: true },
          ctr: { defaultShow: true },
          reach: { defaultShow: true },
          ecpm: { defaultShow: true },
          ecpv: { defaultShow: true },

          visits: { defaultShow: true },
          conversions: { defaultShow: true },
          vr: { defaultShow: true },
          svr: {},
          open_hour_visits: {},
          projected_visits: {},

          secondary_actions: {},
          sar: {},
          website: {},
          directions: {},
          click_to_call: {},
          more_info: {},
          coupon: {},

          video_start: {},
          video_q1: {},
          video_q2: {},
          video_q3: {},
          video_complete: {},
          vcr: {},

          tav_lower_estimate: {},
          tav_point_estimate: {},
          tav_upper_estimate: {}
        }
      },
      placements: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          status: { mustShow: true },

          targeted_product: { defaultShow: features.enableReportingCTS },
          adpackage_name: { defaultShow: features.enableReportingCTS },
          vendorIdentifier: { defaultShow: features.enableReportingCTS },
          client_placement_name: { defaultShow: features.enableReportingCTS },

          totalBudget: { defaultShow: true },
          timeframe_start_string: { defaultShow: true },
          timeframe_end_string: { defaultShow: true },
          cpvBidRate: { defaultShow: true },
          cpmBidRate: { defaultShow: true },

          pacing: { defaultShow: true },
          spends_spent: { defaultShow: true },
          spends_daily_spent: { defaultShow: true },
          impression: { defaultShow: true },
          click: { defaultShow: true },
          ctr: { defaultShow: true },
          reach: { defaultShow: true },
          ecpm: { defaultShow: true },
          ecpv: { defaultShow: true },

          visits: { defaultShow: true },
          conversions: { defaultShow: true },
          vr: { defaultShow: true },
          svr: {},
          open_hour_visits: {},
          projected_visits: {},

          secondary_actions: {},
          sar: {},
          website: {},
          directions: {},
          click_to_call: {},
          more_info: {},
          coupon: {},

          video_start: {},
          video_q1: {},
          video_q2: {},
          video_q3: {},
          video_complete: {},
          vcr: {},

          tav_lower_estimate: {},
          tav_point_estimate: {},
          tav_upper_estimate: {}
        }
      },
      creatives: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          placementName: { mustShow: true },
          creativeSize: { mustShow: true },
          creativeType: { mustShow: true },

          adgroup_id: { defaultShow: features.enableReportingCTS },
          vendorIdentifier: { defaultShow: features.enableReportingCTS },

          totalBudget: { defaultShow: true },
          timeframe_start_string: { defaultShow: true },
          timeframe_end_string: { defaultShow: true },
          bidRate: { defaultShow: true },

          pacing: { defaultShow: true },
          spends_spent: { defaultShow: true },
          impression: { defaultShow: true },
          click: { defaultShow: true },
          ctr: { defaultShow: true },
          reach: { defaultShow: true },
          ecpm: { defaultShow: true },
          ecpv: { defaultShow: true },

          visits: { defaultShow: true },
          vr: { defaultShow: true },
          svr: {},
          open_hour_visits: {},
          projected_visits: {},

          secondary_actions: {},
          sar: {},
          website: {},
          directions: {},
          click_to_call: {},
          more_info: {},
          coupon: {},

          video_start: {},
          video_q1: {},
          video_q2: {},
          video_q3: {},
          video_complete: {},
          vcr: {}
        }
      }
    },
    reportGroupGeneric: {
      campaigns: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },

          totalBudget: { defaultShow: true },
          bidRate: { defaultShow: true },

          pacing: { defaultShow: true },
          spends_spent: { defaultShow: true },
          spends_daily_spent: { defaultShow: true },
          impression: { defaultShow: true },
          click: { defaultShow: true },
          ctr: { defaultShow: true },
          reach: { defaultShow: true },

          visits: { defaultShow: true },
          vr: { defaultShow: true },
          svr: {},
          open_hour_visits: {},
          projected_visits: {},

          secondary_actions: {},
          sar: {},
          website: {},
          directions: {},
          click_to_call: {},
          more_info: {},
          coupon: {},

          video_start: {},
          video_q1: {},
          video_q2: {},
          video_q3: {},
          video_complete: {},
          vcr: {}
        }
      },
      placements: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          status: { mustShow: true },

          totalBudget: { defaultShow: true },
          timeframe_start_string: { defaultShow: true },
          timeframe_end_string: { defaultShow: true },
          bidRate: { defaultShow: true },

          pacing: { defaultShow: true },
          spends_spent: { defaultShow: true },
          spends_daily_spent: { defaultShow: true },
          impression: { defaultShow: true },
          click: { defaultShow: true },
          ctr: { defaultShow: true },
          reach: { defaultShow: true },

          visits: { defaultShow: true },
          vr: { defaultShow: true },
          svr: {},
          open_hour_visits: {},
          projected_visits: {},

          secondary_actions: {},
          sar: {},
          website: {},
          directions: {},
          click_to_call: {},
          more_info: {},
          coupon: {},

          video_start: {},
          video_q1: {},
          video_q2: {},
          video_q3: {},
          video_complete: {},
          vcr: {}
        }
      },
      creatives: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          placementName: { mustShow: true },
          creativeSize: { mustShow: true },
          creativeType: { mustShow: true },

          totalBudget: { defaultShow: true },
          bidRate: { defaultShow: true },

          pacing: { defaultShow: true },
          spends_spent: { defaultShow: true },
          impression: { defaultShow: true },
          click: { defaultShow: true },
          ctr: { defaultShow: true },
          reach: { defaultShow: true },

          visits: { defaultShow: true },
          vr: { defaultShow: true },
          svr: {},
          open_hour_visits: {},
          projected_visits: {},

          secondary_actions: {},
          sar: {},
          website: {},
          directions: {},
          click_to_call: {},
          more_info: {},
          coupon: {},
          vcr: {}
        }
      }
    },
    reportGroupTimeSeries: {
      campaigns: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },

          totalBudget: { defaultShow: true },
          bidRate: { defaultShow: true },

          pacing: { defaultShow: true },
          spends_spent: { defaultShow: true },
          spends_daily_spent: { defaultShow: true },
          impression: { defaultShow: true },
          click: { defaultShow: true },
          ctr: { defaultShow: true },
          reach: { defaultShow: true },

          visits: { defaultShow: true },
          vr: { defaultShow: true },
          svr: {},
          open_hour_visits: {},
          projected_visits: {},

          secondary_actions: {},
          sar: {},
          website: {},
          directions: {},
          click_to_call: {},
          more_info: {},
          coupon: {},

          video_start: {},
          video_q1: {},
          video_q2: {},
          video_q3: {},
          video_complete: {},
          vcr: {}
        }
      },
      placements: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          status: { mustShow: true },

          totalBudget: { defaultShow: true },
          timeframe_start_string: { defaultShow: true },
          timeframe_end_string: { defaultShow: true },
          bidRate: { defaultShow: true },

          pacing: { defaultShow: true },
          spends_spent: { defaultShow: true },
          spends_daily_spent: { defaultShow: true },
          impression: { defaultShow: true },
          click: { defaultShow: true },
          ctr: { defaultShow: true },
          reach: { defaultShow: true },

          visits: { defaultShow: true },
          vr: { defaultShow: true },
          svr: {},
          open_hour_visits: {},
          projected_visits: {},

          secondary_actions: {},
          sar: {},
          website: {},
          directions: {},
          click_to_call: {},
          more_info: {},
          coupon: {},

          video_start: {},
          video_q1: {},
          video_q2: {},
          video_q3: {},
          video_complete: {},
          vcr: {}
        }
      },
      creatives: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          placementName: { mustShow: true },
          creativeSize: { mustShow: true },
          creativeType: { mustShow: true },

          totalBudget: { defaultShow: true },
          bidRate: { defaultShow: true },

          pacing: { defaultShow: true },
          spends_spent: { defaultShow: true },
          impression: { defaultShow: true },
          click: { defaultShow: true },
          ctr: { defaultShow: true },
          reach: { defaultShow: true },

          visits: { defaultShow: true },
          vr: { defaultShow: true },
          svr: {},
          open_hour_visits: {},
          projected_visits: {},

          secondary_actions: {},
          sar: {},
          website: {},
          directions: {},
          click_to_call: {},
          more_info: {},
          coupon: {},

          video_start: {},
          video_q1: {},
          video_q2: {},
          video_q3: {},
          video_complete: {},
          vcr: {}
        }
      }
    },
    reportGroupBehavioral: {
      campaigns: {
        availableColumns: {
          name: { mustShow: true },

          ctr: { mustShow: true },
          vr: { mustShow: true },
          sar: { mustShow: true },
          vcr: {}
        }
      },
      placements: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          status: { mustShow: true },

          ctr: { mustShow: true },
          vr: { mustShow: true },
          sar: { mustShow: true },
          vcr: {}
        }
      },
      creatives: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          placementName: { mustShow: true },
          creativeSize: { mustShow: true },
          creativeType: { mustShow: true },

          ctr: { mustShow: true },
          vr: { mustShow: true },
          sar: { mustShow: true },
          vcr: {}
        }
      }
    },
    reportGroupPublisher: {
      campaigns: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          impression: { mustShow: true },
          total_distribution: { mustShow: true },
          tv_imps: { defaultShow: true },
          tablet_imps: { defaultShow: true },
          mobile_imps: { defaultShow: true },
          desktop_imps: { defaultShow: true },
          vcr: { defaultShow: true },
          video_start: { defaultShow: false },
          video_q1: { defaultShow: false },
          video_q2: { defaultShow: false },
          video_q3: { defaultShow: false },
          video_complete: { defaultShow: false }
        }
      },
      placements: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          impression: { defaultShow: true },
          total_distribution: { defaultShow: true },
          tv_imps: { defaultShow: true },
          tablet_imps: { defaultShow: true },
          mobile_imps: { defaultShow: true },
          desktop_imps: { defaultShow: true },
          vcr: { defaultShow: true },
          video_start: { defaultShow: false },
          video_q1: { defaultShow: false },
          video_q2: { defaultShow: false },
          video_q3: { defaultShow: false },
          video_complete: { defaultShow: false }
        }
      }
    },
    reportGroupNetwork: {
      campaigns: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          impression: { defaultShow: true },
          total_distribution: { defaultShow: true },
          tv_imps: { defaultShow: true },
          tablet_imps: { defaultShow: true },
          mobile_imps: { defaultShow: true },
          desktop_imps: { defaultShow: true },
          video_start: { defaultShow: false },
          video_q1: { defaultShow: false },
          video_q2: { defaultShow: false },
          video_q3: { defaultShow: false },
          video_complete: { defaultShow: false },
          vcr: { defaultShow: true }
        }
      },
      placements: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          impression: { defaultShow: true },
          total_distribution: { defaultShow: true },
          tv_imps: { defaultShow: true },
          tablet_imps: { defaultShow: true },
          mobile_imps: { defaultShow: true },
          desktop_imps: { defaultShow: true },
          video_start: { defaultShow: false },
          video_q1: { defaultShow: false },
          video_q2: { defaultShow: false },
          video_q3: { defaultShow: false },
          video_complete: { defaultShow: false },
          vcr: { defaultShow: true }
        }
      }
    },
    reportGroupConversions: {
      campaigns: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          conversions: { mustShow: true },
          view_conversions: { mustShow: true },
          click_conversions: { mustShow: true }
        }
      },
      placements: {
        availableColumns: {
          id: { mustShow: true },
          name: { mustShow: true },
          conversions: { mustShow: true },
          view_conversions: { mustShow: true },
          click_conversions: { mustShow: true }
        }
      }
    }
  };

  // this maps each report to a group (inside reportGroups)
  var reportGroupMapping = {
    daily: 'reportGroupDailyTrend',
    visitation: 'reportGroupGeneric',
    dow: 'reportGroupTimeSeries',
    tod: 'reportGroupTimeSeries',
    dowtod: 'reportGroupTimeSeries',
    brand: 'reportGroupGeneric',
    category: 'reportGroupGeneric',
    audience: 'reportGroupGeneric',
    os: 'reportGroupGeneric',
    device: 'reportGroupGeneric',
    product: 'reportGroupGeneric',
    demographics: 'reportGroupGeneric',
    behavioral: 'reportGroupBehavioral',
    audiencecategory: 'reportGroupBehavioral',
    brandaffinity: 'reportGroupBehavioral',
    audienceaffinity: 'reportGroupBehavioral',
    location: 'reportGroupGeneric', // this will be moved to a new group eventually
    publisher: 'reportGroupPublisher',
    network: 'reportGroupNetwork',
    conversions: 'reportGroupConversions'
  };

  function getIdColumnName(level) {
    if (level == 'creatives') {
      return 'Creative ID';
    } else if (level == 'campaigns') {
      return 'Campaign ID';
    } else if (level == 'placements') {
      return 'Adgroup ID';
    }
    return 'ID';
  }

  function getNameColumnName(level) {
    if (level == 'creatives') {
      return 'Creative Name';
    } else if (level == 'campaigns') {
      return 'Campaign Name';
    } else if (level == 'placements') {
      return 'Adgroup Name';
    }
    return 'Name';
  }

  // this template describes every column in the tables, and is used for saving local storage states
  // the ordering here governs the order in which the columns are shown
  // should never be modified
  var columnConfigConstant = [
    {
      name: 'ID',
      field: 'id',
      hideInBreakdown: true,
      components: [{ field: 'id' }],
      type: 'details'
    },
    {
      name: 'Name',
      field: 'name',
      hideInBreakdown: true,
      components: [{ field: 'name' }],
      type: 'details'
    },
    {
      name: 'Targeting Indicator',
      field: 'targeted_product',
      components: [{ field: 'targeted_product' }],
      type: 'delivery'
    },
    {
      name: 'Ad Package Name',
      field: 'adpackage_name',
      hasRequiredFeature: features.enableReportingCTS,
      components: [{ field: 'adpackage_name' }],
      type: 'delivery'
    },
    {
      name: 'Third Party Placement ID',
      field: 'vendorIdentifier',
      hasRequiredFeature: features.enableReportingCTS,
      components: [{ field: 'vendorIdentifier' }],
      type: 'delivery'
    },
    {
      name: 'Third Party Placement Name',
      field: 'client_placement_name',
      hasRequiredFeature: features.enableReportingCTS,
      components: [{ field: 'client_placement_name' }],
      type: 'delivery'
    },
    {
      name: 'Ad Group ID',
      field: 'adgroup_id',
      hasRequiredFeature: features.enableReportingCTS,
      components: [{ field: 'adgroup_id' }],
      type: 'delivery'
    },
    {
      name: 'Status',
      field: 'status',
      components: [{ field: 'status', getCSSClass: getStatusCSSClass }],
      type: 'details'
    },
    {
      name: 'Ad Group Name',
      field: 'placementName',
      hideInBreakdown: true,
      components: [{ field: 'placementName' }],
      type: 'details'
    },
    {
      name: 'Size',
      field: 'creativeSize',
      hideInBreakdown: true,
      components: [{ field: 'creativeSize' }],
      type: 'details'
    },
    {
      name: 'Type',
      field: 'creativeType',
      hideInBreakdown: true,
      components: [{ field: 'creativeType' }],
      type: 'details'
    },
    {
      name: 'Pacing',
      field: 'pacing',
      components: [{ field: 'pacing', filter: 'percentage' }],
      type: 'delivery'
    },
    {
      name: 'Start',
      field: 'timeframe_start_string',
      components: [{ field: 'timeframe_start_string' }],
      type: 'flightAndBudget'
    },
    {
      name: 'End',
      field: 'timeframe_end_string',
      components: [{ field: 'timeframe_end_string' }],
      type: 'flightAndBudget'
    },
    {
      name: 'Max CPM Bid',
      hasRequiredFeature: features.enableMaxCPMBid,
      field: 'cpmBidRate',
      components: [{ field: 'cpmBidRate', filter: 'shortnumber' }],
      type: 'flightAndBudget'
    },
    {
      name: 'Max CPV Bid',
      field: 'cpvBidRate',
      components: [{ field: 'cpvBidRate', filter: 'shortnumber' }],
      type: 'flightAndBudget'
    },
    {
      name: 'Budget',
      field: 'totalBudget',
      components: [
        {
          field: 'totalBudgetWithCurrencyCode',
          exportField: 'totalBudget',
          exportFilter: 'number'
        }
      ],
      type: 'flightAndBudget'
    },
    {
      name: 'Total Spent',
      field: 'spends_spent',
      components: [{ field: 'spentWithCurrencyCode', exportField: 'total_spent', exportFilter: 'number' }],
      type: 'delivery'
    },
    {
      name: 'Spent Today',
      field: 'spends_daily_spent',
      components: [
        {
          field: 'dailySpentWithCurrencyCode',
          exportField: 'spends_daily_spent',
          exportFilter: 'number'
        }
      ],
      type: 'delivery'
    },
    {
      name: 'Impressions',
      field: 'impression',
      components: [{ field: 'impression', filter: 'number' }],
      type: 'delivery'
    },
    {
      name: 'Clicks',
      field: 'click',
      components: [{ field: 'click', filter: 'number' }],
      type: 'delivery'
    },
    {
      name: 'CTR',
      field: 'ctr',
      components: [{ field: 'ctr', filter: 'percentageNotMultiplied100' }],
      type: 'delivery'
    },
    {
      name: 'Visits',
      hasRequiredFeature: features.enableVisits,
      field: 'visits',
      components: [{ field: 'visits', filter: 'customNumber' }],
      type: 'visits'
    },
    {
      name: 'Projected Visits',
      field: 'tav_point_estimate',
      components: [{ field: 'tav_point_estimate', filter: 'customNumber' }],
      type: 'visits',
      glyph: 'Projected Visits is a cumulative figure only and independent of date filters.'
    },
    {
      name: 'VR',
      hasRequiredFeature: features.enableVisits,
      field: 'vr',
      components: [{ field: 'vr', filter: 'percentage' }],
      type: 'visits'
    },
    {
      name: 'Web Conversions',
      hasRequiredFeature: features.enableConversions,
      field: 'conversions',
      components: [{ field: 'conversions', filter: 'customNumber' }],
      type: 'visits'
    },
    {
      name: 'View Conversions',
      hasRequiredFeature: features.enableConversions,
      field: 'view_conversions',
      components: [{ field: 'view_conversions', filter: 'customNumber' }],
      type: 'visits'
    },
    {
      name: 'Click Conversions',
      hasRequiredFeature: features.enableConversions,
      field: 'click_conversions',
      components: [{ field: 'click_conversions', filter: 'customNumber' }],
      type: 'visits'
    },
    {
      name: 'Open Hour Visits',
      hasRequiredFeature: features.enableOpenHourVisits,
      field: 'open_hour_visits',
      components: [{ field: 'open_hour_visits', filter: 'customNumber' }],
      type: 'visits'
    },
    {
      name: 'Projected Visits (v1)',
      hasRequiredFeature: features.enableProjectedVisits,
      field: 'projected_visits',
      components: [{ field: 'projected_visits', filter: 'customNumber' }],
      type: 'visits'
    },
    {
      name: 'Reach',
      field: 'reach',
      components: [{ field: 'reach', filter: 'customNumber' }],
      type: 'delivery'
    },
    {
      name: 'Total Sec. Actions',
      field: 'secondary_actions',
      components: [{ field: 'secondary_actions', filter: 'number' }],
      type: 'secondaryActions'
    },
    {
      name: 'Header/ Website',
      hasRequiredFeature: features.enableSABreakdown,
      field: 'website',
      components: [{ field: 'website', filter: 'number' }],
      type: 'secondaryActions'
    },
    {
      name: 'Directions',
      hasRequiredFeature: features.enableSABreakdown,
      field: 'directions',
      components: [{ field: 'directions', filter: 'number' }],
      type: 'secondaryActions'
    },
    {
      name: 'Click to Call',
      hasRequiredFeature: features.enableSABreakdown,
      field: 'click_to_call',
      components: [{ field: 'click_to_call', filter: 'number' }],
      type: 'secondaryActions'
    },
    {
      name: 'CTA/ More Info',
      hasRequiredFeature: features.enableSABreakdown,
      field: 'more_info',
      components: [{ field: 'more_info', filter: 'number' }],
      type: 'secondaryActions'
    },
    {
      name: 'Promo/ Coupon',
      hasRequiredFeature: features.enableSABreakdown,
      field: 'coupon',
      components: [{ field: 'coupon', filter: 'number' }],
      type: 'secondaryActions'
    },
    {
      name: 'SAR',
      field: 'sar',
      components: [{ field: 'sar', filter: 'percentage' }],
      type: 'secondaryActions'
    },
    {
      name: 'SVR',
      hasRequiredFeature: features.enableSVR,
      field: 'svr',
      components: [{ field: 'svr', filter: 'percentage' }],
      type: 'visits'
    },
    {
      name: 'eCPM',
      hasRequiredFeature: features.enableECPMandECPV,
      field: 'ecpm',
      components: [{ field: 'ecpmWithCurrencyCode', exportField: 'ecpm', exportFilter: 'number' }],
      type: 'delivery',
      glyph: 'eCPM is based on effective spend and impressions for the previous day.'
    },
    {
      name: 'eCPV',
      hasRequiredFeature: features.enableECPMandECPV,
      field: 'ecpv',
      components: [{ field: 'ecpvWithCurrencyCode', exportField: 'ecpv', exportFilter: 'number' }],
      type: 'delivery',
      glyph: 'eCPV is based on effective spend and visits for the previous day.'
    },
    {
      name: 'Total Distribution',
      hasRequiredFeature: features.enableCTVOTTReport,
      field: 'total_distribution',
      components: [{ field: 'total_distribution', filter: 'number' }],
      type: 'delivery'
    },
    {
      name: 'TV',
      hasRequiredFeature: features.enableCTVOTTReport,
      field: 'tv_imps',
      components: [{ field: 'tv_imps', filter: 'number' }],
      type: 'devices'
    },
    {
      name: 'Tablet',
      hasRequiredFeature: features.enableCTVOTTReport,
      field: 'tablet_imps',
      components: [{ field: 'tablet_imps', filter: 'number' }],
      type: 'devices'
    },
    {
      name: 'Mobile',
      hasRequiredFeature: features.enableCTVOTTReport,
      field: 'mobile_imps',
      components: [{ field: 'mobile_imps', filter: 'number' }],
      type: 'devices'
    },
    {
      name: 'Desktop',
      hasRequiredFeature: features.enableCTVOTTReport,
      field: 'desktop_imps',
      components: [{ field: 'desktop_imps', filter: 'number' }],
      type: 'devices'
    },
    {
      name: 'Video Start',
      field: 'video_start',
      components: [{ field: 'video_start', filter: 'number' }],
      type: 'video'
    },
    {
      name: 'First Quartile',
      field: 'video_q1',
      components: [{ field: 'video_q1', filter: 'number' }],
      type: 'video'
    },
    {
      name: 'Midpoint',
      field: 'video_q2',
      components: [{ field: 'video_q2', filter: 'number' }],
      type: 'video'
    },
    {
      name: 'Third Quartile',
      field: 'video_q3',
      components: [{ field: 'video_q3', filter: 'number' }],
      type: 'video'
    },
    {
      name: 'Video Complete',
      field: 'video_complete',
      components: [{ field: 'video_complete', filter: 'number' }],
      type: 'video'
    },
    {
      name: 'Pixel Id',
      field: 'pixel_id',
      components: [{ field: 'pixel_id' }],
      type: 'details'
    },
    {
      name: 'Pixel Name',
      field: 'pixel_name',
      components: [{ field: 'pixel_name' }],
      type: 'details'
    },
    {
      name: 'VCR',
      field: 'vcr',
      components: [{ field: 'vcr', filter: 'percentage' }],
      type: 'video'
    }
  ];

  // this generates an appropriate column config based on the specified report
  // if existingConfig is provided, it will use the existing column selections if available
  function generateColumnConfig(reportKey, level) {
    var existingConfig = angular.copy($scope.$storage.columnSelections); // make a copy to prevent altering the origin

    if (!existingConfig) {
      // use our default config as the original template
      existingConfig = angular.copy(columnConfigConstant);
    } else {
      /*
       Added to fix the issue of columns sometimes dissapearing from the picker.
       Upon first pick from column picker, all the columns were stored to localstorage. Instead of using localstorage
       as the source of truth, columnConfigConstant array is used instead, and only selections are being used from localstorage
      */
      const storageConfigMap = existingConfig.reduce((map, config) => {
        map[config.field + config.name] = config;
        return map;
      }, {});
      existingConfig = columnConfigConstant.map(config => {
        const storageConfig = storageConfigMap[config.field + config.name];
        config.selected = storageConfig?.selected;
        return config;
      });
    }

    const idColumn = existingConfig.find(c => c.field === 'id');
    idColumn.name = getIdColumnName(level);
    const nameColumn = existingConfig.find(c => c.field === 'name');
    nameColumn.name = getNameColumnName(level);

    if (!reportGroupMapping.hasOwnProperty(reportKey)) {
      throw "Specified report '" + reportKey + "' does not belong to a report group!";
    }

    if (['campaigns', 'placements', 'creatives'].indexOf(level) === -1) {
      throw "Specified level '" + "' is not recognized!";
    }

    var reportGroup = reportGroupMapping[reportKey];
    var reportGroupConfig = reportGroups[reportGroup][level];
    var outputConfig = [];

    for (var i = 0; i < existingConfig.length; i++) {
      var column = existingConfig[i];

      // if ths column is not available for this report group, drop the column
      if (!reportGroupConfig.availableColumns.hasOwnProperty(column.field)) {
        continue;
      }

      // if the user does not have this feature enabled, also drop the column
      if (column.hasRequiredFeature === false) {
        continue;
      }

      // if the report group allows this column to be shown
      if (reportGroupConfig.availableColumns.hasOwnProperty(column.field)) {
        let columnProperties = reportGroupConfig.availableColumns[column.field];

        // determine if the column will be shown in the table
        // logic is split into individual cases for clarity
        if (columnProperties.mustShow) {
          // if column MUST be shown
          column.hide = false;
          column.mustShow = true; // column picker will use this field later
        } else {
          column.mustShow = false;

          if (column.selected !== undefined) {
            // if the user has made a customization
            column.hide = !column.selected;
          } else if (columnProperties.defaultShow) {
            // if the column is shown by default
            column.hide = false;
          } else {
            column.hide = true;
          }
        }

        outputConfig.push(column);
      }
    }
    return outputConfig;
  }

  const PROGRESS_INTERVAL_DELAY = 3000;

  $scope.isArray = angular.isArray;

  $scope.campaignBulkUploadVars = {
    states: {
      campaignCreateSuccess: $stateParams.isBulkUpload,
      campaignBulkUploadSlides: !campaign.bulk_upload_job_id && !$stateParams.isBulkUpload,
      campaignBulkUploadInProgress: !!campaign.bulk_upload_job_id,
      campaignBulkUploadDone: false
    },
    changeBulkUploadState: function(toState) {
      for (var state in $scope.campaignBulkUploadVars.states) {
        $scope.campaignBulkUploadVars.states[state] = false;
      }
      $scope.campaignBulkUploadVars.states[toState] = true;
    },
    uploadModalOpen: false,
    progress: 0.0,
    output_file_url: null,
    message: null,
    intervalPromise: null
  };

  $scope.openBulkManageCampaign = function() {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaign_bulk_edit.html',
      controller: 'CampaignBulkEditModalInstance',
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    };

    var data = {
      campaign: campaignManagerFactory.selectedCampaign,
      campaignBulkUploadVars: $scope.campaignBulkUploadVars,
      PROGRESS_INTERVAL_DELAY: PROGRESS_INTERVAL_DELAY
    };

    var handlers = {
      checkCampaignBulkUploadProgress: $scope.checkCampaignBulkUploadProgress
    };

    $scope.campaignBulkUploadVars.uploadModalOpen = true;

    modalFactory.createModal(modalOptions, data, handlers).then(function() {
      $log.info('Modal dismissed at: ' + new Date());
      $scope.campaignBulkUploadVars.uploadModalOpen = false;
    });
  };

  $scope.stopCheckingUploadProgress = function() {
    if ($scope.campaignBulkUploadVars.intervalPromise) {
      $interval.cancel($scope.campaignBulkUploadVars.intervalPromise);
      $scope.campaignBulkUploadVars.intervalPromise = null;
    } else {
      $log.warn("Attempted to cancel upload while there wasn't one in progress.");
    }
  };

  $scope.checkCampaignBulkUploadProgress = function() {
    var jobId = $scope.campaignManagerFactory.selectedCampaign.bulk_upload_job_id;
    jobFactory.getJobStatus(jobId).then(
      function(data) {
        if (data.status === 'Success') {
          $scope.campaignBulkUploadVars.progress = data.value;
          $scope.campaignBulkUploadVars.output_file_url = data.output_url;
          $scope.campaignBulkUploadVars.message = data.message;
          $scope.stopCheckingUploadProgress();
          $timeout(function() {
            // 1s delay so progress bar animation can finish
            $scope.campaignBulkUploadVars.changeBulkUploadState('campaignBulkUploadDone');
          }, 3000);
        } else if (data.status === 'Failed') {
          $scope.campaignBulkUploadVars.changeBulkUploadState('campaignBulkUploadDone');
          $scope.campaignBulkUploadVars.message = data.message;
          $scope.campaignBulkUploadVars.progress = data.value;
          $scope.campaignBulkUploadVars.output_file_url = data.output_url;
          $scope.stopCheckingUploadProgress();
        } else {
          $scope.campaignBulkUploadVars.progress = data.value;
        }
      },
      function(data) {
        $scope.campaignBulkUploadVars.errorMsg = data.message;
      }
    );
  };

  $scope.viewChart = true;

  $scope.campaignStartDate = startDate.getTime();

  $scope.campaign_ready = false;

  $scope.campaignManagerFactory = campaignManagerFactory;

  $scope.adGroups = campaign.adGroups;
  $scope.highlightedAdGroup = null;
  $scope.highlightedAdGroupName = '';
  $scope.creativePreviewUrl = '';
  $scope.creativesForHighlightedAdGroup = [];

  $scope.campaign = campaign;
  $scope.salesforceNumber = $scope.campaign.salesforceNumber;
  $scope.client_io_name = $scope.campaign.client_io_name;
  $scope.clientIoNumber = $scope.campaign.clientIoNumber;

  $scope.map_div = 'campaign-playback-map';
  $scope.actionsDivClass = 'campaign-actions';

  $scope.showPlayback = true;
  $scope.editingAdGroupName = false;

  $scope.showBricks = false;
  $scope.creativesBySize = {}; // keys are dimensions (e.g. "320x50") and values are image urls

  $scope.errorMsg = '';

  var defaultExpandedRow = {
    type: '',
    index: null,
    beforeData: null
  };

  // This is a makeshift change to stop arbitrarily long error msg from horcrux. It will be removed when we finish redesign
  const errorMsg = 'Sorry, an unexpected error occurred. Please try again or contact support.';

  $scope.expandedRow = angular.copy(defaultExpandedRow);

  $scope.tabsOrder = [
    {
      text: 'Settings',
      id: 'Settings',
      active: true
    },
    {
      text: 'Excluded Publishers',
      id: 'ExcludedPublishers',
      active: false
    },
    {
      text: 'Included Publishers',
      id: 'IncludedPublishers',
      active: false
    }
  ];

  $scope.tabs = {
    Reporting: {
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaign-selected-live-view.html',
      msg: ''
    },
    Settings: {
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaignSettings.html',
      msg: ''
    },
    'Excluded Publishers': {
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaignBlacklist.html',
      msg: ''
    },
    'Included Publishers': {
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.campaignWhitelist.html',
      msg: ''
    }
  };

  if (!featureFlagFactory.isFeatureEnabled('CAMPAIGN_BLACKLIST_READ')) {
    $scope.tabsOrder.splice(1, 1);
  }

  updateCurrentTab();

  const updateCurrentTabTransitionHandler = $transitions.onSuccess({}, () => {
    updateCurrentTab();
  });

  var highlightedCreative = null;

  $scope.campaignSummaryConfig = {
    table: {
      tableName: 'campaignSummary',
      header: null,
      extra: null,
      defaultOrderField: 'id',
      filter: '',
      limit: 50,
      breakdownShowMoreEnabled: true,
      rowTrigger: function(item) {
        // Handler for when user clicks on a table row.
        // We can't trigger this function if new placement is ongoing,
        if ($scope.booleans.newPlacementProgress == true) {
          return;
        }

        $scope.errorMsg = null;

        highLightRowSwitch(item);

        // If a row is selected, determine which action buttons to show
        if ($scope.highlightedAdGroup) {
          actionButtonManagement();
        }

        // Render charts for selected row
        $scope.updateChartData();

        // Change creatives
        groupCreativesBySize(item);
      },
      expandOrCollapseRowTrigger: function(item) {
        $scope.toggleTableRowBreakdown(item.id);
      },
      extraRowTrigger: function(item) {
        $scope.editAction(item);
      },
      paginationTrigger: function() {
        $scope.campaignSummaryConfig.table.rowTrigger();
        $scope.resetTable();
      }
    },
    chart: {
      daily: {
        active: true,
        changed: false,
        name: 'daily',
        dataLeft: null,
        dataRight: null, // optional
        heading: null,
        type: 'spline',
        progress: false,
        defaultSeries: ['impression', 'visits'],
        fallbackDefaultSeries: ['impression', 'click'], // in case defaultSeries can't be used due to feature management
        seriesOptions: {
          impression: {
            color: '#83bc20',
            name: 'Impressions',
            dataLabel: 'number'
          },
          open_hour_visits: {
            color: '#e67357',
            name: 'Open Hour Visits',
            dataLabel: 'number',
            featureLocked: !features.enableOpenHourVisits
          },
          click: {
            color: '#bd29de',
            name: 'Clicks',
            dataLabel: 'number'
          },
          ctr: {
            // need as percentage
            color: '#f44242',
            name: 'CTR',
            dataLabel: 'percent'
          },
          dailyReach: {
            color: '#FFA500',
            name: 'Daily Reach',
            dataLabel: 'number'
          },
          cumulativeReach: {
            color: '#1484c3',
            name: 'Cumulative Reach',
            dataLabel: 'number'
          },
          spends_spent: {
            color: '#00008B',
            name: 'Total Spend',
            dataLabel: 'number'
          },
          secondary_actions: {
            color: '#00008B',
            name: 'Secondary Actions',
            dataLabel: 'number'
          },
          visits: {
            color: '#00008B',
            name: 'Visits',
            dataLabel: 'number',
            featureLocked: !features.enableVisits
          },
          sar: {
            color: '#75f4b0',
            name: 'SAR',
            dataLabel: 'percent'
          },
          vcr: {
            color: '#75f4b0',
            name: 'VCR',
            dataLabel: 'percent'
          }
        }
      },
      visitation: {
        active: false,
        changed: false,
        name: 'visitation',
        data: null,
        heading: null,
        type: 'bar',
        progress: false
      },
      dow: {
        active: false,
        changed: false,
        name: 'dow',
        data: null,
        heading: null,
        type: 'spline',
        progress: false,
        defaultSeries: ['impression', 'visits'],
        fallbackDefaultSeries: ['impression', 'click'], // in case defaultSeries can't be used due to feature management
        seriesOptions: {
          impression: {
            color: '#83bc20',
            name: 'Impressions',
            dataLabel: 'number'
          },
          open_hour_visits: {
            color: '#e67357',
            name: 'Open Hour Visits',
            dataLabel: 'number',
            featureLocked: !features.enableOpenHourVisits
          },
          click: {
            color: '#bd29de',
            name: 'Clicks',
            dataLabel: 'number'
          },
          ctr: {
            // need as percentage
            color: '#f44242',
            name: 'CTR',
            dataLabel: 'percent'
          },
          spends_spent: {
            color: '#00008B',
            name: 'Total Spend',
            dataLabel: 'number'
          },
          secondary_actions: {
            color: '#00008B',
            name: 'Secondary Actions',
            dataLabel: 'number'
          },
          visits: {
            color: '#00008B',
            name: 'Visits',
            dataLabel: 'number',
            featureLocked: !features.enableVisits
          },
          sar: {
            color: '#75f4b0',
            name: 'SAR',
            dataLabel: 'percent'
          },
          vcr: {
            color: '#75f4b0',
            name: 'VCR',
            dataLabel: 'percent'
          }
        }
      },
      tod: {
        active: false,
        changed: false,
        name: 'tod',
        data: null,
        heading: null,
        type: 'spline',
        progress: false,
        defaultSeries: ['impression', 'visits'],
        fallbackDefaultSeries: ['impression', 'click'], // in case defaultSeries can't be used due to feature management
        seriesOptions: {
          impression: {
            color: '#83bc20',
            name: 'Impressions',
            dataLabel: 'number'
          },
          open_hour_visits: {
            color: '#e67357',
            name: 'Open Hour Visits',
            dataLabel: 'number',
            featureLocked: !features.enableOpenHourVisits
          },
          click: {
            color: '#bd29de',
            name: 'Clicks',
            dataLabel: 'number'
          },
          ctr: {
            color: '#f44242',
            name: 'CTR',
            dataLabel: 'percent'
          },
          spends_spent: {
            color: '#00008B',
            name: 'Total Spend',
            dataLabel: 'number'
          },
          secondary_actions: {
            color: '#00008B',
            name: 'Secondary Actions',
            dataLabel: 'number'
          },
          visits: {
            color: '#00008B',
            name: 'Visits',
            dataLabel: 'number',
            featureLocked: !features.enableVisits
          },
          sar: {
            color: '#75f4b0',
            name: 'SAR',
            dataLabel: 'percent'
          },
          vcr: {
            color: '#75f4b0',
            name: 'VCR',
            dataLabel: 'percent'
          }
        }
      },
      dowtod: {
        active: false,
        changed: false,
        name: 'dowtod',
        data: null,
        heading: null,
        type: 'single-line',
        progress: false,
        defaultSeries: ['impression'],
        seriesOptions: {
          impression: {
            color: '#83bc20',
            name: 'Impressions',
            dataLabel: 'number'
          },
          open_hour_visits: {
            color: '#e67357',
            name: 'Open Hour Visits',
            dataLabel: 'number',
            featureLocked: !features.enableOpenHourVisits
          },
          click: {
            color: '#bd29de',
            name: 'Clicks',
            dataLabel: 'number'
          },
          ctr: {
            color: '#f44242',
            name: 'CTR',
            dataLabel: 'percent'
          },
          spends_spent: {
            color: '#00008B',
            name: 'Spend',
            dataLabel: 'number'
          },
          secondary_actions: {
            color: '#00008B',
            name: 'Secondary Actions',
            dataLabel: 'number'
          },
          visits: {
            color: '#00008B',
            name: 'Visits',
            dataLabel: 'number',
            featureLocked: !features.enableVisits
          },
          sar: {
            color: '#75f4b0',
            name: 'SAR',
            dataLabel: 'percent'
          },
          vcr: {
            color: '#75f4b0',
            name: 'VCR',
            dataLabel: 'percent'
          }
        },
        chartConfig: {
          xAxis: getTimeXAxisConfigurationFn(),
          yAxisTitle: 'Impressions'
        }
      },
      brand: {
        active: false,
        changed: false,
        name: 'dowtod',
        data: null,
        heading: null,
        type: 'spline'
      },
      category: {
        active: false,
        changed: false,
        name: 'category',
        data: null,
        heading: null,
        type: 'spline'
      },
      audience: {
        active: false,
        changed: false,
        name: 'audience',
        data: null,
        heading: null,
        type: 'spline'
      },
      os: {
        active: false,
        changed: false,
        name: 'os',
        data: null,
        heading: null,
        type: 'pie'
      },
      device: {
        active: false,
        changed: false,
        name: 'device',
        data: null,
        heading: null,
        type: 'pie'
      },
      product: {
        active: false,
        changed: false,
        name: 'product',
        data: null,
        heading: null,
        type: 'column',
        progress: false,
        defaultSeries: ['vr'],
        seriesOptions: {
          impression: {
            color: '#83bc20',
            name: 'Impressions',
            dataLabel: 'number'
          },
          open_hour_visits: {
            color: '#e67357',
            name: 'Open Hour Visits',
            dataLabel: 'number',
            featureLocked: !features.enableOpenHourVisits
          },
          visits: {
            color: '#00008B',
            name: 'Visits',
            dataLabel: 'number',
            featureLocked: !features.enableVisits
          },
          click: {
            color: '#bd29de',
            name: 'Clicks',
            dataLabel: 'number'
          },
          ctr: {
            color: '#f44242',
            name: 'CTR',
            dataLabel: 'percent'
          },
          spends_spent: {
            color: '#00008B',
            name: 'Total Spend',
            dataLabel: 'number'
          },
          secondary_actions: {
            color: '#00008B',
            name: 'Secondary Actions',
            dataLabel: 'number'
          },
          sar: {
            color: '#75f4b0',
            name: 'SAR',
            dataLabel: 'percent'
          },
          vr: {
            color: '#83bc20',
            name: 'Visitation Rate (VR)',
            dataLabel: 'percent'
          },
          vcr: {
            color: '#75f4b0',
            name: 'VCR',
            dataLabel: 'percent'
          }
        },
        productOptions: {
          audiencelocation: [
            {
              name: 'GT Audience',
              key: 'ga',
              checked: true,
              enabled: features.enableAudienceTaxonomy
            },
            {
              name: 'Location Group Audience',
              key: 'lg',
              checked: true,
              enabled: features.enableAudienceTaxonomy
            },
            {
              name: 'Location Audience',
              key: 'la',
              checked: true,
              enabled: !features.enableAudienceTaxonomy
            },
            {
              name: 'Lookalike Audience',
              key: 'lo',
              checked: true
            },
            {
              name: 'Behavioral Audience',
              key: 'ba',
              checked: true,
              enabled: !features.enableAudienceTaxonomy
            },
            {
              name: 'Custom Audience',
              key: 'ca',
              checked: true
            },
            {
              name: '3rd / 1st party Audience',
              key: 'tf',
              checked: true
            },
            {
              name: 'Retargeting',
              key: 're',
              checked: true
            }
          ],
          locationtargeting: [
            {
              name: 'Neighborhoods',
              key: 'ne',
              checked: true
            },
            {
              name: 'Radial',
              key: 'ra',
              checked: true
            },
            {
              name: 'Geotargeting',
              key: 'ge',
              checked: true
            }
          ],
          blueprints: [
            {
              name: 'In-Store',
              key: 'is',
              checked: true
            },
            {
              name: 'On-Lot',
              key: 'ol',
              checked: true
            },
            {
              name: 'Retail Block',
              key: 'rb',
              checked: true
            },
            {
              name: 'Near-by / Wider-reach',
              key: 'nw',
              checked: true
            }
          ],
          weather: [
            {
              name: 'Weather Triggering',
              key: 'we',
              checked: true
            }
          ]
        }
      },
      conversions: {
        active: false,
        changed: false,
        name: 'conversions',
        data: null,
        heading: null,
        type: 'single-line',
        progress: false,
        defaultSeries: ['pixels'],
        fallbackDefaultSeries: ['pixels'], // in case defaultSeries can't be used due to feature management
        seriesOptions: {
          pixels: {
            color: '#65c5a5',
            name: 'All Pixels',
            dataLabel: 'number',
            tooltipName: 'conversions',
            displayName: 'All Pixels',
            value: 'pixels'
          }
        },
        chartConfig: {
          xAxis: getDateXAxisConfigurationFn(),
          yAxisTitle: 'Conversions'
        }
      },
      publisher: {
        active: false,
        changed: false,
        name: 'publisher',
        data: null,
        heading: null,
        type: 'column',
        progress: false,
        defaultSeries: ['vcr'],
        fallbackSeries: ['impression'],
        seriesOptions: {
          impression: {
            color: '#83bc20',
            name: 'Impressions',
            dataLabel: 'number'
          },
          video_start: {
            color: '#e67357',
            name: 'Video Start',
            dataLabel: 'number'
          },
          video_q1: {
            color: '#00008B',
            name: 'Video First Quartile',
            dataLabel: 'number'
          },
          video_q2: {
            color: '#bd29de',
            name: 'Video Midpoint',
            dataLabel: 'number'
          },
          video_q3: {
            color: '#f44242',
            name: 'Video Third Quartile',
            dataLabel: 'number'
          },
          video_complete: {
            color: '#00008B',
            name: 'Video Complete',
            dataLabel: 'number'
          },
          vcr: {
            color: '#00008B',
            name: 'VCR',
            dataLabel: 'percentage'
          }
        }
      },
      network: {
        active: false,
        changed: false,
        name: 'network',
        data: null,
        heading: null,
        type: 'column',
        progress: false,
        defaultSeries: ['vcr'],
        fallbackSeries: ['impression'],
        seriesOptions: {
          impression: {
            color: '#83bc20',
            name: 'Impressions',
            dataLabel: 'number'
          },
          video_start: {
            color: '#e67357',
            name: 'Video Start',
            dataLabel: 'number'
          },
          video_q1: {
            color: '#00008B',
            name: 'Video First Quartile',
            dataLabel: 'number'
          },
          video_q2: {
            color: '#bd29de',
            name: 'Video Midpoint',
            dataLabel: 'number'
          },
          video_q3: {
            color: '#f44242',
            name: 'Video Third Quartile',
            dataLabel: 'number'
          },
          video_complete: {
            color: '#00008B',
            name: 'Video Complete',
            dataLabel: 'number'
          },
          vcr: {
            color: '#00008B',
            name: 'VCR',
            dataLabel: 'percentage'
          }
        }
      }
    }
  };

  var audienceChartsConfig = {
    behavioral: {
      active: true,
      changed: false,
      name: 'behavioral',
      data: null,
      heading: null,
      type: 'column',
      progress: false,
      defaultSeries: ['vr'],
      seriesOptions: {
        vr: {
          color: '#83bc20',
          name: 'Visitation Rate (VR)',
          dataLabel: 'percent'
        },
        ctr: {
          color: '#f44242',
          name: 'CTR',
          dataLabel: 'percent'
        },
        sar: {
          color: '#75f4b0',
          name: 'SAR',
          dataLabel: 'percent'
        },
        vcr: {
          color: '#00008B',
          name: 'VCR',
          dataLabel: 'percent'
        }
      }
    },
    audiencecategory: {
      active: false,
      changed: false,
      name: 'category',
      data: null,
      heading: null,
      type: 'column',
      progress: false,
      defaultSeries: ['vr'],
      seriesOptions: {
        vr: {
          color: '#83bc20',
          name: 'Visitation Rate (VR)',
          dataLabel: 'percent'
        },
        ctr: {
          color: '#f44242',
          name: 'CTR',
          dataLabel: 'percent'
        },
        sar: {
          color: '#75f4b0',
          name: 'SAR',
          dataLabel: 'percent'
        },
        vcr: {
          color: '#00008B',
          name: 'VCR',
          dataLabel: 'percent'
        }
      }
    },
    brandaffinity: {
      active: false,
      changed: false,
      name: 'brandaffinity',
      data: null,
      heading: null,
      type: 'column',
      progress: false,
      defaultSeries: ['vr'],
      seriesOptions: {
        vr: {
          color: '#83bc20',
          name: 'Visitation Rate (VR)',
          dataLabel: 'percent'
        },
        ctr: {
          color: '#f44242',
          name: 'CTR',
          dataLabel: 'percent'
        },
        sar: {
          color: '#75f4b0',
          name: 'SAR',
          dataLabel: 'percent'
        },
        vcr: {
          color: '#75f4b0',
          name: 'VCR',
          dataLabel: 'percent'
        }
      }
    },
    audienceaffinity: {
      active: false,
      changed: false,
      name: 'audienceaffinity',
      data: null,
      heading: null,
      type: 'column',
      progress: false,
      defaultSeries: ['vr'],
      seriesOptions: {
        vr: {
          color: '#83bc20',
          name: 'Visitation Rate (VR)',
          dataLabel: 'percent'
        },
        ctr: {
          color: '#f44242',
          name: 'CTR',
          dataLabel: 'percent'
        },
        sar: {
          color: '#75f4b0',
          name: 'SAR',
          dataLabel: 'percent'
        },
        vcr: {
          color: '#00008B',
          name: 'VCR',
          dataLabel: 'percent'
        }
      }
    },
    demographics: {
      active: false,
      changed: false,
      name: 'demographics',
      data: null,
      heading: null,
      type: 'negative-stack-bar',
      categories: ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
      defaultSeries: ['reach'],
      fallbackDefaultSeries: ['impression'],
      seriesOptions: {
        impression: {
          color: '#83bc20',
          name: 'Impressions',
          dataLabel: 'number'
        },
        click: {
          color: '#bd29de',
          name: 'Clicks',
          dataLabel: 'number'
        },
        ctr: {
          color: '#f44242',
          name: 'CTR',
          dataLabel: 'percent'
        },
        reach: {
          color: '#FFA500',
          name: 'Reach',
          dataLabel: 'number'
        },
        visits: {
          color: '#00008B',
          name: 'Visits',
          dataLabel: 'number',
          featureLocked: !features.enableVisits
        },
        vcr: {
          color: '#75f4b0',
          name: 'VCR',
          dataLabel: 'percent'
        }
      }
    }
  };

  function updateCurrentTab() {
    if ($state.current.name === 'campaigns.campaign.settings') {
      $scope.currentTab = $scope.tabs['Settings'];
    } else {
      $scope.currentTab = $scope.tabs['Reporting'];
    }
  }

  // use defaultSeries or fallbackDefaultSeries in a config to set some seriesOptions to visible = true
  function populateDefaultSeries(chartConfig) {
    if (!chartConfig.hasOwnProperty('defaultSeries')) {
      console.error('No defaultSeries found in a chartConfig!');
      return; // no default metrics to populate
    }

    var defaultSeries = chartConfig.defaultSeries;

    // check if the default metrics can be used
    var canUseDefault = true;
    for (var defaultKey of defaultSeries) {
      if (chartConfig.seriesOptions[defaultKey].featureLocked) {
        canUseDefault = false;
        break;
      }
    }

    // select default metrics
    var chosenDefault = defaultSeries;
    if (!canUseDefault) {
      // if default metrics is available, there should be a fallback as well
      chosenDefault = chartConfig.fallbackDefaultSeries;
    }

    chosenDefault.forEach(function(name) {
      chartConfig.seriesOptions[name].visible = true;
    });
  }

  // temporary code to make merge conflict resolution easier
  var summaryChartsConfig = $scope.campaignSummaryConfig.chart;
  var tableConfig = $scope.campaignSummaryConfig.table;

  populateDefaultSeries(audienceChartsConfig.demographics);

  $scope.campaignSummaryConfig = {
    table: tableConfig,
    chart: summaryChartsConfig
  };

  $scope.campaignAudienceConfig = {
    table: tableConfig,
    chart: audienceChartsConfig
  };

  var today = new Date();
  var campaignStartDate = start ? new Date(start) : new Date();
  var firstDay2019 = new Date('January 1, 2019');

  if (campaignStartDate > today) {
    $scope.viewChart = false;
  }

  $scope.dowtodOptions = [
    { value: 'weekday', text: 'Weekday', checked: true, color: '#655cb4' },
    { value: 'weekend', text: 'Weekend', checked: true, color: '#866248' },
    { value: 'monday', text: 'Monday', checked: false, color: '#f46e4d' },
    { value: 'tuesday', text: 'Tuesday', checked: false, color: '#3487bb' },
    { value: 'wednesday', text: 'Wednesday', checked: false, color: '#3e465d' },
    { value: 'thursday', text: 'Thursday', checked: false, color: '#be9266' },
    { value: 'friday', text: 'Friday', checked: false, color: '#4fd540' },
    { value: 'saturday', text: 'Saturday', checked: false, color: '#c45e6d' },
    { value: 'sunday', text: 'Sunday', checked: false, color: '#ad5bb7' }
  ];

  $scope.tableData = [];
  $scope.filteredTableData = [];

  var defaultFilterConfig = {
    status: {
      checked: false,
      rows: [
        { title: 'Active', checked: true },
        { title: 'Pending', checked: true },
        { title: 'Paused', checked: true },
        { title: 'Expired', checked: false }
      ]
    }
  };

  $scope.tableLevels = {
    campaigns: {
      tableData: [campaign], // DEFAULT table data for campaign level (no breakdown).
      value: 'campaigns', // md-select value
      text: 'Campaign' // md-option text shown in UI
    },
    placements: {
      // Default level
      tableData: campaign.adGroups, // DEFAULT table data for placement level (no breakdown).
      value: 'placements',
      text: 'Ad Groups'
    },
    creatives: {
      tableData: getCreativesFromCampaign(campaign), // DEFAULT table data for creative level (no breakdown).
      value: 'creatives',
      text: 'Creatives'
    }
  };

  var defaultTableLevel = $scope.tableLevels.placements.value;

  $scope.selectedDurationRange = {
    start_date: null,
    end_date: null
  };

  $scope.booleans = {
    chartConfigUpdated: false,
    actionInProgress: false,
    tableUpdateProgress: false,
    newPlacementProgress: false,
    tableChanged: false,
    showPlayButton: false,
    showPauseButton: false,
    showViewCreativesButton: true,
    showCloneButton: true,
    showEditButton: true,
    saveProgress: false,
    approveProgress: false,
    rejectProgress: false,
    draft: $scope.campaign.status === 'Draft',
    rejected: $scope.campaign.status === 'Rejected',
    submitted: $scope.campaign.status === 'Submitted'
  };

  var realTimeDate = {
    enabled: false,
    start_date: new Date(),
    end_date: new Date()
  };

  $scope.tableClasses = ['scrollable-table adgroup-table'];
  $scope.companyAndAccountFactory = companyAndAccountFactory;

  $scope.highlightedAdGroup = null; // The adgroup selected in the table

  $scope.summaryCharts = [
    summaryChartsConfig['daily'],
    summaryChartsConfig['visitation'],
    summaryChartsConfig['dow'],
    summaryChartsConfig['tod'],
    summaryChartsConfig['dowtod'],
    summaryChartsConfig['product'],
    summaryChartsConfig['publisher'],
    summaryChartsConfig['network'],
    summaryChartsConfig['conversions']
  ];

  $scope.audienceCharts = [
    audienceChartsConfig['behavioral'],
    audienceChartsConfig['audiencecategory'],
    audienceChartsConfig['brandaffinity'],
    audienceChartsConfig['audienceaffinity'],
    audienceChartsConfig['demographics']
  ];

  $scope.init = function(broadcast) {
    if (
      !$scope.$storage.version ||
      $scope.$storage.version < 1 ||
      !$scope.$storage.currentTenantName ||
      $scope.$storage.currentTenantName !== tenantFactory.tenant.company_name
    ) {
      $scope.$storage.tableFilterConfig = angular.copy(defaultFilterConfig);
      $scope.$storage.currentTableLevel = angular.copy(defaultTableLevel);
      $scope.$storage.currentTenantName = tenantFactory.tenant.company_name;
      $scope.$storage.version = 1;
    }

    if ($scope.campaign.bulk_upload_job_id) {
      $scope.campaignBulkUploadVars.intervalPromise = $interval(
        $scope.checkCampaignBulkUploadProgress,
        PROGRESS_INTERVAL_DELAY
      );
    }

    if ($stateParams.isBulkUpload) {
      $scope.openBulkManageCampaign();
    }

    $scope.errorMsg = '';
    if (broadcast) {
      $scope.$broadcast('adgroup-table-update');
    }
    $scope.booleans.submitted = $scope.campaign.status === 'Submitted';
    $scope.booleans.draft = $scope.campaign.status === 'Draft';
    $scope.booleans.rejected = $scope.campaign.status === 'Rejected';

    $scope.currentReportTab = $scope.reportTabs.summary.value;
    changeReportConfig();
    mapData();

    // Reset audience options as per the enabled flag, if it exists then check it's value otherwise consider it as true
    $scope.campaignSummaryConfig.chart.product.productOptions.audiencelocation = $scope.campaignSummaryConfig.chart.product.productOptions.audiencelocation.filter(
      option => option.enabled ?? true
    );

    if (!$state.params.adGroupId) {
      $scope.campaign_ready = true;
    }

    if (!featureFlagFactory.isFeatureEnabled('LOOKBACK_WINDOW_READ')) {
      $scope.chartReports.splice(1, 1);
    }

    if (!$scope.campaign.adGroups || !$scope.campaign.adGroups.length) {
      $scope.$storage.currentTableLevel = $scope.tableLevels.campaigns.value;
    }
    $scope.campaignSummaryConfig.table.header = generateColumnConfig(
      $scope.getSelectedReportName(),
      $scope.$storage.currentTableLevel
    );

    var account = companyAndAccountFactory.getSelectedAccount();
    account.then(function(data) {
      $scope.booleans.showActionSheet =
        featureFlagFactory.isFeatureEnabled('CAMPAIGN_READ') &&
        companyAndAccountFactory.selectedAccount?.app_used === 'MPCB';
      $scope.currencyCode = data.currency;
      $scope.updateTableData();
    });

    updateStartEndDateForRealTimeData(startDate, endDate);
    $scope.handleConversionsMetrics();
    $scope.changeReport();

    groupCreativesBySize($scope.tableData);

    $scope.macros = {};
    $scope.selectedDurationRange.start_date = campaign.timeframe.start;

    const currentDate = $filter('date')(new Date(), 'yyyy-MM-dd');
    const nextToStartDate = $filter('date')(new Date(startDate).setDate(startDate.getDate() + 1), 'yyyy-MM-dd');
    const defaultEndDate =
      campaign.timeframe.end || (campaign.timeframe.start > currentDate ? nextToStartDate : currentDate);
    $scope.selectedDurationRange.end_date = defaultEndDate;

    staticDataFactory.getMacros(companyAndAccountFactory.selectedAccount).then(function() {
      $scope.macros = staticDataFactory.macroMapTable;
    });
  };

  $scope.changeTab = function(tabName) {
    $scope.$emit('end progress');
    $scope.currentTab = $scope.tabs[tabName];
  };

  function getIdArrayFromObj(obj, id_type) {
    var result = [];
    if (id_type == 'campaign') {
      result.push(obj.id);
    } else if (id_type == 'adgroup') {
      obj.adGroups.forEach(function(item, index) {
        result.push(item.id);
      });
    } else {
      obj.adGroups.forEach(function(item, index) {
        if (Array.isArray(item)) {
          item[0].creatives.forEach(function(creative) {
            result.push(creative.id);
          });
        } else if (item.creatives) {
          item.creatives.forEach(function(creative) {
            result.push(creative.id);
          });
        }
      });
    }
    return result;
  }

  $scope.reportTabs = {
    summary: {
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.performance-report.html',
      text: 'Summary',
      value: 'summary',
      disabled: false,
      reports: {
        daily: {
          value: 'daily',
          text: 'Daily Trend',
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text: 'View a daily breakdown of campaign, ad group, and creative performance.'
          }
        },
        visitation: {
          value: 'visitation',
          text: 'Lookback Window',
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text: 'Insight into the time it takes a user to visit the store after being served an impression.'
          }
        },
        dow: {
          value: 'dow',
          text: 'Day of the Week',
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text:
              'Based on the dates selected, in the chart "day" represents an average of all days whereas in the table "day" represents a sum of each day.'
          }
        },
        tod: {
          value: 'tod',
          text: 'Time of the Day',
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text: 'View a report broken down by time of day.'
          }
        },
        dowtod: {
          value: 'dowtod',
          text: 'Day of the Week + Time of the Day',
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text:
              'View performance by a combination of day of week + time of day. Based on the dates selected, in the chart, each hourly point represents an average, whereas in the table, it represents a sum.'
          }
        },
        brand: {
          value: 'brand',
          text: 'Brand Targeted (Table Only)',
          disabled: featureFlagFactory.isFeatureEnabled('AUDIENCE_TAXONOMY'),
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text: "View which brands are performing the best against KPI's."
          }
        },
        category: {
          value: 'category',
          text: 'Category Targeted (Table Only)',
          disabled: featureFlagFactory.isFeatureEnabled('AUDIENCE_TAXONOMY'),
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text:
              "View which categories are performing the best against KPI's. Note: Visits and reach are not available in the Category Report."
          }
        },
        audience: {
          value: 'audience',
          text: 'Audience Targeted (Table Only)',
          disabled: !featureFlagFactory.isFeatureEnabled('AUDIENCE_TAXONOMY'),
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text:
              "View which audiences are performing the best against KPI's. Note: Visits and reach are not available in the Audience Report."
          }
        },
        os: {
          value: 'os',
          text: 'OS (Table Only)',
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text: "View which OS are performing the best against KPI's."
          }
        },
        device: {
          value: 'device',
          text: 'Device (Table Only)',
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text: "View which devices are performing the best against KPI's."
          }
        },
        product: {
          value: 'product',
          text: 'Reporting by Product',
          enabledOnReportsAfterDate: new Date('January 1, 2019'),
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text: 'View campaign, ad group, and creative performance broken out by targeting strategy.'
          }
        },
        publisher: {
          value: 'publisher',
          text: 'Publisher Reporting (CTV/OTT Only)',
          disabled:
            !featureFlagFactory.isFeatureEnabled('CTV_OTT_PUBLISHER_REPORT') ||
            featureFlagFactory.isFeatureEnabled('CTV_OTT_REPORT_ENHANCEMENT'),
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements],
          tooltip: {
            display: true,
            text: 'View campaign and ad group performance broken out by publisher (CTV/OTT only).'
          }
        },
        conversions: {
          value: 'conversions',
          text: 'Web Conversions',
          disabled: !featureFlagFactory.isFeatureEnabled('WEB_CONVERSIONS_REPORT_READ'),
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements],
          tooltip: {
            display: true,
            text: 'View breakdown of web conversion by pixel and date'
          }
        }
      }
    },
    location: {
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.location-report.html',
      text: 'Location',
      value: 'location',
      // disable location report if any of the following is true
      disabled:
        !featureFlagFactory.isFeatureEnabled('LOCATION_REPORT') || // user does not have the location report feature
        startDate < new Date('2017-11-01') || // campaign starts before Nov 1, 2017
        (tenantFactory.tenant.type == 1 && startDate < new Date('2018-08-01')), // self serve tenant + campaign starts before Aug 1, 2018
      tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements]
    },
    audience: {
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.audience-report.html',
      text: 'Audience',
      value: 'audience',
      disabled: companyAndAccountFactory.selectedAccount?.countryCode === 'DE',
      reports: {
        behavioral: {
          value: 'behavioral',
          text: 'Behavioral',
          disabled: featureFlagFactory.isFeatureEnabled('AUDIENCE_TAXONOMY'),
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text: 'View how your ads performed for the top ten audiences.'
          }
        },
        audiencecategory: {
          value: 'audiencecategory',
          text: 'Category',
          disabled: featureFlagFactory.isFeatureEnabled('AUDIENCE_TAXONOMY'),
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text: 'View how your ads performed for the top ten categories.'
          }
        },
        brandaffinity: {
          value: 'brandaffinity',
          text: 'Brand Affinity',
          disabled: featureFlagFactory.isFeatureEnabled('AUDIENCE_TAXONOMY'),
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text: 'View how your ads performed for the top ten brands of the category you selected.'
          }
        },
        audienceaffinity: {
          value: 'audienceaffinity',
          text: 'GT Audiences',
          disabled: !featureFlagFactory.isFeatureEnabled('AUDIENCE_TAXONOMY'),
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements, $scope.tableLevels.creatives],
          tooltip: {
            display: true,
            text: 'View how your ads performed for the top ten audiences of the category you selected.'
          }
        },
        demographics: {
          value: 'demographics',
          text: 'Demographics',
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements],
          tooltip: {
            display: true,
            text:
              'Get a better understanding of who was exposed to your campaign or ad group, broken down by age and gender.'
          }
        }
      }
    },
    ctv: {
      // using summary tab's data and template for the time being
      url: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.performance-report.html',
      text: 'CTV Inventory',
      value: 'ctv',
      tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements],
      reports: {
        publisher: {
          value: 'publisher',
          text: 'OTT Platform Reporting',
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements],
          tooltip: {
            display: true,
            text: 'View campaign and ad group performance broken out by publisher (CTV/OTT only).'
          }
        },
        network: {
          value: 'network',
          text: 'Network/Channel Reporting',
          tableLevels: [$scope.tableLevels.campaigns, $scope.tableLevels.placements],
          tooltip: {
            display: true,
            text: 'View campaign and ad group performance broken out by network (CTV/OTT only).'
          }
        }
      }
    }
  };

  function getCreativesFromCampaign(campaign) {
    var creatives = [];
    if (campaign == undefined) {
      return creatives;
    }
    if (campaign.hasOwnProperty('adGroups')) {
      campaign.adGroups.forEach(function(item) {
        var placementName = item.name;
        var placementId = item.id;
        if (item.creatives?.length) {
          item.creatives.forEach(function(creative) {
            creative.placementName = placementName;
            creative.placementId = placementId;
            creative.thumbUrl = creative.cmsConfig.bannerUrl;
            creative.timeframe = item.timeframe;
            creatives.push(creative);
          });
        }
      });
    }
    return creatives;
  }

  function highlightCreativeFromCreativeView() {
    // from $scope.tableData get highlightedCreative item
    var item = null;
    for (var i = 0; i < $scope.tableLevels[$scope.$storage.currentTableLevel].tableData.length; i++) {
      item = $scope.tableLevels[$scope.$storage.currentTableLevel].tableData[i];
      if (item.creative_id == highlightedCreative) {
        break;
      }
    }
    highLightRowSwitch(item);
    if ($scope.highlightedAdGroup) {
      actionButtonManagement();
      groupCreativesBySize($scope.highlightedAdGroup);
    } else {
      groupCreativesBySize($scope.tableLevels[$scope.$storage.currentTableLevel].tableData);
    }
  }

  function highLightRowSwitch(item) {
    // Toggle highilght
    if (Array.isArray(item)) {
      // Clicked on a breakdown grouping
      $scope.highlightedAdGroup = item[0];
    } else {
      // Clicked on a table row with no breakdown applied
      $scope.highlightedAdGroup = item;
    }
  }

  $scope.summaryChartMetrics = [];
  $scope.audienceChartMetrics = [];

  $scope.changeReport = async function changeReport() {
    if ($scope.currentReportTab === $scope.reportTabs.location.value) {
      return;
    }

    var chartVal = $scope.selectedReport.value;

    $scope.selectedCategory = null;
    if ($scope.currentReportTab === $scope.reportTabs.summary.value) {
      $scope.summaryChartMetrics = [];
    } else if ($scope.currentReportTab === $scope.reportTabs.audience.value) {
      $scope.audienceChartMetrics = [];
    }

    changeTableLevelsOrder();

    if ($scope.selectedReport.value === 'daily') {
      if ($scope.$storage.currentTableLevel === 'campaigns') {
        if ($scope.isPosConnected === undefined) {
          await $scope.handleSalesMetric();
        }
        if ($scope.isPosConnected) {
          $scope.campaignSummaryConfig.chart[$scope.selectedReport.value].seriesOptions['total_sales'] = {
            color: '#00008C',
            name: 'Sales',
            dataLabel: 'number',
            featureLocked: !features.enableCheckGrowth
          };
        }
      } else if ($scope.campaignSummaryConfig.chart[$scope.selectedReport.value]?.seriesOptions) {
        delete $scope.campaignSummaryConfig.chart[$scope.selectedReport.value].seriesOptions['total_sales'];
      }
    }

    let tabConfig = $scope.campaignSummaryConfig.chart;

    if ($scope.currentReportTab === $scope.reportTabs.audience.value) {
      tabConfig = $scope.campaignAudienceConfig.chart;
    }

    for (var key in tabConfig) {
      if (tabConfig.hasOwnProperty(key)) {
        var chartConfig = tabConfig[key];

        if (key === chartVal) {
          var seriesOptions = chartConfig.seriesOptions;
          for (var optionsKey in seriesOptions) {
            if (seriesOptions.hasOwnProperty(optionsKey)) {
              var option = seriesOptions[optionsKey];
              option.visible = false;

              if (!option.featureLocked) {
                if ($scope.currentReportTab === $scope.reportTabs.summary.value) {
                  $scope.summaryChartMetrics.push({
                    value: optionsKey,
                    displayName: option.name
                  });
                } else if ($scope.currentReportTab === $scope.reportTabs.audience.value) {
                  $scope.audienceChartMetrics.push({
                    value: optionsKey,
                    displayName: option.name
                  });
                }
              }
            }
          }

          if (chartConfig.defaultSeries) {
            populateDefaultSeries(chartConfig);
          }

          chartConfig.active = true;
        } else {
          chartConfig.active = false;
        }
      }
    }

    $scope.resetTable();
    $scope.updateChartData();
    $scope.booleans.tableChanged = true; // Force configuration settings to update.
  };

  $scope.expandTableRowBreakdown = function(index) {
    var type = $scope.$storage.currentTableLevel;
    var id = $scope.tableLevels[$scope.$storage.currentTableLevel].tableData[index].id;

    $scope.expandedRow = {
      type: $scope.$storage.currentTableLevel,
      index: index,
      beforeData: angular.copy($scope.tableLevels[$scope.$storage.currentTableLevel].tableData[index])
    };

    if ($scope.booleans.actionInProgress || $scope.currentReportTab === $scope.reportTabs.location.value) {
      return;
    }
    $scope.booleans.actionInProgress = true;
    if ($scope.currentReportTab === $scope.reportTabs.summary.value) {
      if ($scope.selectedReport.value === 'daily') {
        $scope
          .catchErrors(reportFactory.getDailyTableData(type, id, $scope.selectedDurationRange, 180))
          .then(function(data) {
            if (data === null) {
              return;
            }
            applyBreakdownToData(data);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'visitation') {
        $scope
          .catchErrors(reportFactory.getVisitationChartData(type, id, $scope.selectedDurationRange))
          .then(function(data) {
            if (data === null) {
              return;
            }
            const tableData = data
              ? data.map(([lookback_window_period, visits]) => {
                  return {
                    lookback_window_period,
                    visits
                  };
                })
              : [{ lookback_window_period: null, visits: null }];

            applyBreakdownToData(tableData);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'dow') {
        $scope
          .catchErrors(reportFactory.getDOWTableData(type, id, $scope.selectedDurationRange))
          .then(function(data) {
            if (data === null) {
              return;
            }

            applyBreakdownToData(data);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'tod') {
        $scope
          .catchErrors(reportFactory.getTODTableData(type, id, $scope.selectedDurationRange))
          .then(function(data) {
            if (data === null) {
              return;
            }

            applyBreakdownToData(data);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'dowtod') {
        $scope
          .catchErrors(reportFactory.getDOWTODTableData(type, id, $scope.selectedDurationRange))
          .then(function(data) {
            if (data === null) {
              return;
            }

            var rowsToAdd = [];
            for (var i = 0; i < data.length; i++) {
              // add day
              rowsToAdd.push(data[i]);
              // add hours
              rowsToAdd = rowsToAdd.concat(data[i].hours);
            }
            applyBreakdownToData(rowsToAdd);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'brand') {
        $scope
          .catchErrors(reportFactory.getBrandBreakdownTableData(campaignId, $scope.selectedDurationRange, type, id))
          .then(function(data) {
            if (data === null) {
              return;
            }

            var rowsToAdd = [];
            for (var brand in data[0]) {
              if (data[0].hasOwnProperty(brand)) {
                var row = data[0][brand];
                row.brand = brand;
                rowsToAdd.push(row);
              }
            }
            applyBreakdownToData(rowsToAdd);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'category') {
        $scope
          .catchErrors(reportFactory.getCategoryBreakdownTableData(campaignId, $scope.selectedDurationRange, type, id))
          .then(function(data) {
            if (data === null) {
              return;
            }

            var rowsToAdd = [];
            for (var category in data[0]) {
              if (data[0].hasOwnProperty(category)) {
                var row = data[0][category];
                row.category = category;
                rowsToAdd.push(row);
              }
            }
            applyBreakdownToData(rowsToAdd);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'audience') {
        $scope
          .catchErrors(reportFactory.getAudienceBreakdownTableData(campaignId, $scope.selectedDurationRange, type, id))
          .then(function(data) {
            if (!data) {
              return;
            }

            let rowsToAdd = [];
            for (let audience in data[0]) {
              if (data[0].hasOwnProperty(audience)) {
                let row = data[0][audience];
                row.audience = audience;
                rowsToAdd.push(row);
              }
            }
            applyBreakdownToData(rowsToAdd);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'os') {
        $scope
          .catchErrors(reportFactory.getOSBreakdownTableData(campaignId, $scope.selectedDurationRange, type, id))
          .then(function(data) {
            if (data === null) {
              return;
            }

            var rowsToAdd = [];
            for (var os in data[0]) {
              if (data[0].hasOwnProperty(os)) {
                var row = data[0][os];
                row.os = os;
                rowsToAdd.push(row);
              }
            }
            applyBreakdownToData(rowsToAdd);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'device') {
        $scope
          .catchErrors(reportFactory.getDeviceBreakdownTableData(campaignId, $scope.selectedDurationRange, type, id))
          .then(function(data) {
            if (data === null) {
              return;
            }

            var rowsToAdd = [];
            for (var device in data[0]) {
              if (data[0].hasOwnProperty(device)) {
                var row = data[0][device];
                row.device = device;
                rowsToAdd.push(row);
              }
            }
            applyBreakdownToData(rowsToAdd);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'product') {
        $scope
          .catchErrors(reportFactory.getProductTableData(type, id, $scope.selectedDurationRange))
          .then(function(data) {
            if (data === null) {
              return;
            }

            var targeted_product, constraint;
            var rowsToAdd = [];

            for (var product in data) {
              var row = data[product];
              constraint = data[product].constraint ? ' | ' + data[product].constraint : '';
              targeted_product = data[product].product_group + ' | ' + data[product].product + constraint;
              row.targeted_product = targeted_product;
              rowsToAdd.push(row);
            }

            applyBreakdownToData(rowsToAdd);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'publisher') {
        $scope
          .catchErrors(reportFactory.getPublisherBreakdownTableData(type, id, $scope.selectedDurationRange))
          .then(function(data) {
            if (data === null) {
              return;
            }

            applyBreakdownToData(data);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'conversions') {
        $scope
          .catchErrors(reportFactory.getConversionsTableData(type, id, $scope.selectedDurationRange))
          .then(function(data) {
            if (!data) {
              return;
            }

            applyBreakdownToData(data);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      }
    } else if ($scope.currentReportTab === $scope.reportTabs.audience.value) {
      if ($scope.selectedReport.value === 'demographics') {
        $scope
          .catchErrors(
            reportFactory.getAgeAndGenderBreakdownTableData(campaignId, $scope.selectedDurationRange, type, id)
          )
          .then(function(data) {
            if (data === null) {
              return;
            }

            var rowsToAdd = [];
            for (var gender in data[0]) {
              for (var age in data[0][gender]) {
                var row = null;
                row = data[0][gender][age];
                row.gender = gender;
                row.age = age;
                rowsToAdd.push(row);
              }
            }
            applyBreakdownToData(rowsToAdd);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'brandaffinity') {
        getBrandAffinityTableData(index);
      } else if ($scope.selectedReport.value === 'audienceaffinity') {
        getAudienceAffinityTableData(index); // handling audience affinity the same way as brand affinity
      } else if ($scope.selectedReport.value === 'audiencecategory') {
        $scope
          .catchErrors(reportFactory.getCategoryTableData(type, id, $scope.selectedDurationRange))
          .then(function(data) {
            if (data === null) {
              return;
            }

            applyBreakdownToData(data);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      } else if ($scope.selectedReport.value === 'behavioral') {
        $scope
          .catchErrors(reportFactory.getBehavioralAudienceTableData(type, id, $scope.selectedDurationRange))
          .then(function(data) {
            if (data === null) {
              return;
            }

            applyBreakdownToData(data);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      }
    } else if ($scope.currentReportTab === $scope.reportTabs.ctv.value) {
      if ($scope.selectedReport.value === 'network') {
        // For networks just adding the required column until the api is integrated
        addColumnsOnBreakdown();
        $scope.booleans.actionInProgress = false;
      } else if ($scope.selectedReport.value === 'publisher') {
        $scope
          .catchErrors(reportFactory.getPublisherBreakdownTableData(type, id, $scope.selectedDurationRange))
          .then(function(data) {
            if (data === null) {
              return;
            }

            applyBreakdownToData(data);
            addColumnsOnBreakdown();
          })
          .finally(function() {
            $scope.booleans.actionInProgress = false;
          });
      }
    }
  };

  function getBrandAffinityTableData(index) {
    var type = $scope.$storage.currentTableLevel;
    var id = Array.isArray($scope.tableLevels[type].tableData[index])
      ? $scope.tableLevels[type].tableData[index][0].id
      : $scope.tableLevels[type].tableData[index].id;

    $scope.expandedRow = {
      type: $scope.$storage.currentTableLevel,
      index: index,
      beforeData: angular.copy($scope.tableLevels[$scope.$storage.currentTableLevel].tableData[index])
    };

    $scope.booleans.actionInProgress = true;
    $scope
      .catchErrors(
        reportFactory.getBrandAffinityTableData(type, id, $scope.selectedDurationRange, $scope.selectedCategory)
      )
      .then(function(data) {
        if (data === null) {
          return;
        }

        applyBreakdownToData(data);
        addColumnsOnBreakdown();
      })
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  }

  function getAudienceAffinityTableData(index) {
    let type = $scope.$storage.currentTableLevel;
    let id = Array.isArray($scope.tableLevels[type].tableData[index])
      ? $scope.tableLevels[type].tableData[index][0].id
      : $scope.tableLevels[type].tableData[index].id;
    let config = audienceChartsConfig[$scope.selectedReport.value].seriesOptions;
    let selectedMetrics = $scope.retrieveSelectedMetrics(config);

    $scope.expandedRow = {
      type: $scope.$storage.currentTableLevel,
      index: index,
      beforeData: angular.copy($scope.tableLevels[$scope.$storage.currentTableLevel].tableData[index])
    };

    $scope.booleans.actionInProgress = true;
    $scope
      .catchErrors(
        reportFactory.getAudienceAffinityTableData(
          type,
          id,
          $scope.selectedDurationRange,
          selectedMetrics,
          $scope.selectedCategory
        )
      )
      .then(function(data) {
        if (!data) {
          return;
        }

        applyBreakdownToData(data);
        addColumnsOnBreakdown();
      })
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  }

  function addColumnsOnBreakdown() {
    // reset table header so we can add new column
    $scope.campaignSummaryConfig.table.header = generateColumnConfig(
      $scope.getSelectedReportName(),
      $scope.$storage.currentTableLevel
    );

    var rowData = $scope.tableLevels[$scope.$storage.currentTableLevel].tableData[$scope.expandedRow.index][0];
    var indexToAddColumn = 0;

    // add table columns
    if ($scope.currentReportTab === $scope.reportTabs.summary.value) {
      if ($scope.selectedReport.value === 'daily') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'Date',
          hide: false,
          field: 'date',
          hideInBreakdown: false,
          components: [{ field: 'date' }],
          type: 'details'
        });

        rowData.date = rowData.timeframe ? rowData.timeframe.start + ' - ' + rowData.timeframe.end : null;
      } else if ($scope.selectedReport.value === 'visitation') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'Lookback Window',
          hide: false,
          field: 'lookback_window_period',
          hideInBreakdown: false,
          components: [{ field: 'lookback_window_period' }],
          type: 'details'
        });

        rowData.lookback_window_period = 'Lookback Window Period';
      } else if ($scope.selectedReport.value === 'dow') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'Day',
          hide: false,
          field: 'day',
          hideInBreakdown: false,
          components: [{ field: 'day' }],
          type: 'details'
        });

        rowData.day = 'All Days';
      } else if ($scope.selectedReport.value === 'tod') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'Hour',
          hide: false,
          field: 'hour',
          hideInBreakdown: false,
          components: [{ field: 'hour' }],
          type: 'details'
        });

        rowData.hour = 'All Hours';
      } else if ($scope.selectedReport.value === 'dowtod') {
        $scope.campaignSummaryConfig.table.header.splice(
          indexToAddColumn,
          0,
          {
            name: 'Day',
            hide: false,
            field: 'day',
            hideInBreakdown: false,
            components: [{ field: 'day' }],
            type: 'details'
          },
          {
            name: 'Hour',
            hide: false,
            field: 'hour',
            hideInBreakdown: false,
            components: [{ field: 'hour' }],
            type: 'details'
          }
        );

        rowData.day = 'All Days';
        rowData.hour = 'All Hours';
      } else if ($scope.selectedReport.value === 'brand') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'Brand',
          hide: false,
          field: 'brand',
          hideInBreakdown: false,
          components: [{ field: 'brand' }],
          type: 'details'
        });
      } else if ($scope.selectedReport.value === 'category') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'Category',
          hide: false,
          field: 'category',
          hideInBreakdown: false,
          components: [{ field: 'category' }],
          type: 'details'
        });
      } else if ($scope.selectedReport.value === 'audience') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'Audience',
          hide: false,
          field: 'audience',
          hideInBreakdown: false,
          components: [{ field: 'audience' }],
          type: 'details'
        });
      } else if ($scope.selectedReport.value === 'os') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'OS',
          hide: false,
          field: 'os',
          hideInBreakdown: false,
          components: [{ field: 'os' }],
          type: 'details'
        });
      } else if ($scope.selectedReport.value === 'device') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'Device',
          hide: false,
          field: 'device',
          hideInBreakdown: false,
          components: [{ field: 'device' }],
          type: 'details'
        });
      } else if ($scope.selectedReport.value === 'product') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'Targeted Product',
          hide: false,
          field: 'targeted_product',
          hideInBreakdown: false,
          components: [{ field: 'targeted_product' }],
          type: 'details'
        });
      } else if ($scope.selectedReport.value === 'publisher') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn + 1, 0, {
          name: 'Publisher',
          hide: false,
          field: 'publisher',
          hideInBreakdown: false,
          components: [{ field: 'publisher' }],
          type: 'details'
        });
        $scope.campaignSummaryConfig.table.header.push({
          name: 'VCR',
          hide: false,
          field: 'vcr',
          hideInBreakdown: false,
          components: [{ field: 'vcr', filter: 'percentage' }],
          type: 'details'
        });
      } else if ($scope.selectedReport.value === 'conversions') {
        $scope.campaignSummaryConfig.table.header.splice(2, 0, {
          name: 'Pixel Name',
          hide: false,
          field: 'pixel_name',
          hideInBreakdown: false,
          components: [{ field: 'pixel_name' }],
          type: 'details'
        });
        $scope.campaignSummaryConfig.table.header.splice(2, 0, {
          name: 'Pixel Id',
          hide: false,
          field: 'pixel_id',
          hideInBreakdown: false,
          components: [{ field: 'pixel_id' }],
          type: 'details'
        });
      }
    } else if ($scope.currentReportTab === $scope.reportTabs.audience.value) {
      if ($scope.selectedReport.value === 'demographics') {
        $scope.campaignSummaryConfig.table.header.splice(
          indexToAddColumn,
          0,
          {
            name: 'Gender',
            hide: false,
            field: 'gender',
            hideInBreakdown: false,
            components: [{ field: 'gender' }],
            type: 'details'
          },
          {
            name: 'Age',
            hide: false,
            field: 'age',
            hideInBreakdown: false,
            components: [{ field: 'age' }],
            type: 'details'
          }
        );

        rowData.gender = 'All Genders';
        rowData.age = 'All Ages';
      } else if ($scope.selectedReport.value === 'brandaffinity') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'Brand',
          hide: false,
          field: 'brand_name',
          hideInBreakdown: false,
          components: [{ field: 'brand_name' }],
          type: 'details'
        });

        rowData.brand_name = 'All Brands';
      } else if ($scope.selectedReport.value === 'audiencecategory') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'Category',
          hide: false,
          field: 'category_name',
          hideInBreakdown: false,
          components: [{ field: 'category_name' }],
          type: 'details'
        });

        rowData.category_name = 'All Categories';
      } else if ($scope.selectedReport.value === 'behavioral') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'Audience',
          hide: false,
          field: 'audience_name',
          hideInBreakdown: false,
          components: [{ field: 'audience_name' }],
          type: 'details'
        });

        rowData.audience_name = 'All Audiences';
      } else if ($scope.selectedReport.value === 'audienceaffinity') {
        $scope.campaignSummaryConfig.table.header.splice(indexToAddColumn, 0, {
          name: 'GT Audience',
          hide: false,
          field: 'taxonomy_audience_name',
          hideInBreakdown: false,
          components: [{ field: 'taxonomy_audience_name' }], // to populate GT Audience column
          type: 'details'
        });

        rowData.audience_name = 'All Taxonomy Audiences';
      }
    } else if ($scope.currentReportTab === $scope.reportTabs.ctv.value) {
      if ($scope.selectedReport.value === 'network') {
        $scope.campaignSummaryConfig.table.header.splice(2, 0, {
          name: 'Network/Channel Name',
          hide: false,
          field: 'network_name',
          hideInBreakdown: false,
          components: [{ field: 'network_name' }],
          type: 'details'
        });
      }
      if ($scope.selectedReport.value === 'publisher') {
        $scope.campaignSummaryConfig.table.header.splice(2, 0, {
          name: 'OTT Platform Name',
          hide: false,
          field: 'publisher_name',
          hideInBreakdown: false,
          components: [{ field: 'publisher_name' }],
          type: 'details'
        });
      }
    }
  }

  function applyBreakdownToData(data) {
    if (data === null) {
      return;
    }

    // add flag to breakdown rows
    var i;

    // Old reporting routes multiply CTR by 100, while new ones don't
    var ctrNeedsAdjustment;

    if ($scope.currentReportTab === $scope.reportTabs.audience.value) {
      if ($scope.selectedReport.value === 'demographics') {
        ctrNeedsAdjustment = false;
      } else {
        ctrNeedsAdjustment = true;
      }
    } else if ($scope.currentReportTab === $scope.reportTabs.summary.value) {
      if (
        $scope.selectedReport.value === 'os' ||
        $scope.selectedReport.value === 'device' ||
        $scope.selectedReport.value === 'brand' ||
        $scope.selectedReport.value === 'category' ||
        $scope.selectedReport.value === 'audience'
      ) {
        ctrNeedsAdjustment = false;
      } else {
        ctrNeedsAdjustment = true;
      }
    }

    for (i = 0; i < data.length; i++) {
      data[i].isBreakdownRow = true;
      if (angular.isNumber(data[i].hour)) {
        data[i].hour = $filter('parseHour')(data[i].hour);
      }
      if (data[i].day) {
        data[i].day = data[i].day.charAt(0).toUpperCase() + data[i].day.slice(1);
      }
      if (data[i].ctr && ctrNeedsAdjustment) {
        data[i].ctr *= 100;
      }
    }

    // add breakdown rows and update table
    $scope.tableLevels[$scope.$storage.currentTableLevel].tableData[$scope.expandedRow.index] = [
      $scope.tableLevels[$scope.$storage.currentTableLevel].tableData[$scope.expandedRow.index]
    ].concat(data);
    formatCurrencyData($scope.tableLevels[$scope.$storage.currentTableLevel].tableData);
    $scope.filterTable();
  }

  // this is here because location doesn't count as a report
  $scope.getSelectedReportName = function() {
    if ($scope.currentReportTab === $scope.reportTabs.location.value) {
      return 'location';
    } else if ($scope.selectedReport) {
      return $scope.selectedReport.value;
    } else {
      return null;
    }
  };

  // NOTE: this used to be collapseTableRowBreakdown
  // it has since been repurposed for a more general usage
  $scope.resetTable = function() {
    // collapse any expanded table rows
    if ($scope.expandedRow.index !== null) {
      $scope.tableLevels[$scope.expandedRow.type].tableData[$scope.expandedRow.index] = $scope.expandedRow.beforeData;
      $scope.expandedRow = angular.copy(defaultExpandedRow);
      $scope.filterTable();
    }

    // reload columns
    $scope.campaignSummaryConfig.table.header = generateColumnConfig(
      $scope.getSelectedReportName(),
      $scope.$storage.currentTableLevel
    );
  };

  $scope.toggleTableRowBreakdown = function(id) {
    var tableDataIndex = getTableDataIndexByFilteredId(id);
    if (angular.isNumber($scope.expandedRow.index) && $scope.expandedRow.index != tableDataIndex) {
      $scope.resetTable();
      $scope.expandTableRowBreakdown(tableDataIndex);
    } else if (angular.isNumber($scope.expandedRow.index) || $scope.expandedRow.index === tableDataIndex) {
      $scope.resetTable();
    } else {
      $scope.expandTableRowBreakdown(tableDataIndex);
    }
  };

  $scope.toggleViewChart = function() {
    $scope.viewChart = !$scope.viewChart;

    if ($scope.viewChart) {
      if ($scope.currentReportTab === $scope.reportTabs.summary.value) {
        $scope.campaignSummaryConfig.chart[$scope.selectedReport.value].changed = true;
      } else {
        $scope.campaignAudienceConfig.chart[$scope.selectedReport.value].changed = true;
      }
    }
  };

  function changeReportConfig() {
    var key;
    var reports = angular.copy($scope.reportTabs[$scope.currentReportTab].reports);
    var result = [];
    for (key in reports) {
      let reportConfig = reports[key];
      let isReportAvailableForCampaign = reportConfig.enabledOnReportsAfterDate
        ? reportConfig.enabledOnReportsAfterDate < campaignStartDate
        : true;
      if (!reportConfig.disabled && isReportAvailableForCampaign) {
        result.push(reportConfig);
      }
    }
    $scope.chartReports = result;
    if ($scope.campaign.is_cpv) {
      reportGroups.reportGroupDailyTrend.campaigns.availableColumns.cpvBidRate.defaultShow = true;
      reportGroups.reportGroupDailyTrend.campaigns.availableColumns.cpmBidRate.defaultShow = false;
      reportGroups.reportGroupDailyTrend.placements.availableColumns.cpvBidRate.defaultShow = true;
      reportGroups.reportGroupDailyTrend.placements.availableColumns.cpmBidRate.defaultShow = false;
    } else {
      reportGroups.reportGroupDailyTrend.campaigns.availableColumns.cpmBidRate.defaultShow = true;
      reportGroups.reportGroupDailyTrend.campaigns.availableColumns.cpvBidRate.defaultShow = false;
      reportGroups.reportGroupDailyTrend.placements.availableColumns.cpmBidRate.defaultShow = true;
      reportGroups.reportGroupDailyTrend.placements.availableColumns.cpvBidRate.defaultShow = false;
    }

    $scope.selectedReport = angular.copy($scope.chartReports[0]);

    changeTableLevelsOrder();
  }

  // Mapping the the bid rate date to cpv or cmp data dependeing on the type of campaign we have.
  // This method can be used in future for other mapping other attributes
  function mapData() {
    if ($scope.campaign.is_cpv) {
      $scope.campaign.adGroups.map(function(adGroup) {
        adGroup.cpvBidRate = adGroup.bidRate;
        adGroup.cpmBidRate = null;
        return adGroup;
      });
    } else {
      $scope.campaign.adGroups.map(function(adGroup) {
        adGroup.cpmBidRate = adGroup.bidRate;
        adGroup.cpvBidRate = null;
        return adGroup;
      });
    }
  }

  function changeTableLevelsOrder() {
    $scope.tableLevelsOrder = $scope.reportTabs[$scope.currentReportTab].hasOwnProperty('reports')
      ? angular.copy($scope.reportTabs[$scope.currentReportTab].reports[$scope.selectedReport.value].tableLevels)
      : angular.copy($scope.reportTabs[$scope.currentReportTab].tableLevels);

    if (
      (($scope.currentReportTab === $scope.reportTabs.audience.value &&
        $scope.selectedReport.value === 'demographics') ||
        $scope.currentReportTab === $scope.reportTabs.location.value) &&
      $scope.$storage.currentTableLevel === $scope.tableLevels.creatives.value
    ) {
      $scope.$storage.currentTableLevel = angular.copy(defaultTableLevel);
      $scope.changeTableLevel();
    } else if (
      $scope.currentReportTab === $scope.reportTabs.summary.value &&
      ['publisher', 'conversions'].includes($scope.selectedReport.value) &&
      $scope.$storage.currentTableLevel === $scope.tableLevels.creatives.value
    ) {
      $scope.$storage.currentTableLevel = angular.copy(defaultTableLevel);
      $scope.changeTableLevel();
    } else if ($scope.currentReportTab === $scope.reportTabs.ctv.value) {
      $scope.$storage.currentTableLevel = angular.copy($scope.tableLevels.campaigns.value);
    }
  }

  $scope.changeReportTab = function(tabName) {
    if ($scope.reportTabs[tabName].disabled) {
      return;
    }

    $scope.$emit('currentReportTab', tabName);
    $scope.currentReportTab = tabName;

    changeReportConfig();

    $scope.resetTable();

    $scope.changeReport();

    $scope.updateTableData();
  };

  $scope.disableToggleViewChart = function disableToggleViewChart() {
    var reports = ['brand', 'category', 'os', 'device', 'audience'];
    var target;

    if ($scope.currentReportTab === $scope.reportTabs.summary.value) {
      target = $scope.selectedReport.value;
      return reports.indexOf(target) !== -1;
    }

    return $scope.currentReportTab === $scope.reportTabs.location.value;
  };

  $scope.downloadTableData = function() {
    $scope.$broadcast('downloadTableData');
  };

  $scope.exportData = function() {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.exportData.html',
      controller: 'exportDataController',
      size: 'xl'
    };

    var data = {
      campaign: campaign,
      adgroup:
        $scope.$storage.currentTableLevel == $scope.tableLevels.placements.value ? $scope.highlightedAdGroup : null,
      tableLevel: $scope.$storage.currentTableLevel,
      reportName: ($scope.selectedReport && $scope.selectedReport.value) || null,
      selectedDurationRange: $scope.selectedDurationRange,
      selectedCategory: $scope.selectedCategory
    };

    var handlers = {
      downloadTableDataFn: $scope.downloadTableData
    };

    modalFactory.createModal(modalOptions, data, handlers);
  };

  $scope.changeStockChartUnit = function() {
    $scope.campaignSummaryConfig.chart.daily.unit_switch = $scope.selectedStockUnitOption.value;
  };

  // In the case of campaigns and placements (adgroups), this function adds conversions to the summaryChartsConfig
  // and removes it when creatives is selected.
  $scope.handleConversionsMetrics = function() {
    if ($scope.$storage.currentTableLevel === 'campaigns' || $scope.$storage.currentTableLevel === 'placements') {
      // returns if conversions is already present in the summaryChartsConfig.
      if (summaryChartsConfig[$scope.selectedReport.value].seriesOptions.hasOwnProperty('conversions')) {
        return;
      }
      summaryChartsConfig[$scope.selectedReport.value].seriesOptions['conversions'] = {
        color: '#00008B',
        name: 'Conversions',
        dataLabel: 'number',
        featureLocked: !features.enableConversions
      };
    } else {
      delete summaryChartsConfig[$scope.selectedReport.value].seriesOptions['conversions'];
    }
  };

  $scope.checkPosStatus = async function() {
    try {
      const response = await $http({
        method: 'GET',
        url: `/data/check-growth/connections/${$scope.companyAndAccountFactory.selectedAccount.id}`
      });
      if (response.status === 200) {
        const connections = response.data;
        return connections.some(connection => connection.isConnected);
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  // In the case of campaigns, this function adds sales to the summaryChartsConfig and
  // removes it when placements (adgroup) or creatives is selected.
  $scope.handleSalesMetric = async function() {
    $scope.chartLoading = true;
    $scope.isPosConnected = await $scope.checkPosStatus();
    $scope.chartLoading = false;
    $scope.$apply();
  };

  $scope.addToSummaryChartMetrics = function(setDefault) {
    $scope.summaryChartMetrics = [];
    let chartConfig = $scope.campaignSummaryConfig.chart[$scope.selectedReport.value];
    let seriesOptions = chartConfig.seriesOptions;

    for (let optionsKey in seriesOptions) {
      if (seriesOptions.hasOwnProperty(optionsKey)) {
        let option = seriesOptions[optionsKey];
        if (setDefault) {
          option.visible = false;
        }

        if (!option.featureLocked) {
          $scope.summaryChartMetrics.push({
            value: optionsKey,
            displayName: option.name
          });
        }
      }
    }
    if (chartConfig.defaultSeries && setDefault) {
      populateDefaultSeries(chartConfig);
    }
    chartConfig.active = true;
  };

  $scope.isCtvCampaign = function() {
    const campaign = campaignManagerFactory.selectedCampaign;
    if (campaign?.adGroups?.length) {
      for (const adgroup of campaign.adGroups) {
        if (adgroup.targeting?.deviceTypes?.some(deviceType => deviceType === 'CTV' || deviceType === 'OTT')) {
          return true;
        }
      }
    }
    return false;
  };

  $scope.getFilteredSelectedMetrics = function(selectedMetrics, tableLevel) {
    let filteredMetrics = selectedMetrics;

    if (tableLevel === 'placements') {
      filteredMetrics = filteredMetrics.filter(metric => metric !== 'total_sales');
    } else if (tableLevel === 'creatives') {
      filteredMetrics = filteredMetrics
        .filter(metric => metric !== 'total_sales')
        .filter(metric => metric !== 'conversions');
    }

    return filteredMetrics;
  };

  $scope.changeTableLevel = async function() {
    $scope.shouldPixelOptionDefault = true;

    if ($scope.currentReportTab === $scope.reportTabs.summary.value && $scope.selectedReport.value === 'daily') {
      let setDefault = false;
      let selectedReport = $scope.selectedReport.value;
      let seriesOptions = $scope.campaignSummaryConfig.chart[selectedReport]?.seriesOptions;
      let selectedMetrics = $scope.retrieveSelectedMetrics(seriesOptions);

      $scope.handleConversionsMetrics();

      if ($scope.$storage.currentTableLevel === 'campaigns') {
        if ($scope.isPosConnected === undefined) {
          await $scope.handleSalesMetric();
        }
        if ($scope.isPosConnected) {
          $scope.campaignSummaryConfig.chart[$scope.selectedReport.value].seriesOptions['total_sales'] = {
            color: '#00008C',
            name: 'Sales',
            dataLabel: 'number',
            featureLocked: !features.enableCheckGrowth
          };
        }
      } else if (seriesOptions) {
        if ($scope.$storage.currentTableLevel === 'placements' || $scope.$storage.currentTableLevel === 'creatives') {
          if (
            (Object.prototype.hasOwnProperty.call(seriesOptions, 'total_sales') &&
              selectedMetrics.includes('total_sales')) ||
            (selectedMetrics.includes('conversions') && $scope.$storage.currentTableLevel === 'creatives')
          ) {
            let filteredSelectedMetrics = $scope.getFilteredSelectedMetrics(
              selectedMetrics,
              $scope.$storage.currentTableLevel
            );
            // selectedMetrics should include two metrics from the left and right dropdowns.
            // if either is missing and the metric is not available for selected table level, set selectedMetrics to defaultSeries
            if (filteredSelectedMetrics.length < 2) {
              setDefault = true;
            }
          }
          delete seriesOptions['total_sales'];
        }
      }
      $scope.addToSummaryChartMetrics(setDefault);
    }

    $scope.campaignSummaryConfig.table.header = generateColumnConfig(
      $scope.getSelectedReportName(),
      $scope.$storage.currentTableLevel
    );

    var highlightedRowChanged = $scope.highlightedAdGroup != null;

    $scope.resetTable();

    // update chart data should after updatetabledata
    $scope.updateTableData();
    $scope.updateChartData();

    if (highlightedRowChanged) {
      $scope.updateChartData();
    }

    // When change table level, we reset highlightedAdGroup, get all creatives bannerUrl of this campaign
    groupCreativesBySize($scope.adGroup);
  };

  function getStatusCSSClass(status) {
    const rowStatus = status.toLowerCase();
    if (['active', 'pending', 'paused', 'expired'].includes(rowStatus)) {
      return `status ${rowStatus}-status`;
    }
    return '';
  }

  $scope.dateRangeChanged = function() {
    // Broadcast for Location Report controller
    $scope.$broadcast('daterange.changed');
    // Collapse any breakdowns open first
    $scope.resetTable();

    // Update both table and chart data
    $scope.updateChartData();
    $scope.updateTableData();
  };

  $scope.updateTableData = function() {
    $scope.highlightedAdGroup = null;
    $scope.booleans.actionInProgress = true;
    if ($scope.$storage.currentTableLevel === 'campaigns') {
      $scope
        .catchErrors(
          reportFactory.getCampaignTableData(
            campaign.id,
            $scope.selectedDurationRange,
            getIdArrayFromObj(campaign, 'campaign')
          )
        )
        .then(
          function(data) {
            mergeData($scope.tableLevels.campaigns.tableData, data);
            formatDates($scope.tableLevels.campaigns.tableData);
            formatCurrencyData($scope.tableLevels.campaigns.tableData);
            $scope.filterTable();
          },
          function(data) {
            $scope.errorMsg = errorMsg;
          }
        )
        .finally(function() {
          $scope.booleans.actionInProgress = false;
        });
    } else if ($scope.$storage.currentTableLevel === 'placements') {
      $scope
        .catchErrors(
          reportFactory.getAdGroupTableData(
            campaign.id,
            $scope.selectedDurationRange,
            getIdArrayFromObj(campaign, 'adgroup')
          )
        )
        .then(
          function(data) {
            mergeData($scope.tableLevels.placements.tableData, data);
            formatDates($scope.tableLevels.placements.tableData);

            $scope.tableLevels.placements.tableData.map(function(adGroup) {
              // Add creative thumbnail url to ad group object
              if (adGroup.creatives && adGroup.creatives.length) {
                adGroup.thumbUrl = adGroup.creatives[0].cmsConfig.bannerUrl;
              } else {
                adGroup.thumbUrl = null;
              }
              // Add cpvBidRate or cpmBidRate to ad group object
              if (adGroup.bidType === 'CPV') {
                adGroup.cpvBidRate = adGroup.bidRate;
              } else {
                adGroup.cpmBidRate = adGroup.bidRate;
              }
            });

            formatCurrencyData($scope.tableLevels.placements.tableData);
            $scope.filterTable();
          },
          function(data) {
            $scope.errorMsg = errorMsg;
          }
        )
        .finally(function() {
          $scope.booleans.actionInProgress = false;
        });
    } else if ($scope.$storage.currentTableLevel === 'creatives') {
      $scope
        .catchErrors(
          reportFactory.getCreativeTableData(
            campaign.id,
            $scope.selectedDurationRange,
            getIdArrayFromObj(campaign, 'creative')
          )
        )
        .then(
          function(data) {
            $scope.tableLevels.creatives.tableData = getCreativesFromCampaign(campaign);
            // For Text Banner Creative, creative type is HTML5 and for HTML5, type is HTML5_NEW.
            $scope.tableLevels.creatives.tableData.map(creative => {
              if (creative['creativeType'] == 'HTML5') {
                creative.creativeType = 'TEXT BANNER';
              } else if (creative['creativeType'] == 'HTML5_NEW') {
                creative.creativeType = 'HTML5';
              }
            });
            mergeData($scope.tableLevels.creatives.tableData, data);
            formatCurrencyData($scope.tableLevels.creatives.tableData);
            $scope.filterTable();
            if (highlightedCreative != null) {
              // If we do table level change from creative view.
              // we have to update chart inside of updateTableData function

              // highlight creative
              highlightCreativeFromCreativeView();
              // update daily chart and demographic chart
              $scope.updateChartData();
              // reset highlightedCreative = null
              highlightedCreative = null;
            }
          },
          function(data) {
            $scope.errorMsg = errorMsg;
          }
        )
        .finally(function() {
          $scope.booleans.actionInProgress = false;
        });
    }
  };

  $scope.$on('campaign-report-update', function() {
    $timeout(function() {
      $scope.updateTableData();
    });
  });

  $scope.applyFiltersToTable = function(filters) {
    var tableData = angular.copy($scope.tableLevels[$scope.$storage.currentTableLevel].tableData);
    var result = [];
    var types = Object.keys(filters);
    for (var i = 0; i < tableData.length; i++) {
      for (var x = 0; x < types.length; x++) {
        var type = types[x];
        var filterTerm = filters[type];
        if (Array.isArray(tableData[i])) {
          if (filterTerm.indexOf(tableData[i][0][type]) === -1) {
            break;
          }
        } else {
          if (filterTerm.indexOf(tableData[i][type]) === -1) {
            break;
          }
        }
        if (x === types.length - 1) {
          result.push(tableData[i]);
        }
      }
    }

    $scope.searchTableData(result);
  };

  $scope.filterTable = function(resetStatusFilter) {
    if ($scope.$storage.currentTableLevel !== $scope.tableLevels.placements.value) {
      return $scope.searchTableData();
    }

    var showAll = $scope.campaignSummaryConfig.table.filter || resetStatusFilter;
    var filters = {};
    for (var type in $scope.$storage.tableFilterConfig) {
      if (showAll) {
        $scope.$storage.tableFilterConfig[type].checked = true;
      }
      var filterTerms = [];
      if ($scope.$storage.tableFilterConfig[type] && $scope.$storage.tableFilterConfig[type].rows) {
        for (var i = 0; i < $scope.$storage.tableFilterConfig[type].rows.length; i++) {
          if (showAll) {
            $scope.$storage.tableFilterConfig[type].rows[i].checked = true;
          }
          if ($scope.$storage.tableFilterConfig[type].rows[i].checked) {
            filterTerms.push($scope.$storage.tableFilterConfig[type].rows[i].title);
          }
        }
        filters[type] = filterTerms;
      }
    }
    $scope.applyFiltersToTable(filters);
  };

  $scope.searchTableData = function(data) {
    data = data || angular.copy($scope.tableLevels[$scope.$storage.currentTableLevel].tableData);
    $scope.filteredTableData = $filter('filter')(data, $scope.campaignSummaryConfig.table.filter);
    $scope.booleans.tableChanged = true;
  };

  $scope.searchTable = function() {
    const tableData = angular.copy($scope.tableLevels[$scope.$storage.currentTableLevel].tableData);
    const searchTerm = $scope.campaignSummaryConfig.table.filter;
    const searchProps = ['id', 'name'];
    $scope.filteredTableData = searchInOrder(searchTerm, tableData, searchProps);
    $scope.booleans.tableChanged = true;
  };

  $scope.clearSearch = function() {
    $scope.campaignSummaryConfig.table.filter = '';
    $scope.searchTable();
  };

  function actionButtonManagement() {
    // Play/Pause
    if (
      $scope.highlightedAdGroup.status === campaignManagerFactory.statusNames.ACTIVE ||
      $scope.highlightedAdGroup.status === campaignManagerFactory.statusNames.PENDING
    ) {
      $scope.booleans.showPlayButton = false;
      $scope.booleans.showPauseButton = true;
    } else if ($scope.highlightedAdGroup.status === campaignManagerFactory.statusNames.PAUSED) {
      $scope.booleans.showPauseButton = false;
      $scope.booleans.showPlayButton = true;
    }

    if ($scope.$storage.currentTableLevel == $scope.tableLevels.campaigns.value) {
      $scope.booleans.showCloneButton = false;
      $scope.booleans.showEditButton = true;
    } else if ($scope.$storage.currentTableLevel == $scope.tableLevels.placements.value) {
      $scope.booleans.showCloneButton = true;
      $scope.booleans.showEditButton = true;
    } else {
      $scope.booleans.showCloneButton = true;
      $scope.booleans.showEditButton = false;
    }
  }

  function formatCurrencyData(data) {
    for (var i = 0; i < data.length; i++) {
      var row = data[i];

      row.totalBudgetWithCurrencyCode =
        row.totalBudget != null ? $filter('isoCurrency')(row.totalBudget, $scope.currencyCode) : row.totalBudget;
      if (row.length === undefined && row.hasOwnProperty('spends_spent')) {
        row.total_spent = row.spends_spent;
        row.spentWithCurrencyCode = $filter('isoCurrency')(row.spends_spent, $scope.currencyCode);
        row.dailySpentWithCurrencyCode = $filter('isoCurrency')(row.spends_daily_spent, $scope.currencyCode);
        row.ecpmWithCurrencyCode = $filter('isoCurrency')(row.ecpm, $scope.currencyCode);
        row.ecpvWithCurrencyCode = $filter('isoCurrency')(row.ecpv, $scope.currencyCode);
      } else {
        for (var j = 0; j < row.length; j++) {
          var sub_row = row[j];

          if (sub_row.hasOwnProperty('spends_spent')) {
            sub_row.total_spent = sub_row.spends_spent;
            sub_row.spentWithCurrencyCode = $filter('isoCurrency')(sub_row.spends_spent, $scope.currencyCode);
          }

          if (sub_row.hasOwnProperty('spends_daily_spent')) {
            sub_row.dailySpentWithCurrencyCode = $filter('isoCurrency')(
              sub_row.spends_daily_spent,
              $scope.currencyCode
            );
          }

          if (sub_row.hasOwnProperty('totalBudget')) {
            sub_row.totalBudgetWithCurrencyCode = $filter('isoCurrency')(sub_row.totalBudget, $scope.currencyCode);
          }

          if (sub_row.hasOwnProperty('ecpm')) {
            sub_row.ecpmWithCurrencyCode = $filter('isoCurrency')(sub_row.ecpm, $scope.currencyCode);
          }

          if (sub_row.hasOwnProperty('ecpv')) {
            sub_row.ecpvWithCurrencyCode = $filter('isoCurrency')(sub_row.ecpv, $scope.currencyCode);
          }
        }
      }
    }
  }

  function mergeData(dstArray, srcArray) {
    for (var i = 0; i < dstArray.length; i++) {
      angular.extend(dstArray[i], srcArray[i]);
    }
    dstArray.splice(i);
  }

  function formatDates(arr) {
    var start;
    for (var i = 0; i < arr.length; i++) {
      if (!arr[i].timeframe) {
        continue;
      }
      start = new Date(arr[i].timeframe.start);
      arr[i].timeframe_start_string = start.toISOString().substring(0, 10);

      arr[i].timeframe_end_string = arr[i].timeframe.end
        ? new Date(arr[i].timeframe.end).toISOString().substring(0, 10)
        : null;
    }
  }

  $scope.$on('adgroup-table-update', function() {
    $scope.updateTableData();
    $scope.resetTable();
    $scope.booleans.tableChanged = true;
  });

  $scope.metricsChanged = function() {
    // $scope.updateTableData(); not yet
    $scope.updateChartData();
  };
  let ldaCampaigns = JSON.parse($scope.$storage.ldaCampaigns || '[]');
  let currentCampaign = ldaCampaigns.find(ldaCampaign => ldaCampaign.id == $scope.campaign.id);
  if (!currentCampaign) {
    ldaCampaigns.push({ id: $scope.campaign.id, ldaEnabledAdGroupIds: '' });
  } else {
    for (let adGroupElement of $scope.adGroups) {
      if (adGroupElement.targeting.twentyOneAndOver) {
        currentCampaign['ldaEnabledAdGroupIds'] = currentCampaign['ldaEnabledAdGroupIds'] + adGroupElement.id;
      } else if (adGroupElement.targeting.twentyOneAndOver === false) {
        // In some cases twentyOneAndOver may become undefined, hence Used else if instead of else
        currentCampaign['ldaEnabledAdGroupIds'] = replaceAll(
          currentCampaign['ldaEnabledAdGroupIds'],
          adGroupElement.id.toString(),
          ''
        );
      }
      $scope.$storage.ldaCampaigns = JSON.stringify(ldaCampaigns);
    }
    $scope.isldaEnabled = currentCampaign['ldaEnabledAdGroupIds'] !== '';
  }
  $scope.$storage.ldaCampaigns = JSON.stringify(ldaCampaigns);
  if ($scope.isldaEnabled) {
    $scope.campaignAudienceConfig.chart.demographics.categories[1] = '18-24*';
    $scope.ldaSelectedText =
      '*Because you have restricted delivery of your campaign to consumers of Legal Drinking Age, the recipients represented in this age bracket are actually 21-24, not 18-24.';
  }
  /**
   * Gets new data for demographics and daily charts and re-renders them.
   */

  // helper function used by updateChartData()
  $scope.retrieveSelectedMetrics = function(config) {
    var selectedMetrics = [];

    for (var prop in config) {
      if (config.hasOwnProperty(prop)) {
        if (config[prop].visible) {
          selectedMetrics.push(prop);
        }
      }
    }

    return selectedMetrics;
  };

  $scope.convertAudienceColumnChartData = function(selectedMetrics, data) {
    var chartData = [];
    var point;
    var tempData;
    var metric;

    if (data !== null) {
      for (point of data) {
        tempData = [];

        if (point.taxonomy_audience_name) {
          // to populate GT Audience names on chart
          tempData.push(point.taxonomy_audience_name);
        } else if (point.audience_name) {
          tempData.push(point.audience_name);
        } else if (point.category_name) {
          tempData.push(point.category_name);
        } else {
          tempData.push(point.brand_name);
        }

        for (metric of selectedMetrics) {
          tempData.push(point[metric]);
        }

        chartData.push(tempData);
      }

      return chartData;
    }
  };

  // converts data from backend format into Highcharts format
  // this works for Reporting by Product Chart
  $scope.convertProductColumnChartData = function(selectedMetrics, data) {
    var chartData = [];
    var tempData, point, label, constraint;

    for (point of data) {
      tempData = [];

      constraint = point.constraint !== null ? point.constraint : '';

      label = '<div>' + constraint + '</div><br><div>' + point.product_group + ' | ' + point.product + '</div>';

      if (point.constraint !== null) {
        label =
          '<div>' +
          constraint +
          '</div><br><div>' +
          point.product_group +
          ' | ' +
          point.product +
          ' | ' +
          constraint +
          '</div>';
      }

      tempData.push(label);

      for (var metric of selectedMetrics) {
        tempData.push(point[metric]);
      }

      chartData.push(tempData);
    }

    return chartData;
  };

  // convert data from backend format into HighCharts format
  // this works for TOD Charts and DOW Charts
  $scope.convertHourlyAndWeeklyChartData = function(selectedMetrics, data) {
    var chartData = {};

    for (var metric of selectedMetrics) {
      chartData[metric] = []; // initialize empty data
    }

    for (var point of data) {
      for (var metric of selectedMetrics) {
        chartData[metric].push(point[metric]);
      }
    }

    return chartData;
  };

  // convert data from backend format into HighCharts format
  // this works for Daily Charts
  $scope.convertDailyChartData = function(selectedMetrics, data, isForecasted) {
    var chartData = {};

    for (var metric of selectedMetrics) {
      chartData[metric] = []; // initialize empty data
    }

    if (selectedMetrics.includes('total_sales')) {
      if (!chartData.hasOwnProperty('avg_sales')) {
        chartData['avg_sales'] = [];
      }
    }

    for (var point of data) {
      for (var metric of selectedMetrics) {
        if (
          metric == 'visits' &&
          (point['forecasted'] == 0 || point['forecasted'] == 1 || isForecasted == 1) &&
          point['forecasted'] != isForecasted
        ) {
          continue;
        }
        if (point[metric] != null) {
          chartData[metric].push([point['timestamp'], point[metric]]);
        }
      }
      if (selectedMetrics.includes('total_sales')) {
        chartData['avg_sales'].push([point['timestamp'], point['avg_sales']]);
      }
    }

    return chartData;
  };

  $scope.isMetricDataAllZero = function(selectedReport, selectedMetrics, data) {
    if (!data) {
      summaryChartsConfig[selectedReport].chartMessage =
        'There was an unexpected error while loading reporting data. <br> Please try again later!';
      return [];
    }

    // check if sales data is zero or null
    if (
      selectedMetrics.includes('total_sales') &&
      (data.every(item => item['total_sales'] === 0) || data.every(item => item['total_sales'] === null))
    ) {
      summaryChartsConfig[selectedReport].chartMessage =
        'Thanks for your patience while we retrieve your sales data from your POS. <br> Report will be available once your data is fully processed.';
      return [];
    }

    // check if data for both selected metrics is either zero or null
    const isAnyOtherMetricZero = selectedMetrics.every(
      metric =>
        metric !== 'total_sales' &&
        (data.every(item => item[metric] === 0) || data.every(item => item[metric] === null))
    );

    if (isAnyOtherMetricZero) {
      summaryChartsConfig[selectedReport].chartMessage = 'There is no available data.';
      return [];
    }

    return data;
  };

  // convert data from backend format into HighCharts format
  // this works for Web Conversions Charts
  $scope.convertConversionsChartData = function(selectedMetrics, data) {
    let chartData = {};
    if (!data) {
      return chartData;
    }

    for (let metric of selectedMetrics) {
      chartData[metric] = []; // initialize empty data
    }

    for (let point of data) {
      for (let metric of selectedMetrics) {
        chartData[metric].push([point['timestamp'], point['conversions'], point['date']]);
      }
    }

    return chartData;
  };

  // convert data from backend format into HighCharts format
  // this works for the Demographic Charts
  $scope.convertDemographicChartData = function(selectedMetrics, data) {
    var chartData = {};

    for (var gender in data) {
      if (gender !== 'unknown') {
        var age_array = data[gender];
        chartData[gender] = [];

        for (var age of age_array) {
          if (age['age'] !== 'unknown') {
            chartData[gender].push(age[selectedMetrics]);
          }
        }
      }
    }

    return chartData;
  };

  // convert data from backend formatDates into HighCharts format
  // this works for DOW+TOD Charts
  // NOTE: only takes the first selectedMetric because we only allow one, but the data structure is set up to allow multiple
  $scope.convertDOWTODChartData = function(selectedMetrics, data) {
    var selectedMetric = selectedMetrics[0];
    var chartData = {};

    for (var day of data) {
      var dayString = day.day;
      var hours = day.hours;

      chartData[dayString] = [];

      for (var hour of hours) {
        chartData[dayString].push(hour[selectedMetric]);
      }
    }
    return chartData;
  };

  /**
   * Convert data from backend format into HighChart format
   * this work for the Publisher Charts
   * @param {string} selectedMetric - Name of the metric
   * @param {Array} data - The array of objects contains publisher name and selectedMetric
   * @returns {Array} - The array of data in HighChart format
   */
  $scope.convertPublisherChartData = function([selectedMetric, ...rest], data) {
    const _data = data || [];
    return _data.map(item => [item?.publisher, item[selectedMetric]]);
  };

  // Determine whether or not to show the chart
  $scope.hideChart = function(data) {
    if ($scope.viewChart === false) {
      $scope.viewChart = false;
    }
  };

  $scope.updateSelectedAudienceChart = function(selectedLevel, selectedId, selectedReport) {
    var config = audienceChartsConfig[selectedReport].seriesOptions;
    var selectedMetrics = $scope.retrieveSelectedMetrics(config);
    audienceChartsConfig[selectedReport].progress = true;

    if (selectedReport == 'demographics') {
      $scope
        .catchErrors(
          reportFactory.getDemographicsChartData(
            selectedLevel,
            selectedId,
            $scope.selectedDurationRange,
            selectedMetrics
          )
        )
        .then(function(data) {
          $scope.hideChart(data);
          var chartData = $scope.convertDemographicChartData(selectedMetrics, data);
          audienceChartsConfig[selectedReport].data = chartData;
          audienceChartsConfig[selectedReport].changed = true;
        })
        .finally(function() {
          audienceChartsConfig[selectedReport].progress = false;
        });
    } else if (selectedReport == 'behavioral') {
      // (type, id, selectedDurationRange, metrics)
      $scope
        .catchErrors(
          reportFactory.getBehavioralAudienceChartData(
            selectedLevel,
            selectedId,
            $scope.selectedDurationRange,
            selectedMetrics
          )
        )
        .then(function(data) {
          $scope.hideChart(data);
          var chartData = $scope.convertAudienceColumnChartData(selectedMetrics, data);
          audienceChartsConfig[selectedReport].data = chartData;
          audienceChartsConfig[selectedReport].changed = true;
        })
        .finally(function() {
          audienceChartsConfig[selectedReport].progress = false;
        });
    } else if (selectedReport == 'audiencecategory') {
      // (type, id, selectedDurationRange, metrics)
      $scope
        .catchErrors(
          reportFactory.getCategoryChartData(selectedLevel, selectedId, $scope.selectedDurationRange, selectedMetrics)
        )
        .then(function(data) {
          $scope.hideChart(data);
          var chartData = $scope.convertAudienceColumnChartData(selectedMetrics, data);
          audienceChartsConfig[selectedReport].data = chartData;
          audienceChartsConfig[selectedReport].changed = true;
        })
        .finally(function() {
          audienceChartsConfig[selectedReport].progress = false;
        });
    } else if (selectedReport == 'brandaffinity') {
      // (type, id, selectedDurationRange, metrics, sic_code)
      $scope
        .catchErrors(
          reportFactory.getBrandAffinityChartData(
            selectedLevel,
            selectedId,
            $scope.selectedDurationRange,
            selectedMetrics,
            $scope.selectedCategory
          )
        )
        .then(function(data) {
          if (data) {
            $scope.hideChart(data);
            var chartData = $scope.convertAudienceColumnChartData(selectedMetrics, data);
            audienceChartsConfig[selectedReport].data = chartData;
          } else {
            audienceChartsConfig[selectedReport].data = [];
          }
          audienceChartsConfig[selectedReport].changed = true;
        })
        .finally(function() {
          audienceChartsConfig[selectedReport].progress = false;
        });
    } else if (selectedReport == 'audienceaffinity') {
      $scope
        .catchErrors(
          reportFactory.getAudienceAffinityChartData(
            selectedLevel,
            selectedId,
            $scope.selectedDurationRange,
            selectedMetrics,
            $scope.selectedCategory
          )
        )
        .then(function(data) {
          $scope.hideChart(data);
          const chartData = $scope.convertAudienceColumnChartData(selectedMetrics, data);
          audienceChartsConfig[selectedReport].data = chartData;
          audienceChartsConfig[selectedReport].changed = true;
        })
        .finally(function() {
          audienceChartsConfig[selectedReport].progress = false;
        });
    }
  };

  $scope.showError = function(message) {
    var modalSetting = {
      titleClass: 'alert',
      title: 'Error',
      message: message
    };

    //modalFactory.simpleAlert(modalSetting);
  };

  $scope.catchErrors = function(promise) {
    return promise
      .then(function(data) {
        return data;
      })
      .catch(function(error) {
        $scope.showError(error);
        console.error(error);
      });
  };

  $scope.updateSelectedSummaryChart = function(selectedLevel, selectedId, selectedReport) {
    var config = summaryChartsConfig[selectedReport].seriesOptions;
    var selectedMetrics = $scope.retrieveSelectedMetrics(config);
    summaryChartsConfig[selectedReport].progress = true;
    $scope.$emit('selectedReportChanged', selectedReport);

    if (selectedReport == 'daily') {
      $scope
        .catchErrors(
          reportFactory.getDailyChartData(selectedLevel, selectedId, $scope.selectedDurationRange, selectedMetrics)
        )
        .then(function(data) {
          $scope.hideChart(data);
          let formattedData = $scope.isMetricDataAllZero(selectedReport, selectedMetrics, data);
          let chartData = $scope.convertDailyChartData(selectedMetrics, formattedData, 0);
          summaryChartsConfig[selectedReport].data = chartData;
          let forecastedChartData = $scope.convertDailyChartData(selectedMetrics, formattedData, 1);
          summaryChartsConfig[selectedReport].forecastedData = forecastedChartData;
          summaryChartsConfig[selectedReport].changed = true;
          summaryChartsConfig[selectedReport].accountCurrency =
            $scope.companyAndAccountFactory?.selectedAccount?.currency;
          $scope.$emit('forecastedChartData', forecastedChartData);
        })
        .finally(function() {
          summaryChartsConfig[selectedReport].progress = false;
        });
    } else if (selectedReport == 'tod') {
      $scope
        .catchErrors(
          reportFactory.getTODChartData(selectedLevel, selectedId, $scope.selectedDurationRange, selectedMetrics)
        )
        .then(function(data) {
          $scope.hideChart(data);
          let formattedData = $scope.isMetricDataAllZero(selectedReport, selectedMetrics, data);
          let chartData = $scope.convertHourlyAndWeeklyChartData(selectedMetrics, formattedData);
          summaryChartsConfig[selectedReport].data = chartData;
          summaryChartsConfig[selectedReport].changed = true;
          summaryChartsConfig[selectedReport].accountCurrency =
            $scope.companyAndAccountFactory?.selectedAccount?.currency;
        })
        .finally(function() {
          summaryChartsConfig[selectedReport].progress = false;
        });
    } else if (selectedReport == 'dow') {
      $scope
        .catchErrors(
          reportFactory.getDOWChartData(selectedLevel, selectedId, $scope.selectedDurationRange, selectedMetrics)
        )
        .then(function(data) {
          $scope.hideChart(data);
          let formattedData = $scope.isMetricDataAllZero(selectedReport, selectedMetrics, data);
          let chartData = $scope.convertHourlyAndWeeklyChartData(selectedMetrics, formattedData);
          summaryChartsConfig[selectedReport].data = chartData;
          summaryChartsConfig[selectedReport].changed = true;
          summaryChartsConfig[selectedReport].accountCurrency =
            $scope.companyAndAccountFactory?.selectedAccount?.currency;
        })
        .finally(function() {
          summaryChartsConfig[selectedReport].progress = false;
        });
    } else if (selectedReport == 'dowtod') {
      // TODO: move day selector legend outside of highcharts so that only what's required is requested
      var selectedDays = [];

      for (var day of $scope.dowtodOptions) {
        if (day.checked) {
          selectedDays.push(day.value);
        }
      }

      $scope
        .catchErrors(
          reportFactory.getDOWTODChartData(
            selectedLevel,
            selectedId,
            $scope.selectedDurationRange,
            selectedMetrics,
            selectedDays
          )
        )
        .then(function(data) {
          $scope.hideChart(data);
          var chartData = $scope.convertDOWTODChartData(selectedMetrics, data);
          summaryChartsConfig[selectedReport].data = chartData;
          summaryChartsConfig[selectedReport].changed = true;
        })
        .finally(function() {
          summaryChartsConfig[selectedReport].progress = false;
        });
    } else if (selectedReport == 'brand') {
      // no chart yet, only showing table data
    } else if (selectedReport == 'category') {
      // no chart yet, only showing table data
    } else if (selectedReport == 'audience') {
      // no chart yet, only showing table data
    } else if (selectedReport == 'os') {
      // no chart yet, only showing table data
    } else if (selectedReport == 'device') {
      // no chart yet, only showing table data
    } else if (selectedReport == 'product') {
      var selectedProducts = [];
      var productOptions = summaryChartsConfig[selectedReport].productOptions;

      for (var key in productOptions) {
        for (var prop of productOptions[key]) {
          if (prop.checked) {
            selectedProducts.push(prop.key);
          }
        }
      }

      reportFactory
        .getProductChartData(selectedLevel, selectedId, $scope.selectedDurationRange, selectedMetrics, selectedProducts)
        .then(
          function(data) {
            var chartData = $scope.convertProductColumnChartData(selectedMetrics, data);
            $scope.hideChart(data);
            summaryChartsConfig[selectedReport].data = chartData;
            summaryChartsConfig[selectedReport].changed = true;
          },
          function(error) {
            $scope.showError(error);
            summaryChartsConfig[selectedReport].data = [];
            summaryChartsConfig[selectedReport].changed = true;
          }
        )
        .finally(function() {
          summaryChartsConfig[selectedReport].progress = false;
        });
    } else if (selectedReport == 'visitation') {
      $scope
        .catchErrors(reportFactory.getVisitationChartData(selectedLevel, selectedId, $scope.selectedDurationRange))
        .then(function(data) {
          $scope.hideChart(data);
          summaryChartsConfig[selectedReport].data = data;
          summaryChartsConfig[selectedReport].changed = true;
        })
        .finally(function() {
          summaryChartsConfig[selectedReport].progress = false;
        });
    } else if (selectedReport == 'publisher') {
      $scope
        .catchErrors(
          reportFactory.getPublisherChartData(selectedLevel, selectedId, $scope.selectedDurationRange, selectedMetrics)
        )
        .then(function(data) {
          var chartData = $scope.convertPublisherChartData(selectedMetrics, data);
          $scope.hideChart(chartData);
          summaryChartsConfig[selectedReport].data = chartData;
          summaryChartsConfig[selectedReport].changed = true;
        })
        .finally(function() {
          summaryChartsConfig[selectedReport].progress = false;
        });
    } else if (selectedReport == 'conversions') {
      // get all available pixels to display in dropdown
      $scope
        .catchErrors(reportFactory.getConversionsPixelData(selectedLevel, $scope.campaign.id, selectedId))
        .then(function(data) {
          $scope.summaryChartMetrics = $scope.summaryChartMetrics.filter(metric => metric.value === 'pixels');
          summaryChartsConfig[$scope.selectedReport.value].seriesOptions['pixels'].visible =
            $scope.shouldPixelOptionDefault || selectedMetrics[0] === 'pixels';
          data.pixels.forEach(pixel => {
            // pixel.segment_id == null means it's a conversion pixel and
            // we only want to show conversion pixels in the dropdown
            if (pixel.segment_id == null) {
              const { alias, id } = pixel;
              const seriesOption = {
                color: '#65c5a5',
                value: `${id}`,
                displayName: alias,
                visible: `${id}` === selectedMetrics[0] && !$scope.shouldPixelOptionDefault,
                dataLabel: 'number',
                tooltipName: 'conversions',
                name: alias
              };
              $scope.summaryChartMetrics.push(seriesOption);
              summaryChartsConfig[$scope.selectedReport.value].seriesOptions[id] = seriesOption;
            }
          });
        })
        .finally(function() {
          summaryChartsConfig[selectedReport].progress = false;
        });
      // get chart data
      $scope
        .catchErrors(
          reportFactory.getConversionsChartData(
            selectedLevel,
            selectedId,
            $scope.selectedDurationRange,
            ['conversions'],
            $scope.shouldPixelOptionDefault ? ['pixels'] : selectedMetrics
          )
        )
        .then(function(data) {
          var chartData = $scope.convertConversionsChartData(selectedMetrics, data);
          $scope.hideChart(chartData);
          summaryChartsConfig[selectedReport].data = {
            ...chartData
          };
          summaryChartsConfig[selectedReport].changed = true;
        })
        .finally(function() {
          $scope.shouldPixelOptionDefault = false;
          summaryChartsConfig[selectedReport].progress = false;
        });
    } else {
      // TODO: handle other charts here
      return;
    }
  };

  $scope.updateChartData = function() {
    if ($scope.currentReportTab === $scope.reportTabs.audience.value) {
      if ($scope.$storage.currentTableLevel === 'campaigns' || !$scope.highlightedAdGroup) {
        $scope.updateSelectedAudienceChart('campaigns', campaignId, $scope.selectedReport.value);
      } else if ($scope.$storage.currentTableLevel === 'placements' && $scope.highlightedAdGroup) {
        $scope.updateSelectedAudienceChart('placements', $scope.highlightedAdGroup.id, $scope.selectedReport.value);
      } else if ($scope.$storage.currentTableLevel === 'creatives' && $scope.highlightedAdGroup) {
        $scope.updateSelectedAudienceChart('creatives', $scope.highlightedAdGroup.id, $scope.selectedReport.value);
      }
    } else if ($scope.currentReportTab === $scope.reportTabs.summary.value) {
      if ($scope.$storage.currentTableLevel === 'campaigns' || !$scope.highlightedAdGroup) {
        $scope.updateSelectedSummaryChart('campaigns', campaignId, $scope.selectedReport.value);
      } else if ($scope.$storage.currentTableLevel === 'placements' && $scope.highlightedAdGroup) {
        $scope.updateSelectedSummaryChart('placements', $scope.highlightedAdGroup.id, $scope.selectedReport.value);
      } else if ($scope.$storage.currentTableLevel === 'creatives' && $scope.highlightedAdGroup) {
        $scope.updateSelectedSummaryChart('creatives', $scope.highlightedAdGroup.id, $scope.selectedReport.value);
      } else {
        // TODO: probable put some error stuff
      }
    }
  };

  function updateStartEndDateForRealTimeData(chartStartDate, chartEndDate) {
    var todayDate = new Date(new Date(new Date()).setHours(0, 0, 0, 0));
    if (chartStartDate < todayDate && todayDate < chartEndDate) {
      realTimeDate.enabled = true;

      var twoWeeksAgoDate = new Date(new Date(todayDate.valueOf() - 15 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0));

      if (chartStartDate < twoWeeksAgoDate) {
        chartStartDate = twoWeeksAgoDate;
      }
      if (chartEndDate > todayDate) {
        chartEndDate = todayDate;
      }
    }
    var originStartDate = new Date(new Date(campaign.timeframe.start).setHours(0, 0, 0, 0));
    if (originStartDate > chartStartDate) {
      chartStartDate = originStartDate;
    }
    realTimeDate.start_date = chartStartDate;
    realTimeDate.end_date = chartEndDate;
  }

  function groupCreativesBySize(tableData) {
    // empty creatives view content first
    $scope.creativesBySize = [];
    // if highlighted, check the table level and group creatives by size
    // else, group campaign level creatives by size
    var campaignLevelCreatives = false;
    if ($scope.highlightedAdGroup) {
      if (tableData.hasOwnProperty('creativeSize')) {
        // item selected at creative level
        var size = tableData.creativeSize;
        if (tableData.cmsConfig && tableData.cmsConfig.bannerUrl) {
          if (!$scope.creativesBySize.hasOwnProperty(size)) {
            $scope.creativesBySize[size] = [];
          }
          $scope.creativesBySize[size].push({
            creativeUrl: tableData.cmsConfig.bannerUrl,
            adGroup: null,
            cmsConfig: tableData.cmsConfig,
            creative_id: tableData.id
          });
        }
      } else if (tableData.creatives) {
        // item selected at adgroup level
        for (var j = 0; j < tableData.creatives.length; j++) {
          var size = tableData.creatives[j].creativeSize;
          if (tableData.creatives[j].cmsConfig && tableData.creatives[j].cmsConfig.bannerUrl) {
            if (!$scope.creativesBySize.hasOwnProperty(size)) {
              $scope.creativesBySize[size] = [];
            }
            $scope.creativesBySize[size].push({
              creativeUrl: tableData.creatives[j].cmsConfig.bannerUrl,
              adGroup: tableData,
              cmsConfig: tableData.creatives[j].cmsConfig,
              creative_id: tableData.creatives[j].id
            });
          }
        }
      } else if (Array.isArray(tableData)) {
        // breakdown highlighted, the first element is what we want
        groupCreativesBySize(tableData[0]);
      } else {
        // item selected at campaign level
        campaignLevelCreatives = true;
      }
    }
    if (campaignLevelCreatives || !$scope.highlightedAdGroup) {
      for (var i = 0; i < $scope.adGroups.length; i++) {
        var adGroup = $scope.adGroups[i];
        if (adGroup.creatives) {
          for (var j = 0; j < adGroup.creatives.length; j++) {
            var size = adGroup.creatives[j].creativeSize;
            if (adGroup.creatives[j].cmsConfig && adGroup.creatives[j].cmsConfig.bannerUrl) {
              if (!$scope.creativesBySize.hasOwnProperty(size)) {
                $scope.creativesBySize[size] = [];
              }
              $scope.creativesBySize[size].push({
                creativeUrl: adGroup.creatives[j].cmsConfig.bannerUrl,
                adGroup: adGroup,
                cmsConfig: adGroup.creatives[j].cmsConfig,
                creative_id: adGroup.creatives[j].id
              });
            }
          }
        }
      }
    }
    $scope.creativeSizes = Object.keys($scope.creativesBySize);
    $scope.booleans.showViewCreativesButton = $scope.creativeSizes.length > 0;
  }

  $scope.actionSheetCallback = function(type) {
    if (type === 'activate') {
      $scope.activateAction($scope.highlightedAdGroup);
    } else if (type === 'pause') {
      $scope.pauseAction($scope.highlightedAdGroup);
    } else if (type === 'edit') {
      $scope.editAction($scope.highlightedAdGroup);
    } else if (type === 'clone') {
      $scope.cloneAction($scope.highlightedAdGroup);
    } else if (type === 'delete') {
      $scope.deleteAction($scope.highlightedAdGroup);
    }
  };

  $scope.activateAction = function(item) {
    $scope.resetTable();
    if ($scope.$storage.currentTableLevel == $scope.tableLevels.campaigns.value) {
      $scope.activateCampaign();
    } else if ($scope.$storage.currentTableLevel == $scope.tableLevels.placements.value) {
      $scope.activateAdGroup(item);
    } else if ($scope.$storage.currentTableLevel == $scope.tableLevels.creatives.value) {
      $scope.activateCreative(item);
    }
  };

  $scope.pauseAction = function(item) {
    $scope.resetTable();
    if ($scope.$storage.currentTableLevel == $scope.tableLevels.campaigns.value) {
      $scope.pauseCampaign();
    } else if ($scope.$storage.currentTableLevel == $scope.tableLevels.placements.value) {
      $scope.pauseAdGroup(item);
    } else if ($scope.$storage.currentTableLevel == $scope.tableLevels.creatives.value) {
      $scope.pauseCreative(item);
    }
  };

  $scope.editAction = function(item) {
    $scope.resetTable();
    if ($scope.$storage.currentTableLevel == $scope.tableLevels.campaigns.value) {
      $state.go('campaigns.campaign.settings', { campaignId: $stateParams.campaignId });
    } else if ($scope.$storage.currentTableLevel == $scope.tableLevels.placements.value) {
      $scope.editAdGroup(item);
    }
  };

  $scope.cloneAction = function(item) {
    $scope.resetTable();
    if ($scope.$storage.currentTableLevel == $scope.tableLevels.placements.value) {
      $scope.cloneAdGroup(item);
    } else if ($scope.$storage.currentTableLevel == $scope.tableLevels.creatives.value) {
      $scope.cloneCreative(item);
    }
  };

  $scope.deleteAction = function(item) {
    $scope.resetTable();
    if ($scope.$storage.currentTableLevel == $scope.tableLevels.campaigns.value) {
      $scope.deleteCampaign(item);
    } else if ($scope.$storage.currentTableLevel == $scope.tableLevels.placements.value) {
      $scope.deleteAdGroup(item);
    } else if ($scope.$storage.currentTableLevel == $scope.tableLevels.creatives.value) {
      $scope.showDeleteCreativeConfirm(item).then(function() {
        var creativesTableData = $scope.tableLevels.creatives.tableData;
        for (var i = 0; i < creativesTableData.length; i++) {
          if (creativesTableData[i].id === item.id) {
            creativesTableData.splice(i, 1);
            $scope.updateTableData();
            break;
          }
        }
      });
    }
  };

  $scope.pauseCampaign = function() {
    $scope.booleans.actionInProgress = true;
    campaignManagerFactory
      .changeCampaignStatus(campaign, campaignManagerFactory.statusNames.PAUSED)
      .then(
        function() {
          $scope.errorMsg = '';
          $scope.updateTableData();
          $scope.statusChange(campaignManagerFactory.statusNames.PAUSED);
        },
        function(msg) {
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  };

  $scope.activateCampaign = function() {
    $scope.booleans.actionInProgress = true;
    campaignManagerFactory
      .changeCampaignStatus(campaign, campaignManagerFactory.statusNames.ACTIVE)
      .then(
        function() {
          $scope.init();
          $scope.$storage.currentTableLevel = 'placements';
          $scope.updateTableData();
          $scope.statusChange(campaignManagerFactory.statusNames.ACTIVE);
        },
        function(msg) {
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  };

  $scope.retractCampaign = function() {
    $scope.booleans.saveProgress = true;
    campaignManagerFactory
      .changeCampaignStatus(campaign, campaignManagerFactory.statusNames.DRAFT)
      .then(
        function() {
          $scope.init();
        },
        function(msg) {
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.saveProgress = false;
      });
  };

  $scope.approveCampaign = function() {
    $scope.booleans.approveProgress = true;
    campaignManagerFactory
      .changeCampaignStatus(campaign, campaignManagerFactory.statusNames.ACTIVE)
      .then(
        function() {
          $scope.init(true);
        },
        function(msg) {
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.approveProgress = false;
      });
  };

  $scope.createNewAdGroup = function() {
    $scope.errorMsg = '';
    $scope.booleans.newPlacementProgress = true;
    campaignManagerFactory
      .createNewAdGroup(campaignManagerFactory.selectedCampaign.id, '320x50')
      .then(
        function(adGroup) {
          $scope.resetTable();
          $scope.selectAdGroup(adGroup);
        },
        function(msg) {
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.newPlacementProgress = false;
      });
  };

  $scope.selectAdGroup = function(adGroup) {
    campaignManagerFactory.selectAdGroup(campaign, adGroup);
  };

  $scope.editAdGroup = function(adGroup) {
    var campaign = campaignManagerFactory.selectedCampaign;
    campaignManagerFactory.selectAdGroup(campaign, adGroup);
  };

  $scope.viewCreatives = function() {
    $scope.showBricks = true;
  };

  $scope.closeViewCreatives = function() {
    $scope.showBricks = false;
  };

  $scope.statusChange = function(status) {
    if (status === campaignManagerFactory.statusNames.ACTIVE || status === campaignManagerFactory.statusNames.PENDING) {
      $scope.booleans.showPauseButton = true;
      $scope.booleans.showPlayButton = false;
    } else if (status === campaignManagerFactory.statusNames.PAUSED) {
      $scope.booleans.showPauseButton = false;
      $scope.booleans.showPlayButton = true;
    }
  };

  $scope.deleteCampaign = function(campaign) {
    $scope.showDeleteCampaignConfirm(campaign);
  };

  $scope.deleteAdGroup = function(adGroup) {
    $scope.showDeleteAdGroupConfirm(adGroup);
  };

  $scope.cloneAdGroup = function(adGroup) {
    $scope.showCloneAdGroupConfirm(adGroup, campaignManagerFactory.selectedCampaign.id);
  };

  $scope.cloneCreative = function(creative) {
    $scope.showCloneCreativeConfirm(creative);
  };

  $scope.pauseAdGroup = function(adGroup) {
    $scope.booleans.actionInProgress = true;
    campaignManagerFactory
      .changeAdGroupStatus(adGroup, campaignId, campaignManagerFactory.statusNames.PAUSED)
      .then(
        function() {
          $scope.errorMsg = '';
          $scope.updateTableData();
          $scope.statusChange(campaignManagerFactory.statusNames.PAUSED);
        },
        function(msg) {
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  };

  $scope.pauseCreative = function(creative) {
    $scope.booleans.actionInProgress = true;
    campaignManagerFactory
      .changeCreativeStatus(creative, campaignId, campaignManagerFactory.statusNames.PAUSED)
      .then(
        function() {
          $scope.errorMsg = '';
          $scope.updateTableData();
          $scope.statusChange(campaignManagerFactory.statusNames.PAUSED);
        },
        function(msg) {
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  };

  $scope.activateAdGroup = function(adGroup) {
    $scope.booleans.actionInProgress = true;
    campaignManagerFactory
      .changeAdGroupStatus(adGroup, campaignId, campaignManagerFactory.statusNames.ACTIVE)
      .then(
        function() {
          $scope.errorMsg = '';
          $scope.updateTableData();
          $scope.statusChange(campaignManagerFactory.statusNames.ACTIVE);
        },
        function(msg) {
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  };

  $scope.activateCreative = function(creative) {
    $scope.booleans.actionInProgress = true;
    campaignManagerFactory
      .changeCreativeStatus(creative, campaignId, campaignManagerFactory.statusNames.ACTIVE)
      .then(
        function() {
          $scope.errorMsg = '';
          $scope.updateTableData();
          $scope.statusChange(campaignManagerFactory.statusNames.ACTIVE);
        },
        function(msg) {
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.actionInProgress = false;
      });
  };

  $scope.toggleHighlightCreativeItem = function(creative) {
    // assign creative id to highlightedCreative, because haven't updated table data yet,
    // so we don't have creative level table items, and we can't highlight it.
    // need to use this creative id to find updated creative items and do the rest works.
    if ($scope.$storage.currentTableLevel != $scope.tableLevels.creatives.value) {
      $scope.$storage.currentTableLevel = $scope.tableLevels.creatives.value;
      highlightedCreative = creative.creative_id;
      $scope.changeTableLevel();
    } else {
      highlightedCreative = creative.creative_id;
      // highlight creative
      highlightCreativeFromCreativeView();
      // update daily chart and demographic chart
      $scope.updateChartData();
      // reset highlightedCreative = null
      highlightedCreative = null;
    }
  };

  $scope.openChannelTermsModal = function() {
    $scope.booleans.saveProgress = true;
    modalFactory
      .channelTermsAndConditions()
      .then(
        function() {
          $scope.init();
        },
        function(msg) {
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.saveProgress = false;
      });
  };

  $scope.openRejectCampaignModal = function() {
    $scope.booleans.rejectProgress = true;
    modalFactory
      .rejectCampaign()
      .then(
        function() {
          $scope.init();
        },
        function(msg) {
          $scope.errorMsg = errorMsg;
        }
      )
      .finally(function() {
        $scope.booleans.rejectProgress = false;
      });
  };

  $scope.openColumnPickerModal = function() {
    var modalOptions = {
      templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.modal-column-picker.html',
      controller: 'ColumnPickerModalController',
      size: 'xl'
    };

    var data = {
      // generate the current set of available columns
      availableHeaders: generateColumnConfig($scope.getSelectedReportName(), $scope.$storage.currentTableLevel)
    };

    modalFactory.createModal(modalOptions, data).then(function(data) {
      if (!data) {
        return;
      }

      $scope.$storage.columnSelections = angular.copy(columnConfigConstant);

      // merge selection data into columnSelections
      for (var i = 0; i < $scope.$storage.columnSelections.length; i++) {
        var column = $scope.$storage.columnSelections[i];
        if (data.hasOwnProperty(column.field)) {
          column.selected = data[column.field];
        }
      }

      // reload table
      $scope.campaignSummaryConfig.table.header = generateColumnConfig(
        $scope.getSelectedReportName(),
        $scope.$storage.currentTableLevel
      );

      if (angular.isNumber($scope.expandedRow.index)) {
        addColumnsOnBreakdown();
      }
      $scope.booleans.tableChanged = true;
    });
  };

  function getTableDataIndexByFilteredId(id) {
    var data = $scope.tableLevels[$scope.$storage.currentTableLevel].tableData;
    var i;
    for (i = 0; i < data.length; i++) {
      var row = data[i];
      if (Array.isArray(row)) {
        // row has breakdowns
        row = row[0];
      }
      if (row.id === id) {
        return i;
      }
    }
    return null;
  }

  $scope.searchCategories = function(query) {
    if (featureFlagFactory.isFeatureEnabled('AUDIENCE_TAXONOMY') && query.query.length > 1) {
      return new Promise((resolve, reject) => {
        send$http($http, searchCategories, { data: { search_text: query.query } }).then(
          response => resolve(response.results),
          error => reject(error.message)
        );
      });
    }

    var filters = {
      targeting_search: 1,
      exclude: 0,
      entity_types: 'category',
      max_siccode_size: 6
    };
    return searchFactory.getSearchResults(query.query, filters);
  };

  $scope.changeCategory = function(selected) {
    $scope.selectedCategory = selected?.selected ? selected.selected.sicCode || selected.selected.id : null;

    if (angular.isNumber($scope.expandedRow.index)) {
      $scope.resetTable();
    }

    $scope.updateChartData();
  };

  const resetTableTransitionHandler = $transitions.onStart({}, () => {
    // Collapse breakdowns when exiting state.
    $scope.resetTable();
  });

  this.$onDestroy = () => {
    resetTableTransitionHandler();
    updateCurrentTabTransitionHandler();
  };

  $scope.init();
}
