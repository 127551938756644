/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 * @ngInject
 */
export function trackingPixelAdGroupModalController($scope, $uibModalInstance, data) {
  $scope.pixels = data.pixels;
  $scope.selected = angular.copy(data.adGroupPixels);

  $scope.toggle = function(pixel, list) {
    var idx = -1;
    for (var i = 0; i < list.length; i++) {
      if (list[i].id === pixel.id) {
        idx = i;
        break;
      }
    }
    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(pixel);
    }
  };

  $scope.exists = function(pixel, list) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].id === pixel.id) {
        return true;
      }
    }
    return false;
  };

  $scope.isIndeterminate = function() {
    return $scope.selected.length !== 0 && $scope.selected.length !== $scope.pixels.length;
  };

  $scope.isChecked = function() {
    return $scope.selected.length === $scope.pixels.length;
  };

  $scope.toggleAll = function() {
    if ($scope.selected.length === $scope.pixels.length) {
      $scope.selected = [];
    } else if ($scope.selected.length === 0 || $scope.selected.length > 0) {
      $scope.selected = $scope.pixels.slice(0);
    }
  };

  $scope.save = function() {
    $uibModalInstance.close($scope.selected);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
