import { BidType, BudgetLevel, BudgetType } from '../../../api/types/common';

NewCampaignInstanceController.$inject = [
  '$scope',
  '$uibModalInstance',
  'xadcmsFactory',
  'searchFactory',
  'campaignManagerFactory',
  'companyAndAccountFactory',
  'handlers',
  'staticDataFactory',
  'featureFlagFactory'
];

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 */
export function NewCampaignInstanceController(
  $scope,
  $uibModalInstance,
  xadcmsFactory,
  searchFactory,
  campaignManagerFactory,
  companyAndAccountFactory,
  handlers,
  staticDataFactory,
  featureFlagFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;
  $scope.companyAndAccountFactory = companyAndAccountFactory;

  $scope.campaignName = {
    value: ''
  };
  $scope.salesEmail = {
    value: ''
  };
  $scope.opsEmail = {
    value: ''
  };
  $scope.accountManagerEmail = {
    value: ''
  };

  $scope.clientIoNumber = {
    value: ''
  };

  $scope.clientIoName = {
    value: ''
  };

  $scope.isCbd = {
    value: false
  };

  $scope.isDailyBudget = {
    value: false
  };

  $scope.campaignBudget = {
    value: null
  };

  $scope.campaignBudgetPadding = {
    value: null
  };

  $scope.setBudgetLevel = budgetLevel => {
    $scope.budgetLevel.value = budgetLevel;
  };

  $scope.isChannelTenant = companyAndAccountFactory.selectedTenant?.type === 2;
  $scope.isSelfServeTenant = companyAndAccountFactory.selectedTenant?.type === 1;
  $scope.showAdvancedOptions = companyAndAccountFactory.selectedTenant?.type === 0;

  if (featureFlagFactory.isFeatureEnabled('NAME_AUTO_POPULATE') && companyAndAccountFactory.selectedAccount) {
    $scope.campaignName.value = companyAndAccountFactory.selectedAccount.name.replace(' ', '_');
    if (companyAndAccountFactory?.selectedAccount?.brand?.name) {
      $scope.campaignName.value =
        $scope.campaignName.value + '_' + companyAndAccountFactory.selectedAccount.brand.name.replace(' ', '_');
    }
  }

  $scope.progress = false;
  $scope.advancedOptions = false;
  $scope.selectedAccount = companyAndAccountFactory.selectedAccount;
  $scope.budgetLevel = {
    value: BudgetLevel.ADGROUP
  };
  $scope.showDailyBudget = () =>
    featureFlagFactory.isFeatureEnabled('DAILY_BUDGET') &&
    $scope.budgetLevel.value === BudgetLevel.ADGROUP &&
    $scope.selectedBidType.value === BidType.CPM;
  $scope.budgetSettingsTooltip = staticDataFactory.budgetSettingsTooltip;
  $scope.isCbdTooltip = staticDataFactory.isCbdTooltip;
  $scope.dailyBudgetTooltip = staticDataFactory.dailyBudgetTooltip;
  $scope.bidTypeToolTip = staticDataFactory.bidTypeToolTip;

  $scope.pixelArr = [];

  $scope.dartBillingAccounts = {
    values: null
  };

  $scope.switch = {
    isBulkUpload: false
  };
  $scope.uploadState = 'create-campaign';
  $scope.headerTitle = 'Create new campaign';

  $scope.initModal = function() {
    $scope.vars = {
      salesforceNumber: '',
      selectedCategory: null,
      billingSourceSelected: null,
      enableAutoreconcile: false
    };
    $scope.clientIoName.value = '';
    $scope.clientIoNumber.value = '';

    $scope.isCbd.value = false;
    $scope.isDailyBudget.value = false;

    $scope.searchCategoryString = '';

    // No need to get available DART billing accounts if the user doesn't even
    // have the feature to use account billing sources in the first place
    if (featureFlagFactory.isFeatureEnabled('ACCOUNT_BILLING_SOURCE_READ')) {
      staticDataFactory.getDartBillingAccounts().then(function(data) {
        $scope.dartBillingAccounts.values = angular.copy(data);
        $scope.dartAccount = staticDataFactory.setDartBillingAccount(
          $scope.dartBillingAccounts.values,
          companyAndAccountFactory.selectedAccount
        );
      });
    }

    //tracking pixel logic
    //$scope.pixelArr = campaign.settings.pixels;
    $scope.isShowingURL = false;
    $scope.pixelToAdd = '';
    $scope.addPixel = function() {
      if ($scope.pixelToAdd && !$scope.addPixelProgress) {
        $scope.addPixelProgress = true;
        campaignManagerFactory
          .newConversionTrackingPixel($scope.pixelToAdd)
          .then(function() {
            $scope.pixelArr.push({ alias: $scope.pixelToAdd, url: 'www.example.com', showURL: false });
            $scope.pixelToAdd = '';
          })
          .finally(function() {
            $scope.addPixelProgress = false;
          });
      }
    };

    $scope.removePixel = function(pixel, index) {
      if (!$scope.removePixelProgress) {
        $scope.removePixelProgress = true;
        campaignManagerFactory
          .deleteConversionTrackingPixel(pixel.id)
          .then(function() {
            $scope.pixelArr.splice(index, 1);
          })
          .finally(function() {
            $scope.removePixelProgress = false;
          });
      }
    };

    //final dict should look something like this
    $scope.campaignUsers = {
      'Direct Sales': [],
      'Account Manager': [],
      'Direct Ops': []
    };

    // keep these in an object because otherwise ng-if screws with ng-model by creating a child scope
    $scope.freqCapSettings = {
      frequencyUnlimited: true,
      freqCapValue: null,
      freqCapDuration: null
    };

    $scope.bidTypes = [
      {
        value: 'CPM',
        label: 'CPM - Cost Per 1,000 Ad Impression'
      }
    ];

    if (
      featureFlagFactory.isFeatureEnabled('BID_TYPE_CPV') &&
      companyAndAccountFactory.selectedAccount?.isCpvEligible
    ) {
      $scope.bidTypes.push({
        value: 'CPV',
        label: 'CPV - Cost Per Visit'
      });
    }
    $scope.selectedBidType = $scope.bidTypes[0];
  };

  $scope.goToState = function(state) {
    if (state === 'create-campaign') {
      $scope.headerTitle = 'Create new campaign';
    } else if (state === 'campaign-bulk-upload') {
      $scope.headerTitle = 'Bulk campaign creation & edit';
    } else if (state === 'campaign-processing') {
      $scope.headerTitle = 'Bulk campaign creation & edit';
    } else if (state === 'campaign-complete') {
      $scope.headerTitle = 'Upload complete';
    }
  };

  $scope.goToCampaignCreated = function() {
    $scope.uploadState = 'campaign-created';
    $scope.goToState('campaign-created');
  };

  $scope.goToCampaignBulkUpload = function() {
    $scope.uploadState = 'campaign-bulk-upload';
    $scope.goToState('campaign-bulk-upload');
  };

  $scope.goToCampaignProcessing = function() {
    $scope.uploadState = 'campaign-processing';
    $scope.goToState('campaign-processing');
  };

  $scope.goToCampaignComplete = function() {
    $scope.campaignState = 'campaign-complete';
    $scope.goToState('campaign-complete');
  };

  $scope.toggleAdvancedOptions = function() {
    $scope.advancedOptions = !$scope.advancedOptions;
  };

  $scope.close = function() {
    $uibModalInstance.close();
  };

  $scope.modalSubmit = function() {
    $scope.progress = true;

    if (
      $scope.budgetLevel.value === BudgetLevel.CAMPAIGN ||
      ($scope.budgetLevel.value === BudgetLevel.ADGROUP && $scope.selectedBidType.value !== BidType.CPM)
    ) {
      $scope.isDailyBudget.value = false;
    }

    const formData = {
      name: $scope.campaignName.value,
      adgroupSize: $scope.creativeSize,
      category: $scope.vars.selectedCategory,
      salesforceNumber: $scope.vars.salesforceNumber,
      client_io_name: $scope.clientIoName.value,
      clientIoNumber: $scope.clientIoNumber.value,
      isCbd: $scope.isCbd.value,
      users: $scope.campaignUsers,
      billing_source_id: $scope.vars.billingSourceSelected.id,
      enableAutoreconcile: $scope.vars.enableAutoreconcile,
      dartAccount: $scope.dartAccount,
      campaignBudget: $scope.campaignBudget.value,
      campaignBudgetPadding: $scope.campaignBudgetPadding.value,
      freqCapValue: $scope.freqCapSettings.freqCapValue,
      freqCapDuration: $scope.freqCapSettings.freqCapDuration,
      isBulkUpload: $scope.switch.isBulkUpload,
      salesEmail: $scope.salesEmail.value,
      opsEmail: $scope.opsEmail.value,
      accountManagerEmail: $scope.accountManagerEmail.value,
      budgetLevel: $scope.budgetLevel.value,
      bidType: $scope.selectedBidType.value,
      budgetType: $scope.isDailyBudget.value ? BudgetType.DAILY : BudgetType.LIFETIME
    };
    if (window.campaign) {
      formData.id = window.campaign.id;
    }
    if ($scope.budgetLevel.value === BudgetLevel.ADGROUP) {
      formData.campaignBudget = null;
      formData.campaignBudgetPadding = null;
    }

    handlers
      .submitFn(formData)
      .then(
        function(campaign) {
          $scope.close();
        },
        function(msg) {
          if (msg == 'session changed') {
            $scope.close();
            return;
          }
          var errorElement = $('.modal-body .error').addClass('active');
          errorElement.text(msg);
        }
      )
      .finally(function() {
        $scope.progress = false;
      });
  };

  $scope.searchCategories = function(query) {
    var filter = {
      targeting_search: 1,
      entity_types: 'campaign_category'
    };
    return searchFactory.getSearchResults(query, filter);
  };

  $scope.searchSalesUsers = function(query) {
    return xadcmsFactory.getUsersAutoComplete(query, 'Direct Sales');
  };

  $scope.searchManagerUsers = function(query) {
    return xadcmsFactory.getUsersAutoComplete(query, 'Account Manager');
  };

  $scope.searchAdOpsUsers = function(query) {
    return xadcmsFactory.getUsersAutoComplete(query, 'Direct Ops');
  };

  $scope.initModal();

  $scope.stringComparator = function(v1, v2) {
    // Compare strings alphabetically
    return (v1.value || '').localeCompare(v2.value);
  };

  $scope.billingSourceActive = function(bill_id) {
    staticDataFactory.getBillingSources().then(function() {
      $scope.billingSourcesData = staticDataFactory.billingSources.billing_sources;
      $scope.vars.billingSourceSelected = $scope.billingSourcesData.filter(function(item) {
        return item.id === bill_id;
      })[0];
    });
  };

  $scope.billingSourceUpdate = function() {
    $scope.vars.enableAutoreconcile = 0;
  };

  $scope.billingSourceActive(1);

  $scope.bidTypeChange = function(selectedBidType) {
    $scope.selectedBidType = selectedBidType;
  };

  $scope.frequencyCappingChange = function() {
    if ($scope.freqCapSettings.frequencyUnlimited) {
      $scope.freqCapSettings.freqCapValue = null;
      $scope.freqCapSettings.freqCapDuration = null;
    } else {
      $scope.freqCapSettings.freqCapDuration = 1;
    }
  };
}
