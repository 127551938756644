import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import { ModalBase } from '../../../components/reactComponents/Modal/ModalBase';
import { PaymentForm } from './PaymentForm';

interface PaymentMethodModalProps {
  open: boolean;
  onClose: () => void;
  submitPaymentMethod: (tokenId: string) => any;
}

export function PaymentMethodModal(props: PaymentMethodModalProps) {
  const { open, onClose, submitPaymentMethod } = props;
  const [stripeObject, setStripeObject] = useState<Stripe | null>(null);

  useEffect(() => {
    if (window.stripePublicKey && !stripeObject) {
      loadStripe(window.stripePublicKey).then(value => setStripeObject(value));
    }
  });

  return (
    <ModalBase open={open} title="Add New Payment Method" className="new-payment-modal-root">
      <button type="button" className="close-modal-button" onClick={onClose}>
        <span className="cancel-icon"></span>
      </button>
      <div id="modal--new-payment" className="with-borders modal-dialog modal--new-payment-react">
        <div className="modal-body">
          <Elements stripe={stripeObject}>
            <PaymentForm onClose={onClose} submitPaymentMethod={submitPaymentMethod} />
          </Elements>
        </div>
      </div>
    </ModalBase>
  );
}

PaymentMethodModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  submitPaymentMethod: PropTypes.func
};
