import { CREDIT_CARD_TENANT_TYPES } from '../../api/types/common';
import { AdsManagerEvent } from '../../events/events';

BaseController.$inject = [
  '$scope',
  '$rootScope',
  '$window',
  '$state',
  '$timeout',
  '$localStorage',
  'companyAndAccountFactory',
  'modalFactory',
  'campaignManagerFactory',
  'searchFactory',
  'tenantDetail',
  'featureFlagFactory',
  'universalAlert',
  'tenantFactory',
  'notificationFactory'
];

/**
 * @param {ng.IWindowService} $window
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {ng.ITimeoutService} $timeout
 * @param {import('../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {import('ngstorage').ngStorage.StorageService} $localStorage
 */
export function BaseController(
  $scope,
  $rootScope,
  $window,
  $state,
  $timeout,
  $localStorage,
  companyAndAccountFactory,
  modalFactory,
  campaignManagerFactory,
  searchFactory,
  tenantDetail,
  featureFlagFactory,
  universalAlert,
  tenantFactory,
  notificationFactory
) {
  var vm = this;
  vm.featureFlagFactory = featureFlagFactory;

  $rootScope.pageLoaded = true;
  $rootScope.openUrlWithSubsession = openUrlWithSubsession;
  vm.showMenu = false;
  vm.searchQuery = searchFactory.query;
  vm.toggleMenu = toggleMenu;
  vm.openState = openState;
  vm.openProfile = openProfile;
  vm.universalAlert = universalAlert;
  vm.openCreativeRepo = openCreativeRepo;
  vm.openCreativeRepoV2 = openCreativeRepoV2;
  vm.openReportScheduler = openReportScheduler;
  vm.openAccountSetting = openAccountSetting;
  vm.openDirectMailOrders = openDirectMailOrders;
  vm.openConditionsModal = openConditionsModal;
  vm.openHelpModal = openHelpModal;
  vm.logout = logout;
  vm.isAuthorizedForAnyApp = isAuthorizedForAnyApp;
  vm.search = search;
  vm.openCampaignCreationHelpWizardModal = openCampaignCreationHelpWizardModal;
  vm.currentUser = window.user;
  vm.showBillingMenu = CREDIT_CARD_TENANT_TYPES.includes(companyAndAccountFactory.selectedTenant?.payment_type);
  vm.hasTenantDashboardAccess = vm.featureFlagFactory.isFeatureEnabled('TENANT_DASHBOARD');
  vm.isHoldingCompany = companyAndAccountFactory.selectedTenant?.is_holding_company;
  vm.showDropdowns = true;
  vm.currentYear = new Date().getFullYear();

  $scope.$on('success-toast', toastHandler);
  $scope.$on('failed-toast', toastHandler);

  $scope.$on(AdsManagerEvent.UpdateTenantInformation, async (_event, tenantData) => {
    tenantDetail.company_name = tenantData.company_name;
    companyAndAccountFactory.selectedTenant.name = tenantData.company_name;
    companyAndAccountFactory.selectedTenant.company_address1 = tenantData.company_address1;
    companyAndAccountFactory.selectedTenant.company_address2 = tenantData.company_address2;
    companyAndAccountFactory.selectedTenant.company_city = tenantData.company_city;
    companyAndAccountFactory.selectedTenant.company_state = tenantData.company_state;
    companyAndAccountFactory.selectedTenant.company_zipcode = tenantData.company_zipcode;
    companyAndAccountFactory.selectedTenant.company_phoneNumber = tenantData.company_phoneNumber;

    let tenant_id = companyAndAccountFactory.selectedTenant?.tenant_id;
    await $state.go('tenantDashboard', { tenantId: tenant_id, accountId: null, companyId: null }, { reload: true });
  });

  function toastHandler(event, args) {
    vm.failedToast = event.name === 'failed-toast';
    vm.toastMsg = args.message;
    $timeout(function() {
      vm.toastMsg = null;
    }, 5000);
  }

  function openUrlWithSubsession(path) {
    $window.open(path + '?subsession=' + window.subsession, '_blank');
  }

  function toggleMenu() {
    vm.showMenu = !vm.showMenu;
  }

  function openState(statename, reload = false) {
    if (statename !== $state.current.name) {
      if (statename == 'campaigns.dashboard' && companyAndAccountFactory.canShowDashBoard()) {
        campaignManagerFactory.campaignsLoading = false;
        campaignManagerFactory.abort();
        $state.go(statename);
      } else if (statename === 'campaigns.opsDashboard') {
        $state.go(statename, undefined, { reload: true });
      } else if (statename !== 'campaigns.dashboard') {
        $state.go(statename, undefined, { reload });
      }
    }
  }

  function openProfile() {
    if (vm.currentUser.id != null) {
      $state.go('users.profile', { userId: vm.currentUser.id });
    }
  }

  function openCreativeRepo() {
    $state.go('creative-repository');
  }

  function openCreativeRepoV2() {
    $state.go('creative-repository-v2');
  }

  function openReportScheduler() {
    $state.go('report-scheduler');
  }

  function openDirectMailOrders() {
    $state.go('direct-mail-orders');
  }

  function openAccountSetting() {
    if (companyAndAccountFactory.selectedCompany != null) {
      if (companyAndAccountFactory.selectedAccount != null) {
        $state.go('account', {
          selectedCompanyId: companyAndAccountFactory.selectedCompany.id,
          selectedAccountId: companyAndAccountFactory.selectedAccount.id
        });
      } else {
        $state.go('company', { selectedCompanyId: companyAndAccountFactory.selectedCompany.id });
      }
    }
  }

  function openHelpModal() {
    modalFactory.welcome();
  }

  function openCampaignCreationHelpWizardModal() {
    modalFactory.campaignCreationHelpWizardModal({ state: $state.current.name, urlHash: $window.location.hash });
  }

  function logout() {
    $window.location.replace('/logout');
  }

  function isAuthorizedForAnyApp() {
    const authorizedApps = window.user?.authorized_apps || '';
    return ['CAB', 'RA', 'LM', 'LIP'].some(app => authorizedApps?.split(',').includes(app));
  }

  function openConditionsModal() {
    modalFactory.termsAndConditions();
  }

  function search(value = '') {
    if (value) {
      vm.searchQuery.text = value;
    }

    if (vm.searchQuery.text) {
      vm.onSearchFocusOut();
      $state.go('search', { q: vm.searchQuery.text, filter_by: 'account' });
    }
  }

  const saveOrgAndAccountSelectionInLocalStorage = () => {
    $localStorage.tenantDashboard.selectedCompanies = vm.selectedCompanies.map(company => company.id);
    $localStorage.tenantDashboard.selectedAccounts = vm.selectedAccounts.map(account => account.id);
  };

  /**
   * Since we're maintaining separate accounts list.
   * Need to copy over account selections
   * @param {Array} allAccounts
   * @param {Array} selectedAccounts
   */
  const copyAccountSelectionsToAllAccounts = (allAccounts, selectedAccounts) => {
    const selectedAccountsIdSet = new Set(selectedAccounts.map(account => account.id));
    return allAccounts.map(account => {
      if (selectedAccountsIdSet.has(account.id)) {
        account.checked = true;
      }
      return account;
    });
  };

  const updateCompaniesAndAccountsForTopBar = (selectedCompanies, id) => {
    // Based on the selected companies show all accounts in those companies
    let allAccountsForSelectedCompanies = selectedCompanies.map(company => company.accounts).flat();
    allAccountsForSelectedCompanies = copyAccountSelectionsToAllAccounts(
      allAccountsForSelectedCompanies,
      vm.selectedAccounts
    );
    vm.allAccountsForSelectedCompanies = angular.copy(allAccountsForSelectedCompanies);
    vm.selectedAccounts = vm.allAccountsForSelectedCompanies.filter(account => account.checked);

    // If a company is un-checked, all the accounts under that company are un-checked here
    const selectedCompaniesSet = new Set(selectedCompanies.map(company => company[id]));
    vm.allCompaniesForTenant = vm.allCompaniesForTenant.map(company => {
      company.accounts.forEach(account => {
        !selectedCompaniesSet.has(company[id]) ? (account.checked = false) : null;
      });
      return company;
    });
  };

  const notifyOfChange = () => {
    $rootScope.$broadcast('companyOrAccountChanged', {
      selectedCompanies: vm.selectedCompanies.map(company => company.id),
      selectedAccounts: vm.selectedAccounts.map(account => account.id)
    });
  };

  const updateSelectedItemsFromLocalStorage = (selected, poolOfAll) => {
    let result = [];
    const selectedItemsSet = new Set(selected);
    poolOfAll.forEach(item => {
      if (selectedItemsSet.has(item.id)) {
        item.checked = true;
        result.push(item);
      }
    });
    return result;
  };

  vm.onSearchFocus = () => {
    vm.showDropdowns = false;
  };

  vm.onSearchFocusOut = () => {
    vm.showDropdowns = true;
  };

  vm.handleLogoClick = () => {
    if (vm.featureFlagFactory.isFeatureEnabled('CAMPAIGN_OPS_DASHBOARD')) {
      vm.openState('campaigns.opsDashboard');
    } else if (vm.hasTenantDashboardAccess) {
      vm.openState('tenantDashboard', true);
    } else {
      vm.openState('campaigns.dashboard');
    }
  };

  vm.updateSelectedList = (item, id, selectedList) => {
    if (item.checked) {
      selectedList.push(item);
      return selectedList;
    } else {
      return selectedList.filter(checkedItem => item[id] !== checkedItem[id]);
    }
  };

  vm.isOnTenantDashboardPageWithAccess = () => {
    return vm.hasTenantDashboardAccess && $state.current.name === 'tenantDashboard';
  };

  vm.isOnCreativeRepo = () => {
    return $state.current.name === 'creative-repository';
  };

  /**
   * Showing old dropdown only on states related to campaigns
   */
  vm.showCompanyAccountSelectDropdown = () => $state.current.name?.includes('campaigns') ?? false;

  vm.changeTenant = async (selectedList, id) => {
    if (!selectedList.length) {
      return;
    }
    const tenantId = selectedList[0][id];
    if (tenantId === companyAndAccountFactory.selectedTenant?.[id]) {
      return;
    }

    const typesToRefresh = [
      'NO_VALID_CREDIT_CARD',
      'NO_VALID_ACCOUNT_LEVEL_CREDIT_CARD',
      'PAUSED_ACCOUNT_FOUND',
      'SPEND_LIMIT_TENANT'
    ];

    typesToRefresh.forEach(type => {
      notificationFactory.updateNotificationState(type, true);
    });

    // clear out selections of companies and accounts when changing tenants
    if ($localStorage.tenantDashboard) {
      $localStorage.tenantDashboard.selectedCompanies = [];
      $localStorage.tenantDashboard.selectedAccounts = [];
    }
    await $state.go('tenantDashboard', { tenantId: tenantId, accountId: null, companyId: null }, { reload: true });
    $rootScope.$broadcast('updateTenantCompanyAccount', tenantId);
  };

  vm.isOnAdgroupPages = () => {
    return $state.current.name === 'campaigns.adgroup' && !!campaignManagerFactory.selectedAdGroup;
  };

  vm.changeCompanies = async (selectedCompanies, id) => {
    updateCompaniesAndAccountsForTopBar(selectedCompanies, id);
    vm.accountDropdownSelectedTitle = vm.getSelectedTitle('Account', 'name', vm.selectedAccounts);
    saveOrgAndAccountSelectionInLocalStorage();
    notifyOfChange();
  };

  vm.changeAccounts = async () => {
    saveOrgAndAccountSelectionInLocalStorage();
    notifyOfChange();
  };

  vm.getSelectedTitle = (title, displayProp, selectedList) => {
    // Used to change dropdown title based on selectedList
    if (selectedList.length) {
      title = selectedList.map(item => item[displayProp]).join(', ');
    }
    return title;
  };

  vm.initTenantDashboardTopBarData = () => {
    vm.showTenantDropdown = vm.currentUser.is_sudoer;
    vm.allTenantsForUser = companyAndAccountFactory.tenants.map(t => {
      // previous checked tenant needs to be reset in order for it be selected again
      t.checked = false;
      return t;
    });
    if (companyAndAccountFactory.selectedTenant) {
      companyAndAccountFactory.selectedTenant.checked = true;
    }
    vm.selectedTenant = [companyAndAccountFactory.selectedTenant];

    if (!$localStorage.tenantDashboard) {
      $localStorage.tenantDashboard = {};
    }

    /**
     * Tenant can be changed outside of tenant dashboard page.
     * So, this way if the tenant was changed, we clear out previous selections
     * of companies and accounts.
     */
    if (
      $localStorage.tenantDashboard &&
      $localStorage.tenantDashboard.tenantId &&
      $localStorage.tenantDashboard.tenantId !== companyAndAccountFactory.selectedTenant?.tenant_id
    ) {
      $localStorage.tenantDashboard.selectedCompanies = [];
      $localStorage.tenantDashboard.selectedAccounts = [];
    }

    $localStorage.tenantDashboard.tenantId = companyAndAccountFactory.selectedTenant?.tenant_id;

    vm.allCompaniesForTenant = angular.copy(companyAndAccountFactory.companiesAccounts);
    vm.selectedCompanies =
      $localStorage.tenantDashboard && $localStorage.tenantDashboard.selectedCompanies
        ? $localStorage.tenantDashboard.selectedCompanies
        : [];

    vm.selectedCompanies = updateSelectedItemsFromLocalStorage(vm.selectedCompanies, vm.allCompaniesForTenant);

    const allAccountsForSelectedCompanies = vm.selectedCompanies.map(company => company.accounts).flat();
    vm.allAccountsForSelectedCompanies = angular.copy(allAccountsForSelectedCompanies);
    vm.selectedAccounts =
      $localStorage.tenantDashboard && $localStorage.tenantDashboard.selectedAccounts
        ? $localStorage.tenantDashboard.selectedAccounts
        : [];

    vm.selectedAccounts = updateSelectedItemsFromLocalStorage(vm.selectedAccounts, vm.allAccountsForSelectedCompanies);

    saveOrgAndAccountSelectionInLocalStorage();
    vm.tenantDropdownSelectedTitle = vm.getSelectedTitle('Tenant', 'name', vm.selectedTenant);
    vm.companyDropdownSelectedTitle = vm.getSelectedTitle('Organization', 'name', vm.selectedCompanies);
    vm.accountDropdownSelectedTitle = vm.getSelectedTitle('Account', 'name', vm.selectedAccounts);
  };

  vm.hasTenantDashboardAccess ? vm.initTenantDashboardTopBarData() : null;

  vm.init = async () => {
    // if they are a non-CC tenant check if they have past statements to view
    if (!vm.showBillingMenu) {
      vm.showBillingMenu = await tenantFactory.tenantHasPastBillingStatements();
    }

    await notificationFactory.getNotifications();
  };

  vm.clearSearchText = () => {
    searchFactory.clearSearchQueryText();
  };

  vm.init();
}
