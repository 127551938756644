/**
 *  SimpleAlert Modal
 *
 *  Data:
 *    class
 *    title
 *    message
 *    okText (optional)
 *
 *  Handlers: none
 *
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 * @ngInject
 */
export function modalSimpleAlertController($uibModalInstance, data) {
  var vm = this;

  vm.data = data;
  vm.close = close;

  function close() {
    $uibModalInstance.close();
  }
}
