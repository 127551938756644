import * as React from 'react';
import { BaseTable, SortingDirection } from './BaseTable';
import { VirtualList } from '../../../utils/virtuallist';

import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/styles';
import { isPromise } from '../../../factories/general_utils';
import { Pagination } from './Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { primary3Palette } from '../styles/colorConstants';
import { withStyles } from '@material-ui/core';

export interface Column<T> {
  title: React.ReactNode;
  selector: (item: T) => React.ReactNode;
  className?: string;
  /** Will update the items props */
  onSort?: () => void;
  /**
   * When sortedDirection is null the column is not sorted
   * @default: null
   */
  sortedDirection?: SortingDirection | null;
}

export { SortingDirection } from './BaseTable';

interface TableViewProps<T> {
  itemsVirtualList: VirtualList<T>;
  emptyTableMessage?: string;
  columns: Column<T>[];
  /**
   * On Items per page change
   */
  onItemsPerPageChange: (itemsPerPage: number) => void;
  /**
   * Items per page dropdown value
   */
  itemsPerPage: number;
  className?: string;
}

const ItemsPerPageSelect = styled('select')({
  display: 'inline-block !important'
});

const PerPageText = styled('span')({
  marginLeft: '16px',
  color: 'var(--color-on-primary)',
  marginRight: '32px',
  fontFamily: 'var(--font-family-regular)',
  fontSize: 'var(--font-size-regular)',
  fontWeight: 'var(--font-weight-bold)',
  lineHeight: 'var(--line-height)'
});

const StyledCircularProgress = withStyles({
  root: {
    padding: 10
  }
})(CircularProgress);

const NoItemsMessage = styled('span')({
  color: 'var(--color-on-primary)',
  textAlign: 'center',
  fontFamily: 'var(--font-family-regular)',
  fontSize: 'var(--font-size-regular)',
  fontWeight: 'var(--font-weight-regular)',
  lineHeight: 'var(--line-height)'
});

export function TableView<T>(props: TableViewProps<T>) {
  const { itemsVirtualList, columns, emptyTableMessage = 'No Items' } = props;

  const [currentPage, setPage] = React.useState(0);
  const [items, setItems] = React.useState<T[] | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [totalPages, setTotalPages] = React.useState(0);
  const numberOfItemsPerPages = [10, 15, 25];

  const onItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.onItemsPerPageChange(parseInt(e.target.value));
  };

  const loadItems = async () => {
    let itemCount: number;
    let items: T[];

    const getItemCount = itemsVirtualList.getCount();

    if (isPromise(getItemCount)) {
      setLoading(true);
      itemCount = await getItemCount;
    } else {
      itemCount = getItemCount;
    }

    let computedTotalPages = Math.ceil(itemCount / props.itemsPerPage);
    setTotalPages(computedTotalPages);
    if (computedTotalPages && currentPage >= computedTotalPages) {
      /**
       * If we can go back one page we will do that. This usually would happen when we delete an item.
       * Otherwise we will set the current page to 1
       */

      const computedCurrentPage = currentPage - 1 < computedTotalPages ? currentPage - 1 : 0;
      setPage(computedCurrentPage);
    }

    let itemsResult = itemsVirtualList.getItems(currentPage * props.itemsPerPage, props.itemsPerPage);

    try {
      if (isPromise(itemsResult)) {
        setLoading(true);
        items = await itemsResult;
      } else {
        items = itemsResult;
      }

      setItems(items);
    } catch (e) {
      console.error('There was an error loading the table: ', e);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadItems();
  }, [props.itemsVirtualList, currentPage, props.itemsPerPage]);

  React.useEffect(() => {
    setPage(0);
  }, [props.itemsPerPage]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" className={props.className}>
      <BaseTable items={items ? items : []} columns={columns}></BaseTable>
      {items && items.length === 0 && !loading && (
        <NoItemsMessage className="no-search-results">{emptyTableMessage}</NoItemsMessage>
      )}
      {loading && <StyledCircularProgress color="secondary" />}
      <div className="pagination-select report-pagination-select">
        {items && items.length !== 0 && (
          <>
            <ItemsPerPageSelect onChange={onItemsPerPageChange} value={props.itemsPerPage}>
              {numberOfItemsPerPages.map(numberOfItem => (
                <option key={numberOfItem} value={numberOfItem}>
                  {numberOfItem}
                </option>
              ))}
            </ItemsPerPageSelect>
            <PerPageText>per page</PerPageText>
            <Pagination totalPages={totalPages} activeIndex={currentPage} onChange={setPage} />
          </>
        )}
      </div>
    </Box>
  );
}
