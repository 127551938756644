import { http } from '../def';
import {
  PostRequest,
  PostResponse,
  PutRequest,
  PutResponse,
  PutStatusRequest,
  DeleteRequest,
  DeleteResponse,
  PostFetchAssetsRequest,
  PostFetchAssetsResponse
} from '../types/creativeAssets';

export const postApi = http.post`/data/creative_assets`.acceptsJsonOf(PostRequest).returnsJsonOf(PostResponse);

export const postFetchAssetsApi = http.post`/data/creative_assets/v2`
  .acceptsJsonOf(PostFetchAssetsRequest)
  .returnsJsonOf(PostFetchAssetsResponse);

export const putApi = http.put<number>`/data/creative_assets/${id => id}`
  .acceptsJsonOf(PutRequest)
  .returnsJsonOf(PutResponse);

export const deleteApi = http.post`/data/creative_assets/deletions`
  .acceptsJsonOf(DeleteRequest)
  .returnsJsonOf(DeleteResponse);

export const putStatusApi = http
  .put<number>(creativeAssetId => `data/campaigns/creatives/status/asset/${creativeAssetId}`)
  .acceptsJsonOf(PutStatusRequest)
  .returnsJsonOf(PutResponse);
