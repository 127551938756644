import template from './adgroup_targeting_audience.html';
import { AdGroupTargeting } from '../../../../api/http/adgroup';
import { AbstractAdgroupTargetingController } from '../adgroup_targeting_base/abstract_adgroup_targeting';
import { AdgroupTargetingService } from '../../adgroup_targeting.serv';
import {
  CampaignManagerService,
  CompanyAndAccountFactoryService,
  FeatureFlagService,
  SearchService
} from '../../../../factories/types';
import { safeApply } from '../../../../factories/general_utils';

interface Value {
  value: string;
}

export interface LooklikeAudienceType {
  label: string;
  value: string;
}

export function adgroupTargetingAudience(): ng.IDirective {
  return {
    template: template,
    bindToController: true,
    controller: AdgroupTargetingAudienceController,
    controllerAs: 'vm',
    restrict: 'E',
    link: () => {},
    scope: {
      adgroupTargeting: '=',
      checkSensitivityForMultiple: '=',
      saveSelectedAudiences: '=',
      selectionTargetingTableChanged: '=',
      getLocationGroupsStatus: '=',
      openUploadModal: '='
    }
  };
}

export class AdgroupTargetingAudienceController extends AbstractAdgroupTargetingController implements ng.IOnInit {
  hasCabAccess = false;
  hasAdGroupWriteAccess = false;
  lookalikeAudienceOptionsOrder: LooklikeAudienceType[] = [];
  featureFlagFactory: FeatureFlagService;
  adgroupTargeting: AdGroupTargeting = {};
  selectedLookalikeAudience?: LooklikeAudienceType;
  locationManagerURL = '';
  audienceManagerURL = '';
  selectedAdgroupId = null;
  selectedCampaignId = null;

  /* @ngInject */
  constructor(
    $scope: ng.IScope,
    companyAndAccountFactory: CompanyAndAccountFactoryService,
    adgroupTargetingService: AdgroupTargetingService,
    searchFactory: SearchService,
    featureFlagFactory: FeatureFlagService,
    campaignManagerFactory: CampaignManagerService
  ) {
    super($scope, companyAndAccountFactory, adgroupTargetingService, searchFactory, campaignManagerFactory);
    this.lookalikeAudienceOptionsOrder = adgroupTargetingService.getOrderedLookalikeAudienceOptions();
    this.hasCabAccess = adgroupTargetingService.isFeatureEnabled('CAB_ACCESS');
    this.hasAdGroupWriteAccess = adgroupTargetingService.isFeatureEnabled('ADGROUP_WRITE');
    this.featureFlagFactory = featureFlagFactory;
    this.campaignManagerFactory = campaignManagerFactory;
  }

  getAdgroupTargeting() {
    return this.adgroupTargeting;
  }

  $onInit() {
    this.locationManagerURL = `//${window.locationManagerURL}`;
    this.audienceManagerURL = `//${window.audienceManagerURL}`;

    if (this.adgroupTargeting?.lookalikeAudienceScale) {
      this.selectedLookalikeAudience = this.adgroupTargetingService.getLookalikeAudienceOption(
        this.adgroupTargeting.lookalikeAudienceScale
      );
    }
    this.showAudienceExclude = !!this.adgroupTargeting?.audience_exclude?.length;
    this.locationFilterSearchPlaceholder = this.adgroupTargetingService.getLocationFilterSearchPlaceholder(
      this.adgroupTargeting
    );
    this.selectedAdgroupId = this.campaignManagerFactory.selectedAdGroup?.id;
    this.selectedCampaignId = this.campaignManagerFactory.selectedCampaign?.id;
  }

  toggleEnableLookalike = () => {
    if (this.adgroupTargeting.lookalikeAudienceScale) {
      this.adgroupTargeting.lookalikeAudienceScale = null;
    } else {
      this.adgroupTargeting.lookalikeAudienceScale = '1';
      this.selectedLookalikeAudience = this.adgroupTargetingService.getLookalikeAudienceOption('1');
    }
  };

  selectLookalikeAudienceScale = (selectedLookalikeAudience: Value) => {
    this.adgroupTargeting.lookalikeAudienceScale = selectedLookalikeAudience.value;
  };

  updateAudiences = async (audiences: [], resetSelectionCallback: Function, selectedAudienceIds: Set<string>) => {
    //@ts-ignore
    const consentProvided = await this.scope.$parent.checkSensitivityForMultiple(
      audiences,
      'selectionTargetingTableChanged',
      audiences,
      false
    );
    if (consentProvided || audiences.length === 0) {
      this.adgroupTargeting.audience = audiences;
    } else {
      //reset audiences selected
      resetSelectionCallback(this.adgroupTargeting.audience);
    }
    safeApply(this.scope);
  };

  handleExcludeAudienceChange = () => {
    if (!this.adgroupTargeting.showAudienceExclude) {
      this.adgroupTargeting.audience_exclude = [];
    }
    safeApply(this.scope);
  };

  updateExcludeAudiences = excluded_audiences => {
    this.adgroupTargeting.audience_exclude = excluded_audiences;
    safeApply(this.scope);
  };
}
