import * as React from 'react';
import { deleteColor } from '../styles/colorConstants';
import { styled } from '../../../utils/react/mui_styled';
import { PrimaryButton, PrimaryButtonProps } from './PrimaryButton';

export type DangerButtonProps = PrimaryButtonProps;

const StyledPrimaryButton = styled(PrimaryButton)({
  background: deleteColor,
  '&:hover': {
    background: deleteColor
  }
});

export const DangerButton = React.forwardRef<HTMLButtonElement, DangerButtonProps>(function DangerButton(props, ref) {
  return <StyledPrimaryButton ref={ref} {...props} />;
});
DangerButton.displayName = 'DangerButton';
