gtAutocomplete.$inject = ['$timeout'];

export function gtAutocomplete($timeout) {
  var directive = {
    templateUrl: '/ui/templates/static-templates/28d8fc8de204417ea0893277f48c37ff.gt-autocomplete.html',
    bindToController: true,
    controller: GtAutocompleteController,
    controllerAs: 'vm',
    link: link,
    restrict: 'E',
    require: '^?form',
    scope: {
      queryFn: '&',
      callbackFn: '&',
      parseItemByKeys: '=?', // Default: ['name']
      parseItemSeparator: '@', // Default: ' '
      selectType: '@', // Default: 'single' Options: 'multi'
      tabbed: '=',
      isDisabled: '=',
      isRequired: '=',
      selectedItem: '=',
      inputName: '@',
      placeholder: '@', // Default: 'Search'
      inputMinlength: '=?', // Default: 0
      inputDelay: '=?', // Default: 300
      chipSkin: '@', // Options: green, gray, red, blue
      hideChips: '='
    }
  };
  return directive;

  function link(scope, element, attrs, formCtrl) {
    var KEY_CODE = {
      TAB: 9,
      ENTER: 13,
      ESCAPE: 27,
      LEFT_ARROW: 37,
      UP_ARROW: 38,
      RIGHT_ARROW: 39,
      DOWN_ARROW: 40
    };

    var input = element.find('input')[0];
    var inputElem = angular.element(input);

    scope.vm.scrollLeft = scrollLeft;
    scope.vm.scrollRight = scrollRight;
    scope.vm.checkShowArrows = checkShowArrows;

    if (formCtrl && formCtrl[scope.vm.inputName] && scope.vm.isRequired) {
      scope.$watch(
        'vm.selectedItem',
        function() {
          var isValid = scope.vm.isMultiType ? scope.vm.selectedItem.length > 0 : !!scope.vm.selectedItem;
          formCtrl[scope.vm.inputName].$setValidity('required', isValid);
        },
        true
      );
    }

    function checkShowArrows() {
      // timeout to make sure the element loaded first
      $timeout(function() {
        var tabsContainer = element[0].querySelector('.nav-tabs');
        var tabs = angular.element(tabsContainer).find('li');
        var i;
        var totalWidth = 0;

        if (!tabs.length) return;

        for (i = 0; i < tabs.length; i++) {
          totalWidth += /** @type {HTMLElement} */ (tabs[i]).offsetWidth;
        }

        scope.vm.showArrows = tabsContainer.offsetWidth < totalWidth;
      });
    }

    function updateVerticalScroll() {
      var listElemClassName = scope.vm.tabbed ? '.tab-content' : '.autocomplete-suggestions';
      var listElem = element[0].querySelector(listElemClassName);
      // for tabbed autocomplete, list is hosted under tab-content's child tab-pane,
      // hence the need to check li container
      var liContainer = scope.vm.tabbed ? element[0].querySelector('.tab-pane.active') : listElem;
      var firstLiElem = /** @type {HTMLElement | undefined} */ (angular.element(liContainer).find('li')[0]);

      if (!firstLiElem) return;

      var height = firstLiElem.offsetHeight;
      var top = height * scope.vm.highlightedIndex;
      var bot = top + height;
      var hgt = listElem.clientHeight;
      var scrollTop = listElem.scrollTop;

      if (top < scrollTop) {
        listElem.scrollTop = top;
      } else if (bot > scrollTop + hgt) {
        listElem.scrollTop = bot - hgt;
      }
    }

    function updateHorizontalScroll() {
      var tabsContainer = element[0].querySelector('.nav-tabs');
      var tabs = angular.element(tabsContainer).find('li');
      var i;
      var currentWidth = 0;

      if (!tabs.length) return;

      for (i = 0; i < scope.vm.activeTabIndex; i++) {
        currentWidth += /** @type {HTMLElement} */ (tabs[i]).offsetWidth;
      }

      tabsContainer.scrollLeft = currentWidth;
    }

    function scrollLeft() {
      if (scope.vm.isLoading) return;

      if (scope.vm.tabbed) {
        event?.stopPropagation();
        event?.preventDefault();

        scope.vm.highlightedIndex = 0;
        scope.vm.activeTabIndex = scope.vm.activeTabIndex === 0 ? 0 : Math.max(0, scope.vm.activeTabIndex - 1);
        scope.vm.tabs[scope.vm.activeTabIndex].active = true;

        updateVerticalScroll();
        updateHorizontalScroll();
      }
    }

    function scrollRight() {
      if (scope.vm.isLoading) return;

      if (scope.vm.tabbed) {
        event?.stopPropagation();
        event?.preventDefault();

        scope.vm.highlightedIndex = 0;
        scope.vm.activeTabIndex = Math.min(scope.vm.activeTabIndex + 1, scope.vm.tabs.length - 1);
        scope.vm.tabs[scope.vm.activeTabIndex].active = true;

        updateVerticalScroll();
        updateHorizontalScroll();
      }
    }

    function keyListener(event) {
      var key = event.keyCode;

      if (key === KEY_CODE.ENTER) {
        event.stopPropagation();
        event.preventDefault();

        scope.vm.selectItem(scope.vm.highlightedIndex);
        input.blur();
      } else if (key === KEY_CODE.TAB) {
        scope.vm.selectItem(scope.vm.highlightedIndex);
        input.blur();
      } else if (key === KEY_CODE.ESCAPE) {
        event.stopPropagation();
        event.preventDefault();

        scope.vm.closeDropdown();
        input.blur();
      } else if (key === KEY_CODE.UP_ARROW) {
        if (scope.vm.isLoading) return;
        event.stopPropagation();
        event.preventDefault();

        scope.vm.highlightedIndex = scope.vm.highlightedIndex === 0 ? 0 : Math.max(0, scope.vm.highlightedIndex - 1);

        updateVerticalScroll();
      } else if (key === KEY_CODE.DOWN_ARROW) {
        if (scope.vm.isLoading) return;
        event.stopPropagation();
        event.preventDefault();

        if (scope.vm.tabbed) {
          scope.vm.highlightedIndex = Math.min(
            scope.vm.highlightedIndex + 1,
            scope.vm.searchResults[scope.vm.tabs[scope.vm.activeTabIndex].key].length - 1
          );
        } else {
          scope.vm.highlightedIndex = Math.min(scope.vm.highlightedIndex + 1, scope.vm.searchResults.length - 1);
        }

        updateVerticalScroll();
      } else if (key === KEY_CODE.LEFT_ARROW) {
        scrollLeft();
      } else if (key === KEY_CODE.RIGHT_ARROW) {
        scrollRight();
      }

      scope.$apply();
    }

    inputElem.on('keydown', keyListener);

    scope.$on('$destroy', function() {
      inputElem.off('keydown', keyListener);
    });
  }
}

GtAutocompleteController.$inject = ['$scope'];

function GtAutocompleteController($scope) {
  var vm = this;

  this.$onInit = () => {
    // setting default config
    vm.placeholder = vm.placeholder || 'Search';
    vm.isMultiType = vm.selectType === 'multi';
    vm.inputMinlength = vm.inputMinlength || 0;
    vm.inputDebounce = { debounce: vm.inputDelay || 300 };
    vm.parseItemByKeys = vm.parseItemByKeys || ['name'];
    vm.parseItemSeparator = vm.parseItemSeparator || ' ';
    vm.chipSkin = vm.chipSkin || '';

    vm.isLoading = false;
    vm.showDropdown = false;
    vm.noResult = false;

    vm.selectedItem =
      vm.selectedItem && !angular.equals({}, vm.selectedItem) ? vm.selectedItem : vm.isMultiType ? [] : null;

    vm.parseItem = parseItem;
    vm.closeDropdown = closeDropdown;
    vm.getQueryResult = getQueryResult;
    vm.removeSelectedItem = removeSelectedItem;
    vm.selectItem = selectItem;
    vm.selectTab = selectTab;

    reset();
  };

  $scope.$watch('vm.selectedItem', setSearchText);

  function reset() {
    vm.highlightedIndex = 0;
    vm.activeTabIndex = 0;
    vm.searchResults = null;
    vm.noResult = false;
    setSearchText();
  }

  function setSearchText() {
    vm.searchText = !vm.isMultiType && vm.selectedItem ? vm.parseItem(vm.selectedItem) : '';
  }

  function parseItem(item) {
    var parsedKeys = vm.parseItemByKeys.map(function(key) {
      if (!item.hasOwnProperty(key)) {
        throw 'GT autocomplete error: selected item does not have "' + key + '" key';
      }
      return item[key];
    });

    return parsedKeys.join(vm.parseItemSeparator);
  }

  function closeDropdown() {
    vm.showDropdown = false;

    reset();
  }

  function getQueryResult() {
    if (!vm.searchText && !vm.isMultiType) {
      vm.selectedItem = null;
    }

    if (vm.searchText.length < vm.inputMinlength) return;

    vm.noResult = false;
    vm.isLoading = true;

    // Get search data & process.
    vm.queryFn({ query: vm.searchText })
      .then(
        function(data) {
          vm.searchResults = data;

          if (!vm.searchResults || angular.equals({}, vm.searchResults) || angular.equals([], vm.searchResults)) {
            vm.noResult = true;
          } else {
            if (vm.tabbed) {
              parseTabbedResults();
            }
          }
        },
        function() {
          vm.noResult = true;
        }
      )
      .finally(function() {
        vm.isLoading = false;
        vm.showDropdown = true;

        if (vm.tabbed) {
          vm.checkShowArrows();
        }
      });
  }

  function parseTabbedResults() {
    vm.tabs = [];
    var key;

    for (key in vm.searchResults) {
      if (vm.searchResults.hasOwnProperty(key)) {
        vm.tabs.push({
          key: key,
          text: key + ' (' + vm.searchResults[key].length + ')',
          active: vm.tabs.length === 0
        });
      }
    }
  }

  function removeSelectedItem(index) {
    vm.selectedItem.splice(index, 1);
  }

  function selectItem(index) {
    var item = vm.tabbed ? vm.searchResults[vm.tabs[vm.activeTabIndex].key][index] : vm.searchResults[index];

    if (item === undefined) {
      vm.closeDropdown();
      return;
    }
    if (vm.isMultiType) {
      var duplicate = false;
      for (var selected in vm.selectedItem) {
        if (vm.selectedItem[selected].type === item.type && vm.selectedItem[selected].name === item.name) {
          duplicate = true;
        }
      }

      if (!duplicate) {
        vm.selectedItem.push(item);
      }
    } else {
      vm.selectedItem = item;
    }

    vm.closeDropdown();

    if (typeof vm.callbackFn === 'function') {
      vm.callbackFn({ selected: vm.selectedItem });
    }
  }

  function selectTab(index) {
    vm.activeTabIndex = index;
  }
}
