import { AdsManagerEvent } from '../../../events/events';
import { NotificationTypes } from '../../../api/types/common';

tenantEditController.$inject = [
  '$scope',
  '$q',
  'generalUtilsFactory',
  'tenantFactory',
  'staticDataFactory',
  'featureFlagFactory'
];

/**
 * @param {ng.IQService} $q
 * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
 */
export function tenantEditController(
  $scope,
  $q,
  generalUtilsFactory,
  tenantFactory,
  staticDataFactory,
  featureFlagFactory
) {
  $scope.featureFlagFactory = featureFlagFactory;

  $scope.tenantDetails = {
    company_name: null,
    company_website: null,
    company_address1: null,
    company_address2: null,
    company_city: null,
    company_state: null,
    company_zipcode: null,
    company_country: null,
    company_phoneNumber: null,
    contact_person_name: null,
    contact_person_email: null,
    currency: null,
    serving_country: null
  };

  $scope.booleans = {
    saveTenantProgress: null
  };

  $scope.errorMsg = {
    tenantSaveResponse: ''
  };

  $scope.countries = {};
  $scope.countryStates = [];
  $scope.excludeNotificationTypes = [NotificationTypes.NO_VALID_ACCOUNT_LEVEL_CREDIT_CARD];

  $scope.init = function() {
    var countryDfd = staticDataFactory.getCountries();
    var countryStateDfd = staticDataFactory.getCountryStates();

    $q.all([countryDfd, countryStateDfd]).then(function(data) {
      $scope.countries = staticDataFactory.countries;

      generalUtilsFactory.nestedUpdateInPlace(tenantFactory.tenant, $scope.tenantDetails);

      if (tenantFactory.tenant.serving_country && tenantFactory.tenant.serving_country.hasOwnProperty('displayName')) {
        $scope.tenantDetails.serving_country = tenantFactory.tenant.serving_country.displayName;
      }

      if (tenantFactory.tenant.company_country && tenantFactory.tenant.company_country.hasOwnProperty('code')) {
        for (var key in $scope.countries) {
          if (tenantFactory.tenant.company_country.code == key) {
            $scope.tenantDetails.company_country = $scope.countries[key];
            break;
          }
        }
      }

      $scope.updateCountry(tenantFactory.tenant.company_state);
    });
  };

  $scope.updateCountry = function(state) {
    if ($scope.tenantDetails.company_country && $scope.tenantDetails.company_country.hasOwnProperty('code')) {
      $scope.countryStates = staticDataFactory.countryStates[$scope.tenantDetails.company_country.code];
      $scope.updateState(state);
    }
  };

  $scope.updateState = function(state) {
    if (state == null) {
      $scope.tenantDetails.company_state = $scope.countryStates[0];
    } else {
      for (var i = 0; i < $scope.countryStates.length; i++) {
        if (state == $scope.countryStates[i].stateCode) {
          $scope.tenantDetails.company_state = $scope.countryStates[i];
          break;
        }
      }
    }
  };

  $scope.saveTenant = function() {
    $scope.booleans.saveTenantProgress = true;
    $scope.errorMsg.tenantSaveResponse = '';

    tenantFactory
      .saveTenantDetails($scope.tenantDetails)
      .then(
        function(data) {
          generalUtilsFactory.nestedUpdateInPlace(data, $scope.tenantDetails);
          $scope.updateState(data.company_state);
          $scope.$emit('success-toast', { message: 'Updated successfully.' });
          $scope.$emit(AdsManagerEvent.UpdateTenantInformation, data);
        },
        function(data) {
          $scope.errorMsg.tenantSaveResponse = data;
        }
      )
      .finally(function() {
        $scope.booleans.saveTenantProgress = false;
      });
  };

  $scope.init();
}
