import {
  getCreativeAssetAssociatedAdgroups,
  postCreativeAssets,
  deleteCreativeAssets,
  putCreativeAssetStatus,
  putCreativeAsset,
  postFetchCreativeAssets
} from '../../api/http';
import { send$http } from '../../api/def/ngExecutor';
import { UploadedFile } from '../../api/types/creativeAssets';

export class creativeRepositoryService {
  private $http: ng.IHttpService;

  /** @ngInject */
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  async insertIntoCreativeRepository(assetsToInsert: UploadedFile[]) {
    const data = await send$http(this.$http, postCreativeAssets, { data: { creative_assets: assetsToInsert } });
    return data.result;
  }

  async getCreativeAssetList({
    sort_by = '',
    sort_desc = false,
    page = 1,
    limit = 10,
    organization_ids = [],
    asset_types = [],
    status = [],
    size = [],
    account_ids = [],
    search = '',
    asset_type = '',
    full_load = true
  }) {
    const body = {
      sort_by: sort_by,
      sort_desc: sort_desc,
      page: page,
      limit: limit,
      organization_ids: organization_ids,
      asset_types: asset_types,
      status: status,
      size: size,
      account_ids: account_ids,
      search: search,
      asset_type: asset_type,
      full_load: full_load
    };
    return await send$http(this.$http, postFetchCreativeAssets, { data: body });
  }

  async getAssociatedAdgroups(asset_id: number) {
    const data = await send$http(this.$http, getCreativeAssetAssociatedAdgroups, { params: asset_id });
    return data.adgroups;
  }

  async changeCreativeAssetName(creativeAssetId: number, creativeAssetName: string) {
    const data = await send$http(this.$http, putCreativeAsset, {
      params: creativeAssetId,
      data: {
        asset_name: creativeAssetName
      }
    });
    return data;
  }

  async pauseCreativeAsset(creativeAssetId: number) {
    const data = await send$http(this.$http, putCreativeAssetStatus, {
      params: creativeAssetId,
      data: {
        status: 'paused'
      }
    });
    return data;
  }

  async deleteCreative(creativeAssetIds: number[]) {
    const data = await send$http(this.$http, deleteCreativeAssets, { data: { asset_ids: creativeAssetIds } });
    return data;
  }
}
