import { send$http } from '../api/def/ngExecutor';
import { get_ad_group_location_filter_sensitivity_api } from '../api/http/data.generated';

xadcmsFactory.$inject = ['$q', '$http'];

export function xadcmsFactory($q, $http) {
  var _promiseStore = {};

  var getUsersAutoComplete = function(query, group) {
    var d = $q.defer();
    $http({ method: 'GET', url: '/data/users', timeout: d.promise, params: { query: query, group: group } }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject('Something went wrong');
      }
    );
    return d.promise;
  };

  var getCampaignsWithBudgetsForAccount = function(campaignId, adGroupId) {
    var d = $q.defer();

    const params =
      campaignId || adGroupId
        ? {
            campaignId: campaignId,
            adGroupId: adGroupId
          }
        : {
            fullLoad: 0
          };

    $http({ method: 'GET', url: '/data/campaigns', params: params })
      .then(function({ data, status, headers, config }) {
        d.resolve(data.campaigns);
      })
      .catch(function({ data, status, headers, config }) {
        d.resolve({});
      });
    return d.promise;
  };

  var addPromise = function(campaignId, promise) {
    if (!(campaignId in _promiseStore)) {
      _promiseStore[campaignId] = promise;
    }
  };

  var removePromise = function(campaignId) {
    var promise;
    if (campaignId in _promiseStore) {
      promise = _promiseStore[campaignId];
      delete _promiseStore[campaignId];
    }
    return promise;
  };

  var abort = function(campaignId) {
    var d = removePromise(campaignId);

    if (d) {
      d.resolve({});
      return d.promise;
    }
  };

  var getCampaignWithAdGroups = function(campaignId, adGroupId) {
    var d = $q.defer();

    addPromise(campaignId, d);

    var params = {};

    if (campaignId || adGroupId) {
      params = {
        campaignId: campaignId,
        adGroupId: adGroupId
      };
    }

    $http({ method: 'GET', url: '/data/campaigns', params: params, timeout: d.promise })
      .then(function({ data, status, headers, config }) {
        removePromise(campaignId);
        d.resolve(data.campaigns);
      })
      .catch(function({ data, status, headers, config }) {
        removePromise(campaignId);
        d.reject({});
      });
    return d.promise;
  };

  var getAdGroupSettingsData = function(campaignId, adGroupId) {
    var d = $q.defer();
    $http({ method: 'GET', url: `/data/campaigns/${campaignId}/adgroups/${adGroupId}` })
      .then(function({ data, status, headers, config }) {
        d.resolve(data.campaigns);
      })
      .catch(function({ data, status, headers, config }) {
        d.resolve({});
      });
    return d.promise;
  };

  var getAdGroupLocationGroupSensitivityData = function(campaignId, adGroupId) {
    const params = {
      campaign_id: campaignId,
      adgroup_id: adGroupId
    };

    return send$http($http, get_ad_group_location_filter_sensitivity_api, { params });
  };

  return {
    abort: abort,
    getCampaignsWithBudgetsForAccount: getCampaignsWithBudgetsForAccount,
    getCampaignWithAdGroups: getCampaignWithAdGroups,
    getAdGroupSettingsData: getAdGroupSettingsData,
    getAdGroupLocationGroupSensitivityData: getAdGroupLocationGroupSensitivityData,
    getUsersAutoComplete: getUsersAutoComplete
  };
}
