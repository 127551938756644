/**
 * Formats a number for display.
 * If the number is greater than or equal to 1 million, it is divided by 1 million and displayed with 'M' appended.
 * If the number is greater than or equal to 1 thousand but less than 1 million, it is divided by 1 thousand and displayed with 'K' appended.
 * If the number is less than 1 thousand, it is displayed as `<1K`.
 *
 * @param {number} num - The number to format.
 * @returns {string} The formatted number.
 */

export function formatNumber(num) {
  if (!num) {
    return '';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(2) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(2) + 'K';
  } else if (num === 0) {
    return '';
  } else {
    return '<1K';
  }
}
