export function capitalize() {
  return function(input, remove) {
    if (!angular.isString(input)) {
      return input;
    }

    if (remove) {
      var re = new RegExp(remove, 'g');
      input = input.replace(re, ' ');
    }

    return input.toLowerCase().replace(/(^| )[a-z]/g, function(letter) {
      return letter.toUpperCase();
    });
  };
}
