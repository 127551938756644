import * as React from 'react';
import { react2angular } from 'react2angular';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { gtTheme } from './styles';

type ReactComponentMap = {
  [key: string]: React.ComponentType<any>;
};

type AngularComponentMap = {
  [key: string]: ng.IComponentOptions;
};

export function reactToAngularComponents(components: ReactComponentMap): AngularComponentMap {
  const angularComponents: AngularComponentMap = {};

  Object.keys(components).forEach(key => {
    const componentKey = key[0].toUpperCase() + key.slice(1);
    const Component = components[key];

    const ThemeWrappedComponent: React.FC<object> = props => {
      return (
        <StylesProvider injectFirst={true}>
          <ThemeProvider theme={gtTheme}>
            <Component {...props} />
          </ThemeProvider>
        </StylesProvider>
      );
    };

    ThemeWrappedComponent.propTypes = Component.propTypes;
    angularComponents[`gt${componentKey}`] = react2angular(ThemeWrappedComponent);
  });

  return angularComponents;
}
