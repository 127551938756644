export class adGroupService {
  /**
   * @param {ng.IHttpService} $http
   * @ngInject
   */
  constructor($http) {
    this.$http = $http;
  }

  /**
   * @param {string | null} searchQuery
   */
  async getListOfAdgroups(searchQuery = null, limit = 100) {
    let url;
    if (searchQuery) {
      url = `/data/campaigns/adgroups?search=${searchQuery}&limit=${limit}`;
    } else {
      url = `/data/campaigns/adgroups?limit=${limit}`;
    }
    let response = await this.$http({ method: 'GET', url });

    return response.data.adgroups;
  }

  /**
   * @param {number[]} adGroupsToAdd
   * @param {number[]} creativeAssetsToAdd
   */
  async addCreativesAssetsToAdGroups(adGroupsToAdd, creativeAssetsToAdd) {
    let url = '/data/campaigns/creatives';
    let response = await this.$http({
      method: 'POST',
      url,
      data: {
        adgroup_ids: adGroupsToAdd,
        asset_ids: creativeAssetsToAdd
      }
    });

    return response.data;
  }
}
