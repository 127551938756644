adgroupTargetingFactory.$inject = ['$q', '$http', 'companyAndAccountFactory'];

/**
 * @param {ng.IQService} $q
 * @param {import('../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 * @param {ng.IHttpService} $http
 */
export function adgroupTargetingFactory($q, $http, companyAndAccountFactory) {
  var adgroupTargetingFactory = {};

  adgroupTargetingFactory.confirmUpload = function(campaignId, adgroupId, jobId) {
    var d = $q.defer();
    $http({
      method: 'PUT',
      url: `/data/campaigns/${campaignId}/adgroups/${adgroupId}/jobs/${jobId}/confirmation`
    })
      .then(function({ data }) {
        d.resolve(data.location_filters);
      })
      .catch(function({ data }) {
        d.reject(data);
      });
    return d.promise;
  };

  adgroupTargetingFactory.entityDisplayTypes = {
    city: 'City',
    state: companyAndAccountFactory.isAccountInCountry('CA') ? 'Province' : 'State',
    dma: 'DMA',
    county: 'County',
    zip_code: companyAndAccountFactory.isAccountInCountry('CA') ? 'Postal Code' : 'ZIP Code',
    latlng: 'LatLng',
    address: 'Address',
    brand: 'Brand',
    category: 'Category',
    location_group: 'Location Group',
    behavior: 'Behavior',
    custom_audience: 'Custom',
    segment: 'Segment',
    weather_alert: 'Alert',
    weather_condition: 'Condition',
    weather_humidity: 'Humidity',
    weather_pressure: 'Pressure',
    weather_rain_probability: 'Rain Probability',
    weather_temperature: 'Temperature',
    weather_wind_speed: 'Wind Speed',
    weather_pollen: 'Pollen'
  };

  adgroupTargetingFactory.semiSensitiveWarningMessage = {
    OnPremise: {
      '1': 'Targeting sensitive locations is prohibited.',
      '2': 'Targeting sensitive locations is prohibited.',
      '3': 'Targeting sensitive locations is prohibited.',
      radial: 'Targeting sensitive locations with a radius of less than one mile is prohibited.'
    },
    Neighborhoods: 'Neighborhoods will be compiled using visits to non-sensitive locations only.',
    Audiences: 'Audiences will be compiled using visits to non-sensitive locations only.',
    DriveTo: null
  };

  adgroupTargetingFactory.driveToSemiSensitiveLocationWarningMessage =
    'We will only provide reporting that includes the visits to the non-sensitive locations included in your brand or location group.';

  adgroupTargetingFactory.getDemographicsOptions = {
    gender: {
      // radio
      All: {
        label: 'All',
        value: 'All'
      },
      Unknown: {
        label: 'Unknown',
        value: 'Unknown'
      },
      Male: {
        label: 'Male',
        value: 'Male'
      },
      Female: {
        label: 'Female',
        value: 'Female'
      }
    },
    ethnicity: {
      All: {
        label: 'All',
        value: 'All'
      },
      'African American': {
        label: 'African American',
        value: 'aa'
      },
      Asian: {
        label: 'Asian',
        value: 'as'
      },
      'Hispanic or Latino': {
        label: 'Hispanic or Latino',
        value: 'hl'
      }
    },
    income: {
      // checkboxes
      All: {
        label: 'All',
        value: 'All'
      },
      '0-50K': {
        label: '0-50K',
        value: '-50'
      },
      '50-100K': {
        label: '50-100K',
        value: '50-100'
      },
      '100-150K': {
        label: '100-150K',
        value: '100-150'
      },
      '150-200K': {
        label: '150-200K',
        value: '150-200'
      },
      '200K+': {
        label: '200K+',
        value: '200-'
      }
    },
    age: {
      // checkboxes
      All: {
        label: 'All',
        value: 'All'
      },
      Unknown: {
        label: 'Unknown',
        value: 'Unknown'
      },
      '18-24': {
        label: '18-24',
        value: '18-24'
      },
      '25-34': {
        label: '25-34',
        value: '25-34'
      },
      '35-44': {
        label: '35-44',
        value: '35-44'
      },
      '45-54': {
        label: '45-54',
        value: '45-54'
      },
      '55-64': {
        label: '55-64',
        value: '55-64'
      },
      '65+': {
        label: '65+',
        value: '65+'
      }
    }
  };

  adgroupTargetingFactory.getLookalikeAudienceOptions = {
    '1': {
      label: '2x of original audience',
      value: '1'
    },
    '3': {
      label: '4x of original audience',
      value: '3'
    },
    '5': {
      label: '6x of original audience',
      value: '5'
    },
    '9': {
      label: '10x of original audience',
      value: '9'
    }
  };

  adgroupTargetingFactory.orderedLookalikeAudienceOptions = [
    adgroupTargetingFactory.getLookalikeAudienceOptions['1'],
    adgroupTargetingFactory.getLookalikeAudienceOptions['3'],
    adgroupTargetingFactory.getLookalikeAudienceOptions['5'],
    adgroupTargetingFactory.getLookalikeAudienceOptions['9']
  ];

  adgroupTargetingFactory.getTechnographicsOptions = {
    os: {
      All: {
        label: 'All',
        value: 'All'
      },
      iOS: {
        label: 'iOS',
        value: 'iOS'
      },
      Android: {
        label: 'Android',
        value: 'Android'
      },
      Other: {
        label: 'Others',
        value: 'Other'
      }
    },
    device: {
      All: {
        label: 'ALL',
        value: 'All'
      },
      MobileApp: {
        label: 'MOBILE APP',
        default: true,
        value: 'App'
      },
      MobileWeb: {
        label: 'MOBILE WEB',
        value: 'Mobile Web'
      },
      Desktop: {
        label: 'DESKTOP',
        enableOnCrossDevice: true,
        value: 'Desktop'
      },
      CTV: {
        label: 'CTV',
        value: 'CTV'
      },
      OTT: {
        label: 'OTT',
        value: 'OTT'
      },
      AudioPodcast: {
        label: 'AUDIO PODCAST',
        value: 'Audio-Podcast'
      },
      AudioStreaming: {
        label: 'AUDIO STREAMING',
        value: 'Audio-Streaming'
      }
    }
  };

  adgroupTargetingFactory.checkSensitivityConsent = function(campaignId, params) {
    var d = $q.defer();
    $http({ method: 'GET', url: '/data/campaigns/' + campaignId + '/sensitivity_consent', params: params }).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );
    return d.promise;
  };

  adgroupTargetingFactory.checkSensitivityConsentMulti = async function(campaignId, data) {
    try {
      const url = `/data/campaigns/${campaignId}/sensitivity_consent_multi`;
      const result = await $http.post(url, data);
      return result.data;
    } catch (error) {
      return error.data.message;
    }
  };

  adgroupTargetingFactory.giveSensitivityConsent = function(campaignId, adgroupId, data) {
    var d = $q.defer();
    $http.post('/data/campaigns/' + campaignId + '/adgroups/' + adgroupId + '/sensitivity_consent', data).then(
      function(response) {
        d.resolve(response.data);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );
    return d.promise;
  };

  adgroupTargetingFactory.giveSensitivityConsentMulti = async function(campaignId, adgroupId, data) {
    try {
      const url = `/data/campaigns/${campaignId}/adgroups/${adgroupId}/sensitivity_consent_multi`;
      const result = await $http.post(url, data);
      return result.data;
    } catch (error) {
      return error.data.message;
    }
  };

  adgroupTargetingFactory.getLocationGroupStatus = function(locationGroupId, productFlag) {
    var d = $q.defer();
    let params = { product_flag: productFlag };
    $http({ method: 'GET', url: '/data/location_groups/' + locationGroupId + '/status', params: params }).then(
      function(response) {
        d.resolve(response.data.status);
      },
      function(response) {
        d.reject(response.data.message);
      }
    );
    return d.promise;
  };

  return adgroupTargetingFactory;
}
