modalChannelTermsController.$inject = ['$uibModalInstance', 'data', 'campaignManagerFactory'];

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 */
export function modalChannelTermsController($uibModalInstance, data, campaignManagerFactory) {
  var vm = this;

  vm.data = data;

  vm.campaign = campaignManagerFactory.selectedCampaign;
  vm.saveProgress = false;

  vm.close = function() {
    $uibModalInstance.close();
  };

  vm.submitCampaign = function() {
    vm.saveProgress = true;
    campaignManagerFactory
      .changeCampaignStatus(vm.campaign, campaignManagerFactory.statusNames.SUBMITTED)
      .then(
        function() {
          vm.errorMsg = '';
        },
        function(msg) {
          vm.errorMsg = msg;
        }
      )
      .finally(function() {
        vm.saveProgress = false;
        $uibModalInstance.close();
      });
  };
}
