import { observable, computed, action } from 'mobx';

export interface EditableFormField<T> {
  readonly value: T;
  readonly update: (value: T) => void;

  readonly isValid: boolean;
  readonly validationMessage: string | null;
}

export interface FormField<T, V = T> extends EditableFormField<T> {
  readonly validatedValue: V;
  set(value: V): void;

  validate(): boolean;
}

type ValidationResult<V> =
  | { valid: true; validatedValue: V }
  | { valid: false; validatedValue: V; validationMessage: string };

type FormFieldDefinition<T, V> = {
  readonly initialValue: T;
  readonly defaultValue?: V;
  readonly fromValue: (value: V) => T;
  readonly validate: (value: T) => ValidationResult<V>;
};

export class BaseFormField<T, V> implements FormField<T, V> {
  @observable.ref initalValue: T;
  @observable.ref value: T;

  private _validate: (value: T) => ValidationResult<V>;
  private _fromValue: (value: V) => T;

  constructor(def: FormFieldDefinition<T, V>) {
    this._validate = def.validate;
    this._fromValue = def.fromValue;

    this.initalValue = def.initialValue;
    this.value = this.initalValue;
  }

  @action.bound update(value: T): void {
    this.value = value;
  }

  @observable private liveValidation = false;

  @action.bound validate() {
    this.liveValidation = true;
    return this.isValid;
  }

  @computed get isValid(): boolean {
    if (!this.liveValidation) return true;
    return this._validationResult.valid;
  }

  @computed get validationMessage(): string | null {
    if (!this.liveValidation) return null;
    return this._validationResult.valid ? null : this._validationResult.validationMessage;
  }

  @computed private get _validationResult(): ValidationResult<V> {
    return this._validate(this.value);
  }

  @computed get validatedValue(): V {
    return this._validationResult.validatedValue;
  }

  @action.bound set(value: V): void {
    this.value = this._fromValue(value);
  }
}

function field<T>({ initialValue, required }: { initialValue: T; required?: boolean }) {
  return new BaseFormField<T, T>({
    fromValue: value => value,
    initialValue,
    validate: value =>
      required && !value
        ? { valid: false, validatedValue: value, validationMessage: 'Value is required' }
        : { valid: true, validatedValue: value }
  });
}

export function textField({ initialValue = '', required = false }: { initialValue?: string; required?: boolean } = {}) {
  return field<string>({ initialValue, required });
}

export function dateField({
  initialValue = null,
  required = false
}: { initialValue?: Date | null; required?: boolean } = {}) {
  return field<Date | null>({ initialValue, required });
}

export interface OptionsFormData<O> {
  readonly options: O[];
  readonly compare?: (option: O, value: O) => boolean;
}

export interface MultiselectEditableFormField<O> extends EditableFormField<O[]>, OptionsFormData<O> {}

export interface MultiselectFormField<O, V> extends MultiselectEditableFormField<O>, FormField<O[], V[]> {
  options: O[];
}

interface MultiselectFormFieldDefinition<O, V> {
  readonly options: O[] | (() => O[]);
  readonly initialValue?: O[];
  readonly getOptionValue: (option: O, instance: MultiselectFormField<O, V>) => V;
  readonly compare: (option: O, value: V, instance: MultiselectFormField<O, V>) => boolean;
  readonly getOption: (value: V, instance: MultiselectFormField<O, V>) => O;
  readonly validate?: (value: V[], instance: MultiselectFormField<O, V>) => ValidationResult<V[]>;
}

class MultiselectFormFieldImpl<O, V> extends BaseFormField<O[], V[]> implements MultiselectFormField<O, V> {
  private def: MultiselectFormFieldDefinition<O, V>;
  @observable.ref private _options: O[] | (() => O[]);

  constructor(def: MultiselectFormFieldDefinition<O, V>) {
    super({
      initialValue: def.initialValue ?? [],
      fromValue: value => value.map(v => def.getOption(v, this)),
      validate: value => {
        const validatedValue = value.map(v => def.getOptionValue(v, this));
        if (def.validate) return def.validate(validatedValue, this);
        return { valid: true, validatedValue };
      }
    });

    this.def = def;
    this._options = def.options;
    this.compare = this.compare.bind(this);
  }

  @computed get options() {
    return typeof this._options === 'function' ? this._options() : this._options;
  }
  set options(value: O[]) {
    this._options = value;
  }

  compare(option: O, value: O) {
    if (!this.def.compare) return option == value;

    const valueValue = this.def.getOptionValue(value, this);
    return this.def.compare(option, valueValue, this);
  }
}

/**
 * @param skipMultiValidation -
 * Used to skip the validation part where multiple values can exists,
 * but aren't fetched or aren't available right now to validate
 */
export function multiselectField<O>({
  options = [],
  required = false,
  compare = (option, value) => option == value,
  skipMultiValidation = false
}: {
  options?: O[] | (() => O[]);
  required?: boolean;
  compare?: (option: O, value: O) => boolean;
  skipMultiValidation?: boolean;
} = {}): MultiselectFormField<O, O> {
  return new MultiselectFormFieldImpl<O, O>({
    options,
    getOption: value => value,
    getOptionValue: option => option,
    compare,
    validate: (validatedValue, { options }) => {
      if (validatedValue.length === 0) {
        if (required) {
          return { valid: false, validatedValue, validationMessage: 'At least one option must be selected.' };
        } else {
          return { valid: true, validatedValue };
        }
      }
      const valid = validatedValue.every(value => options.findIndex(option => compare(option, value)) >= 0);
      if (valid || skipMultiValidation) {
        return { valid: true, validatedValue };
      } else {
        return { valid, validatedValue, validationMessage: 'One or more selected values are not available' };
      }
    }
  });
}

export interface SelectEditableFormField<O> extends EditableFormField<O | null>, OptionsFormData<O> {}

export interface SelectFormField<O, V> extends SelectEditableFormField<O>, FormField<O | null, V | null> {
  options: O[];
}

interface SelectFormFieldDefinition<O, V> {
  readonly options: O[] | (() => O[]);
  readonly initialValue?: O | null;
  readonly getOptionValue: (option: O, instance: SelectFormField<O, V>) => V;
  readonly getOption: (value: V, instance: SelectFormField<O, V>) => O;
  readonly validate?: (value: V | null, instance: SelectFormField<O, V>) => ValidationResult<V | null>;
}

class SelectFormFieldImpl<O, V> extends BaseFormField<O | null, V | null> implements SelectFormField<O, V> {
  @observable.ref private _options: O[] | (() => O[]);

  constructor(def: SelectFormFieldDefinition<O, V>) {
    super({
      initialValue: def.initialValue ?? null,
      fromValue: value => (value === null ? null : def.getOption(value, this)),
      validate: value => {
        const validatedValue = value !== null ? def.getOptionValue(value, this) : null;
        if (def.validate) {
          return def.validate(validatedValue, this);
        }
        return { valid: true, validatedValue };
      }
    });

    this._options = def.options;
  }

  @computed get options(): O[] {
    return typeof this._options === 'function' ? this._options() : this._options;
  }
  set options(value: O[]) {
    this._options = value;
  }
}

export function selectField<O>({
  options = [],
  required = false
}: { options?: O[] | (() => O[]); required?: boolean } = {}): SelectFormField<O, O> {
  return new SelectFormFieldImpl<O, O>({
    options,
    getOption: value => value,
    getOptionValue: option => option,
    validate: (validatedValue, { options }) => {
      if (validatedValue === null) {
        if (required) {
          return { valid: false, validatedValue, validationMessage: 'One of the options must be selected' };
        } else {
          return { valid: true, validatedValue };
        }
      }
      const valid = options.findIndex(option => option === validatedValue) >= 0;
      if (valid) {
        return { valid: true, validatedValue };
      } else {
        return { valid: false, validatedValue, validationMessage: 'Value is not available' };
      }
    }
  });
}

export function checkboxField(): FormField<boolean> {
  return field<boolean>({
    initialValue: false
  });
}

export interface ListFormField<T, V> extends EditableFormField<T[]>, FormField<T[], V[]> {}

interface ListFormFieldDefinition<T, V> {
  readonly initialValue?: T[];
  readonly fromItemValue: (item: V) => T;
  readonly validateItem: (item: T) => ValidationResult<V>;
  readonly validate?: (value: V[]) => ValidationResult<V[]>;
}

class ListFormFieldImpl<T, V> extends BaseFormField<T[], V[]> implements ListFormField<T, V> {
  constructor(def: ListFormFieldDefinition<T, V>) {
    super({
      initialValue: def.initialValue ?? [],
      fromValue: value => value.map(v => def.fromItemValue(v)),
      validate: value => {
        const results = value.map(v => def.validateItem(v));
        const valid = results.every(r => r.valid);
        const validatedValue = results.map(r => r.validatedValue);
        if (valid) return def.validate ? def.validate(validatedValue) : { valid, validatedValue };
        const validationMessage = results
          .map(r => (!r.valid ? r.validationMessage : null))
          .filter(m => m)
          .join(', ');
        return { valid, validatedValue, validationMessage };
      }
    });
  }
}

export function listField<T>({ required }: { required?: boolean } = {}) {
  return new ListFormFieldImpl<T, T>({
    validateItem: item => ({ valid: true, validatedValue: item }),
    fromItemValue: itemValue => itemValue,
    validate: required
      ? validatedValue =>
          validatedValue.length > 0
            ? { valid: true, validatedValue }
            : { valid: false, validatedValue, validationMessage: 'At least one item must be added' }
      : undefined
  });
}
