import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import { RemoveIcon, ErrorSquaredIcon, ErrorTriangleIcon } from '../../../../components/reactComponents/Icons';
import { styled } from '../../../../utils/react/styled';
import { Audience } from '../../../../api/http/data.generated';
import { formatNumber } from './utils';
import { getType } from './AccordionItem';
import { locationGroupWarningTooltip } from '../../common_messages';

interface AudienceListItemProps {
  includeItemDescription: boolean;
  isExcluded: boolean;
  item: Audience;
  status: string;
  onRemoveItem: (item: Audience) => void;
}

const cssStyles = {
  good: {
    icon: '#83BC1F',
    backgroundColor: 'var(--color-warning-background)'
  },
  errors: {
    icon: '#FFA940',
    backgroundColor: '#F2EEDA'
  },
  exclude: {
    icon: '#EE5658',
    backgroundColor: 'var(--color-error-container)'
  }
};

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: 'var(--color-on-primary)',
    borderRadius: '4px',
    color: 'var(--color-background)',
    fontFamily: 'var(--font-family-regular)',
    fontSize: 'var(--font-size-regular)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--line-height)',
    padding: '10px',
    maxWidth: '350px'
  },
  arrow: {
    color: 'var(--color-on-primary)'
  }
})(Tooltip);

const CustomRemoveIcon = React.memo(
  styled(RemoveIcon)(props => ({
    width: 'var(--icon-size)',
    height: 'var(--icon-size)'
  }))
);

const ErrorCustomSquaredIcon = styled(ErrorSquaredIcon)({
  width: 24,
  height: 24
});

const ErrorCustomTriangleIcon = styled(ErrorTriangleIcon)({
  width: 24,
  height: 24
});

const Wrapper = React.memo(
  styled('li')<{
    backgroundColor: string;
  }>(({ backgroundColor }) => ({
    width: '100%',
    '&:hover .audience-list__item-description': {
      transition: 'max-height 2.8s ease 2s, padding-top 0.6s ease 2s, padding-bottom 0.6s ease 2s',
      maxHeight: '500px',
      paddingBottom: '8px'
    },
    'border-radius': 4,
    backgroundColor
  }))
);

const SENSITIVITY_WARNING = 'Audience will be compiled using visits to non-sensitive locations only';
const LOCATION_GROUP_WARNING = locationGroupWarningTooltip.audience;

export function AudienceListItem({
  item,
  includeItemDescription,
  isExcluded,
  status,
  onRemoveItem
}: AudienceListItemProps) {
  const getItemStyle = () => {
    let type = 'good';
    if (isExcluded) {
      type = 'exclude';
    }
    if (item?.type === 'location_group' && (status !== 'ready' || item?.is_sensitive)) {
      type = 'errors';
    }
    return cssStyles[type];
  };

  const showDescription = React.useCallback(() => {
    return item?.description && includeItemDescription;
  }, [item, includeItemDescription]);

  // store it's value to use it as a check as well because getType returns null for non-private audiences
  const audienceType = getType(item);

  return (
    <Wrapper
      backgroundColor={getItemStyle().backgroundColor}
      className="audience-list__item"
      data-testid="audience-list__item"
    >
      <div className="audience-list__item-wrapper">
        <div className="audience-list__item-content">
          <span className="audience-list__item-name">
            {item?.name}
            {audienceType && <span className="audience-list__item-type">{`${audienceType}`}</span>}
          </span>
          {item?.type === 'location_group' && (
            <div className="audience-list__item-icons">
              {item?.is_sensitive && (
                <StyledTooltip title={SENSITIVITY_WARNING} placement="top">
                  <div className="audience-list__items-icon-sensitive">
                    <ErrorCustomSquaredIcon />
                  </div>
                </StyledTooltip>
              )}
              {status !== 'ready' && (
                <StyledTooltip title={LOCATION_GROUP_WARNING[status]} placement="top">
                  <div className="audience-list__items-icon-expired">
                    <ErrorCustomTriangleIcon />
                  </div>
                </StyledTooltip>
              )}
            </div>
          )}
          {item?.size != 0 && <span className="audience-list__item-size">{formatNumber(item.size)}</span>}
          <button className="audience-list__item-remove" onClick={() => onRemoveItem(item)}>
            <CustomRemoveIcon fill={getItemStyle().icon} />
          </button>
        </div>
      </div>
      {showDescription() && <div className="audience-list__item-description">{item.description}</div>}
    </Wrapper>
  );
}
