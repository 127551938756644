import buttonWithIconTemplate from './button-with-icon-template.html';

export class buttonWithIconController {
  constructor() {}

  $onInit() {}
}

export const buttonWithIcon = {
  template: buttonWithIconTemplate,
  controller: buttonWithIconController,
  controllerAs: 'vm',
  bindings: {
    iconUrl: '@',
    title: '@',
    buttonClass: '@',
    onClick: '='
  }
};
