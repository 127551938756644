SuperformUploadController.$inject = [
  '$scope',
  '$interval',
  '$uibModalInstance',
  '$log',
  'generalUtilsFactory',
  'companyAndAccountFactory',
  'campaignManagerFactory',
  'adgroupTargetingFactory',
  'jobFactory',
  'data',
  'handlers'
];

/**
 * @param {ng.ui.bootstrap.IModalInstanceService} $uibModalInstance
 * @param {import('../../../factories/types').GeneralUtilsService} generalUtilsFactory
 * @param {import('../../../factories/types').CompanyAndAccountFactoryService} companyAndAccountFactory
 */
export function SuperformUploadController(
  $scope,
  $interval,
  $uibModalInstance,
  $log,
  generalUtilsFactory,
  companyAndAccountFactory,
  campaignManagerFactory,
  adgroupTargetingFactory,
  jobFactory,
  data,
  handlers
) {
  const PROGRESS_INTERVAL_DELAY = 3000;

  var campaignId = campaignManagerFactory.selectedCampaign.id;
  var adgroupId = campaignManagerFactory.selectedAdGroup.id;

  $scope.uploadVars = data.uploadVars;
  $scope.adGroup = data.adGroup;

  $scope.cancelUpload = handlers.cancelUpload;
  $scope.checkUploadProgress = handlers.checkUploadProgress;
  $scope.companyAndAccountFactory = companyAndAccountFactory;
  $scope.uploadedFileName = null;
  $scope.showProgressSpinner = false;

  $scope.downloadVars = {
    errorMessage: null,
    showDownloadProgressSpinner: false
  };

  $scope.onFileAdded = function(file, $event) {
    var fileExtension = generalUtilsFactory.getFileExtensionFromString(file.name);
    if (!fileExtension || ['csv', 'xlsx'].indexOf(fileExtension) === -1) {
      $scope.errorMsg = 'Please upload a .csv or .xlsx file.';
      $scope.uploadVars.status = null;
      return false;
    }
    $scope.uploadVars.fileName = file.name;
  };

  $scope.fileAdded = function($flow, flowFile, message) {
    $scope.uploadVars.status = 'In Progress';
    $scope.downloadVars.errorMessage = null;

    $log.info('Successfully uploaded to s3.');
    let s3Url = window.mediaCdnUrl + JSON.parse(message).s3_path;
    var appendData = $scope.uploadVars.appendData;
    const accountId = $scope.companyAndAccountFactory?.selectedAccount?.id;
    jobFactory.createJob(jobFactory.jobTypes.GEOTARGET, s3Url, campaignId, adgroupId, accountId, appendData).then(
      function(data) {
        $scope.adGroup.targeting.from.geotarget_job_id = data.job_id;
        $scope.uploadVars.intervalPromise = $interval($scope.checkUploadProgress, PROGRESS_INTERVAL_DELAY);
      },
      function(data) {
        $scope.uploadVars.status = 'Failed';
        $scope.uploadVars.errorMsg = data.message;
      }
    );
  };

  $scope.confirmUpload = async () => {
    const jobId = $scope.adGroup.targeting.from.geotarget_job_id;
    $scope.showProgressSpinner = true;
    try {
      const uploadLocationFilters = await adgroupTargetingFactory.confirmUpload(campaignId, adgroupId, jobId);
      // Update the location filters in-place.
      // If we were to assign $scope.adGroup.targeting.location_filters
      // to a new list, it would break references that other components are using.
      angular.copy(uploadLocationFilters, $scope.adGroup.targeting.location_filters);
      angular.copy(
        $scope.adGroup.targeting.location_filters,
        campaignManagerFactory.selectedAdGroup.targeting.location_filters
      );
      $scope.showProgressSpinner = false;
      $scope.closeModal();
    } catch (error) {
      $scope.showProgressSpinner = false;
      $scope.uploadVars.errorMsg = error.message;
    }
  };

  $scope.downloadLocationFilters = async () => {
    $scope.downloadVars.errorMessage = null;

    if (!$scope.downloadVars.showDownloadProgressSpinner) {
      $scope.downloadVars.showDownloadProgressSpinner = true;
      const adgroupId = $scope.adGroup.id;
      const campaignId = campaignManagerFactory.selectedCampaign.id;
      try {
        const locationFiltersData = await campaignManagerFactory.downloadLocationFilters(campaignId, adgroupId);
        const filename = `adgroup_${adgroupId}_location_filters.xlsx`;
        generalUtilsFactory.downloadSpreadsheet(locationFiltersData, filename);
      } catch (errData) {
        // Since we are downloading a file, we set response type as arraybuffer
        // However, error responses return as JSON, so we need to decode the arraybuffer response
        const decodedResponseStr = String.fromCharCode(...new Uint8Array(errData.data));
        const decodedResponse = JSON.parse(decodedResponseStr);
        $scope.downloadVars.errorMessage = decodedResponse.message;
      } finally {
        $scope.downloadVars.showDownloadProgressSpinner = false;
        generalUtilsFactory.safeApply($scope);
      }
    }
  };

  // Closes the modal but doesn't reset upload vars
  $scope.minimizeModal = function() {
    $uibModalInstance.close();
  };

  // Close modal and reset upload vars
  $scope.closeModal = function() {
    $uibModalInstance.close();
    $scope.uploadVars.status = null;
    $scope.uploadVars.progress = 0;
    $scope.uploadVars.appendData = false;
  };
}
